import { connect } from 'react-redux';
import { setUser } from '../../InterviewDates/InterviewDatesRouter.action';
import Header from './Header';
import { setDate, setDateArray, setHeaderHeight } from './Header.action';
import { setEvaluatorInterviewDates } from 'Evaluator.action';

const mapStateToProps = (state) => ({
  user: state.userInfo,
  header: state.header,
  activeDepartment: state.department.activeDepartment,
  activeSeason: state.department.activeSeason,
  seasonChoices: state.department.seasonChoices,
  interviewDates: state.evaluator.interviewDates,
});

const mapDispatchToProps = (dispatch) => ({
  setDate: (date) => dispatch(setDate(date)),
  setUser: (userObject) => dispatch(setUser(userObject)),
  setEvaluatorInterviewDates: (dates) => dispatch(setEvaluatorInterviewDates(dates)),
  setDateArray: (dates) => dispatch(setDateArray(dates)),
  setHeaderHeight: (height) => dispatch(setHeaderHeight(height)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
