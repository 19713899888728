import React, { useContext, useEffect, useRef, useState } from 'react';

import style from './FlexEventAction.style';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faTrash, faTrashRestore } from '@fortawesome/free-solid-svg-icons';
import { clone } from 'lodash';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

const FlexEventAction = ({
  row,
  rowIndex,
  slotsToDelete,
  isSaving,
  unavailableSlots,
  setUnavailableSlots,
  setSaveHasResponded,
  deleteFlexEvent,
}) => {
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  // useEffect(() => {
  //   console.log('action slotsToDelete change: ', slotsToDelete);
  // }, [slotsToDelete]);

  const renderConfirmModal = () => {
    return (
      <Modal
        isOpen={showConfirmModal}
        centered={true}
        zIndex={9999}
        toggle={() => {
          setShowConfirmModal(!showConfirmModal);
        }}
      >
        <ModalHeader>Confirmation</ModalHeader>
        <ModalBody>
          You are about to delete a flex event. You cannot undo this. Are you sure you want to delete?
        </ModalBody>
        <ModalFooter>
          <Button
            id="unavailable_confirm_delete"
            color="success"
            style={style.confirmButtons}
            onClick={(e) => {
              e.stopPropagation();

              deleteFlexEvent(row.pk_FlexEvent);

              setShowConfirmModal(false);
            }}
          >
            Yes
          </Button>
          <Button
            id="unavailable_cancel_delete"
            color="danger"
            style={style.confirmButtons}
            onClick={(e) => {
              // e.stopPropagation();
              // setShowEvaluatorPicker({});
              setShowConfirmModal(false);
            }}
          >
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    );
  };

  const renderButtons = () => {
    return (
      <div style={style.centeredRow}>
        <FontAwesomeIcon
          id={`cancel-${rowIndex}`}
          style={{ ...style.clickableIcon, fontSize: 30, cursor: isSaving ? 'not-allowed' : 'pointer' }}
          icon={row.pk_FlexEvent ? (slotsToDelete.includes(row.pk_FlexEvent) ? faTrashRestore : faTrash) : faTimes}
          onClick={() => {
            if (isSaving) return;

            if (row.pk_FlexEvent != null) {
              setShowConfirmModal(true);
              // const newRow = clone(selectedRow);
              // newRow.isEdit = false;

              // updateSlot(newRow, rowIndex);

              // setSelectedRow(null);
              // selectedRowRef.current = null;
            } else {
              const newUnavailableSlots = clone(unavailableSlots);
              newUnavailableSlots.splice(rowIndex, 1);
              setUnavailableSlots(newUnavailableSlots);
            }
          }}
        />
      </div>
    );
  };

  return (
    <div>
      {renderButtons()}
      {renderConfirmModal()}
    </div>
  );
};

export default FlexEventAction;
