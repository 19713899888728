import { faSquareCheck } from '@fortawesome/free-regular-svg-icons';
import { faBell, faCheck, faSquare, faTimes } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import FontAwesome from 'react-fontawesome';
import { Button, Popover, PopoverBody, PopoverHeader, UncontrolledTooltip } from 'reactstrap';
import AlertTemplate from 'react-alert-template-basic';
import { positions, Provider as AlertProvider } from 'react-alert';
import BellSound from '../../../../assets/sounds/shipbell.wav';
import SilentSound from '../../../../assets/sounds/silent.wav';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAlert } from 'react-alert';
import { isNotificationsSupported } from 'Common.functions';
import NotifAlertTemplate from '../NotifAlertTemplate/NotifAlertTemplate';

const options = {
  timeout: 5000,
  position: positions.TOP_CENTER,
};
const NotificationTest = ({ unique_id, size }) => {
  const [showMyAlert, setShowMyAlert] = useState(false);
  const [myNotifPermission, setMyNotifPermission] = useState('default');
  const [myAudioPermission, setMyAudioPermission] = useState(false);
  const myAudioPermissionRef = useRef();
  const myAudioIsTestingRef = useRef();

  const mySound = BellSound;
  const audio = new Audio(mySound);
  const alert = useAlert();

  useEffect(() => {
    if (isNotificationsSupported()) {
      if (Notification.permission !== 'granted') {
        setMyNotifPermission(false);
        Notification.requestPermission().then((newPermission) => {
          setMyNotifPermission(newPermission === 'granted');
        });
      }
    } else {
      setMyNotifPermission(false);
    }

    audio.src = SilentSound;
    audio.volume = 1;
    audio
      .play()
      .then(() => {
        // console.log('silent click sound played fine');
        setMyAudioPermission(true);
        myAudioIsTestingRef.current = false;
      })
      .catch((err) => {
        console.log('silent click sound play. err: ', err);
        setMyAudioPermission(false);
        myAudioIsTestingRef.current = false;
      });
  }, []);

  useEffect(() => {
    myAudioPermissionRef.current = myAudioPermission;
  }, [myAudioPermission]);

  const testAlert = () => {
    if (isNotificationsSupported()) {
      if (Notification.permission !== 'granted') {
        setMyNotifPermission(false);
        Notification.requestPermission().then((newPermission) => {
          setMyNotifPermission(newPermission === 'granted');
        });
      }
    } else {
      setMyNotifPermission(false);
    }

    const Message = `This is a test notification!`;
    try {
      new Notification(`${Message}`);
      setMyNotifPermission(true);
    } catch (e) {
      console.log('Failed to fire notif. Err: ', e);
      setMyNotifPermission(false);
    }
    audio.src = mySound;
    audio.volume = 0.9;
    audio
      .play()
      .then(() => {
        console.log('test alert played fine');
        setMyAudioPermission(true);
      })
      .catch((err) => {
        console.log('test alert failed to play. err: ', err);
        setMyAudioPermission(false);
      });

    alert.info(`${Message}`, { timeout: 10000, html: true });
  };

  document.addEventListener('click', () => {
    if (!myAudioPermissionRef.current && !myAudioIsTestingRef.current) {
      // let audio = new Audio(mySound);
      myAudioIsTestingRef.current = true;
      audio.src = SilentSound;
      audio.volume = 1;
      audio
        .play()
        .then(() => {
          // console.log('silent click sound played fine');
          setMyAudioPermission(true);
          myAudioIsTestingRef.current = false;
        })
        .catch((err) => {
          console.log('silent click sound play. err: ', err);
          setMyAudioPermission(false);
          myAudioIsTestingRef.current = false;
        });
    }
  });

  return (
    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'left', alignItems: 'center' }}>
      <Button
        size={size || 'lg'}
        id={`notification_status_btn_${unique_id || ''}`}
        color={
          myNotifPermission && myAudioPermission
            ? `success`
            : !myNotifPermission && !myAudioPermission
            ? 'danger'
            : `warning`
        }
        onClick={() => {
          setShowMyAlert(!showMyAlert);
        }}
      >
        <FontAwesomeIcon icon={faBell} />
        <UncontrolledTooltip target={`notification_status_btn_${unique_id || ''}`}>
          <div>
            System Notifications: <FontAwesomeIcon icon={myNotifPermission ? faCheck : faTimes} />
          </div>
          <div>
            RezRate Sounds: <FontAwesomeIcon icon={myAudioPermission ? faCheck : faTimes} />
          </div>
        </UncontrolledTooltip>
      </Button>
      <Popover
        trigger="legacy"
        target={`notification_status_btn_${unique_id || ''}`}
        isOpen={showMyAlert}
        toggle={() => {
          setShowMyAlert(!showMyAlert);
        }}
      >
        <PopoverHeader>Notification Status</PopoverHeader>
        <PopoverBody>
          <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: '',
                alignItems: 'center',
                fontWeight: 'bold',
                color: myNotifPermission ? 'green' : 'red',
              }}
            >
              <div style={{ marginRight: 10 }}>
                <FontAwesomeIcon icon={myNotifPermission ? faSquareCheck : faSquare} />
              </div>
              System Notifications
            </div>

            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: '',
                alignItems: 'center',
                fontWeight: 'bold',
                color: myAudioPermission ? 'green' : 'red',
              }}
            >
              <div style={{ marginRight: 10 }}>
                <FontAwesomeIcon icon={myAudioPermission ? faSquareCheck : faSquare} />
              </div>
              RezRate Sounds
            </div>

            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', marginTop: 10 }}>
              <Button
                color="warning"
                size="sm"
                onClick={() => {
                  testAlert();
                }}
              >
                Test Notifications!
              </Button>
            </div>
          </div>
        </PopoverBody>
      </Popover>

      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          textAlign: 'center',
          justifyContent: 'left',
          paddingLeft: 10,
        }}
      ></div>
    </div>
  );
};

const AlertProvidedInterviewSchedule = (props) => {
  return (
    <AlertProvider template={NotifAlertTemplate} timeout={8000}>
      <NotificationTest {...props} />
    </AlertProvider>
  );
};

export default AlertProvidedInterviewSchedule;
