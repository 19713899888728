import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { determineIcon, isDev } from '../../Common.functions';
import { fetchDataAgnosticWithoutCredentials } from '../../Services/dataApi';

const DocumentViewer = (props) => {
  const [isAuthenticated, setIsAuthenticated] = useState(true);
  const [candidateFiles, setCandidateFiles] = useState(null);
  const [candidateInfo, setCandidateInfo] = useState(null);
  const [pk_UUID_FM, setPk_UUID_FM] = useState(null);
  const [documentTypes, setDocumentTypes] = useState([]);
  const [numOfDocTypes, setNumOfDocTypes] = useState(null);
  const [department, setDepartment] = useState(null);
  const [organization, setOrganization] = useState(null);
  const [sessionID, setSessionID] = useState(null);
  const [tempToken, setTempToken] = useState(null);

  const getCandidateInformation = (department, organization, pk_UUID_FM, sessionID, documentTypesLength) => {
    fetchDataAgnosticWithoutCredentials('candidate', {
      candidateFMUUID: pk_UUID_FM,
      department,
      organization,
      pk_Season: 1,
      sessionID,
      dev: isDev(),
    })
      .then((res) => {
        setCandidateInfo(res.data);
        fetchDataAgnosticWithoutCredentials('candidate/files/excludeHidden', {
          pk_Candidate: res.data.pk_Candidate,
          noHidden: true,
        })
          .then((res) => {
            // Candidate files are only getting candidates files that exist.  In order to display the correct number of documents
            // associated with that department, have to grab all document types for that department and note the length

            // TODO: should probably write a function that joins existing files with doucment types instead of putting them in
            // state as two different lengths
            setCandidateFiles(res.data);
            if (documentTypesLength) {
              setNumOfDocTypes(documentTypesLength);
            } else {
              setNumOfDocTypes(res.data);
            }
          })
          .catch((err) => {});
      })
      .catch((err) => {});
  };

  const getDocumentTypes = (department) => {
    fetchDataAgnosticWithoutCredentials('documentTypes', { department })
      .then((res) => {
        return res.data.length;
      })
      .catch((err) => {
        return null;
      });
  };

  useEffect(() => {
    const { department, organization, pk_UUID_FM, seasonID, tempToken } = props.match.params;

    const checkIfUserIsAuthenticated = async () => {
      const authenticated = await checkTempToken(tempToken);
      if (authenticated) {
        setTempToken(tempToken);
        setPk_UUID_FM(pk_UUID_FM);
        setDepartment(department);
        setOrganization(organization);
        setSessionID(seasonID);
        fetchDataAgnosticWithoutCredentials('documentTypes', { department })
          .then((res) => {
            getCandidateInformation(department, organization, pk_UUID_FM, seasonID, res.data);
          })
          .catch((err) => {
            getCandidateInformation(department, organization, pk_UUID_FM, seasonID, null);
          });
      } else {
        setIsAuthenticated(false);
      }
    };
    checkIfUserIsAuthenticated();
  }, []);

  const handleClick = (file) => {
    window.open(
      `/viewFile/${organization}/${department}/${sessionID}/${pk_UUID_FM}/${tempToken}/${file.pk_File}/${file.pk_DocumentType}/${file.Name}`,
      '_blank',
    );
  };

  const checkTempToken = (tempToken) => {
    return fetchDataAgnosticWithoutCredentials('tempToken/group', { tempToken, dev: isDev() })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return false;
      });
  };
  if (!isAuthenticated) {
    return <div></div>;
  }

  if (candidateFiles && numOfDocTypes) {
    return (
      <div
        style={{
          width: '400px',
          padding: '15px',
          height: '100vh',
          maxHeight: '100vh',
          overflow: 'scroll',
        }}
      >
        {numOfDocTypes.map((item, i) => {
          const file = candidateFiles[i];
          const color = i % 2 === 0 ? '#dadada' : '#FFF';
          if (!file) {
            return (
              <div
                key={i}
                style={{
                  padding: '10px',
                  height: '90px',
                  backgroundColor: color,
                }}
              ></div>
            );
          }
          return (
            <div
              className="hover_blue"
              key={file.pk_File}
              style={{
                padding: '10px',
                cursor: 'pointer',
                height: '90px',
                backgroundColor: color,
              }}
            >
              <div
                style={{ display: 'flex', alignItems: 'center' }}
                onClick={(e) => {
                  e.preventDefault();
                  handleClick(file);
                }}
              >
                <div style={{ paddingRight: '15px' }}>
                  <FontAwesomeIcon size={'5x'} icon={determineIcon(file.MimeType)} />
                </div>
                <h3>{file.DocumentTypeName}</h3>
              </div>
            </div>
          );
        })}
      </div>
    );
  }

  return <div />;
};

export default DocumentViewer;
