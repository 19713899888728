import React, { useEffect, useState, useContext } from 'react';
import { Alert } from 'reactstrap';
import { fetchDataAgnosticWithoutCredentials } from './Services/dataApi';
import { DepartmentContext } from './DepartmentWrapper';

const UserNotification = () => {
  const [notificaton, setNotification] = useState(null);

  const dContext = useContext(DepartmentContext);

  const { department, season } = dContext;

  useEffect(() => {
    if (department && department.pk_Department && season && season.pk_Season)
      fetchDataAgnosticWithoutCredentials('groupInfo/notifications', {
        pk_Department: dContext.department.pk_Department,
        pk_Season: dContext.season.pk_Season,
      })
        .then((res) => {
          if (res.data && res.data.ShouldDisplay) {
            setNotification(res.data);
          }
        })
        .catch((err) => {});
  }, [department, season]);
  if (!notificaton) return <div></div>;

  return (
    <div style={{ margin: 0 }}>
      <Alert style={{ margin: 0 }} color={notificaton.TypeOfAlert || 'info'}>
        {notificaton.MessageToUser}
      </Alert>
    </div>
  );
};

export default UserNotification;
