import { faCloudUploadAlt, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useAuth0 } from '../../auth0/reactAuth0Spa';
import { Col, Row } from 'reactstrap';
import { fetchDataAgnostic, constructUrl } from '../../Services/dataApi';
import Swal from 'sweetalert2';
import Error from '../../Body/Statuses/Error';
import Loading from '../../Body/Statuses/Loading';
import CandidateHeader from '../../Header/header.container';
import UserPhotoTypes from '../../Body/DragAndDropFiles/UserPhoto/UserPhotoTypes';
import UserPhoto from '../../Body/DragAndDropFiles/UserPhoto/UserPhoto';

const thumb = {};

const thumbInner = {};

const CandidatePhoto = ({ match, user, setUser }) => {
  const [hasPhoto, setHasPhoto] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isDemo, setIsDemo] = useState(false);
  const [error, setError] = useState(false);
  const [noUser, setNoUser] = useState(false);
  const [isPosting, setIsPosting] = useState(false);
  const [currentProgress, setCurrentProgress] = useState(0);
  const [files, setFiles] = useState([]);

  const { PhotoUrl } = user || {};

  useEffect(() => {
    if (PhotoUrl != null && PhotoUrl.trim() !== '') {
      setHasPhoto(true);
    } else {
      setHasPhoto(false);
    }
  }, [PhotoUrl]);

  const { getTokenSilently } = useAuth0();

  const thumbs = () => {
    return files.map((file) => (
      <div style={thumb} key={file.name}>
        <div style={thumbInner}>
          <img src={file.preview} style={{ width: '100%' }} />
        </div>
      </div>
    ));
  };

  const singleCandidateObject = (files) => {
    const formData = new FormData();
    for (var i in files) {
      formData.append(`photo`, files[i]);
      formData.append('w', 1);
      formData.append('h', 1);
      formData.append('x', 1);
      formData.append('y', 1);
    }
    return formData;
  };

  const fileUpload = (formData) => {
    // return;
    // const url = 'https://devapi.rezrate.com/files';
    const isLocal = window.location.href.includes('localhost:3000/');
    const url = isLocal ? 'http://localhost:8080/files/candidate' : 'https://devapi.rezrate.com/files/candidate';
    setIsPosting(true);

    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Access-Control-Allow-Origin': '*',
      },
      onUploadProgress: function(progressEvent) {
        var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        setCurrentProgress(percentCompleted);
      },
    };
    return Axios.post(url, formData, config)
      .then((res) => {
        postToFM(res.data.uri);
      })
      .catch((err) => {
        Swal.fire('Oops', 'Something went wrong. Please try again or contact support', 'error').then(() => {});
        return false;
      })
      .finally(() => {
        setIsPosting(false);
      });
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      'image/jpeg': [],
      'image/png': [],
    },
    onDrop: (acceptedFiles) => {
      const incorrectLength = acceptedFiles.length !== 1;
      const incorrectBytes = acceptedFiles[0].size > 5000000;
      if (incorrectBytes) {
        Swal.fire('Oops...', `It looks like the file might be a little too big, make sure it's under 5mb`, 'error');
        return;
      }

      if (incorrectLength) {
        Swal.fire('Oops,', 'Please make sure you only upload a single image file', 'error');
        return;
      }
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          }),
        ),
      );

      // const fileData = singleCandidateObject(acceptedFiles);
      postToFM(acceptedFiles[0]);
    },
  });

  const succesMessage = () => {
    Swal.fire('Success!', 'Your new file has been uploaded', 'success');
  };

  const errorMessage = () => {
    Swal.fire('Oops,', 'Something went wrong, please try again later or contact support', 'error');
  };

  const postToFM = (fileData) => {
    try {
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Access-Control-Allow-Origin': '*',
        },
        onUploadProgress: function(progressEvent) {
          var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          setCurrentProgress(percentCompleted);
        },
      };

      const formData = new FormData();
      formData.append('File', fileData);
      setIsUploading(true);
      const { id } = match.params;
      const urlToSend = `${constructUrl('files/candidate')}?id=${id}`;

      Axios.post(urlToSend, formData, config)
        .then((result) => {
          setUser(result.data);
        })
        .catch((err) => {
          setError(true);
        })
        .finally(() => {
          setIsUploading(false);
        });
    } catch (err) {}
  };

  useEffect(() => {
    const { id = 'DEMO' } = match.params;
    if (id.toUpperCase() === 'DEMO') {
      setIsDemo(true);
      setUser({});
      setIsLoading(false);
      return;
    }

    fetchDataAgnostic('candidate/photo', { CandidateUUID: id })
      .then((result) => {
        setUser(result.data);
        setIsLoading(false);
      })
      .catch((err) => {
        setError(true);
      });
  }, []);

  if (isLoading) {
    return <Loading />;
  }

  if ((user === null || !user) && !isDemo) {
    return <Error header="Error" subHeader="We couldn't find a user with that ID" />;
  }

  const { id = 'DEMO' } = match.params;

  return (
    <Row style={{ padding: '25px', width: '100%' }}>
      <Col>
        <Row>
          <CandidateHeader showNotifTester={false} />
        </Row>

        <br />
        <Row style={{ marginTop: '5vh' }}>
          <Col
            md={{ size: 6, offset: 3 }}
            offset
            style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: '' }}
          >
            <Row>
              <div style={{ width: '100%', minHeight: 300 }}>
                <UserPhoto
                  type={UserPhotoTypes.Candidate}
                  photoUrl={PhotoUrl}
                  id={id}
                  imgStyle={{ minWidth: 300, objectFit: 'scale-down' }}
                  showDeleteButton={false}
                />
              </div>
              <div style={{ padding: '15px' }}>
                {!hasPhoto && (
                  <h5>
                    <span className="bold">{user.NameFirst || 'Welcome'}</span>, here you can upload a photo of yourself
                    that will be used within RezRATE. This photo will be seen by those you interview with, so make sure
                    you look your best!
                  </h5>
                )}
                {hasPhoto && (
                  <h5>
                    <span className="bold">{user.NameFirst || 'Welcome'}</span>, it looks like we already have a photo
                    for you. You can replace it if you have a better one.
                  </h5>
                )}
              </div>
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default CandidatePhoto;

// documents dissapearing
// inversion
// ship bell
// schedule showing
// status not updating
