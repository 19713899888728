import React, { useEffect, useState } from 'react';
import { Button, Input, Label } from 'reactstrap';
import { formatBearerToken } from '../../../../../Common.functions';
import { putData } from '../../../../../Services/dataApi';
import FocusPalette from '../FocusPalette';

import style from './RoomPropertiesPopover.style';

const RoomPropertiesPopover = ({
  header,
  onUpdateEvaluators,
  setShowRoomPropertiesPopover,
  hasDelay,
  allowEdit,
  reference,
  returnEntireFocusObject,
  updateSelectedFocuses,
  fireConfirmationForEditingPastSchedule,
  dateIsInThePast,
  willEditThePast,
}) => {
  const [VirtualRoomURL, setVirtualRoomURL] = useState();
  const [VirtualRoomDescription, setVirtualRoomDescription] = useState();
  const [roomLabel, setRoomLabel] = useState();
  const [ignoreEvaluatorFocuses, setIgnoreEvaluatorFocuses] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    if (header) {
      const { rawHeader = {} } = header;
      console.log('rawHeader: ', rawHeader);
      setVirtualRoomURL(rawHeader.VirtualRoomURL || '');
      setVirtualRoomDescription(rawHeader.VirtualRoomDescription || '');
      setRoomLabel(header.label);
      setIgnoreEvaluatorFocuses(rawHeader.EnableIgnoreEvaluatorFocus || false);
    }
  }, []);

  const onSave = () => {
    setIsSubmitting(true);
    onUpdateEvaluators(
      {
        ignoreEvaluatorFocuses,
        VirtualRoomURL,
        VirtualRoomDescription,
        RoomLabel: roomLabel,
        column: header,
        pk_ScheduleAssignment: header.dataField,
        RoomFocuses: reference,
      },
      () => {
        setShowRoomPropertiesPopover(false);
        setIsSubmitting(false);
      },
    );
  };

  const defaultTotalSplits =
    header && header.rawHeader && header.rawHeader.ColumnTotalNumberSplits != null
      ? header.rawHeader.ColumnTotalNumberSplits
      : 1;

  return (
    <>
      <div>
        <div style={style.simpleRow}>
          <div
            style={{ ...style.simpleColumn, width: '65%', marginRight: 10 }}
            onClick={async () => {
              if (dateIsInThePast && !willEditThePast) {
                const continueProcess = await fireConfirmationForEditingPastSchedule();
                if (!continueProcess) {
                  return;
                }
              }
            }}
          >
            <div>
              <Label htmlFor="virtual_room">Virtual Room URL</Label>
              <Input
                disabled={isSubmitting}
                id="virtual_room"
                style={{ textAlign: 'center' }}
                type="text"
                placeholder="Ex: https://www.google.com"
                defaultValue={VirtualRoomURL}
                onChange={(e) => {
                  setVirtualRoomURL(e.target.value);
                }}
              />
            </div>
            <div>
              <Label htmlFor="virtual_room_details">Virtual Room Details</Label>
              <Input
                disabled={isSubmitting}
                id="virtual_room_details"
                style={{ textAlign: 'center' }}
                type="textarea"
                placeholder="Details. . ."
                defaultValue={VirtualRoomDescription}
                onChange={(e) => {
                  setVirtualRoomDescription(e.target.value);
                }}
              />
            </div>
            <div>
              <Label htmlFor="virtual_room_label">Room Label</Label>
              <Input
                id="virtual_room_label"
                disabled={isSubmitting}
                type="text"
                style={{ textAlign: 'center' }}
                placeholder="Enter a Room Label. . ."
                value={roomLabel}
                onChange={(e) => {
                  setRoomLabel(e.target.value);
                }}
              />
            </div>

            <div />
          </div>
          <div style={{ ...style.simpleColumn, width: '35%' }}>
            <FocusPalette
              hasDelay={hasDelay}
              allowEdit={allowEdit}
              reference={reference}
              ignoreEvaluatorFocuses={ignoreEvaluatorFocuses}
              setIgnoreEvaluatorFocuses={setIgnoreEvaluatorFocuses}
              returnEntireFocusObject={returnEntireFocusObject}
              updateSelectedFocuses={updateSelectedFocuses}
            />
          </div>
        </div>
      </div>

      <div style={style.roomNumberButtons}>
        <Button
          color="danger"
          style={{ ...style.roomNumberButton }}
          disabled={isSubmitting}
          onClick={() => {
            setShowRoomPropertiesPopover(false);
          }}
        >
          Cancel
        </Button>

        <Button
          disabled={isSubmitting}
          color="success"
          style={{ ...style.roomNumberButton, ...style.genButton, backgroundColor: null }}
          onClick={async () => {
            if (dateIsInThePast && !willEditThePast) {
              const continueProcess = await fireConfirmationForEditingPastSchedule();
              if (!continueProcess) {
                return;
              }
            }
            onSave();
          }}
        >
          Save
        </Button>
      </div>
    </>
  );
};

export default RoomPropertiesPopover;
