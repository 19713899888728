import { connect } from 'react-redux';
import Settings from './Settings';
import { setSettings } from './Settings.action';

const mapStateToProps = (state) => {
  return {
    sidebarIsClosed: state.sidebar.sidebarIsClosed,
  };
};

const mapDispatchToProps = (dispatch) => ({
  setSettings: (settings) => dispatch(setSettings(settings)),
});

export default connect(null, mapDispatchToProps)(Settings);
