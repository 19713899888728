import { faTag } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { clone } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { useAlert } from 'react-alert';
import {
  Button,
  Card,
  CardBody,
  CustomInput,
  Input,
  InputGroup,
  InputGroupAddon,
  ModalBody,
  ModalHeader,
  Popover,
  PopoverBody,
  PopoverHeader,
  UncontrolledTooltip,
  UncontrolledPopover,
} from 'reactstrap';
let typingTimeout = null;
const LimitInput = (props) => {
  const [limitToDisplay, setLimitToDisplay] = useState(0);
  const {
    cell,
    departmentSettings,
    formatExtraData,
    isUpdating,
    lastField,
    renderRegistrationLimitPopover,
    renderType = 1,
    row,
    rowIndex,
    setLastField,
    setShowSettings,
    showSettings,
    typeChangeRef,
    updateEntry,
  } = props;

  let { lastEditedField } = props;
  const {
    EnableHiddenStatus,
    EnableLockedStatus,
    MomentDateOfInterview,
    pk_InterviewDate,
    CannotBeUnlocked,
    CandidateRegistrationLimit,
    CountOfCandidates,
    DateOfInterview,
    StartTime,
    pk_Tag,
    Tag,
  } = row;

  const alert = useAlert();
  useEffect(() => {
    setLimitToDisplay(CandidateRegistrationLimit);
  }, [CandidateRegistrationLimit]);

  const allowTagFiltering = departmentSettings && departmentSettings.EnableTagFiltering; // get from department settings

  const inputKey = `registration_btn_${pk_InterviewDate}`;
  //   let countToDisplay = CandidateRegistrationLimit;

  return (
    <>
      <InputGroup style={{ width: '100%' }}>
        <Input
          disabled={isUpdating || (lastField != null && lastField !== inputKey)}
          onFocus={(e) => {
            // if (allowTagFiltering) {
            //   e.target.blur();
            // }
          }}
          id={inputKey}
          key={`key_${inputKey}_${allowTagFiltering ? limitToDisplay : cell || 0}`}
          type={'number'}
          min={0}
          // value={allowTagFiltering ? limitToDisplay : cell || 0}
          value={limitToDisplay || 0}
          style={{
            textAlign: 'center',
            marginRight: 0,
            fontSize: 13,
            height: 52,
            backgroundColor:
              limitToDisplay == CountOfCandidates || (limitToDisplay == null && CountOfCandidates == 0)
                ? '#88ff82'
                : CountOfCandidates > limitToDisplay
                ? '#ffc180'
                : null,
          }}
          onClick={(e) => {
            if (isUpdating) {
              e.stopPropagation();
              e.preventDefault();
            }
          }}
          onChange={(e) => {
            if (isUpdating) {
              return;
            }
            let newValue = parseInt(e.target.value);
            console.log('typingTimeout: ', typingTimeout);
            if (typingTimeout) {
              clearTimeout(typingTimeout);
              typingTimeout = null;
            }

            if (typeChangeRef && typeChangeRef.current) {
              typeChangeRef.current.CandidateRegistrationLimit = newValue >= 0 ? newValue : 0;
            } else {
              typeChangeRef.current = { CandidateRegistrationLimit: newValue >= 0 ? newValue : 0 };
            }

            setLimitToDisplay(newValue);
            setLastField(inputKey);
            lastEditedField = inputKey; // `registration_btn_${rowIndex}`;
            typingTimeout = setTimeout(() => {
              updateEntry(typeChangeRef.current, pk_InterviewDate, (success) => {
                if (success) {
                  alert.success('Limit updated!');
                } else {
                  alert.error('Failed to update limit!');
                }
              });
            }, 1000);
          }}
        />
        {allowTagFiltering ? (
          <InputGroupAddon addonType="append">
            <Button
              size="sm"
              // outline={pk_Tag ? true : false}
              id={`registration_btn_${rowIndex}`}
              style={{ zIndex: 0, backgroundColor: pk_Tag ? null : '#a1a5aa' }}
              color={pk_Tag ? 'primary' : null}
              onClick={() => {
                const key = `registration_btn_${rowIndex}`;
                const newSettings = clone(showSettings);
                newSettings[key] = !newSettings[key];
                setShowSettings(newSettings);
              }}
            >
              <FontAwesomeIcon icon={faTag} style={{ color: 'white' }} />
            </Button>
            <UncontrolledTooltip target={`registration_btn_${rowIndex}`}>
              {' '}
              {pk_Tag
                ? `Session is only visible to candidates with tag: ${Tag}`
                : 'Session is visible to all candidates'}{' '}
            </UncontrolledTooltip>
          </InputGroupAddon>
        ) : null}
      </InputGroup>
      {allowTagFiltering ? renderRegistrationLimitPopover({ ...row, rowIndex, renderType }) : null}
    </>
  );
};
export default LimitInput;
