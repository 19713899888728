const style = {
  listItem: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    textAlign: 'center',
    justifyContent: 'spaced-between',
    padding: 5,
    cursor: 'pointer',
    width: '100%',
  },
};

export default style;
