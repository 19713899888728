import { connect } from 'react-redux';
import { setDateList, setOrder } from '../Animation/ListAnimation.action';
import ListAnimation from './ListAnimation';

const mapStateToProps = (state) => ({
  listArray: state.animation.listArray,
  order: state.animation.order,
});

const mapDispatchToProps = (dispatch) => ({
  setOrder: (order) => dispatch(setOrder(order)),
  setListArray: (list) => dispatch(setDateList(list)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ListAnimation);
