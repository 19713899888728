import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import imageToBase64 from 'image-to-base64';

import moment from 'moment-timezone';
import tinycolor from 'tinycolor2';
import { clone, getAvailableCandidatesForRow } from '../../schedule';
import Swal from 'sweetalert2';
import profilePlaceholder from '../CandidateBadges/Portrait_Placeholder.png';
import rezRateLogo from '../rezRateLogo.png';

let rezRateLogoBase64 = null;
// const bgGuide = require('./AVERY_5390.png');
let profilePlaceholderBase64 = null;

pdfMake.vfs = pdfFonts.pdfMake.vfs;

// 1 inch = 72 units.
// LETTER size is 612x792

const tableLayouts = {
  customTable: {
    hLineWidth: (i, node) => {
      return 1;
      // if (i === 0 || i === node.table.body.length) {
      //   return 0;
      // }
      // return i === node.table.headerRows ? 2 : 1;
    },
    vLineWidth: (i) => {
      return 1;
    },
    hLineColor: (i) => {
      return i === 1 ? 'black' : '#aaa';
    },
    paddingLeft: (i) => {
      return 0;
    },
    paddingRight: (i, node) => {
      return 0;
    },
    paddingTop: (i, node) => {
      return 0;
    },
    paddingBottom: (i, node) => {
      return 0;
    },
  },
};

const createPDFProper2 = (data) => {
  const { interviewDate, candidates, department, departmentLogo, scheduleData, rowTracker, columnTracker } = data;
  const { body = [], headers = [], metaData = {} } = scheduleData;

  const candidateKeys = candidates.map((c) => {
    return c.pk_Candidate;
  });

  const columnsToFill = headers.map((h) => {
    return h.pk_ScheduleAssignment;
  });

  const interviewCountTracker = {};
  Object.keys(rowTracker).forEach((r) => {
    candidateKeys.forEach((c) => {
      if (rowTracker[r].includes(c)) {
        if (interviewCountTracker[c] != null) {
          interviewCountTracker[c] += 1;
        } else {
          interviewCountTracker[c] = 1;
        }
      }
    });
  });

  const tableRows = [];
  let rows = [
    [
      { text: 'Time Slot', style: { fontSize: 15, alignment: 'center', bold: true } },
      { text: 'Candidates on Break', fontSize: 15, alignment: 'center', bold: true },
    ],
  ];

  const renderCard = (candidates, timeSlotRow) => {
    const cardRows = [];
    const userDetailRows = [];
    const rowColumns = [];

    rowColumns.push({
      style: { alignment: 'center', bold: true },
      text: `${moment.tz(timeSlotRow.slot, 'MMM DD, YYYY hh:mm A', null).format('hh:mm A')} `,
      fontSize: 11,
      margin: [0, 0, 0, 2],
    });
    candidates.forEach((candidate) => {
      const { userPhotoBase64 } = candidate;
      const userPhoto = userPhotoBase64
        ? {
            width: 40,
            columns: [
              {
                image: userPhotoBase64 ? `data:image/png;base64,${userPhotoBase64}` : null,
                fit: [20, 20],
              },
            ],
            margin: [10, 0, 10, 0],
          }
        : {
            width: 40,
            columns: [
              {
                image: profilePlaceholderBase64 ? `data:image/png;base64,${profilePlaceholderBase64}` : null,
                fit: [20, 20],
              },
            ],
            margin: [10, 0, 10, 0],
          };

      userDetailRows.push({
        style: { alignment: 'left', paddingLeft: 50 },
        text: `${candidate.LastName}, ${candidate.FirstName} `,
        fontSize: 10,
        margin: [15, 0, 0, 0],
      });
    });

    if (candidates.length <= 0) {
      userDetailRows.push({
        style: { alignment: 'left', paddingLeft: 50 },
        text: `No Candidates on Break! `,
        fontSize: 10,
        margin: [15, 0, 0, 0],
      });
    }

    rowColumns.push({ columns: [userDetailRows], style: { paddingTop: 10, paddingBottom: 10 } });

    cardRows.push(rowColumns);

    return rowColumns;
  };

  body.forEach((timeSlotRow, i) => {
    if (timeSlotRow.pk_Timeslot === 'END') {
      return;
    }
    const currentRowToFill = rowTracker[timeSlotRow.pk_Timeslot];

    const validCandidatesActual = candidates.filter((c) => {
      return !currentRowToFill.includes(parseInt(c.pk_Candidate));
    });

    rows.push(renderCard(validCandidatesActual, timeSlotRow));
  });

  if (rows.length <= 0) {
    Swal.fire('Error', 'There are no Timeslots for this Interview Date. There is nothing to print!', 'error');
    return;
  }

  let pdfObject = [
    {
      table: { headerHeight: 50, widths: [1.95 * 72, 5.95 * 72], body: rows, dontBreakRows: true, headerRows: 1 },
      layout: 'customTable',
      margin: [10, 20, 0, 0],
    },
  ];

  // imageToBase64(bgGuide)
  //   .then((bgBase64) => {
  const docDefinition = {
    // background: { image: `data:image/png;base64,${bgBase64}`, width: 612 },
    info: { title: 'Candidates Break Report' },
    pageSize: 'LETTER',
    pageOrientation: 'portrait',
    content: pdfObject,
    defaultStyle: {
      fontSize: 10,
    },
    pageMargins: [0.25 * 72, 1.5 * 72, 0.25 * 72, 0.5 * 72],
    header: function(currentPage, pageCount, pageSize) {
      const deptLogo = {
        width: 80,
        columns: [
          {
            image: `data:image/png;base64,${departmentLogo || rezRateLogoBase64}`,
            // width: 90,
            fit: [80, 80],
          },
        ],
        margin: [0, 0, 10, 0],
      };

      const { DateOfInterview, StartTime, Label } = metaData || {};
      const dateToDisplay = interviewDate.MomentDateOfInterview
        ? interviewDate.MomentDateOfInterview.format('dddd, MMMM DD, YYYY')
        : DateOfInterview
        ? moment.tz(`${DateOfInterview} ${StartTime}`, null).format('dddd, MMMM DD, YYYY')
        : '(No Date)';

      return [
        {
          columns: [
            deptLogo,
            [
              [
                {
                  text: `Candidate Breaks Report - ${dateToDisplay}`,
                  alignment: 'bottom',
                  style: { bold: true },
                  fontSize: 15,
                  margin: [10, 0, 0, 0],
                },
              ],
              [
                {
                  text: ` ${interviewDate.Label ? interviewDate.Label : Label ? Label : ''}`,
                  alignment: 'bottom',
                  style: { bold: true, color: '#9e9e9e' },
                  fontSize: 12,
                  margin: [10, 0, 0, 0],
                },
              ],
              [
                {
                  text: `${department.DepartmentName}`,
                  alignment: 'bottom',
                  style: { bold: false },
                  fontSize: 11,
                  margin: [10, 0, 0, 0],
                },
              ],
              [
                {
                  text: `${department.OrganizationName || department.OrganizationAlias || ''}`,
                  alignment: 'bottom',
                  style: { bold: false },
                  fontSize: 11,
                  margin: [10, 0, 0, 0],
                },
              ],
            ],
          ],
          margin: [0.4 * 72, 20, 0.4 * 72, 0],
        },
      ];
    },
  };

  pdfMake.createPdf(docDefinition, tableLayouts).open({}, window.open('', '_blank'));
  // })
  // .catch((err) => {
  // });
};

export const createPDFForCandidatesBreakReport = ({
  interviewDate,
  department,
  candidates = [],
  rowTracker,
  columnTracker,
  callback,
  scheduleData,
}) => {
  const candidatesCopy = clone(candidates);
  Promise.all([
    ...candidatesCopy.map((c) => {
      return new Promise((resolve, reject) => {
        if (c.PhotoUrl) {
          imageToBase64(c.PhotoUrl)
            .then((base64Img) => {
              c.userPhotoBase64 = base64Img;
              resolve(c);
            })
            .catch((err) => {
              resolve({ error: true, ...c });
            });
        } else {
          resolve(c);
        }
      });
    }),
    new Promise((resolve, reject) => {
      imageToBase64(profilePlaceholder)
        .then((base64) => {
          profilePlaceholderBase64 = base64;
          resolve(base64);
        })
        .catch((err) => {
          reject(err);
        });
    }),

    new Promise((resolve, reject) => {
      imageToBase64(rezRateLogo)
        .then((res) => {
          rezRateLogoBase64 = res;
          resolve(res);
        })
        .catch(() => {
          reject();
        });
    }),
  ])
    .then((res) => {
      if (department.DepartmentLogo) {
        imageToBase64(department.DepartmentLogo)
          .then((deptLogo) => {
            if (callback) {
              callback();
            }
            createPDFProper2({
              candidates: candidatesCopy,
              department,
              interviewDate,
              rowTracker,
              columnTracker,
              scheduleData,
              departmentLogo: deptLogo,
            });

            if (callback) {
              callback();
            }
          })
          .catch((err) => {
            createPDFProper2({
              candidates: candidatesCopy,
              department,
              interviewDate,
              rowTracker,
              columnTracker,
              scheduleData,
            });
            if (callback) {
              callback();
            }
          });
      } else {
        createPDFProper2({
          candidates: candidatesCopy,
          department,
          interviewDate,
          rowTracker,
          columnTracker,
          scheduleData,
        });

        if (callback) {
          callback();
        }
      }
    })
    .catch((err) => {
      console.log('err2: ', err);
      createPDFProper2({
        candidates: candidatesCopy,
        department,
        interviewDate,
        rowTracker,
        columnTracker,
        scheduleData,
      });
      if (callback) {
        callback(err);
      }
    });
};
