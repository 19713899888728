import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import imageToBase64 from 'image-to-base64';

import moment from 'moment-timezone';
import tinycolor from 'tinycolor2';
import { clone } from '../../schedule';
import { determineEvent } from '../../../Common.functions';
import rezRateLogo from '../rezRateLogo.png';

let rezRateLogoBase64 = null;

pdfMake.vfs = pdfFonts.pdfMake.vfs;

// 1 inch = 72 units.
// LETTER size is 612x792

const tableLayouts = {
  customTable: {
    hLineWidth: (i, node) => {
      return 0;
    },
    vLineWidth: (i) => {
      return 0;
    },
    hLineColor: (i) => {
      return i === 1 ? 'red' : '#aaa';
    },
    paddingLeft: (i) => {
      return 0;
    },
    paddingRight: (i, node) => {
      return 0;
    },
    paddingTop: (i, node) => {
      return 0;
    },
    paddingBottom: (i, node) => {
      return 0;
    },
    fillColor: function(rowIndex, node, columnIndex) {
      return rowIndex % 2 === 0 ? '#CCCCCC' : 'white'; // Alternating row colors
    },
  },
  headerTable: {
    hLineWidth: (i, node) => {
      return 1;
    },
    vLineWidth: (i) => {
      return 0;
    },
    hLineColor: (i) => {
      return '#aaa';
    },
    paddingLeft: (i) => {
      return 10;
    },
    paddingRight: (i, node) => {
      return 10;
    },
    paddingTop: (i, node) => {
      return 10;
    },
    paddingBottom: (i, node) => {
      return 10;
    },
    fillColor: function(rowIndex, node, columnIndex) {
      return '#CCCCCC';
    },
  },
};

const truncate = (wordToTruncate, limit = 0, forcedCut) => {
  if (wordToTruncate == null) {
    return '';
  }
  if (limit == 0 || wordToTruncate.length < limit + 1) {
    return wordToTruncate;
  } else if (forcedCut) {
    return `${wordToTruncate.slice(0, limit)}`;
  } else if (wordToTruncate.length > limit + 1) {
    return `${wordToTruncate.slice(0, limit - 5)}. . .`;
  }
};

const createPDFProper = (data) => {
  const { interviewDate, department, departmentLogo, candidates } = data;
  const { metaData, MomentDateOfInterview } = interviewDate;
  const { TimeZone, OrganizationName, DepartmentName } = department;

  const rows = [];
  let dateToDisplay = '';
  if (metaData) {
    const { DateOfInterview, StartTime } = metaData;
    dateToDisplay = moment.tz(`${DateOfInterview} ${StartTime}`, null).format('MMM DD, YYYY');
  } else if (MomentDateOfInterview) {
    dateToDisplay = MomentDateOfInterview.format('MMM DD, YYYY');
  }

  const renderCard = (candidate) => {
    const cardRows = [];
    const timeSlotRows = [];
    const { interviewDateMeta } = candidate;
    const { FirstName, LastName } = interviewDateMeta;
    candidate.schedule.forEach((slot) => {
      const { StartTime, EndTime, isPassingPeriod, Users = [] } = slot;
      let roomLabel = '';
      if (Users.length > 0) {
        roomLabel = Users[0].RoomLabel || '';
      } else {
        roomLabel = slot.RoomLabel || '';
      }
      if (isPassingPeriod) {
        return;
      }

      timeSlotRows.push([
        {
          text: StartTime ? moment.tz(StartTime, TimeZone).format('hh:mm A') : moment().format('hh:mm A'),
          margin: [15, 2, 0, 2],
          style: { bold: true, alignment: 'right' },
          fontSize: 12,
        },
        {
          columns: [[determineEvent(slot)]],
        },
        {
          columns: [
            [
              {
                text: roomLabel,
                style: { alignment: 'left' },
                margin: [15, 2, 0, 2],
                fontSize: 12,
              },
            ],
          ],
        },
      ]);
    });

    cardRows.push({
      table: {
        widths: [45, '*', '*'],
        body: [
          [
            [
              {
                image: departmentLogo
                  ? `data:image/png;base64,${departmentLogo}`
                  : `data:image/png;base64,${rezRateLogoBase64}`,
                fit: [50, 50],
              },
            ],
            [
              {
                text: `${FirstName} ${LastName}`,
                style: { alignment: 'left', bold: true },
                margin: [0, 2, 0, 0],
                fillColor: '#CCCCCC',
              },
              {
                text: `${OrganizationName || ''}`,
                style: { alignment: 'left', bold: false },
                fontSize: 10,
                margin: [0, 2, 0, 0],
                // fillColor: '#CCCCCC',
              },
              {
                text: `${DepartmentName || ''}`,
                style: { alignment: 'left', bold: false },
                fontSize: 10,
                margin: [0, 2, 0, 0],
                // fillColor: '#CCCCCC',
              },
            ],
            [
              {
                text: `${dateToDisplay}`,
                style: { alignment: 'right', bold: true },
                margin: [0, 10, 0, 0],
                fillColor: '#CCCCCC',
              },
            ],
          ],
        ],
      },
      layout: 'headerTable',
      margin: [0, 0, 0, 20],
    });

    cardRows.push({
      table: {
        widths: [140, 150, '*'],
        body: timeSlotRows,
      },

      pageBreak: 'after',
      layout: 'customTable',
    });

    return cardRows;
  };

  candidates.forEach((c) => {
    rows.push(renderCard(c));
  });

  let pdfObject = rows;

  // imageToBase64(bgGuide)
  //   .then((bgBase64) => {
  const docDefinition = {
    // background: { image: `data:image/png;base64,${bgBase64}`, width: 612 },
    info: { title: `Candidate Schedule ${dateToDisplay}` },
    pageSize: 'LETTER',
    pageOrientation: 'portrait',
    content: pdfObject,
    defaultStyle: {
      fontSize: 15,
    },
    pageMargins: [0.25 * 72, 0.5 * 72, 0.25 * 72, 72],
  };

  pdfMake.createPdf(docDefinition, tableLayouts).open({}, window.open('', '_blank'));
  // })
  // .catch((err) => {

  // });
};

export const createPDFForCandidateSchedule = ({ interviewDate, department, candidates = [], callback }) => {
  const startProper = () => {
    const candidatesCopy = clone(candidates);
    if (department.DepartmentLogo) {
      imageToBase64(department.DepartmentLogo)
        .then((res) => {
          if (callback) {
            callback();
          }
          createPDFProper({ candidates: candidatesCopy, department, departmentLogo: res, interviewDate });
        })
        .catch((err) => {
          if (callback) {
            callback();
          }
          createPDFProper({ candidates: candidatesCopy, department, interviewDate });
        });
    } else {
      if (callback) {
        callback();
      }
      createPDFProper({ interviewDate, candidates: candidatesCopy, department });
    }
  };

  imageToBase64(rezRateLogo)
    .then((res) => {
      rezRateLogoBase64 = res;
      startProper();
    })
    .catch(() => {
      startProper();
    });
};
