const style = {
  dropDownBody: {
    padding: 10,
  },
  userList: {
    display: 'flex',
    flexDirection: 'column',
    maxHeight: 150,
    minWidth: '100%',
    overflowY: 'scroll',
    marginBottom: 20,
  },
  userItem: {
    paddingTop: 5,
    paddingBottom: 5,
    paddingLeft: 5,
    fontWeight: 600,
  },
  spacedBetweenRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  simpleColumn: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  simpleRow: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
  },
  genButton: {
    backgroundColor: '#0a91ff',
    marginTop: 5,
    // marginBottom: 5,
  },
  roomNumberButtons: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: 20,
  },
  roomNumberButton: {
    width: '30%',
  },
  clickableIcon: {
    cursor: 'pointer',
    marginRight: 5,
  },
  buttonLabel: { width: '100%', textAlign: 'center', fontWeight: 600 },
};
export default style;
