import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Fragment, useState } from 'react';
import { Modal, ModalBody, ModalHeader, UncontrolledAlert, UncontrolledPopover, UncontrolledTooltip } from 'reactstrap';
import styled from 'styled-components';

const Circle = styled.div`
  height: 40px;
  width: 40px;
  display: flex !important;
  align-items: center;
  justify-content: center;
  text-align: center;
  border: 2px solid ${(props) => props.borderColor};
  background-color: ${(props) => props.backgroundColor};
  border-radius: 50%;
  display: inline-block;
`;

const LikertQuestionChoice = ({
  item,
  index,
  postAnswer,
  setSelected,
  selected,
  pk_EvaluationQuestion,
  checkIfStatusNeedsChangingToModify,
  constructCircleClassName,
  questionaireDisabled,
  isMobile,
}) => {
  const [disableButton, setDisableButton] = useState(false);
  const [optionToDetail, setOptionToDetail] = useState(null);
  const [showDetailModal, setShowDetailModal] = useState(false);

  const disableButtonForXSeconds = (num) => {
    setDisableButton(true);
    setTimeout(() => {
      setDisableButton(false);
    }, num * 1000);
  };
  const { QuestionText, Label, Description, DisplayValue } = item;

  return (
    <Fragment key={index}>
      <div className={`flex-center like-box-${index + 1} box-row-1`}>
        <Circle
          style={{ cursor: 'pointer' }}
          tabIndex={questionaireDisabled ? -1 : 0}
          onClick={async () => {
            if (disableButton) {
              return;
            }

            const continueWorkflow = await checkIfStatusNeedsChangingToModify();
            if (continueWorkflow === false) {
              return;
            }
            // if user clicks the same answer we need to deslect it and also prevent them from clicking the same answer again
            if (selected === index) {
              setSelected(null);
              postAnswer(null, pk_EvaluationQuestion);
            } else {
              setSelected(index);
              postAnswer(DisplayValue, pk_EvaluationQuestion);
            }
            disableButtonForXSeconds(1);
          }}
          className={constructCircleClassName(index)}
        >
          {DisplayValue}
        </Circle>
      </div>
      {Label ? (
        <div
          className={`flex-center like-box-${index + 1} box-row-2 bold`}
          style={{ flexDirection: 'row', display: 'flex', textAlign: 'center' }}
        >
          {Label}
        </div>
      ) : null}
      {isMobile ? (
        Description ? (
          <>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
              <FontAwesomeIcon
                icon={faInfoCircle}
                id={`info_${pk_EvaluationQuestion}_${index}`}
                onClick={() => {
                  console.log('clicked');
                  setShowDetailModal(!showDetailModal);
                }}
              />
              <Modal isOpen={showDetailModal} centered style={{ maxHeight: '100vh' }}>
                <ModalHeader
                  toggle={() => {
                    setShowDetailModal(false);
                  }}
                ></ModalHeader>
                <ModalBody style={{ height: '70vh' }}>
                  <div
                    style={{ display: 'flex', flexDirection: 'row', borderBottom: '1px solid gray', marginBottom: 20 }}
                  >
                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', width: '100%' }}>
                      <div
                        className={`flex-center like-box-${index + 1} box-row-1`}
                        style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}
                      >
                        <Circle
                          style={{ cursor: 'pointer' }}
                          tabIndex={questionaireDisabled ? -1 : 0}
                          onClick={async () => {
                            if (disableButton) {
                              return;
                            }

                            const continueWorkflow = await checkIfStatusNeedsChangingToModify();
                            if (continueWorkflow === false) {
                              return;
                            }
                            // if user clicks the same answer we need to deslect it and also prevent them from clicking the same answer again
                            if (selected === index) {
                              setSelected(null);
                              postAnswer(null, pk_EvaluationQuestion);
                            } else {
                              setSelected(index);
                              postAnswer(DisplayValue, pk_EvaluationQuestion);
                            }
                            disableButtonForXSeconds(1);
                          }}
                          className={constructCircleClassName(index)}
                        >
                          {DisplayValue}
                        </Circle>
                      </div>
                      {Label ? (
                        <div
                          className={`flex-center like-box-${index + 1} box-row-2 bold`}
                          style={{ flexDirection: 'row', display: 'flex', textAlign: 'center' }}
                        >
                          {Label}
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      flexWrap: 'wrap',
                      justifyContent: 'center',
                      textAlign: 'center',
                      overflowY: 'scroll',
                      height: '55vh',
                    }}
                  >
                    <div style={{ display: 'flex', flexDirection: 'column', height: 'auto', width: '100%' }}>
                      <p>{Description}</p>
                    </div>
                  </div>
                </ModalBody>
              </Modal>

              {/* <UncontrolledPopover trigger="legacy" target={`info_${pk_EvaluationQuestion}_${index}`}>
              INFO!!
            </UncontrolledPopover> */}
            </div>
          </>
        ) : null
      ) : Description ? (
        <div
          className={`flex-center like-box-${index + 1} box-row-3 ${item}`}
          style={{ overflowWrap: 'anywhere', alignContent: 'center' }}
        >
          {Description}
        </div>
      ) : null}
      {/* {Description ? (
        <div
          className={`flex-center like-box-${index + 1} box-row-3 ${item}`}
          style={{ overflowWrap: 'anywhere', alignContent: 'center' }}
        >
          {Description}
        </div>
      ) : null} */}
    </Fragment>
  );
};

export default LikertQuestionChoice;
