const style = {
  simpleRow: {
    display: 'flex',
    flexDirection: 'row',
  },
  simpleColumn: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  spacedBetween: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  commentContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    marginLeft: 10,
    textAlign: 'left',
    paddingRight: 10,
    paddingLeft: 5,
  },
  evaluatorName: {
    display: 'flex',
    flexDirection: 'row',
    fontWeight: 'bold',
  },

  ratingBar: {
    display: 'flex',
    flexDirection: 'column',
    height: 30,
    marginRight: 3,
    marginTop: 10,
  },
};

export default style;
