import moment from 'moment';
import React from 'react';
import BootstrapTable from 'react-bootstrap-table-next';

import style from './ManageWaitlistDatePicker.style';

const ManageWaitlistDatePicker = ({ interviewDates = [], setSelectedDate, selectedDate, setShowDatePicker }) => {
  const headerFormatter = (column, colIndex) => {
    return <div style={style.headerStyle}>{column.text}</div>;
  };
  const cellFormatter = (cell, row, rowIndex, formatExtraData) => {
    switch (formatExtraData.label) {
      case 'Interview Date':
        return (
          <div style={{ ...style.cellStyle }}>
            <div>
              <div style={style.centeredText}>
                {row.DateOfInterview ? moment(row.DateOfInterview).format('dddd, MMMM DD, YYYY') : ''}
              </div>
              <div style={{ ...style.centeredText, fontSize: 12 }}>{row.Label ? row.Label : ''}</div>
            </div>
          </div>
        );
      case 'Registered':
        return <div style={style.cellStyle}>{row.CountOfCandidates || 0}</div>;
      case 'Waitlisted':
        return <div style={style.cellStyle}>{row.NumberOfWaitlistedCandidates || 0}</div>;
      default:
        return <div style={style.cellStyle}>{row.CandidateRegistrationLimit || 0}</div>;
    }
  };

  const makeColumns = () => {
    const newColumns = [];
    const headers = [
      { text: 'Interview Date', dataField: 'DateOfInterview', width: '60%' },
      { text: 'Registered', dataField: 'CandidateRegistrationLimit' },
      { text: 'Slots', dataField: 'CandidateRegistrationLimit' },
      { text: 'Waitlisted', dataField: 'CandidateRegistrationLimit' },
    ];
    headers.forEach((header) => {
      newColumns.push({
        dataField: header.dataField,
        text: header.text,
        label: header.text,
        formatter: cellFormatter,
        headerFormatter: headerFormatter,
        style: (cell, row, rowIndex, colIndex) => {
          const backgroundColor = selectedDate.pk_InterviewDate === row.pk_InterviewDate ? '#d9f5ff' : null;
          return { verticalAlign: 'middle', width: header.width || 'auto', backgroundColor, cursor: 'pointer' };
        },
        headerStyle: { verticalAlign: 'middle' },
        formatExtraData: { label: header.text },
        events: {
          onClick: (e, column, columnIndex, row, rowIndex) => {
            setSelectedDate(row);
            setShowDatePicker(false);
          },
        },
        headerEvents: {
          onClick: (e, column, columnIndex) => {},
        },
        headerAttrs: {
          // id: 'timeSlotHeader',
        },
        attrs: (cell, row, rowIndex, colIndex) => {
          // return { id: `timeSlot_${row.pk_Timeslot}` };
        },
      });
    });

    return newColumns;
  };

  return (
    <div style={{ height: 400, overflowY: 'scroll' }}>
      <BootstrapTable
        bordered={false}
        columns={makeColumns()}
        data={interviewDates || []}
        keyField="pk_InterviewDate"
      />
    </div>
  );
};

export default ManageWaitlistDatePicker;
