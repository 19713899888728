const style = {
  dropDownBody: {
    minWidth: '100%',
    maxHeight: 250,
    overflowY: 'scroll',
    display: 'flex',
    flexDirection: 'column',
  },
  simpleRow: {
    display: 'flex',
    flexDirection: 'row',
  },
  simpleColumn: {
    display: 'flex',
    flexDirection: 'column',
  },
};

export default style;
