import { Col } from 'reactstrap';
import styled from 'styled-components';

const HeaderStyle = {
  ColumnWrapper: styled(Col)`
    display: flex;
    justify-content: center;
    maxwidth: 100%;
    padding: 0;
  `,
  seasonButton: (activeSeason, selectedSeason) => {
    const s = { width: 250, color: 'white', borderColor: 'white' };
    if (activeSeason && selectedSeason && activeSeason.pk_Season != selectedSeason.pk_Season) {
      s.color = '#ffc180';
      s.borderColor = '#ffc180';
    }
    return s;
  },
  spacedBetweenRow: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  simpleRow: {
    display: 'flex',
    flexDirection: 'row',
  },
  simpleColumn: {
    display: 'flex',
    flexDirection: 'column',
  },
  topBtn: {
    marginLeft: 10,
  },
  hazardIcon: {
    color: '#ffc180',
    fontSize: 17,
    marginRight: 5,
    cursor: 'pointer',
  },
};

export default HeaderStyle;
