import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import { useAuth0 } from '../../../auth0/reactAuth0Spa';
import { formatBearerToken } from '../../../Common.functions';
import { DepartmentContext } from '../../../DepartmentWrapper';
import { fetchDataAgnostic, fetchDataAuth0 } from '../../../Services/dataApi';
import Error from '../../Statuses/Error';
import AdminCandidates from './AdminCandidates/AdminCandidates';
import AdminCommunicationsLanding from './AdminCommunications/AdminCommunicationsLanding';
import AdminEvaluateContainer from './AdminEvaluate/AdminEvaluate.container';
import AdminEvaluationStatus from './AdminEvaluationStatus/AdminEvaluationStatus';
import AdminEvaluationTemplateLanding from './AdminEvaluationTemplate/AdminEvaluationTemplateLanding';
import AdminInterviewDates from './AdminInterviewDates/AdminInterviewDates';
import AdminRankHistoryLanding from './AdminRankHistory/AdminRankHistoryLanding';
import AdminSchedule from './AdminSchedule/AdminSchedule';
import AdminSettings from './AdminSettings/AdminSettings';
import AdminTeam from './AdminTeam/AdminTeam';
import FourOhFour from 'Body/FourOhFour';

const getActiveSeason = (seasons) => {
  let activeSeason = null;

  seasons.forEach((season) => {
    if (season.Active) {
      activeSeason = season;
    }
  });

  // if, for whatever reason, there is no active season default to the first one in the array
  if (!activeSeason) {
    return seasons[0];
  }

  return activeSeason;
};

let fileInputs = document.getElementsByClassName('swal2-file');
const AdminLanding = ({ setSeasonChoices, setActiveSeason, activeSeason, match }) => {
  const [redirectToLogin, setRedirectToLogin] = useState(false);
  const [componentLoading, setComponentLoading] = useState(true);
  const [error, setError] = useState(false);
  const [candidates, setCandidates] = useState([]);
  const [currentInterviewDates, setCurrentInterviewDates] = useState([]);
  const [selectedScheduleItem, setSelectedScheduleItem] = useState(null);
  const { user, isAuthenticated, loginWithRedirect, getTokenSilently, logout } = useAuth0();

  //TODO: make sure it gets department

  const dContext = useContext(DepartmentContext);

  const { department, setDepartment } = dContext;

  useEffect(() => {
    const type = 'administrator';
    if (setDepartment && department && (!department.roleType || department.roleType !== type)) {
      setDepartment((prevState) => {
        // eslint-disable-next-line
        let clone = structuredClone(prevState);
        clone.roleType = type;
        return clone;
      });
    }
  }, [setDepartment, department]);

  const formatThenSetInterviewDates = (array) => {
    const arrayWithMoment = array.map((item) => ({
      ...item,
      MomentDateOfInterview: moment.tz(item.DateOfInterview, null),
    }));
    const sortedArray = arrayWithMoment.sort((a, b) => a.MomentDateOfInterview - b.MomentDateOfInterview);
    setCurrentInterviewDates(sortedArray);
  };

  const getActiveCandidates = (pk_Season, pk_Department, token) => {
    getTokenSilently()
      .then((token) => {
        fetchDataAuth0('candidate', { pk_Department, pk_Season }, formatBearerToken(token))
          .then((result) => {
            if (result.data && result.data.error) {
              setError(true);
            } else {
              setCandidates(result.data);
            }
          })
          .catch((err) => {
            setError(true);
          })
          .finally(() => {
            setComponentLoading(false);
          });
      })
      .catch((err) => {});
  };

  const getActiveInterviewDates = (callback) => {
    getTokenSilently()
      .then((t) => {
        fetchDataAgnostic(
          'department/interviewDates',
          { pk_Department: dContext.department.pk_Department, pk_Season: dContext.season.pk_Season },
          formatBearerToken(t),
        )
          .then((res) => {
            formatThenSetInterviewDates(res.data);
            if (callback) {
              callback();
            }
          })
          .catch((err) => {});
      })
      .catch((err) => {});
  };

  useEffect(() => {
    getActiveInterviewDates();
    if (dContext.department === null || dContext.season == null) {
      setRedirectToLogin(true);
    }
    // else if (dContext.department === null && user) {
    //   getDefaultDepartment();
    // }
  }, []);

  const { path, url } = useRouteMatch();

  if (redirectToLogin || dContext.department === null || dContext.season == null) {
    return <Redirect to="/" />;
  }

  // if user isn't an admin or a superuser they shuoldn't access any of these routes
  if (department && !department.EnableAdmin && !department.EnableSuperUser) {
    return <Redirect to="/" />;
  }
  if (error) {
    // if (componentLoading) {
    //   return <Loading />;
    // }

    return <Error />;
  }

  return (
    <div>
      <Switch>
        <Route
          exact
          path={path}
          render={(props) => (
            <AdminInterviewDates
              {...props}
              dContext={dContext}
              getActiveInterviewDates={getActiveInterviewDates}
              activeDepartment={dContext.department}
              setSelectedScheduleItem={setSelectedScheduleItem}
              candidates={candidates}
              activeSeason={activeSeason}
              currentInterviewDates={currentInterviewDates}
              formatThenSetInterviewDates={formatThenSetInterviewDates}
            />
          )}
        />
        <Route
          path={`${path}/team`}
          render={(props) => <AdminTeam {...props} activeDepartment={dContext.department} />}
        />
        <Route
          path={`${path}/evaluate`}
          render={(props) => <AdminEvaluateContainer {...props} activeDepartment={dContext.department} />}
        />
        <Route
          path={`${path}/schedule/:schedule_id`}
          render={(props) => (
            <AdminSchedule
              {...props}
              activeDepartment={dContext.department}
              selectedScheduleItem={selectedScheduleItem}
              currentInterviewDates={currentInterviewDates}
              formatThenSetInterviewDates={formatThenSetInterviewDates}
            />
          )}
        />
        <Route
          path={`${path}/candidates`}
          render={(props) => (
            <AdminCandidates
              {...props}
              activeDepartment={dContext.department}
              pk_Department={dContext && dContext.department ? dContext.department.pk_Department : null}
              setCandidates={setCandidates}
              detailedCandidates={candidates}
              activeSeason={activeSeason}
              match={match}
              currentInterviewDates={currentInterviewDates}
              formatThenSetInterviewDates={formatThenSetInterviewDates}
            />
          )}
        />
        <Route
          path={`${path}/evaluation_template`}
          render={(props) => (
            <AdminEvaluationTemplateLanding
              {...props}
              candidates={candidates}
              activeDepartment={dContext.department}
              activeSeason={dContext.season}
            />
          )}
        />
        <Route
          path={`${path}/evaluation_status`}
          render={(props) => (
            <AdminEvaluationStatus
              {...props}
              interviewDates={currentInterviewDates}
              activeDepartment={dContext.department}
              activeSeason={dContext.season}
            />
          )}
        />
        <Route
          path={`${path}/communications`}
          render={(props) => (
            <AdminCommunicationsLanding
              {...props}
              candidates={candidates}
              activeDepartment={dContext.department}
              currentInterviewDates={currentInterviewDates}
            />
          )}
        />
        <Route
          path={`${path}/rank`}
          render={(props) => (
            <AdminRankHistoryLanding
              {...props}
              activeDepartment={dContext.department}
              candidates={candidates}
              getActiveCandidates={getActiveCandidates}
            />
          )}
        />
        <Route
          path={`${path}/settings`}
          render={(props) => (
            <AdminSettings
              {...props}
              activeDepartment={dContext.department}
              candidates={candidates}
              getActiveCandidates={getActiveCandidates}
            />
          )}
        />
        <Route render={(props) => <FourOhFour {...props} isAuthenticated={true} />} />
      </Switch>
    </div>
  );
};

export default AdminLanding;
