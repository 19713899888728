import { connect } from 'react-redux';
import UserInformation from './UserInformation';

const mapStateToProps = (state) => {
  const { userInfo } = state.adminUser;
  const { isAdmin } = state.login;
  return { userInfo, isAdmin };
};

export default connect(mapStateToProps)(UserInformation);
