const style = {
  manageFieldsRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'spaced-between',
    alignItems: 'center',
    borderBottom: '1px solid #b6b6b8',
    paddingBottom: 5,
    paddingTop: 5,
  },
  manageFieldsLabel: {
    alignItems: 'center',
    textAlign: 'center',
    width: '100%',
  },
  manageFieldsName: {
    alignItems: 'center',
    textAlign: 'center',
    width: '80%',
    marginRight: 5,
    marginLeft: 5,
    wordWrap: 'normal',
  },
  manageFieldsSortOrder: {
    alignItems: 'center',
    textAlign: 'center',
    width: '20%',
    marginLeft: 5,
  },
};

export default style;
