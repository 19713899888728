import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import arrayMove from 'array-move';
import React, { Fragment, useCallback, useEffect, useState } from 'react';
import Moment from 'react-moment';
import { Col } from 'reactstrap';
import Swal from 'sweetalert2';
import arrow from '../../../assets/images/arrow.png';
import { postDataAgnostic } from '../../../Services/dataApi';
import { ordinal_suffix_of } from '../../../SortableList';
import { CenterRow, CustomTable, TDPadding } from './InterviewDateConfirm.styles';
import CandidateValidatePopup from '../CandidateValidatePopup/CandidateValidatePopup';
import { CustomButton, EvenRow } from './InterviewDateSelection.styles';
import { clone } from 'lodash';

const InterviewDateConfirm = ({
  changeStep,
  clientID,
  dateOfInterview,
  demo,
  getCandidate,
  hasMadeSelection,
  hasPreferenceOrderWithNoInterviewDate,
  headerText,
  interviewDate,
  isScheduled,
  listArray,
  modificationConfirmed,
  scheduledLabel,
  selectedDates,
  setDateList,
  setError,
  setHasInterviewDate,
  setHasNoChoicesToMake,
  setHasPermission,
  setModificationConfirmed,
  setSelectedDates,
  singleDateChoice,
}) => {
  const [isSending, setIsSending] = useState(false);
  const [change, setChange] = useState(false);
  const [accessPinOpen, setAccessPinOpen] = useState(false);
  const [userHasNoPhoto, setUserHasNoPhoto] = useState(false);
  const [displayPhotoLink, setDisplayPhotoLink] = useState(false);

  // TODO: fix label not appearing.

  useEffect(() => {
    //TODO: find a better way to confirm this should be displayed than using the text type
    if (
      hasMadeSelection
      // headerText.includes(`you've been scheduled for the following`) ||
      // headerText.includes('waitlisted for the date')
    ) {
      setDisplayPhotoLink(true);
    } else {
      setDisplayPhotoLink(false);
    }
  }, [headerText]);

  const sendRequest = useCallback(async () => {
    const selectedDates = sortData(listArray);
    const AccessID = localStorage.getItem('AccessID');

    postDataAgnostic('candidate/interviewDates', { clientID, AccessID }, { selectedDates })
      .then((results) => {
        if (results.data.DateLocked && results.data.DateLocked === true) {
          Swal.fire({
            title: 'Date Locked',
            text: `It appears you're already schedule for a date and this date is locked. If this seems like a mistake, please contact your coordinator`,
            icon: 'warning',
          }).then((result) => {
            window.location.reload();
          });
        } else if (results.data.error === false) {
          window.location.reload();
        } else if (results.data.permissions === false) {
          setHasPermission(false);
        } else {
          setError(true);
        }
      })
      .catch((err) => {
        setError(true);
      })
      .finally(() => {
        // setIsSending(false);
      });

    // // once the request is sent, update state again
    // setIsSending(false);
  }, [isSending, listArray]); // update the callback if the state changes

  const test = (changeSlide) => {
    if (changeSlide) {
      changeStep(false, 1);
      setChange(false);
      const listArrayCopy = listArray.slice();
      listArrayCopy.forEach((item) => {
        // item.selected = false;
        // item.preference = null;
      });
      setDateList(listArrayCopy);
      // setDateList((prevDateList) => {
      //   return prevDateList;
      // });
    }
  };

  const executeModificationClick = async () => {
    let changeSlide = false;
    setHasInterviewDate(false);
    // setIsLoading(true);
    setHasNoChoicesToMake(false);
    if (dateOfInterview || hasMadeSelection) {
      changeSlide = true;
      setTimeout(() => {
        getCandidate(true);
      }, 500);
    } else {
      changeStep(false, 1);
    }
  };

  const handleModifyClick = async (e) => {
    e.preventDefault();
    // only prompt them the first time
    if (modificationConfirmed === false) {
      // setModificationConfirmed(true);
      // executeModificationClick();
      Swal.fire({
        title: 'Caution!',
        text:
          'If you have been registered for an interview, making changes to your selection may result in forfeiting your current spot. Are you sure you want to proceed?',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: 'red',
        confirmButtonText: `Continue Modification`,
      }).then((result) => {
        if (result.value) {
          setModificationConfirmed(true);
          executeModificationClick();
        }
      });
    } else {
      executeModificationClick();
    }
  };

  const sortData = (arrayList) => {
    // if there's only one date it won't have a preference order so we need to add one
    // TODO: maybe there's a more elegant way to do this?
    if (arrayList && arrayList.length === 1) {
      arrayList[0].preference = 0;
    }
    const selectedItems = arrayList.filter((item) => item.selected);
    const sortedItems = selectedItems.sort((a, b) => {
      return a.preference - b.preference;
    });
    return sortedItems;
  };

  const simulateSending = () => {
    Swal.fire(
      `Your selection has been submitted (not really)`,
      `If this was a real invitation, the selection would be sent to the corresponding department.`,
    );
  };
  const onSortEnd = ({ oldIndex, newIndex }) => {
    setSelectedDates(arrayMove(selectedDates, oldIndex, newIndex));
  };

  return (
    <Col>
      <CenterRow style={{ padding: '15px' }}>
        {displayPhotoLink && userHasNoPhoto && (
          <h6 style={{ color: 'red' }}>
            There's one more thing you need to do! Upload a photo of yourself by clicking{' '}
            <span>
              <a href={`https://portal.rezrate.com/candidate/photo/${clientID}`} target="_blank">
                here.
              </a>
            </span>
          </h6>
        )}
        <CustomTable>
          <thead>
            <tr>
              <th></th>
              {!dateOfInterview && !singleDateChoice && !isScheduled && <th>Choice</th>}
              <th>Date</th>
              <th></th>
            </tr>
          </thead>
          <tbody style={{}}>
            {dateOfInterview && (
              <tr>
                <TDPadding />
                <td>
                  <div>
                    <Moment format="dddd, MMMM DD, YYYY" utc>
                      {dateOfInterview.DateOfInterview}
                    </Moment>
                  </div>
                  {dateOfInterview.Label && (
                    <span>
                      <br />
                      {dateOfInterview.Label}
                    </span>
                  )}
                </td>
                <TDPadding />
              </tr>
            )}
            {hasMadeSelection &&
              listArray.map((item, i) => {
                return (
                  <tr key={item.pk_interviewDate}>
                    <TDPadding />
                    {!dateOfInterview && <td>{ordinal_suffix_of(i + 1)}</td>}
                    <td>
                      <Moment format="dddd, MMMM DD, YYYY" utc>
                        {item.DateOfInterview}
                      </Moment>
                      <div>{item.Label || ''}</div>
                      {/* {item.Label && (
                        <span>
                          <br />
                          {item.Label}
                        </span>
                      )} */}
                    </td>
                    <TDPadding />
                  </tr>
                );
              })}
            {!hasMadeSelection &&
              sortData(listArray).map((item, i) => {
                return (
                  <tr key={item.pk_interviewDate}>
                    <TDPadding />
                    {!dateOfInterview && !singleDateChoice && <td>{ordinal_suffix_of(i + 1)}</td>}
                    <td>
                      <Moment format="dddd, MMMM DD, YYYY" utc>
                        {item.DateOfInterview}
                      </Moment>
                      {item.Label && (
                        <span>
                          <br />
                          {item.Label}
                        </span>
                      )}
                    </td>
                    <TDPadding />
                  </tr>
                );
              })}
          </tbody>
        </CustomTable>
      </CenterRow>
      {true && (
        <Fragment>
          {sortData(listArray).length === 1 &&
            !dateOfInterview &&
            !hasPreferenceOrderWithNoInterviewDate &&
            !singleDateChoice && (
              <EvenRow style={{ flexDirection: 'column' }}>
                <h4 style={{ color: 'black' }}>
                  You selected only one date. Did you know you can select multiple options?
                </h4>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <p style={{ marginBottom: '5px', color: 'black' }}>
                    Click{' '}
                    <span className="clickable" onClick={(e) => changeStep(e, 1)}>
                      modify
                    </span>{' '}
                    to add more dates
                  </p>
                </div>
              </EvenRow>
            )}
          <EvenRow>
            <div>
              {sortData(listArray).length === 1 &&
                !dateOfInterview &&
                !hasPreferenceOrderWithNoInterviewDate &&
                !singleDateChoice && (
                  <div>
                    <img src={arrow} style={{ width: 'auto', height: '27px', position: 'relative', left: '21px' }} />
                  </div>
                )}
              {/* {dateOfInterview && <div style={{ height: '27px' }} />} */}
              {((interviewDate && !interviewDate.DateLocked) || !dateOfInterview) && (
                <CustomButton color="warning" disabled={isSending} onClick={handleModifyClick}>
                  Modify
                </CustomButton>
              )}
            </div>
            {!dateOfInterview && !hasMadeSelection && (
              <div>
                {sortData(listArray).length === 1 &&
                  !dateOfInterview &&
                  !hasPreferenceOrderWithNoInterviewDate &&
                  !singleDateChoice && <div style={{ height: '27px' }} />}
                {!demo && (
                  <CustomButton
                    disabled={isSending}
                    onClick={() => {
                      setIsSending(true);
                      sendRequest();
                    }}
                    color="success"
                  >
                    {!isSending ? 'Confirm' : <FontAwesomeIcon icon={faSpinner} spin />}
                  </CustomButton>
                )}
                {demo && (
                  <CustomButton disabled={isSending} onClick={simulateSending} color="success">
                    Confirm
                  </CustomButton>
                )}
              </div>
            )}
          </EvenRow>
        </Fragment>
      )}
    </Col>
  );
};

export default InterviewDateConfirm;
