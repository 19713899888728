import moment from 'moment';
import React, { Fragment, useState, useEffect } from 'react';
import Button from 'reactstrap/lib/Button';
import Popover from 'reactstrap/lib/Popover';
import PopoverBody from 'reactstrap/lib/PopoverBody';
import PopoverHeader from 'reactstrap/lib/PopoverHeader';
import { shouldJoinMeetingButtonBeVisisble } from '../../../../Common.functions';
import { createTimeTracker } from '../../../../Common.jsx';
import { clone } from '../../../../Services/schedule';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';

const ScheduleItem = ({
  // showRed,
  chosenCandidate,
  currentTime,
  currentTimeAsMoment,
  date,
  determineEvent,
  determineIcon,
  differences,
  firstBlockEarlyJoin,
  getCandidate,
  getPercentageTime,
  handleIterativeVariables,
  heights,
  i,
  indexesWithDifferences = [],
  interviewIsToday,
  handleDateChange,
  isMobile,
  isPassingPeriod,
  item,
  joinMeetingButton,
  prevItem,
  requestVirtualMeetingEvaluator,
  selectedCandidateIdLocal,
  setChosenCandidate,
  setUserIdOnCurrentInterview,
  tableRowOpacity,
  userIdOnCurrentInterview,
  reduxDate,
  setSelectedTimeSlot,
  selectedTimeSlot,
  setSelectedUser,
}) => {
  // need to check if meeting should be visisble locally so it doesn't extend into additional days
  const [showJoinMeetingLocal, setShowJoinMeetingButtonLocal] = useState(false);
  const [showDateDropdown, setShowDateDropdown] = useState(false);

  const history = useHistory();

  if (item.isRedirect) {
    const { interviewDate = {}, interviewDates = [], type } = item;
    const { CountOfCandidates, Label } = interviewDate;
    const key = `redirect_${item.type}_${interviewDate.pk_InterviewDate}`;

    return (
      <tr id={`tableRow${i}`}>
        <td colSpan={6}>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Button
              id={key}
              color={type == 'same' ? 'secondary' : 'success'}
              onClick={(e) => {
                if (type === 'same') {
                  // setShowDateDropdown(!showDateDropdown);
                  if (interviewDates && interviewDates.length == 1) {
                    if (handleDateChange) {
                      handleDateChange({ target: { value: interviewDates[0].pk_InterviewDate } });
                    } else {
                      const nextDate = interviewDates[0];
                      if (nextDate) {
                        history.push(`/user/evaluate/${nextDate.pk_InterviewDate}`);
                      }
                    }
                  }
                } else {
                  if (handleDateChange) {
                    handleDateChange({ target: { value: interviewDate.pk_InterviewDate } });
                  } else {
                    history.push(`/user/evaluate/${interviewDate.pk_InterviewDate}`);
                  }
                }
              }}
            >
              {type === 'before'
                ? `View previous schedule for today ${Label ? `(${Label})` : ''} ${
                    CountOfCandidates ? `(${CountOfCandidates})` : ''
                  }`
                : null}

              {type === 'after'
                ? `View next schedule for today ${Label ? `(${Label})` : ''} ${
                    CountOfCandidates ? `(${CountOfCandidates})` : ''
                  }`
                : null}

              {type === 'same'
                ? `View alternative schedule for today ${
                    interviewDates.length > 1 ? `(${interviewDates.length} found)` : `${interviewDates[0].Label || ''}`
                  }`
                : null}
            </Button>
          </div>
        </td>
        {item.type === 'same' && item.interviewDates && item.interviewDates.length > 1 ? (
          <Popover
            target={key}
            placement="right"
            flip={false}
            isOpen={showDateDropdown}
            trigger="legacy"
            placementPrefix="focusPopover bs-popover"
            toggle={() => {
              setShowDateDropdown(!showDateDropdown);
            }}
          >
            <PopoverHeader close>Alternative Schedules</PopoverHeader>
            <PopoverBody>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  overflowY: 'scroll',
                  width: '100%',
                  height: '100%',
                }}
              >
                {interviewDates.map((d) => {
                  return (
                    <div
                      style={{
                        textAlign: 'center',
                        justifyContent: 'center',
                        display: 'flex',
                        padding: 5,
                        fontWeight: 600,
                        cursor: 'pointer',
                        borderBottom: '1px solid black',
                      }}
                      onClick={() => {
                        handleDateChange({ target: { value: d.pk_InterviewDate } });
                        setShowDateDropdown(false);
                      }}
                    >
                      {d.Label ? `${d.Label}` : '(Unlabelled Schedule)'}
                    </div>
                  );
                })}
              </div>
            </PopoverBody>
          </Popover>
        ) : null}
      </tr>
    );
  }

  const differenceInTime = differences['Schedules4EvalTime' + i] || differences['Schedules4EvalTimeEnd' + i];

  const differenceInName = differences['Candidates4DateNameFull' + i];
  const joinMeetingButtonVisible = shouldJoinMeetingButtonBeVisisble(item, false, currentTimeAsMoment);

  if (joinMeetingButtonVisible) {
    handleIterativeVariables(item);
    if (showJoinMeetingLocal === false) setShowJoinMeetingButtonLocal(true);
  } else {
    if (showJoinMeetingLocal === true) setShowJoinMeetingButtonLocal(false);
  }

  const { pk_Timeslot, pk_ScheduleBlock, StartTime } = item;

  const uniqueId = `${pk_Timeslot}_${pk_ScheduleBlock}_${StartTime}`;
  const selectedUniqueID = `${selectedTimeSlot ? selectedTimeSlot.pk_Timeslot : ''}_${
    selectedTimeSlot ? selectedTimeSlot.pk_ScheduleBlock : ''
  }_${selectedTimeSlot ? selectedTimeSlot.StartTime : ''}`;

  const chosenScheduleItem = uniqueId === selectedUniqueID;

  let difference;
  let color = null;
  let coverBorder = null;
  // date.InterviewDatesVLDate
  const activeDate = date ? date : reduxDate ? reduxDate : {};
  const stringDate = activeDate.InterviewDatesVLDate + ' ' + item.Schedules4PortalTime;
  const numberDate = Date.parse(stringDate);

  difference = getPercentageTime(
    currentTimeAsMoment.valueOf(),
    tableRowOpacity,
    item.StartTimeAsMoment.valueOf(),
    item.EndTimeAsMoment.valueOf(),
  );

  if (difference.height !== 0) {
    color = '#0C7489';
    coverBorder = '1px solid #0c7489';
  } else {
    color = '#FCDC4D';
    coverBorder = 'none';
  }

  let borderTop = 'auto';

  let fontWeight = 500;

  if (i === 0) {
    borderTop = 'none';
  }

  if (difference.height !== 0 && difference.height !== '100%') {
    fontWeight = 700;
  }

  const showHighlight = indexesWithDifferences.includes(i);

  return (
    /* eslint-disable */
    <Fragment>
      <tr
        key={uniqueId}
        // style={{ border: '2px solid red' }}
        className={item.isPassingPeriod ? 'stripes passing_wrapper' : 'hover_blue'}
        onClick={() => {
          if (item.isPassingPeriod) return;

          // right now we should only bring up a candidate if there is a candidate and there is only one candidate (flex events could have multiple candidates)
          if (item && item.Candidates && item.Candidates[0] && item.Candidates.length === 1) {
            if (isMobile && item.Candidates[0].pk_Candidate === selectedCandidateIdLocal) {
              changeToCandidateTab();
            }
            getCandidate(item.Candidates[0].pk_Candidate);
            setChosenCandidate(uniqueId);
          } else {
            setChosenCandidate(null);
            if (setSelectedUser) {
              setSelectedUser(null);
            }

            // setSelectedTimeSlot(null);
          }
          setSelectedTimeSlot(item);
        }}
        style={{
          cursor: 'pointer',
          backgroundColor: showHighlight ? '#c4fcbb' : chosenScheduleItem ? '#d4ebf2' : item.color,
          borderRadius: showHighlight ? 25 : null,
        }}
      >
        {/* <td style={{ ...borderTop, position: 'relative', top: '-13px' }}> */}
        {interviewIsToday && heights.length > 0 && (
          <div style={{ position: 'relative', top: '-13px' }}>
            <div>{createTimeTracker(heights, i, difference, color, coverBorder, true)}</div>
          </div>
        )}
        {/* </td> */}
        <td
          style={{
            borderTop,
            paddingTop: item.isPassingPeriod ? '0' : 'auto',
            paddingBottom: item.isPassingPeriod ? '0' : 'auto',
          }}
        >
          <div style={{ height: '100%', display: 'flex', alignItems: 'center' }}>
            <div className={'red_staging ' + (false ? 'red' : '')}>
              <h5 style={{ fontWeight, margin: 0, color: '#000' }}>
                {/* {item.StartTimeAsMoment} */}
                {item.StartTimeAsMoment && item.StartTimeAsMoment.format
                  ? item.StartTimeAsMoment.format('h:mm A')
                  : `${item.pk_Timeslot}`}
              </h5>
            </div>
          </div>
        </td>
        <td style={{ borderTop, paddingBottom: '12px' }} id={'tableRow' + i}>
          {!isMobile && <div>{determineIcon(item)}</div>}
        </td>
        <td style={{ borderTop }}>
          <div style={{ height: '100%', display: 'flex', alignItems: 'center' }}>
            <div className={'red_staging ' + (false ? 'red' : '')}>{determineEvent(item)}</div>
          </div>
        </td>
        <td></td>
        {/* if it's mobile have to to combine rows */}
        <td
          style={{
            cursor: 'pointer',
            backgroundColor: chosenScheduleItem && !item.isPassingPeriod ? '#d4ebf2' : '',
          }}
        >
          {/* {shouldJoinMeetingButtonBeVisisble(item, null, currentTimeAsMoment)} */}
          {joinMeetingButton(joinMeetingButtonVisible, interviewIsToday, item)}
        </td>
      </tr>
    </Fragment>
    /* eslint-enable */
  );
};

export default ScheduleItem;
