import React from 'react';
import { Form, FormGroup, Label, Input, Col } from 'reactstrap';

const SeasonCreationAndEditWidget = ({ seasonName, setSeasonName }) => {
  return (
    <Form>
      <FormGroup row>
        <Label for="departmentAlias" sm={2}>
          Name:
        </Label>
        <Col sm={10}>
          <Input type="text" name="websiteText" value={seasonName} onChange={(e) => setSeasonName(e.target.value)} />
        </Col>
      </FormGroup>
    </Form>
  );
};

export default SeasonCreationAndEditWidget;
