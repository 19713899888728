import { faArrowsAltV, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import arrayMove from 'array-move';
import React, { useEffect, useState } from 'react';
import { sortableContainer, sortableElement, sortableHandle } from 'react-sortable-hoc';
import AdminRanks from './AdminRanks';
import { GridContextProvider, GridDropZone, GridItem, move, swap } from 'react-grid-dnd';
import { clone } from 'lodash';

const DragHandle = sortableHandle((props) => {
  const { sliderValue, backgroundColor, pk_Candidate } = props;
  const fontSizes = [15, 15, 30, 40];

  return (
    <div
      id={`dragHandle_${pk_Candidate}`}
      style={{
        display: 'flex',
        width: 60,
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        marginRight: 10,
        backgroundColor,
        cursor: 'grab',
      }}
      // onDrag={(e) => {
      //   e.target.style.cursor = 'grabbing';
      // }}
      // onDragEnd={(e) => {
      //   e.target.style.cursor = 'grab';
      // }}
      // onMouseEnter={(e) => {
      //   e.target.backgroundColor = 'red';
      // }}
      // onMouseLeave={(e) => {
      //   e.target.backgroundColor = 'white';
      // }}
    >
      <FontAwesomeIcon
        icon={faArrowsAltV}
        style={{
          fontSize: fontSizes[sliderValue],
        }}
      />
    </div>
  );
});

const SortableItem = sortableElement((props) => {
  const {
    i,
    candidates,
    setCandidateRanks,
    setSelectedUser,
    candidate,
    sliderValue,
    postChange,
    selectedUser,
    isFetchingCandidates,
    isCollapsed,
    unique_key,
    setLastJumpTo,
    detailNotesHaveChange,
    viewMode,
  } = props;

  const { pk_Candidate } = candidate;
  return (
    <AdminRanks
      detailNotesHaveChange={detailNotesHaveChange}
      candidate={candidate}
      candidates={candidates}
      DragHandle={DragHandle}
      i={i}
      isFetchingCandidates={isFetchingCandidates}
      key={`key_${pk_Candidate}_${i}_${unique_key}`}
      postChange={postChange}
      selectedUser={selectedUser}
      setCandidateRanks={setCandidateRanks}
      setSelectedUser={setSelectedUser}
      sliderValue={sliderValue}
      unique_key={unique_key}
      setLastJumpTo={setLastJumpTo}
      viewMode={viewMode}
    />
  );

  return (
    <AdminRanks
      selectedUser={selectedUser}
      sliderValue={sliderValue}
      candidates={candidates}
      setCandidateRanks={setCandidateRanks}
      setSelectedUser={setSelectedUser}
      DragHandle={DragHandle}
      postChange={postChange}
      candidate={candidate}
      i={i}
      isFetchingCandidates={isFetchingCandidates}
    />
  );
  // <li>
  //   <DragHandle />
  //   {value.sortOrder}
  // </li>
});

const SortableContainer = sortableContainer(({ children, isCollapsed }) => {
  return <tbody style={{ visibility: isCollapsed ? 'collapse' : null }}>{children}</tbody>;
});

const AdminRanksSort = ({
  candidates,
  setCandidateRanks,
  setSelectedUser,
  sliderValue,
  postChange,
  selectedUser,
  isFetchingCandidates,
  isCollapsed,
  unique_key,
  setLastJumpTo,
  detailNotesHaveChange,
  viewMode,
}) => {
  const [candidatesToDisplay, setCandidatesToDisplay] = useState(candidates || []);
  const [items, setItems] = useState({ candidates: candidatesToDisplay });
  const [gridRowItemCount, setGridRowItemCount] = useState(4);
  const [isMouseDown, setIsMouseDown] = useState(false);
  const [scrollY, setScrollY] = useState(0);

  useEffect(() => {
    document.addEventListener('mousedown', (event) => {
      const rankListContainer = document.getElementById('rankList_grid');
      const clickedItem = event.target;
      if (
        rankListContainer &&
        clickedItem &&
        clickedItem.id !== 'rankList_grid' &&
        rankListContainer.contains(clickedItem)
      ) {
        setScrollY(rankListContainer.scrollTop);
        rankListContainer.top = rankListContainer.scrollTop;
        setIsMouseDown(true);
      }
    });

    document.addEventListener('mouseup', (event) => {
      const rankListContainer = document.getElementById('rankList_grid');
      setIsMouseDown(false);
      // setScrollY(0);
    });
  }, []);

  useEffect(() => {
    setCandidatesToDisplay(candidates || []);
    setItems({ candidates: candidates });
  }, [candidates]);

  useEffect(() => {
    // setGridRowItemCount(4 - sliderValue);
    switch (sliderValue) {
      case 1:
        setGridRowItemCount(6);
        break;
      case 2:
        setGridRowItemCount(4);
        break;
      case 3:
        setGridRowItemCount(3);
        break;
    }
  }, [sliderValue]);

  const onSortEnd = ({ oldIndex, newIndex }) => {
    if (oldIndex == newIndex) {
      return;
    }
    let newRanks = null;
    const candidate = candidates[oldIndex];
    const { FirstName, LastName } = candidate;
    const ranks = arrayMove(candidates, oldIndex, newIndex);
    newRanks = ranks;
    setLastJumpTo(oldIndex + 1);
    setSelectedUser(candidate, true);
    setCandidateRanks(newRanks);
    postChange(
      newRanks,
      candidates,
      `${FirstName} ${LastName} moved from position ${oldIndex + 1} to position ${newIndex + 1}`,
      candidate.pk_Candidate,
      oldIndex,
      Number(newIndex) - 1,
    );
  };

  const onChange = (sourceId, sourceIndex, targetIndex, targetId) => {
    if (!sourceId) {
      return;
    }
    // if (targetId) {
    //   const result = move(items[sourceId], items[targetId], sourceIndex, targetIndex);
    //   return setCandidatesToDisplay({
    //     ...items,
    //     [sourceId]: result[0],
    //     [targetId]: result[1],
    //   });
    // }

    let sanitizedTargetIndex = targetIndex;
    let sourceList = items[sourceId];

    if (sanitizedTargetIndex == sourceIndex && (targetId == null || targetId == sourceId)) {
      return;
    }

    if (sanitizedTargetIndex > sourceList.length - 1) {
      sanitizedTargetIndex = sourceList.length - 1;
    } else if (sanitizedTargetIndex < 0) {
      sanitizedTargetIndex = 0;
    }

    const candidate = items[sourceId][sourceIndex];
    const newList = swap(items[sourceId], sourceIndex, sanitizedTargetIndex);

    const newItems = clone(items);
    const { FirstName, LastName } = candidate;
    newItems[sourceId] = newList;
    setItems(newItems);
    setLastJumpTo(sourceIndex + 1);
    setSelectedUser(candidate, true);
    setCandidateRanks(newList);
    postChange(
      newList,
      candidates,
      `${FirstName} ${LastName} moved from position ${sourceIndex + 1} to position ${sanitizedTargetIndex + 1}`,
      candidate.pk_Candidate,
      sourceIndex,
      Number(targetIndex) - 1,
    );
  };

  if (viewMode === 'Grid') {
    let rowHeightAddition = 0;
    switch (sliderValue) {
      case 1:
        rowHeightAddition = 30;
        break;
      case 2:
        rowHeightAddition = 15;
        break;
      case 3:
        rowHeightAddition = 20;
        break;
    }

    return (
      <div
        id={'rankList_grid'}
        onScroll={(e) => {
          const rankListContainer = document.getElementById('rankList_grid');

          e.preventDefault();
          if (rankListContainer && isMouseDown) {
            console.log('Preventing scrolling e.deltaY: ', e.deltaY);
            rankListContainer.scrollTop = -e.deltaY;
            e.preventDefault();
          }
        }}
        style={{
          width: '100%',
          minHeight: 510,
          height: 'calc(100vh - 400px)',
          overflowY: isMouseDown ? 'hidden' : 'scroll',
          oveflowX: null,
          // position: isMouseDown ? 'fixed' : null,
          // top: isMouseDown ? scrollY : null,
        }}
      >
        <GridContextProvider onChange={onChange}>
          <GridDropZone
            className="dropzone left"
            id="candidates"
            boxesPerRow={gridRowItemCount}
            rowHeight={90 + rowHeightAddition}
            style={{ minHeight: 100 * Math.ceil(items.candidates.length / gridRowItemCount) }}
          >
            {items.candidates.map((item, i) => {
              const { pk_Candidate } = item;
              return (
                <GridItem
                  key={`key_${item.pk_Candidate}_${item.FirstName}_${item.LastName}`}
                  style={{ marginRight: (i + 1) % gridRowItemCount == 0 ? 30 : 0 }}
                >
                  <div
                    className="grid-item"
                    style={{
                      padding: 10,
                      width: `100%`,
                      height: ' 100%',
                      boxSizing: 'border-box',
                      alignItems: 'bottom',
                    }}
                  >
                    <div
                      style={{
                        width: '100%',
                        height: '100%',
                        boxSizing: 'border-box',
                        backgroundColor: selectedUser && selectedUser.pk_Candidate == pk_Candidate ? '#d9f5ff' : '#ccc',
                        display: 'flex',
                        justifyContent: 'center',
                        color: 'white',
                        alignItems: 'bottom',
                        borderRadius: 15,

                        border: '1px solid #ccc',
                        borderRadius: 5,
                      }}
                    >
                      <AdminRanks
                        detailNotesHaveChange={detailNotesHaveChange}
                        candidate={item}
                        candidates={candidates}
                        DragHandle={DragHandle}
                        i={i}
                        isFetchingCandidates={isFetchingCandidates}
                        key={`key_${pk_Candidate}_${i}_${unique_key}`}
                        postChange={postChange}
                        selectedUser={selectedUser}
                        setCandidateRanks={setCandidateRanks}
                        setSelectedUser={setSelectedUser}
                        sliderValue={sliderValue}
                        unique_key={unique_key}
                        setLastJumpTo={setLastJumpTo}
                        viewMode={viewMode}
                      />
                    </div>
                  </div>
                </GridItem>
              );
            })}
          </GridDropZone>
        </GridContextProvider>
      </div>
    );
  } else {
    return (
      <SortableContainer onSortEnd={onSortEnd} useDragHandle isCollapsed={isCollapsed}>
        {candidates.map((candidate, index) => {
          const { pk_Candidate, Tags } = candidate;

          return (
            <SortableItem
              detailNotesHaveChange={detailNotesHaveChange}
              candidate={candidate}
              candidates={candidates}
              DragHandle={DragHandle}
              i={index}
              index={index}
              isFetchingCandidates={isFetchingCandidates}
              key={`key_${pk_Candidate}_${index}`}
              postChange={postChange}
              selectedUser={selectedUser}
              setCandidateRanks={setCandidateRanks}
              setSelectedUser={setSelectedUser}
              sliderValue={sliderValue}
              unique_key={unique_key}
              setLastJumpTo={setLastJumpTo}
              viewMode={viewMode}
            />
          );

          return (
            <AdminRanks
              candidate={candidate}
              candidates={candidates}
              DragHandle={DragHandle}
              i={index}
              isFetchingCandidates={isFetchingCandidates}
              key={`key_${pk_Candidate}_${index}`}
              postChange={postChange}
              selectedUser={selectedUser}
              setCandidateRanks={setCandidateRanks}
              setSelectedUser={setSelectedUser}
              sliderValue={sliderValue}
              unique_key={unique_key}
            />
          );

          return (
            <SortableItem
              candidate={candidate}
              candidates={candidates}
              i={index}
              index={index}
              isFetchingCandidates={isFetchingCandidates}
              key={`key_${pk_Candidate}_${index}`}
              postChange={postChange}
              selectedUser={selectedUser}
              setCandidateRanks={setCandidateRanks}
              setSelectedUser={setSelectedUser}
              sliderValue={sliderValue}
            />
          );
        })}
      </SortableContainer>
    );
  }
};

export default AdminRanksSort;
