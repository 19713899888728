import React from 'react';

import { faPencilAlt, faPortrait } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import style from './style.js';
import { useHistory } from 'react-router-dom';

const EvaluatorDropDownItem = ({ evaluator, index, candidateInteviewCount, candidates, ignoreValidity }) => {
  const { UserPhotoUrl } = evaluator;
  const history = useHistory();
  return (
    <div
      style={{
        ...style.itemRow,
        backgroundColor: index % 2 == 0 ? '#cad0db' : null,
      }}
    >
      <div style={{ ...style.simpleColumn, width: '100% ' }}>
        <div style={{ ...style.spacedBetweenRow, alignItems: 'center' }}>
          <div style={{ ...style.simpleColumn, width: '100%' }}>
            <div style={{ ...style.simpleRow, alignItems: 'flex-start' }}>
              <div style={style.simpleColumn}>
                {UserPhotoUrl ? (
                  <img src={UserPhotoUrl} style={style.imgPlaceHolder} />
                ) : (
                  <FontAwesomeIcon style={style.imgPlaceHolder} icon={faPortrait} />
                )}
              </div>
              <div style={style.info}>
                <div style={{ ...style.simpleRow, fontWeight: 'bold', textAlign: 'left', alignItems: 'flex-start' }}>
                  {evaluator
                    ? `${evaluator.UserLast ? `${evaluator.UserLast}, ` : ''}${evaluator.UserFirst || ''}`
                    : ''}
                </div>
                {ignoreValidity ? null : (
                  <div style={{ ...style.simpleRow, fontSize: 11, textAlign: 'left' }}>
                    Candidates to interview: {candidateInteviewCount} / {Object.values(candidates).length}
                  </div>
                )}
              </div>
            </div>
          </div>

          {ignoreValidity ? null : (
            <div style={style.info}>
              <FontAwesomeIcon
                icon={faPencilAlt}
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  const pathExploded = history.location.pathname.split('/');
                  const scheduleID = pathExploded[pathExploded.length - 1];

                  //NEW ONE!
                  window.open(
                    `/administrator/evaluate?pk_InterviewDate=${scheduleID}&pk_User=${evaluator.pk_User}`,
                    '_blank',
                  );
                  // OLD!
                  // window.open(`/evaluate?pk_User=${evaluator.pk_User}`, '_blank');
                }}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default EvaluatorDropDownItem;
