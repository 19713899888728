import { faCheckSquare, faSquare } from '@fortawesome/free-regular-svg-icons';
import { faTag } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import style from './style';

const TagItem = ({ tag, selectedTags = [], oldTags = [], index, onClickTag }) => {
  const isInSelectedTags = selectedTags.find((selectedTag) => {
    return selectedTag.Tag == tag.Tag;
  });

  const isInOldTags = oldTags.find((selectedTag) => {
    return selectedTag.Tag == tag.Tag;
  });

  const isNewTag = isInSelectedTags && !isInOldTags;
  const isRemovedTag = !isInSelectedTags && isInOldTags;
  let backgroundColor = index % 2 == 0 ? '#dee2e6' : null;

  if (isNewTag) {
    backgroundColor = '#88ff82';
  } else if (isRemovedTag) {
    backgroundColor = '#f77777';
  } else if (isInSelectedTags) {
    backgroundColor = '#d9f5ff';
  }

  return (
    <div
      style={{ ...style.listItem, backgroundColor }}
      onClick={() => {
        if (onClickTag) {
          onClickTag(tag, isInSelectedTags);
        }
      }}
    >
      <div>
        <FontAwesomeIcon icon={faTag} />
      </div>
      <div style={{ width: '100%', textAlign: 'left', marginLeft: 10 }}>{tag.Tag}</div>{' '}
      <div>
        <FontAwesomeIcon icon={isInSelectedTags ? faCheckSquare : faSquare} />
      </div>
    </div>
  );
};

export default TagItem;
