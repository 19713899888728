const style = {
  mainContainer: {
    marginTop: 10,
    padding: 20,
    height: '45vh',
    overflowY: 'scroll',
    border: '1px solid #c7cbd1',
  },
  title: {
    textAlign: 'center',
    fontWeight: 'bold',
  },
  itemRow: {
    minHeight: 80,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: 15,
  },
  imgPlaceHolder: {
    fontSize: 70,
    marginRight: 10,
    width: 80,
  },
  candidateInfo: {
    textAlign: 'left',
    fontWeight: 600,
    // fontSize: 12,
  },
  buttonIcon: {
    cursor: 'pointer',
    margin: 5,
  },
  simpleRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    verticalAlign: 'middle',
  },
  simpleColumn: {
    display: 'flex',
    flexDirection: 'column',
  },
  candidateName: {
    textAlign: 'left',
    fontWeight: 'bold',
    fontSize: 15,
  },
  medicalSchool: {
    textAlign: 'left',
    fontSize: 12,
  },
  spacedBetweenRow: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'spaced-between',
  },
  clickable: {
    cursor: 'pointer',
  },
};

export default style;
