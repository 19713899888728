import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Fragment, useState, useEffect } from 'react';
import { Button, Popover, PopoverBody } from 'reactstrap';
import style from './SortOrderSelector.style';
import { scrollToElement } from 'Common.functions';
const SortOrderSelector = ({
  rowIndex,
  itemChoices,
  onSortChange,
  unique_key,
  backgroundColor = 'white',
  fontSize,
}) => {
  const [showSortPopover, setShowSortPopover] = useState();

  useEffect(() => {
    if (showSortPopover) {
      scrollToElement(`sort_order_selector_${unique_key}_${rowIndex}`, { behavior: 'instant', block: 'center' });
    }
  }, [showSortPopover]);

  return (
    <div style={{ backgroundColor }}>
      <Button
        outline
        color="secondary"
        size="sm"
        id={`sort_order_selector_${unique_key}`}
        style={{ width: 80 }}
        onClick={(e) => {
          e.stopPropagation();

          if (!showSortPopover) {
            scrollToElement(`sort_order_selector_${unique_key}_${rowIndex}`, { behavior: 'instant', block: 'center' });
          }

          setShowSortPopover(!showSortPopover);
        }}
      >
        <div style={{ ...style.simpleRow, justifyContent: 'space-between', cursor: 'pointer' }}>
          <div style={{ ...style.simpleColumn, fontSize: fontSize || 14, textAlign: 'center', width: '100%' }}>
            {rowIndex + 1}
          </div>

          <div style={{ ...style.simpleColumn, justifyContent: 'center', alignItems: 'center', paddingLeft: 10 }}>
            <FontAwesomeIcon icon={faChevronDown} />
          </div>
        </div>
        <Popover
          isOpen={showSortPopover}
          target={`sort_order_selector_${unique_key}`}
          trigger="legacy"
          placement="right"
          toggle={() => {
            if (showSortPopover) {
              setShowSortPopover(false);
            }
          }}
        >
          <PopoverBody style={{ width: 80, maxHeight: 140, minHeight: 80 }}>
            <div
              style={{
                ...style.simpleRow,
                maxHeight: 130,
                minHeight: 90,
                overflowY: 'scroll',
              }}
            >
              <div style={{ ...style.simpleColumn, width: '100%' }}>
                {itemChoices.map((c, optionIndex) => {
                  return (
                    <div
                      id={`sort_order_selector_${unique_key}_${optionIndex}`}
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        backgroundColor: optionIndex == rowIndex ? '#d9f5ff' : 'white',
                        width: '100%',
                        justifyContent: 'center',
                        alignItems: 'center',
                        fontSize: 17,
                        cursor: 'pointer',
                      }}
                      onClick={(e) => {
                        e.stopPropagation();
                        if (onSortChange) {
                          onSortChange({ target: { value: optionIndex + 1 } });
                        } else {
                          // console.log('No onSortChange assigned!');
                        }
                      }}
                    >
                      {optionIndex + 1}
                    </div>
                  );
                })}
              </div>
            </div>
          </PopoverBody>
        </Popover>
      </Button>
    </div>
  );
};

export default SortOrderSelector;
