const style = {
  itemRow: {
    minHeight: 80,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: 15,
    cursor: 'pointer',
  },
  spacedBetweenRow: {
    flexDirection: 'row',
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
  filterButton: {
    width: '100%',
    marginTop: 10,
  },
  imgPlaceHolder: {
    fontSize: 50,
    marginRight: 10,
    width: 40,
  },
  candidateInfo: {
    textAlign: 'left',
    fontWeight: 600,
    width: '100%',
  },
  buttonIcon: {
    cursor: 'pointer',
    margin: 5,
  },
  simpleRow: {
    display: 'flex',
    flexDirection: 'row',
  },
  simpleColumn: {
    display: 'flex',
    flexDirection: 'column',
  },
  userListContainer: {
    marginTop: 10,
    height: 'calc(100vh - 430px)',
    overflowY: 'scroll',
    width: '100%',
  },
  userListItem: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: 5,
    borderBottom: '1px solid gray ',
  },
  filterChoiceButton: {
    width: '100%',
    marginTop: 10,
  },
  centeredText: {
    width: '100%',
    textAlign: 'center',
  },
};

export default style;
