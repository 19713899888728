import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState, useRef } from 'react';
import { Input, Label, FormGroup, Button } from 'reactstrap';
import { clone } from '../../../../../../Services/schedule.js';
import style from './style.js';

const AdditionalDetails = ({
  selectedCandidate,
  saveResults,
  isSaving,
  isGettingCandidateDetails,
  isUpdatingCandidateList,
  setChanges,
  changes,
  resetFunctions,
  getCandidateDetails,
  abortController,
}) => {
  const [schedulingNotes, setSchedulingNotes] = useState('');
  const [discussionNotes, setDiscussionNotes] = useState('');
  const dataReference = useRef(); // We use a dataReference useRef object because passing discardChanges as a function to resetFunctions can't get the curr

  useEffect(() => {
    if (resetFunctions) {
      if (resetFunctions.current == null) {
        resetFunctions.current = {};
      }
      if (resetFunctions.current.additionalDetails == null) {
        resetFunctions.current.additionalDetails = discardChanges;
      }
    }
  }, [resetFunctions]);

  useEffect(() => {
    if (selectedCandidate) {
      updateValues(selectedCandidate);
    }
  }, [JSON.stringify(selectedCandidate)]);

  useEffect(() => {
    checkChanges();
  }, [schedulingNotes, discussionNotes]);

  const updateValues = (newCandidate) => {
    const { DiscussionNotes, SchedulingNotes } = newCandidate;
    dataReference.current = { SchedulingNotes, DiscussionNotes };

    checkChanges(newCandidate);

    setSchedulingNotes(SchedulingNotes);
    setDiscussionNotes(DiscussionNotes);
  };

  const discardChanges = () => {
    const { DiscussionNotes, SchedulingNotes } = dataReference.current;

    setSchedulingNotes(SchedulingNotes);
    setDiscussionNotes(DiscussionNotes);
  };

  const saveChanges = () => {
    const { pk_Candidate } = selectedCandidate;
    saveResults({
      candidatesToUpdate: [pk_Candidate],
      fieldsToUpdate: { SchedulingNotes: schedulingNotes, DiscussionNotes: discussionNotes },
      forcedSelectedCandidateId: pk_Candidate,
      callback: (success) => {
        if (success) {
          getCandidateDetails(selectedCandidate, {
            callback: (newCandidate) => {
              updateValues(newCandidate);
            },
          });
        }
      },
    });
  };

  const checkChanges = (forcedItemToCheck) => {
    const { DiscussionNotes = '', SchedulingNotes = '' } = dataReference.current || {};
    let hasChange = false;
    const rdNotes = DiscussionNotes ? DiscussionNotes : '';
    const rsNotes = SchedulingNotes ? SchedulingNotes : '';
    const dNotes =
      forcedItemToCheck && forcedItemToCheck.DiscussionNotes
        ? forcedItemToCheck.DiscussionNotes
        : discussionNotes || '';
    const sNotes =
      forcedItemToCheck && forcedItemToCheck.SchedulingNotes
        ? forcedItemToCheck.SchedulingNotes
        : schedulingNotes || '';

    // console.log('dataReference.current: ', clone(dataReference.current));
    if (rdNotes !== dNotes || rsNotes !== sNotes) {
      hasChange = true;
    }
    // console.log('checkChanges comparing: ', `${rsNotes} vs ${sNotes}\n`);
    // console.log('hasChange: ', hasChange);
    const newChanges = clone(changes);
    newChanges.additionalDetails = hasChange;
    // console.log('newChanges: ', newChanges);
    setChanges(newChanges);
  };

  return (
    <div style={style.mainContainer}>
      {/* <div style={style.simpleRow}>
        <Input type="select">
          {markOptions.map((m) => {
            return <option value={m}>{m}</option>;
          })}
        </Input>
      </div> */}
      <div style={{ ...style.simpleRow, justifyContent: 'center' }}>
        <Button
          disabled={!changes.additionalDetails || isSaving || isGettingCandidateDetails || isUpdatingCandidateList}
          style={{ width: 100 }}
          color="success"
          onClick={() => {
            saveChanges();
          }}
        >
          Save
        </Button>
      </div>
      <div style={style.simpleRow}>
        <div style={style.simpleColumn}>
          <FormGroup>
            <Label for="scheduling">Scheduling Notes</Label>
            <Input
              disabled={isSaving || isGettingCandidateDetails || isUpdatingCandidateList}
              value={schedulingNotes || ''}
              type="textarea"
              name="scheduling"
              id="scheduling"
              placeholder="Scheduling Notes"
              rows="10"
              onChange={(e) => {
                if (!isSaving && !isGettingCandidateDetails && !isUpdatingCandidateList) {
                  setSchedulingNotes(e.target.value);
                  // handleChange('SchedulingNotes', e.target.value);
                }
              }}
            />
          </FormGroup>
        </div>
        <div style={style.simpleColumn}>
          <FormGroup>
            <Label for="discussion">Discussion Notes</Label>
            <Input
              disabled={isSaving || isGettingCandidateDetails || isUpdatingCandidateList}
              value={discussionNotes || ''}
              type="textarea"
              name="discussion"
              id="discussion"
              placeholder="Discussion Notes"
              rows="10"
              onChange={(e) => {
                if (!isSaving && !isGettingCandidateDetails && !isUpdatingCandidateList) {
                  setDiscussionNotes(e.target.value);
                  // handleChange('DiscussionNotes', e.target.value);
                }
              }}
            />
          </FormGroup>
        </div>
      </div>
    </div>
  );
};

export default AdditionalDetails;
