import React, { useEffect } from 'react';
import { postDataAgnostic } from '../../../../../Services/dataApi';
import { Button, Card, CardBody, CardHeader } from 'reactstrap';
import { faPortrait } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const testApiCall = [
  {
    email: 'example@test.com',
    timestamp: 1617990919,
    'smtp-id': '<14c5d75ce93.dfd.64b469@ismtpd-555>',
    event: 'processed',
    category: ['cat facts'],
    sg_event_id: 'BtPPMlYMnUKNbofWQ2Cw0g==',
    sg_message_id: '14c5d75ce93.dfd.64b469.filter0001.16648.5515E0B88.0',
  },
  {
    email: 'example@test.com',
    timestamp: 1617990919,
    'smtp-id': '<14c5d75ce93.dfd.64b469@ismtpd-555>',
    event: 'deferred',
    category: ['cat facts'],
    sg_event_id: 'G1P40WENSk17qK3RNig2kA==',
    sg_message_id: '14c5d75ce93.dfd.64b469.filter0001.16648.5515E0B88.0',
    response: '400 try again later',
    attempt: '5',
  },
  {
    email: 'example@test.com',
    timestamp: 1617990919,
    'smtp-id': '<14c5d75ce93.dfd.64b469@ismtpd-555>',
    event: 'delivered',
    category: ['cat facts'],
    sg_event_id: 'bbYYeJ5FriFCdB7lcWVu2A==',
    sg_message_id: '14c5d75ce93.dfd.64b469.filter0001.16648.5515E0B88.0',
    response: '250 OK',
  },
  {
    email: 'example@test.com',
    timestamp: 1617990919,
    'smtp-id': '<14c5d75ce93.dfd.64b469@ismtpd-555>',
    event: 'open',
    category: ['cat facts'],
    sg_event_id: 'f8n27pu_Nxa1YIP5vYL-OA==',
    sg_message_id: '14c5d75ce93.dfd.64b469.filter0001.16648.5515E0B88.0',
    useragent: 'Mozilla/4.0 (compatible; MSIE 6.1; Windows XP; .NET CLR 1.1.4322; .NET CLR 2.0.50727)',
    ip: '255.255.255.255',
  },
  {
    email: 'example@test.com',
    timestamp: 1617990919,
    'smtp-id': '<14c5d75ce93.dfd.64b469@ismtpd-555>',
    event: 'click',
    category: ['cat facts'],
    sg_event_id: '1YGVI6UUNFbZhmg2nx4kmg==',
    sg_message_id: '14c5d75ce93.dfd.64b469.filter0001.16648.5515E0B88.0',
    useragent: 'Mozilla/4.0 (compatible; MSIE 6.1; Windows XP; .NET CLR 1.1.4322; .NET CLR 2.0.50727)',
    ip: '255.255.255.255',
    url: 'http://www.sendgrid.com/',
  },
  {
    email: 'example@test.com',
    timestamp: 1617990919,
    'smtp-id': '<14c5d75ce93.dfd.64b469@ismtpd-555>',
    event: 'bounce',
    category: ['cat facts'],
    sg_event_id: 'FNQlFErF4bKZoahNytBowQ==',
    sg_message_id: '14c5d75ce93.dfd.64b469.filter0001.16648.5515E0B88.0',
    reason: '500 unknown recipient',
    status: '5.0.0',
  },
  {
    email: 'example@test.com',
    timestamp: 1617990919,
    'smtp-id': '<14c5d75ce93.dfd.64b469@ismtpd-555>',
    event: 'dropped',
    category: ['cat facts'],
    sg_event_id: 'vaNm6qLZjszJEnRxOF4SJA==',
    sg_message_id: '14c5d75ce93.dfd.64b469.filter0001.16648.5515E0B88.0',
    reason: 'Bounced Address',
    status: '5.0.0',
  },
  {
    email: 'example@test.com',
    timestamp: 1617990919,
    'smtp-id': '<14c5d75ce93.dfd.64b469@ismtpd-555>',
    event: 'spamreport',
    category: ['cat facts'],
    sg_event_id: 'k-6EDyL-blFNzB4CPeZoTQ==',
    sg_message_id: '14c5d75ce93.dfd.64b469.filter0001.16648.5515E0B88.0',
  },
  {
    email: 'example@test.com',
    timestamp: 1617990919,
    'smtp-id': '<14c5d75ce93.dfd.64b469@ismtpd-555>',
    event: 'unsubscribe',
    category: ['cat facts'],
    sg_event_id: 'RWNouSDFZ9NuklVLROFWRw==',
    sg_message_id: '14c5d75ce93.dfd.64b469.filter0001.16648.5515E0B88.0',
  },
  {
    email: 'example@test.com',
    timestamp: 1617990919,
    'smtp-id': '<14c5d75ce93.dfd.64b469@ismtpd-555>',
    event: 'group_unsubscribe',
    category: ['cat facts'],
    sg_event_id: '9mOdv4x1CSFNJK-JiFID3w==',
    sg_message_id: '14c5d75ce93.dfd.64b469.filter0001.16648.5515E0B88.0',
    useragent: 'Mozilla/4.0 (compatible; MSIE 6.1; Windows XP; .NET CLR 1.1.4322; .NET CLR 2.0.50727)',
    ip: '255.255.255.255',
    url: 'http://www.sendgrid.com/',
    asm_group_id: 10,
  },
  {
    email: 'example@test.com',
    timestamp: 1617990919,
    'smtp-id': '<14c5d75ce93.dfd.64b469@ismtpd-555>',
    event: 'group_resubscribe',
    category: ['cat facts'],
    sg_event_id: 'aBh5AyxL08p-_gVnzX4d6A==',
    sg_message_id: '14c5d75ce93.dfd.64b469.filter0001.16648.5515E0B88.0',
    useragent: 'Mozilla/4.0 (compatible; MSIE 6.1; Windows XP; .NET CLR 1.1.4322; .NET CLR 2.0.50727)',
    ip: '255.255.255.255',
    url: 'http://www.sendgrid.com/',
    asm_group_id: 10,
  },
];

const AdminRankRemovedCandidates = ({
  removedCandidates,
  addCandidateToRank,
  getActiveCandidates,
  setOpenRemovedCandidatesList,
}) => {
  return (
    <div>
      <Card>
        <CardHeader>
          <div style={{ fontSize: 18, textAlign: 'center' }}>
            {removedCandidates.length} Excluded Candidate{removedCandidates.length > 1 ? 's' : ''}
          </div>
        </CardHeader>
        <CardBody style={{ padding: 0 }}>
          <div style={{ height: '80vh', minHeight: 600, overflowY: 'scroll', width: '100%' }}>
            <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
              {removedCandidates.map((candidate, i) => {
                const { PhotoUrl, FirstName, LastName, CandidateSubHeading, EvalPosition } = candidate;

                return (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      paddingTop: 10,
                      paddingBottom: 10,
                      backgroundColor: i % 2 == 0 ? 'white' : '#ebe9e8',
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: 80,
                        height: 80,
                        marginRight: 5,
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      {PhotoUrl ? (
                        <img
                          class="rounded-circle"
                          alt="140x140"
                          style={{ width: '80px', height: '80px' }}
                          src={PhotoUrl}
                          data-holder-rendered="true"
                        />
                      ) : (
                        <FontAwesomeIcon icon={faPortrait} style={{ fontSize: 80 }} />
                      )}
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'left',
                        width: '100%',
                        textAlign: 'left',
                      }}
                    >
                      <div style={{ fontSize: 15, fontWeight: 'bold' }}>
                        {FirstName || ' '} {LastName || ''}
                      </div>
                      <div style={{ fontSize: 12 }}>{CandidateSubHeading}</div>
                      <div style={{ fontSize: 12 }}>
                        <b>Removed from position {EvalPosition}.</b>
                      </div>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: 180,
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <Button
                        color="primary"
                        size="sm"
                        outline
                        style={{ width: 180 }}
                        onClick={(e) => {
                          e.preventDefault();
                          addCandidateToRank(candidate);
                          setOpenRemovedCandidatesList(false);
                        }}
                        class="btn btn-sm btn-outline-primary"
                      >
                        Return To Rank List
                      </Button>
                    </div>
                    {/* <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                      <div style={{ display: 'flex', flexDirection: 'row' }}></div>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                      <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <button
                          onClick={(e) => {
                            e.preventDefault();
                            addCandidateToRank(candidate);
                            setOpenRemovedCandidatesList(false);
                          }}
                          class="btn btn-sm btn-outline-primary"
                        >
                          <i class="fas fa-share fa-flip-horizontal"></i>&nbsp;Return To Rank List
                        </button>
                      </div>
                    </div>
                  </div> */}
                  </div>
                );
              })}
            </div>
          </div>
        </CardBody>
      </Card>
    </div>
  );
  return (
    <div style={{ height: '50vh' }}>
      <div class="card text-center p-0">
        <div class="card-header p-2">
          <h4 class="m-0">
            {removedCandidates.length} Excluded Candidate{removedCandidates.length > 1 ? 's' : ''}
          </h4>
        </div>
        <div class="card-body p-0" style={{ overflowY: 'scroll', height: '100%' }}>
          <table class="table table-striped m-0">
            {removedCandidates.map((candidate) => {
              const { PhotoUrl, FirstName, LastName, HigherEducationInstitution, EvalPosition } = candidate;
              return (
                <tr>
                  <td class="text-center" style={{ verticalAlign: 'middle' }}>
                    <img
                      class="rounded-circle"
                      alt="140x140"
                      style={{ width: '80px', height: '80px' }}
                      src={PhotoUrl}
                      data-holder-rendered="true"
                    />
                  </td>
                  <td class="text-left" style={{ verticalAlign: 'middle' }}>
                    <h5>
                      {FirstName || ' '} {LastName || ''}
                    </h5>
                    <h6>{HigherEducationInstitution}</h6>
                    <p style={{ margin: '0px', fontSize: '10pt', fontWeight: 'normal' }}>
                      {/* [Wednesday, March 19, 2021, 6:15:03 pm] Removed from position 19 */}
                      Removed from position {EvalPosition}.
                    </p>
                  </td>
                  <td class="text-left" style={{ verticalAlign: 'middle' }}>
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        addCandidateToRank(candidate);
                        setOpenRemovedCandidatesList(false);
                      }}
                      class="btn btn-sm btn-outline-primary"
                    >
                      <i class="fas fa-share fa-flip-horizontal"></i>&nbsp;Return To Rank List
                    </button>
                  </td>
                </tr>
              );
            })}
          </table>
        </div>
      </div>
    </div>
  );
};

export default AdminRankRemovedCandidates;
