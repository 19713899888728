import React from 'react';

const AdminRankSettings = () => {
  return (
    <div class="container-fluid">
      <div class="card text-center">
        <div class="card-header p-2">
          <h4 class="m-0">Rank Configuration Settings</h4>
        </div>
        <div class="card-body" style={{ padding: '0px' }}>
          <div class="row" style={{ padding: '10px' }}>
            <div class="col">
              <button class="btn btn-success fas fa-toggle-on" alt="Click to hide final scores">
                &nbsp;Final scores are visible
              </button>
              {/* <!-- when toggled off, use a-toggle-off for icon and text should read 'Final scores are hidden' --> */}
            </div>
          </div>
          <div class="row" style={{ padding: '10px' }}>
            <div class="col">
              <button class="btn btn-success far fa-list-alt" alt="Click to customize export fields">
                &nbsp;Customize export fields
              </button>
            </div>
          </div>
          <div class="row" style={{ padding: '10px' }}>
            <div class="col">
              <button class="btn btn-warning fas fa-lock-open" alt="Cliick to lock evaluations">
                &nbsp;Evaluations are unlocked
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminRankSettings;
