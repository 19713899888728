const style = {
  candidateName: {
    fontSize: 16,
    // fontWeight: 'bold',
  },
  candidateSchool: {
    fontSize: 12,
    flexDirection: 'row',
  },
  spacedBetweenRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  simpleColumn: {
    display: 'flex',
    flexDirection: 'column',
  },
  simpleRow: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
  },
  flagItem: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: 50,
  },
};

export default style;
