import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Button } from 'reactstrap';
import { faSquare } from '@fortawesome/free-regular-svg-icons';
import { faCheckSquare } from '@fortawesome/free-solid-svg-icons';
import style from './style';

const AddColumnPopover = ({
  evaluators = [
    { pk_User: 1, UserFirst: 'Tuwad', UserLast: 'Tuwadero' },
    { pk_User: 2, UserFirst: 'Dodo', UserLast: 'Bernal' },
    { pk_User: 3, UserFirst: 'Raigor', UserLast: 'Stonehoof' },
  ],
  onAddColumn,
  isAddingColumn,
  onAddColumns,
  onAddFlexColumn,
  onRemoveFlexColumn,
  hasFlexColumn,
  headers,
  setShowAddColumnPopover,
}) => {
  const [selectedEvaluators, setSelectedEvaluators] = useState([]);

  const availableEvaluators = { ...evaluators };
  let headerEvaluators = [];

  if (headers) {
    headers.forEach((header) => {
      if (header.Evaluators && header.Evaluators.Evaluators) {
        headerEvaluators = [...headerEvaluators, ...header.Evaluators.Evaluators];
      }
    });
  }

  headerEvaluators.forEach((e) => {
    delete availableEvaluators[e.pk_User];
  });

  const nonDeletedEvaluators = Object.values(availableEvaluators)
    .filter((e) => {
      if (e) {
        return !e.Deleted;
      } else {
        return false;
      }
    })
    .sort((a, b) => {
      if (a.UserLast > b.UserLast) {
        return 1;
      } else if (a.UserLast < b.UserLast) {
        return -1;
      } else {
        return 0;
      }
    });

  return (
    <div>
      <div style={style.label}>
        {Object.keys(availableEvaluators).length > 0 ? 'Add Evaluators to Column' : 'No Available Evaluators'}
      </div>
      <div style={style.userList}>
        {nonDeletedEvaluators.map((evaluator, index) => {
          if (evaluator.Deleted) {
            return;
          }
          let backgroundColor = index % 2 == 0 ? '#c7cbd1' : null;
          const isSelected = selectedEvaluators.indexOf(evaluator.pk_User) >= 0;
          if (isSelected) {
            backgroundColor = '#81cf7a';
          }
          return (
            <div
              key={evaluator.pk_User}
              style={{ ...style.spacedBetweenRow, ...style.userItem, backgroundColor: backgroundColor }}
              onClick={() => {
                const newSelectedEvaluators = [...selectedEvaluators];
                if (isSelected) {
                  const indexToDelete = selectedEvaluators.indexOf(evaluator.pk_User);

                  newSelectedEvaluators.splice(indexToDelete, 1);
                } else {
                  newSelectedEvaluators.push(evaluator.pk_User);
                }
                setSelectedEvaluators(newSelectedEvaluators);
              }}
            >
              <FontAwesomeIcon icon={isSelected ? faCheckSquare : faSquare} />
              <div style={style.label}>
                {evaluator.UserLast ? `${evaluator.UserLast}, ` : ''}
                {evaluator.UserFirst || ''}
              </div>
            </div>
          );
        })}
      </div>

      {Object.keys(availableEvaluators).length <= 0 ? <div style={style.label}> Add Evaluators Link here! </div> : null}
      <br />

      {selectedEvaluators.length < 2 ? (
        <div style={style.simpleRow}>
          <Button
            disabled={isAddingColumn || selectedEvaluators.length == 0}
            onClick={() => {
              if (onAddColumn) {
                onAddColumn({ evaluatorList: selectedEvaluators });
              }
            }}
            color={selectedEvaluators.length > 0 ? 'success' : null}
            style={{ ...style.genButton, width: '100%' }}
          >
            <div style={style.label}>Add Column</div>
          </Button>
          {/* {selectedEvaluators.length == 0 && !hasFlexColumn ? (
            <Button
              disabled={isAddingColumn}
              color="primary"
              onClick={() => {
                if (onAddFlexColumn) {
                  onAddFlexColumn();
                  setShowAddColumnPopover(false);
                }
              }}
              style={{ ...style.genButton, width: '50%', backgroundColor: null }}
            >
              <div style={style.label}>Add Flex Column</div>
            </Button>
          ) : null} */}
        </div>
      ) : (
        <>
          <div style={style.simpleRow}>
            <Button
              disabled={isAddingColumn || selectedEvaluators.length == 0}
              onClick={() => {
                if (onAddColumn) {
                  onAddColumn({ evaluatorList: selectedEvaluators });
                }
              }}
              style={{ ...style.genButton, width: '50%' }}
            >
              <div style={style.label}>Add Grouped</div>
            </Button>
            <Button
              disabled={isAddingColumn}
              color="success"
              onClick={() => {
                if (onAddColumns) {
                  onAddColumns({ evaluatorList: selectedEvaluators });
                }
              }}
              style={{ ...style.genButton, width: '50%', backgroundColor: null }}
            >
              <div style={style.label}>Add Separate</div>
            </Button>
          </div>
        </>
      )}

      <div style={style.simpleRow}>
        <Button
          disabled={isAddingColumn}
          color="danger"
          onClick={() => {
            if (setShowAddColumnPopover) {
              setShowAddColumnPopover(false);
            }
          }}
          style={{ ...style.genButton, backgroundColor: null }}
        >
          <div style={style.label}>Cancel</div>
        </Button>
      </div>
    </div>
  );
};

export default AddColumnPopover;
