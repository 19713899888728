const style = {
  simpleRow: {
    display: 'flex',
    flexDirection: 'row',
  },
  simpleColumn: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  actionBtn: {
    marginRight: 5,
  },
  spacedBetweenRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'spaced-between',
    alignItems: 'center',
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'spaced-between',
    alignItems: 'center',
    marginBottom: 5,
    padding: 5,
    cursor: 'pointer',
  },
};
export default style;
