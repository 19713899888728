import React, { useState, useEffect } from 'react';
import { Col, Button, Form, FormGroup, Label, Input, FormText, Table, InputGroup, InputGroupAddon } from 'reactstrap';
import { fetchDataAgnostic, postDataAgnostic } from '../../../Services/dataApi';
import { useAuth0 } from '../../../auth0/reactAuth0Spa';
import Swal from 'sweetalert2';
import { formatBearerToken } from '../../../Common.functions';
import { NotificationManager } from 'react-notifications';
import Style from '../EntityWrapper.style';
import Popup from 'reactjs-popup';
import OrganizationListItem from './OrganizationListItem';
import FontAwesome from 'react-fontawesome';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faTimes } from '@fortawesome/free-solid-svg-icons';

let typingTimeout = null;
const OrganizationList = ({
  setSelectedOrganization,
  selectedOrganization,
  setOrganizations,
  addNewOrganizationToList,
  organizations,
  updateOrganizationArrayValues,
}) => {
  const [orgPopoverOpen, setOrgPopoverOpen] = useState(false);
  const [newOrgPopup, setNewOrgPopup] = useState(false);
  const [organizationName, setOrganizationName] = useState('');
  const [organizationHeaderName, setOrganizationHeaderName] = useState('');
  const [organizationShortName, setOrganizationShortName] = useState('');
  const [organizationWebsite, setOrganizationWebsite] = useState('');
  const [pk_Organization, setPk_Organization] = useState(null);
  const [filterBy, setFilterBy] = useState('');
  const [filteredOrganizations, setFilteredOrganizations] = useState([]);

  const { getTokenSilently } = useAuth0();

  const addNewOrganization = (e) => {
    getTokenSilently().then((token) => {
      postDataAgnostic(
        'organization',
        {},
        {
          OrganizationName: organizationName,
          OrganizationShortName: organizationShortName,
          OrganizationWebsite: organizationWebsite,
        },
        formatBearerToken(token),
      )
        .then((result) => {
          addNewOrganizationToList(result.data);
          setPk_Organization(result.data.pk_Organization);
          setNewOrgPopup(false);
          Swal.fire('Success!', 'Organization Created', 'success');
        })
        .catch((err) => {});
    });
  };

  const changeOrganization = (e, pk_Organization) => {
    e.preventDefault();
    if (pk_Organization === selectedOrganization) return setSelectedOrganization(null);

    return setSelectedOrganization(pk_Organization);
  };

  useEffect(() => {
    getTokenSilently()
      .then((token) => {
        fetchDataAgnostic('organization/all', null, formatBearerToken(token))
          .then((result) => {
            setOrganizations(result.data);
          })
          .catch((err) => {});
      })
      .catch((err) => {});
  }, []);

  useEffect(() => {
    if (!filterBy) {
      setFilteredOrganizations(organizations);
    } else {
      setFilteredOrganizations(
        organizations.filter((org) => {
          return org.OrganizationName.toLowerCase().includes(filterBy.toLowerCase());
        }),
      );
    }
  }, [filterBy, organizations]);

  return (
    <div className="admin_popover" style={{ height: 'calc(100vh - 260px)' }}>
      <div style={Style.headerWrapper}>Organizations</div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          paddingTop: 5,
          paddingBottom: 5,
          paddingRight: 2,
          paddingLeft: 2,
        }}
      >
        <InputGroup>
          <InputGroupAddon addonType="prepend">
            <Button color="secondary" style={{ cursor: 'normal' }}>
              <FontAwesomeIcon icon={faSearch} />
            </Button>
          </InputGroupAddon>
          <Input
            // value={filterBy}
            id={`searchOrganizations`}
            type="text"
            placeholder="Search Organizations. . ."
            onChange={(e) => {
              clearTimeout(typingTimeout);
              const newValue = e.target.value;
              typingTimeout = setTimeout(() => {
                setFilterBy(newValue);
              }, 300);
            }}
          />
          <InputGroupAddon addonType="append">
            <Button
              color="secondary"
              onClick={() => {
                setFilterBy('');
                document.getElementById('searchOrganizations').value = '';
              }}
            >
              <FontAwesomeIcon icon={faTimes} />
            </Button>
          </InputGroupAddon>
        </InputGroup>
      </div>
      <Popup
        className="max_width_500"
        closeOnDocumentClick={true}
        open={newOrgPopup}
        onClose={() => {
          setNewOrgPopup(false);
          setOrganizationName('');
          setOrganizationShortName('');
          setOrganizationWebsite('');
        }}
      >
        <div style={{ padding: '15px' }}>
          <div className="flex-center">
            <div className="header">Add New Organization</div>
          </div>
          <Form>
            <FormGroup row>
              <Label for="organizationName" sm={2}>
                Name:
              </Label>
              <Col sm={10}>
                <Input
                  type="text"
                  name="organizationName"
                  value={organizationName}
                  onChange={(e) => setOrganizationName(e.target.value)}
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for="organizationShortName" sm={2}>
                Alias:
              </Label>
              <Col sm={10}>
                <Input
                  type="text"
                  name="organizationShortName"
                  value={organizationShortName}
                  onChange={(e) => setOrganizationShortName(e.target.value)}
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for="organizationWebsite" sm={2}>
                Domain:
              </Label>
              <Col sm={10}>
                <Input
                  type="text"
                  name="organizationWebsite"
                  value={organizationWebsite}
                  onChange={(e) => setOrganizationWebsite(e.target.value)}
                />
              </Col>
            </FormGroup>
            <Button
              color="success"
              onClick={(e) => {
                e.preventDefault();
                addNewOrganization();
              }}
            >
              Save
            </Button>
          </Form>
        </div>
      </Popup>
      <div className="overflowy_scroll" style={Style.scroll}>
        <Table className="admin_table">
          <tbody>
            {filteredOrganizations
              .sort((a, b) => {
                const stringA = a.OrganizationName ? a.OrganizationName.toLowerCase() : '';
                const stringB = b.OrganizationName ? b.OrganizationName.toLowerCase() : '';

                if (stringA < stringB) {
                  return -1;
                }
                if (stringA > stringB) {
                  return 1;
                }
                return 0;
              })
              .map((organization, i) => {
                return (
                  <tr
                    className={
                      'hover_blue' + (selectedOrganization === organization.pk_Organization ? ' blue_selected' : '')
                    }
                    onClick={(e) => {
                      changeOrganization(e, organization.pk_Organization);
                    }}
                  >
                    <OrganizationListItem
                      key={organization.pk_Organization}
                      {...organization}
                      setOrganizations={setOrganizations}
                      setSelectedOrganization={setSelectedOrganization}
                      updateOrganizationArrayValues={updateOrganizationArrayValues}
                      selectedOrganization={selectedOrganization}
                      orgPopoverOpen={orgPopoverOpen}
                      setOrgPopoverOpen={setOrgPopoverOpen}
                    />
                  </tr>
                );
              })}
          </tbody>
        </Table>
      </div>
      <div className="flex-center">
        <Button
          color="success"
          onClick={(e) => {
            e.preventDefault();
            setNewOrgPopup(true);
          }}
        >
          Add New Organization
        </Button>
      </div>
    </div>
  );
};

export default OrganizationList;
