import React, { useEffect } from 'react';
import { formatBearerToken } from '../../../Common.functions';
import { fetchData } from '../../../Services/dataApi';
import { useAuth0 } from '../../reactAuth0Spa';

const formatSettings = (arr) => {
  const rv = {};
  for (var i = 0; i < arr.length; ++i) {
    const current = arr[i];
    const isSettingOnForSite = current.CanBeOverriddenByDepartment
      ? current.departmentSettingIsOn !== null
        ? current.departmentSettingIsOn
        : current.organizationSettingIsOn
      : current.organizationSettingIsOn;
    rv[current['pk_Setting']] = { isSettingOnForSite, ...current };
  }
  return rv;
};

const Settings = ({ user, children, userDepartment, setSettings }) => {
  const { getTokenSilently } = useAuth0();
  useEffect(() => {
    // if userDepartment is not array this means it's a single object and the user has either chosen a department
    // or only belongs to a signle department
    if (userDepartment && !Array.isArray(userDepartment)) {
      getTokenSilently()
        .then((token) => {
          fetchData(
            '/settings',
            {
              organization: userDepartment.pk_Organization,
              department: userDepartment.pk_Department,
            },
            formatBearerToken(token),
          )
            .then((res) => {
              setSettings(formatSettings(res.data));
            })
            .catch((err) => {});
        })
        .catch((err) => {});
    }
  }, [userDepartment]);

  return <div style={{ display: 'flex', width: '100%', height: '100%' }}>{children}</div>;
};

export default Settings;
