import React, { useCallback, useEffect, useRef, useState } from 'react';
import ReactCSSTransitionReplace from 'react-css-transition-replace';
import { useLocation } from 'react-router-dom';
import { Button } from 'reactstrap';
import RezRate from '../assets/images/header/rezRate.gif';
import { sizes } from '../Body/Common.style';
import { isDev, useEventListener, urlWithHttpProtocol } from '../Common.functions';
import { ImageWrapper, TextWrapper, Wrapper } from './header.styles';
import BellSound from '../assets/sounds/shipbell.wav';
import { useAlert } from 'react-alert';
import NotificationTest from 'Body/RezRATE/Common/NotificationTest/NotificationTest';
const headerText = 'RezRATE';
const subHeaderText = '';

// necessary for mobile.  Logo scales smaller on phone landscape
const initialImgWidth = window.innerWidth <= sizes.phoneLandscape ? 50 : 100;

const Header = ({ user = {}, breakUrl, showNotifTester = true }) => {
  const [logo, setLogo] = useState(RezRate);
  const imgSize = useRef(null);
  const [userState, setUserState] = useState(null);
  const [loading, setLoading] = useState(true);
  const [imgWidth, setImgWidth] = useState(initialImgWidth);
  const [notifPermission, setNotifPermission] = useState('default');

  const location = useLocation();
  const alert = useAlert();

  // const { DepartmentLogo, DepartmentName, DepartmentPhone, DepartmentWebsite, FirstName, LastName } = user;

  // use the default header text until the api return header information
  const organization =
    user && user.OrganizationName
      ? `${user.DepartmentName ? user.DepartmentName : ''} ${
          user.OrganizationName ? 'at ' + user.OrganizationName : ''
        }`
      : headerText;

  const name =
    user && user.FirstName
      ? `${user.FirstName ? user.FirstName : ''} ${user.LastName ? user.LastName : ''}`
      : subHeaderText;

  // Sets image size based off of ref.  Ref can not be used with listener in current state
  const getAndSetImgSize = () => {
    // display: none doesn't adjust the image to the container immediately, so calculate the value which must be multiplied by height
    // to make it 100px, and multiply this by width to get the correct width
    const convertHeightTo100 = initialImgWidth / imgSize.current.height;
    if (imgSize && imgSize.current && imgSize.current.width) {
      setImgWidth(imgSize.current.width * convertHeightTo100);
      setLoading(false);
    }
  };

  const handler = useCallback((e) => {
    // can't use ref due to event listener
    const x = document.getElementsByClassName('logo');
    if (x[0] && x[0].width) {
      setImgWidth(x[0].width);
    }
  });

  useEventListener('resize', handler);

  const hasLogo = user && user.DepartmentLogo;

  return (
    <div>
      <Wrapper imgWidth={imgWidth} style={{ paddingLeft: '20px' }}>
        {/* switches out default logo with user's logo when it's done loading into the browser.  Child key must be unique to either element */}
        <ReactCSSTransitionReplace
          transitionName="cross-fade"
          transitionEnterTimeout={1000}
          transitionLeaveTimeout={1000}
        >
          <ImageWrapper className="ImageWrapper" key={loading}>
            {hasLogo && (
              <img
                className="logo"
                alt="department logo"
                ref={imgSize}
                src={user.DepartmentLogo}
                style={loading ? { display: 'none', height: '100px', width: '100px' } : {}}
                onLoad={() => getAndSetImgSize()}
              />
            )}
            {!hasLogo && <img ref={imgSize} src={logo} alt="rezrate logo" />}
          </ImageWrapper>
        </ReactCSSTransitionReplace>
        <ReactCSSTransitionReplace
          transitionName="cross-fade"
          transitionEnterTimeout={1000}
          transitionLeaveTimeout={1000}
        >
          <TextWrapper className="no-text-decoration" key={organization}>
            <h2 className="no-text-decoration">
              {organization}
              <span style={{ color: 'red' }}>{isDev() === 1 ? 'DEV' : ''}</span>
            </h2>
            <div
              className="browser_display"
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                flexDirection: 'row',
                alignItems: 'flex-end',
              }}
            >
              {name && <h4>{name}</h4>}

              <div style={{ display: 'flex' }}>
                {user && user.DepartmentName && user.DepartmentWebsite && (
                  <Button
                    onClick={(e) => {
                      e.preventDefault();
                      window.open(urlWithHttpProtocol(user.DepartmentWebsite));
                    }}
                    style={{ marginRight: '5px', whiteSpace: 'none' }}
                    color="primary"
                    size="sm"
                  >
                    Our Website
                  </Button>
                )}
                {breakUrl && breakUrl.url && breakUrl.url !== '' && breakUrl.buttonText && (
                  <Button
                    onClick={(e) => {
                      e.preventDefault();
                      window.open(breakUrl.url);
                    }}
                    style={{ marginRight: '5px', whiteSpace: 'none' }}
                    color="warning"
                    size="sm"
                  >
                    {breakUrl.buttonText}
                  </Button>
                )}

                {showNotifTester ? <NotificationTest size="sm" /> : null}
              </div>
            </div>
          </TextWrapper>
        </ReactCSSTransitionReplace>
      </Wrapper>

      <div className="mobile_display">
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'column',
          }}
        >
          {name && <h4>{name}</h4>}

          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            {user && user.DepartmentName && user.DepartmentWebsite && (
              <Button
                size="sm"
                onClick={(e) => {
                  e.preventDefault();
                  window.open(urlWithHttpProtocol(user.DepartmentWebsite));
                }}
                style={{ whiteSpace: 'none' }}
                color="primary"
              >
                Our Website
              </Button>
            )}
            {breakUrl && breakUrl.url && breakUrl.url !== '' && breakUrl.buttonText && (
              <Button
                size="sm"
                onClick={(e) => {
                  e.preventDefault();
                  window.open(breakUrl.url);
                }}
                style={{ whiteSpace: 'none' }}
                color="warning"
              >
                {breakUrl.buttonText}
              </Button>
            )}
            {showNotifTester ? <NotificationTest size="sm" /> : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
