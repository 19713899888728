import { combineReducers } from 'redux';
import AuthReducer from './auth0/AuthWrapper.reducer';
import AnimationReducer from './Body/Animation/ListAnimation.reducer';
import InterviewDatesRouterReducer from './Body/InterviewDates/InterviewDatesRouter.reducer';
import LoginReducer from './Body/Login/Login.reducer';
import DepartmentReducer from './Body/RezRATE/Body/Department/Department.reducer';
import HeaderReducer from './Body/RezRATE/Header/Header.reducer';
import EvaluatorReducer from './Evaluator.reducer';
import UserInformationReducer from './Body/RezRATE/Header/UserInformation/UserInformation.reducer';

export default combineReducers({
  userInfo: InterviewDatesRouterReducer,
  animation: AnimationReducer,
  login: LoginReducer,
  evaluator: EvaluatorReducer,
  header: HeaderReducer,
  adminUser: UserInformationReducer,
  auth: AuthReducer,
  department: DepartmentReducer,
});
