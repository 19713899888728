import { faInfo, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import React, { Fragment, useEffect, useState } from 'react';
import ReactCSSTransitionReplace from 'react-css-transition-replace';
import { Button, Form, FormGroup, Input, Label } from 'reactstrap';
import Swal from 'sweetalert2';
import Bubble from '../../assets/images/Bubble.png';
import { determineCredentials, isDev } from '../../Common.functions';
import './Demo.styles.css';

const collapsed = {
  width: '40px',
  height: '40px',
  left: 'calc(100% - 56px)',
  top: '15px',
  padding: '14px 14px 14px 14px',
  backgroundColor: 'rgb(51,122,183)',
  borderRadius: '50px 50px 50px 50px',
  borderStyle: 'solid',
  borderWidth: '3px 3px 3px 3px',
  borderColor: '#ffffff',
};

const expanded = {
  left: '50vw',
  top: '15vh',
  width: '40px',
  height: '40px',
  padding: '14px 14px 14px 14px',
  borderRadius: '50px 50px 50px 50px',
  borderStyle: 'solid',
  borderWidth: '3px 3px 3px 3px',
  borderColor: 'rgb(51,122,183)',
};

const expandedDisplay = {
  borderRadius: '0px 0px 0px 0px',
  width: '40em',
  height: 'auto',
  padding: '15px 30px 15px 30px',
  borderWidth: '0px',
  transform: 'translateX(-50%)',
  backgroundColor: '#D1495B',
  maxWidth: '95%',
};

// Displays a sign up page for user whe on the demo page

const Demo = () => {
  const [expand, setExpand] = useState(false);
  const [displayGreeting, setDisplayGreeting] = useState(false);
  const [alreadyClicked, setAlreadyClicked] = useState();
  const [promptTimeout, setPromptTimeout] = useState(null);
  const [bubbleTimeout, setBubbleTimeout] = useState(null);
  const [showBubble, setShowBubble] = useState(false);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');

  useEffect(() => {
    const TO = setTimeout(function() {
      setShowBubble(true);
    }, 10 * 1000);
    setPromptTimeout(TO);
  }, []);

  const transitionOut = () => {
    if (expand) {
      setExpand(false);
      setDisplayGreeting(false);
    } else {
      setExpand(true);
      setShowBubble(false);
      setTimeout(function() {
        setDisplayGreeting(true);
      }, 500);
    }
  };
  // setTimeout(function() {
  //   setExpand(true);
  // }, 15 * 1000);
  // return <div />;
  const styleTwo = displayGreeting ? expandedDisplay : {};
  const styleOne = expand ? expanded : collapsed;

  const handleSubmit = async (e) => {
    e.preventDefault();
    // const url = 'http://localhost:8080/email';
    const url = 'https://devapi.rezrate.com/email';
    await axios({
      method: 'post',
      url,
      withCredentials: determineCredentials(),
      params: { dev: isDev() },
      data: { name, email, phoneNumber },
      headers: {
        'content-type': 'application/json',
      },
    })
      .then((res) => {
        if (res.status === 200 || res.statusText.toUpperCase === 'OK') {
          Swal.fire('Thank you!', 'We will try to get back to you as quickly as possible');
        } else {
          Swal.fire('Oops...', 'Something went wrong, please try again later', 'error');
        }
      })
      .catch((err) => {
        Swal.fire('Oops...', 'Something went wrong, please try again later', 'error');
      })
      .finally(() => {
        setName('');
        setEmail('');
        setPhoneNumber('');
        transitionOut();
      });
  };

  // return <div></div>;
  return (
    <div style={{ width: '100%', height: '0px', top: 0, zIndex: 100 }} className="sticky">
      {displayGreeting && (
        <div
          style={{
            width: '100vw',
            height: '100vh',
            maxWidth: '100%',
            position: 'absolute',
            zIndex: '10',
            backgroundColor: 'rgba(52,52,52,0.8)',
          }}
        />
      )}
      <div>
        <ReactCSSTransitionReplace transitionName="fade" transitionEnterTimeout={500} transitionLeaveTimeout={500}>
          <div className="demo-wrapper" style={{ ...styleOne, ...styleTwo }}>
            {!displayGreeting && (
              <Fragment>
                <div
                  style={{
                    width: '100%',
                    height: '100%',
                    padding: '23px',
                    position: 'absolute',
                    zIndex: 100,
                    top: '-7px',
                    left: '-7px',
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    if (promptTimeout) {
                      clearTimeout(promptTimeout);
                      setPromptTimeout(null);
                    }
                    transitionOut();
                  }}
                />
                <div key="1" style={{ cursor: 'pointer' }}>
                  <FontAwesomeIcon
                    style={{ position: 'relative', fontSize: '22px', left: '-1px', top: '-9px', color: '#ffffff' }}
                    icon={faInfo}
                    size="5px"
                  ></FontAwesomeIcon>
                </div>
                <img
                  className="bubble"
                  src={Bubble}
                  style={{
                    position: 'absolute',
                    left: '-211px',
                    top: '1px',
                    height: '100px',
                    width: 'auto',
                    opacity: showBubble ? 1 : 0,
                  }}
                />
                <p
                  style={{
                    position: 'absolute',
                    top: '19px',
                    color: '#ffffff',
                    fontWeight: 700,
                    left: '-200px',
                    textAlign: 'center',
                    width: '140px',
                    opacity: showBubble ? 1 : 0,
                  }}
                >
                  Hey there!
                  <br /> Want to learn more about RezRATE?
                </p>
                <div
                  onClick={() => {
                    setShowBubble(false);
                  }}
                  style={{
                    position: 'absolute',
                    top: '4px',
                    left: '-92px',
                    padding: '12px 17px 6px 11px',
                    zIndex: '100',
                    cursor: 'pointer',
                    opacity: showBubble ? 1 : 0,
                  }}
                >
                  <FontAwesomeIcon
                    style={{ position: 'relative', fontSize: '18px', color: '#ffffff' }}
                    icon={faTimes}
                    size="5px"
                  ></FontAwesomeIcon>
                </div>
                <div
                  onClick={() => {
                    setShowBubble(false);
                    setExpand(true);
                    setTimeout(function() {
                      setDisplayGreeting(true);
                    }, 500);
                  }}
                  style={{
                    position: 'absolute',
                    top: '3px',
                    width: '159px',
                    cursor: 'pointer',
                    height: '96px',
                    left: '-208px',
                    opacity: showBubble ? 1 : 0,
                  }}
                />
              </Fragment>
            )}
            {displayGreeting && (
              <div key="2" style={{ textAlign: 'center', cursor: 'pointer', padding: '20px', color: '#ffffff' }}>
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'flex-end',
                    alignItems: 'flex-end',
                    paddingBottom: '15px',
                  }}
                >
                  <div onClick={() => transitionOut()} style={{ padding: '10px' }}>
                    <FontAwesomeIcon icon={faTimes} size="lg" style={{ textAlign: 'right' }}></FontAwesomeIcon>
                  </div>
                </div>
                <div style={{ color: '#ffffff' }}>
                  <h2 style={{ fontWeight: '700' }}>Hey there,</h2>
                  <h4>Would you like to get more information about RezRATE?</h4>
                </div>
                <Form onSubmit={handleSubmit}>
                  <FormGroup>
                    <Label for="exampleAddress">Name</Label>
                    <Input
                      required
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      type="name"
                      placeholder="Your Name"
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label for="exampleAddress">Email</Label>
                    <Input
                      required
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      type="email"
                      id="exampleAddress"
                      placeholder="Your Email"
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label for="exampleAddress">Phone Number</Label>
                    <Input
                      value={phoneNumber}
                      onChange={(e) => setPhoneNumber(e.target.value)}
                      type="tel"
                      pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                      placeholder="Your Phone Number"
                    />
                  </FormGroup>
                  <Button
                    style={{
                      backgroundColor: 'rgb(240,240,240)',
                      color: 'rgb(20,20,20)',
                      marginRight: '15px',
                      marginTop: '10px',
                    }}
                    type="submit"
                  >
                    Submit
                  </Button>
                  <Button
                    style={{
                      backgroundColor: 'rgb(240,240,240)',
                      color: 'rgb(20,20,20)',
                      marginLeft: '15px',
                      marginTop: '10px',
                    }}
                    onClick={(e) => {
                      e.preventDefault();
                      transitionOut();
                    }}
                  >
                    Dismiss
                  </Button>
                </Form>
              </div>
            )}
          </div>
        </ReactCSSTransitionReplace>
      </div>
    </div>
  );
};

export default Demo;
