const style = {
  mainBody: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
  },
  simpleRow: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 10,
  },
  inputLabel: {
    fontWeight: 'bold',
  },
  closeButton: {
    alignSelf: 'flex-end',
  },
  inputFields: {
    textAlign: 'center',
    width: 60,
  },
  buttonIcon: {
    marginRight: 10,
    cursor: 'pointer',
  },
  spaceBetweenRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  genButton: {
    // marginLeft: 10,
    // marginRight: 10,
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddignRight: 20,
    paddingBottom: 10,
    paddingLeft: 20,
    paddingTop: 10,
    width: '100%',
  },
  headerTitle: {
    fontWeight: 'bold',
    width: '100%',
    textAlign: 'center',
  },
  buttonLabel: { width: 200, textAlign: 'center', fontWeight: 600, fontSize: 13 },
};

export default style;
