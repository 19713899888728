const initialState = {
  interviewDates: [],
};

const EvaluatorReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_EVALUATOR_INTERVIEW_DATES':
      return {
        ...state,
        interviewDates: action.payload,
      };
    default:
      return state;
  }
};

export default EvaluatorReducer;
