import { getCookie } from '../Common.functions';

const initialState = {
  isAuthenticated: getCookie('feathers-jwt') !== undefined,
};

const CalendarReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'IS_AUTHENTICATED':
      return {
        ...state,
        isAuthenticated: action.payload,
      };
    case 'AUTH_OBJECT':
      return {
        ...state,
        authObject: action.payload,
      };
    case 'SET_LOGIN_HINT':
      return {
        ...state,
        loginHint: action.payload,
      };
    default:
      return state;
  }
};

export default CalendarReducer;
