const style = {
  simpleRow: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    // margin: 10,
  },
  simpleColumn: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: 10,
  },
  printButton: {
    backgroundColor: '#0a91ff',

    height: '100%',
    width: '100%',
  },
};

export default style;
