import {
  faExclamationTriangle,
  faEyeSlash,
  faFile,
  faFileImage,
  faFilePdf,
  faSpinner,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useCallback, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useHistory } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import { Button, Row } from 'reactstrap';
import Swal from 'sweetalert2';
import { truncate } from 'lodash';
import { fetchDataAgnosticWithoutCredentials } from 'Services/dataApi';
import { useAuth0 } from 'auth0/reactAuth0Spa';

const ROOT_LOGO_URL = 'https://rezratefiles.blob.core.windows.net/logos/';
const WIDTH_DIFFERENCE = 10;

const viewWidth = `100%`;
const BOX_WIDTH = 'calc(100% - 20px)';
const height = '150px';

const DragAndDropGrid = ({
  attachedDoc,
  urlObject,
  uploadLogo,
  currentLogo,
  deleteFile,
  confirmDelete,
  setConfirmDelete,
  setParentFile,
  typeRestrictions,
}) => {
  const [preventClick, setPreventClick] = useState(false);
  const [validDepartment, setValidDepartment] = useState(null);
  const [confirmReplace, setConfirmReplace] = useState(false);
  const [chooseViewOrReplace, setChooseViewOrReplace] = useState(false);
  const [pendingFile, setPendingFile] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);

  const { getTokenSilently } = useAuth0();
  const [file, setFile] = useState({});

  const specialMimes = {
    docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    doc: 'application/msword',
    xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    xls: 'application/vnd.ms-excel',
    ppt: 'application/vnd.ms-powerpoint',
    pptx: 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  };

  useEffect(() => {
    if (uploadLogo) {
      const validateDepartment = async () => {
        const { pk_UUID_FM, department, organization, sessionID, tempToken } = urlObject;
        const validDepartment = fetchDataAgnosticWithoutCredentials('organization/department', urlObject)
          .then((res) => {
            if (res && res.data && res.data.pk_Department) {
              setValidDepartment(true);
            } else {
              setValidDepartment(false);
            }
          })
          .catch((err) => {});
      };
      validateDepartment();
    }
  }, [uploadLogo]);

  useEffect(() => {
    if (attachedDoc) {
      console.log('attachedDoc change: ', attachedDoc);
      setFile(attachedDoc);
    }
  }, [attachedDoc]);

  const BOX_STYLE = {
    display: 'flex',
    justifyContent: 'center',
    cursor: 'pointer',
    alignItems: 'center',
    width: viewWidth,
  };

  const BOX_STYLE_NO_POINTER = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: viewWidth,
  };

  const fireReplaceAlert = (pendingFiles, callback) => {
    Swal.fire({
      title: 'Please confirm!',
      text: `Are you sure you want to replace the current file ${file.DisplayName || file.DocumentTypeName || ''}?`,
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: `Replace`,
    }).then(async (result) => {
      if (result.value) {
        // setPendingFile(pendingFiles[0]);
        callback();
        // setPreventClick(true);
        // const deleted = await deleteFile(file);
        // const fileSent = await initiateSending(pendingFiles);
        // Promise.all([deleted, fileSent]).then((values) => {
        //   setPreventClick(false);
        //   setConfirmReplace(false);
        // });
      } else {
        // setConfirmReplace(false);
        // setPendingFile(null);
      }
    });
  };

  const onDrop = useCallback(
    (acceptedFiles) => {
      setPreventClick(false);
      if (acceptedFiles.length > 1) {
        Swal.fire('Oops', 'Please limit 1 file per category', 'error');
      } else if (uploadLogo) {
        if (file && file.deptLogoExists === true && false) {
          setPendingFile(acceptedFiles);
          fireReplaceAlert(acceptedFiles);
        } else {
          setFile(acceptedFiles[0]);
          setParentFile(acceptedFiles[0]);
        }
      } else {
        if (file && (file.Name || file.DisplayName)) {
          fireReplaceAlert(acceptedFiles, () => {
            if (acceptedFiles[0].type.includes('image')) {
              // Create a FileReader object to read the file
              const reader = new FileReader();

              // Set up an event listener for when the file is loaded
              reader.onload = (e) => {
                console.log('reader setImagePreview: ');
                // Set the imagePreview state to the data URL obtained from the FileReader
                setImagePreview(e.target.result);
              };

              // Read the file as a data URL
              reader.readAsDataURL(acceptedFiles[0]);
            } else {
              setImagePreview(null);
            }
            console.log('setFile: ', acceptedFiles[0]);
            setFile(acceptedFiles[0]);
            setParentFile(acceptedFiles[0]);
          });
        }

        if (file && file.Name && false) {
          setPendingFile(acceptedFiles);
          fireReplaceAlert(acceptedFiles);
        } else {
          if (acceptedFiles[0].type.includes('image')) {
            // Create a FileReader object to read the file
            const reader = new FileReader();

            // Set up an event listener for when the file is loaded
            reader.onload = (e) => {
              console.log('reader setImagePreview: ');
              // Set the imagePreview state to the data URL obtained from the FileReader
              setImagePreview(e.target.result);
            };

            // Read the file as a data URL
            reader.readAsDataURL(acceptedFiles[0]);
          } else {
            setImagePreview(null);
          }

          const allowedTypes = typeRestrictions.split(',');
          let typeAllowed = false;
          allowedTypes.forEach((allowedType) => {
            allowedType = allowedType.replace('.', '');
            allowedType = allowedType.replaceAll(' ', '');

            if (acceptedFiles[0].type.includes(allowedType) || acceptedFiles[0].type === specialMimes[allowedType]) {
              typeAllowed = true;
            }
          });

          if (!typeAllowed) {
            Swal.fire('Invalid File Type', `Expected types: ${typeRestrictions}`, 'error');
            return;
          }

          setFile(acceptedFiles[0]);
          setParentFile(acceptedFiles[0]);

          // initiateSending(acceptedFiles);
        }
      }
    },
    [file],
  );

  const determineIcon = () => {
    let iconToUse = faFile;
    const isImage = (file.type && file.type.includes('image')) || (file.MimeType && file.MimeType.includes('image'));
    if ((file.type && file.type.includes('pdf')) || (file.MimeType && file.MimeType.includes('pdf'))) {
      iconToUse = faFilePdf;
    }
    if (isImage) {
      iconToUse = faFileImage;
    }
    // if (file.MimeType && file.MimeType.includes('image')) {
    //   return faFileWord;
    // }
    return (
      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
              marginBottom: 5,
            }}
          >
            {!isImage || (isImage && !imagePreview) ? (
              // <img src={imagePreview} style={{ height: 60 }} />
              <FontAwesomeIcon icon={iconToUse} style={{ fontSize: 55 }} />
            ) : null}
          </div>
          {isImage ? (
            <>
              <div style={{ backgroundColor: 'white' }}>{truncate(file.name || file.DisplayName, 13)}</div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center',
                  alignItems: 'center',
                  fontWeight: 'bold',
                  backgroundColor: 'white',
                }}
              >
                Click or Drag File here to Replace
              </div>
            </>
          ) : (
            <>
              <div>{truncate(file.name, 13)}</div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center',
                  alignItems: 'center',
                  fontWeight: 'bold',
                  backgroundColor: 'white',
                }}
              >
                Click or Drag File here to Replace
              </div>
            </>
          )}
        </div>
      </div>
    );
    // return <FontAwesomeIcon icon={faFile} style={{ fontSize: 55 }} />;
  };

  const determineDragText = (hasFile) => {
    if (hasFile) {
      return determineIcon();
      //return 'Replace Document';
    }

    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          fontWeight: 'bold',
        }}
      >
        Click or Drag File Here
      </div>
    );
  };

  const renderChooseViewOrReplace = () => {
    return (
      <div className="boxWrapper" style={{ margin: '10px', border: '1px dashed #000', width: BOX_WIDTH }}>
        <div
          className="boxSecondWrapper"
          style={{
            ...BOX_STYLE_NO_POINTER,
            height,
            flexDirection: 'column',
            padding: '5px',
            width: '100%',
          }}
        ></div>
      </div>
    );
  };

  const renderConfirmDelete = () => {
    return (
      <div style={{ margin: '10px', border: '1px dashed #000', width: BOX_WIDTH }}>
        <div
          style={{
            ...BOX_STYLE_NO_POINTER,
            height,
            flexDirection: 'column',
            padding: '5px',
            width: '100%',
          }}
        >
          <div style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
            <h6 style={{ paddingRight: '5px' }}>
              <FontAwesomeIcon icon={faExclamationTriangle} color="#d11a2a" />
            </h6>
            <h6>Delete?</h6>
          </div>
          <div>
            <p style={{ textAlign: 'center' }}>
              Are you sure you want to delete {file.DisplayName || file.DocumentTypeName || ''}?
            </p>
          </div>
          <div style={{ display: 'flex', width: '100%', justifyContent: 'space-around' }}>
            <div>
              <Button
                onClick={(e) => {
                  e.preventDefault();
                  setPreventClick(true);
                  deleteFile(file);
                  setPreventClick(false);
                }}
                style={{ backgroundColor: '#d11a2a' }}
              >
                Yes
              </Button>
            </div>
            <div>
              <Button
                onClick={(e) => {
                  e.preventDefault();
                  setConfirmDelete(false);
                }}
                style={{ backgroundColor: '#32CD32' }}
              >
                No
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const documentContent = () => {
    if (preventClick) {
      return (
        <div style={{ margin: '10px', border: '1px dashed #000', width: BOX_WIDTH }}>
          <div
            className="hover_blue"
            style={{
              ...BOX_STYLE,
              width: '100%',
              height,
              flexDirection: 'column',
              padding: '5px',
            }}
          >
            <FontAwesomeIcon icon={faSpinner} spin={true} />
          </div>
        </div>
      );
    }

    if (chooseViewOrReplace) {
      return renderChooseViewOrReplace();
    }

    if (confirmDelete) {
      return renderConfirmDelete();
    }

    const hasFile = (file && (file.name || file.DisplayName)) || (pendingFile && pendingFile.name);

    // DRAG n drop box proper
    return (
      <div
        style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <div style={{ height: '10px' }}></div>
        <div
          // className={hasFile ? 'drop_wrapper_as_button' : 'drop_wrapper'}
          className={hasFile ? '' : 'drop_wrapper'}
          id={`box_${file ? file.type || file.MimeType || 'noType' : 'noFile'}`}
          style={{
            width: BOX_WIDTH,
            borderRadius: 5,
            border: 'dashed',
            backgroundSize: 'auto 100%',
            backgroundPosition: 'center center',
            backgroundRepeat: 'no-repeat',
            backgroundImage:
              file && file.type && file.type.includes('image') && imagePreview ? `url(${imagePreview})` : 'none',
          }}
        >
          <div
            className={!hasFile && 'hover_blue'}
            {...getRootProps()}
            style={{
              ...BOX_STYLE,
              // height: hasFile ? 'auto' : height,
              height,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-end',
              alignContent: 'flex-end',
              width: '100%',
            }}
          >
            <input {...getInputProps()} accept={typeRestrictions} />
            <div
              style={{
                display: 'flex',
                alignContent: 'center',
                flexDirection: 'row',
                textAlign: 'center',
                fontSize: 13,

                // verticalAlign: 'middle',
              }}
            >
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  width: '100%',
                  justifyContent: 'flex-end',
                  alignContent: 'flex-end',
                }}
              >
                {determineDragText(hasFile)}
                {file.Hide && (
                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <div style={{ paddingRight: '5px' }}>
                      <FontAwesomeIcon icon={faEyeSlash} />
                    </div>
                    <h6 style={{ margin: 0 }}>Hidden</h6>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const logoContent = () => {
    if (validDepartment === null) {
      return <div>No Valid Department</div>;
    }

    // if (!validDepartment) {
    //   return (
    //     <div style={{ margin: '5px', border: '1px dashed #000', width: BOX_WIDTH }}>
    //       <div
    //         className="hover_blue"
    //         style={{
    //           ...BOX_STYLE,
    //           height: uploadLogo ? '90vh' : 'auto',
    //         }}
    //       >
    //         <div
    //           style={{
    //             display: 'flex',
    //             alignContent: 'center',
    //             flexDirection: 'column',
    //             textAlign: 'center',
    //           }}
    //         >
    //           <p style={{ color: 'rgb(255, 0, 0)' }}>Invalid Department</p>
    //           <p style={{ fontWeight: '700' }}>{file.DocumentTypeName}</p>
    //           {file.Hide && (
    //             <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
    //               <div style={{ paddingRight: '5px' }}>
    //                 <FontAwesomeIcon icon={faEyeSlash} />
    //               </div>
    //               <h6 style={{ margin: 0 }}>Hidden</h6>
    //             </div>
    //           )}
    //         </div>
    //       </div>
    //     </div>
    //   );
    // }
    // if (confirmReplace) {
    //   return (
    //     <div style={{ margin: '10px', border: '1px dashed #000', width: BOX_WIDTH }}>
    //       <div
    //         style={{
    //           ...BOX_STYLE_NO_POINTER,
    //           height,
    //           flexDirection: 'column',
    //           padding: '5px',
    //           width: '100%',
    //         }}
    //       >
    //         <div style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
    //           <h6 style={{ paddingRight: '5px' }}>
    //             <FontAwesomeIcon icon={faExclamationTriangle} color="#d11a2a" />
    //           </h6>
    //           <h6>Replace</h6>
    //         </div>
    //         <div>
    //           <p style={{ textAlign: 'center' }}>
    //             Are you sure you want to replace the current file ({file.DocumentTypeName})?
    //           </p>
    //         </div>
    //         <div style={{ display: 'flex', width: '100%', justifyContent: 'space-around' }}>
    //           <div>
    //             <Button
    //               onClick={async (e) => {
    //                 e.preventDefault();
    //                 setPreventClick(true);
    //                 const deleted = await deleteFile(file);
    //                 const fileSent = await sendFile();
    //                 Promise.all([deleted, fileSent]).then((values) => {
    //                   setPreventClick(false);
    //                   setConfirmReplace(false);
    //                 });
    //               }}
    //               style={{ backgroundColor: '#d11a2a' }}
    //             >
    //               Yes
    //             </Button>
    //           </div>
    //           <div>
    //             <Button
    //               onClick={(e) => {
    //                 e.preventDefault();
    //                 setConfirmReplace(false);
    //                 setPendingFile(null);
    //               }}
    //               style={{ backgroundColor: '#32CD32' }}
    //             >
    //               No
    //             </Button>
    //           </div>
    //         </div>
    //       </div>
    //     </div>
    //   );
    // }
    if (currentLogo.deptLogoExists) {
      const fullLogoUrl = currentLogo.logoUrl;
      return (
        <div style={{ margin: '5px', border: '1px dashed #000', width: BOX_WIDTH }}>
          <div
            className="hover_blue"
            {...getRootProps()}
            style={{
              ...BOX_STYLE,
              height: uploadLogo ? '90vh' : 'auto',
            }}
          >
            <input {...getInputProps()} />
            <div
              style={{
                display: 'flex',
                alignContent: 'center',
                flexDirection: 'column',
                textAlign: 'center',
              }}
            >
              <img style={{ maxWidth: '100%', maxHeight: '100%' }} src={fullLogoUrl} />
            </div>
          </div>
        </div>
      );
    }

    return (
      <div style={{ margin: '5px', border: '1px dashed #000', width: BOX_WIDTH }}>
        <div
          className="hover_blue"
          {...getRootProps()}
          style={{
            ...BOX_STYLE,
            height: uploadLogo ? '90vh' : 'auto',
          }}
        >
          <input {...getInputProps()} />
          <div
            style={{
              display: 'flex',
              alignContent: 'center',
              flexDirection: 'column',
              textAlign: 'center',
            }}
          >
            <p>Click or Drag</p>
            <p style={{ fontWeight: '700' }}>{file.DocumentTypeName}</p>
            {file.Hide && (
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <div style={{ paddingRight: '5px' }}>
                  <FontAwesomeIcon icon={faEyeSlash} />
                </div>
                <h6 style={{ margin: 0 }}>Hidden</h6>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  };

  const determineContent = () => {
    if (uploadLogo) {
      return logoContent();
    } else {
      return documentContent();
    }
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });
  return (
    <Row
      style={{
        width: '100%',
        overflowX: 'hidden',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        margin: 0,
      }}
    >
      {determineContent()}
    </Row>
  );
};

export default DragAndDropGrid;
