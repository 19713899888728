import React, { useEffect, useRef } from 'react';
import Lottie from 'lottie-web';

const rezRateIcon = require('./RezRATELoadingIconCrop.json');

// Generic loading screen.  Can either have variables passed in or will take the values of the header and subheader below
const Loading = ({ options = {}, header, subHeader, type = 'lottie' }) => {
  const loadingContainer = useRef(null);
  useEffect(() => {
    if (type === 'lottie' && loadingContainer) {
      Lottie.loadAnimation({
        container: loadingContainer.current,
        renderer: 'svg',
        loop: true,
        autoplay: true,
        animationData: rezRateIcon, // Add your animation data here
      });
    }
  }, [type, loadingContainer]);

  if (type === 'lottie') {
    const { labelText = '', labelStyle = { fontSize: 15, textAlign: 'center', fontWeight: 'bold' } } = options;

    return (
      <div style={{ margin: 0, width: '100%', height: 100, display: 'flex', flexDirection: 'column' }}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            height: `calc(100px - ${(labelStyle.fontSize || 15) + 10}px)`,
            width: '100%',
          }}
          ref={loadingContainer}
        ></div>
        <div style={labelStyle}>{labelText}</div>
      </div>
    );
  } else if (type === 'legacy') {
    const headerMessage = header ? header : 'Loading...';
    const subHeaderMessage = subHeader
      ? subHeader
      : 'Please be patient. Refreshing this page will move you back in the queue.';

    return (
      <div style={{ margin: 0, width: '100%', height: '100%' }}>
        <div className="midwrapper">
          <div className="midblock lds-dual-ring"></div>
          <div className="midblock">
            <h2>{headerMessage}</h2>
            {subHeaderMessage}
          </div>
        </div>
      </div>
    );
  }
};

export default Loading;
