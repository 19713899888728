import {
  faBan,
  faCheck,
  faCheckSquare,
  faPencilAlt,
  faPlusCircle,
  faSquare,
  faTimes,
  faTimesCircle,
  faTrash,
  faTrashRestore,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment-timezone';
import React, { useContext, useEffect, useRef, useState } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import { TimeInput } from 'react-widgets/cjs';
import DatePicker from 'react-widgets/DatePicker';
import 'react-widgets/styles.css';
import { Button, FormFeedback, Input, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { clone } from '../../../../../Services/schedule';
import EvaluatorPicker from './EvaluatorPicker/EvaluatorPicker';
import style from './style';
import { deleteData, postDataAgnostic, putData } from 'Services/dataApi';
import { useAuth0 } from 'auth0/reactAuth0Spa';
import { DepartmentContext } from 'DepartmentWrapper';
import FlexEventAction from './FlexEventAction/FlexEventAction';
import { formatBearerToken } from 'Common.functions';

const HeaderUnavailablePanel = ({
  header,
  addUnavailableSlot,
  addUnavailableSlots,
  updateFlexEvent,
  deleteFlexEvent,
  scheduleData,
  isFetching,
  isSubmitting,
  showUnavailabilityEvaluatorPicker,
  setShowUnavailabilityEvaluatorPicker,
  selectedCell,
  scheduleID,
  updateFlexEvents,
  setUnavailabilityPanelHasChanges,
  setShowUnavailabilityModal,
  fireUnsavedChangesAlert,
  updateAllData,
}) => {
  const [unavailableSlots, setUnavailableSlots] = useState([]);
  const [unavailableSlotsReference, setUnavailableSlotsReference] = useState([]);
  const [slotsToDelete, setSlotsToDelete] = useState([]);
  const [slotsToAdd, setSlotsToAdd] = useState([]);

  const [showEvaluatorPicker, setShowEvaluatorPicker] = useState({});
  const [saveHasResponded, setSaveHasResponded] = useState(true);
  const [selectedRow, setSelectedRow] = useState(null);
  const [showSaveModal, setShowSaveModal] = useState();
  const [toggleRefresh, setToggleRefresh] = useState(false);
  const [isSaving, setIsSaving] = useState();
  const [keysToDelete, setKeysToDelete] = useState([]);

  const dContext = useContext(DepartmentContext);
  const { getTokenSilently } = useAuth0();
  const selectedRowRef = useRef();
  const { metaData } = scheduleData || {};
  const { StartDateAndTime, DateOfInterview } = metaData || {};

  useEffect(() => {
    if (!saveHasResponded) {
      return;
    }
    let newUnavailableSlots = [];
    let newShowUnavailabilityEvaluatorPicker = {};
    const { rawHeader } = clone(header) || {};
    if (rawHeader) {
      newUnavailableSlots = rawHeader.flexEvents ? clone(rawHeader).flexEvents.slice() : [];
    }

    newUnavailableSlots.forEach((slot, i) => {
      slot.FlexStartTime = slot.FlexTimeStart;
      newShowUnavailabilityEvaluatorPicker[`picker_${i}`] = false;
      // slot.isEdit = true;
    });

    newUnavailableSlots.push({ id: 'addSlotBar' });
    setUnavailableSlots(newUnavailableSlots);
    setUnavailableSlotsReference(newUnavailableSlots);
    setShowUnavailabilityEvaluatorPicker(newShowUnavailabilityEvaluatorPicker);
    setSelectedRow(null);
    setKeysToDelete([]);
    selectedRowRef.current = null;
  }, [JSON.stringify(header), selectedCell]);

  useEffect(() => {
    if (!saveHasResponded) {
      return;
    }
    if (selectedRow) {
      selectedRowRef.current = selectedRow;
    }
  }, [JSON.stringify(selectedRow)]);

  useEffect(() => {
    if (slotsToDelete && unavailableSlots && unavailableSlots.length > 0) {
      updateUnavailableSlotActionStatus();
    }
  }, [slotsToDelete]);

  useEffect(() => {
    if (unavailableSlots && setUnavailabilityPanelHasChanges) {
      setUnavailabilityPanelHasChanges(hasChanges(unavailableSlots));
    }
    if (unavailableSlots) {
      const newSlotstoAdd = [];

      unavailableSlots.forEach((slot, i) => {
        const { pk_FlexEvent, id } = slot || {};
        if (pk_FlexEvent == null && id != 'addSlotBar') {
          newSlotstoAdd.push({ ...slot, rowIndex: i });
        }
      });

      setSlotsToAdd(newSlotstoAdd);
    }
  }, [unavailableSlots]);

  const updateUnavailableSlotActionStatus = () => {
    const newUnavailableSlots = unavailableSlots.slice();

    // Add flags to slots to update Bootstraptable, to refresh the table, and to update the slots' props. Otherwise, removing slot would only work once and never again, as slotsToDelete would be empty, forever.
    newUnavailableSlots.forEach((slot) => {
      // If new slot, add toAdd flag
      if (slot.pk_FlexEvent == null) {
        slot.toAdd = true;
        delete slot.toDelete;
      }

      // If existing slot and is newly added to slotsToDelete, add toDelete flag
      if (slotsToDelete.includes(slot.pk_FlexEvent)) {
        slot.toDelete = true;
        delete slot.toAdd;
      }

      // If existing slot, remove toDelete flag
      if (!slotsToDelete.includes(slot.pk_FlexEvent) && slot.toDelete) {
        delete slot.toDelete;
      }
    });

    setUnavailableSlots(newUnavailableSlots);
  };

  const reloadData = () => {
    let newUnavailableSlots = [];
    const newHeader = clone(header);
    const { rawHeader } = newHeader || {};
    if (rawHeader) {
      newUnavailableSlots = rawHeader.flexEvents ? rawHeader.flexEvents.slice() : [];
    }
    newUnavailableSlots.push({ id: 'addSlotBar' });
    setUnavailableSlots(newUnavailableSlots);
    setUnavailableSlotsReference(newUnavailableSlots);
  };

  // Shape for updating.
  const prepareFlexEvent = (newSlot) => {
    const { FlexStartTime, FlexTimeEnd, evaluatorsToAdd, evaluatorsToDelete, pk_FlexEvent, rowIndex } = newSlot;
    const fieldId = `input_${pk_FlexEvent != null ? pk_FlexEvent : `temp_${rowIndex}`}`;
    const startTimeValue = document.getElementById(`${fieldId}_startTime`).value;
    const endTimeValue = document.getElementById(`${fieldId}_endTime`).value;

    const newFlexEvent = {
      pk_FlexEvent,
      StartDateTime: moment.tz(`${DateOfInterview} ${startTimeValue}`, null).format(),
      EndDateTime: moment.tz(`${DateOfInterview} ${endTimeValue}`, null).format(),
      FlexTimeStart: startTimeValue,
      FlexTimeEnd: endTimeValue,
      EnableForAllUsers: false,
      EnableAbsoluteTime: true,
      EventName: 'UNAVAILABLE',
      evaluatorsToAdd,
      evaluatorsToDelete,
    };
    return newFlexEvent;
  };

  const prepareNewFlexEvent = (newSlot) => {
    const { FlexStartTime, FlexTimeEnd, rowIndex } = newSlot;
    const fieldId = `input_temp_${rowIndex}`;

    const startTimeValue = document.getElementById(`${fieldId}_startTime`).value;
    const endTimeValue = document.getElementById(`${fieldId}_endTime`).value;

    const newFlexEvent = {
      StartDateTime: moment.tz(`${DateOfInterview} ${startTimeValue}`, null).format(),
      EndDateTime: moment.tz(`${DateOfInterview} ${endTimeValue}`, null).format(),
      FlexTimeStart: startTimeValue,
      FlexTimeEnd: endTimeValue,
      EnableForAllUsers: false,
      EnableAbsoluteTime: true,
      EventName: 'UNAVAILABLE',
      newEvaluators: newSlot.evaluators
        ? newSlot.evaluators.map((e) => {
            return e.pk_User;
          })
        : [],
    };
    // Only ScheduleDetailModal > UnavailabilityPanel gives this selectedCell
    if (selectedCell) {
      newFlexEvent.hideScheduleDetailModal = false;
    }

    return newFlexEvent;
    // return {
    //   event: newFlexEvent,
    //   evaluators: newEvaluators
    //     ? newEvaluators.map((e) => {
    //         return e.pk_User;
    //       })
    //     : [],
    //   candidates: candidates || [],
    // };
  };

  // Front end only
  const addNewUnavailability = () => {
    const { metaData } = scheduleData;
    const { StartTime, DateOfInterview } = metaData;

    const newUnavailableSlots = unavailableSlots.slice();
    const newSlotsToAdd = clone(slotsToAdd);

    let startTimeToUse = StartTime;
    let endTimeToUse = StartTime;

    if (selectedCell) {
      const { row } = selectedCell;
      const { slot, slotEnd } = row;

      startTimeToUse = moment.tz(slot, null).format('HH:mm');
      endTimeToUse = moment.tz(row.slotEnd, null).format('HH:mm');
    }

    const newSlot = {
      id: newUnavailableSlots.length + 1,
      FlexStartTime: startTimeToUse,
      FlexTimeEnd: endTimeToUse,
      evaluators: header.rawHeader.Evaluators.Evaluators,
      action: null,
      isEdit: true,
      rowIndex: unavailableSlots.length > 0 ? unavailableSlots.length - 1 : 0,
    };

    // newUnavailableSlots.push(newSlot);
    newUnavailableSlots.splice(newUnavailableSlots.length - 1, 0, newSlot);
    newSlotsToAdd.push(newSlot);
    setSelectedRow(newSlot);
    setUnavailableSlots(newUnavailableSlots);
    setSlotsToAdd(newSlotsToAdd);
  };

  const saveAll = () => {
    const eventsToUpdate = [];

    unavailableSlots.forEach((slot, i) => {
      // Only PUT if the slot has a pk_FlexEvent, meaning pre-existing ones.
      // We also ignore toDelete ones.
      if ((slot.pk_FlexEvent != null && slot.id) == null && slotsToDelete.includes(slot.pk_FlexEvent) === false) {
        eventsToUpdate.push(prepareFlexEvent({ ...slot, rowIndex: i }));
      }
    });
    const eventsToAdd = slotsToAdd.map((slot) => {
      return prepareNewFlexEvent(slot);
    });

    const eventsToDelete = slotsToDelete.map((pk_FlexEvent) => {
      return pk_FlexEvent;
    });

    // const deleteItems = new Promise((resolve, reject) => {
    //   deleteUnavailabilities(eventsToDelete, resolve, reject);
    // });

    setIsSaving(true);

    const promises = [];

    if (eventsToAdd.length > 0) {
      promises.push(() => {
        return new Promise((resolve, reject) => {
          addUnavailableSlots(eventsToAdd, null, null, (err) => {
            setSaveHasResponded(true);
            if (err) {
              reject(err);
            } else {
              resolve();
              setSlotsToAdd([]);
            }

            // reloadData();
          });
        });
      });
    }

    if (eventsToUpdate.length > 0) {
      promises.push(() => {
        return new Promise((resolve, reject) => {
          updateFlexEvents(eventsToUpdate, (err) => {
            console.log('updateFlexEvents cb, err: ', err);
            if (err) {
              reject(err);
            } else {
              resolve();
            }
          });
        });
      });
    }

    promises
      .reduce((chain, promise) => {
        return chain.then(() => promise());
      }, Promise.resolve())
      .then((res) => {
        setIsSaving(false);
        updateAllData();
      })
      .catch((err) => {
        setIsSaving(false);
        updateAllData();
        console.log('saveAll err: ', err);
      });

    // Promise.all(
    //   promises.map((p) => {
    //     return p();
    //   }),
    // )
    //   .then((res) => {
    //     setIsSaving(false);
    //     console.log('RESOLVE!');
    //   })
    //   .catch((err) => {
    //     setIsSaving(false);
    //     console.log('saveAll err: ', err);
    //   });
  };

  const deleteUnavailabilities = (slotsToDelete, resolve, reject) => {
    resolve();
    return;
    getTokenSilently()
      .then((token) => {
        deleteData(
          'department/season/schedule/flexEvent',
          {
            pk_Department: dContext.department.pk_Department,
          },
          { pk_FlexEvents: slotsToDelete },
          formatBearerToken(token),
        )
          .then((res) => {
            if (resolve) {
              resolve(res);
            }
          })
          .catch((err) => {
            reject(err);
          });
      })
      .catch((err) => {
        reject(err);
      });
  };

  const updateUnavailabilities = (slotsToUpdate, resolve, reject) => {
    resolve();
    return;
    getTokenSilently()
      .then((token) => {
        putData(
          'department/season/schedule/flexEvent',
          {
            pk_Department: dContext.department.pk_Department,
            pk_InterviewDate: Number(scheduleID),
          },
          { events: slotsToUpdate },
          formatBearerToken(token),
        )
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      })
      .catch((err) => {
        reject(err);
      });
  };

  const hasChanges = (slots, showDebugger) => {
    const fieldsToChange = ['FlexStartTime', 'FlexTimeEnd'];
    const slotsToTest = slots || unavailableSlots;

    let hasChanges = false;

    if (!slots && slotsToAdd.length > 0) {
      return true;
    }

    slotsToTest.forEach((slot, i) => {
      const { evaluatorsToAdd, evaluatorsToDelete, AttendingUsers, pk_FlexEvent, rowIndex } = slot;
      if (pk_FlexEvent != null && slot.id == null) {
        const originalSlot = unavailableSlotsReference.find((s) => s.pk_FlexEvent === slot.pk_FlexEvent);
        if (originalSlot == null) {
          return true;
        }
        const originalSlotStartTime = moment
          .tz(`${DateOfInterview} ${originalSlot.FlexStartTime}`, null)
          .format('HH:mm');
        const originalSlotEndTime = moment.tz(`${DateOfInterview} ${originalSlot.FlexTimeEnd}`, null).format('HH:mm');
        // fieldsToChange.forEach((field) => {
        const fieldId = `input_${pk_FlexEvent != null ? pk_FlexEvent : `temp_${rowIndex != null ? rowIndex : i}`}`;
        const startTimeInputField = document.getElementById(`${fieldId}_startTime`);
        const endTimeInputField = document.getElementById(`${fieldId}_endTime`);
        const fieldFlexStartTime = startTimeInputField ? startTimeInputField.value : '';
        const fieldFlexEventEndTime = endTimeInputField ? endTimeInputField.value : '';

        // console.log('originalSlotStartTime: ', originalSlotStartTime);
        // console.log('originalSlotEndTime: ', originalSlotEndTime);
        // console.log('fieldFlexStartTime: ', fieldFlexStartTime);
        // console.log('fieldFlexEventEndTime: ', fieldFlexEventEndTime);

        if (originalSlotStartTime != fieldFlexStartTime || originalSlotEndTime != fieldFlexEventEndTime) {
          hasChanges = true;
        }
        // });

        if (!hasChanges) {
          if (evaluatorsToAdd != null && evaluatorsToAdd.length > 0) {
            evaluatorsToAdd.forEach((pk_User) => {
              if (AttendingUsers.find((u) => u.pk_User === pk_User) == null) {
                console.log('evaluatorsToAdd hasChanges');
                hasChanges = true;
              }
            });
          }

          if (evaluatorsToDelete != null && evaluatorsToDelete.length > 0) {
            evaluatorsToDelete.forEach((pk_User) => {
              if (AttendingUsers.find((u) => u.pk_User === pk_User) != null) {
                console.log('evaluatorsToDelete hasChanges');
                hasChanges = true;
              }
            });
          }
        }
      }
    });

    // if (showDebugger) {
    //   console.log('slots: ', slots);
    //   console.log('hasChanges: ', hasChanges);
    // }

    return hasChanges;
  };

  const updateSlot = (newSlot, rowIndex, updateData) => {
    const newUnavailableSlots = clone(unavailableSlots);
    newUnavailableSlots[rowIndex] = newSlot;
    setUnavailableSlots(newUnavailableSlots);
    const { metaData } = scheduleData;

    if (updateData) {
      if (newSlot.pk_FlexEvent != null) {
        updateFlexEvent(newSlot, () => {
          reloadData();
        });
      } else {
        const { FlexStartTime, FlexTimeEnd } = newSlot;
        const newFlexEvent = {
          StartDateTime: moment.tz(`${DateOfInterview} ${FlexStartTime}`, null).format(),
          EndDateTime: moment.tz(`${DateOfInterview} ${FlexTimeEnd}`, null).format(),
          FlexTimeStart: FlexStartTime,
          FlexTimeEnd,
          EnableForAllUsers: false,
          EnableAbsoluteTime: true,
          EventName: 'UNAVAILABLE',
          newEvaluators: newSlot.evaluators
            ? newSlot.evaluators.map((e) => {
                return e.pk_User;
              })
            : [],
        };
        // Only ScheduleDetailModal > UnavailabilityPanel gives this selectedCell
        if (selectedCell) {
          newFlexEvent.hideScheduleDetailModal = false;
        }

        setSaveHasResponded(false);
        addUnavailableSlot(newFlexEvent, null, null, () => {
          setSaveHasResponded(true);
          reloadData();
        });
      }
    }
  };

  const processInputValidity = (options) => {
    const { fieldId, updatingField } = options;

    const startTimeInputField = document.getElementById(`${fieldId}_startTime`);
    const endTimeInputField = document.getElementById(`${fieldId}_endTime`);
    const fieldFlexStartTime = startTimeInputField ? startTimeInputField.value : '';
    const fieldFlexEventEndTime = endTimeInputField ? endTimeInputField.value : '';
    const startTime = `${DateOfInterview} ${fieldFlexStartTime}`;
    const endTime = `${DateOfInterview} ${fieldFlexEventEndTime}`;
    const isInvalidTime = moment(startTime).isAfter(moment(endTime));
    if (updatingField === 'startTime') {
      return { isInvalidTime, capValue: moment(endTime).format('HH:mm') };
    } else {
      return { isInvalidTime, capValue: moment(startTime).format('HH:mm') };
    }
  };

  const renderStartTime = (cell, row, rowIndex, formatExtraData) => {
    const { id } = row;

    const { metaData } = scheduleData;
    const { StartTime, DateOfInterview } = metaData;

    if (id === 'addSlotBar') {
      return (
        <div style={style.centeredRow} id="towader">
          <Button
            disabled={isSaving || isSubmitting || isFetching}
            onClick={() => {
              addNewUnavailability();
            }}
            color="success"
          >
            Add Unavailability
          </Button>
        </div>
      );
    }
    if (true) {
      const defaultValue = `${DateOfInterview} ${row.FlexStartTime || row.FlexTimeStart}`;
      const fieldId = `input_${row.pk_FlexEvent != null ? row.pk_FlexEvent : `temp_${rowIndex}`}_startTime`;
      const { isInvalidTime, capValue } = processInputValidity({
        fieldId: `input_${row.pk_FlexEvent != null ? row.pk_FlexEvent : `temp_${rowIndex}`}_startTime`,
        updatingField: 'startTime',
      });

      return (
        <div style={style.centeredRow}>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
            <Input
              disabled={isSaving || isSubmitting || isFetching}
              invalid={isInvalidTime}
              id={fieldId}
              type="time"
              max={capValue}
              defaultValue={moment.tz(defaultValue, null).format('HH:mm')}
              onChange={(e) => {
                const { isInvalidTime, capValue } = processInputValidity({
                  fieldId: `input_${row.pk_FlexEvent != null ? row.pk_FlexEvent : `temp_${rowIndex}`}`,
                  updatingField: 'startTime',
                });

                if (isInvalidTime) {
                  const startTimeInputField = document.getElementById(`${fieldId}`);
                  startTimeInputField.value = capValue;
                }
                setToggleRefresh(!toggleRefresh);
              }}
            />
            <FormFeedback>Invalid Start Time</FormFeedback>
          </div>
        </div>
      );
    } else {
      const refValue =
        selectedRowRef && selectedRowRef.current
          ? selectedRowRef.current.FlexStartTime
          : selectedRow
          ? selectedRow.FlexStartTime
          : row.pk_FlexEvent != null
          ? row.FlexTimeStart
          : StartTime;
      const newTime = moment().format('MMM DD, YYYY');

      const timeToDisplay = moment.tz(`${DateOfInterview} ${refValue}`, null).format('hh:mm A');

      return <div style={style.centeredRow}>{timeToDisplay ? timeToDisplay : '--:-- --'}</div>;
    }
  };

  const renderEndTime = (cell, row, rowIndex, formatExtraData) => {
    const { id } = row;
    const { metaData } = scheduleData;
    if (id === 'addSlotBar') {
      return;
    }
    if (true) {
      const defaultValue = `${DateOfInterview} ${row.FlexTimeEnd}`;
      const fieldId = `input_${row.pk_FlexEvent != null ? row.pk_FlexEvent : `temp_${rowIndex}`}_endTime`;
      const { isInvalidTime, capValue } = processInputValidity({
        fieldId: `input_${row.pk_FlexEvent != null ? row.pk_FlexEvent : `temp_${rowIndex}`}`,
        updatingField: 'endTime',
      });

      return (
        <div style={style.centeredRow}>
          <Input
            disabled={isSaving || isSubmitting || isFetching}
            type="time"
            invalid={isInvalidTime}
            id={fieldId}
            min={capValue}
            defaultValue={moment.tz(defaultValue, null).format('HH:mm')}
            onChange={(e) => {
              const { isInvalidTime, capValue } = processInputValidity({
                fieldId: `input_${row.pk_FlexEvent != null ? row.pk_FlexEvent : `temp_${rowIndex}`}`,
                updatingField: 'endTime',
              });

              if (isInvalidTime) {
                const endTimeInputField = document.getElementById(`${fieldId}`);
                endTimeInputField.value = capValue;
              }
              setToggleRefresh(!toggleRefresh);
            }}
          />
          <FormFeedback>Invalid Start Time</FormFeedback>
        </div>
      );
    } else {
      const refValue =
        selectedRowRef && selectedRowRef.current
          ? selectedRowRef.current.FlexTimeEnd
          : selectedRow
          ? selectedRow.FlexTimeEnd
          : row.pk_FlexEvent != null && row.FlexTimeEnd
          ? row.FlexTimeEnd
          : StartDateAndTime;

      const timeToDisplay = moment.tz(`${DateOfInterview} ${refValue}`, null).format('HH:mm A');

      return <div style={style.centeredRow}>{timeToDisplay ? timeToDisplay : '--:-- --'}</div>;
    }
  };

  const renderSaveConfirmModal = () => {
    return (
      <Modal
        isOpen={showSaveModal}
        centered={true}
        zIndex={9999}
        toggle={() => {
          setShowSaveModal(!showSaveModal);
        }}
      >
        <ModalHeader>Confirmation</ModalHeader>
        <ModalBody>
          You are about to save your changes. However, you have marked {slotsToDelete.length} for deletion. Are you sure
          you want to delete thes Unavailability items?{' '}
        </ModalBody>
        <ModalFooter>
          <Button
            id="unavailable_confirm_delete"
            color="success"
            style={style.confirmButtons}
            onClick={(e) => {
              e.stopPropagation();
              setSaveHasResponded(false);
              saveAll();
              // deleteFlexEvent(row.pk_FlexEvent, null, null, () => {
              //   setSaveHasResponded(true);
              //   reloadData();
              // });
              // setShowEvaluatorPicker({});
              // setShowConfirmModal(false);
            }}
          >
            Yes
          </Button>
          <Button
            id="unavailable_cancel_delete"
            color="danger"
            style={style.confirmButtons}
            onClick={(e) => {
              // e.stopPropagation();
              // setShowEvaluatorPicker({});
              setShowSaveModal(false);
            }}
          >
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    );
  };

  const renderEvaluatorPickers = (rows) => {
    return rows.map((row, i) => {
      if (row.isEdit) {
        return (
          <EvaluatorPicker
            updateSlot={updateSlot}
            header={header}
            rowIndex={i}
            flexEvent={row}
            showUnavailabilityEvaluatorPicker={showUnavailabilityEvaluatorPicker}
            setShowUnavailabilityEvaluatorPicker={setShowUnavailabilityEvaluatorPicker}
            showEvaluatorPicker={showEvaluatorPicker}
            setShowEvaluatorPicker={setShowEvaluatorPicker}
            setSelectedRow={setSelectedRow}
            selectedRowRef={selectedRowRef}
          />
        );
      }
    });
  };

  const renderEvaluator = (cell, row, rowIndex, formatExtraData) => {
    const { id } = row;
    if (id === 'addSlotBar') {
      return;
    }
    const evaluatorList =
      cell.length > 0
        ? cell.map((person, i) => {
            if (i > 0) {
              return `, ${person.UserLast}`;
            } else {
              return `${person.UserLast}`;
            }
          })
        : 'No Evaluators. . .';
    return (
      <div style={{ ...style.spaceBetweenRow, justifyContent: 'center' }}>
        <div>{evaluatorList}</div>
        <div>
          <FontAwesomeIcon
            id={`button_${row.pk_FlexEvent != null ? row.pk_FlexEvent : `temp_${rowIndex}`}_addEvaluator`}
            style={{ ...style.clickableIcon, cursor: isSaving ? 'not-allowed' : 'pointer' }}
            icon={faPlusCircle}
            onClick={(e) => {
              e.stopPropagation();
              if (isSaving) {
                return;
              }
              if (row.pk_FlexEvent) {
                const newShowEvaluatorPicker = clone(showEvaluatorPicker);
                newShowEvaluatorPicker[row.pk_FlexEvent] = true;

                setShowEvaluatorPicker(newShowEvaluatorPicker);
              }
            }}
          />

          <EvaluatorPicker
            updateSlot={(newSlot, index) => {
              const newUnavailables = clone(unavailableSlots);
              newUnavailables[index] = newSlot;
              setUnavailableSlots(newUnavailables);
            }}
            header={header}
            rowIndex={rowIndex}
            flexEvent={row}
            showUnavailabilityEvaluatorPicker={showUnavailabilityEvaluatorPicker}
            setShowUnavailabilityEvaluatorPicker={setShowUnavailabilityEvaluatorPicker}
            showEvaluatorPicker={showEvaluatorPicker}
            setShowEvaluatorPicker={setShowEvaluatorPicker}
            setSelectedRow={setSelectedRow}
            selectedRowRef={selectedRowRef}
          />
        </div>
      </div>
    );
  };

  const renderAction = (cell, row, rowIndex, formatExtraData) => {
    const { id } = row;
    if (id === 'addSlotBar') {
      return;
    }
    return (
      <FlexEventAction
        cell={cell}
        row={row}
        isSaving={isSaving}
        rowIndex={rowIndex}
        formatExtraData={formatExtraData}
        updateSlot={updateSlot}
        slotsToAdd={slotsToAdd}
        setSlotsToAdd={setSlotsToAdd}
        slotsToDelete={slotsToDelete}
        setSlotsToDelete={setSlotsToDelete}
        unavailableSlots={unavailableSlots}
        setUnavailableSlots={setUnavailableSlots}
        setSaveHasResponded={setSaveHasResponded}
        deleteFlexEvent={(pk_FlexEvent) => {
          setIsSaving(true);
          const newKeysToDelete = keysToDelete.slice();
          if (newKeysToDelete.includes(pk_FlexEvent) === false) {
            newKeysToDelete.push(pk_FlexEvent);
          }
          setKeysToDelete(newKeysToDelete);
          deleteFlexEvent([row.pk_FlexEvent], null, null, () => {
            setIsSaving(false);
            setSaveHasResponded(true);

            // reloadData();
          });
        }}
      />
    );
  };

  const processStyle = (cell, row, rowIndex, colIndex) => {
    const { pk_FlexEvent } = row;
    let style = {
      alignItems: 'center',
      verticalAlign: 'middle',
      backgroundColor: rowIndex % 2 == 0 ? 'white' : '#f2f2f2',
    };

    if (row.id === 'addSlotBar') {
      if (colIndex != 0) {
        style.visibility = 'hidden';
      } else {
        style = {
          bottom: 0,
          position: 'sticky',
          backgroundColor: 'white',
        };
      }
    } else {
      if (pk_FlexEvent == null) {
        style.backgroundColor = '#88ff82';
      } else {
        if (slotsToDelete.includes(pk_FlexEvent) || keysToDelete.includes(pk_FlexEvent)) {
          style.backgroundColor = '#f77777';
        }

        if (hasChanges([{ ...row, rowIndex }])) {
          style.backgroundColor = '#ffeb88';
        }
      }
    }

    return style;
  };

  const makeColumns = (data) => {
    const headers = ['Start', 'End', 'Person', 'Action'];
    const newColumns = [];

    headers.forEach((header) => {
      switch (header) {
        case 'Start':
          newColumns.push({
            dataField: 'FlexStartTime',
            text: header,
            formatter: renderStartTime,
            style: processStyle,
            headerStyle: { textAlign: 'center', alignItems: 'center', fontWeight: 'bold', width: '20%' },
            formatExtraData: unavailableSlots,
            attrs: (cell, row, rowIndex, colIndex) => {
              if (row.id === 'addSlotBar') {
                if (colIndex == 0) {
                  return { colSpan: 4 };
                } else {
                  return { hidden: true };
                }
              }
            },
          });
          break;
        case 'End':
          newColumns.push({
            dataField: 'FlexTimeEnd',
            text: header,
            formatter: renderEndTime,
            style: processStyle,
            headerStyle: { textAlign: 'center', alignItems: 'center', fontWeight: 'bold', width: '20%' },
            formatExtraData: unavailableSlots,
            attrs: (cell, row, rowIndex, colIndex) => {
              if (row.id === 'addSlotBar') {
                if (colIndex == 0) {
                  return { colSpan: 4 };
                } else {
                  return { hidden: true };
                }
              }
            },
          });
          break;
        case 'Person':
          newColumns.push({
            dataField: 'evaluators',
            text: header,
            formatter: renderEvaluator,
            style: processStyle,
            headerStyle: { textAlign: 'center', alignItems: 'center', fontWeight: 'bold', width: '45%' },
            formatExtraData: unavailableSlots, // Somehow, data dont update properly without this.
            attrs: (cell, row, rowIndex, colIndex) => {
              if (row.id === 'addSlotBar') {
                if (colIndex == 0) {
                  return { colSpan: 4 };
                } else {
                  return { hidden: true };
                }
              }
            },
          });
          break;
        case 'Action':
          newColumns.push({
            dataField: 'action',
            text: header,
            formatter: renderAction,
            style: processStyle,
            headerStyle: { textAlign: 'center', alignItems: 'center', fontWeight: 'bold', width: '15%' },
            formatExtraData: unavailableSlots,
            attrs: (cell, row, rowIndex, colIndex) => {
              if (row.id === 'addSlotBar') {
                if (colIndex == 0) {
                  return { colSpan: 4 };
                } else {
                  return { hidden: true };
                }
              }
            },
          });
          break;
      }
    });

    return newColumns;
  };

  return (
    <div
      style={style.mainBody}
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <div style={style.tableContainer}>
        {' '}
        <BootstrapTable
          striped={true}
          bordered={false}
          columns={makeColumns(unavailableSlots)}
          data={clone(unavailableSlots)}
          keyField="star"
        />
      </div>
      {unavailableSlots ? renderEvaluatorPickers(unavailableSlots) : null}

      <div style={style.spaceAroundRow}>
        <Button
          style={{ width: 160 }}
          disabled={!hasChanges() || isSaving || isSubmitting || isFetching}
          onClick={(e) => {
            if (slotsToDelete.length > 0) {
              setShowSaveModal(true);
            }

            saveAll();
          }}
          color="success"
        >
          Save All Changes
        </Button>
        <Button
          style={{ width: 160 }}
          color="danger"
          onClick={() => {
            if (hasChanges()) {
              fireUnsavedChangesAlert();
              return;
            }

            setShowUnavailabilityModal(false);
          }}
        >
          Cancel
        </Button>
      </div>
      {renderSaveConfirmModal()}
    </div>
  );
};

export default HeaderUnavailablePanel;
