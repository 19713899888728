import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import imageToBase64 from 'image-to-base64';

import moment from 'moment-timezone';
import tinycolor from 'tinycolor2';
import { clone } from '../../schedule';
import Swal from 'sweetalert2';
import { determineEvent } from '../../../Common.functions';
import rezRateLogo from '../rezRateLogo.png';

let rezRateLogoBase64 = null;
pdfMake.vfs = pdfFonts.pdfMake.vfs;

// 1 inch = 72 units.
// LETTER size is 612x792

const tableLayouts = {
  customTable: {
    hLineWidth: (i, node) => {
      return 0;
    },
    vLineWidth: (i) => {
      return 0;
    },
    hLineColor: (i) => {
      return i === 1 ? 'black' : '#aaa';
    },
    paddingLeft: (i) => {
      return 0;
    },
    paddingRight: (i, node) => {
      return 0;
    },
    paddingTop: (i, node) => {
      return 0;
    },
    paddingBottom: (i, node) => {
      return 0;
    },
    fillColor: function(rowIndex, node, columnIndex) {
      return rowIndex % 2 === 0 ? '#CCCCCC' : 'white'; // Alternating row colors
    },
  },
  headerTable: {
    hLineWidth: (i, node) => {
      return 1;
    },
    vLineWidth: (i) => {
      return 0;
    },
    hLineColor: (i) => {
      return '#aaa';
    },
    paddingLeft: (i) => {
      return 10;
    },
    paddingRight: (i, node) => {
      return 10;
    },
    paddingTop: (i, node) => {
      return 10;
    },
    paddingBottom: (i, node) => {
      return 10;
    },
  },
};

const createPDFProper = (data) => {
  const { interviewDate, department, departmentLogo, evaluators } = data;
  const { metaData, MomentDateOfInterview } = interviewDate;
  const { TimeZone } = department;
  const rows = [];
  let dateToDisplay = '';
  if (metaData) {
    const { DateOfInterview, StartTime } = metaData;
    dateToDisplay = moment.tz(`${DateOfInterview} ${StartTime}`, null).format('MMM DD, YYYY');
  } else if (MomentDateOfInterview) {
    dateToDisplay = MomentDateOfInterview.format('MMM DD, YYYY');
  }

  const renderCard = (evaluator, i) => {
    const cardRows = [];
    const timeSlotRows = [];
    evaluator.body.forEach((slot) => {
      if (slot.isPassingPeriod) {
        return;
      }

      timeSlotRows.push([
        {
          text: moment.tz(slot.StartTime, TimeZone).format('hh:mm A'),
          margin: [15, 2, 0, 2],
          style: { bold: true, alignment: 'right' },
          fontSize: 12,
        },

        {
          columns: [[determineEvent(slot)]],
        },
      ]);
    });

    cardRows.push({
      table: {
        widths: [45, '*', '*'],
        body: [
          [
            {
              image: departmentLogo
                ? `data:image/png;base64,${departmentLogo}`
                : `data:image/png;base64,${rezRateLogoBase64}`,
              fit: [40, 40],
              fillColor: '#E5E5E5',
            },
            {
              text: `${evaluator.metaData.UserFirst} ${evaluator.metaData.UserLast}`,
              style: { alignment: 'left', bold: true },
              margin: [0, 10, 0, 0],
              fillColor: '#E5E5E5',
            },
            {
              text: `${dateToDisplay}`,
              style: { alignment: 'right', bold: true },
              margin: [0, 10, 0, 0],
              fillColor: '#E5E5E5',
            },
          ],
        ],
      },
      layout: 'headerTable',
      pageBreak: i > 0 && timeSlotRows.length <= 0 ? 'before' : null,
      margin: [0, 0, 0, 20],
    });

    if (timeSlotRows.length > 0) {
      cardRows.push({
        table: {
          widths: [140, '*'],
          body: timeSlotRows,
        },
        pageBreak: i < evaluators.length - 1 ? 'after' : null,
        layout: 'customTable',
      });
    }

    return cardRows;
  };

  evaluators.forEach((c, i) => {
    rows.push(renderCard(c, i));
  });

  if (rows.length <= 0) {
    Swal.fire(
      'Error',
      'There are no Schedules for any Evaluator on this Interview Date. There is nothing to print!',
      'error',
    );
    return;
  }
  let pdfObject = rows;

  // imageToBase64(bgGuide)
  //   .then((bgBase64) => {
  const docDefinition = {
    // background: { image: `data:image/png;base64,${bgBase64}`, width: 612 },
    info: { title: `Evaluator Schedule ${dateToDisplay}` },
    pageSize: 'LETTER',
    pageOrientation: 'portrait',
    content: pdfObject,
    defaultStyle: {
      fontSize: 15,
    },
    pageMargins: [0.25 * 72, 0.5 * 72, 0.25 * 72, 72],
  };

  pdfMake.createPdf(docDefinition, tableLayouts).open({}, window.open('', '_blank'));
  // })
  // .catch((err) => {
  // });
};

export const createPDFForEvaluatorSchedule = ({ interviewDate, department, evaluators = [], callback }) => {
  const startProper = () => {
    if (department.DepartmentLogo) {
      imageToBase64(department.DepartmentLogo)
        .then((res) => {
          if (callback) {
            callback();
          }
          createPDFProper({ evaluators, department, departmentLogo: res, interviewDate });
        })
        .catch((err) => {
          if (callback) {
            callback();
          }
          createPDFProper({ evaluators, department, interviewDate });
        });
    } else {
      if (callback) {
        callback();
      }
      createPDFProper({ interviewDate, departmentLogo: rezRateLogoBase64, evaluators, department });
    }
  };
  imageToBase64(rezRateLogo)
    .then((res) => {
      rezRateLogoBase64 = res;
      startProper();
    })
    .catch(() => {
      startProper();
    });
};
