import { faArrowDown, faArrowUp, faBan, faEllipsisH, faPortrait } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import arrayMove from 'array-move';
import moment from 'moment';
import React, { useState, useEffect, useContext } from 'react';
import { useAlert, Provider as AlertProvider } from 'react-alert';

import { Popover, PopoverBody, UncontrolledTooltip } from 'reactstrap';

import { listUserNames, roundToDecimalPlace, scrollToElement } from '../../../../../Common.functions';
import { clone } from '../../../../../Services/schedule';
import style from './AdminRanks.style';
import SortOrderSelector from 'Body/RezRATE/Common/SortOrderSelector/SortOrderSelector';
import Swal from 'sweetalert2';
import { DepartmentContext } from 'DepartmentWrapper';

const determineHeight = (sliderValue, isRaw) => {
  if (isRaw) {
    if (sliderValue == 1) {
      return 30;
    }

    if (sliderValue === 2) {
      return 60;
    }

    if (sliderValue === 3) {
      return 70;
    }
  }
  if (sliderValue == 1) {
    return '30px';
  }

  if (sliderValue === 2) {
    return '60px';
  }

  if (sliderValue === 3) {
    return '70px';
  }

  // if for whatever reason no values are passed in just return the largest
  return '100px';
};

const AdminRanks = ({
  candidate,
  DragHandle,
  sliderValue,
  i,
  candidates,
  setCandidateRanks,
  setSelectedUser,
  selectedUser,
  postChange,
  isFetchingCandidates,
  unique_key,
  setLastJumpTo,
  detailNotesHaveChange,
  viewMode,
  mode,
}) => {
  const [showMovePopover, setShowMovePopover] = useState();
  const [flag, setFlag] = useState();

  const alert = useAlert();
  const dContext = useContext(DepartmentContext);
  const { department } = dContext || {};
  const { ProgramType } = department || {};

  const flagOptions = [
    { label: 'No Change', id: 0, icon: faEllipsisH },
    { label: 'Consider Moving Up', value: 'Up', id: 1, icon: faArrowUp, color: '#38824c' },
    { label: 'Consider Moving Down', id: 2, value: 'Down', icon: faArrowDown, color: '#f77777' },
    { label: 'Consider Removing from Ranked List', id: 3, value: 'Remove', icon: faBan, color: '#f77777' },
  ];

  useEffect(() => {
    if (candidate) {
      const { CandidateConsideration } = candidate;
      if (CandidateConsideration === 'Up') {
        setFlag(flagOptions[1]);
      } else if (CandidateConsideration === 'Down') {
        setFlag(flagOptions[2]);
      } else if (CandidateConsideration === 'Remove') {
        setFlag(flagOptions[3]);
      } else {
        setFlag(flagOptions[0]);
      }
    }
  }, [JSON.stringify(candidate)]);

  const onSortChange = (e) => {
    const { value } = e.target;
    if (detailNotesHaveChange) {
      Swal.fire({
        title: 'Unsaved changes!',
        text:
          'You have unsaved Note changes. Changing Rank order now would permanently discard these changes. Are you sure you want to proceed? This cannot be undone.',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: 'red',
        confirmButtonText: 'Discard',
        cancelButtonColor: 'gray',
        cancelButtonText: 'Cancel',
        reverseButtons: true,
      }).then((result) => {
        if (result.value) {
          setCandidateRanks((prevState) => {
            const candidate = prevState[i];
            const { FirstName, LastName } = candidate;
            const newOrder = arrayMove(prevState, i, Number(value) - 1);

            setSelectedUser(candidate);
            postChange(
              newOrder,
              prevState,
              `${FirstName} ${LastName} moved from position ${i + 1} to position ${Number(value)}`,
              candidate.pk_Candidate,
              i,
              Number(value) - 1,
              () => {
                setLastJumpTo(i + 1);
                scrollToElement(`row_${candidate.pk_Candidate}_${unique_key}`);
              },
            );
            return newOrder;
          });
        }
      });
    } else {
      setCandidateRanks((prevState) => {
        const candidate = prevState[i];
        const { FirstName, LastName } = candidate;
        const newOrder = arrayMove(prevState, i, Number(value) - 1);

        setSelectedUser(candidate);
        postChange(
          newOrder,
          prevState,
          `${FirstName} ${LastName} moved from position ${i + 1} to position ${Number(value)}`,
          candidate.pk_Candidate,
          i,
          Number(value) - 1,
          () => {
            setLastJumpTo(i + 1);
            scrollToElement(`row_${candidate.pk_Candidate}_${unique_key}`);
          },
        );
        return newOrder;
      });
    }
  };

  const formatMessage = (LastName, FirstName, oldFlagValue, newFlag) => {
    let newMessage = '';
    if (newFlag && newFlag.value === 'Remove') {
      newMessage = flagOptions[3].label;
    } else if (newFlag && newFlag.value === 'Up') {
      newMessage = flagOptions[1].label;
    } else if (newFlag && newFlag.value === 'Down') {
      newMessage = flagOptions[2].label;
    } else {
      newMessage = 'None';
    }

    if (!oldFlagValue) {
      return `${LastName}, ${FirstName} flagged as ${newMessage}`;
    }

    if (oldFlagValue) {
      let oldMessage = '';
      if (oldFlagValue === 'Remove') {
        oldMessage = flagOptions[3].label;
      } else if (oldFlagValue === 'Up') {
        oldMessage = flagOptions[1].label;
      } else if (oldFlagValue === 'Down') {
        oldMessage = flagOptions[2].label;
      } else {
        oldMessage = 'None';
      }
      return `${LastName}, ${FirstName}'s flag changed from  ${oldMessage} to ${newMessage}`;
    }
  };

  const onChangeFlag = (newFlag) => {
    if (detailNotesHaveChange) {
      Swal.fire({
        title: 'Unsaved changes!',
        text:
          'You have unsaved Note changes. Changing flags now would permanently discard these changes. Are you sure you want to proceed? This cannot be undone.',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: 'red',
        confirmButtonText: 'Discard',
        cancelButtonColor: 'gray',
        cancelButtonText: 'Cancel',
        reverseButtons: true,
      }).then((result) => {
        if (result.value) {
          setCandidateRanks((prevState) => {
            const newState = clone(prevState);
            const candidate = newState[i];
            const { FirstName, LastName } = candidate;
            const oldFlagValue = candidate.OriginalCandidateConsideration;
            candidate.Flag = newFlag.id;
            candidate.CandidateConsideration = newFlag.value;
            newState[i] = candidate;

            // if user already has this direction, don't change it, just inform the user
            if ((!newFlag && !oldFlagValue) || (newFlag && oldFlagValue === newFlag.value)) {
              alert.error('User already has the selected flag.');
              return prevState;
            }

            const message = formatMessage(LastName, FirstName, oldFlagValue, newFlag);

            postChange(newState, prevState, message, candidate.pk_Candidate, i, i);
            return newState;
          });
        }
      });
    } else {
      setCandidateRanks((prevState) => {
        const newState = clone(prevState);
        const candidate = newState[i];
        const { FirstName, LastName } = candidate;
        const oldFlagValue = candidate.OriginalCandidateConsideration;
        candidate.Flag = newFlag.id;
        candidate.CandidateConsideration = newFlag.value;
        newState[i] = candidate;

        // if user already has this direction, don't change it, just inform the user
        if ((!newFlag && !oldFlagValue) || (newFlag && oldFlagValue === newFlag.value)) {
          alert.error('User already has the selected flag.');
          return prevState;
        }

        const message = formatMessage(LastName, FirstName, oldFlagValue, newFlag);

        postChange(newState, prevState, message, candidate.pk_Candidate, i, i);
        return newState;
      });
    }
  };

  const {
    pk_Rank,
    FirstName,
    LastName,
    PhotoUrl,
    CandidateFinalScoreAsDecimal = 0,
    DateOfInterview,
    UsersWhoMarkedDoNotRank,
    EvalPosition,
    CandidateSubHeading = '',
  } = candidate;

  const ApplicantName = `${LastName || ''}${FirstName ? `, ${FirstName}` : ''}`;

  const rowStyle = {
    cursor: 'pointer',
    maxHeight: 40,
    height: `${10 + sliderValue * 10}px !important`,
    // height: sliderValue > 1 ? 'auto' : '30px !important',
  };

  const cellStyle = {
    verticalAlign: 'middle',
    padding: sliderValue > 1 ? '2px' : 2,
    height: 10,
  };

  const formatInterviewDate = (interviewDate) => {
    if (interviewDate == null || interviewDate.trim() === '') {
      return 'No interview date';
    }
    return moment(DateOfInterview).format('MM/DD/YYYY');
  };

  if (isFetchingCandidates) {
    rowStyle.backgroundColor = '#cad0db';
  } else if (selectedUser && selectedUser.pk_Candidate == candidate.pk_Candidate) {
    rowStyle.backgroundColor = '#d9f5ff';
  } else {
    if (i % 2 == 0) {
      rowStyle.backgroundColor = '#f2f2f2';
    } else {
      rowStyle.backgroundColor = 'white';
    }
  }

  if (viewMode == 'Grid') {
    return (
      <div
        id={`row_${candidate.pk_Candidate}_${unique_key}`}
        style={{ ...style.simpleRow, cursor: 'grab', color: 'black' }}
        onClick={() => {
          if (detailNotesHaveChange) {
            Swal.fire({
              title: 'Unsaved changes!',
              text:
                'You have unsaved changes in the detail notes. Are you sure you want to discard your changes? This cannot be undone.',
              icon: 'warning',
              showCancelButton: true,
              confirmButtonColor: 'red',
              confirmButtonText: 'Discard',
              cancelButtonText: 'Cancel',
            }).then((result) => {
              if (result.isConfirmed) {
                setSelectedUser(candidate);
              }
            });
            return;
          }
          if (selectedUser.pk_Candidate != candidate.pk_Candidate) {
            setSelectedUser(candidate);
          }
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: sliderValue > 1 ? 'center' : 'flex-end',
            alignItems: 'center',
            flexDirection: 'column',
            width: sliderValue > 1 ? determineHeight(sliderValue, true) + 10 : '100%',
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'row',
            }}
          >
            <div
              style={{
                position: 'relative',
                width: sliderValue > 1 ? determineHeight(sliderValue) : 45,
                height: sliderValue > 1 ? determineHeight(sliderValue) : 45,
                flexDirection: 'column',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                paddingLeft: sliderValue > 1 ? 10 : 0,
                width: '100%',
              }}
            >
              {PhotoUrl ? (
                <img
                  alt="Candidate Photo"
                  className="rankImage"
                  style={{
                    objectFit: 'cover',
                    float: 'left',
                    width: sliderValue > 1 ? determineHeight(sliderValue) : 45,
                    height: sliderValue > 1 ? determineHeight(sliderValue) : 45,
                  }}
                  src={PhotoUrl}
                  data-holder-rendered="true"
                />
              ) : (
                <FontAwesomeIcon
                  icon={faPortrait}
                  style={{
                    height: '100%',
                    minWidth: sliderValue > 1 ? determineHeight(sliderValue) : 45,
                    fontSize: 30,
                    float: 'center',
                  }}
                />
              )}
            </div>
          </div>
          {sliderValue == 1 && (
            <div
              style={{
                ...style.candidateName,
                fontSize: 11,
                marginLeft: 5,
                textAlign: 'center',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                width: '100%',
              }}
            >
              {ApplicantName}
            </div>
          )}
          {sliderValue == 1 && (
            <div
              className="card-footer"
              style={{
                borderTop: '1px solid black',
                display: 'flex',
                width: '100%',
                justifyContent: 'center',
                alignItems: 'center',
                height: 30,
                backgroundColor: 'gray',
                opacity: 0.8,
              }}
            >
              <div
                style={{
                  ...style.candidateName,
                  fontSize: 19,
                  textAlign: 'center',
                  fontWeight: 'bold',
                }}
              >
                {EvalPosition}
              </div>
            </div>
          )}
        </div>
        {sliderValue > 1 ? (
          <>
            {' '}
            <div
              style={{
                ...style.simpleColumn,
                justifyContent: 'flex-start',
                alignItems: 'left',
                paddingTop: 5,
                paddingRight: 5,
                width:
                  sliderValue > 1
                    ? `calc(100% - ${determineHeight(sliderValue, true) + 40 + (sliderValue - 1) * 10}px)`
                    : '100%',
              }}
            >
              {sliderValue == 2 && (
                <>
                  <div
                    style={{
                      ...style.candidateName,
                      fontSize: 12,
                      marginLeft: 5,
                      fontWeight: 'bold',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    }}
                  >
                    {ApplicantName}
                  </div>
                  <div
                    style={{
                      ...style.candidateSchool,
                      fontSize: 11,
                      marginLeft: 5,
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    }}
                  >
                    {CandidateSubHeading || ''}
                  </div>
                </>
              )}
              {sliderValue === 3 && (
                <>
                  <div
                    style={{
                      ...style.candidateName,
                      fontSize: 13,
                      marginLeft: 5,
                      fontWeight: 'bold',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    }}
                  >
                    {ApplicantName}
                  </div>
                  <div
                    style={{
                      ...style.candidateSchool,
                      fontSize: 11,
                      marginLeft: 5,
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    }}
                  >
                    {CandidateSubHeading || ''}
                  </div>
                </>
              )}
              {sliderValue > 1 && (
                <>
                  <div
                    style={{
                      ...style.candidateName,
                      fontSize: 13,
                      marginLeft: 5,
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    }}
                  >
                    Score: {roundToDecimalPlace(100 * CandidateFinalScoreAsDecimal, 2)}
                  </div>

                  {UsersWhoMarkedDoNotRank && UsersWhoMarkedDoNotRank.length > 0 ? (
                    <div
                      id={`DNR_${pk_Rank}`}
                      style={{
                        ...style.candidateSchool,
                        fontSize: 13,
                        marginLeft: 5,
                        fontWeight: 'bold',
                        color: 'red',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                      }}
                    >
                      {UsersWhoMarkedDoNotRank.length} {mode === 2 ? 'DNI' : 'DNR'}
                      <UncontrolledTooltip target={`DNR_${pk_Rank}`}>
                        {UsersWhoMarkedDoNotRank.map((user) => {
                          const { UserFirst, UserLast } = user;
                          return (
                            <div>
                              {UserLast ? `${UserLast}, ` : ''}
                              {UserFirst || ''}
                            </div>
                          );
                        })}
                      </UncontrolledTooltip>
                    </div>
                  ) : null}
                </>
              )}
            </div>
            <div
              style={{
                ...style.simpleColumn,

                borderLeft: '1px solid black',
                display: 'flex',
                // width: '100%',
                justifyContent: 'center',
                alignItems: 'center',
                width: 40 + (sliderValue - 1) * 10,
                backgroundColor: 'gray',
                opacity: 0.8,
              }}
            >
              <div
                style={{
                  ...style.candidateName,
                  fontSize: sliderValue == 3 ? 19 : 16,
                  textAlign: 'center',
                  fontWeight: 'bold',
                }}
              >
                {EvalPosition}
              </div>
            </div>
          </>
        ) : null}
        <div
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            display: 'flex',
            // backgroundColor: 'red',
          }}
        ></div>
      </div>
    );
  } else {
    return (
      <tr
        id={`row_${candidate.pk_Candidate}_${unique_key}`}
        onClick={() => {
          if (detailNotesHaveChange) {
            Swal.fire({
              title: 'Unsaved changes!',
              text:
                'You have unsaved changes in the detail notes. Are you sure you want to discard your changes? This cannot be undone.',
              icon: 'warning',
              showCancelButton: true,
              confirmButtonColor: 'red',
              confirmButtonText: 'Discard',
              cancelButtonText: 'Cancel',
            }).then((result) => {
              if (result.isConfirmed) {
                setSelectedUser(candidate);
              }
            });
            return;
          }
          if (selectedUser.pk_Candidate != candidate.pk_Candidate) {
            setSelectedUser(candidate);
          }
        }}
        style={rowStyle}
      >
        <td style={cellStyle}>
          <div style={style.simpleRow}>
            <div
              className="dragHandleArea"
              style={{
                display: 'flex',
                flexDirection: 'column',
                // marginLeft: 20,
                // marginRight: 20,
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <DragHandle
                sliderValue={sliderValue}
                backgroundColor={rowStyle.backgroundColor}
                pk_Candidate={candidate.pk_Candidate}
              />
            </div>
            <div>
              <SortOrderSelector
                rowIndex={i}
                itemChoices={candidates}
                onSortChange={onSortChange}
                unique_key={`rank_${candidate.pk_Candidate}_${unique_key}`}
                fontSize={12 + sliderValue}
              />
            </div>
          </div>
        </td>
        <td style={cellStyle}>
          <div style={{ paddingLeft: 5, paddingRight: 5 }}>
            <FontAwesomeIcon
              id={`popover_trigger_${candidate.pk_Candidate}_${i}_${unique_key}`}
              icon={flag ? flag.icon : faEllipsisH}
              style={{ fontSize: 25, cursor: 'pointer', color: flag ? flag.color : null }}
              onClick={(e) => {
                e.stopPropagation();
                setShowMovePopover(true);
              }}
            />

            <Popover
              popperClassName="flagPopover"
              key={`candidate_${candidate.pk_Candidate}_${i}_popover`}
              id={`candidate_${candidate.pk_Candidate}_${i}_popover`}
              target={`popover_trigger_${candidate.pk_Candidate}_${i}_${unique_key}`}
              trigger="legacy"
              flip={false}
              isOpen={showMovePopover}
              toggle={() => {
                setShowMovePopover(!showMovePopover);
              }}
            >
              <PopoverBody>
                <div style={{ ...style.simpleColumn, overflowY: 'scroll' }}>
                  {flagOptions.map((flagOption, i) => {
                    const backgroundColor =
                      (flagOption.id == 0 && flag == null) || (flag && flagOption.id == flag.id)
                        ? '#d9f5ff'
                        : i % 2 == 0
                        ? '#c7cbd1'
                        : null;
                    return (
                      <div
                        style={{ ...style.flagItem, backgroundColor, cursor: 'pointer' }}
                        onClick={(e) => {
                          e.stopPropagation();
                          setFlag(flagOption);
                          setShowMovePopover(false);
                          onChangeFlag(flagOption);
                          setShowMovePopover(false);
                        }}
                      >
                        <FontAwesomeIcon
                          icon={flagOption.icon}
                          style={{ marginLeft: 10, fontSize: 20, color: flagOption.color }}
                        />{' '}
                        <div style={{ textAlign: 'center', width: '100%' }}>{flagOption.label}</div>
                      </div>
                    );
                  })}
                </div>
              </PopoverBody>
            </Popover>
          </div>
        </td>
        <td style={cellStyle}>
          <span style={{ textAlign: 'left' }}>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <div
                style={{
                  position: 'relative',
                  width: determineHeight(sliderValue),
                  height: determineHeight(sliderValue),
                }}
              >
                {PhotoUrl && sliderValue > 1 ? (
                  <img
                    alt="Candidate Photo"
                    className="rankImage"
                    style={{
                      objectFit: 'cover',
                      float: 'left',
                      width: determineHeight(sliderValue),
                      height: determineHeight(sliderValue),
                      marginRight: '10px',
                    }}
                    src={PhotoUrl}
                    data-holder-rendered="true"
                  />
                ) : sliderValue > 1 ? (
                  <FontAwesomeIcon
                    icon={faPortrait}
                    style={{ height: '100%', minWidth: 55, fontSize: 30, float: 'left', marginRight: '10px' }}
                  />
                ) : null}
              </div>
              {sliderValue == 1 && (
                <div style={{ ...style.simpleColumn, verticalAlign: 'middle' }}>
                  <div style={{ ...style.candidateName, marginLeft: 10, textAlign: 'center', fontSize: 14 }}>
                    {ApplicantName}
                  </div>
                </div>
              )}
              {sliderValue == 2 && (
                <div style={{ ...style.simpleColumn, verticalAlign: 'middle' }}>
                  {/* <div>
                  s_pk: {selectedUser.pk_Candidate} c_pk: {candidate.pk_Candidate}
                </div> */}
                  <div style={{ ...style.candidateName, fontSize: 14, marginLeft: 10, fontWeight: 'bold' }}>
                    {ApplicantName}
                  </div>
                  <div style={{ ...style.candidateSchool, fontSize: 12, marginLeft: 10 }}>
                    {CandidateSubHeading || ''}
                  </div>
                </div>
              )}

              {sliderValue === 3 && (
                <div style={{ ...style.simpleColumn, verticalAlign: 'middle' }}>
                  <div style={{ ...style.candidateName, fontSize: 16, marginLeft: 10, fontWeight: 'bold' }}>
                    {ApplicantName}
                  </div>
                  <div style={{ ...style.candidateSchool, fontSize: 13, marginLeft: 10 }}>
                    {CandidateSubHeading || ''}
                  </div>
                </div>
              )}
            </div>
          </span>
        </td>
        <td style={cellStyle}>{formatInterviewDate(DateOfInterview)}</td>
        <td style={cellStyle}>
          {' '}
          <div style={{ color: UsersWhoMarkedDoNotRank && UsersWhoMarkedDoNotRank.length > 0 ? 'red' : null }}>
            {' '}
            <b id={`key_${candidate.pk_Candidate}_${unique_key}`}>
              {UsersWhoMarkedDoNotRank ? UsersWhoMarkedDoNotRank.length : 0}
              {UsersWhoMarkedDoNotRank && UsersWhoMarkedDoNotRank.length > 0 ? (
                <UncontrolledTooltip target={`key_${candidate.pk_Candidate}_${unique_key}`} style={{ width: 150 }}>
                  {listUserNames(UsersWhoMarkedDoNotRank).map((name) => {
                    return <div>{name}</div>;
                  })}
                </UncontrolledTooltip>
              ) : null}
            </b>
          </div>
        </td>
        <td style={cellStyle}>
          <strong>{roundToDecimalPlace(100 * CandidateFinalScoreAsDecimal, 2)}</strong>
        </td>
      </tr>
    );
  }
};

export default AdminRanks;
