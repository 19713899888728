const style = {
  mainBody: {
    padding: 10,
  },
  tableContainer: {
    // backgroundColor: '#d9f5ff',
    height: 430,
    marginBottom: 10,
    marginTop: 10,
    overflowY: 'scroll',
  },
  spaceBetweenRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  spaceAroundRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
  },
  centeredRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    width: '100%',
  },
  rightedRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'right',
    alignItems: 'center',
  },
  flexEndRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  clickableIcon: {
    cursor: 'pointer',
    marginLeft: 5,
    marginRight: 5,
  },
  userItem: {
    paddingTop: 5,
    paddingBottom: 5,
    paddingLeft: 5,
    fontWeight: 600,
  },
  confirmButtons: {
    width: 80,
  },
};
export default style;
