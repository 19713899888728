const style = {
  endAlignRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    width: '100%',
  },
  centerAlignRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    width: '100%',
  },
  simpleColumn: {
    display: 'flex',
    flexDirection: 'column',
    padding: 5,
  },
  simpleRow: {
    display: 'flex',
    flexDirection: 'row',
  },
  footerContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'center',
  },
  saveButton: {
    width: 200,
  },
};
export default style;
