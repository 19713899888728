const style = {
  rightedRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'right',
    alignItems: 'center',
  },
  clickableIcon: {
    cursor: 'pointer',
    marginLeft: 5,
    marginRight: 5,
  },
  confirmButtons: {
    width: 80,
  },
  centeredRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    width: '100%',
  },
};

export default style;
