import { faCheckSquare, faSquare } from '@fortawesome/free-regular-svg-icons';
import { faUsers } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import style from './style';

const GroupItem = ({ Group, selectedGroup, candidate, index, onClickGroup }) => {
  //   const isInSelectedTags = selectedGroups.find((selectedGroup) => {
  //     return selectedGroup.Tag == tag.Tag;
  //   });

  //   const isInOldTags = oldTags.find((selectedGroup) => {
  //     return selectedGroup.Tag == tag.Tag;
  //   });

  const isNewGroup =
    selectedGroup &&
    selectedGroup.pk_Group === Group.pk_Group &&
    candidate.Group &&
    candidate.Group.pk_Group !== selectedGroup.pk_Group;
  const isRemovedGroup =
    candidate.Group &&
    selectedGroup &&
    candidate.Group.pk_Group == Group.pk_Group &&
    candidate.Group.pk_Group !== selectedGroup.pk_Group;

  const isSelectedGroup = selectedGroup && selectedGroup.pk_Group === Group.pk_Group;

  let backgroundColor = index % 2 == 0 ? '#dee2e6' : null;

  if (isNewGroup) {
    backgroundColor = '#88ff82';
  } else if (isRemovedGroup) {
    backgroundColor = '#f77777';
  } else if (isSelectedGroup) {
    backgroundColor = '#d9f5ff';
  }

  return (
    <div
      style={{ ...style.listItem, backgroundColor }}
      onClick={() => {
        if (onClickGroup) {
          onClickGroup(Group);
          //   onClickGroup(tag, isInSelectedTags);
        }
      }}
    >
      <div>
        <FontAwesomeIcon icon={faUsers} />
      </div>
      <div style={{ width: '100%', textAlign: 'left', marginLeft: 10 }}>{Group.Name}</div>{' '}
      <div>
        <FontAwesomeIcon icon={isSelectedGroup ? faCheckSquare : faSquare} />
      </div>
    </div>
  );
};

export default GroupItem;
