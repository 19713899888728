import styled, { css } from 'styled-components';
import { Col } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const sizes = {
  phonePortrait: '300',
  phoneLandscape: '480',
  tabletPortrait: '768',
  tabletLandscape: '1024',
};

// creates media query format using sizes variable
const cssSizes = Object.keys(sizes).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media (max-width: ${sizes[label]}px) {
      ${css(...args)};
    }
  `;
  return acc;
}, {});

const ColNoPadding = styled(Col)`
  padding: 0 !important;
`;

const CustomFontAwesomeIcon = styled(FontAwesomeIcon)`
  color: ${(props) => props.color};
`;

const DraggableItemWrapper = styled.div`
  position: absolute;
  background-color: #f0f0f0;
  width: 50vw;
  max-width: 400px;
  overflow: visible;
  cursor: pointer;
  pointer-events: auto;
  transform-origin: 50% 50% 0px;
  border-radius: 4px;
  color: rgb(153, 153, 153);
  height: 70px;
  // line-height: 70px;
  font-size: 24px;
  text-align: left;
  font-weight: 400;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  white-space: nowrap;
  background-color: ${(props) => props.backgroundColor} !important;
  ${cssSizes.phoneLandscape` 
    width: calc(100vw - 55px) !important;
  `}
`;

export { DraggableItemWrapper, cssSizes, ColNoPadding, sizes, CustomFontAwesomeIcon };
