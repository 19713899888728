const style = {
  simpleRow: {
    display: 'flex',
    flexDirection: 'row',
  },
  simpleColumn: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  spaceBetweenRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  spaceAroundRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
  },
  notifItem: {
    marginBottom: 20,
  },
  alignedRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  checkbox: {
    marginRight: 10,
    fontSize: 22,
    cursor: 'pointer',
  },
};
export default style;
