import { formatBearerToken } from '../Common.functions';
import { assignColorToCandidates } from './candidates';
import { fetchDataAgnostic } from './dataApi';

export const fetchCandidates = (token, options = {}) => {
  const { resolve, reject, pk_Department, callback, pk_Season } = options;

  fetchDataAgnostic(
    'department/candidates',
    {
      pk_Department,
      pk_Season,
    },
    formatBearerToken(token),
  )
    .then((res) => {
      let newCandidates = { ...res.data };
      newCandidates = assignColorToCandidates(newCandidates);

      if (callback) {
        callback(newCandidates);
      }

      if (resolve) {
        resolve(newCandidates);
        return;
      }

      return newCandidates;
    })
    .catch((e) => {
      if (callback) {
        callback([]);
      }

      if (reject) {
        reject(e);
        return;
      }
      return e;
    });
};
