import React, { useContext, useEffect, useState } from 'react';
import SingleCandidateDragAndDropItem from '../../../../../../../DragAndDropFiles/SingleCandidateDragAndDropItem';
import { Button, Input, Label, Popover, PopoverBody, PopoverHeader, UncontrolledPopover } from 'reactstrap';

import style from './EditAttachmentPopover.style';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { constructUrl, deleteData, putData } from 'Services/dataApi';
import { formatBearerToken } from 'Common.functions';
import { DepartmentContext } from 'DepartmentWrapper';
import { post } from 'axios';
import { useAuth0 } from 'auth0/reactAuth0Spa';
import Swal from 'sweetalert2';
import DragAndDropGrid from '../DragAndDropGrid/DragAndDropGrid';
import { useAlert } from 'react-alert';

const EditAttachmentPopover = ({
  target,
  onSave,
  onDelete,
  onCancel,
  getAttachments,
  setShowEditAttachmentPopover,
  showEditAttachmentPopover,
  uniqueId = '',
  attachment,
}) => {
  // const [myAttachment, setMyAttachment] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [currentProgress, setCurrentProgress] = useState(0);
  const [file, setFile] = useState(null);
  const [Name, setName] = useState('');
  const NameCountLimit = 250;
  // const { Name = '', file } = newAttachment;

  const dContext = useContext(DepartmentContext);
  const { department, season } = dContext;
  const { getTokenSilently } = useAuth0();
  const alert = useAlert();

  useEffect(() => {
    if (attachment) {
      setName(attachment.DisplayName || '');
      setFile({ ...file, ...attachment });
    }
  }, [attachment]);

  // useEffect(() => {
  //   if (!showEditAttachmentPopover) {
  //     setName('');
  //     setFile(null);
  //   }
  // }, [showEditAttachmentPopover]);

  const prepareFileForUpload = (file) => {
    const formData = new FormData();
    const fileJson = {
      displayName: Name,
      name: file.name,
      pk_Department: department.pk_Department,
      pk_Attachment: attachment.pk_Attachment,
      DocumentUUID: attachment.DocumentUUID,
    };

    const jsonToSend = JSON.stringify(fileJson);

    formData.append(`File`, file, jsonToSend);

    return formData;
  };

  const fileUpload = (formData) => {
    const url = constructUrl('files/communications/attachment');

    setIsUploading(true);
    getTokenSilently().then((token) => {
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
          ...formatBearerToken(token),
        },
        onUploadProgress: function(progressEvent) {
          var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          setCurrentProgress(percentCompleted);
        },
      };
      post(url, formData, config)
        .then((res) => {
          alert.success('File uploaded successfully');
          getAttachments();
          setShowEditAttachmentPopover(false);
          // handleSingleCandidateUpload(res.data);
        })
        .catch((err) => {
          Swal.fire('Oops', 'Something went wrong uploading file. Please try again or contact support', 'error').then(
            () => {
              // clearQueue();
            },
          );
        })
        .finally(() => {
          setCurrentProgress(0);
          setIsUploading(false);
        });
    });
  };

  const deleteFile = () => {
    setIsUploading(true);
    getTokenSilently()
      .then((token) => {
        deleteData(
          'files/communications/attachment',
          {
            pk_Department: dContext.department.pk_Department,
            // pk_Season: dContext.season.pk_Season,
          },
          {
            pk_Attachment: attachment.pk_Attachment,
          },
          formatBearerToken(token),
        )
          .then((res) => {
            alert.success('DELETED!');
            setShowEditAttachmentPopover(false);
          })
          .catch((err) => {
            alert.error('FAILED TO DELETE!');
          })
          .finally(() => {
            getAttachments();
            setIsUploading(false);
          });
      })
      .catch((err) => {
        alert.error('FAILED TO DELETE!');
        setIsUploading(false);
      });
  };

  const updateAttachmentName = () => {
    setIsLoading(true);
    getTokenSilently()
      .then((token) => {
        putData(
          'department/attachment',
          {
            pk_Department: department.pk_Department,
            // pk_Season: season.pk_Season,
            pk_Attachment: attachment.pk_Attachment,
          },
          { DisplayName: Name, uuid: attachment.DocumentUUID },
          formatBearerToken(token),
        )
          .then((res) => {
            alert.success('Successfully changed name!');
            setShowEditAttachmentPopover(false);
            setShowEditAttachmentPopover(false);
          })
          .catch((err) => {
            console.log('updateAttachmentName err: ', err);
            alert.error('Failed to update attachment name!');
          })
          .finally(() => {
            getAttachments();
            setIsLoading(false);
          });
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  return (
    <Popover
      target={target}
      trigger="legacy"
      modifiers={{ flip: { behavior: ['left'] }, preventOverflow: { boundariesElement: 'viewport' } }}
      placementPrefix="editAttachmentPopover_ attachmentsPopover bs-popover"
      id={`editAttachmentPopover_${uniqueId}`}
      isOpen={showEditAttachmentPopover}
      placement="left"
      onClick={(e) => {
        e.stopPropagation();
      }}
      toggle={(e) => {
        if (e.target.className.includes && e.target.className.includes('swal2')) {
          return;
        }
        const self = document.getElementById(`editAttachmentPopover_${uniqueId}`);
        const eventTarget = e.target;

        if (self && eventTarget && self.contains(eventTarget)) {
          return;
        }
      }}
    >
      <PopoverHeader>
        <div style={style.spacedBetweenRow}>
          <div style={style.buttonLabel}>Edit Attachment</div>
          <div>
            <FontAwesomeIcon
              icon={faTimesCircle}
              style={{ cursor: 'pointer' }}
              onClick={() => {
                setShowEditAttachmentPopover(false);
              }}
            />
          </div>
        </div>
      </PopoverHeader>
      <PopoverBody>
        <div style={style.simpleRow}>
          <div style={{ ...style.simpleColumn, width: '100%' }}>
            <div style={style.simpleRow}>
              <div style={{ ...style.simpleColumn, width: '100%' }}>
                <Label for="fileName">Attachment Name</Label>
                <Input
                  id="fileName"
                  value={Name}
                  type="text"
                  onChange={(e) => {
                    if (e.target.value.length > NameCountLimit) {
                      return;
                    }
                    setName(e.target.value);
                    // setNewAttachment({ ...newAttachment, Name: e.target.value });
                  }}
                />
              </div>
            </div>

            <div style={{ borderBottom: '3px #75b1fa solid', width: `${currentProgress}%`, marginTop: 5 }}></div>
            <div style={style.simpleRow}>
              <DragAndDropGrid
                attachedDoc={file}
                typeRestrictions={'.jpg, .jpeg, .pdf, .png, .doc, .xls, .xlsx, .docx, .ppt, .pptx'}
                setParentFile={(newFile) => {
                  setFile(newFile);

                  if (!Name) {
                    setName(newFile.name.replace(/\.[^.]*$/, ''));
                  }
                }}
              />
            </div>
            <div style={style.spacedBetweenRow}>
              <Button
                disabled={isUploading}
                size="sm"
                color="danger"
                style={{ width: '32.9%' }}
                onClick={() => {
                  Swal.fire({
                    title: 'Are you Sure?',
                    text: 'This will permanently delete this attachment.',
                    showCancelButton: true,
                    // confirmButtonText: 'Reset',

                    confirmButtonColor: '#d33',
                    cancelButtonColor: 'gray',
                  }).then((res) => {
                    if (res.value) {
                      deleteFile();
                    }
                  });

                  // Swal.fire('Are you Sure?', 'This will permanently delete this attachment', 'warning').then((res) => {
                  //   if (res.value) {
                  //     deleteFile();
                  //   }
                  // });
                }}
              >
                Delete
              </Button>
              <Button
                disabled={isUploading}
                size="sm"
                color="secondary"
                style={{ width: '32.9%' }}
                onClick={() => {
                  setShowEditAttachmentPopover(false);
                }}
              >
                Cancel
              </Button>
              <Button
                disabled={isUploading}
                size="sm"
                color="success"
                style={{ width: '32.9%' }}
                onClick={() => {
                  if (file.name) {
                    // Meaning we have a file with a name - meaning the user dragged n dropped a new file to replace the existing one.
                    fileUpload(prepareFileForUpload(file));
                  } else {
                    // Meaning we have a file with no name, meaning this file was NOT
                    // dragged n dropped - this is from db. Which means all user did was type a name, CHANGE a name of an existing attachemnt.
                    updateAttachmentName();
                  }
                }}
              >
                Save
              </Button>
            </div>
          </div>
        </div>
      </PopoverBody>
    </Popover>
  );
};

export default EditAttachmentPopover;
