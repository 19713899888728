const style = {
  simpleRow: {
    display: 'flex',
    flexDirection: 'row',
  },
  simpleColumn: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
};
export default style;
