const style = {
  simpleRow: {
    display: 'flex',
    flexDirection: 'row',
  },

  simpleColumn: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  spacedBetweenRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  inputFieldContainer: {
    marginTop: 10,
    width: '100%',
  },
  inputField: {
    width: '100%',
  },
  subTitleContainer: {
    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: 14,
    borderBottom: '1px solid #91908c',
    marginTop: 20,
    marginBottom: 10,
  },
};

export default style;
