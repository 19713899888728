import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState, useEffect } from 'react';
import { Button, Modal, ModalBody, Input } from 'reactstrap';
import moment from 'moment-timezone';

import { faPlus, faTimes } from '@fortawesome/free-solid-svg-icons';
import FlexEventItem from './FlexEventItem/FlexEventItem';
import AddFlexEventModal from './AddFlexEventModal/AddFlexEventModal';
import EditFlexEventModal from './EditFlexEventModal/EditFlexEventModal';

import style from './FlexEventDetailModal.style';
import { clone } from 'lodash';

const FlexEventDetailModal = ({
  candidates,
  columnTracker,
  dateIsInThePast,
  evaluators = [],
  fireConfirmationForEditingPastSchedule,
  forcedContext,
  indexedFlexEventEvaluators,
  isOpen,
  pk_InterviewDate,
  rowTracker,
  scheduleData,
  selectedCell,
  setSelectedCell,
  setShowFlexEventDetailModal,
  showPastScheduleWarning,
  updateAllData,
  willEditThePast,
  VirtualMeetingMode,
}) => {
  const [showAddFlexEventModal, setShowAddFlexEventModal] = useState(false);
  const [showEditFlexEventModal, setShowEditFlexEventModal] = useState(false);

  const [flexEventToEdit, setFlexEventToEdit] = useState(null);
  const [FlexEvents, setFlexEvents] = useState([]);
  const [timeSlot, setTimeSlot] = useState();
  const [wasOpen, setWasOpen] = useState(false);

  const { metaData = [] } = scheduleData || {};

  useEffect(() => {
    if (isOpen) {
      setWasOpen(true);
      if (selectedCell) {
        const newRow = selectedCell.row;
        setTimeSlot(JSON.stringify(newRow));
        setFlexEvents(newRow.FlexEvents || []);
      } else if (!selectedCell) {
        setTimeSlot('all');
        extractAllFlexEvents();
      }
    } else {
      if (wasOpen) {
        // Only clear selected cell if the modal was open
        setSelectedCell(null);
      }

      setWasOpen(false);
    }
  }, [isOpen, selectedCell ? JSON.stringify(selectedCell) : null]);

  useEffect(() => {
    if (scheduleData && metaData.FlexEvents) {
      extractAllFlexEvents();
    }
  }, [metaData]);

  const extractAllFlexEvents = () => {
    const { metaData = {}, headers = [] } = scheduleData || {};
    const schedFlexEvents = metaData.FlexEvents ? metaData.FlexEvents.slice() : [];
    const evaluatorFlexEvents = [];

    const allFlexEvents = [...schedFlexEvents];
    setFlexEvents(allFlexEvents);
  };

  const renderAddFlexEventModal = () => {
    return (
      <AddFlexEventModal
        candidates={candidates}
        columnTracker={columnTracker}
        dateIsInThePast={dateIsInThePast}
        evaluators={evaluators}
        fireConfirmationForEditingPastSchedule={fireConfirmationForEditingPastSchedule}
        forcedContext={forcedContext}
        indexedFlexEventEvaluators={indexedFlexEventEvaluators}
        isOpen={showAddFlexEventModal}
        pk_InterviewDate={pk_InterviewDate}
        rowTracker={rowTracker}
        scheduleData={scheduleData}
        selectedCell={selectedCell}
        setSelectedCell={setSelectedCell}
        selectedTimeSlot={timeSlot}
        setShowAddFlexEventModal={setShowAddFlexEventModal}
        setShowFlexEventDetailModal={setShowFlexEventDetailModal}
        updateAllData={updateAllData}
        willEditThePast={willEditThePast}
      />
    );
  };

  const renderEditFlexEventModal = () => {
    return (
      <EditFlexEventModal
        candidates={candidates}
        columnTracker={columnTracker}
        dateIsInThePast={dateIsInThePast}
        evaluators={evaluators}
        fireConfirmationForEditingPastSchedule={fireConfirmationForEditingPastSchedule}
        flexEventToEdit={flexEventToEdit}
        forcedContext={forcedContext}
        indexedFlexEventEvaluators={indexedFlexEventEvaluators}
        isOpen={showEditFlexEventModal}
        pk_InterviewDate={pk_InterviewDate}
        rowTracker={rowTracker}
        scheduleData={scheduleData}
        selectedCell={selectedCell}
        setSelectedCell={setSelectedCell}
        setShowEditFlexEventModal={setShowEditFlexEventModal}
        setShowFlexEventDetailModal={setShowFlexEventDetailModal}
        showPastScheduleWarning={showPastScheduleWarning}
        updateAllData={updateAllData}
        willEditThePast={willEditThePast}
      />
    );
  };

  return (
    <>
      <Modal isOpen={isOpen} centered={true} style={{ minWidth: '80vw' }}>
        <div style={{ ...style.spaceBetweenRow, alignItems: 'center' }} className="modal-header">
          <div style={{ fontWeight: 'bold' }}>Flex Events</div>
          <div>
            <Input
              type="select"
              style={{ width: 200 }}
              value={timeSlot}
              onChange={(e) => {
                if (e.target.value !== 'all') {
                  const row = JSON.parse(e.target.value);
                  setTimeSlot(row);
                  setFlexEvents(row.FlexEvents || []);
                } else {
                  extractAllFlexEvents();
                }

                setTimeSlot(e.target.value);
              }}
            >
              {scheduleData ? (
                <>
                  <option disabled selected>
                    Select Time Slot
                  </option>
                  <option value={'all'}>All Flex Events</option>
                  {scheduleData.body.map((row) => {
                    return (
                      <option value={JSON.stringify(row)} key={row.pk_Timeslot}>
                        {moment.tz(row.slot, null).format('hh:mm A')} - {moment(row.slotEnd).format('hh:mm A')}
                      </option>
                    );
                  })}
                </>
              ) : (
                <option disabled>No Timeslots</option>
              )}
            </Input>
          </div>

          <div style={{ ...style.simpleColumn }}>
            <div style={{ ...style.simpleRow, alignItems: 'center' }}>
              <Button
                size="sm"
                color="success"
                onClick={async () => {
                  if (dateIsInThePast && !willEditThePast) {
                    const continueProcess = await fireConfirmationForEditingPastSchedule();
                    if (!continueProcess) {
                      return;
                    }
                  }
                  setShowAddFlexEventModal(true);
                }}
              >
                <FontAwesomeIcon icon={faPlus} />
              </Button>
              <FontAwesomeIcon
                icon={faTimes}
                style={{ marginLeft: 20, cursor: 'pointer' }}
                onClick={() => {
                  setSelectedCell(null);
                  setShowFlexEventDetailModal(false);
                }}
              />
            </div>
          </div>
        </div>
        <ModalBody>
          <div style={{ ...style.simpleColumn, height: 300, overflowY: 'scroll' }}>
            {FlexEvents && FlexEvents.length > 0 ? (
              FlexEvents.map((FlexEvent, i) => {
                if (
                  (FlexEvent.FlexEventName && FlexEvent.FlexEventName.toLowerCase() !== 'unavailable') ||
                  (FlexEvent.EventName && FlexEvent.EventName.toLowerCase() !== 'unavailable')
                ) {
                  return (
                    <FlexEventItem
                      key={`flex_event_${FlexEvent.pk_FlexEvent}`}
                      flexEvent={FlexEvent}
                      scheduleData={scheduleData}
                      VirtualMeetingMode={VirtualMeetingMode}
                      setFlexEventToEdit={(newFlexEvent) => {
                        setFlexEventToEdit(newFlexEvent);
                        setShowEditFlexEventModal(true);
                      }}
                    />
                  );
                }
              })
            ) : (
              <div style={{ textAlign: 'center' }}>No Flex Events</div>
            )}
          </div>
        </ModalBody>
      </Modal>
      {renderAddFlexEventModal()}
      {renderEditFlexEventModal()}
    </>
  );
};

export default FlexEventDetailModal;
