const style = {
  spacedBetweenRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  label: {
    fontSize: 14,
    textAlign: 'center',
    width: '100%',
  },
  userList: {
    display: 'flex',
    flexDirection: 'column',
    maxHeight: 350,
    minWidth: '100%',
    overflowY: 'scroll',
    marginBottom: 20,
  },
  genButton: {
    alignItems: 'center',
    backgroundColor: '#0a91ff',
    display: 'flex',
    flexDirection: 'row',
    fontWeight: 600,
    justifyContent: 'space-between',
    marginLeft: 10,
    marginRight: 10,
    paddignRight: 20,
    paddingBottom: 10,
    paddingLeft: 20,
    paddingTop: 10,
    width: '100%',
  },
  simpleRow: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 10,
  },
  userItem: {
    cursor: 'pointer',
    fontWeight: 600,
    paddingBottom: 5,
    paddingLeft: 5,
    paddingTop: 5,
  },
};

export default style;
