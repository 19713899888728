const style = {
  documentTypesVisibilityLabel: {
    textAlign: 'end',
    width: '100%',
  },
  documentTypeListContainer: {
    overflowY: 'scroll',
    height: 205,
  },
  documentTypeAddButtonContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'spaced-between',
    alignItems: 'center',
    paddingBottom: 5,
    paddingTop: 5,
    marginTop: 20,
  },
  manageFieldsRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'spaced-between',
    alignItems: 'center',
    borderBottom: '1px solid #b6b6b8',
    paddingBottom: 5,
    paddingTop: 5,
  },
  manageFieldsLabel: {
    alignItems: 'center',
    textAlign: 'center',
    width: '100%',
  },
  manageFieldsName: {
    alignItems: 'center',
    textAlign: 'center',
    width: '80%',
    marginRight: 5,
    marginLeft: 5,
  },
  manageFieldsNewName: {
    alignItems: 'center',
    textAlign: 'center',
    width: '90%',
    marginRight: 5,
    marginLeft: 5,
  },
  manageFieldsNewIcon: {
    marginRight: 5,
  },
  centeredText: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    textAlign: 'center',
  },
};

export default style;
