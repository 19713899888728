import React, { useState } from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import { Col, Nav, NavItem, Row } from 'reactstrap';

const AdminCommunicationsNavigation = ({ activeTab = 1, setActiveTab }) => {
  // const [activeTab, setActiveTab] = useState(1);
  const { path } = useRouteMatch();

  const toggle = (selectedTab) => {
    setActiveTab(selectedTab);
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'row' }}>
      <Nav className="nav-tabs">
        <NavItem onClick={() => toggle(1)} className="nav-item">
          <Link className={'nav-link ' + (activeTab === 1 ? 'active' : '')} to={`${path}/sendmessages`}>
            <i className="fa fa-paper-plane"></i> Send Message
          </Link>
        </NavItem>
        <NavItem onClick={() => toggle(2)} className="nav-item">
          <Link className={'nav-link ' + (activeTab === 2 ? 'active' : '')} to={`${path}/waitlist`}>
            <i className="fa fa-clipboard-list"></i> Manage Waitlist
          </Link>
        </NavItem>
        <NavItem onClick={() => toggle(3)} className="nav-item">
          <Link className={'nav-link ' + (activeTab === 3 ? 'active' : '')} to={`${path}/history`}>
            <i className="fa fa-comment-alt"></i> Communication History
          </Link>
        </NavItem>
        {/* <NavItem onClick={() => toggle(4)} className="nav-item">
            <Link className={'nav-link ' + (activeTab === 4 ? 'active' : '')} to={`${path}/settings`}>
              <i className="fa fa-cog"></i> Communication Settings
            </Link>
          </NavItem> */}
      </Nav>
    </div>
  );
};

export default AdminCommunicationsNavigation;
