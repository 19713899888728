import React, { useState, useEffect, useContext } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faPencil, faShare, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { Button } from 'reactstrap';
import style from './AttachmentItem.style';
import Swal from 'sweetalert2';
import { truncate } from 'Services/candidates';
import EditAttachmentPopover from '../EditAttachmentPopover/EditAttachmentPopover';

const AttachmentItem = ({ attachment, removeAttachment, getAttachments, attachmentIndex, addAttachment }) => {
  const [showEditAttachmentPopover, setShowEditAttachmentPopover] = useState(false);

  const renderEditAttachmentPopover = () => {
    const target = `editAttachmentBtn_${attachmentIndex}`;

    return (
      <EditAttachmentPopover
        target={target}
        attachment={attachment}
        getAttachments={getAttachments}
        showEditAttachmentPopover={showEditAttachmentPopover}
        setShowEditAttachmentPopover={(newValue) => {
          console.log('setShowNewAttachmentPopover firing!');
          setShowEditAttachmentPopover(newValue);
        }}
        uniqueId={attachmentIndex}
      />
    );
  };

  return (
    <div
      style={{
        ...style.simpleRow,
        marginBottom: 5,
        alignItems: 'center',
        backgroundColor: attachmentIndex % 2 == 0 ? 'white' : '#c7cbd1',
      }}
    >
      <div style={{ ...style.simpleColumn, width: 40, marginRight: 5 }}>
        <Button
          size="sm"
          color="primary"
          onClick={() => {
            const { pk_Attachment, DisplayName, DocumentUUID } = attachment || {};

            let server = 'https://dev.rezrate.com/department/attachment';

            const { REACT_APP_BUILD_TYPE, NODE_ENV } = process.env;
            if (REACT_APP_BUILD_TYPE && REACT_APP_BUILD_TYPE === 'liveproduction') {
              server = 'https://app.rezrate.com/department/attachment';
            }

            if (REACT_APP_BUILD_TYPE && REACT_APP_BUILD_TYPE === 'livedevelopment') {
              server = 'https://dev.rezrate.com/department/attachment';
            }
            const { hostname, search } = window.location;
            if (hostname === 'localhost') {
              server = 'http://localhost:3000/department/attachment';
            }
            const url = `${server}/${DocumentUUID}`;
            // const url = `http://localhost:3000/department/attachment/${DocumentUUID}`;

            window.open(url, '_blank');
          }}
        >
          <FontAwesomeIcon icon={faEye} />
        </Button>
      </div>

      <div
        style={{
          ...style.simpleColumn,
          width: '100%',
          marginRight: 5,
          justifyContent: 'left',
          //   alignItems: 'center',
          textAlign: 'left',
        }}
      >
        {truncate(attachment.DisplayName, 35)}
      </div>

      <div style={{ ...style.simpleColumn, width: 40, marginRight: 5 }}>
        <Button
          size="sm"
          color="primary"
          id={`editAttachmentBtn_${attachmentIndex}`}
          onClick={() => {
            setShowEditAttachmentPopover(!showEditAttachmentPopover);
            // Swal.fire('Coming Soon!', 'This feature is coming soon', 'info');
          }}
        >
          <FontAwesomeIcon icon={faPencil} />
          {renderEditAttachmentPopover()}
        </Button>
      </div>
      {/* <div style={{ ...style.simpleColumn, width: 40, marginRight: 5 }}>
        <Button
          size="sm"
          color="danger"
          onClick={() => {
            Swal.fire('Coming Soon!', 'This feature is coming soon', 'info');
          }}
          //   onClick={() => {
          //     if (removeAttachment) {
          //       removeAttachment(attachmentIndex);
          //     }
          //   }}
        >
          <FontAwesomeIcon icon={faTrashAlt} />
        </Button>
      </div> */}

      <div style={{ ...style.simpleColumn, width: 40, marginRight: 5 }}>
        <Button
          size="sm"
          color="success"
          onClick={() => {
            addAttachment(attachment);
            // Swal.fire('Coming Soon!', 'This feature is coming soon', 'info');
          }}
        >
          <FontAwesomeIcon icon={faShare} />
        </Button>
      </div>
    </div>
  );
};

export default AttachmentItem;
