const style = {
  simpleRow: {
    display: 'flex',
    flexDirection: 'row',
  },
  simpleColumn: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  spaceBetweenRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  bottomButtonPanels: {
    flexWrap: 'wrap',
    display: 'flex',
    flexDirection: 'row',
    // position: 'sticky',
    // bottom: 0,
    backgroundColor: 'white',
    padding: 10,
    paddingLeft: 15,
    paddingRight: 15,
  },
  bottomButton: {
    minWidth: 220,
    marginRight: 10,
    marginBottom: 10,
  },
};

export default style;
