import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import imageToBase64 from 'image-to-base64';

import moment from 'moment';
import tinycolor from 'tinycolor2';
import { clone } from '../../schedule';
import Swal from 'sweetalert2';
import profilePlaceholder from './Portrait_Placeholder.png';

const bgGuide = require('./AVERY_5390.png');
let profilePlaceholderBase64 = null;

pdfMake.vfs = pdfFonts.pdfMake.vfs;

// 1 inch = 72 units.
// LETTER size is 612x792

const tableLayouts = {
  customTable: {
    hLineWidth: (i, node) => {
      return 0;
      // if (i === 0 || i === node.table.body.length) {
      //   return 0;
      // }
      // return i === node.table.headerRows ? 2 : 1;
    },
    vLineWidth: (i) => {
      return 0;
    },
    hLineColor: (i) => {
      return i === 1 ? 'black' : '#aaa';
    },
    paddingLeft: (i) => {
      return 0;
    },
    paddingRight: (i, node) => {
      return 0;
    },
    paddingTop: (i, node) => {
      return 0;
    },
    paddingBottom: (i, node) => {
      return 0;
    },
  },
};

const truncate = (wordToTruncate, limit = 0, forcedCut) => {
  if (wordToTruncate == null) {
    return '';
  }
  if (limit == 0 || wordToTruncate.length < limit + 1) {
    return wordToTruncate;
  } else if (forcedCut) {
    return `${wordToTruncate.slice(0, limit)}`;
  } else if (wordToTruncate.length > limit + 1) {
    return `${wordToTruncate.slice(0, limit - 5)}. . .`;
  }
};

const createPDFProper = (data) => {
  const { interviewDate, candidates, department, departmentLogo } = data;
  const { metaData, MomentDateOfInterview } = interviewDate;
  const { TimeZone } = department;

  let dateToDisplay = '';
  let labelToDisplay = '';

  if (metaData) {
    const { DateOfInterview, StartTime, Label } = metaData;
    dateToDisplay = moment(`${DateOfInterview} ${StartTime}`).format('MMM DD, YYYY');
    labelToDisplay = Label;
  } else if (MomentDateOfInterview) {
    labelToDisplay = 'Ayy yooo';
    dateToDisplay = MomentDateOfInterview.format('MMM DD, YYYY');
  }

  const rows = [];
  let counter = 0;

  const renderCard = (candidate, counter) => {
    const { userPhotoBase64 } = candidate;
    const cardRows = [];
    const userDetailRows = [];

    const userPhoto = userPhotoBase64
      ? {
          width: 100,
          columns: [
            {
              image: userPhotoBase64 ? `data:image/png;base64,${userPhotoBase64}` : null,
              width: 80,
            },
          ],
          margin: [10, 0, 10, 0],
        }
      : {
          width: 100,
          columns: [
            {
              image: profilePlaceholderBase64 ? `data:image/png;base64,${profilePlaceholderBase64}` : null,
              width: 80,
            },
          ],
          margin: [10, 0, 10, 0],
        };

    userDetailRows.push([
      {
        style: { alignment: 'left', bold: true },
        text: `${candidate.LastName}, ${candidate.FirstName} `,
        fontSize: 14,
        margin: [0, 0, 0, 10],
      },
    ]);

    userDetailRows.push([
      {
        style: { alignment: 'left' },
        text: `${candidate.CandidateSubHeading ? candidate.CandidateSubHeading : ' '}`,

        margin: [0, 0, 0, 0],
      },
    ]);

    userDetailRows.push([
      {
        style: { alignment: 'left' },
        text: `${candidate.Email ? candidate.Email : ' '}`,

        margin: [0, 0, 0, 0],
      },
    ]);

    userDetailRows.push([
      {
        style: { alignment: 'left' },
        text: `${candidate.PreferredPhone ? candidate.PreferredPhone : ''}${
          candidate.PreferredPhone && candidate.PhoneNumber ? ' | ' : ''
        } ${candidate.PhoneNumber ? candidate.PhoneNumber : ''}${
          candidate.PhoneNumber && candidate.CellPhone ? ' | ' : ''
        }${candidate.CellPhone ? candidate.CellPhone : ''}`,
        // fontSize: 12,
        margin: [0, 0, 0, 0],
      },
    ]);

    cardRows.push({
      columns: [userPhoto, userDetailRows],
      margin: [0, 20, 0, 5],
    });

    return cardRows;
  };

  while (counter < candidates.length) {
    const columns = [];
    const tableRow = [];

    columns.push({ width: 3.95 * 72, columns: [renderCard(candidates[counter], counter)] });
    tableRow.push(renderCard(candidates[counter], counter));
    counter++;
    if (counter < candidates.length) {
      columns.push({ width: 3.95 * 72, columns: [renderCard(candidates[counter], counter)] });
      tableRow.push(renderCard(candidates[counter], counter));
    } else {
      tableRow.push({ text: '' });
    }

    rows.push(tableRow);
    counter++;
  }
  if (rows.length <= 0) {
    Swal.fire('Error', 'There are no Candidates for this Interview Date. There is nothing to print!', 'error');
    return;
  }
  let pdfObject = [
    {
      table: { heights: 1.5 * 72, widths: [3.95 * 72, 3.95 * 72], body: rows, dontBreakRows: true },
      layout: 'customTable',
    },
  ];
  // imageToBase64(bgGuide)
  //   .then((bgBase64) => {
  const docDefinition = {
    // background: { image: `data:image/png;base64,${bgBase64}`, width: 612 },
    info: { title: 'Candidate Face Sheet' },
    pageSize: 'LETTER',
    pageOrientation: 'portrait',
    content: pdfObject,
    defaultStyle: {
      fontSize: 10,
    },
    pageMargins: [0.25 * 72, 1.25 * 72, 0.25 * 72, 0.5 * 72],
    header: function(currentPage, pageCount, pageSize) {
      return [
        {
          text: `${dateToDisplay} ${labelToDisplay ? `(${labelToDisplay})` : ''}`,
          alignment: 'bottom',
          style: { bold: true },
          fontSize: 15,
          margin: [0.4 * 72, 50, 0, 0],
        },
      ];
    },
  };

  pdfMake.createPdf(docDefinition, tableLayouts).open({}, window.open('', '_blank'));
  // })
  // .catch((err) => {
  // });
};

export const createPDFForCandidateFaces = ({ interviewDate, department, candidates = [], callback }) => {
  const candidatesCopy = clone(
    candidates.sort((a, b) => {
      const textA = `${a.LastName}, ${a.FirstName}`.toUpperCase();
      const textB = `${b.LastName}, ${b.FirstName}`.toUpperCase();

      return textA < textB ? -1 : textA > textB ? 1 : 0;
    }),
  );
  Promise.all([
    ...candidatesCopy.map((c) => {
      if (c.pk_Candidate == 6275) {
        console.log('c: ', c);
      }
      return new Promise((resolve, reject) => {
        if (c.PhotoUrl) {
          imageToBase64(c.PhotoUrl)
            .then((base64Img) => {
              if (c.pk_Candidate == 6275) {
                console.log('base64: ', base64Img);
              }
              c.userPhotoBase64 = base64Img;
              resolve(c);
            })
            .catch((err) => {
              console.log('err: ', err);
              resolve({ error: true, ...c });
            });
        } else {
          resolve(c);
        }
      });
    }),
    new Promise((resolve, reject) => {
      imageToBase64(profilePlaceholder)
        .then((base64) => {
          profilePlaceholderBase64 = base64;
          resolve(base64);
        })
        .catch((err) => {
          reject(err);
        });
    }),
  ])
    .then((res) => {
      createPDFProper({ candidates: candidatesCopy, department, interviewDate });
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      if (callback) {
        callback(err);
      }
    });
};
