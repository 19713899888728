import { faSpinner, faUpload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Loading from 'Body/Statuses/Loading';
import React, { useContext, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { Button, Table } from 'reactstrap';
import Swal from 'sweetalert2';
import XLSX from 'xlsx';
import { useAuth0 } from '../../../../auth0/reactAuth0Spa';
import { formatBearerToken } from '../../../../Common.functions';
import { DepartmentContext } from '../../../../DepartmentWrapper';
import { postDataAgnostic } from '../../../../Services/dataApi';

const AdminBulkImportUsers = ({ callback }) => {
  const { getTokenSilently } = useAuth0();
  const [postResults, setPostResults] = useState({});
  const [error, setError] = useState(false);
  const [isPosting, setIsPosting] = useState(false);
  const [finished, setFinished] = useState(false);
  const dContext = useContext(DepartmentContext);

  const postUsers = (users) => {
    getTokenSilently().then((token) => {
      postDataAgnostic(
        'department/users',
        { pk_Department: dContext.department.pk_Department, isSingleUser: false },
        { users },
        formatBearerToken(token),
      )
        .then((result) => {
          setPostResults(result.data);
          setFinished(true);
          callback();
        })
        .catch((err) => {
          setError(true);
        })
        .finally(() => {
          setIsPosting(false);
        });
    });
  };
  const formatDataForPosting = (headerInfo, body) => {
    return body.map((bodyItem) => {
      const entry = {};
      bodyItem.forEach((item, i) => {
        if (
          headerInfo[i] === 'EnableEvaluator' ||
          headerInfo[i] === 'EnableAssistant' ||
          headerInfo[i] === 'EnablePointOfContact' ||
          headerInfo[i] === 'EnableAdmin'
        ) {
          entry[headerInfo[i]] = item.trim().length > 0 && item.trim().toUpperCase() !== 'NO' ? 1 : 0;
        } else {
          if (item.length > 0) entry[headerInfo[i]] = item;

          // need to add email to this key
          if (headerInfo[i] === 'UserEmail') {
            entry['Auth0Email'] = item;
          }
        }
      });
      return entry;
    });
  };
  const getHeaderData = (arrayOfData) => {
    const keys = {};
    if (arrayOfData && arrayOfData[1]) {
      const headerPosition = arrayOfData[1].forEach((item, i) => {
        const itemToUpperCase = item.toUpperCase();
        switch (true) {
          case itemToUpperCase.includes('EMAIL'):
            keys[i] = 'UserEmail';
            break;
          case itemToUpperCase.includes('LAST'):
            keys[i] = 'UserLast';
            break;
          case itemToUpperCase.includes('FIRST'):
            keys[i] = 'UserFirst';
            break;
          case itemToUpperCase.includes('MIDDLE'):
            keys[i] = 'UserMiddle';
            break;
          case itemToUpperCase.includes('SUFFIX'):
            keys[i] = 'UserSuffix';
            break;
          case itemToUpperCase.includes('CELL'):
            keys[i] = 'UserCellPhoneNumber';
            break;
          case itemToUpperCase.includes('EVALUATOR'):
            keys[i] = 'EnableEvaluator';
            break;
          case itemToUpperCase.includes('ADMINISTRATOR'):
            keys[i] = 'EnableAdmin';
            break;
          case itemToUpperCase.includes('POINT OF CONTACT'):
            keys[i] = 'EnablePointOfContact';
            break;
        }
      });
      return keys;
    }
  };

  const handleExcelDrop = async (acceptedFiles) => {
    let payload = [];
    acceptedFiles.forEach((file) => {
      const reader = new FileReader();
      const rABS = !!reader.readAsBinaryString;
      reader.onabort = () => {
        setIsPosting(false);
      };
      reader.onerror = () => {
        setIsPosting(false);
      };
      reader.onload = (e) => {
        var bstr = e.target.result;
        var workbook = XLSX.read(bstr, { type: rABS ? 'binary' : 'array' });
        var sheet_name_list = workbook.SheetNames[0];
        var jsonFromExcel = XLSX.utils.sheet_to_json(workbook.Sheets[sheet_name_list], {
          raw: false,
          dateNF: 'MM-DD-YYYY',
          header: 1,
          defval: '',
        });
        payload = jsonFromExcel;
        const headerInfo = getHeaderData(jsonFromExcel);

        // need to remove the header and the description in the excel sheet
        const arrayWithoutHeader = jsonFromExcel.filter((item, i) => i > 1);

        const formatedForPosting = formatDataForPosting(headerInfo, arrayWithoutHeader);

        const post = postUsers(formatedForPosting);
      };
      if (rABS) reader.readAsBinaryString(file);
      else reader.readAsArrayBuffer(file);
    });
    return payload;
  };

  const onDrop = async (files) => {
    setIsPosting(true);
    const file = files[0];
    if (file.name.toUpperCase().includes('.XLSX') || file.name.toUpperCase().includes('.CSV')) {
      const arrayOfData = await handleExcelDrop(files);
    } else {
      Swal.fire('Incompatible File Type', 'Please make sure your file is either of type .xlsx or .csv', 'error');
      setIsPosting(false);
    }
  };
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });
  const { existingUsers, newCandidates } = postResults;

  if (finished) {
    return (
      <div>
        <div style={{ maxHeight: '350px', overflow: 'scroll' }}>
          {newCandidates.length > 0 && (
            <div>
              <div style={{ width: '100%', textAlign: 'center' }}>
                <h5 style={{ color: '#009944' }}>Users Added</h5>
              </div>
              <Table>
                <tbody>
                  {newCandidates.map((item) => {
                    const {
                      Auth0Email,
                      EnableAssistant,
                      EnableEvaluator,
                      EnablePointOfContact,
                      UserCellPhoneNumber,
                      UserEmail,
                      UserFirst,
                      UserLast,
                      UserMiddle,
                    } = item;
                    return (
                      <tr>
                        <td>{UserFirst}</td>
                        <td>{UserLast}</td>
                        <td>{UserEmail}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </div>
          )}
          {existingUsers.length > 0 && (
            <div>
              <div style={{ width: '100%', textAlign: 'center' }}>
                <h5 style={{ color: '#CF000F' }}>Users With These Emails Already Exist</h5>
              </div>
              <Table>
                <tbody>
                  {existingUsers.map((item) => {
                    const {
                      Auth0Email,
                      EnableAssistant,
                      EnableEvaluator,
                      EnablePointOfContact,
                      UserCellPhoneNumber,
                      UserEmail,
                      UserFirst,
                      UserLast,
                      UserMiddle,
                    } = item;
                    return (
                      <tr>
                        <td>{UserFirst}</td>
                        <td>{UserLast}</td>
                        <td>{UserEmail}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </div>
          )}
        </div>
        <div style={{ paddingTop: '20px', display: 'flex', justifyContent: 'center' }}>
          <Button
            onClick={(e) => {
              e.preventDefault();
              setFinished(false);
              setPostResults({});
            }}
            color="info"
          >
            New Import
          </Button>
        </div>
      </div>
    );
  }
  return (
    <div>
      <div>Please make sure to use a CSV or XLSX file</div>
      <div
        style={{
          cursor: 'pointer',
          height: '200px',
          border: '1px dotted black',
          display: 'flex',
          justifyContent: 'center',
          alignContent: 'center',
        }}
        className="hover_blue"
      >
        {isPosting && (
          <div style={{ height: '100%', display: 'flex', alignItems: 'center' }}>
            <div style={{ height: 80, width: 80 }}>
              <Loading options={{ labelText: 'Uploading. . .', labelStyle: { fontSize: 9, textAlign: 'center' } }} />
            </div>

            {/* <FontAwesomeIcon icon={faSpinner} spin size="3x" /> */}
          </div>
        )}
        {!isPosting && (
          <div {...getRootProps()} style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
            <input {...getInputProps()} />
            <div style={{ width: '100%', display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
              <FontAwesomeIcon icon={faUpload} size="4x" />
              <p style={{ paddingTop: '15px' }}>Drag or Drop CSV or XLSX</p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default AdminBulkImportUsers;
