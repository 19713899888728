const style = {
  cellStyle: { cursor: 'pointer', display: 'flex', justifyContent: 'center', verticalAlign: 'middle' },
  headerStyle: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    textAlign: 'center',
    verticalAlign: 'middle',
  },
  hideInterviewDatesContainer: {
    marginBottom: 10,
    paddingTop: 5,
    paddingBottom: 5,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  centeredText: {
    width: '100%',
    textAlign: 'center',
  },
};

export default style;
