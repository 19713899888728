import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import doctor from '../assets/images/doctor.png';
import doctorShrug from '../assets/images/doctorShrug.png';
import { Link } from 'react-router-dom';
import { Col, Row, Container } from 'reactstrap';

const DoctorImage = styled.img`
  height: 50vh;
  width: auto;
`;

const FourOhFour = ({ onClickLogin, isAuthenticated, myKey }) => {
  const [randomBool, setRandomBool] = useState(Math.random() >= 0.5);

  // useEffect(() => {
  //   setRandomBool(Math.random() >= 0.5);
  // }, []);
  // var randomBool = Math.random() >= 0.5;
  document.body.style.backgroundColor = '#fff';

  // const processMessage = () => {
  //   switch (myKey) {
  //     case 'badUrl':

  //       return;
  //   }
  // };
  return (
    <Container>
      {/* <div> myKey: {myKey}</div> */}
      <Row style={{ paddingTop: '7vw' }}>
        <Col sm="4" className="d-none d-xl-block d-lg-block">
          {/* <div style={{ position: 'relative', left: '50%' }}> */}
          <DoctorImage style={{ right: '50%' }} src={randomBool ? doctorShrug : doctor} />
          {/* </div> */}
        </Col>
        <Col sm="8" style={{ padding: '50px' }}>
          <Row>
            <h1 style={{ fontSize: '4em' }}>Page not found</h1>
            {/* <Col>
                <img style={{ height: '100px' }} src={RezRate} />
              </Col> */}
          </Row>
          <Row>
            {isAuthenticated ? (
              <h4 style={{ paddingTop: '10px' }}>
                Click{' '}
                <Link to="/" onClick={onClickLogin}>
                  here
                </Link>{' '}
                to go to the home page.
              </h4>
            ) : (
              <>
                <h4 style={{ paddingTop: '10px' }}>
                  If you're a candidate, please check the link in your email.
                  <br />
                  Try copy and pasting if the button isn't working.
                </h4>
                <h4 style={{ paddingTop: '10px' }}>
                  RezRATE Users can{' '}
                  <Link
                    to="/"
                    onClick={(e) => {
                      e.preventDefault();
                      if (onClickLogin) {
                        return onClickLogin();
                      }
                    }}
                  >
                    log in here
                  </Link>
                  .
                </h4>
              </>
            )}
          </Row>
        </Col>
      </Row>
      <Row></Row>
    </Container>
  );
};

export default FourOhFour;
