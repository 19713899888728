const style = {
  simpleRow: {
    display: 'flex',
    flexDirection: 'row',
  },
  simpleColumn: {
    display: 'flex',
    flexDirection: 'column',
  },
  spacedBetweenRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  responsesContainer: {
    display: 'flex',
    flexDirection: 'row',
    height: '100%',
    overflowY: 'scroll',
    marginTop: 10,
  },
  filterItem: {
    display: 'flex',
    flexDirection: 'row',
    paddingTop: 10,
    paddingBottom: 10,
    cursor: 'pointer',
  },
  centeredText: {
    width: '100%',
    textAlign: 'center',
  },
  filterButtonText: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    textAlign: 'center',
    // width: 'calc(100% - 45px)',
  },
  clickable: {
    cursor: 'pointer',
  },
};
export default style;
