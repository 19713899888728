import { connect } from 'react-redux';
import { setActiveDepartment, setActiveSeason, setSeasonChoices } from '../Body/Department/Department.action';
import AdminLanding from './AdminLanding';

const mapStateToProps = (state) => {
  return {
    user: state.userInfo.user,
    isAdmin: state.login.isAdmin,
    activeDepartment: state.department.activeDepartment,
    activeSeason: state.department.activeSeason,
  };
};

const mapDispatchToProps = (dispatch) => ({
  setActiveDepartment: (department) => dispatch(setActiveDepartment(department)),
  setActiveSeason: (season) => dispatch(setActiveSeason(season)),
  setSeasonChoices: (seasons) => dispatch(setSeasonChoices(seasons)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AdminLanding);
