import React from 'react';
import CandidateDropDownItem from './CandidateDropDownItem';
import style from './style.js';

const CandidateDropDown = ({
  candidates = [],
  onEditCandidate,
  onCalendarClick,
  interviewLimit,
  scheduleData,
  allowInterviewLimit,
  scheduleID,
}) => {
  return (
    <div>
      <div style={style.dropDownBody}>
        {Object.values(candidates)
          .sort((a, b) => {
            const aName = `${a.LastName ? `${a.LastName}, ` : ''}${a.FirstName || ''}`;
            const bName = `${b.LastName ? `${b.LastName}, ` : ''}${b.FirstName || ''}`;

            if (aName < bName) {
              return -1;
            }
            if (aName > bName) {
              return 1;
            }
            return 0;
          })
          .map((candidate) => {
            return (
              <CandidateDropDownItem
                allowInterviewLimit={allowInterviewLimit}
                scheduleData={scheduleData}
                key={candidate.pk_Candidate}
                interviewLimit={interviewLimit}
                candidate={candidate}
                onEditCandidate={onEditCandidate}
                onCalendarClick={onCalendarClick}
                scheduleID={scheduleID}
              />
            );
          })}
      </div>
    </div>
  );
};

export default CandidateDropDown;
