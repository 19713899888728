import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Form, FormGroup, Input } from 'reactstrap';
import { matchPath } from 'react-router';
import { useHistory, useParams } from 'react-router-dom';
import Style from './DateDisplay.style';
import { clone } from 'lodash';
import { getClosestFutureDate, getDefaultInterviewDate } from 'Common.functions';
import { DatePicker } from 'react-widgets/cjs';

let stopInfiniteLoop = 0;

const DateDisplay = ({ mockUser, date = {}, header, setDate, dateArray, seasonType, season }) => {
  const [chosenDate, setChosenDate] = useState(null);
  const [isPrescreen, setIsPrescreen] = useState(false);
  const [match, setMatch] = useState({ params: {} });
  const routerParams = useParams();
  const history = useHistory();
  const { pk_InterviewDate } = match.params;
  // the headers is outside of the evaluate route, which excludes it from having organic access to
  // path params. To overcome this without significant refactoring, creating the route params
  // in this componenet

  const handleDatesPropChange = (data) => {
    const { date, dateArray } = data;

    if (date) {
      if (date === 'Prescreen' || date.pk_InterviewDate === 'Prescreen') {
        setChosenDate('Prescreen');
        if (history.location.pathname !== '/user/evaluate/Prescreen') {
          history.push(`/user/evaluate/Prescreen`);
        }

        return;
      }
      const dateExistsInArray = dateArray.find((item) => {
        return item.pk_InterviewDate === date.pk_InterviewDate;
      });

      if (dateExistsInArray) {
        if (chosenDate !== date.pk_InterviewDate) {
          setChosenDate(date.pk_InterviewDate);
        } else {
          history.push(`/user/evaluate/${date.pk_InterviewDate}`);
        }
      } else {
        if (dateArray && dateArray.length > 0) {
          // If no date is selected, but we have a dateArray now, check URL for pk and set date to that, if possible.
          if (!date.pk_InterviewDate) {
            const pathExploded = history.location.pathname.split('/');
            const url_pk_InterviewDate = pathExploded[pathExploded.length - 1];
            const dateExistsInArray = dateArray.find((item) => {
              return item.pk_InterviewDate === parseInt(url_pk_InterviewDate);
            });

            // if url date exists in list, select date. otherwise, select closest future date.
            if (dateExistsInArray) {
              setChosenDate(dateExistsInArray.pk_InterviewDate);
              return;
            }
          }
          const localDefaultDate = isPrescreen ? { pk_InterviewDate: 'Prescreen' } : getClosestFutureDate(dateArray);
          setChosenDate(localDefaultDate.pk_InterviewDate);

          history.push(`/user/evaluate/${localDefaultDate.pk_InterviewDate}`);
        }
      }
    } else if (dateArray && dateArray.length > 0) {
      const localDefaultDate = isPrescreen ? { pk_InterviewDate: 'Prescreen' } : getClosestFutureDate(dateArray);

      history.push(`/user/evaluate/${localDefaultDate.pk_InterviewDate}`);
    } else {
      // console.log('dateArray: ', dateArray);
      // console.log('date: ', date);
    }
  };

  useEffect(() => {
    let newMatch = matchPath(history.location.pathname, {
      path: '/user/evaluate/:pk_InterviewDate',
      exact: true,
      strict: false,
    });

    if (newMatch && newMatch.params) {
      setMatch(newMatch);
    }
  }, [history.location.pathname]);

  useEffect(() => {
    // if (season.EnablePrescreenMode) {
    //   return setChosenDate('Prescreen');
    // }
    setChosenDate(pk_InterviewDate);
  }, [pk_InterviewDate, dateArray, season]);

  useState(() => {
    if (season && season.AllowPrescreen) {
      setIsPrescreen(true);
    } else {
      setIsPrescreen(false);
    }
  }, [seasonType, season]);

  useState(() => {
    handleDatesPropChange({ date, dateArray });
  }, [date]);

  useEffect(() => {
    handleDatesPropChange({ date, dateArray });
  }, [dateArray]);

  const getSelectedOrDefaultDate = (dateArray) => {
    // If user is accessing prescreen just return prescreen as the value.
    // Need to add the key InterviewDatesVL__pkUUID_InterviewDate so all
    // options have uniform data
    if (isPrescreen) {
      return { InterviewDatesVL__pkUUID_InterviewDate: 'Prescreen' };
    }
    const rightNow = new Date(Date.now());

    const correctDate = dateArray.find((date, i) => {
      const differenceInTime = new Date(date.DateOfInterview).getTime() - rightNow.getTime();
      const differenceInDays = differenceInTime / (1000 * 3600 * 24);

      // If the next upcoming date is within 5 days, set this as the default
      if (differenceInDays > 0 && differenceInDays <= 5) {
        return true;
      }

      if (date[i + 1]) {
        const nextDate = date[i + 1];
        const differenceOfNextDateInDays = new Date(nextDate.InterviewDatesVLDate).getTime() - rightNow.getTime();

        // need to make sure the next date doesn't satisfy this condition.  If it does, just return false
        // and next itteration it will return true
        if (differenceOfNextDateInDays > 0 && differenceOfNextDateInDays <= 5) {
          return false;
        }
      }

      if (differenceInDays > -10 && differenceInDays < 0) {
        return true;
      }

      return false;
    });

    if (correctDate) {
      setDate(correctDate);
      setChosenDate(correctDate.pk_InterviewDate);
    } else {
      // if none of the coniditions are true just return the last date
      const lastDate = dateArray[dateArray.length - 1];
      setDate(lastDate);
      setChosenDate(lastDate.pk_InterviewDate);
    }
  };

  const handleChange = (e) => {
    // If user has selected Prescreen no need to match with a date id.  Just set it to Prescreen
    const rootUrl = '/user/evaluate';

    history.push(`${rootUrl}/${e.target.value}`);
  };

  if (!season) {
    return <div />;
  }

  //if there's only one date and prescreen is false, no need to use a dropdown

  if (dateArray.length === 1 && !isPrescreen) {
    return (
      <div
        style={{
          color: '#fff',
          display: 'flex',
          textAlign: 'center',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
        }}
      >
        {moment(dateArray[0].InterviewDatesVLDate).format('ddd, MMM D, YYYY')}
      </div>
    );
  }

  // if the date array is empty and prescreen state is enabled then we don't need a dropdown
  if (dateArray.length === 0 && isPrescreen) {
    return (
      <div
        style={{
          color: '#fff',
          display: 'flex',
          textAlign: 'left',
          justifyContent: 'left',
          alignItems: 'center',
          flexDirection: 'row',
          fontSize: 16,
          width: '100%',
          padding: 10,
        }}
      >
        Prescreen
      </div>
    );
  }

  const sortedDates = dateArray.sort((a, b) => {
    const dateA = moment(`${a.DateOfInterview} ${a.StartTime}`, 'YYYY-MM-DD HH:mm:ss').valueOf();
    const dateB = moment(`${b.DateOfInterview} ${b.StartTime}`, 'YYYY-MM-DD HH:mm:ss').valueOf();

    // const dateAEnd moment()
    if (dateA !== dateB) {
      return dateA - dateB;
    }

    return a.pk_InterviewDate - b.pk_InterviewDate;
  });

  return (
    <Input
      type="select"
      name="select"
      className="options"
      value={chosenDate}
      onChange={handleChange}
      style={{ width: '100%' }}
    >
      {isPrescreen && <option value="Prescreen">Prescreen</option>}
      {sortedDates.map((item) => {
        return (
          <option key={item.pk_InterviewDate} value={item.pk_InterviewDate}>
            {moment(item.DateOfInterview).format('ddd, MMM D, YYYY')} {item.Label} ({item.CountOfCandidates || 0} Evals)
          </option>
        );
      })}
    </Input>
  );
};

export default DateDisplay;
