import { ContentState, EditorState, Modifier, RichUtils, SelectionState } from 'draft-js';
import React, { useState } from 'react';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import Button from 'reactstrap/lib/Button';
import style from './Wysiwyg.style.js';
import Swal from 'sweetalert2';
import { Popover, PopoverBody, PopoverHeader } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import AttachmentsPopover from '../AdminLanding/AdminCommunications/AdminSendMessage/AdminMessageComposer/AttachmentPopover/AttachmentsPopover.js';

import './CustomButtons.style.css';
import { constructUrl } from 'Services/dataApi.js';
const CustomOption = ({ editorState, onChange, selectedCandidate, toggleAttachmentPopup }) => {
  const [showAttachmentPopover, setShowAttachmentPopover] = useState(false);

  const { FirstName, LastName, DateOfInterview } = selectedCandidate || {};
  const addStar = () => {
    const contentState = Modifier.replaceText(
      editorState.getCurrentContent(),
      editorState.getSelection(),
      '{FirstName}',
      editorState.getCurrentInlineStyle(),
    );
    onChange(EditorState.push(editorState, contentState, 'insert-characters'));
  };

  const addLastName = () => {
    const contentState = Modifier.replaceText(
      editorState.getCurrentContent(),
      editorState.getSelection(),
      `{LastName}`,
      editorState.getCurrentInlineStyle(),
    );
    onChange(EditorState.push(editorState, contentState, 'insert-characters'));
  };

  const addButton = (buttonText) => {
    const contentState = Modifier.replaceText(
      editorState.getCurrentContent(),
      editorState.getSelection(),
      buttonText,
      editorState.getCurrentInlineStyle(),
    );
    onChange(EditorState.push(editorState, contentState, 'insert-characters'));

    // // Get the current content
    // const currentContent = editorState.getCurrentContent();

    // // Check if the editor is empty. TODO: Also check if previous block is empty or is newline. This is to fix bug where if editor is not empty, it just appends the damn button.
    // const isEditorEmpty =
    //   currentContent.getBlockMap().size === 1 && currentContent.getFirstBlock().getText().length === 0;

    // // Get the current selection
    // const currentSelection = editorState.getSelection();

    // if (!isEditorEmpty) {
    //   // Split the block at the current selection
    //   const contentStateWithSplitBlock = Modifier.splitBlock(currentContent, currentSelection);

    //   // Get the new selection after the split
    //   const newBlockKey = contentStateWithSplitBlock.getSelectionAfter().getStartKey();

    //   // Create a new SelectionState at the end of the newly created block
    //   const newSelection = new SelectionState({
    //     anchorKey: newBlockKey,
    //     anchorOffset: buttonText.length,
    //     focusKey: newBlockKey,
    //     focusOffset: buttonText.length,
    //   });

    //   // Replace the content with the desired text at the new selection
    //   const contentStateWithButton = Modifier.replaceText(contentStateWithSplitBlock, newSelection, buttonText);

    //   // Create a new EditorState with the modified ContentState
    //   const newEditorState = EditorState.push(editorState, contentStateWithButton, 'insert-characters');

    //   // Set the new EditorState with the updated selection
    //   onChange(EditorState.forceSelection(newEditorState, newSelection));
    // } else {
    //   console.log('empty');
    //   // If the editor is empty, just add the button without splitting
    //   const contentStateWithButton = Modifier.insertText(currentContent, currentSelection, buttonText);

    //   // Create a new EditorState with the modified ContentState
    //   const newEditorState = EditorState.push(editorState, contentStateWithButton, 'insert-characters');

    //   // Update the editor state
    //   onChange(newEditorState);
    // }
  };

  // const addButton = (buttonText) => {
  //   // Get the current selection
  //   const currentSelection = editorState.getSelection();

  //   // Split the block at the current selection
  //   const contentStateWithSplitBlock = Modifier.splitBlock(editorState.getCurrentContent(), currentSelection);

  //   // Get the new selection after the split
  //   const newBlockKey = contentStateWithSplitBlock.getSelectionAfter().getStartKey();

  //   // Create a new SelectionState at the end of the newly created block
  //   const newSelection = new SelectionState({
  //     anchorKey: newBlockKey,
  //     anchorOffset: contentStateWithSplitBlock.getBlockForKey(newBlockKey).getText().length,
  //     focusKey: newBlockKey,
  //     focusOffset: contentStateWithSplitBlock.getBlockForKey(newBlockKey).getText().length,
  //   });

  //   // Replace the content with the desired text at the new selection
  //   const contentStateWithButton = Modifier.replaceText(contentStateWithSplitBlock, newSelection, buttonText);

  //   // Create a new EditorState with the modified ContentState
  //   const newEditorState = EditorState.push(editorState, contentStateWithButton, 'insert-characters');

  //   // Set the new EditorState with the updated selection
  //   onChange(EditorState.forceSelection(newEditorState, newSelection));
  // };

  // const addButton = (buttonText) => {
  //   // Get the current selection
  //   const currentSelection = editorState.getSelection();

  //   // Split the block at the current selection
  //   const contentStateWithSplitBlock = Modifier.splitBlock(editorState.getCurrentContent(), currentSelection);

  //   // Get the new selection after the split
  //   const newBlockKey = contentStateWithSplitBlock.getSelectionAfter().getStartKey();

  //   // Create a new SelectionState at the end of the newly created block
  //   const newSelection = new SelectionState({
  //     anchorKey: newBlockKey,
  //     anchorOffset: contentStateWithSplitBlock.getBlockForKey(newBlockKey).getText().length,
  //     focusKey: newBlockKey,
  //     focusOffset: contentStateWithSplitBlock.getBlockForKey(newBlockKey).getText().length,
  //   });

  //   // Replace the content with the desired text at the new selection
  //   const contentStateWithButton = Modifier.replaceText(contentStateWithSplitBlock, newSelection, buttonText);

  //   // Create a new EditorState with the modified ContentState
  //   onChange(EditorState.push(editorState, contentStateWithButton, 'insert-characters'));
  // };

  const addScheduleInterviewButton = () => {
    addButton('{RegistrationInvitationButton}');
    // const contentState = Modifier.replaceText(
    //   editorState.getCurrentContent(),
    //   editorState.getSelection(),
    //   `{RegistrationInvitationButton}`,
    //   editorState.getCurrentInlineStyle(),
    // );
    // onChange(EditorState.push(editorState, contentState, 'insert-characters'));
  };

  const addViewScheduleButton = () => {
    addButton('{ViewScheduleButton}');
    // const contentState = Modifier.replaceText(
    //   editorState.getCurrentContent(),
    //   editorState.getSelection(),
    //   '{ViewScheduleButton}',
    //   editorState.getCurrentInlineStyle(),
    // );
    // onChange(EditorState.push(editorState, contentState, 'insert-characters'));
  };

  const addPhotoUploadButton = () => {
    addButton('{PhotoUploadButton}');

    // const contentState = Modifier.replaceText(
    //   editorState.getCurrentContent(),
    //   editorState.getSelection(),
    //   '{PhotoUploadButton}',
    //   editorState.getCurrentInlineStyle(),
    // );
    // onChange(EditorState.push(editorState, contentState, 'insert-characters'));
  };

  const addWaitListDate = () => {
    const contentState = Modifier.replaceText(
      editorState.getCurrentContent(),
      editorState.getSelection(),
      '{WaitlistInfo}',
      editorState.getCurrentInlineStyle(),
    );
    onChange(EditorState.push(editorState, contentState, 'insert-characters'));
  };

  const addInterviewDate = () => {
    const contentState = Modifier.replaceText(
      editorState.getCurrentContent(),
      editorState.getSelection(),
      `{InterviewDate}`,
      editorState.getCurrentInlineStyle(),
    );
    onChange(EditorState.push(editorState, contentState, 'insert-characters'));
  };

  const addAttachment = (attachment) => {
    const { pk_Attachment, DisplayName, DocumentUUID } = attachment || {};

    let server = 'https://dev.rezrate.com/department/attachment';

    const { REACT_APP_BUILD_TYPE, NODE_ENV } = process.env;
    if (REACT_APP_BUILD_TYPE && REACT_APP_BUILD_TYPE === 'liveproduction') {
      server = 'https://app.rezrate.com/department/attachment';
    }

    if (REACT_APP_BUILD_TYPE && REACT_APP_BUILD_TYPE === 'livedevelopment') {
      server = 'https://dev.rezrate.com/department/attachment';
    }
    const url = `${server}/${DocumentUUID}`;
    // const url = `http://localhost:3000/department/attachment/${DocumentUUID}`;
    const contentState = editorState.getCurrentContent();

    // Create an entity for the link
    const contentStateWithEntity = contentState.createEntity('LINK', 'MUTABLE', { url, target: '_blank' });
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();

    // Create a selection for the text you want to link
    const selection = editorState.getSelection();

    // Apply the entity to the selected text
    const contentStateWithLink = Modifier.applyEntity(contentState, selection, entityKey);

    // Replace the selected text with a new ContentState containing the linked text
    const newContentState = Modifier.replaceText(
      contentStateWithLink,
      selection,
      `${DisplayName}  `,
      null, // No inline style
      entityKey,
      {
        cursor: 'pointer',
      },
    );

    // Update the editor state
    const newEditorState = EditorState.push(editorState, newContentState, 'apply-entity');

    onChange(newEditorState);
  };

  const renderAttachmentsPopover = () => {
    // return;
    return (
      <Popover
        placementPrefix="attachmentsPopover bs-popover"
        modifiers={{ flip: { behavior: ['top'] }, preventOverflow: { boundariesElement: 'viewport' } }}
        isOpen={showAttachmentPopover}
        placement="top"
        trigger="legacy"
        target="attachment_button"
        toggle={(e) => {
          if (e.target.className.includes && e.target.className.includes('swal2')) {
            return;
          }
          const newAttachmentPopover = document.getElementById('newAttachmentPopover_comms_composer');
          const editPopovers = document.getElementsByClassName('editAttachmentPopover_');
          const eventTarget = e.target;

          if (
            eventTarget &&
            newAttachmentPopover &&
            // eventTarget.id != attachmentButton.id &&
            newAttachmentPopover.contains(eventTarget)
          ) {
            return;
          }

          if (eventTarget) {
            let isFromEditPopover = false;
            let counter = 0;
            while (counter < editPopovers.length && !isFromEditPopover) {
              if (editPopovers[counter].contains(eventTarget)) {
                isFromEditPopover = true;
              }
              counter++;
            }

            if (isFromEditPopover) {
              return;
            }
          }

          setShowAttachmentPopover((prevState) => !prevState);
        }}
      >
        <PopoverHeader>
          <div style={style.spacedBetweenRow}>
            <div style={style.buttonLabel}>Attachments</div>
            <div>
              <FontAwesomeIcon
                icon={faTimesCircle}
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  setShowAttachmentPopover(false);
                  // setTemplateToEdit(null);
                }}
              />
            </div>
          </div>
        </PopoverHeader>
        <PopoverBody>
          <AttachmentsPopover uniqueId="comms_composer" addAttachment={addAttachment} />
        </PopoverBody>
      </Popover>
    );
  };

  return (
    <div style={style.actionButtonContainer} id="custom-button-wrapper">
      <Button
        style={style.actionButton('First Name')}
        size="sm"
        color="info"
        onClick={(e) => {
          e.preventDefault();
          addStar();
        }}
      >
        First Name
      </Button>
      <Button
        style={style.actionButton('Last Name')}
        size="sm"
        color="info"
        onClick={(e) => {
          e.preventDefault();
          addLastName();
        }}
      >
        Last Name
      </Button>
      <Button
        style={style.actionButton('Registration Invitation')}
        size="sm"
        color="info"
        onClick={(e) => {
          e.preventDefault();
          addScheduleInterviewButton();
        }}
      >
        Registration Invitation
      </Button>
      <Button
        style={style.actionButton('View Schedule Button')}
        size="sm"
        color="info"
        onClick={(e) => {
          e.preventDefault();
          addViewScheduleButton();
        }}
      >
        View Schedule Button
      </Button>
      <Button
        style={style.actionButton('Waitlist Date')}
        size="sm"
        color="info"
        onClick={(e) => {
          e.preventDefault();
          addWaitListDate();
        }}
      >
        Waitlist Info
      </Button>
      <Button
        style={style.actionButton('Interview Date')}
        size="sm"
        color="info"
        onClick={(e) => {
          e.preventDefault();
          addInterviewDate();
        }}
      >
        Interview Date
      </Button>
      <Button
        style={style.actionButton('Photo Upload Button')}
        size="sm"
        color="info"
        onClick={(e) => {
          e.preventDefault();
          addPhotoUploadButton();
        }}
      >
        Photo Upload Button
      </Button>
      <Button
        style={style.actionButton('Photo Upload Button')}
        size="sm"
        color="info"
        id="attachment_button"
        onClick={(e) => {
          e.preventDefault();
          toggleAttachmentPopup();
          // Swal.fire({
          //   title: 'Attachment',
          //   input: 'file',
          //   inputAttributes: {
          //     accept: 'image/*',
          //     'aria-label': 'Upload your profile picture',
          //   },
          // });

          // Swal.fire('Coming Soon!', 'This feature is coming soon', 'info');
        }}
      >
        Attachment
        {renderAttachmentsPopover()}
      </Button>
    </div>
  );
};
export default CustomOption;

//Candidate First Name
//Candidate Last Name
//Registration Invitation
//View Schedule Button
// Wait List Date
//Interview Date
//Photo Upload Button
