import React, { useState, useEffect, Fragment } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBuilding, faPencilAlt } from '@fortawesome/free-solid-svg-icons';

const DepartmentListItem = ({
  pk_Department,
  DepartmentName,
  DepartmentLogo,
  UserCount,
  setSelectedDepartment,
  selectedDepartment,
  backgroundColor,
}) => {
  const [isSelected, setIsSelected] = useState(false);

  useEffect(() => {
    setIsSelected(pk_Department === selectedDepartment);
  }, [selectedDepartment, pk_Department]);

  const renderImage = () => {
    if (DepartmentLogo) return <img width="50px" height="50px" src={DepartmentLogo} alt={DepartmentName} />;

    return (
      <div>
        <FontAwesomeIcon icon={faBuilding} size="3x" />
      </div>
    );
  };
  return (
    <Fragment>
      <td style={{ cursor: 'pointer', display: 'flex' }}>
        <div>{renderImage()}</div>
      </td>
      <td>
        <div>
          <h6>{DepartmentName}</h6>
          <h6>{UserCount} Users</h6>
        </div>
      </td>
    </Fragment>
  );
};

export default DepartmentListItem;
