import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import imageToBase64 from 'image-to-base64';

import moment from 'moment';
import tinycolor from 'tinycolor2';
import { clone } from '../../schedule';
import Swal from 'sweetalert2';
import profilePlaceholder from './Portrait_Placeholder.png';
import { pdf_concatenate } from '../../candidates';
const bgGuide = require('./AVERY_5384.png');
let profilePlaceholderBase64 = null;

pdfMake.vfs = pdfFonts.pdfMake.vfs;

// 1 inch = 72 units.
// LETTER size is 612x792

const tableLayouts = {
  customTable: {
    hLineWidth: (i, node) => {
      return 0;
      // if (i === 0 || i === node.table.body.length) {
      //   return 0;
      // }
      // return i === node.table.headerRows ? 2 : 1;
    },
    vLineWidth: (i) => {
      return 0;
    },
    hLineColor: (i) => {
      return i === 1 ? 'black' : '#aaa';
    },
    paddingLeft: (i) => {
      return 0;
    },
    paddingRight: (i, node) => {
      return 0;
    },
    paddingTop: (i, node) => {
      return 0;
    },
    paddingBottom: (i, node) => {
      return 0;
    },
  },
};

const truncate = (wordToTruncate, limit = 0, forcedCut) => {
  if (wordToTruncate == null) {
    return '';
  }
  if (limit == 0 || wordToTruncate.length < limit + 1) {
    return wordToTruncate;
  } else if (forcedCut) {
    return `${wordToTruncate.slice(0, limit)}`;
  } else if (wordToTruncate.length > limit + 1) {
    return `${wordToTruncate.slice(0, limit - 5)}. . .`;
  }
};

const createPDFProper = (data) => {
  const { interviewDate, candidates, department, callback } = data;

  const rows = [];
  let counter = 0;
  const renderCard = (candidate, counter) => {
    const { userPhotoBase64, error } = candidate;
    const cardRows = [];
    const userDetailRows = [];

    const userPhoto =
      userPhotoBase64 && !error
        ? {
            width: 100,
            columns: [
              {
                image: userPhotoBase64 ? `data:image/png;base64,${userPhotoBase64}` : null,
                width: 80,
              },
            ],
            margin: [10, 0, 10, 0],
          }
        : {
            width: 100,
            columns: [
              {
                image: profilePlaceholderBase64 ? `data:image/png;base64,${profilePlaceholderBase64}` : null,
                width: 80,
              },
            ],
            margin: [10, 0, 10, 0],
          };

    userDetailRows.push([
      {
        style: { alignment: 'left', bold: true },
        text: `${candidate.LastName}, ${candidate.FirstName}`,
        // fontSize: 12,
        margin: [15, 0, 0, 0],
      },
    ]);

    userDetailRows.push([
      {
        style: { alignment: 'left' },
        text: `${candidate.CandidateSubHeading ? candidate.CandidateSubHeading : ' '}`,
        // fontSize: 12,
        margin: [15, 0, 0, 0],
      },
    ]);

    userDetailRows.push([
      {
        style: { alignment: 'left' },
        text: `${candidate.Email ? candidate.Email : ' '}`,
        // fontSize: 12,
        margin: [15, 0, 0, 0],
      },
    ]);

    userDetailRows.push({
      width: 185,
      text: department.DepartmentName,
      //   fontSize: 12,
      style: { alignment: 'left' },
      margin: [15, 0, 0, 0],
    });

    cardRows.push({
      columns: [userDetailRows, userPhoto],
      margin: [0, 20, 0, 0],
    });

    cardRows.push({
      width: 185,
      text: {
        text: [
          { text: 'Hobbies: ', style: { bold: true }, margin: [0, 0, 10, 0] },
          {
            text: pdf_concatenate({
              string: `${candidate.HobbiesAndInterests ? candidate.HobbiesAndInterests : ''}`,
              maxLimit: 350,
            }),
          },
        ],
      },
      //   fontSize: 12,
      style: { alignment: 'left' },
      margin: [15, 10, 0, 0],
    });

    // cardRows.push({ canvas: [{ type: 'line', x1: 20, y1: 0, x2: 260.2, y2: 0, lineWidth: 1 }] });

    return cardRows;
  };

  while (counter < candidates.length) {
    const columns = [];
    const tableRow = [];

    columns.push({ width: 3.95 * 72, columns: [renderCard(candidates[counter], counter)] });
    tableRow.push(renderCard(candidates[counter], counter));
    counter++;
    if (counter < candidates.length) {
      columns.push({ width: 3.95 * 72, columns: [renderCard(candidates[counter], counter)] });
      tableRow.push(renderCard(candidates[counter], counter));
    } else {
      tableRow.push({ text: '' });
    }

    rows.push(tableRow);
    counter++;
  }

  if (rows.length <= 0) {
    Swal.fire('Error', 'There are no Candidates for this Interview Date. There is nothing to print!', 'error');
    return;
  }

  let pdfObject = [
    { table: { heights: 212, widths: [3.95 * 72, 3.95 * 72], body: rows, dontBreakRows: true }, layout: 'customTable' },
  ];

  const docDefinition = {
    info: { title: 'Candidate Fact Cards' },
    pageSize: 'LETTER',
    pageOrientation: 'portrait',
    content: pdfObject,
    defaultStyle: {
      fontSize: 11,
    },

    pageMargins: [0.25 * 72, 72, 0.25 * 72, 72],
  };
  pdfMake.createPdf(docDefinition, tableLayouts).open({}, window.open('', '_blank'));
};

export const createPDFForCandidateFactCards = ({ interviewDate, department, candidates = [], callback }) => {
  const candidatesCopy = clone(
    candidates.sort((a, b) => {
      const textA = `${a.LastName}, ${a.FirstName}`.toUpperCase();
      const textB = `${b.LastName}, ${b.FirstName}`.toUpperCase();

      return textA < textB ? -1 : textA > textB ? 1 : 0;
    }),
  );
  if (department.DepartmentLogo) {
    Promise.all([
      ...candidatesCopy.map((c) => {
        return new Promise((resolve, reject) => {
          if (c.PhotoUrl) {
            imageToBase64(c.PhotoUrl)
              .then((base64Img) => {
                c.userPhotoBase64 = base64Img;
                resolve(c);
              })
              .catch((err) => {
                resolve({ error: true, ...c });
              });
          } else {
            resolve(c);
          }
        });
      }),
      new Promise((resolve, reject) => {
        imageToBase64(profilePlaceholder)
          .then((base64) => {
            profilePlaceholderBase64 = base64;

            resolve(base64);
          })
          .catch((err) => {
            reject(err);
          });
      }),
    ])
      .then((res) => {
        createPDFProper({ candidates: candidatesCopy, department });
        if (callback) {
          callback();
        }
      })
      .catch((err) => {
        // ('createPDF err: ', err);
      });
  } else {
    createPDFProper({ interviewDate, candidates: candidatesCopy, department });
  }
};
