const style = {
  buttonWrapper: {
    display: 'flex',
    flexDirection: 'row',
  },
  simpleColumn: {
    display: 'flex',
    flexDirection: 'column',
  },
  simpleRow: {
    display: 'flex',
    flexDirection: 'row',
  },
  spacedBetweenRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'spaced-between',
    alignItems: 'center',
  },
  topButtonContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'spaced-between',
    margin: 5,
    marginTop: 0,
    marginBottom: 0,
    paddingTop: 5,
    paddingBottom: 5,
  },
  buttonLabel: {
    width: '100%',
    fontWeight: 'bold',
    fontSize: 'clamp(.1rem, .8vw, 1.5rem)',
  },
  clickable: {
    cursor: 'pointer',
  },
  manageFieldsContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '50%',
    padding: 10,
    height: 335,
    margin: 5,
    border: '1px solid black',
    overflowY: 'scroll',
  },
  manageFieldsContainerSubContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    padding: 10,
    height: 290,
    margin: 5,
    overflowY: 'scroll',
  },
  manageFieldsRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'spaced-between',
    alignItems: 'center',
    borderBottom: '1px solid #b6b6b8',
    paddingBottom: 5,
    paddingTop: 5,
  },
  manageFieldsLabel: {
    alignItems: 'center',
    textAlign: 'center',
    width: '100%',
  },
  manageFieldsName: {
    alignItems: 'center',
    textAlign: 'center',
    width: '80%',
    marginRight: 5,
    marginLeft: 5,
  },
  manageFieldsNewName: {
    alignItems: 'center',
    textAlign: 'center',
    width: '90%',
    marginRight: 5,
    marginLeft: 5,
  },

  manageFieldsSortOrder: {
    alignItems: 'center',
    textAlign: 'center',
    width: '20%',
  },

  candidateDetailsContainer: {
    height: 450,
    overflowY: 'scroll',
  },
  manageFieldsEnabledItemsVisibilityLabels: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    width: 'calc(100% - 27px)',
  },
  visibilityLabel: {
    alignSelf: 'flex-end',
    fontSize: 10,
    fontWeight: 'bold',
    marginLeft: 3,
    padding: 5,
    textAlign: 'center',
    verticalAlign: 'bottom',
    width: 45,
  },
};

export default style;
