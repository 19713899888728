import { faCheckSquare, faSquare } from '@fortawesome/free-regular-svg-icons';
import { faCheck, faInfoCircle, faPencilAlt, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { Input } from 'reactstrap';
import style from './FocusItem.style';

const FocusItem = ({
  focus,
  index,
  onToggleFocus,
  onDeleteFocus,
  onSave,
  allowEdit,
  forcedDisable,
  reference,
  selectedFocuses = [],
  isUpdating,
}) => {
  const [edit, setEdit] = useState(false);
  const isOld =
    reference &&
    reference.findIndex((f) => {
      return f.pk_Focus == focus.pk_Focus;
    }) >= 0;
  const isSelected = index >= 0;
  const isDeselected =
    !isSelected &&
    reference &&
    reference.findIndex((f) => {
      return f.pk_Focus == focus.pk_Focus;
    }) >= 0 &&
    selectedFocuses.findIndex((f) => {
      return f.pk_Focus == focus.pk_Focus;
    }) < 0;
  return (
    <div
      disabled={isUpdating}
      style={{
        ...style.itemRow,
        backgroundColor: isUpdating ? '#e9ecef' : isSelected && !isOld ? '#d9f5ff' : isDeselected ? '#f77777' : null,
        paddingLeft: 5,
        cursor: isUpdating || forcedDisable ? 'not-allowed' : 'pointer',
      }}
      onClick={() => {
        if (!isUpdating && !forcedDisable) onToggleFocus(focus.pk_Focus);
      }}
    >
      <div style={style.simpleColumn}>
        <div style={style.simpleRow}>
          <FontAwesomeIcon
            icon={isSelected ? faCheckSquare : faSquare}
            style={{ cursor: isUpdating || forcedDisable ? 'not-allowed' : 'pointer' }}
            allowEdit={allowEdit}
          />
          {edit ? (
            <Input
              disabled={isUpdating}
              value={focus.Name}
              type="text"
              onChange={(e) => {
                onSave({ ...focus, Name: e.target.value });
              }}
            />
          ) : (
            <div style={style.itemLabel}>{focus.Name}</div>
          )}
        </div>
      </div>
      <div style={style.simpleColumn}>
        <div style={style.simpleRow}>
          {/* <FontAwesomeIcon id={`focus_${focus.id}_info`} icon={faInfoCircle} style={style.clickable} /> */}

          {allowEdit ? (
            <FontAwesomeIcon
              icon={edit ? faCheck : faPencilAlt}
              style={style.clickable}
              onClick={() => {
                setEdit(!edit);
              }}
            />
          ) : null}
          {allowEdit ? (
            <FontAwesomeIcon
              icon={faTrashAlt}
              style={style.clickable}
              onClick={() => {
                onDeleteFocus(focus.id);
              }}
            />
          ) : null}

          {
            //  <UncontrolledTooltip  target={`focus_${focus.id}_info`}>
            //  {focus.Description}
            // </UncontrolledTooltip>
          }
        </div>
      </div>
    </div>
  );
};

export default FocusItem;
