import React, { useState } from 'react';
import { faTrashAlt, faPencilAlt, faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Input } from 'reactstrap';
import style from './TagItems.style';

function TagItems({ tag, index, onDeleteTag, onSave }) {
  const [edit, setEdit] = useState(false);
  const backgroundColor = index % 2 == 0 ? '#c7cbd1' : 'white';
  return (
    <div style={{ ...style.itemRow, backgroundColor }}>
      <div style={style.simpleColumn}>
        <div style={style.simpleRow}>
          {edit ? (
            <Input
              value={tag.Tag}
              type="text"
              onChange={(e) => {
                onSave({ ...tag, Tag: e.target.value, index: index });
              }}
            />
          ) : (
            <div style={style.itemLabel}>{tag.Tag}</div>
          )}
        </div>
      </div>
      <div style={style.simpleColumn}>
        <div style={style.simpleRow}>
          <div style={{ marginRight: '30px' }}>
            <FontAwesomeIcon
              icon={edit ? faCheck : faPencilAlt}
              style={style.clickable}
              onClick={() => {
                setEdit(!edit);
              }}
            />
          </div>
          <div style={{ marginRight: '35px' }}>
            <FontAwesomeIcon
              icon={faTrashAlt}
              style={style.clickable}
              onClick={() => {
                onDeleteTag(tag.pk_Tag);
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default TagItems;
