const style = {
  labelText: {
    fontSize: 15,
    fontWeight: 600,
    textAlign: 'center',
    width: '100%',
  },
  modalBody: {
    minHeight: 50,
    alignItems: 'center',
    paddingTop: 20,
  },
  centeredRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  genButton: {
    alignItems: 'center',
    backgroundColor: '#0a91ff',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginLeft: 10,
    marginRight: 10,
    paddignRight: 20,
    paddingBottom: 10,
    paddingTop: 10,
    minWidth: 100,
  },
  buttonLabel: { width: '100%', textAlign: 'center', fontWeight: 600 },
};

export default style;
