import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Input, Button, UncontrolledTooltip } from 'reactstrap';
import { faArrowAltCircleRight, faEye, faEyeSlash, faMinusSquare } from '@fortawesome/free-solid-svg-icons';
import style from './style';

const DataTypeItem = ({
  dataType = {},
  updateDataType,
  isUpdatingDataType = false,
  eanbledChoices,
  selectOptions = [],
  numberOfEnabled,
}) => {
  const [type, setType] = useState(1);

  const { Alias, SortOrder, Type } = dataType;
  let aliasTimeOut = null;

  const sortOptions = selectOptions.slice();

  useEffect(() => {
    setType(dataType.Type || 1);
  }, []);

  const updateValue = (key, value) => {
    const newObject = {};
    newObject[`${dataType.Name}`] = {};
    newObject[`${dataType.Name}`][key] = value;
    if (dataType && dataType.pk_DepartmentDataType) {
      newObject[`${dataType.Name}`].pk_DepartmentDataType = dataType.pk_DepartmentDataType;
    }

    if (key === 'Type') {
      newObject[`${dataType.Name}`].UIType = value;
    }
    if (key === 'Enabled') {
      if (value) {
        newObject[`${dataType.Name}`].SortOrder = numberOfEnabled + 1;
        // newObject[`${dataType.Name}`].listOrigin = 'disabled';
      } else {
        newObject[`${dataType.Name}`].listOrigin = 'enabled';
      }
    }

    updateDataType(newObject, key === 'SortOrder' || (key === 'Enabled' && !value) ? true : false);
  };

  if (!dataType.Enabled) {
    return (
      <div style={style.manageFieldsRow} key={dataType.Name}>
        <div style={{ ...style.manageFieldsName, width: '100%' }}>
          <div style={style.manageFieldsLabel}>{Alias}</div>

          <div style={{ textAlign: 'center', fontSize: 12, fontWeight: 'bold' }}>
            {dataType.Name.indexOf('CustomField') == 0 ? dataType.Name : null}
          </div>
        </div>

        <div>
          <Button
            disabled={isUpdatingDataType}
            color="primary"
            onClick={() => {
              if (updateDataType) {
                updateValue('Enabled', true);
              }
            }}
          >
            <FontAwesomeIcon icon={faArrowAltCircleRight} />
          </Button>
        </div>
      </div>
    );
  } else {
    let isEditable = false;
    if (dataType.Name && dataType.Name.indexOf('CustomField') == 0) {
      isEditable = true;
    }
    return (
      <div
        style={style.manageFieldsRow}
        key={`${dataType.Name}_${dataType.SortOrder}`}
        id={`${dataType.Name}_${dataType.SortOrder}`}
      >
        <div>
          <Button
            color="danger"
            disabled={isUpdatingDataType}
            onClick={() => {
              if (updateDataType) {
                updateValue('Enabled', false);
              }
            }}
          >
            <FontAwesomeIcon icon={faMinusSquare} />
          </Button>
        </div>
        <div style={style.manageFieldsSortOrder}>
          <Input
            disabled={isUpdatingDataType}
            type="select"
            name="select"
            id="exampleSelect"
            defaultValue={SortOrder}
            value={SortOrder}
            onChange={(e) => {
              updateValue('SortOrder', parseInt(e.target.value));
            }}
          >
            {sortOptions}
          </Input>
        </div>
        <div style={{ ...style.manageFieldsSortOrder, width: '24%' }}>
          <Input
            disabled={isUpdatingDataType}
            type="select"
            name="select"
            value={type || 3}
            onChange={(e) => {
              updateValue('Type', parseInt(e.target.value));
              setType(e.target.value);
            }}
          >
            <option value={1}>Score</option>
            <option value={2}>Text </option>
            <option value={3}>Yes/No</option>
          </Input>
        </div>
        <div style={{ ...style.manageFieldsName, width: '54%' }}>
          <Input
            autocomplete={`field_${dataType.Name}`}
            id={`${dataType.Name}_${dataType.SortOrder}_Alias`}
            disabled={!isEditable}
            defaultValue={`${Alias || ''}`}
            onChange={(e) => {
              const newValue = e.target.value;
              if (aliasTimeOut) {
                clearTimeout(aliasTimeOut);
              }

              aliasTimeOut = setTimeout(() => {
                updateValue('Alias', newValue);
              }, 1000);
            }}
          />
          <div style={{ textAlign: 'center', fontSize: 12, fontWeight: 'bold' }}>
            {isEditable ? dataType.Name : null}
          </div>
        </div>

        <div>
          <Button
            id={`DisplayOnRank_${dataType.SortOrder}_${dataType.Name}`}
            color={dataType.DisplayOnRank ? 'success' : 'danger'}
            disabled={isUpdatingDataType}
            onClick={(e) => {
              updateValue('DisplayOnRank', !dataType.DisplayOnRank);
            }}
          >
            <FontAwesomeIcon icon={dataType.DisplayOnRank ? faEye : faEyeSlash} />
          </Button>
        </div>
        <div style={{ paddingLeft: 5 }}>
          <Button
            color={dataType.DisplayToEvaluator ? 'success' : 'danger'}
            disabled={isUpdatingDataType}
            onClick={(e) => {
              updateValue('DisplayToEvaluator', !dataType.DisplayToEvaluator);
            }}
          >
            <FontAwesomeIcon
              id={`DisplayToEvaluator_${dataType.SortOrder}`}
              icon={dataType.DisplayToEvaluator ? faEye : faEyeSlash}
            />
          </Button>
        </div>
      </div>
    );
  }
};

export default DataTypeItem;
