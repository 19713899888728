import React, { useEffect, useState } from 'react';
import { faTrashAlt, faPencilAlt, faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Input } from 'reactstrap';
import style from './FocusItems.style';
import Swal from 'sweetalert2';
import { set } from 'lodash';
import { useAlert } from 'react-alert';

let typingTimeout = null;

function FocustItems({ focus, index, onDeleteFocus, onSave }) {
  const [edit, setEdit] = useState(false);
  const [Name, setName] = useState('');
  const backgroundColor = index % 2 == 0 ? '#c7cbd1' : 'white';
  const alert = useAlert();
  useEffect(() => {
    if (focus) {
      setName(focus.Name || '');
    }
  }, [focus]);

  return (
    <div style={{ ...style.itemRow, backgroundColor }}>
      <div style={{ ...style.simpleColumn, width: '100%' }}>
        <div style={{ ...style.simpleRow, paddingRight: 10, paddingLeft: 10 }}>
          {edit ? (
            <Input
              value={Name}
              type="text"
              onChange={(e) => {
                const newValue = e.target.value;
                setName(newValue);
              }}
            />
          ) : (
            <div style={style.itemLabel}>{focus.Name}</div>
          )}
        </div>
      </div>
      <div style={style.simpleColumn}>
        <div style={style.simpleRow}>
          <div style={{ marginRight: 10 }}>
            <Button
              size="sm"
              color={edit ? 'success' : 'primary'}
              disabled={edit && Name === focus.Name}
              onClick={() => {
                if (edit) {
                  onSave({ ...focus, Name, index: index }, (err) => {
                    if (!err) {
                      setEdit(false);
                    } else {
                    }
                  });
                } else {
                  setEdit(true);
                }
              }}
            >
              <FontAwesomeIcon icon={edit ? faCheck : faPencilAlt} style={style.clickable} />
            </Button>
          </div>

          <div style={{ marginRight: 10 }}>
            <Button
              size="sm"
              color={edit ? 'secondary' : 'danger'}
              onClick={() => {
                if (edit) {
                  setEdit(false);
                  setName(focus.Name);
                } else {
                  Swal.fire({
                    title: 'Are you sure?',
                    text: 'You will not be able to recover this focus!',
                    showCancelButton: true,
                    confirmButtonText: `Delete`,
                    confirmButtonColor: 'red',
                    icon: 'warning',
                  }).then((result) => {
                    if (result.value) {
                      onDeleteFocus(focus.pk_Focus);
                    }
                  });
                }
              }}
            >
              <FontAwesomeIcon icon={edit ? faTimes : faTrashAlt} style={style.clickable} />
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FocustItems;
