import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router';

import { Button, Modal, ModalFooter, ModalBody, ModalHeader } from 'reactstrap';
import { clone } from '../../../../Services/schedule';

export function RouterPrompt(props) {
  const { hasChanges, onOk, onCancel, title, okLabel, cancelLabel, message } = props;

  const history = useHistory();

  const [showPrompt, setShowPrompt] = useState(false);
  const [currentPath, setCurrentPath] = useState('');

  useEffect(() => {
    if (hasChanges) {
      history.block((prompt) => {
        setCurrentPath(prompt.pathname);
        setShowPrompt(true);
        return 'true';
      });
    } else {
      history.block(() => {});
    }

    return () => {
      history.block(() => {});
    };
  }, [history, hasChanges]);

  window.onbeforeunload = function(e) {
    if (hasChanges) {
      return true;
    }
  };
  const handleOK = useCallback(async () => {
    if (onOk) {
      const canRoute = await Promise.resolve(onOk());
      if (canRoute) {
        history.block(() => {});
        history.push(currentPath);
      }
    }
    setShowPrompt(false);
  }, [currentPath, history, onOk]);

  const handleCancel = useCallback(async () => {
    if (onCancel) {
      const canRoute = await Promise.resolve(onCancel());
      if (canRoute) {
        history.block(() => {});
        history.push(currentPath);
      }
    }
    setShowPrompt(false);
  }, [currentPath, history, onCancel]);

  return (
    <Modal isOpen={showPrompt} centered={true}>
      <ModalHeader>{title || 'Warning!'}</ModalHeader>
      <ModalBody>{message || 'There are unsaved changes. Are you sure want to leave this page?'}</ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={handleOK}>
          {okLabel || 'OK'}
        </Button>
        <Button color="secondary" onClick={handleCancel}>
          {cancelLabel || 'Cancel'}
        </Button>
      </ModalFooter>
    </Modal>
  );
}
