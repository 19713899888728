import { faBullseye, faCheckSquare, faSpinner, faTimes, faUpload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { post } from 'axios';
import _ from 'lodash';
import React, { useCallback, useEffect, useState, useContext } from 'react';
import Dropzone, { useDropzone } from 'react-dropzone';
import { formatBearerToken } from '../../Common.functions';
import Popup from 'reactjs-popup';
import { Col, Form, FormGroup, Input, Row, Table, Button } from 'reactstrap';
import styled from 'styled-components';
import { DepartmentContext } from '../../DepartmentWrapper';
import Swal from 'sweetalert2';
import { fetchDataAgnostic, constructUrl } from '../../Services/dataApi';
import BulkUploadPreview from './BulkUploadPreview';
import EmbededPdf from './Pdf/EmbededPdf/EmbededPdf';
import SingleCandidateDragAndDrop from './SingleCandidateDragAndDrop';
import { useAuth0 } from '../../auth0/reactAuth0Spa';

const orphanedMessage = 'No Associated Users Found';

const PopupWrapper = styled.div`
  // .popup-overlay {
  //   .popup-content {
  //     max-height: 100%;
  //     overflow: scroll;
  //   }
  // }
`;

const thumbsContainer = {
  display: 'flex',
  flexDirection: 'column',
  flexWrap: 'wrap',
  marginTop: 16,
};

const thumb = {
  display: 'inline-flex',
  borderRadius: 2,
  border: '1px solid #eaeaea',
  marginBottom: 8,
  marginRight: 8,
  width: 150,
  height: 100,
  padding: 4,
  boxSizing: 'border-box',
};

const thumbInner = {
  display: 'flex',
  minWidth: 0,
  overflow: 'hidden',
};

const img = {
  display: 'block',
  width: 'auto',
  height: '100%',
};

const getUrlParameter = (name) => {
  name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
  var regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
  var results = regex.exec(window.location.search);
  return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
};

const DragAndDropFiles = (props, { embedded }) => {
  const {
    uploadLogo,
    selectedCandidate,
    isSingleCandidate,
    activeDepartment,
    getCandidateDetails,
    documentTypes = [],
    setDocumentTypes,
    attachedDocuments,
    abortController,
    isLoading,
  } = props;
  const [files, setFiles] = useState([]);
  const [secret, setSecret] = useState(window.location.pathname.split('/')[2]);
  const [finished, setFinished] = useState(false);
  const [numOfErrors, setNumOfErrors] = useState(0);
  const [currentSet, setCurrentSet] = useState(0);
  const [percentageUploaded, setPercentageUploaded] = useState(0.05);
  const [isPosting, setIsPosting] = useState(false);
  const [fileType, setFileType] = useState();
  const [matchType, setMatchType] = useState(null);
  const [pk_UUID_FM, setPk_UUID_FM] = useState(null);
  // const [documentTypes, setDocumentTypes] = useState([]);
  const [error, setError] = useState(false);
  const [department, setDepartment] = useState(null);
  const [candidateFiles, setCandidateFiles] = useState([]);
  const [currentLogo, setCurrentlogo] = useState({});
  const [organization, setOrganization] = useState(null);
  const [filesInserted, setFilesInserted] = useState([]);
  const [filesWithoutAssociatedCandidates, setFilesWithoutAssociatedCandidates] = useState([]);
  const [displayResults, setDisplayResults] = useState(false);
  const [showFileList, setShowFileList] = useState(false);
  const [candidateInfo, setCandidateInfo] = useState(null);
  const [sessionID, setSessionID] = useState(null);
  const [selectedDocumentType, setSelectedDocumentType] = useState(null);
  const [isBulkUploader, setIsBulkUploader] = useState(false);
  const [currentProgress, setCurrentProgress] = useState(0);
  const [tempToken, setTempToken] = useState(null);
  // const [isSingleCandidate, setIsSingleCandidate] = useState(null);
  const [itemPreviewPopup, setItemPreviewPopup] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(true);

  const dContext = useContext(DepartmentContext);
  const { getTokenSilently } = useAuth0();

  useEffect(() => {
    if (documentTypes.length > 0) {
      const documentTypesCopy = JSON.parse(JSON.stringify(documentTypes[0]));
      setSelectedDocumentType(0);
    }
  }, [documentTypes]);

  // useEffect(() => {
  //   if (selectedCandidate) {
  //   }
  // }, [JSON.stringify(selectedCandidate)]);

  const getLogoInformation = async (paramDepartment) => {
    const chosenDepartment = paramDepartment || department;
    const logoObject = await getLogo(chosenDepartment);
    setCurrentlogo(logoObject);
    setCandidateFiles([logoObject]);
    setFiles([logoObject]);
  };

  const getCandidateInformation = () => {
    getCandidateDetails(selectedCandidate);
  };

  const getIDByCondition = (documentName) => {
    const nameSplit = documentName.split('_');
    for (let i = 0; i < nameSplit.length; i++) {
      const currentItem = nameSplit[i];
      const isNum = /^\d+$/.test(currentItem);
      if (currentItem.length > 4 && currentItem.length < 10 && isNum) {
        return currentItem;
      }
    }
    return false;
  };

  const getIDFromFileName = (fileName) => {
    const pieces = fileName.split('_');

    const regex = /^[^0-9]{2}([0-9]+)/;
    let sf_match_id = false;
    pieces.forEach((piece) => {
      const isNum = /^\d+$/.test(piece);
      if (!isNum && !sf_match_id) {
        const match = piece.match(regex);
        if (match && match[1] && !sf_match_id) {
          sf_match_id = match[1];
        }
      } else if (!sf_match_id) {
        sf_match_id = piece;
      }
    });
    return sf_match_id;
  };

  const getAAMCIDFromFileName = (fileName) => {
    const nameSplit = fileName.split('_');
    for (let i = 0; i < nameSplit.length; i++) {
      const currentItem = nameSplit[i];
      const isNum = /^\d+$/.test(currentItem);
      if (currentItem.length > 4 && currentItem.length < 10 && isNum) {
        return currentItem;
      }
    }
    return false;
  };

  const getLogo = (department) => {
    return fetchDataAgnostic('files/logo', { departmentUUID: department }, null, { abortController: abortController })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {});
  };

  const uploadFiles = (files) => {
    const formData = multiCandidateObject(files);
    fileUpload(formData);
  };

  const initiateFileUpload = (files, documentTypePk, isBulkUploader) => {
    if (files.length > 0 && isPosting === false) {
      if (uploadLogo) {
        const formData = logoObject(files, documentTypePk);
        return fileUpload(formData);
      } else if (isSingleCandidate) {
        const formData = singleCandidateObject(files, documentTypePk);

        return fileUpload(formData);
      } else {
        fileUploadBulk(files, isBulkUploader);
      }
    }
  };

  useEffect(() => {
    if (files[currentSet] !== undefined) {
      fileUpload();
    }
  }, [currentSet]);

  // NOT BEING USED. IS ONLY MENTIONED IN COMMENTED CODE
  const checkIfUserExistsSFMatch = (allFiles) => {
    const selectedFiles = allFiles[currentProgress];
    const deepCloneFile = _.cloneDeep(selectedFiles);
    const id_Array = [];
    deepCloneFile.forEach((file) => {
      const SFMatchID = getIDByCondition(file.name);
      id_Array.push(SFMatchID);
    });
    const { department, season } = dContext;
    const { pk_Department } = department;
    const { pk_Season } = season;
    fetchDataAgnostic('candidate/all/ ', {
      id_Array,
      selectedDocumentType,
      type: 'sfMatch',
      pk_Department,
      organization,
    })
      .then((results) => {
        deepCloneFile.forEach((file) => {
          let userAssociated = false;
          const SFMatchID = getIDByCondition(file.name);
          results.data.forEach((resultItem) => {
            if (resultItem.SFMatchID === SFMatchID) {
              file['userInfo'] = resultItem;
              userAssociated = true;
              if (resultItem.pk_File) {
                file['existingFile'] = true;
              }
              file['checked'] = true;
            }
          });
          file['userAssociated'] = userAssociated;
        });

        const deepCloneAllFiles = _.cloneDeep(files);
        deepCloneAllFiles[currentProgress] = deepCloneFile;
        setFiles(deepCloneAllFiles);
        setItemPreviewPopup(true);
      })
      .catch((err) => {});
  };

  const checkIfUserExistsAAMCID = (allFiles) => {
    const selectedFiles = allFiles[currentProgress];
    const deepCloneFile = _.cloneDeep(selectedFiles);
    const id_Array = deepCloneFile.map((file) => {
      const AAMCID = getIDByCondition(file.name);
      return AAMCID;
    });

    const { department, season } = dContext;
    const { pk_Department } = department;
    const { pk_Season } = season;
    fetchDataAgnostic('candidate/all/byExternalID', {
      id_Array,
      selectedDocumentType,
      type: 'AAMCID',
      department,
      organization,
      pk_Department,
    })
      .then((results) => {
        deepCloneFile.forEach((file) => {
          let userAssociated = false;
          const AAMCID = getIDByCondition(file.name);
          results.data.forEach((resultItem) => {
            if (resultItem.AAMCID === AAMCID) {
              file['userInfo'] = resultItem;
              userAssociated = true;
              if (resultItem.pk_File) {
                file['existingFile'] = true;
              }
              file['checked'] = true;
            }
          });
          file['userAssociated'] = userAssociated;
        });

        const deepCloneAllFiles = _.cloneDeep(files);
        deepCloneAllFiles[currentProgress] = deepCloneFile;
        setFiles(deepCloneAllFiles);
        setItemPreviewPopup(true);
      })
      .catch((err) => {});
  };

  const onDrop = useCallback(
    (acceptedFiles) => {
      setIsBulkUploader(true);
      const filesCopy = files.slice();
      filesCopy.push(acceptedFiles);
      setFiles(filesCopy);
      initiateFileUpload(filesCopy, null, true);
    },
    [selectedDocumentType],
  );
  // useEffect(() => {
  //   const secretUUID = getUrlParameter('allow');
  //   setSecret(secretUUID);
  // }, []);

  useEffect(
    () => () => {
      // Make sure to revoke the data uris to avoid memory leaks
      files.forEach((file) => URL.revokeObjectURL(file.preview));
    },
    [files],
  );

  const determineContainer = () => {
    if (uploadLogo) {
      return 'logos';
    }

    return 'newuploadtest';
  };

  const multiCandidateObject = (candidates, matchType) => {
    const last = candidates.length - 1;
    const candidate = candidates[last];
    const formData = new FormData();

    for (var i in candidate) {
      const currentFile = candidate[i];
      const { userInfo } = currentFile;

      if (dContext && dContext.department && dContext.department.ApplicationSystem === 'sf_match') {
        const name = getIDFromFileName(currentFile.name);
        if (name) {
          const SFMatchID = name;

          const payload = {
            name: currentFile.name,
            SFMatchID: SFMatchID,
            department: dContext.department.pk_Department,
            container: 'newuploadtest',
            checked: currentFile.checked,
            matchType: 'SFMatchID',
            fk_DocumentType: selectedDocumentType,
          };
          formData.append(`files`, currentFile, JSON.stringify(payload));
        } else {
          currentFile.error = true;
          setNumOfErrors((prevNumOfErrs) => prevNumOfErrs + 1);
        }
      } else {
        const name = getIDFromFileName(currentFile.name);
        const AAMCID = name;

        const payload = {
          name: currentFile.name,
          AAMCID: AAMCID,
          department: dContext.department.pk_Department,
          container: 'newuploadtest',
          checked: currentFile.checked,
          matchType: 'AAMCID',
          fk_DocumentType: selectedDocumentType,
        };
        if (name) {
          formData.append(`files`, currentFile, JSON.stringify(payload));
        } else {
          currentFile.error = true;
          setNumOfErrors((prevNumOfErrs) => prevNumOfErrs + 1);
        }
      }
    }
    return formData;
  };

  const singleCandidateObject = (files, pk_DocumentType) => {
    const file = files[0];
    const formData = new FormData();

    for (var i in file) {
      const fileJson = {
        name: file[i].name,
        pk_Department: activeDepartment.pk_Department,
        container: determineContainer,
        pk_Candidate: selectedCandidate.pk_Candidate,
        pk_DocumentType: pk_DocumentType,
      };
      const jsonToSend = JSON.stringify(fileJson);

      formData.append(`File`, file[i], jsonToSend);
    }
    return formData;
  };

  const logoObject = (files, documentTypePk) => {
    const file = files[0];
    const formData = new FormData();
    for (var i in file) {
      const jsonToSend = `{ "name":"${
        file[i].name
      }", "checked": "true", "department":"${department}", "container":"${determineContainer()}", "logo": "true"}`;
      formData.append(`File`, file[i], jsonToSend);
    }
    return formData;
  };

  const displayResultsTable = (candidates, color, message) => {
    const filesHaveAssociatedCandidate = message !== orphanedMessage;
    if (!candidates || candidates.length === 0) {
      return <div></div>;
    }
    return (
      <div>
        <div>
          <div style={{ border: `3px solid ${color}`, padding: '15px' }}>
            <h6 style={{ textAlign: 'center', fontWeight: '700', color, marginBottom: 0 }}>
              {message} ({candidates.length})
            </h6>
          </div>
          <Table>
            <thead></thead>
            <tbody>
              {candidates.map((item) => {
                const { FirstName, LastName, data } = item;
                const { name } = data;

                return (
                  <tr>
                    {filesHaveAssociatedCandidate && (
                      <td>
                        {LastName}, {FirstName}
                      </td>
                    )}
                    <td>{name}</td>
                  </tr>
                );
                return <div />;
              })}
            </tbody>
          </Table>
        </div>
      </div>
    );
  };

  const handleSingleCandidateUpload = (data) => {
    const { filesInserted, filesWithoutAssociatedCandidates } = data;
    setFilesInserted(filesInserted);
    setDisplayResults(true);
    setCurrentProgress(0);
    if (uploadLogo) {
      getLogoInformation();
    } else {
      // if this a bulk uploader than there is no single candidate to fetch
      getCandidateInformation();
    }
    return true;
  };

  const handleMutipleCandidateUpload = (data) => {
    const { filesInserted, filesWithoutAssociatedCandidates } = data;
    setFilesInserted(filesInserted);
    setDisplayResults(true);
    setFilesWithoutAssociatedCandidates(filesWithoutAssociatedCandidates);
  };

  const clearQueue = () => {
    setFiles([]);
    setCurrentSet(0);
    setNumOfErrors(0);
  };

  const chunkUpload = async (formData, isBulkUploader, url) => {
    return getTokenSilently().then((token) => {
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
          ...formatBearerToken(token),
        },
      };
      return post(url, formData, config)
        .then((res) => {
          return res.data;
        })
        .catch((err) => {
          return false;
        });
    });
  };

  const fileUploadBulk = async (files, isBulkUploader) => {
    let filesAsArray = files[0];
    const isLocal = process.env.NODE_ENV !== 'production';
    const url = isBulkUploader ? constructUrl('files') : constructUrl('files/candidate/document');
    let filesInserted = [];
    let filesWithoutAssociatedCandidates = [];
    const chunkSize = 5;
    const filesLength = filesAsArray.length;

    setIsPosting(true);

    for (let i = 0; i < filesAsArray.length; i += chunkSize) {
      const filesChunk = filesAsArray.slice(i, i + chunkSize);

      const filesTogether = [];
      filesTogether.push(filesChunk);
      const formData = multiCandidateObject(filesTogether, matchType);

      // const formData = multiCandidateObject(files, matchType);
      const chunkResult = await chunkUpload(formData, isBulkUploader, url);

      if (!chunkResult) {
        Swal.fire('Oops', 'Something went wrong uploading file. Please try again or contact support', 'error').then(
          () => {
            clearQueue();
            setSelectedDocumentType(0);
            setIsPosting(false);
            setFilesWithoutAssociatedCandidates([]);
            setFilesInserted([]);
            setDisplayResults(false);
          },
        );
      } else {
        filesInserted = [...filesInserted, ...chunkResult.filesInserted];
        filesWithoutAssociatedCandidates = [
          ...filesWithoutAssociatedCandidates,
          ...chunkResult.filesWithoutAssociatedCandidates,
        ];

        let decimal = (i + chunkSize) / filesLength;

        // seeing no progress is disconcerting, so make sure the progress is at least 5%
        if (decimal < 0.05) {
          decimal = 0.05;
        } else if (decimal > 1) {
          decimal = 1;
        }

        setPercentageUploaded(decimal);
      }

      // setTimeout(() => { console.log('Upload wait 500ms'); }, 500);
    }

    handleMutipleCandidateUpload({ filesInserted, filesWithoutAssociatedCandidates });

    setIsPosting(false);
  };

  const fileUpload = (formData, isBulkUploader) => {
    // return;
    const isLocal = process.env.NODE_ENV !== 'production';
    const url = isBulkUploader ? constructUrl('files') : constructUrl('files/candidate/document');
    setIsPosting(true);
    getTokenSilently().then((token) => {
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
          ...formatBearerToken(token),
        },
        onUploadProgress: function(progressEvent) {
          var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          setCurrentProgress(percentCompleted);
        },
      };
      return post(url, formData, config)
        .then((res) => {
          handleSingleCandidateUpload(res.data);
        })
        .catch((err) => {
          Swal.fire('Oops', 'Something went wrong uploading file. Please try again or contact support', 'error').then(
            () => {
              clearQueue();
            },
          );
          return false;
        })
        .finally(() => {
          setIsPosting(false);
        });
    });
  };

  const progressBar = (i, thumbnail) => {
    if (thumbnail && thumbnail.error) {
      return <FontAwesomeIcon size="3x" color="#e50000" icon={faTimes} style={{ paddingBottom: '15px' }} />;
    }
    let progress = 0;
    progress = currentProgress;

    if (progress === 100) {
      return <FontAwesomeIcon size="3x" color="#228b22" icon={faCheckSquare} style={{ paddingBottom: '15px' }} />;
    }
    return (
      <div className="progress-bar">
        <div style={{ width: `${progress}%` }}></div>
      </div>
    );
  };

  const cancelCurrentSet = () => {
    // take the items off the array if they cancel the transaction
    const fileClone = [...files];
    fileClone.splice(currentProgress, 1);
    setFiles(fileClone);
  };

  const thumbs = () => {
    if (files.length === 0) {
      return;
    }
    let thumbnails = [];
    for (var i in files) {
      thumbnails[i] = files[i].map((file) => {
        return Object.assign(file, {
          preview: URL.createObjectURL(file),
        });
      });
    }

    const previewNodes = [];
    for (var i in thumbnails) {
      const nodes = thumbnails[i].map((thumbnail) => {
        if (thumbnail.type.includes('pdf')) {
          return (
            <div key={thumbnail.name} style={{ display: 'flex', flexDirection: 'row' }}>
              <EmbededPdf thumbnail={thumbnail} />
              <div style={{ paddingLeft: '10px' }}>
                <p>{thumbnail.name}</p>
                {!isBulkUploader && progressBar(i, thumbnail)}
              </div>
            </div>
          );
        }
        if (thumbnail.type.includes('msword')) {
          return (
            <div key={thumbnail.name}>
              <iframe src={`https://docs.google.com/gview?url=${thumbnail.preview}&embedded=true`}></iframe>
            </div>
          );
        }
        return (
          <div key={thumbnail.name} style={{ display: 'flex', flexDirection: 'row' }}>
            <div style={thumb} key={thumbnail.name}>
              <div style={thumbInner}>
                <img src={thumbnail.preview} style={img} />
              </div>
            </div>
            <div>
              <p>{thumbnail.name}</p>
              {progressBar(i, thumbnail)}
            </div>
          </div>
        );
      });
      previewNodes.push(nodes);
    }
    return previewNodes;
  };

  // if (props.uploadLogo) {
  //   return (
  //     <LogoUploader
  //       documentTypes={documentTypes}
  //       setDocumentTypes={setDocumentTypes}
  //       singleCandidateObject={singleCandidateObject}
  //       fileUpload={fileUpload}
  //       initiateFileUpload={initiateFileUpload}
  //       setSelectedDocumentType={setSelectedDocumentType}
  //       getCandidateInformation={getCandidateInformation}
  //       setFiles={setFiles}
  //       currentSet={currentSet}
  //       candidateFiles={candidateFiles}
  //       setCandidateFiles={setCandidateInfo}
  //       urlObject={{ pk_UUID_FM, department, organization, sessionID, tempToken }}
  //     />
  //   );
  // }

  // if (!selectedCandidate || !selectedCandidate.Documents) {
  //   return <div></div>;
  // }

  if (isSingleCandidate || props.uploadLogo) {
    return (
      <SingleCandidateDragAndDrop
        attachedDocuments={attachedDocuments}
        documentTypes={documentTypes} // {selectedCandidate.Documents}
        uploadLogo={uploadLogo}
        setDocumentTypes={setDocumentTypes}
        singleCandidateObject={singleCandidateObject}
        fileUpload={fileUpload}
        initiateFileUpload={initiateFileUpload}
        isLoading={isLoading}
        setSelectedDocumentType={setSelectedDocumentType}
        getCandidateDetails={getCandidateDetails}
        selectedCandidate={selectedCandidate}
        getCandidateInformation={getCandidateInformation}
        setFiles={setFiles}
        currentSet={currentSet}
        currentLogo={currentLogo}
        candidateFiles={candidateFiles}
        setCandidateFiles={setCandidateInfo}
        urlObject={{ pk_UUID_FM, department, organization, sessionID, tempToken }}
      />
    );
  }

  return (
    <Col>
      <PopupWrapper>
        <Popup closeOnDocumentClick={false} open={itemPreviewPopup} id="bulk-upload-popup">
          <BulkUploadPreview
            cancelCurrentSet={cancelCurrentSet}
            files={files[currentProgress]}
            allFiles={files}
            setIsPosting={setIsPosting}
            isPosting={isPosting}
            setFiles={setFiles}
            uploadFiles={uploadFiles}
            currentSet={currentSet}
            setItemPreviewPopup={setItemPreviewPopup}
          />
        </Popup>
      </PopupWrapper>
      {/* {candidateInfo && (
        <Row>
          <Col>
            <img style={{ width: '150px', height: 'auto' }} src={candidateInfo.uri} />
          </Col>
        </Row>
      )} */}
      <Row style={{ maxWidth: '100vw' }}>
        <Col md={12} style={{ height: '100%' }}>
          <div style={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column' }}>
            {/* <h2 style={{ paddingTop: '15px', paddingBottom: '50px', textAlign: 'center' }}>Upload Files</h2> */}
            {!isPosting && !displayResults && (
              <div style={{ paddingTop: '15px', paddingBottom: '15px' }}>
                <h4>Document Type</h4>
                <Form>
                  <FormGroup>
                    <Input
                      type="select"
                      name="select"
                      id="exampleSelect"
                      onChange={(e) => {
                        const { value } = e.target;
                        setSelectedDocumentType(Number(value));
                      }}
                    >
                      <option value={0} select={0 === selectedDocumentType}>
                        None
                      </option>
                      {documentTypes.map((item) => (
                        <option
                          key={item.pk_DocumentType}
                          value={item.pk_DocumentType}
                          selected={item.pk_DocumentType === selectedDocumentType}
                        >
                          {item.DocumentTypeName}
                        </option>
                      ))}
                    </Input>
                  </FormGroup>
                </Form>
              </div>
            )}
            {isPosting && (
              <div style={{ height: '429px' }}>
                <h4>
                  Upload Queue {'  '}
                  <FontAwesomeIcon icon={faSpinner} spin />
                </h4>
                <h4 style={{ color: 'rgb(255, 40, 40)', fontWeight: '700' }}> Do not close or refresh window</h4>
                <div style={{ width: '100%', height: '10px', border: '2px solid #000000' }}>
                  <div
                    style={{ backgroundColor: 'rgb(0, 209, 0)', width: `${percentageUploaded * 100}%`, height: '100%' }}
                  />
                </div>
                <div style={{ height: '75%', overflowY: 'scroll' }}>
                  <aside style={thumbsContainer}>{thumbs()}</aside>
                </div>
              </div>
            )}
          </div>
        </Col>
      </Row>
      <Row>
        <Col md={12} style={{ height: '100%' }}>
          {!displayResults && !isPosting && (
            <Dropzone onDrop={onDrop} disabled={selectedDocumentType === 0}>
              {({ getRootProps, getInputProps, isDragActive }) => {
                const icon = isDragActive ? faBullseye : faUpload;
                return (
                  <div style={{ width: '100%', height: '50vh' }}>
                    <div {...getRootProps()} style={{ height: '100%' }}>
                      <input {...getInputProps()} />
                      {selectedDocumentType !== 0 && (
                        <div
                          style={{
                            height: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            flexDirection: 'column',
                          }}
                        >
                          <FontAwesomeIcon icon={icon} size="10x" style={{ paddingBottom: '15px' }} />
                          {isDragActive ? (
                            <h4>Drop the files here ...</h4>
                          ) : (
                            <h4>Click to browse or drag and drop files here</h4>
                          )}
                        </div>
                      )}

                      {selectedDocumentType === 0 && (
                        <h4 style={{ color: 'rgb(255, 40, 40)', fontWeight: '700' }}> Please select a document type</h4>
                      )}
                    </div>
                  </div>
                );
              }}
            </Dropzone>
          )}
          {displayResults && (
            <div style={{ overflow: 'scroll', maxHeight: '75vh' }}>
              {displayResultsTable(filesWithoutAssociatedCandidates, '#990000', orphanedMessage)}
              {displayResultsTable(filesInserted, '#00008B', 'Upload Successful')}
            </div>
          )}
        </Col>
      </Row>
      {displayResults && !isPosting && (
        <div style={{ display: 'flex', justifyContent: 'center', padding: '10px' }}>
          <Button
            onClick={(e) => {
              e.preventDefault();
              setFilesWithoutAssociatedCandidates([]);
              setFilesInserted([]);
              clearQueue();
              setDisplayResults(false);
              setSelectedDocumentType(0);
            }}
            color="success"
          >
            New Upload
          </Button>
        </div>
      )}
    </Col>
  );
};

export default DragAndDropFiles;
