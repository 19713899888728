import { faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Circle } from '../../../../Common.jsx.js';
import React from 'react';

const DNRComponent = (isPhoto) => {
  const left = isPhoto ? '7px' : '22px';
  const top = isPhoto ? '-5px' : '36px';

  return (
    <div style={{ position: 'relative', left, top }}>
      <Circle>
        <p style={{ padding: 0, margin: 0 }}>DNR</p>
      </Circle>
    </div>
  );
};

const renderAvatar = (photoUrl, enableDoNotRank) => {
  if (photoUrl && photoUrl !== '') {
    return (
      <div style={{ minHeight: '78px' }}>
        <img style={{ width: '78px', height: 'auto' }} src={photoUrl}></img>
        {enableDoNotRank && DNRComponent(true)}
      </div>
    );
  }

  return (
    <div
      style={{
        width: '78px',
        height: '78px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <FontAwesomeIcon icon={faUser} size="4x" />
      {enableDoNotRank && DNRComponent(false)}
    </div>
  );
};

export { renderAvatar };
