import {
  faCheck,
  faCheckCircle,
  faCheckSquare,
  faExclamationCircle,
  faExclamationTriangle,
  faGear,
  faHatWizard,
  faInfoCircle,
  faMinusCircle,
  faRedoAlt,
  faSpinner,
  faSquare,
  faSyncAlt,
  faTimesCircle,
  faUndoAlt,
  faUpload,
  faUserCircle,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Table, Button, Tooltip, Input, Alert, UncontrolledTooltip, Modal, ModalHeader, ModalBody } from 'reactstrap';
import csv from 'csv';
import React, { useCallback, useState, useContext, useEffect, useRef } from 'react';
import { useDropzone } from 'react-dropzone';
import { DepartmentContext } from '../../../../../DepartmentWrapper';
import Swal from 'sweetalert2';
import XLSX from 'xlsx';
import { useAuth0 } from '../../../../../auth0/reactAuth0Spa';
import { formatBearerToken, mergeUniqueObjects } from '../../../../../Common.functions';
import { candidateImportData, AAMCIDCSVData } from '../../../../../enums';
import { fetchDataAgnostic, postDataAgnostic, putData } from '../../../../../Services/dataApi';
import BootstrapTable from 'react-bootstrap-table-next';
import { getFieldAliases, pdf_concatenate, separateDataTypesByAvailability } from 'Services/candidates';
import './AdminImportCandidates.style.css';
import { clone } from 'Services/schedule';
import ManageFields from '../../AdminSettings/CandidateSettings/ManageFields/ManageFields';
import { useAlert } from 'react-alert';
import moment from 'moment';
import { faClock } from '@fortawesome/free-regular-svg-icons';
import { chunk, get, set } from 'lodash';
import Loading from 'Body/Statuses/Loading';
import chardet from 'chardet';

const mapDepartmentToArray = (candidates, pk_Season) => {
  return candidates.map((item) => {
    return { ...item, fk_Season: pk_Season };
  });
};

const popoverModifiers = {
  preventOverflow: { boundariesElement: 'viewport' },
  flip: {
    behavior: ['top', 'right', 'bottom', 'left'],
    fallbackPlacements: ['top', 'right', 'bottom', 'left'],
  },
};

const AdminImportCandidates = ({
  getCandidates,
  getAllCandidatesData,
  existingCandidatesReference = [],
  setImportErasOpen,
  updateParentCandidateList,
  changes,
  setChanges,
}) => {
  const [files, setFiles] = useState([]);
  const [indentifierTooltipOpen, setIndentifierTooltipOpen] = useState(false);
  const [displayResults, setDisplayResults] = useState(false);
  const [candidatesToBeImported, setCandidatesToBeImported] = useState([]);
  const [repackedCandidates, setRepackedCandidates] = useState([]);

  const [candidatesForUpdate, setCandidatesForUpdate] = useState([]);
  const [candidatesForAddition, setCandidatesForAddition] = useState([]);
  const [candidatesForInvalid, setCandidatesForInvalid] = useState([]);

  const [candidatesToBeImportedKeys, setCandidatesToBeImportedKeys] = useState([]);

  const [mappedCount, setMappedCount] = useState(0);
  const [unmappedCount, setUnmappedCount] = useState(0);
  const [excludedCount, setExcludedCount] = useState(0);
  const [uploadedCount, setUploadedCount] = useState(0);

  const [showManageFields, setShowManageFields] = useState();
  const [dataFieldsHaveChange, setDataFieldsHaveChange] = useState(false);
  const [uploadStatus, setUploadStatus] = useState({}); // keys of <AAMCID>_<SFMATCHID>_<Email>_<FirstName>_<LastName>, value of candidate object
  const [repackedCandidateCount, setRepackedCandidateCount] = useState(0);

  const [results, setResults] = useState({ candidatesInserted: [], existingCandidates: [] });
  const resultsRef = useRef({ candidatesInserted: [], existingCandidates: [] });

  const [isPosting, setIsPosting] = useState(false);
  const [isFetchingDataTypes, setIsFetchingDataTypes] = useState(false);
  const [isRepackingCandidatesForImport, setIsRepackingCandidatesForImport] = useState(false);
  const [savedFieldAssignment, setSavedFieldAssignment] = useState([]);

  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const { loading, user, isAuthenticated, loginWithRedirect, getTokenSilently, logout } = useAuth0();

  const dataTypesRef = useRef([]);
  const uploadStatusRef = useRef({});
  const hasReset = useRef(false);

  const dContext = useContext(DepartmentContext);
  const { season, department } = dContext;
  const alert = useAlert();

  useEffect(() => {
    getDepartmentDataTypes();
    getAllCandidatesData();
  }, []);

  useEffect(() => {
    if (
      dataTypesRef.current &&
      dataTypesRef.current.enabled &&
      dataTypesRef.current.enabled.length > 0 &&
      existingCandidatesReference &&
      existingCandidatesReference.length > 0
    ) {
      // doFakeImport();
    } else {
    }
  }, [dataTypesRef.current, existingCandidatesReference]);

  useEffect(() => {
    if (candidatesToBeImportedKeys) {
      countImportStats(candidatesToBeImportedKeys);
    }
  }, [candidatesToBeImportedKeys]);

  useEffect(() => {
    console.log('candidatesToBeImported: ', candidatesToBeImported);
  }, [candidatesToBeImported]);
  useEffect(() => {
    // console.log('isFetchingDataTypes change: ', isFetchingDataTypes);
  }, [isFetchingDataTypes]);

  const updateImportStatuses = (newUploadStatus) => {
    const newCandidatesForAddition = clone(candidatesForAddition);
    const newCandidatesForUpdate = clone(candidatesForUpdate);
    newCandidatesForAddition.forEach((candidate) => {
      const { AAMCID, SFMatchID, Email, FirstName, LastName } = candidate;
      const key = `${AAMCID || SFMatchID || Email || `${FirstName}_${LastName}`}`;
      candidate.uploadStatus = newUploadStatus[key] ? newUploadStatus[key].uploadStatus : 'queued';
    });

    newCandidatesForUpdate.forEach((candidate) => {
      const { AAMCID = '', SFMatchID = '', Email = '', FirstName, LastName } = candidate;
      const key = `${AAMCID || SFMatchID || Email || `${FirstName}_${LastName}`}`;
      if (newUploadStatus[key] && candidate.uploadStatus !== newUploadStatus[key].uploadStatus) {
        candidate.uploadStatus = newUploadStatus[key].uploadStatus;
      } else {
        candidate.uploadStatus = 'queued';
      }
    });

    setCandidatesForAddition((prevValue) => newCandidatesForAddition);
    setCandidatesForUpdate((prevValue) => newCandidatesForUpdate);
  };

  const updateImportStatusForSelected = (options) => {
    const { candidates = [], newUploadStatus, uploadStatus } = options;

    const statusArray = Object.values(newUploadStatus);

    candidates.forEach((candidate) => {
      const { AAMCID, SFMatchID, Email, FirstName, LastName } = candidate;

      const matchingCandidateIndex = statusArray.findIndex((item) => {
        const match =
          (item.AAMCID && AAMCID && item.AAMCID === AAMCID) ||
          (item.SFMatchID && SFMatchID && item.SFMatchID === SFMatchID) ||
          (item.Email && Email && item.Email.toLowerCase() === Email.toLowerCase());

        return match;
      });

      const matchingCandidate = clone(statusArray[matchingCandidateIndex]);

      if (matchingCandidate) {
        const { AAMCID, SFMatchID, Email, FirstName, LastName } = matchingCandidate;

        const key = `${AAMCID || SFMatchID || Email || `${FirstName}_${LastName}`}`;

        newUploadStatus[key] = { ...matchingCandidate, uploadStatus: uploadStatus || candidate.updateStatus };
        statusArray.splice(matchingCandidateIndex, 1);
      } else {
        const key = `${AAMCID || SFMatchID || Email || `${FirstName}_${LastName}`}`;
        newUploadStatus[key] = { ...candidate, uploadStatus: uploadStatus || candidate.uploadStatus };
      }
    });

    return newUploadStatus;
  };

  const doFakeImport = () => {
    const data = [
      {
        'First Name': 'Tu',
        'Last Name': 'Wahdir',
        'AAMC ID': parseInt(Math.random() * 150000).toString(),
        SFMatchID: parseInt(Math.random() * 150000).toString(),
      },
      {
        'First Name': 'GeraldXX',
        'Last Last Name': 'HudsonSSS',
        'AAMC ID': '100001',
      },
      {
        'First Name': 'Domon',
        'Last Name': 'Kasshu',
        MEwoa: 'eEEEE',
      },
      {
        'First Name': 'Cornelius',
        'Last Name': 'Gray',
        'AAMC ID': '100065',
      },
    ];

    processCandidatePreviewKeys(data);
    setCandidatesToBeImported(data);
  };

  const countImportStats = (data) => {
    if (data) {
      let newMappedCount = 0;
      let newUnmappedCount = 0;
      let newExcludedCount = 0;

      data.forEach((field) => {
        const { field_status, field_to_map } = field;
        if (field_status === 'Mapped') {
          newMappedCount++;
        } else {
          newUnmappedCount++;
        }

        if (field_to_map === 'doNotImport') {
          newExcludedCount++;
        }
      });

      setMappedCount(newMappedCount);
      setUnmappedCount(newUnmappedCount);
      setExcludedCount(newExcludedCount);
    }
  };

  const computeLoadingProgress = () => {
    const totalCandidates = Object.keys(uploadStatus).length;
    const totalCandidatesUploaded = Object.keys(uploadStatus).filter((key) => {
      return uploadStatus[key].uploadStatus === 'uploaded';
    }).length;

    return { totalCandidatesUploaded, totalCandidates };
  };

  const indetifierTooltipToggle = () => {
    setIndentifierTooltipOpen((prevState) => !prevState);
  };

  const cleanupBOM = (candidates) => {
    const newCandidates = [];

    const codePointsToRemove = [
      0xfeff, // Zero Width No-Break Space (most common)
      0xfffe, // UTF-16 Big-Endian BOM
      0xfeff, // UTF-16 Little-Endian BOM
      0xfffe0000, // UTF-32 Big-Endian BOM
      0x0000feff, // UTF-32 Little-Endian BOM
      239,
      187,
      191, // Bytes of UTF-8 BOM "ï»¿"
      // Add any other BOM code points you want to remove
    ];

    candidates.forEach((c, i) => {
      const newC = {};

      Object.keys(c).forEach((key) => {
        const cleanKey = key
          .split('')
          .filter((char) => {
            const codePoint = char.charCodeAt(0);
            return !codePointsToRemove.includes(codePoint);
          })
          .join('');

        newC[cleanKey] = c[key];
      });

      newCandidates.push(newC);
    });

    return newCandidates;
  };

  const resetCandidateImports = () => {
    setRepackedCandidates([]);
    processCandidatePreviewKeys([]);
    setCandidatesToBeImported([]);
    setCandidatesForAddition([]);
    setCandidatesForUpdate([]);
    setCandidatesForInvalid([]);
    setIsPosting(false);
    setUploadedCount(0);
    setDisplayResults(false);

    setResults({ candidatesInserted: [], existingCandidates: [] });
    setChanges({ ...changes, import_data: false });
    hasReset.current = true;
  };

  const isCandidateHasRequiredFields = (candidate) => {
    const requiredFields = ['AAMCID', 'SFMatchID', 'Email'];
    let aliasFields = []; // Fields that are actually recognized as the requiredFields under a different name, i.e. Email could have E-mail and Email as aliases.

    let hasRequiredFields = false;
    requiredFields.forEach((field) => {
      // aliasFields = [...aliasFields, ...getFieldAliases(field)];

      let alias = getCandidateAliasForKey({
        candidate,
        key: field,
      });

      if (!alias || !candidate[alias]) {
        const mappedEquivalent = candidatesToBeImportedKeys.find((item) => item.field_to_map === field);
        alias = mappedEquivalent ? mappedEquivalent.field_Key : null;
      }

      if (alias && candidate[alias]) {
        hasRequiredFields = true;
      }
    });

    return hasRequiredFields;
  };

  const getCandidateAliasForKey = (options) => {
    const { candidate, key } = options;
    const aliasFields = getFieldAliases(key);
    let candidateAlias = null;
    try {
      Object.keys(candidate).forEach((candidateKey) => {
        if (aliasFields.includes(candidateKey.toUpperCase())) {
          candidateAlias = candidateKey;
        }
      });
    } catch (e) {
      console.log('error: ', e);
      console.log('options: ', options);
    }

    return candidateAlias;
  };

  const repackCaller = async (candidatesToBeImported) => {
    setTimeout(() => {
      repackCandidatesForImport(candidatesToBeImported);
    }, 800);
  };

  const processOrphanConflictDetails = (items) => {
    let AAMCIDItems = [];
    let SFMatchIDItems = [];
    let EmailItems = [];

    const fieldsToImport = candidatesToBeImportedKeys.filter((field) => {
      return field.field_status === 'Mapped' && field.field_to_map !== 'doNotImport';
    });

    if (!items || items.length == 0) {
      return { AAMCIDItems, SFMatchIDItems, EmailItems };
    }
    let AAMCIDKey = null;

    let SFMatchIDKey = null;

    let EmailKey = null;

    fieldsToImport.forEach((field) => {
      const { field_Key, field_to_map } = field;
      if (field_to_map === 'AAMCID') {
        AAMCIDKey = field_Key;
      }

      if (field_to_map === 'SFMatchID') {
        SFMatchIDKey = field_Key;
      }

      if (field_to_map === 'Email') {
        EmailKey = field_Key;
      }
    });

    items.forEach((item) => {
      if (item[AAMCIDKey] && !AAMCIDItems.includes(item[AAMCIDKey])) {
        AAMCIDItems.push(item[AAMCIDKey]);
      } else if (item[SFMatchIDKey] && !SFMatchIDItems.includes(item[SFMatchIDKey])) {
        SFMatchIDItems.push(item[SFMatchIDKey]);
      } else if (item[EmailKey] && !EmailItems.includes(item[EmailKey])) {
        EmailItems.push(item[EmailKey]);
      }
    });

    return { AAMCIDItems, SFMatchIDItems, EmailItems };
  };

  const repackCandidatesForImport = async (rawCandidates) => {
    const newCandidates = [];
    const existingCandidatesReferenceCopy = clone(existingCandidatesReference);

    const fieldsToImport = candidatesToBeImportedKeys.filter((field) => {
      return field.field_status === 'Mapped' && field.field_to_map !== 'doNotImport';
    });
    let newCandidatesForUpdate = [];
    let newCandidatesForAddition = [];
    let newCandidatesForInvalid = [];
    let duplicateCandidates = []; // candidates with duplicate AAMCID or SFMatchID or Email
    let debuggerDuplicateCandidates = []; // for easier debugging

    localStorage.setItem(
      `fieldAssignment_${dContext.department.pk_Department}_${dContext.season.pk_Season}`,
      JSON.stringify(candidatesToBeImportedKeys),
    );

    const promises = [];

    const chunkSize = 25;
    let index = 0;

    while (index < rawCandidates.length) {
      const chunkToRepack = rawCandidates.slice(index, index + chunkSize);
      const chunkIndex = index;
      promises.push(() => {
        return new Promise((resolve) => {
          chunkToRepack.forEach((candidate) => {
            const newCandidate = { fk_Season: dContext.season.pk_Season, uploadStatus: 'queued' };

            let AAMCIDKey = null;

            let SFMatchIDKey = null;

            let EmailKey = null;

            fieldsToImport.forEach((field) => {
              const { field_Key, field_to_map } = field;
              newCandidate[field_to_map] = candidate[field_Key];
              if (field_to_map === 'AAMCID') {
                AAMCIDKey = field_Key;
              }

              if (field_to_map === 'SFMatchID') {
                SFMatchIDKey = field_Key;
              }

              if (field_to_map === 'Email') {
                EmailKey = field_Key;
              }
            });

            let fieldsToDisplay = {
              SFMatchID: '',
              AAMCID: '',
              Email: '',
            };

            Object.keys(fieldsToDisplay).forEach((key) => {
              const candidateAlias = getCandidateAliasForKey({
                candidate,
                key,
              });
              if (candidate[candidateAlias] && !newCandidate[key]) {
                newCandidate[key] = candidate[candidateAlias];
              }
            });

            newCandidates.push(newCandidate);
            const ownIndex = rawCandidates.findIndex((item) => {
              return (
                item[AAMCIDKey] == newCandidate.AAMCID &&
                item[SFMatchIDKey] == newCandidate.SFMatchID &&
                item[EmailKey] == newCandidate.Email
              );
            });

            let sameKey = null;
            // Run through the csv candidates and check for duplicates foor current candidate
            const newDuplicateCandidates = rawCandidates.filter((item, i) => {
              if (newCandidate.AAMCID === '6' && !newCandidate.FirstName && item['First Name'] === 'Leslie') {
                console.log('newCandidate: ', newCandidate);
                console.log('item: ', item);
                console.log('AAMCIDKey: ', AAMCIDKey);
              }

              if (AAMCIDKey && newCandidate.AAMCID && newCandidate.AAMCID === item[AAMCIDKey]) {
                sameKey = 'AAMCID';
              } else if (SFMatchIDKey && newCandidate.SFMatchID && newCandidate.SFMatchID === item[SFMatchIDKey]) {
                sameKey = 'SFMatchID';
              } else if (EmailKey && newCandidate.Email && newCandidate.Email === item[EmailKey]) {
                sameKey = 'Email';
              }

              return (
                ownIndex != i &&
                ((AAMCIDKey && newCandidate.AAMCID && newCandidate.AAMCID === item.AAMCID) ||
                  (SFMatchIDKey && newCandidate.SFMatchID && newCandidate.SFMatchID === item.SFMatchID) ||
                  (EmailKey && newCandidate.Email && newCandidate.Email === item.Email))
              );
            });

            if (newCandidate.AAMCID === '6' && !newCandidate.FirstName) {
              console.log('sameKey: ', sameKey);
            }
            // Run through the existingCandidatesReferenceCopy and check for duplicates foor current candidate, newCandidate. newCandidate has remapped
            const existingCandidateIndex = rawCandidates.findIndex((item) => {
              return (
                (AAMCIDKey && candidate[AAMCIDKey] && candidate[AAMCIDKey] === item[AAMCIDKey]) ||
                (SFMatchIDKey && candidate[SFMatchIDKey] && candidate[SFMatchIDKey] === item[SFMatchIDKey]) ||
                (EmailKey && candidate[EmailKey] && candidate[EmailKey] === item[EmailKey])
              );
            });

            const existingCandidate = clone(existingCandidatesReferenceCopy[existingCandidateIndex]);

            if (existingCandidate && newDuplicateCandidates.length == 1) {
              newCandidatesForUpdate.push(newCandidate);
              existingCandidatesReferenceCopy.splice(existingCandidateIndex, 1);
            } else if (!isCandidateHasRequiredFields(candidate)) {
              newCandidatesForInvalid.push(newCandidate);
            } else if (newDuplicateCandidates.length > 1) {
              //  Check if a dulicateTeam with the candidate's sameKey value already exists.
              const existingDuplicateTeamIndex = duplicateCandidates.findIndex((duplicateTeam) => {
                let hasMatching = false;
                duplicateTeam.forEach((duplicate) => {
                  if (duplicate[sameKey] === newCandidate[sameKey]) {
                    hasMatching = true;
                  }
                  return;
                  let AAMCIDKey = 'AAMCID';

                  let SFMatchIDKey = 'SFMatchID';

                  let EmailKey = 'Email';

                  if (AAMCIDKey && newCandidate[AAMCIDKey] && newCandidate[AAMCIDKey] === duplicate[AAMCIDKey]) {
                    hasMatching = true;
                  } else if (
                    SFMatchIDKey &&
                    newCandidate[SFMatchIDKey] &&
                    newCandidate[SFMatchIDKey] === duplicate[SFMatchIDKey]
                  ) {
                    hasMatching = true;
                  } else if (EmailKey && newCandidate[EmailKey] && newCandidate[EmailKey] === duplicate[EmailKey]) {
                    hasMatching = true;
                  }
                });
                return hasMatching;
              });

              let existingDuplicateTeam = [];
              let duplicateData = {};

              if (existingDuplicateTeamIndex >= 0) {
                existingDuplicateTeam = clone(duplicateCandidates[existingDuplicateTeamIndex]);

                // newDuplicateCandidates.forEach((duplicate) => {
                let existsAlready = existingDuplicateTeam.find((item) => {
                  return (
                    item[AAMCIDKey] === newCandidate[AAMCIDKey] &&
                    item[SFMatchIDKey] === newCandidate[SFMatchIDKey] &&
                    item[EmailKey] === newCandidate[EmailKey]
                  );
                });

                if (!existsAlready) {
                  existingDuplicateTeam.push(newCandidate);
                }
                // });

                duplicateCandidates[existingDuplicateTeamIndex] = existingDuplicateTeam;
                // debuggerDuplicateCandidates = duplicateCandidates.map((duplicateTeam) => {
                //   return duplicateTeam.map((duplicate) => {
                //     const { FirstName, LastName } = duplicate;
                //     const name = `${duplicate.FirstName || ''} ${duplicate.LastName || ''}`;

                //     return {
                //       name,
                //       AAMCID: duplicate[AAMCIDKey],
                //       SFMatchID: duplicate[SFMatchIDKey],
                //       Email: duplicate[EmailKey],
                //     };
                //   });
                // });

                duplicateData = processOrphanConflictDetails(existingDuplicateTeam);
              } else {
                duplicateCandidates.push([...newDuplicateCandidates]);

                // let AAMCIDKey = 'AAMCID';

                // let SFMatchIDKey = 'SFMatchID';

                // let EmailKey = 'Email';

                // debuggerDuplicateCandidates = duplicateCandidates.map((duplicateTeam) => {
                //   return duplicateTeam.map((duplicate) => {
                //     const { FirstName, LastName } = duplicate;
                //     const name = `${duplicate.FirstName || ''} ${duplicate.LastName || ''}`;

                //     return {
                //       name,
                //       AAMCID: duplicate[AAMCIDKey],
                //       SFMatchID: duplicate[SFMatchIDKey],
                //       Email: duplicate[EmailKey],
                //     };
                //   });
                // });
                existingDuplicateTeam = [...newDuplicateCandidates];
                duplicateData = processOrphanConflictDetails([newCandidate, ...newDuplicateCandidates]);
              }

              // console.log('duplicateCandidateInvalidItem: ', duplicateCandidateInvalidItem);
              // console.log('debuggerDuplicateCandidates: ', debuggerDuplicateCandidates);

              // TODO: Must checek newCandidatesForInvalid for existing duplicateCandidateItems!!! This loop is PER CANDIDATE, so we're pushing a NEW duplicateCandidateInvalidItem for each candidate instead of just adding to the existing duplicates team. Just check for isOrphanedConflict and check AAMCIDs, SFMatchIDs, and Emails for matches, then merge the conflictingCandidates array and the AAMCIDItems, SFMatchIDItems, and EmailItems arrays.
              // newCandidatesForInvalid.push(duplicateCandidateInvalidItem);

              // console.log('newCandidatesForInvalid: ', newCandidatesForInvalid);

              // const existingCandidateInvalidItemIndex = newCandidatesForInvalid.findIndex((item) => {
              //   let hasSameRequiredFieldValues = false;

              //   if (item.isOrphanedConflict) {
              //     if (duplicateCandidateInvalidItem.AAMCIDs && duplicateCandidateInvalidItem.AAMCIDs.length > 0) {
              //       duplicateCandidateInvalidItem.AAMCIDs.forEach((AAMCID) => {
              //         if (item.AAMCIDs.includes(AAMCID)) {
              //           hasSameRequiredFieldValues = true;
              //         }
              //       });
              //     } else if (
              //       duplicateCandidateInvalidItem.SFMatchIDs &&
              //       duplicateCandidateInvalidItem.SFMatchIDs.length > 0
              //     ) {
              //       duplicateCandidateInvalidItem.SFMatchIDs.forEach((SFMatchID) => {
              //         if (SFMatchID && item.SFMatchIDs.includes(SFMatchID)) {
              //           hasSameRequiredFieldValues = true;
              //         }
              //       });
              //     } else if (duplicateCandidateInvalidItem.Emails && duplicateCandidateInvalidItem.Emails.length > 0) {
              //       duplicateCandidateInvalidItem.Emails.forEach((Email) => {
              //         if (Email && item.Emails.includes(Email)) {
              //           hasSameRequiredFieldValues = true;
              //         }
              //       });
              //     }
              //   }

              //   return hasSameRequiredFieldValues;
              // });

              // console.log('existingCandidateInvalidItemIndex: ', existingCandidateInvalidItemIndex);

              // if (existingCandidateInvalidItemIndex >= 0) {
              //   console.log('splicing');

              //   const existingCandidateInvalidItem = newCandidatesForInvalid[existingCandidateInvalidItemIndex];
              //   console.log(
              //     `${existingCandidateInvalidItem.conflictingCandidates
              //       .map((item) => {
              //         return `${item.FirstName || ''} ${item.LastName || ''}`;
              //       })
              //       .join(', ')} vs ${duplicateCandidateInvalidItem.conflictingCandidates
              //       .map((item) => {
              //         return `${item.FirstName || ''} ${item.LastName || ''}`;
              //       })
              //       .join(', ')}`,
              //   );

              //   let keyToCompare = 'Email';

              //   if (duplicateCandidateInvalidItem.AAMCIDs.length > 0 && duplicateCandidateInvalidItem.AAMCIDs[0]) {
              //     keyToCompare = 'AAMCID';
              //   } else if (
              //     duplicateCandidateInvalidItem.SFMatchIDs.length > 0 &&
              //     duplicateCandidateInvalidItem.SFMatchIDs[0]
              //   ) {
              //     keyToCompare = 'SFMatchID';
              //   }
              //   console.log('keyToCompare: ', keyToCompare);

              //   existingCandidateInvalidItem.conflictingCandidates = mergeUniqueObjects(
              //     existingCandidateInvalidItem.conflictingCandidates,
              //     duplicateCandidateInvalidItem.conflictingCandidates,
              //     keyToCompare,
              //   );
              //   console.log(
              //     'existingCandidateInvalidItem: ',
              //     existingCandidateInvalidItem.conflictingCandidates
              //       .map((item) => {
              //         return `${item.FirstName || ''} ${item.LastName || ''}`;
              //       })
              //       .join(', '),
              //   );

              //   newCandidatesForInvalid.splice(existingCandidateInvalidItemIndex, 1, duplicateCandidateInvalidItem);
              // } else {
              //   console.log('pushing');

              //   newCandidatesForInvalid.push(duplicateCandidateInvalidItem);
              // }
            } else {
              newCandidatesForAddition.push(newCandidate);
            }
          });

          if (
            chunkIndex == 0 ||
            (chunkIndex + chunkToRepack.length) % 200 == 0 ||
            chunkIndex + chunkToRepack.length >= rawCandidates.length
          ) {
            setRepackedCandidateCount((prevState) => {
              return chunkIndex + chunkToRepack.length;
            });
          }

          if (chunkIndex + chunkToRepack.length >= rawCandidates.length) {
            setIsRepackingCandidatesForImport(false);
            setCandidatesForInvalid((prevValue) => {
              const newValue = clone(prevValue);
              duplicateCandidates.forEach((duplicateTeam) => {
                const newDuplicateTeam = [];
                duplicateTeam.forEach((duplicate) => {
                  const newDuplicateForDisplay = {};
                  fieldsToImport.forEach((field) => {
                    const { field_Key, field_to_map } = field;
                    newDuplicateForDisplay[field_to_map] = duplicate[field_Key];
                  });
                  newDuplicateTeam.push(newDuplicateForDisplay);
                });
                const duplicateData = processOrphanConflictDetails(newDuplicateTeam);

                const duplicateCandidateInvalidItem = {
                  isOrphanedConflict: true,
                  AAMCIDs: duplicateData.AAMCIDItems,
                  SFMatchIDs: duplicateData.SFMatchIDItems,
                  Emails: duplicateData.EmailItems,
                  conflictingCandidates: newDuplicateTeam,
                };

                newValue.unshift(duplicateCandidateInvalidItem);
              });
              console.log('newValue: ', newValue);

              return newValue;
            });
          }
          resolve();
        });
      });

      index += chunkToRepack.length;
    }

    promises
      .reduce(async (p, nextPromise) => {
        return p.then(() => {
          // return new Promise((resolve) => {
          setTimeout(() => {
            nextPromise();
            // resolve();
          }, 800);
          // });
        });
      }, Promise.resolve())
      .then(() => {
        // WARNING: These fire IMMEDIATELY after the promise.reduce is called, because setTimeout within makes the damn thing resolve IMMEDIATELY. This however, resulted to a good UI behavior.
        const newUploadStatus = updateImportStatusForSelected({
          candidates: newCandidates,
          newUploadStatus: uploadStatusRef.current,
          uploadStatus: 'queued',
        });

        uploadStatusRef.current = newUploadStatus;
        updateImportStatuses(newUploadStatus);
        setUploadStatus((prevStatus) => ({ ...prevStatus, ...uploadStatusRef.current }));

        setCandidatesForAddition(newCandidatesForAddition);
        setCandidatesForUpdate(newCandidatesForUpdate);
        setCandidatesForInvalid(newCandidatesForInvalid);

        setRepackedCandidates(newCandidates);
        // setIsRepackingCandidatesForImport(false);
      });
    // setCandidatesForAddition(newCandidatesForAddition);
    // setCandidatesForUpdate(newCandidatesForUpdate);
    // setCandidatesForInvalid(newCandidatesForInvalid);

    // setRepackedCandidates(newCandidates);
    // setIsRepackingCandidatesForImport(false);
  };

  const createCandidates = (candidates) => {
    const { department, season } = dContext;
    const { pk_Department } = department;
    const { pk_Season } = season;
    const newResults = {};

    const cleanCandidates = cleanupBOM(candidates);

    const debuggerCandidate = cleanCandidates[0];
    setIsPosting(true);

    getTokenSilently().then(async (token) => {
      const candidateArray = await mapDepartmentToArray(cleanCandidates, pk_Season);
      let index = 0;
      let chunkSize = 10;

      let hasError = false;
      let hasExistingCandidatesWithRemovedStatus = false;
      let promises = [];
      while (index < candidateArray.length) {
        const chunkToUpload = candidateArray.slice(index, index + chunkSize);
        // const newUploadStatus = updateImportStatusForSelected({
        //   candidates: chunkToUpload,
        //   newUploadStatus: uploadStatusRef.current,
        //   uploadStatus: 'queued',
        // });
        // uploadStatusRef.current = newUploadStatus;
        // updateImportStatuses(newUploadStatus);
        // setUploadStatus((prevStatus) => ({ ...prevStatus, ...uploadStatusRef.current }));
        promises.push(
          () =>
            new Promise((resolve, reject) => {
              if (!hasReset.current) {
              } else {
                resetCandidateImports();
                getCandidates();
                return;
              }
              const myChunk = chunkToUpload;
              const newUploadStatus = updateImportStatusForSelected({
                candidates: myChunk,
                newUploadStatus: uploadStatusRef.current,
                uploadStatus: 'uploading',
              });
              uploadStatusRef.current = newUploadStatus;
              updateImportStatuses(newUploadStatus);
              setUploadStatus((prevStatus) => ({ ...prevStatus, ...uploadStatusRef.current }));
              postDataAgnostic(
                'candidate',
                null,
                { candidates: chunkToUpload, pk_Season, pk_Department },
                formatBearerToken(token),
              )
                .then((result) => {
                  if (result && result.data) {
                    Object.keys(result.data).forEach((key) => {
                      if (resultsRef.current[key]) {
                        resultsRef.current[key] = [...resultsRef.current[key], ...result.data[key]];
                      } else {
                        resultsRef.current[key] = result.data[key];
                      }
                    });
                  }
                  if (
                    result &&
                    result.data &&
                    result.data.existingCandidatesWithRemovedStatus &&
                    result.data.existingCandidatesWithRemovedStatus.length > 0
                  ) {
                    hasExistingCandidatesWithRemovedStatus = true;
                  }

                  const { candidatesInserted, existingCandidates, existingCandidatesWithRemovedStatus } = result.data;
                  let debugKey = '';
                  const candidatesToUpdate = [
                    ...candidatesInserted,
                    ...existingCandidates,
                    ...existingCandidatesWithRemovedStatus,
                  ];

                  const newUploadStatus = updateImportStatusForSelected({
                    candidates: candidatesToUpdate,
                    newUploadStatus: uploadStatusRef.current,
                    uploadStatus: 'uploaded',
                  });

                  uploadStatusRef.current = newUploadStatus;
                  updateImportStatuses(newUploadStatus);
                  setUploadStatus((prevStatus) => ({ ...prevStatus, ...uploadStatusRef.current }));
                  setResults((prevResults) => ({ ...prevResults, ...resultsRef.current }));
                  // setTimeout(() => {
                  resolve(result);
                  // }, [5000]);
                })
                .catch((err) => {
                  console.log('ERROR! err: ', err);
                  hasError = true;
                  const newUploadStatus = updateImportStatusForSelected({
                    candidates: myChunk,
                    newUploadStatus: uploadStatusRef.current,
                    uploadStatus: 'failed',
                  });

                  uploadStatusRef.current = newUploadStatus;
                  updateImportStatuses(newUploadStatus);
                  setUploadStatus((prevStatus) => ({ ...prevStatus, ...uploadStatusRef.current }));
                  // setTimeout(() => {
                  resolve();
                  // }, [5000]);
                });
            }),
        );

        index += chunkSize;
      }
      const newUploadStatus = updateImportStatusForSelected({
        candidates: candidateArray,
        newUploadStatus: uploadStatusRef.current,
        uploadStatus: 'queued',
      });
      uploadStatusRef.current = newUploadStatus;
      updateImportStatuses(newUploadStatus);
      setUploadStatus((prevStatus) => ({ ...prevStatus, ...uploadStatusRef.current }));

      setUploadStatus(uploadStatusRef.current);
      promises
        .reduce(async (p, nextPromise) => {
          return p.then(() => nextPromise());
        }, Promise.resolve())
        .then(() => {
          if (hasExistingCandidatesWithRemovedStatus) {
            Swal.fire('Warning', 'Check candidate list filtered by deleted', 'warning');
          }

          setChanges({ ...changes, import_data: false });
          setDisplayResults(true);
          setIsPosting(false);
          getCandidates();
          // }
        });

      // postDataAgnostic(
      //   'candidate',
      //   null,
      //   { candidates: candidateArray, pk_Season, pk_Department },
      //   formatBearerToken(token),
      // )
      //   .then((result) => {
      //     if (
      //       result &&
      //       result.data &&
      //       result.data.existingCandidatesWithRemovedStatus &&
      //       result.data.existingCandidatesWithRemovedStatus.length > 0
      //     ) {
      //       Swal.fire('Warning', 'Check candidate list filtered by deleted', 'warning');
      //     }
      //     getCandidates();
      //     setResults(result.data);
      //     setDisplayResults(true);
      //   })
      //   .catch((err) => {
      //     setError(true);
      //     setErrorMessage('Something went wrong...');
      //   })
      //   .finally(() => {
      //     setIsPosting(false);
      //   });
    });
  };

  const findHeaderIndex = (worksheetAsRows) => {
    const criteria = [
      'APPLICANTID',
      'APPLICANT ID',
      'SFMATCHID',
      'SF MATCHID',
      'EMAIL',
      'E-MAIL',
      'EMAIL ADDRESS',
      'E-MAIL ADDRESS',
      'EMAIL_ADDRESS',
    ];
    const index = worksheetAsRows.findIndex((row) => {
      let isMatch = false;
      for (const key in row) {
        if (!isMatch) {
          // if any columns within a row have these values then it's the headers
          isMatch = criteria.includes(row[key].toUpperCase());
        }
      }
      if (isMatch) {
        return true;
      }
    });

    return index;
  };

  const formatHeaders = (worksheetAsRows) => {
    if (!worksheetAsRows[0].__EMPTY) return worksheetAsRows;

    // find the index with the headers
    const headerIndex = findHeaderIndex(worksheetAsRows);

    if (headerIndex === -1) {
      return false;
    }

    const headers = worksheetAsRows[headerIndex];

    // filter out rows that aren't data
    worksheetAsRows = worksheetAsRows.filter((item, i) => i > headerIndex);

    worksheetAsRows = worksheetAsRows.map((row) => {
      const objectWithCorectKeys = {};
      Object.keys(row).forEach((key) => {
        const cellValue = row[key];
        const headerValue = headers[key];

        objectWithCorectKeys[headerValue] = cellValue;
      });

      return objectWithCorectKeys;
    });

    return worksheetAsRows;
  };

  const processCandidatePreviewKeys = (candidateData) => {
    if (candidateData.length === 0 || !candidateData) {
      setCandidatesToBeImportedKeys([]);
      return;
    }
    hasReset.current = false;

    const getSampleData = (key) => {
      const sampleToReturn = [];
      let count = 0;
      while (sampleToReturn.length < 3 && count < candidateData.length) {
        if (candidateData[count][key]) {
          sampleToReturn.push(pdf_concatenate({ string: candidateData[count][key].toString(), maxLimit: 50 }));
        }
        count++;
      }

      return sampleToReturn;
    };
    const keys = Object.keys(candidateData[0]);
    const newKeysData = keys.map((key) => {
      return {
        field_Key: key,
        field_preview: getSampleData(key),
        field_status: 'Not Mapped',
        field_to_map: '',
        field_not_import: false,
      };
    });
    const newCandidatesToBeImportedKeys = autoAssignFields({ fields: newKeysData });
    setCandidatesToBeImportedKeys(newCandidatesToBeImportedKeys);
    setSavedFieldAssignment(newCandidatesToBeImportedKeys);
  };

  const handleExcelDocument = (file) => {
    let atLeastOneHeaderFormattedCorreclty = false;
    const reader = new FileReader();
    const encoderReader = new FileReader();
    // let encoding = 'UTF-8';

    // encoderReader.onload = (evt) => {
    //   let data = new Uint8Array(evt.target.result);
    //   let buffer = Buffer.from(data.buffer);
    //   encoding = jschardet.detect(buffer);
    // };

    encoderReader.readAsArrayBuffer(file);

    reader.onload = function(e) {
      const data = e.target.result;
      const workbook = XLSX.read(data, {
        type: 'binary',
        // codepage: 65001, // UTF-08, need to npm install codepage
      });

      workbook.SheetNames.forEach((sheetName) => {
        let worksheetAsRows = XLSX.utils.sheet_to_row_object_array(workbook.Sheets[sheetName], { raw: false });
        worksheetAsRows = worksheetAsRows.map((row) => {
          for (let key in row) {
            let value = row[key];
            if (moment(value, 'MM/DD/YYYY', true).isValid()) {
              row[key] = moment(value, 'MM/DD/YYYY').format('MM/DD/YYYY');
            }
          }
          return row;
        });

        worksheetAsRows = formatHeaders(worksheetAsRows);
        if (worksheetAsRows) {
          setChanges({ ...changes, import_data: true });
          processCandidatePreviewKeys(worksheetAsRows);
          setCandidatesToBeImported(worksheetAsRows);
        } else {
          Swal.fire({
            title: 'Unable to read headers from file.',
            text: 'Data file is not formatted properly, please reformat data or contact support.',
            icon: 'error',
          });
        }
      });

      // DELETE this to make it work for sFMatchID
      // if not a single worksheet correctly had their headers extracted inform the user
      // if (!atLeastOneHeaderFormattedCorreclty) {
      //   setError(true);
      //   setErrorMessage('Unable to locate headers');
      // }
    };

    reader.onerror = function(ex) {};

    reader.readAsBinaryString(file);
  };

  const autoAssignFields = (options) => {
    const { fields, ignoreSavedFieldAssignment, resetNoMatchFields = true } = options;
    const savedFieldAssignment = JSON.parse(
      localStorage.getItem(`fieldAssignment_${dContext.department.pk_Department}_${dContext.season.pk_Season}`) || '{}',
    );

    if (!ignoreSavedFieldAssignment && savedFieldAssignment.length > 0) {
      const newCandidatesToBeImportedKeys = clone(fields);
      const dataTypes = dataTypesRef.current;
      newCandidatesToBeImportedKeys.forEach((field) => {
        const { field_Key } = field;
        const fieldToMap = savedFieldAssignment.find((item) => item.field_Key === field_Key);
        const fieldToMapIsActive =
          fieldToMap &&
          fieldToMap.field_to_map &&
          dataTypes &&
          dataTypes.enabled &&
          dataTypes.enabled.find((item) => item.Name === fieldToMap.field_to_map);

        if (fieldToMap && fieldToMapIsActive) {
          field.field_to_map = fieldToMap.field_to_map;
          field.field_status = fieldToMap.field_status;
          field.field_not_import = fieldToMap.field_not_import;
        }
      });
      return newCandidatesToBeImportedKeys;
    } else {
      const newCandidatesToBeImportedKeys = clone(fields);
      if (fields.length === 0) return fields;
      const dataTypes = dataTypesRef.current;
      const customFields = dataTypes.enabled.filter((item) => item.Name.indexOf('CustomField') >= 0);

      newCandidatesToBeImportedKeys.forEach((field) => {
        const { field_Key } = field;
        let fieldToMap = AAMCIDCSVData[field_Key.toUpperCase()];

        if (!fieldToMap) {
          const matchingCustomField = customFields.find((item) => item.Alias === field_Key);

          if (matchingCustomField) {
            fieldToMap = AAMCIDCSVData[matchingCustomField.Name.toUpperCase()];
          }
        }
        const isFieldToMapVisible =
          fieldToMap &&
          fieldToMap.matchingObject &&
          dataTypes &&
          dataTypes.enabled &&
          dataTypes.enabled.find((item) => item.Name === fieldToMap.matchingObject.databaseColumn);

        if (isFieldToMapVisible) {
          field.field_to_map = fieldToMap.matchingObject.databaseColumn;
          field.field_status = 'Mapped';
        } else if (resetNoMatchFields || field.field_status == 'Mapped') {
          field.field_to_map = '';
          field.field_status = 'Not Mapped';
        }
      });

      return newCandidatesToBeImportedKeys;
    }
  };

  const getDepartmentDataTypes = () => {
    setIsFetchingDataTypes(true);
    getTokenSilently()
      .then((token) => {
        fetchDataAgnostic(
          'department/dataTypes',
          { pk_Department: dContext.department.pk_Department },
          formatBearerToken(token),
        )
          .then((res) => {
            setIsFetchingDataTypes(false);
            dataTypesRef.current = separateDataTypesByAvailability(res.data);
            if (dataFieldsHaveChange) {
              setDataFieldsHaveChange(false);
              processCandidatePreviewKeys(candidatesToBeImported);
            }
          })
          .catch((err) => {
            setIsFetchingDataTypes(false);
          });
      })
      .catch((err) => {
        console.log('getDepartmentDataTypes err: ', err);

        setIsFetchingDataTypes(false);
      });
  };

  const onDrop = useCallback(
    (acceptedFiles) => {
      console.log('onDrop acceptedFiles: ', acceptedFiles);
      if (isFetchingDataTypes) {
        return;
      }
      const isXlsx = acceptedFiles[0].name.includes('.xlsx');
      const isCsv = acceptedFiles[0].name.includes('.csv');

      // if neither of the files is excel or csv we can't process it
      if (!isXlsx && !isCsv) {
        Swal.fire({
          title: 'Invalid File Type',
          icon: 'error',
          type: 'error',
          text: 'Files must either have an extension of .xlsx or .csv',
        });
        // setError(true);
        // setErrorMessage('Files must either have an extension of .xlsx or .csv');
        return;
      }
      // setIsPosting(true);
      if (isXlsx) {
        handleExcelDocument(acceptedFiles[0]);
        return;
      }

      getCandidates();
      const reader = new FileReader();
      let encoding = 'UTF-8';
      const encoderReader = new FileReader();
      encoderReader.onloadend = function(evt) {
        if (evt.target.readyState == FileReader.DONE) {
          const buffer = new Buffer(evt.target.result);
          const encoding = chardet.detect(buffer);

          if (encoding !== 'UTF-8' && encoding !== 'ISO-8859-1') {
            Swal.fire({
              title: 'Unexpected Encoding',
              icon: 'error',
              type: 'error',
              text: 'Files should preferably be in UTF-8 format.',
            });
            return;
          }
          try {
            reader.onload = (evt) => {
              // Parse CSV file
              csv.parse(reader.result, { columns: true }, async (err, data) => {
                if (data) {
                  setChanges({ ...changes, import_data: true });
                  processCandidatePreviewKeys(data);
                  setCandidatesToBeImported(data);
                } else {
                  setIsPosting(false);

                  Swal.fire({
                    title: 'Error',
                    text: 'Data file is not formatted properly, please reformat data or contact support.',
                    icon: 'error',
                    showDenyButton: true,
                    denyButtonColor: '#0a91ff',
                    confirmButtonText: `Ok`,
                    denyButtonText: `More info`,
                  }).then((result) => {
                    if (result.isDenied) {
                      window.open('https://www.rezrate.com/knowledge-base/eras-csv-formatting-error/', '_blank');
                    }
                    setImportErasOpen(false);
                  });
                }
              });
            };
          } catch (err) {}

          // read file contents
          // acceptedFiles.forEach((file) => reader.readAsBinaryString(file));
          acceptedFiles.forEach((file) => {
            reader.readAsText(file, encoding || 'UTF-8');
          });
          // Use detectedEncoding
        }
      };
      encoderReader.readAsArrayBuffer(acceptedFiles[0]);
      // languageEncoding(acceptedFiles[0]).then((fileInfo) => {
      //   if (fileInfo) {
      //     encoding = fileInfo.encoding;
      //     console.log('fileInfo: ', fileInfo);
      //     try {
      //       reader.onload = (evt) => {
      //         // Parse CSV file
      //         csv.parse(reader.result, { columns: true }, async (err, data) => {
      //           if (data) {
      //             setChanges({ ...changes, import_data: true });
      //             processCandidatePreviewKeys(data);
      //             setCandidatesToBeImported(data);
      //           } else {
      //             setIsPosting(false);

      //             Swal.fire({
      //               title: 'Error',
      //               text: 'Data file is not formatted properly, please reformat data or contact support.',
      //               icon: 'error',
      //               showDenyButton: true,
      //               denyButtonColor: '#0a91ff',
      //               confirmButtonText: `Ok`,
      //               denyButtonText: `More info`,
      //             }).then((result) => {
      //               if (result.isDenied) {
      //                 window.open('https://www.rezrate.com/knowledge-base/eras-csv-formatting-error/', '_blank');
      //               }
      //               setImportErasOpen(false);
      //             });
      //           }
      //         });
      //       };
      //     } catch (err) {}

      //     // read file contents
      //     // acceptedFiles.forEach((file) => reader.readAsBinaryString(file));
      //     acceptedFiles.forEach((file) => {
      //       reader.readAsText(file, encoding || 'UTF-8');
      //     });
      //   } else {
      //     console.log('no file info on drop');
      //   }
      // });
    },
    [dContext],
  );

  const renderImportStatus = () => {
    const { existingCandidates, existingCandidatesWithRemovedStatus, candidatesInserted } = results;
    const { totalCandidates, totalCandidatesUploaded } = computeLoadingProgress();
    return (
      <>
        {isPosting ? (
          <div style={{ display: 'flex', flexDirection: 'row', width: '80%', justifyContent: 'left', marginBottom: 5 }}>
            <div
              style={{
                borderBottom: '3px solid #04fb04',
                width: `${(totalCandidatesUploaded / totalCandidates) * 100}%`,
              }}
            ></div>
          </div>
        ) : null}
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            width: '80%',
            height: 'calc(100vh - 550px)',
            marginBottom: 20,
            paddingTop: 10,
            overflow: 'auto',
          }}
        >
          <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
            {candidatesForInvalid &&
              candidatesForInvalid.length > 0 &&
              candidatesForInvalid.map((item, itemIndex) => (
                <PostImportPreviewItem
                  itemIndex={itemIndex}
                  item={item}
                  type={item.isOrphanedConflict ? 'orphaned_conflict' : 'orphaned'}
                  uploadStatus={uploadStatusRef.current}
                />
              ))}
            {existingCandidatesWithRemovedStatus &&
              existingCandidatesWithRemovedStatus.length > 0 &&
              existingCandidatesWithRemovedStatus.map((item, i) => {
                const { AAMCID, SFMatchID, Email, FirstName, LastName } = item;
                const key = `${AAMCID || SFMatchID || Email || `${FirstName}_${LastName}`}`;
                const rowKey = `key_${key}_${i}_${
                  uploadStatusRef.current[key] ? uploadStatusRef.current[key].uploadStatus : 'queued'
                }`;
                return (
                  <PostImportPreviewItem
                    key={rowKey}
                    item={item}
                    type={'deleted'}
                    uploadStatus={uploadStatusRef.current}
                    createCandidates={createCandidates}
                  />
                );
              })}
            {candidatesForAddition &&
              candidatesForAddition.length > 0 &&
              candidatesForAddition.map((item, i) => {
                const { AAMCID, SFMatchID, Email, FirstName, LastName } = item;
                const key = `${AAMCID || SFMatchID || Email || `${FirstName}_${LastName}`}`;
                const rowKey = `key_${key}_${i}_${
                  uploadStatusRef.current[key] ? uploadStatusRef.current[key].uploadStatus : 'queued'
                }`;
                return (
                  <PostImportPreviewItem
                    key={rowKey}
                    item={item}
                    type={'inserted'}
                    uploadStatus={uploadStatusRef.current}
                    createCandidates={createCandidates}
                  />
                );
              })}
            {candidatesForUpdate &&
              candidatesForUpdate.length > 0 &&
              candidatesForUpdate.map((item, i) => {
                const { AAMCID, SFMatchID, Email, FirstName, LastName } = item;
                const key = `${AAMCID || SFMatchID || Email || `${FirstName}_${LastName}`}`;
                const rowKey = `key_${key}_${i}_${
                  uploadStatusRef.current[key] ? uploadStatusRef.current[key].uploadStatus : 'queued'
                }`;

                return (
                  <PostImportPreviewItem
                    key={rowKey}
                    item={item}
                    type={'updated'}
                    wahdir={rowKey}
                    uploadStatus={uploadStatusRef.current}
                    createCandidates={createCandidates}
                  />
                );
              })}
          </div>
        </div>
      </>
    );
  };

  const renderManageFields = () => {
    return (
      <Modal
        style={{ minWidth: '90vw' }}
        centered
        isOpen={showManageFields}
        toggle={() => {
          if (showManageFields && dataFieldsHaveChange) {
            getDepartmentDataTypes();
            autoAssignFields({
              fields: candidatesToBeImportedKeys,
              ignoreSavedFieldAssignment: true,
              resetNoMatchFields: false,
            });
          }

          setShowManageFields(!showManageFields);
        }}
      >
        <ModalHeader
          toggle={() => {
            if (showManageFields && dataFieldsHaveChange) {
              getDepartmentDataTypes();
            }
            setShowManageFields(!showManageFields);
          }}
        >
          Manage Fields
        </ModalHeader>
        <ModalBody>
          <ManageFields
            onSaved={(err) => {
              if (err) {
                alert.error('Error saving fields');
              } else {
                alert.success('Fields saved');
                setDataFieldsHaveChange(true);
              }
            }}
          />
        </ModalBody>
      </Modal>
    );
  };

  const checkIfFieldAlreadyMappedTo = (fieldToCheck) => {
    const existingFieldIndex = candidatesToBeImportedKeys.findIndex((field) => {
      return field.field_to_map === fieldToCheck;
    });

    if (existingFieldIndex > -1 && candidatesToBeImportedKeys[existingFieldIndex].field_to_map !== 'doNotImport') {
      return { existingField: candidatesToBeImportedKeys[existingFieldIndex], existingFieldIndex };
    } else {
      return null;
    }
  };

  const headerFormatter = (options) => {
    const { column, colIndex, hasRequiredFields, requiredFieldsMappedTo } = options;
    const { dataField, text } = column;

    if (dataField === 'field_to_map') {
      return (
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              width: 'calc(100% - 50px)',
              textAlign: 'center',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
              Map to Field
              {!hasRequiredFields ? (
                <>
                  {' '}
                  <FontAwesomeIcon
                    id="no_required_fields_enabled"
                    icon={faExclamationCircle}
                    style={{ color: '#ff3d3d', marginLeft: 5, cursor: 'pointer' }}
                  />
                  <UncontrolledTooltip
                    target="no_required_fields_enabled"
                    placement="top"
                    boundariesElement="window"
                    modifiers={popoverModifiers}
                  >
                    No Required fields enabled!
                  </UncontrolledTooltip>
                </>
              ) : !requiredFieldsMappedTo ? (
                <>
                  <FontAwesomeIcon
                    id="no_required_fields_mapped"
                    icon={faExclamationCircle}
                    style={{ color: '#ffc180', marginLeft: 5, cursor: 'pointer' }}
                  />
                  <UncontrolledTooltip
                    target="no_required_fields_mapped"
                    placement="top"
                    boundariesElement="window"
                    modifiers={popoverModifiers}
                  >
                    No Required fields mapped!
                  </UncontrolledTooltip>
                </>
              ) : null}
            </div>
          </div>
          {/* {savedFieldAssignment.length > 0 &&
            JSON.stringify(savedFieldAssignment) !== JSON.stringify(candidatesToBeImportedKeys) && ( */}
          <div style={{ display: 'flex', flexDirection: 'column', width: 45, marginRight: 5 }}>
            <Button
              id={`undo_temp_auto_mapping`}
              disabled={
                !(
                  savedFieldAssignment.length > 0 &&
                  JSON.stringify(savedFieldAssignment) !== JSON.stringify(candidatesToBeImportedKeys)
                )
              }
              size="sm"
              color={'danger'}
              onClick={() => {
                setCandidatesToBeImportedKeys(savedFieldAssignment);
              }}
            >
              <FontAwesomeIcon icon={faUndoAlt} />
              <UncontrolledTooltip
                target="undo_temp_auto_mapping"
                placement="top"
                boundariesElement="window"
                modifiers={popoverModifiers}
              >
                Undo temporary auto-mapping. This will revert the fields to the mapping done after file was dropped.
                This mapping could be be the previous mapping saved on this browser.
              </UncontrolledTooltip>
            </Button>
          </div>
          {/* )} */}
          <div style={{ display: 'flex', flexDirection: 'column', width: 45, marginRight: 5 }}>
            <Button
              id={`re_asses_auto_mapping`}
              size="sm"
              color={'success'}
              onClick={() => {
                setCandidatesToBeImportedKeys(
                  autoAssignFields({ fields: candidatesToBeImportedKeys, ignoreSavedFieldAssignment: true }),
                );
                alert.success('Auto-assigned fields!');
              }}
            >
              <FontAwesomeIcon icon={faSyncAlt} />
              <UncontrolledTooltip
                target="re_asses_auto_mapping"
                placement="top"
                boundariesElement="window"
                modifiers={popoverModifiers}
              >
                Re-assess auto-mapping. This will make the system go through the auto-mappign system. This could clear
                up some problems brought out by a previously saved mapping.
              </UncontrolledTooltip>
            </Button>
          </div>

          <div style={{ display: 'flex', flexDirection: 'column', width: 45 }}>
            <Button
              id={`manageFieldsButton`}
              size="sm"
              color={hasRequiredFields && requiredFieldsMappedTo ? 'primary' : 'danger'}
              onClick={() => {
                setShowManageFields(!showManageFields);
              }}
            >
              <FontAwesomeIcon icon={faGear} />
            </Button>
            <UncontrolledTooltip target="manageFieldsButton" modifiers={popoverModifiers}>
              Click to Manage Fields. At least one of the following must be enabled and mapped to: AAMCID, SFMatchID or
              Email.
            </UncontrolledTooltip>
          </div>
        </div>
      );
    } else {
      return text;
    }
  };

  const makePreviewColumns = () => {
    const headers = [
      { text: 'Field Name from File', dataField: 'field_Key', minWidth: 250 },
      { text: 'Data Preview (first 3 lines)', dataField: 'field_preview', minWidth: 200 },
      { text: 'Status', dataField: 'field_status', minWidth: 80 },
      { text: 'Map to Field', dataField: 'field_to_map', minWidth: 300 },
      { text: 'Do Not Import', dataField: 'field_not_import', minWidth: 100 },
    ];
    const requiredFields = ['AAMCID', 'SFMatchID', 'Email'];
    const dataTypes = dataTypesRef.current;

    const cellFormatter = (cell, row, rowIndex, formatExtraData) => {
      const { dataField } = formatExtraData;

      if (dataField === 'field_preview') {
        return (
          <>
            {row.field_preview.map((preview) => {
              return <div>{preview}</div>;
            })}
          </>
        );
      }

      if (dataField === 'field_status') {
        return (
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
            {cell === 'Not Mapped' ? (
              <FontAwesomeIcon icon={faTimesCircle} style={{ color: 'red' }} />
            ) : row.field_to_map === 'doNotImport' ? (
              <FontAwesomeIcon icon={faMinusCircle} style={{ color: 'gray' }} />
            ) : (
              <FontAwesomeIcon icon={faCheckCircle} style={{ color: 'green' }} />
            )}
          </div>
        );
      }

      if (dataField === 'field_to_map') {
        let enabledFields = dataTypes.enabled || [];
        enabledFields = enabledFields.sort((a, b) => {
          return a.SortOrder > b.SortOrder ? 1 : a.SortOrder < b.SortOrder ? -1 : 0;
          if (!b.Alias || a.Alias > b.Alias) {
            return 1;
          } else if (!a.Alias || a.Alias < b.Alias) {
            return -1;
          } else {
            return 0;
          }
        });

        return (
          <Input
            type="select"
            style={{ width: '100%' }}
            value={row.field_to_map}
            onChange={(e) => {
              const newValue = e.target.value;
              const existingFieldData = checkIfFieldAlreadyMappedTo(newValue);

              if (existingFieldData) {
                const { existingField, existingFieldIndex } = existingFieldData;

                Swal.fire({
                  title: 'Field Already Assigned',
                  html: `<b>${newValue}</b> is already mapped to <b>${existingField.field_Key}</b>. <br/> <br/>Reassign to <b>${row.field_Key}</b> instead?`,
                  icon: 'warning',
                  showDenyButton: true,
                  confirmButtonColor: 'gray',
                  denyButtonColor: '#ffc107',
                  confirmButtonText: `No`,
                  denyButtonText: `Yes, Reassign`,
                  customClass: {
                    denyButton: 'warning_deny_btn', // This assigns a custom class to the deny button
                  },
                }).then((res) => {
                  if (res.isDenied) {
                    const newCandidatesToBeImportedKeys = [...candidatesToBeImportedKeys];

                    newCandidatesToBeImportedKeys[existingFieldIndex].field_to_map = '';
                    newCandidatesToBeImportedKeys[existingFieldIndex].field_status = 'Not Mapped';

                    newCandidatesToBeImportedKeys[rowIndex].field_to_map = newValue;
                    newCandidatesToBeImportedKeys[rowIndex].field_status = 'Mapped';
                    newCandidatesToBeImportedKeys[rowIndex].field_not_import =
                      newValue === 'doNotImport' ? true : false;

                    setCandidatesToBeImportedKeys(newCandidatesToBeImportedKeys);
                  } else {
                  }
                });
              } else {
                const newCandidatesToBeImportedKeys = [...candidatesToBeImportedKeys];
                newCandidatesToBeImportedKeys[rowIndex].field_to_map = newValue;
                newCandidatesToBeImportedKeys[rowIndex].field_status = 'Mapped';
                newCandidatesToBeImportedKeys[rowIndex].field_not_import = newValue === 'doNotImport' ? true : false;

                setCandidatesToBeImportedKeys(newCandidatesToBeImportedKeys);
              }
            }}
          >
            <option value="" disabled selected></option>
            <option value="doNotImport">Do Not Import</option>
            {enabledFields.map((field) => {
              return (
                <option
                  style={{ fontWeight: field.Name.indexOf('CustomField') >= 0 ? 'bold' : null }}
                  value={field.Name}
                >
                  {field.Alias || '----'}
                  {field.Name.indexOf('CustomField') >= 0 ? '*' : ''}
                </option>
              );
            })}
            {/* {headers.map((header) => {
              return (
                <option value={header.dataField} selected={header.dataField === row.field_to_map}>
                  {header.text}
                </option>
              );
            })} */}
          </Input>
        );
      }

      if (dataField === 'field_not_import') {
        const isChecked = row.field_not_import || row.field_to_map === 'doNotImport';

        return (
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
            <FontAwesomeIcon
              icon={isChecked ? faCheckSquare : faSquare}
              style={{ cursor: 'pointer', fontSize: 19 }}
              onClick={() => {
                const newIsChecked = row.field_not_import || row.field_to_map === 'doNotImport';
                const newValue = !newIsChecked;
                const newCandidatesToBeImportedKeys = [...candidatesToBeImportedKeys];
                newCandidatesToBeImportedKeys[rowIndex].field_not_import = newValue;
                newCandidatesToBeImportedKeys[rowIndex].field_to_map = newValue ? 'doNotImport' : '';
                newCandidatesToBeImportedKeys[rowIndex].field_status = newValue
                  ? 'Mapped'
                  : newCandidatesToBeImportedKeys[rowIndex].field_to_map
                  ? 'Mapped'
                  : 'Not Mapped';
                setCandidatesToBeImportedKeys(newCandidatesToBeImportedKeys);
              }}
            />
          </div>
        );
        // return (
        //   <input
        //     type="checkbox"
        //     checked={row.field_not_import || row.field_to_map === 'doNotImport'}
        //     onChange={(e) => {
        //       const newValue = e.target.checked;
        //       const newCandidatesToBeImportedKeys = [...candidatesToBeImportedKeys];
        //       newCandidatesToBeImportedKeys[rowIndex].field_not_import = newValue;
        //       newCandidatesToBeImportedKeys[rowIndex].field_to_map = newValue ? 'doNotImport' : '';
        //       newCandidatesToBeImportedKeys[rowIndex].field_status = newValue
        //         ? 'Mapped'
        //         : newCandidatesToBeImportedKeys[rowIndex].field_to_map
        //         ? 'Mapped'
        //         : 'Not Mapped';
        //       setCandidatesToBeImportedKeys(newCandidatesToBeImportedKeys);
        //     }}
        //   />
        // );
      }

      return `${cell}`;
    };

    const newColumns = [];
    let hasRequiredFields = false;
    let requiredFieldsMappedTo = false;

    requiredFields.forEach((requiredField) => {
      const fieldIsEnabled = dataTypes.enabled.find((item) => item.Name === requiredField);
      const fieldIsMappedTo = checkIfFieldAlreadyMappedTo(requiredField);
      const fieldExists = fieldIsEnabled && fieldIsMappedTo;

      if (fieldExists) {
        hasRequiredFields = true;
      }

      if (fieldIsMappedTo) {
        requiredFieldsMappedTo = true;
      }
      return fieldExists;
    });

    headers.forEach((header) => {
      const { minWidth } = header;
      newColumns.push({
        dataField: header.dataField,
        text: header.text,
        label: header.text,
        formatter: cellFormatter,
        style: (cell, row, rowIndex, colIndex) => {
          return {
            verticalAlign: 'middle',
            paddingLeft: 10,
            paddingRight: 10,
            paddingTop: 5,
            paddingBottom: 5,
          };
        },
        headerFormatter: (column, colIndex) => {
          return headerFormatter({ column, colIndex, hasRequiredFields, requiredFieldsMappedTo });
        },
        headerStyle: {
          top: -10,
          position: 'sticky',
          verticalAlign: 'middle',
          backgroundColor: 'white',
          zIndex: 1,
          padding: 20,
          minWidth,
          width: minWidth,
        },
        // isTime: true,
        formatExtraData: { label: header.text, dataField: header.dataField },
        events: {
          onClick: (e, column, columnIndex, row, rowIndex) => {},
        },
        headerEvents: {
          onClick: (e, column, columnIndex) => {},
        },
        headerAttrs: {
          // id: 'timeSlotHeader',
        },
        attrs: (cell, row, rowIndex, colIndex) => {
          // return { id: `timeSlot_${row.pk_Timeslot}` };
        },
      });
    });

    return newColumns;
  };

  const renderCandidatesToImportPreview = () => {
    const renderCandidateItem = (item) => {
      const { FirstName, LastName, AAMCID = '---', SFMatchID = '---', Email = '---' } = item;
      const nameToDisplay = `${LastName || ''}${LastName && FirstName ? ', ' : ''}${FirstName || ''}`;

      return (
        <div style={{ display: 'flex', flexDirection: 'row', padding: 10, borderBottom: '1px solid #D0D0D0' }}>
          <div style={{ display: 'flex', flexDirection: 'column', width: 80, marginRight: 10 }}>
            <FontAwesomeIcon icon={faUserCircle} style={{ fontSize: 50 }} />
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', width: 'calc(100% - 80px)', fontSize: 13 }}>
            <div style={{ fontSize: 14, fontWeight: 'bold' }}>{nameToDisplay || '---'}</div>
            <div>
              {AAMCID || '---'} | {SFMatchID || '---'}
            </div>

            <div>{Email || '---'}</div>
          </div>
        </div>
      );
    };

    const renderOrphanedConflictCandidateItem = (item) => {
      const { AAMCIDs = [], SFMatchIDs = [], Emails = [], conflictingCandidates, itemIndex } = item;
      let duplicateValueToDisplay = '';
      let duplicateField = '';
      if (AAMCIDs.length > 0 && AAMCIDs[0]) {
        duplicateValueToDisplay = AAMCIDs[0];
        duplicateField = 'AAMCID';
      } else if (SFMatchIDs.length > 0 && SFMatchIDs[0]) {
        duplicateValueToDisplay = SFMatchIDs[0];
        duplicateField = 'SFMatchID';
      } else if (Emails.length > 0 && Emails[0]) {
        duplicateValueToDisplay = Emails[0];
        duplicateField = 'Email';
      }

      return (
        <>
          {conflictingCandidates.map((candidate, i) => {
            const { FirstName, LastName, AAMCID = '---', SFMatchID = '---', Email = '---' } = candidate;
            const nameToDisplay = `${LastName || ''}${LastName && FirstName ? ', ' : ''}${FirstName || ''}`;

            let tooltipText = conflictingCandidates
              .map((c, ii) => {
                if (ii == i) {
                  return;
                }
                const { FirstName, LastName, AAMCID = '---', SFMatchID = '---', Email = '---' } = candidate;
                const tooltipName = `${LastName || ''}${LastName && FirstName ? ', ' : ''}${FirstName || ''}`;

                return `${tooltipName}`;
              })
              .join('&nbsp');

            // tooltipText = `This candidate shares it's ${duplicateField} value with other candidates: ${(
            //   <br />
            // )}${tooltipText}`;
            const iconWithTooltip = (
              <>
                <FontAwesomeIcon
                  id={`invalidIcon_${itemIndex}_${i}`}
                  icon={faInfoCircle}
                  style={{ cursor: 'pointer', marginLeft: 10 }}
                />
                <UncontrolledTooltip
                  placement="top"
                  target={`invalidIcon_${itemIndex}_${i}`}
                  modifiers={popoverModifiers}
                >
                  This candidate shares it's {duplicateField} value with other candidates:
                  <br />
                  {conflictingCandidates.map((c, ii) => {
                    if (ii == i) {
                      return;
                    }
                    const { FirstName, LastName, AAMCID = '---', SFMatchID = '---', Email = '---' } = c;
                    const tooltipName = `${LastName || ''}${LastName && FirstName ? ', ' : ''}${FirstName || ''}`;

                    return <div>{tooltipName}</div>;
                  })}
                </UncontrolledTooltip>
              </>
            );
            return (
              <div style={{ display: 'flex', flexDirection: 'row', padding: 10, borderBottom: '1px solid #D0D0D0' }}>
                <div style={{ display: 'flex', flexDirection: 'column', width: 80, marginRight: 10 }}>
                  <FontAwesomeIcon icon={faUserCircle} style={{ fontSize: 50 }} />
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', width: 'calc(100% - 80px)', fontSize: 13 }}>
                  <div style={{ display: 'flex', flexDirection: 'column', width: '100%', fontSize: 13 }}>
                    <div style={{ fontSize: 14, fontWeight: 'bold' }}>{nameToDisplay || '---'}</div>
                    <div>
                      {AAMCID || '---'} {duplicateField === 'AAMCID' ? iconWithTooltip : ''} | {SFMatchID || '---'}{' '}
                      {duplicateField === 'SFMatchID' ? iconWithTooltip : ''}
                    </div>

                    <div>
                      {Email || '---'} {duplicateField === 'Email' ? iconWithTooltip : ''}
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </>
      );
    };

    const countInvalidCandidatesCount = (candidatesForInvalid) => {
      let count = 0;
      candidatesForInvalid.forEach((item) => {
        if (item.isOrphanedConflict) {
          count += item.conflictingCandidates.length;
        } else {
          count++;
        }
      });

      return count;
    };

    let columnWidth = 28;
    let validColumns = 0;

    if (candidatesForAddition.length > 0) {
      validColumns++;
    }

    if (candidatesForUpdate.length > 0) {
      validColumns++;
    }

    if (candidatesForInvalid.length > 0) {
      validColumns++;
    }

    switch (validColumns) {
      case 1:
        columnWidth = 100;
        break;
      case 2:
        columnWidth = 44;
        break;
      case 3:
        columnWidth = 31;
        break;
      default:
        columnWidth = 100;
        break;
    }

    let columnHeaderStyle = {
      display: 'flex',
      flexDirection: 'column',
      padding: 10,
      fontWeight: 'bold',
      justifyContent: 'center',
      alignItems: 'center',
      fontSize: 15,
      width: `${columnWidth}%`,
    };
    return (
      <div style={{ display: 'flex', flexDirection: 'row', width: '100%', paddingTop: 10 }}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              width: '100%',
              borderTop: '1px solid black',
              justifyContent: 'space-around',
              alignItems: 'center',
            }}
          >
            {!isRepackingCandidatesForImport && candidatesForInvalid.length > 0 ? (
              <div
                style={{
                  ...columnHeaderStyle,
                  color: '#FF0000',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    width: '100%',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  {`${countInvalidCandidatesCount(candidatesForInvalid)} / ${
                    repackedCandidates.length
                  } Invalid Candidates`}
                  <FontAwesomeIcon
                    id={`invalidIcon`}
                    icon={faInfoCircle}
                    style={{ cursor: 'pointer', marginLeft: 10 }}
                  />
                  <UncontrolledTooltip placement="top" target="invalidIcon" modifiers={popoverModifiers}>
                    Candidates must have a valid email address, SF Match ID or ERAS ID when importing
                  </UncontrolledTooltip>
                </div>
              </div>
            ) : null}
            {!isRepackingCandidatesForImport && candidatesForUpdate.length > 0 ? (
              <div
                style={{
                  ...columnHeaderStyle,
                  color: '#6666da',
                }}
              >
                {`${candidatesForUpdate.length} / ${repackedCandidates.length} Candidates to Update`}
              </div>
            ) : null}

            {!isRepackingCandidatesForImport && candidatesForAddition.length > 0 ? (
              <div
                style={{
                  ...columnHeaderStyle,
                  color: '#00AE28',
                }}
              >
                {' '}
                {`${candidatesForAddition.length} / ${repackedCandidates.length} Candidates to Import`}
              </div>
            ) : null}
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              width: '100%',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {isRepackingCandidatesForImport ? (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: '100%',
                  height: 'calc(100vh - 550px)',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                    fontWeight: 'bold',
                  }}
                >
                  <Loading
                    options={{
                      labelText: `Processing Candidates. . .(
                  ${repackedCandidateCount} / ${candidatesToBeImported.length})`,
                    }}
                  />{' '}
                </div>
              </div>
            ) : null}
            {!isRepackingCandidatesForImport && candidatesForInvalid.length > 0 ? (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: `${columnWidth}%`,
                  height: 'calc(100vh - 550px)',
                  overflowY: 'auto',
                  marginRight: 10,
                  marginLeft: 10,
                  backgroundColor: '#FFDADA',
                }}
              >
                {/* {isRepackingCandidatesForImport && (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      width: '100%',
                      height: '100%',
                      backgroundColor: 'rgba(0, 0, 0, 0.5)',
                      zIndex: 999,
                    }}
                  />
                )} */}
                {candidatesForInvalid.length > 0 ? (
                  candidatesForInvalid.map((item, i) => {
                    const { isOrphanedConflict } = item;

                    if (isOrphanedConflict) {
                      return renderOrphanedConflictCandidateItem({ ...item, itemIndex: i });
                    } else {
                      return renderCandidateItem(item);
                    }
                  })
                ) : (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'center',
                      textAlign: 'center',
                      fontWeight: 'bold',
                      padding: 10,
                    }}
                  >
                    No Candidates to Display
                  </div>
                )}
              </div>
            ) : null}

            {!isRepackingCandidatesForImport && candidatesForUpdate.length > 0 ? (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: `${columnWidth}%`,
                  height: 'calc(100vh - 550px)',
                  overflowY: 'auto',
                  marginRight: 10,
                  marginLeft: 10,
                  backgroundColor: '#aeaefb',
                }}
              >
                {/* {isRepackingCandidatesForImport && (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      width: '100%',
                      height: '100%',
                      backgroundColor: 'rgba(0, 0, 0, 0.5)',
                      zIndex: 999,
                    }}
                  />
                )} */}
                {candidatesForUpdate.length > 0 ? (
                  candidatesForUpdate.map((item) => renderCandidateItem(item))
                ) : (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'center',
                      textAlign: 'center',
                      fontWeight: 'bold',
                      padding: 10,
                    }}
                  >
                    No Candidates to Update
                  </div>
                )}
              </div>
            ) : null}

            {!isRepackingCandidatesForImport && candidatesForAddition.length > 0 ? (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: `${columnWidth}%`,
                  height: 'calc(100vh - 550px)',
                  overflowY: 'auto',
                  marginRight: 10,
                  marginLeft: 10,
                  backgroundColor: '#CEFFDA',
                }}
              >
                {candidatesForAddition.length > 0 ? (
                  candidatesForAddition.map((item) => renderCandidateItem(item))
                ) : (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'center',
                      textAlign: 'center',
                      fontWeight: 'bold',
                      padding: 10,
                    }}
                  >
                    No Candidates to Update
                  </div>
                )}
              </div>
            ) : null}
          </div>
        </div>
      </div>
    );
  };

  const renderFieldManagement = () => {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          // width: 'calc(100vw - 400px)',
          width: 'calc(100% - 50px)',
          height: 'calc(100vh - 480px)',
          overflow: 'auto',
          padding: 10,
        }}
      >
        <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
          <BootstrapTable
            bordered={true}
            condensed={true}
            columns={makePreviewColumns()}
            data={candidatesToBeImportedKeys}
            rowStyle={(row, rowIndex) => {
              return { padding: 0 };
            }}
            keyField="field_key"
            noDataIndication={<div style={{ textAlign: 'center' }}>No Data</div>}
          />
        </div>
      </div>
    );
  };

  const renderDragAndDropArea = () => {
    return (
      <div style={{ display: 'flex', flexDirection: 'row', marginTop: '2vh' }}>
        {' '}
        <div
          disabled={isFetchingDataTypes}
          {...getRootProps()}
          style={{
            height: 'min-content',
            cursor: 'pointer',
            borderRadius: 15,
          }}
          className="hover_blue"
        >
          <input disabled={isFetchingDataTypes} {...getInputProps()} />
          <div
            style={{
              height: 'calc(70vh - 250px',
              width: 'calc(70vw - 250px)',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
              margin: 10,
              padding: 10,
              backgroundColor: isFetchingDataTypes ? '#94908f' : '#cfd0d1',
              border: '3px solid black',
              borderRadius: 15,
            }}
          >
            <FontAwesomeIcon icon={faUpload} size="10x" style={{ paddingBottom: '15px' }} />
            {isFetchingDataTypes ? (
              <h4>Please wait. . .</h4>
            ) : isDragActive && !isFetchingDataTypes ? (
              <h4>Drop the files here ...</h4>
            ) : (
              <h4>Click to browse or drag and drop files here</h4>
            )}
          </div>
        </div>
      </div>
    );
  };

  const renderContent = () => {
    if (displayResults || isPosting) {
      return renderImportStatus();
    } else if (repackedCandidates.length > 0 || isPosting) {
      return renderCandidatesToImportPreview();
    } else if (candidatesToBeImported.length > 0) {
      return renderFieldManagement();
    } else {
      return renderDragAndDropArea();
    }
  };

  const renderTopMessage = () => {
    if (displayResults) {
      const {
        existingCandidates = [],
        existingCandidatesWithRemovedStatus = [],
        candidatesInserted = [],
        countOfOrphanedRows = 0,
      } = results;
      const { totalCandidates, totalCandidatesUploaded } = computeLoadingProgress();
      return (
        <div style={{ display: 'flex', flexDirection: 'column', width: '100%', marginBottom: 10 }}>
          <div
            style={{
              color: '#00AE28',
              fontWeight: 'bold',
              fontSize: 17,
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center',
              padding: 10,
            }}
          >
            Successfully imported {totalCandidatesUploaded} / {totalCandidates} Candidate Records
          </div>
        </div>
      );
    } else if (isPosting) {
      const { totalCandidates, totalCandidatesUploaded } = computeLoadingProgress();
      return (
        <div style={{ color: '#00AE28', fontWeight: 'bold', fontSize: 17 }}>
          Importing {repackedCandidates.length} Candidate Records ({totalCandidatesUploaded} / {totalCandidates})
        </div>
      );
    } else if (repackedCandidates.length > 0) {
      return;
      return (
        <div style={{ color: '#00AE28', fontWeight: 'bold', fontSize: 17 }}>
          Ready to Import {repackedCandidates.length} Candidate Records
        </div>
      );
    } else {
      return (
        <div>
          <div>
            {' '}
            The CSV or XSLX file containing your candidate data such as name, email, phone number, test scores, etc...
          </div>
          <div style={{ fontWeight: 'bold', textAlign: 'center' }}>CSVs must have UTF-8 encoding.</div>
        </div>
      );
    }
  };

  const renderBottomBar = () => {
    if (displayResults) {
      return (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            padding: 10,
          }}
        >
          <Button
            color={'success'}
            style={{ width: 250, fontWeight: 'bold' }}
            onClick={() => {
              resetCandidateImports();
              setDisplayResults(false);
            }}
          >
            Done
          </Button>
        </div>
      );
    } else if ([...candidatesForAddition, ...candidatesForUpdate].length > 0) {
      if (isRepackingCandidatesForImport) {
        return;
      }
      return (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            width: '100%',
            padding: 10,
          }}
        >
          <Button
            disabled={isRepackingCandidatesForImport}
            color={'secondary'}
            style={{ width: 250, fontWeight: 'bold' }}
            onClick={() => {
              if (isPosting) {
                Swal.fire({
                  title: 'Are you sure?',
                  text:
                    'You are in the middle of uploading. This will stop the process and unuploaded data will be lost.',
                  icon: 'warning',
                  showDenyButton: true,
                  cancelButtonColor: 'red',
                  confirmButtonText: 'No, Keep Going',
                  denyButtonText: 'Yes, Cancel',
                  confirmButtonColor: 'gray',
                }).then((result) => {
                  if (result.isDenied) {
                    resetCandidateImports();
                  }
                });
              } else {
                resetCandidateImports();
              }
            }}
          >
            Cancel
          </Button>
          <Button
            disabled={isPosting || isRepackingCandidatesForImport}
            color={'success'}
            style={{ width: 250, fontWeight: 'bold' }}
            onClick={() => {
              createCandidates([...candidatesForAddition, ...candidatesForUpdate]);
              // createCandidates([]);
            }}
          >
            Import {[...candidatesForAddition, ...candidatesForUpdate].length} Record
            {[...candidatesForAddition, ...candidatesForUpdate].length > 1 ? 's' : ''}
          </Button>
        </div>
      );
    } else if (candidatesToBeImported.length > 0) {
      const requiredFields = ['AAMCID', 'SFMatchID', 'Email'];
      const dataTypes = dataTypesRef.current;

      let hasRequiredFields = false;

      let requiredFieldsMappedTo = false;

      requiredFields.forEach((requiredField) => {
        const fieldIsEnabled = dataTypes.enabled.find((item) => item.Name === requiredField);
        const fieldIsMappedTo = checkIfFieldAlreadyMappedTo(requiredField);
        const fieldExists = fieldIsEnabled && fieldIsMappedTo;

        if (fieldExists) {
          hasRequiredFields = true;
        }

        if (fieldIsMappedTo) {
          requiredFieldsMappedTo = true;
        }

        return fieldExists;
      });

      return (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            width: '100%',
            margin: 20,
          }}
        >
          <div style={{ direction: 'flex', flexDirection: 'column', width: 130, paddingLeft: 20 }}>
            <Button
              disabled={isRepackingCandidatesForImport}
              size="sm"
              style={{ width: 100, fontWeight: 'bold' }}
              color="secondary"
              onClick={() => {
                resetCandidateImports();
              }}
            >
              Cancel
            </Button>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              width: 'calc(100% - 300px)',
              justifyContent: 'center',
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                paddingLeft: 20,
                paddingRight: 20,
                fontWeight: 'bold',
              }}
            >
              <div
                style={{
                  direction: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignContent: 'center',
                  paddingLeft: 20,
                  paddingRight: 20,
                  borderRight: '1px solid gray',
                }}
              >
                {unmappedCount || 0} Unmapped
              </div>
              <div
                style={{
                  direction: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignContent: 'center',
                  paddingLeft: 20,
                  paddingRight: 20,
                  borderRight: '1px solid gray',
                }}
              >
                {mappedCount || 0} Mapped
              </div>
              <div
                style={{
                  direction: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignContent: 'center',
                  paddingLeft: 20,
                  paddingRight: 20,
                  borderRight: '1px solid gray',
                }}
              >
                {excludedCount || 0} Excluded
              </div>
              <div
                style={{
                  direction: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignContent: 'center',
                  paddingLeft: 20,
                  paddingRight: 20,
                }}
              >
                {candidatesToBeImportedKeys.length} Total
              </div>
            </div>
          </div>
          <div style={{ direction: 'flex', flexDirection: 'column', width: 130, paddingRight: 20 }}>
            <Button
              id={'toImportPreviewNext'}
              disabled={!hasRequiredFields || isRepackingCandidatesForImport}
              size="sm"
              style={{ width: 100, fontWeight: 'bold' }}
              color={hasRequiredFields ? 'success' : 'danger'}
              onClick={() => {
                if (unmappedCount > 0) {
                  Swal.fire({
                    title: `${unmappedCount} Unmapped Fields`,
                    html: `Ignore <b>${unmappedCount}</b> unmapped fields and continue with  <b>${mappedCount}</b> mapped fields only?`,
                    icon: 'warning',
                    showDenyButton: true,
                    confirmButtonColor: 'gray',
                    denyButtonColor: '#ffc107',
                    confirmButtonText: `No, Go Back`,
                    denyButtonText: `Yes, Continue`,
                    customClass: {
                      denyButton: 'warning_deny_btn', // This assigns a custom class to the deny button
                    },
                  }).then((res) => {
                    if (res.isDenied) {
                      setIsRepackingCandidatesForImport(true);
                      repackCaller(candidatesToBeImported);

                      // setShowPreImportScreen(true);
                    }
                  });
                } else {
                  setIsRepackingCandidatesForImport(true);
                  repackCaller(candidatesToBeImported);
                }
              }}
            >
              Next
            </Button>
            {!hasRequiredFields ? (
              <UncontrolledTooltip target={'toImportPreviewNext'} modifiers={popoverModifiers}>
                You must enable and map to at least one of the following primary fields: AAMCID, SFMatchID or Email.{' '}
              </UncontrolledTooltip>
            ) : null}
          </div>
        </div>
      );
    } else {
    }
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  if (error) {
    return (
      <div className="flex-center" style={{ flexDirection: 'column', height: '30vh' }}>
        <h4 style={{ marginBottom: '15px' }}>{errorMessage}</h4>
        <Button
          color="primary"
          onClick={(e) => {
            e.preventDefault();
            setDisplayResults(false);
            setResults({ candidatesInserted: [], existingCandidates: [], existingCandidatesWithRemovedStatus: [] });
            setError(false);
            setErrorMessage('');
          }}
        >
          Back
        </Button>
      </div>
    );
  }

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        margin: 10,
        backgroundColor: 'white',
        width: '98%',
        minHeight: '60vh',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          alignItems: 'center',
          width: '100%',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
            }}
          >
            <div style={{ fontSize: 17, fontWeight: 'bold', marginBottom: 5, padding: 5 }}>
              Import Candidate Profile Data
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', width: '80%' }}>
              {renderTopMessage()}
            </div>
          </div>
        </div>
        {renderContent()}
        {renderBottomBar()}
        {renderManageFields()}
      </div>
    </div>
  );
};

const PostImportPreviewItem = (props) => {
  const [isSaving, setIsSaving] = useState(false);
  const [isFailedToRestore, setIsFailedToRestore] = useState(true);

  const dContext = useContext(DepartmentContext);

  const { getTokenSilently } = useAuth0();

  const { item, type, uploadStatus, createCandidates } = props;

  const messages = {
    deleted: 'Candidate was updated but is marked as deleted.',
    inserted: 'New Candidate added.',
    updated: 'Candidate was updated.',
    restored: 'Updated Candidate but initially marked as deleted. Now Restored.',
    orphaned: 'Unable to import - Missing Match Field',
    queued: 'Candidate is queued for upload.',
    uploading: 'Candidate is being uploaded.',
    failed: 'Candidate upload failed.',
    orphaned_conflict: 'Unable to import - Conflicting Match Fields',
  };

  const icons = {
    deleted: faUndoAlt,
    inserted: faCheckCircle,
    updated: faCheckCircle,
    orphaned: faExclamationCircle,
    orphaned_conflict: faExclamationCircle,
    queued: faClock,
    uploading: faSpinner,
    uploaded: faCheckCircle,
    failed: faRedoAlt,
  };

  const colors = {
    deleted: '#ffa459',
    inserted: '#CEFFDA ',
    updated: '#aeaefb',
    orphaned: '#ff8080',
    orphaned_conflict: '#ff8080',
    queued: '#D0D0D0',
    failed: '#ff8080',
  };

  if (item.isOrphanedConflict) {
    const { AAMCIDs = [], SFMatchIDs = [], Emails = [], conflictingCandidates, itemIndex } = item;
    let duplicateValueToDisplay = '';
    let duplicateField = '';
    if (AAMCIDs.length > 0 && AAMCIDs[0]) {
      duplicateValueToDisplay = AAMCIDs[0];
      duplicateField = 'AAMCID';
    } else if (SFMatchIDs.length > 0 && SFMatchIDs[0]) {
      duplicateValueToDisplay = SFMatchIDs[0];
      duplicateField = 'SFMatchID';
    } else if (Emails.length > 0 && Emails[0]) {
      duplicateValueToDisplay = Emails[0];
      duplicateField = 'Email';
    }

    return (
      <>
        {conflictingCandidates.map((candidate, i) => {
          const { LastName, FirstName, SFMatchID, AAMCID, Email } = candidate;

          const key = `${AAMCID || SFMatchID || Email || `${FirstName}_${LastName}`}`;
          const itemUploadStatus =
            uploadStatus[key] && uploadStatus[key].uploadStatus ? uploadStatus[key].uploadStatus : 'queued';

          const nameToDisplay = `${LastName || ''}${LastName && FirstName ? ', ' : ''}${FirstName || ''}`;

          const icon = icons[type];
          let color = colors[type];
          let message = messages[type];

          const iconID = `icon_${LastName}_${FirstName}_${AAMCID}_${SFMatchID}`.replaceAll(' ', '_');

          return (
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                borderBottom: '1px solid #D0D0D0',
                backgroundColor: color,
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'flex-start',
                  width: '50%',
                }}
              >
                <div style={{ display: 'flex', flexDirection: 'row', padding: 10 }}>
                  <div style={{ display: 'flex', flexDirection: 'column', width: 80, marginRight: 10 }}>
                    <FontAwesomeIcon icon={faUserCircle} style={{ fontSize: 70 }} />
                  </div>
                  <div style={{ display: 'flex', flexDirection: 'column', width: 'calc(100% - 80px)' }}>
                    <div style={{ fontSize: 13, fontWeight: 'bold' }}>{nameToDisplay || '---'}</div>
                    <div style={{ fontSize: 13 }}>
                      {AAMCID || '---'} | {SFMatchID || '---'}
                    </div>

                    <div style={{ fontSize: 13 }}>{Email || '---'}</div>
                  </div>
                </div>
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: '50%',
                  fontWeight: 'bold',
                  fontSize: 13,
                }}
              >
                {message}
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: 90,
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <>
                  <FontAwesomeIcon
                    id={iconID}
                    icon={icon}
                    style={{
                      fontSize: 25,
                      color: 'black',
                      cursor: type == 'orphaned_conflict' ? 'pointer' : null,
                    }}
                    onClick={() => {
                      if (itemUploadStatus == 'failed') {
                        createCandidates([item]);
                      }
                    }}
                  />

                  <UncontrolledTooltip target={iconID}>
                    This candidate shares it's {duplicateField} value with other candidates:
                    <br />
                    {conflictingCandidates.map((c, ii) => {
                      if (ii == i) {
                        return;
                      }
                      const { FirstName, LastName, AAMCID = '---', SFMatchID = '---', Email = '---' } = c;
                      const tooltipName = `${LastName || ''}${LastName && FirstName ? ', ' : ''}${FirstName || ''}`;

                      return <div>{tooltipName}</div>;
                    })}
                  </UncontrolledTooltip>
                </>
              </div>
            </div>
          );
        })}
      </>
    );
  } else {
    const { LastName, FirstName, SFMatchID, AAMCID, Email } = item;

    const key = `${AAMCID || SFMatchID || Email || `${FirstName}_${LastName}`}`;
    const itemUploadStatus =
      uploadStatus[key] && uploadStatus[key].uploadStatus ? uploadStatus[key].uploadStatus : 'queued';

    const nameToDisplay = `${LastName || ''}${LastName && FirstName ? ', ' : ''}${FirstName || ''}`;

    const icon =
      !isFailedToRestore && !isSaving && itemUploadStatus === 'uploaded'
        ? faCheckCircle
        : isSaving
        ? faSpinner
        : itemUploadStatus === 'uploaded' || type === 'orphaned' || type === 'orphaned_conflict'
        ? icons[type]
        : icons[itemUploadStatus];

    let color =
      type === 'orphaned' || type === 'orphaned_conflict' || itemUploadStatus === 'uploaded'
        ? colors[type]
        : colors[itemUploadStatus];
    let message = type === 'orphaned' || itemUploadStatus === 'uploaded' ? messages[type] : messages[itemUploadStatus];

    if (type === 'deleted' && !isFailedToRestore && !isSaving) {
      message = messages['restored'];
      color = colors['updated'];
    }

    const iconID = `icon_${LastName}_${FirstName}_${AAMCID}_${SFMatchID}`.replaceAll(' ', '_');
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          borderBottom: '1px solid #D0D0D0',
          backgroundColor: color,
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'flex-start',
            width: '50%',
          }}
        >
          <div style={{ display: 'flex', flexDirection: 'row', padding: 10 }}>
            <div style={{ display: 'flex', flexDirection: 'column', width: 80, marginRight: 10 }}>
              <FontAwesomeIcon icon={faUserCircle} style={{ fontSize: 70 }} />
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', width: 'calc(100% - 80px)' }}>
              <div style={{ fontSize: 13, fontWeight: 'bold' }}>{nameToDisplay || '---'}</div>
              <div style={{ fontSize: 13 }}>
                {AAMCID || '---'} | {SFMatchID || '---'}
              </div>

              <div style={{ fontSize: 13 }}>{Email || '---'}</div>
            </div>
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            width: '50%',
            fontWeight: 'bold',
            fontSize: 13,
          }}
        >
          {message}
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: 90,
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <>
            <FontAwesomeIcon
              id={iconID}
              spin={isSaving || itemUploadStatus === 'uploading'}
              icon={icon}
              style={{
                fontSize: 25,
                color: 'black',
              }}
              onClick={() => {
                if (itemUploadStatus == 'failed') {
                  createCandidates([item]);
                }
              }}
            />
            {type == 'orphaned' ? (
              <UncontrolledTooltip target={iconID} modifiers={popoverModifiers}>
                Candidates must have a valid email address, SF Match ID or ERAS ID when importing
              </UncontrolledTooltip>
            ) : null}
            {itemUploadStatus == 'failed' ? (
              <UncontrolledTooltip target={iconID} modifiers={popoverModifiers}>
                Retry uploading this candidate
              </UncontrolledTooltip>
            ) : null}
          </>
        </div>
      </div>
    );
  }
};
export default AdminImportCandidates;
