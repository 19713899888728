import React, { Fragment, useEffect, useState } from 'react';
import { Col, Row } from 'reactstrap';
import styled from 'styled-components';
import LikertQuestionChoice from './LikertQuesitonChoice';
import moment from 'moment';

const InnerCircle = styled.div`
  height: 30px;
  width: 30px;
  display: flex !important;
  align-items: center;
  justify-content: center;
  border: 2px solid ${(props) => props.borderColor};
  border-radius: 50%;
  display: inline-block;
  font-weight: 700;
`;

const wrapperStyle = {
  width: '20%',
  width: '20%',
  display: 'flex',
  justifyContent: 'space-between',
  flexDirection: 'column',
  alignItems: 'center',
  textAlign: 'center',
};

const LikertQuestion = ({
  question,
  Answers4QuestionsAnswerNumberScore,
  updateEval,
  i,
  checkIfStatusNeedsChangingToModify,
  questionOptions,
  numberOfSelections = 5,
  demo,
  questionaireDisabled,
}) => {
  const [selected, setSelected] = useState(null);
  const [likertLengthAlphabet, setLikertLengthAlphabet] = useState([]);
  const [gridTemplateColumns, setGridTemplateColumns] = useState('');
  const [answer, setAnswer] = useState(null);
  const [oldValue, setOldValue] = useState(null);

  const [windowWidth, setWindowWidth] = useState(0);
  const [windowHeight, setWindowHeight] = useState(0);

  let isMobile = windowHeight > windowWidth;
  useEffect(() => {
    setWindowWidth(window.innerWidth);
    setWindowHeight(window.innerHeight);

    document.addEventListener('mousemove', () => {
      const now = moment();
      localStorage.setItem('lastActivity', now.format('MMM DD, YYYY hh:mm A'));
    });
    document.addEventListener('click', () => {
      const now = moment();
      localStorage.setItem('lastActivity', now.format('MMM DD, YYYY hh:mm A'));
    });

    window.addEventListener('resize', () => {
      setWindowWidth(window.innerWidth);
      setWindowHeight(window.innerHeight);
    });
  }, []);

  useEffect(() => {
    if (question.pk_EvaluationAnswer) {
      questionOptions.forEach((item, index) => {
        if (Number(question.AnswerNumber) === Number(item.DisplayValue)) {
          setSelected(index);
          setOldValue(index);
        }
      });
    }
  }, [question]);

  useEffect(() => {
    // set the grid width based on the number of choices
    if (numberOfSelections === 5) {
      setGridTemplateColumns('20% 20% 20% 20% 20%');
    }

    if (numberOfSelections === 4) {
      setGridTemplateColumns('25% 25% 25% 25%');
    }

    if (numberOfSelections === 3) {
      setGridTemplateColumns('33.33% 33.33% 33.33%');
    }
    const alphabetArray = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm'];

    //
    const restrictedArray = alphabetArray.splice(0, numberOfSelections);
    setLikertLengthAlphabet(restrictedArray);
  }, [numberOfSelections]);

  const postAnswer = (DisplayValue, pk_EvaluationQuestion) => {
    if (!demo)
      updateEval(DisplayValue, pk_EvaluationQuestion, 'scale', (res) => {
        if (res.error) {
          revert();
        } else {
          setOldValue(selected);
        }
      });
  };

  const revert = () => {
    setSelected(oldValue);
  };

  const constructCircleClassName = (num) => {
    return 'light-blue-border-hover ' + (selected === num ? 'selectedButton' : '');
  };

  const { pk_EvaluationQuestion } = question;

  return (
    <Row>
      <Col style={{ padding: 0 }} lg="12" md="12">
        <p style={{ fontWeight: 700 }}>
          <span style={{ fontWeight: 700 }}>{i + 1}. </span>
          {question.EnableRequired && <span style={{ color: '#ff0000' }}>*</span>}
          {question.QuestionText}
        </p>
      </Col>
      <Col lg="12" md="12" style={{ maxWidth: '100%', padding: 5 }}>
        <div
          className="like-wrapper"
          style={{ display: 'flex', justifyContent: 'space-evenly', flexDirection: 'row', flexWrap: 'wrap' }}
        >
          {questionOptions &&
            questionOptions.map((item, index) => {
              return (
                <>
                  <div
                    style={{
                      padding: 5,
                      marginBottom: 0,
                      width: isMobile ? 80 : '10%',
                    }}
                  >
                    <LikertQuestionChoice
                      item={item}
                      index={index}
                      postAnswer={postAnswer}
                      checkIfStatusNeedsChangingToModify={checkIfStatusNeedsChangingToModify}
                      setSelected={setSelected}
                      pk_EvaluationQuestion={pk_EvaluationQuestion}
                      constructCircleClassName={constructCircleClassName}
                      selected={selected}
                      questionaireDisabled={questionaireDisabled}
                      isMobile={isMobile}
                    />
                  </div>
                </>
              );
            })}
        </div>
      </Col>
    </Row>
  );
};

export default LikertQuestion;
