import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { Button, CustomInput, FormFeedback, Input, Label, Modal, ModalBody, ModalHeader } from 'reactstrap';
import style from './AddUser.style';
import { isInvalidEmail } from 'Common.functions';

const AddUser = ({
  isOpen,
  onSave,
  onCancel,
  setIsOpen,
  saveNewUser,
  renderUserExistsError,
  handleChange,
  user,
  setUser,
  isPosting,
}) => {
  const [triedToSubmit, setTriedToSubmit] = useState(false);
  const { UserFirst, UserMiddle, UserLast, UserEmail, UserSuffix, EnableAdmin, EnableEvaluator } = user;

  return (
    <Modal
      isOpen={isOpen}
      onClosed={() => {
        setUser({});
        setTriedToSubmit(false);
      }}
    >
      <ModalHeader
        closeButton
        toggle={() => {
          setIsOpen(false);
        }}
      >
        Add New User
      </ModalHeader>
      <ModalBody>
        <div style={style.simpleRow}>
          <div style={style.simpleColumn}>
            <div style={style.inputFieldContainer}>
              <Label for="field1">First Name</Label>
              <Input
                autoComplete="off"
                invalid={triedToSubmit && (UserFirst == '' || UserFirst == null)}
                id="field1"
                type="text"
                placeholder="First Name"
                style={style.inputField}
                value={UserFirst}
                onChange={(e) => {
                  handleChange('UserFirst', e.target.value);
                }}
              />
              <FormFeedback>First Name is Required!</FormFeedback>
            </div>
            <div style={style.inputFieldContainer}>
              <Label for="field2">Middle Name</Label>
              <Input
                autoComplete="off"
                id="field2"
                type="text"
                placeholder="Middle Name"
                style={style.inputField}
                value={UserMiddle}
                onChange={(e) => {
                  handleChange('UserMiddle', e.target.value);
                }}
              />
            </div>
            <div style={style.inputFieldContainer}>
              <Label for="field3">Last Name</Label>
              <Input
                autoComplete="off"
                invalid={triedToSubmit && (UserLast == '' || UserLast == null)}
                id="field3"
                type="text"
                placeholder="Last Name"
                style={style.inputField}
                value={UserLast}
                onChange={(e) => {
                  handleChange('UserLast', e.target.value);
                }}
              />
              <FormFeedback>Last Name is Required!</FormFeedback>
            </div>
            <div style={style.inputFieldContainer}>
              <Label for="field4">Suffix</Label>
              <Input
                autoComplete="off"
                id="field4"
                type="text"
                placeholder="Suffix"
                style={style.inputField}
                value={UserSuffix}
                onChange={(e) => {
                  handleChange('UserSuffix', e.target.value);
                }}
              />
            </div>
            <div style={style.inputFieldContainer}>
              <Label for="field5">Email</Label>
              <Input
                autoComplete="off"
                invalid={(triedToSubmit && (UserEmail == '' || UserEmail == null)) || isInvalidEmail(UserEmail)}
                id="field5"
                type="email"
                placeholder="Email"
                style={style.inputField}
                value={UserEmail}
                required
                onChange={(e) => {
                  handleChange('UserEmail', e.target.value);
                }}
              />
              <FormFeedback>{isInvalidEmail(UserEmail) ? 'Invalid Email format!' : 'Email is Required!'}</FormFeedback>
            </div>
            <div style={style.subTitleContainer}>Assign Roles</div>
            <div style={style.spacedBetweenRow}>
              <div style={style.inputFieldContainer}>
                <CustomInput
                  label="Administrator"
                  type="switch"
                  id="field6"
                  name="field6"
                  checked={EnableAdmin}
                  onChange={(e) => {
                    handleChange('EnableAdmin', !EnableAdmin);
                  }}
                />
              </div>
              <div style={style.inputFieldContainer}>
                <CustomInput
                  label="Evaluator"
                  type="switch"
                  id="field7"
                  name="field7"
                  checked={EnableEvaluator}
                  onChange={(e) => {
                    handleChange('EnableEvaluator', !EnableEvaluator);
                  }}
                />
              </div>
            </div>
            <div style={style.inputFieldContainer}>
              <Button
                style={style.inputField}
                disabled={isPosting}
                color="success"
                onClick={() => {
                  setTriedToSubmit(true);
                  if (saveNewUser) {
                    saveNewUser(user);
                  }
                }}
              >
                {!isPosting ? 'Save' : <FontAwesomeIcon icon={faSpinner} spin />}
              </Button>
            </div>
            {renderUserExistsError() && (
              <div style={{ paddingTop: '5px' }}>
                <p style={{ color: '#a64452', fontWeight: 700 }}>
                  Error: A user with that email already exists in this department.
                </p>
              </div>
            )}
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default AddUser;
