const style = {
  itemRow: {
    alignItems: 'center',
    // borderBottom: '1px solid #c8dae0',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingTop: 15,
    paddingBottom: 15,
  },
  clickable: {
    cursor: 'pointer',
    marginLeft: 5,
    marginRight: 5,
  },
  itemLabel: {
    fontWeight: 600,
    marginLeft: 5,
  },
  simpleRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  simpleColumn: {
    display: 'flex',
    flexDirection: 'column',
  },
};

export default style;
