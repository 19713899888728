import { faCheckSquare, faSearch, faSquare, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext, useState, useEffect } from 'react';
import { Button, Input, InputGroup, InputGroupAddon } from 'reactstrap';
import { useAuth0 } from '../../../../../../auth0/reactAuth0Spa';
import { formatBearerToken } from '../../../../../../Common.functions';
import { DepartmentContext } from '../../../../../../DepartmentWrapper';
import { deleteData, fetchDataAgnostic, postDataAgnostic } from '../../../../../../Services/dataApi';
import style from './style';
import { useAlert } from 'react-alert';
import { clone } from 'lodash';

const PreScreenPopover = ({
  // evaluators,
  token,
  selectedCandidates,
  setShowPreScreen,
  getEvaluators,
  getCandidates,
  getAllCandidatesData,
  setIsUpdating,
}) => {
  const [evaluators, setEvaluators] = useState([]);
  const [evaluatorsWithNoCandidates, setEvaluatorsWithNoCandidates] = useState();
  const [evaluatorsWithCandidates, setEvaluatorCandidates] = useState();

  const [selectedEvaluators, setSelectedEvaluators] = useState([]);
  const [searchString, setSearchString] = useState('');
  const [isFetching, setIsFetching] = useState(false);

  const dContext = useContext(DepartmentContext);

  const alert = useAlert();
  const { getTokenSilently } = useAuth0();

  useEffect(() => {
    fetchEvaluators();
    getEvaluatorsWithCandidates();
  }, []);

  useEffect(() => {
    if (evaluatorsWithNoCandidates && evaluatorsWithCandidates) {
      evaluatorsWithCandidates.forEach((evaluator) => {
        const counterpart = evaluatorsWithNoCandidates.findIndex((enc) => {
          return enc.pk_User === evaluator.pk_User;
        });
        if (counterpart >= 0) {
          // evaluatorsWithNoCandidates[counterpart] = evaluator;
          const oldEvaluator = evaluatorsWithNoCandidates[counterpart];
          const newEvaluator = { ...oldEvaluator, assignedCandidates: evaluator.assignedCandidates };
          evaluatorsWithNoCandidates[counterpart] = newEvaluator;
        }
      });

      setEvaluators(evaluatorsWithNoCandidates);
    }
  }, [evaluatorsWithNoCandidates, evaluatorsWithCandidates]);

  const getEvaluatorsWithCandidates = () => {
    getTokenSilently()
      .then((token) => {
        fetchDataAgnostic(
          'department/season/users/prescreen/all',
          {
            pk_Department: dContext.department.pk_Department,
            pk_Season: dContext.season.pk_Season,
          },
          formatBearerToken(token),
        )
          .then((res) => {
            setEvaluatorCandidates(Object.values(res.data) || []);
          })
          .catch((err) => {});
      })
      .catch((err) => {});
  };

  const fetchEvaluators = () => {
    setIsFetching(true);
    getTokenSilently()
      .then((token) => {
        fetchDataAgnostic(
          'department/users',
          {
            pk_Department: dContext.department.pk_Department,
            IncludeEvaluators: 1,
          },
          formatBearerToken(token),
        )
          .then((res) => {
            const newPrescreeners = Object.values(res.data).sort((a, b) => {
              const stringA = `${a.UserLast || ''}, ${a.UserFirst || ''}`.toLowerCase();
              const stringB = `${b.UserLast || ''}, ${b.UserFirst || ''}`.toLowerCase();

              if (stringA < stringB) {
                return -1;
              }
              if (stringA > stringB) {
                return 1;
              }
              return 0;
            });

            setEvaluatorsWithNoCandidates(newPrescreeners || []);
            setIsFetching(false);
          })
          .catch((e) => {
            setIsFetching(false);
          });
      })
      .catch((err) => {
        setIsFetching(false);
      });
  };

  const assignEvaluators = () => {
    const evaluator_pks = selectedEvaluators.map((item) => {
      return item.pk_User;
    });
    setIsUpdating(true);
    getTokenSilently()
      .then((token) => {
        postDataAgnostic(
          'department/season/users/prescreen',
          { pk_Department: dContext.department.pk_Department, pk_Season: dContext.season.pk_Season },
          { candidatePks: selectedCandidates, evaluatorPks: evaluator_pks || [] },
          formatBearerToken(token),
        )
          .then((res) => {
            fetchEvaluators();
            setShowPreScreen(false);
            getCandidates();
            getAllCandidatesData(() => {
              setIsUpdating(false);
            });
            alert.success('Added Screener successfully');
          })
          .catch((err) => {
            alert.error('Error adding Screener');
          });
      })
      .catch((err) => {
        alert.error('Error adding Screener');
      });
  };

  const removeAssignment = () => {
    const evaluator_pks = selectedEvaluators.map((item) => {
      return item.pk_User;
    });
    setIsUpdating(true);
    getTokenSilently()
      .then((token) => {
        deleteData(
          'department/season/users/prescreen',
          { pk_Department: dContext.department.pk_Department, pk_Season: dContext.season.pk_Season },
          { candidatePks: selectedCandidates, evaluatorPks: evaluator_pks || [] },

          formatBearerToken(token),
        )
          .then((res) => {
            fetchEvaluators();
            setShowPreScreen(false);
            getCandidates();
            getAllCandidatesData(() => {
              setIsUpdating(false);
            });
            alert.success('Removed Screener successfully');
          })
          .catch((err) => {
            alert.error('Error removing screener');
          });
      })
      .catch((err) => {
        alert.error('Error removing screener');
      });
  };

  const filteredEvaluators = evaluators
    .filter((evaluator) => {
      const name = `${evaluator.UserLast}, ${evaluator.UserFirst}`;

      return name.toLowerCase().indexOf(searchString.toLowerCase()) >= 0;
    })
    .filter((u) => {
      const { Deleted, EnableEvaluator } = u;
      return !Deleted && EnableEvaluator;
    });

  return (
    <div>
      <div>
        <InputGroup>
          <InputGroupAddon addonType="prepend">
            <Button
              onClick={() => {
                setSearchString('');
              }}
            >
              <FontAwesomeIcon icon={faTimes} />
            </Button>
          </InputGroupAddon>
          <Input
            type="text"
            placeholder="Search. . ."
            onChange={(e) => {
              setSearchString(e.target.value);
            }}
          />
          <InputGroupAddon addonType="append">
            <Button color="success">
              <FontAwesomeIcon icon={faSearch} />
            </Button>
          </InputGroupAddon>
        </InputGroup>
      </div>
      <div style={style.evaluatorList}>
        {isFetching ? (
          <div style={{ textAlign: 'center' }}>Loading . . .</div>
        ) : (
          filteredEvaluators.map((evaluator, i) => {
            const { Deleted, EnableEvaluator } = evaluator;
            const isSelected = selectedEvaluators.find((item) => {
              return item.pk_User == evaluator.pk_User;
            });
            const backgroundColor = isSelected ? '#caecfa' : i % 2 == 0 ? '#dee2e6' : null;
            return (
              <div
                style={{ ...style.listItem, backgroundColor }}
                onClick={() => {
                  const newSelectedEvaluators = selectedEvaluators.slice();
                  if (isSelected) {
                    newSelectedEvaluators.splice(
                      newSelectedEvaluators.findIndex((item) => {
                        return item.pk_User == evaluator.pk_User;
                      }),
                      1,
                    );
                  } else {
                    newSelectedEvaluators.push(evaluator);
                  }
                  setSelectedEvaluators(newSelectedEvaluators);
                }}
              >
                <div>
                  <FontAwesomeIcon icon={isSelected ? faCheckSquare : faSquare} />
                </div>
                <div style={{ textAlign: 'center', width: '100%' }}>{`${evaluator.UserLast}, ${evaluator.UserFirst} (${
                  evaluator.assignedCandidates ? evaluator.assignedCandidates.length : 0
                })`}</div>
              </div>
            );
          })
        )}
      </div>
      <div>
        <div>
          <Button
            color="success"
            disabled={selectedEvaluators.length <= 0}
            style={style.evaluatorListButton}
            onClick={() => {
              assignEvaluators();
              setShowPreScreen(false);
            }}
          >
            Set Assignment
          </Button>
        </div>
        <div>
          <Button
            color="danger"
            style={style.evaluatorListButton}
            disabled={selectedEvaluators.length <= 0}
            onClick={() => {
              removeAssignment();
              setShowPreScreen(false);
            }}
          >
            Remove Assignment
          </Button>
        </div>
      </div>
    </div>
  );
};

export default PreScreenPopover;
