import moment from 'moment';
import React, { Fragment } from 'react';
import parse from 'html-react-parser';
import sanitizeHtml from 'sanitize-html';
import { sanitizeWithOptions } from '../../../../../../Common.functions';
import style from './AdminEmailPreview.style';
import './AdminEmailPreview.style.css';

const AdminEmailPreview = ({ itemToPreview = {}, mode = 'sent' }) => {
  const { isOpened, Body, Subject, DateSent, FirstName, LastName, index, EmailSentTo } = itemToPreview || {};

  const createMarkUp = () => {
    return { __html: sanitizeWithOptions(Body) };
  };

  const renderSentTo = () => {
    if (!LastName && !FirstName && !EmailSentTo) {
      return '';
    }
    return `${LastName || ''} ${FirstName ? `, ${FirstName}` : ''} (${EmailSentTo || ''})`;
  };

  return (
    <Fragment>
      {mode === 'sent' ? (
        <div>
          <div style={style.simpleRow}>
            <div style={{ ...style.simpleColumn, width: 100 }}>
              <strong>Subject:</strong>
            </div>
            <div style={style.simpleColumn}>{Subject || ''}</div>
          </div>
          <div style={style.simpleRow}>
            <div style={{ ...style.simpleColumn, width: 100 }}>
              <strong>Date Sent:</strong>
            </div>
            <div style={style.simpleColumn}>
              {DateSent ? moment(DateSent).format('dddd, MMMM DD, YYYY hh:mm A') : ' '}
            </div>
          </div>
          <div style={style.simpleRow}>
            <div style={{ ...style.simpleColumn, width: 100 }}>
              <strong>Sent To:</strong>
            </div>
            <div style={style.simpleColumn}>{renderSentTo()}</div>
          </div>
        </div>
      ) : null}
      <div class="row">
        <div class="col-12">
          <div
            style={{
              width: '100%',
              minHeight: 'calc(100vh - 450px)',
              backgroundColor: 'aliceblue',
              borderRadius: '10px',
              paddingTop: 20,
              paddingBottom: 20,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'top',
              // justifyContent: 'center',
              display: 'flex',
              //alignItems: 'center',
            }}
          >
            {mode === 'sent' || !Body ? (
              parse(sanitizeWithOptions(Body || ''))
            ) : (
              <div dangerouslySetInnerHTML={createMarkUp()}></div>
            )}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default AdminEmailPreview;
