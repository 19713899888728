import { faBan, faClipboardList, faFileAlt, faIdCard, faPortrait, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState, useEffect, useContext } from 'react';
import {
  Button,
  ButtonGroup,
  Card,
  CardBody,
  CardHeader,
  Modal,
  ModalBody,
  ModalHeader,
  UncontrolledTooltip,
} from 'reactstrap';
import moment from 'moment';
import Portfolio from '../../../Body/Evaluate/Portfolio';
import AdminRankResponses from './AdminRankResponses/AdminRankResponses';
import { fetchDataAgnostic, putData } from '../../../../../Services/dataApi';
import {
  formatBearerToken,
  listUserNames,
  roundToDecimalPlace,
  scrollToElement,
} from '../../../../../Common.functions';
import style from '../../AdminCandidates/CandidateWidget/AdditionalDetails/style';
import { useAuth0 } from '../../../../../auth0/reactAuth0Spa';
import Input from 'reactstrap/lib/Input';
import { useAlert } from 'react-alert';
import styled from 'styled-components';

import './AdminRankDetails.style.css';
import Swal from 'sweetalert2';

const AdminRankDetails = ({
  selectedUser,
  confirmRemoval,
  dataTypes,
  dContext,
  token,
  mode,
  getActiveCandidates,
  isGettingCandidateDetails,
  candidateCount,
  setDetailNotesHaveChange,
}) => {
  const [activeTab, setActiveTab] = useState(1);

  const [isGettingResponses, setIsGettingResponses] = useState(false);
  const [responses, setResponses] = useState([]);
  const [oldPk_Candidate, setOldPk_Candidate] = useState();
  const [notes, setNotes] = useState();
  const [isSaving, setIsSaving] = useState();
  const [showLargePhoto, setShowLargePhoto] = useState();

  const alert = useAlert();

  const {
    FirstName,
    LastName,
    DateOfInterview,
    AwardsAndRecognitionsMedicalSchool,
    PhotoUrl,
    EvalPosition,
    CandidateFinalScoreAsDecimal,
    UsersWhoMarkedDoNotRank,
  } = selectedUser || {};
  const ApplicantName = `${LastName || ''}${FirstName ? `, ${FirstName}` : ''}${
    DateOfInterview ? ` (${moment(DateOfInterview).format('MM/DD/YYYY')})` : ''
  }`;

  const { getTokenSilently } = useAuth0();
  const { season } = dContext || {};
  const { EnablePrescreenMode } = season || {};

  useEffect(() => {
    window.addEventListener('keyup', (event) => {
      if (event.key === 'Escape' && !showLargePhoto) {
        setShowLargePhoto(false);
      }
    });
  }, []);

  useEffect(() => {
    if (selectedUser) {
      const notesValue = notes || '';
      const DiscussionNotesValue = selectedUser.DiscussionNotes || '';
      setDetailNotesHaveChange(notesValue !== DiscussionNotesValue);
    }
  }, [notes]);

  useEffect(() => {
    if (selectedUser) {
      if (selectedUser.pk_Candidate != oldPk_Candidate) {
        getCandidateResponses(selectedUser);
        setOldPk_Candidate(selectedUser.pk_Candidate);
      }

      setNotes(selectedUser.DiscussionNotes || '');
    }
  }, [selectedUser]);

  useEffect(() => {
    if (selectedUser && mode) {
      getCandidateResponses(selectedUser);
    }
  }, [mode]);

  const getCandidateResponses = (candidate) => {
    if (candidate.pk_Candidate == null) {
      return;
    }
    setIsGettingResponses(true);
    setResponses([]);
    getTokenSilently()
      .then((token) => {
        fetchDataAgnostic(
          'department/candidates/questions',
          {
            pk_Candidate: candidate.pk_Candidate,
            pk_Department: dContext.department.pk_Department,
            pk_Season: dContext.season.pk_Season,
            type: mode == 1 ? 'evaluation' : 'prescreen',
          },
          formatBearerToken(token),
        )
          .then((res) => {
            setIsGettingResponses(false);
            setResponses(res.data);
          })
          .catch((err) => {
            console.log('getCandidateResponses err: ', err);
            setIsGettingResponses(false);
          });
      })
      .catch((err) => {
        console.log('getCandidateResponses getToken err: ', err);
      });
  };

  const renderContent = () => {
    switch (activeTab) {
      case 1:
        return <Portfolio selectedUser={selectedUser} dataTypes={dataTypes} isLoading={isGettingCandidateDetails} />;
      case 2:
        return <div>Documents</div>;
      case 3:
        return (
          <AdminRankResponses
            selectedUser={selectedUser}
            responses={responses}
            isGettingResponses={isGettingResponses}
            dContext={dContext}
            getCandidateResponses={getCandidateResponses}
            mode={mode}
          />
        );

      case 4:
        return (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              height: '100%',
              width: '100%',
            }}
          >
            <div
              style={{ maxHeight: 'calc(100vh - 590px)', overflow: 'scroll', display: 'flex', flexDirection: 'row' }}
            >
              <Input
                disabled={isSaving || isGettingCandidateDetails}
                type="textarea"
                rows={10}
                value={notes}
                placeholder="Notes. . ."
                onChange={(e) => {
                  setNotes(e.target.value);
                }}
              />
            </div>

            <Button
              disabled={isSaving}
              color="success"
              style={{ marginTop: 10 }}
              onClick={() => {
                saveUserNotes();
              }}
            >
              Save
            </Button>
          </div>
        );
    }
  };

  const processButtonColors = (btnValue) => {
    const isSelected = activeTab == btnValue;
    return {
      backgroundColor: isSelected ? '#d9f5ff' : null,
      color: isSelected ? 'black' : null,
    };
  };

  const handleTabChange = (newTab) => {
    if (activeTab == 4) {
      const userNotes = selectedUser.DiscussionNotes || '';
      if (notes !== userNotes) {
        Swal.fire({
          title: 'You have unsaved notes.',
          text: 'Are you sure you want discard your changes?',
          showCancelButton: true,
          confirmButtonText: `Discard`,
          confirmButtonColor: 'red',
          icon: 'warning',
        }).then((result) => {
          if (result.isConfirmed) {
            setNotes(selectedUser.DiscussionNotes);
            setActiveTab(newTab);
          }
        });
      } else {
        setActiveTab(newTab);
      }
    } else {
      setActiveTab(newTab);
    }
  };

  const saveUserNotes = () => {
    const { pk_Candidate } = selectedUser;
    setIsSaving(true);
    getTokenSilently()
      .then((token) => {
        putData(
          'candidate',
          { pk_Department: dContext.department.pk_Department },
          {
            CandidatePks: [pk_Candidate],
            fieldsToUpdate: {
              DiscussionNotes: notes,
            },
          },
          formatBearerToken(token),
        )
          .then((res) => {
            alert.success('Notes Saved!');
            setIsSaving(false);

            getActiveCandidates();
          })
          .catch((err) => {
            alert.error('Saving failed!');
            setIsSaving(false);
          });
      })
      .catch((err) => {
        alert.error('Saving failed!');
        setIsSaving(false);
      });
  };

  return (
    <Card style={{ width: '100%', minHeight: 600, height: 'calc(100vh - 300px)' }}>
      <CardHeader style={{ padding: 5, paddingLeft: '10px' }}>
        <div class="row">
          <div
            class="col"
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
            }}
          >
            <h4 class="mb-2 mt-2">Details</h4>
          </div>
        </div>
      </CardHeader>

      <CardBody class="card-body" style={{ padding: 0 }}>
        <div style={{ ...style.simpleColumn, width: '100%', padding: 0, height: '100%' }}>
          <div
            style={{
              ...style.simpleRow,
              justifyContent: 'center',
              height: '160px',
              marginBottom: 0,
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <div style={{ ...style.simpleColumn, width: '100%' }}>
              <div style={{ ...style.simpleRow, marginBottom: 0 }}>
                <div
                  class="text-center"
                  style={{
                    verticalAlign: 'middle',
                    alignItems: 'center',
                    display: 'flex',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    width: 140,
                  }}
                >
                  {PhotoUrl ? (
                    <img
                      class="rounded-circle"
                      alt="100x120"
                      style={{ width: '100px', height: '100px', cursor: 'pointer' }}
                      src={PhotoUrl}
                      data-holder-rendered="true"
                      onClick={() => {
                        setShowLargePhoto(true);
                      }}
                    />
                  ) : (
                    <FontAwesomeIcon
                      icon={faPortrait}
                      class="rounded-circle"
                      style={{ width: '100px', height: '100px' }}
                    />
                  )}
                </div>
                <div
                  style={{
                    verticalAlign: 'left',
                    padding: '0px',
                    textAlign: 'left',
                    minHeight: 55,
                    // overflowY: 'scroll',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    width: '100%',
                    display: 'flex',
                  }}
                >
                  <div style={{ fontSize: 15, fontWeight: 'bold', textAlign: 'left' }}>{ApplicantName}</div>
                  <div style={{ fontSize: 13 }}>{AwardsAndRecognitionsMedicalSchool}</div>
                  <div
                    style={{ fontSize: 13, fontWeight: 'bold', cursor: 'pointer' }}
                    onClick={() => {
                      scrollToElement(`row_${selectedUser.pk_Candidate}_2`);
                    }}
                  >
                    Current Position:{' '}
                    {EvalPosition != null ? (
                      EvalPosition
                    ) : (
                      <span style={{ color: 'red' }} id={`rank_details_position`}>
                        ??
                      </span>
                    )}
                    /{candidateCount}
                    {EvalPosition != null ? null : (
                      <UncontrolledTooltip target="rank_details_position"> No rank position found.</UncontrolledTooltip>
                    )}
                  </div>
                  <div>
                    {mode == 2 ? 'Prescreen Score' : 'Final Score'}:{' '}
                    {CandidateFinalScoreAsDecimal ? roundToDecimalPlace(CandidateFinalScoreAsDecimal * 100, 2) : 0}
                  </div>
                  <div>
                    <Button
                      disabled={isGettingCandidateDetails}
                      size="sm"
                      color="danger"
                      class="btn btn-danger fas fa-ban"
                      onClick={(e) => {
                        e.preventDefault();
                        confirmRemoval();
                      }}
                    >
                      <FontAwesomeIcon icon={faBan} style={{ marginRight: 10 }} />
                      Exclude From Rank
                    </Button>
                  </div>
                </div>
              </div>
            </div>
            {UsersWhoMarkedDoNotRank && UsersWhoMarkedDoNotRank.length > 0 ? (
              <div
                id={`DNR_Details`}
                style={{ ...style.simpleColumn, width: 80, justifyContent: 'center', alignItems: 'center' }}
              >
                <div
                  style={{
                    ...style.simpleRow,
                    alignItems: 'center',
                    backgroundColor: 'red',
                    borderRadius: 40,
                    color: 'white',
                    fontSize: 15,
                    fontWeight: 'bold',
                    height: 40,
                    justifyContent: 'center',
                    textAlign: 'center',
                    width: 40,
                    marginBottom: 0,
                  }}
                >
                  {/* {UsersWhoMarkedDoNotRank.length} */}
                  {mode == 1 ? 'DNR' : 'DNI'}
                </div>
                <UncontrolledTooltip target="DNR_Details" style={{ width: 150 }}>
                  <div>Users who marked Do Not Rank:</div>
                  {listUserNames(UsersWhoMarkedDoNotRank)}
                </UncontrolledTooltip>
              </div>
            ) : (
              ''
            )}
          </div>
          <div style={{ ...style.simpleRow }}>
            <ButtonGroup style={{ width: '100%' }}>
              <Button
                disabled={isGettingCandidateDetails}
                style={{ width: '33.3333%', ...processButtonColors(1) }}
                onClick={() => {
                  handleTabChange(1);
                }}
              >
                <FontAwesomeIcon icon={faIdCard} style={{ marginRight: 10 }} />
                Details
              </Button>

              <Button
                disabled={isGettingCandidateDetails}
                style={{ width: '33.3333%', ...processButtonColors(3) }}
                onClick={() => {
                  handleTabChange(3);
                }}
              >
                <FontAwesomeIcon icon={faFileAlt} style={{ marginRight: 10 }} />
                Responses
              </Button>

              <Button
                disabled={isGettingCandidateDetails}
                style={{ width: '33.3333%', ...processButtonColors(4) }}
                onClick={() => {
                  handleTabChange(4);
                }}
              >
                <FontAwesomeIcon icon={faClipboardList} style={{ marginRight: 10 }} />
                {notes && notes.length > 0
                  ? notes.split(/\r?\n/).filter((n) => {
                      return n;
                    }).length || ''
                  : ''}{' '}
                Notes
              </Button>
            </ButtonGroup>
          </div>
          <div
            style={{
              ...style.simpleRow,
              minHeight: 285,
              height: '100%',
              overflowY: activeTab != 1 ? 'scroll' : 'clip',
            }}
          >
            {renderContent()}
          </div>
        </div>
      </CardBody>
      <Modal
        centered
        style={{ width: 'auto' }}
        contentClassName="large_photo_modal"
        isOpen={showLargePhoto}
        onClose={() => {
          setShowLargePhoto(false);
        }}
      >
        {/* <ModalHeader
          className="large_photo_modal_header"
          toggle={() => {
            setShowLargePhoto(false);
          }}
        ></ModalHeader> */}
        <div>
          <FontAwesomeIcon
            icon={faTimes}
            style={{ float: 'right', cursor: 'pointer', color: 'white', fontSize: 30 }}
            onClick={() => {
              setShowLargePhoto(false);
            }}
          />
        </div>
        <div style={{ padding: 10, backgroundColor: 'white' }}>
          <img
            src={PhotoUrl}
            style={{ maxWidth: '70vw', height: 'auto', maxHeight: '70vh', width: 'auto' }}
            onClick={() => {
              setShowLargePhoto(false);
            }}
          />
        </div>
      </Modal>
    </Card>
  );
};

export default AdminRankDetails;
