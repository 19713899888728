const style = {
  mainContainer: {
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'row',
    padding: 5,
    paddingTop: 10,
    paddingBottom: 10,
    width: '100%',
  },
  simpleColumn: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  simpleRow: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
  },
  dateLabel: {
    fontWeight: 'bold',
    fontSize: 15,
  },
  minorText: {
    fontSize: 12,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    paddingRight: 10,
  },
};

export default style;
