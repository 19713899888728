import {
  faCheckSquare,
  faEye,
  faSave,
  faSquare,
  faTrash,
  faVolumeUp,
  faPencilAlt,
  faCheck,
  faTimes,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState, useContext } from 'react';
import BellSound from '../../../../../../assets/sounds/shipbell.wav';
import { Button, Modal, ModalHeader, ModalBody } from 'reactstrap';
import style from './NotificationItem.style';
import { clone } from '../../../../../../Services/schedule';
import Swal from 'sweetalert2';
import { useAlert } from 'react-alert';
import EditNotificationModal from '../EditNotificationModal/EditNotificationModal';
import { deleteData } from '../../../../../../Services/dataApi';
import { formatBearerToken } from '../../../../../../Common.functions';
import { useAuth0 } from '../../../../../../auth0/reactAuth0Spa';
import { DepartmentContext } from '../../../../../../DepartmentWrapper';

const NotificationItem = ({ notification, index, updateNotification, deleteNotification, getNotifications }) => {
  const [showEditNotificationModal, setShowEditNotificationModal] = useState(false);

  const [Sound, setSound] = useState('Bell');
  const [Message, setMessage] = useState('');
  const [Minutes, setMinutes] = useState(0);

  const [EnableAlertEvaluator, setEnableAlertEvaluator] = useState(true);
  const [EnableAlertCandidate, setEnableAlertCandidate] = useState(true);
  const [Type, setType] = useState('end_of_passing');

  const alert = useAlert();
  const { getTokenSilently } = useAuth0();
  const dContext = useContext(DepartmentContext);

  useEffect(() => {
    if (notification) {
      const {
        Minutes = 0,
        Type, // meeting_end, block_end
        EnableAlertEvaluator,
        EnableAlertCandidate,
        notifEndsMeeting,
        Message,
        Sound,
      } = notification || {};

      setSound(Sound);
      setMessage(Message);
      setEnableAlertEvaluator(EnableAlertEvaluator);
      setEnableAlertCandidate(EnableAlertCandidate);
      setType(Type);
      setMinutes(Minutes);
    }
  }, [notification]);

  const checkForChanges = () => {
    const currentValues = {
      Minutes,
      // Type, // meeting_end, block_end
      EnableAlertEvaluator,
      EnableAlertCandidate,
      Message,
      Sound,
    };

    let hasChanges = false;
    Object.keys(currentValues).forEach((key) => {
      if (currentValues[key] != notification[key]) {
        hasChanges = true;
      }
    });

    return hasChanges;
  };

  const notifTest = () => {
    let soundToPlay = BellSound;

    switch (Sound) {
      case 'Bell':
        soundToPlay = BellSound;
        break;
      default:
        soundToPlay = BellSound;
        break;
    }

    let audio = new Audio(soundToPlay);
    audio.volume = 0.9;
    new Notification(Message);

    if (Sound !== 'silent') {
      audio.play();
    }
  };

  const deleteNotif = () => {
    getTokenSilently()
      .then((token) => {
        deleteData(
          'department/alerts',
          {
            pk_Department: dContext.department.pk_Department,
            pk_Alert: notification.pk_Alert,
          },
          null,
          formatBearerToken(token),
        )
          .then((res) => {
            alert.success('Deleted Notification!');
            getNotifications();
          })
          .catch((err) => {
            alert.error('Delete Failed!');
          });
      })
      .catch((err) => {});
  };

  const renderEditNotificationModal = () => {
    return (
      <Modal isOpen={showEditNotificationModal} centered={true} style={{ maxWidth: '60%' }}>
        <ModalHeader
          closeButton
          toggle={() => {
            setShowEditNotificationModal(!showEditNotificationModal);
          }}
        >
          Edit Notification
        </ModalHeader>
        <ModalBody>
          <EditNotificationModal
            notification={notification}
            getNotifications={getNotifications}
            setShowEditNotificationModal={setShowEditNotificationModal}
          />
        </ModalBody>
      </Modal>
    );
  };

  return (
    <div
      style={{
        ...style.simpleRow,
        // marginBottom: 10,
        padding: 10,
        backgroundColor: index % 2 == 0 ? '#c7cbd1' : 'white',
      }}
    >
      <div style={{ ...style.simpleColumn, width: 50, textAlign: 'center' }}>
        <Button
          color="danger"
          size="sm"
          style={{ width: 40, marginRight: 10 }}
          onClick={() => {
            Swal.fire({
              title: 'Warning!',
              text: `Are you sure you want to delete ${Message}?`,
              type: 'warning',
              showCancelButton: true,
              confirmButtonColor: 'red',
              confirmButtonText: `Delete`,
            }).then((result) => {
              if (result.value) {
                deleteNotif();
              }
            });
          }}
        >
          <FontAwesomeIcon icon={faTrash} />
        </Button>
      </div>
      <div style={{ ...style.simpleColumn, width: '40%', textAlign: 'center' }}>
        <div>{Message}</div>
        <div style={{ fontSize: 10, fontWeight: 'bold' }}>
          {Minutes} Minutes before {Type}
        </div>
      </div>
      <div
        style={{
          ...style.simpleColumn,
          width: '15%',
          marginRight: 10,
          textAlign: 'center',
          color: EnableAlertEvaluator ? 'green' : 'red',
        }}
      >
        <div style={{ ...style.simpleRow, justifyContent: 'center' }}>
          <FontAwesomeIcon icon={EnableAlertEvaluator ? faCheck : faTimes} style={{ fontSize: 28 }} />
        </div>
      </div>
      <div
        style={{
          ...style.simpleColumn,
          width: '15%',
          marginRight: 10,
          textAlign: 'center',
          color: EnableAlertCandidate ? 'green' : 'red',
        }}
      >
        <div style={{ ...style.simpleRow, justifyContent: 'center' }}>
          <FontAwesomeIcon icon={EnableAlertCandidate ? faCheck : faTimes} style={{ fontSize: 28 }} />
        </div>
      </div>
      <div style={{ ...style.simpleColumn, width: '20%', textAlign: 'center' }}>
        <div style={style.simpleRow}>
          <Button
            color="warning"
            size="sm"
            style={{ width: 150, marginRight: 10 }}
            onClick={() => {
              notifTest();
              alert.info(`${Message}`);
            }}
          >
            Test
          </Button>{' '}
          <Button
            color="primary"
            size="sm"
            style={{ width: 40, marginRight: 10 }}
            onClick={() => {
              setShowEditNotificationModal(!showEditNotificationModal);
            }}
          >
            <FontAwesomeIcon icon={faPencilAlt} />
          </Button>
        </div>
      </div>

      {/* <div style={{ ...style.simpleColumn, width: '80%' }}>{Message}</div>
      <div style={{ ...style.simpleColumn, width: 150, marginRight: 10 }}>
        <Button
          color="warning"
          size="sm"
          // disabled={Sound === 'silent'}
          onClick={() => {
            notifTest();
            alert.info(`${Message}`);
          }}
        >
          Test
        </Button>
      </div>
      <div style={{ ...style.simpleColumn, width: 50 }}>
        <Button
          color="primary"
          size="sm"
          style={{ width: 40, marginRight: 10 }}
          onClick={() => {
            setShowEditNotificationModal(!showEditNotificationModal);
          }}
        >
          <FontAwesomeIcon icon={faPencilAlt} />
        </Button>
      </div>
      <div style={{ ...style.simpleColumn, width: 50 }}>
      <Button
          color="danger"
          size="sm"
          style={{ width: 40, marginRight: 10 }}
          onClick={() => {
            Swal.fire({
              title: 'Warning!',
              text: `Are you sure you want to delete ${Message}?`,
              type: 'warning',
              showCancelButton: true,
              confirmButtonColor: 'red',
              confirmButtonText: `Delete`,
            }).then((result) => {
              if (result.value) {
                deleteNotif();
              }
            });
          }}
        >
          <FontAwesomeIcon icon={faTrash} />
        </Button>
      </div> */}
      {renderEditNotificationModal()}
    </div>
  );
};

export default NotificationItem;
