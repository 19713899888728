import React, { useState, useEffect } from 'react';
import {
  faBan,
  faBars,
  faCheck,
  faCheckSquare,
  faCrosshairs,
  faDoorOpen,
  faEye,
  faEyeSlash,
  faInfoCircle,
  faLink,
  faMagic,
  faSquare,
  faTimesCircle,
  faTrashAlt,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import ConfirmModal from '../ConfirmModal';

import FocusPalette from '../FocusPalette';

import { Input, Button, Popover, PopoverBody, PopoverHeader, Label } from 'reactstrap';
import style from './style';
import './HeaderDetailDropdown.style.css';
import HeaderUnavailablePanel from '../HeaderUnavailablePanel';
import {
  clone,
  countScheduledCandidates,
  getScheduleStartAndEndTime,
  isCandidateWillBeUnscheduled,
} from '../../../../../Services/schedule';
import { useAlert } from 'react-alert';
import Swal from 'sweetalert2';
import moment from 'moment';
import VirtualRoomPopover from '../VirtualRoomPopover/VirtualRoomPopover';
import RoomPropertiesPopover from '../RoomPropertiesPopover/RoomPropertiesPopover';
import { faUser } from '@fortawesome/free-regular-svg-icons';

let nE = [];

const HeaderDropDown = ({
  addUnavailableSlot,
  columnTracker,
  dContext,
  deleteFlexEvent,
  evaluators,
  fetchSchedule,
  generateScheduleForColumn,
  headers,
  isSubmitting,
  onClearColumn,
  onDeleteColumn,
  onUpdateEvaluators,
  scheduleData,
  selectedHeader,
  setColumnSplit,
  setIsChanged,
  setSaveHook,
  setShowHeaderPopOver,
  setShowSplitCellConfirmModal,
  showHeaderPopOver,
  splitCountTracker,
  token,
  updateFlexEvent,
  fireConfirmationForEditingPastSchedule,
  dateIsInThePast,
  willEditThePast,
  scheduleID,
  addUnavailableSlots,
  updateFlexEvents,
}) => {
  const [allowEditFocus, setAllowEditFocus] = useState(false);
  const [existingEvaluators, setExistingEvaluators] = useState([]);
  const [newEvaluators, setNewEvaluators] = useState([]);
  const [availableEvaluators, setAvailableEvaluators] = useState([]);
  const [showUnavailable, setShowUnavailable] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [roomLabel, setRoomLabel] = useState();
  const [showFocusPopover, setShowFocusPopover] = useState(false);
  const [showRoomNumberPopover, setShowRoomNumberPopover] = useState(false);
  const [showSplitPopover, setShowSplitPopover] = useState(false);
  const [showZoomURLPopover, setShowZoomURLPopover] = useState(false);
  const [unavailabilityPanelHasChanges, setUnavailabilityPanelHasChanges] = useState(false);
  // ==================== NEW ======================= //
  const [showEvaluatorsPopover, setShowEvaluatorsPopover] = useState(false);
  const [showRoomPropertiesPopover, setShowRoomPropertiesPopover] = useState(false);

  const [isUpdatingEvaluators, setIsUpdatingEvaluators] = useState(false);

  const popoverLevels = {
    showUnavailable: 2,
  };

  // ================================================ //

  const [showConfirmClearPopover, setShowConfirmClearPopover] = useState(false);
  const [showUnavailabilityEvaluatorPicker, setShowUnavailabilityEvaluatorPicker] = useState(false);

  const [focuses, setFocuses] = useState([]);

  const [splitCount, setSplitCount] = useState(0);
  const [maxSplitCount, setMaxSplitCount] = useState(1);

  const alert = useAlert();

  useEffect(() => {
    if (setSaveHook) {
      setSaveHook({ onSave, onClear }, selectedHeader.dataField);
    }

    if (selectedHeader) {
      const { rawHeader } = selectedHeader;
      if (rawHeader && rawHeader.Evaluators) {
        const newSplitCount =
          selectedHeader && selectedHeader.rawHeader && selectedHeader.rawHeader.ColumnTotalNumberSplits != null
            ? selectedHeader.rawHeader.ColumnTotalNumberSplits
            : 1;
        const newExistingEvaluators = [];
        rawHeader.Evaluators.Evaluators.forEach((e) => {
          newExistingEvaluators.push(e.pk_User);
        });

        setExistingEvaluators(newExistingEvaluators);
        setNewEvaluators(newExistingEvaluators);

        setSplitCount(newSplitCount);
        setRoomLabel(selectedHeader.label);
        if (selectedHeader.rawHeader.RoomFocuses) {
          setFocuses(selectedHeader.rawHeader.RoomFocuses);
        }
      }
    }
  }, [JSON.stringify(selectedHeader)]);

  useEffect(() => {
    if (scheduleData && selectedHeader) {
      const col = parseInt(selectedHeader.dataField);
      const { StandardDurationInMinutes, StandardPassingDurationInMinutes } = scheduleData.metaData;
      // const standardDuration = parseInt(StandardDurationInMinutes) + parseInt(StandardPassingDurationInMinutes);

      let lowestSplitCount = null;
      scheduleData.body.forEach((row) => {
        const { CustomDurationInMinutes = 0, CustomPassingDurationInMinutes = 0 } = row[col];
        // const customDuration = parseInt(CustomDurationInMinutes) + parseInt(CustomPassingDurationInMinutes);
        const duration = CustomDurationInMinutes
          ? parseInt(CustomDurationInMinutes)
          : parseInt(StandardDurationInMinutes);
        const passingDuration = CustomPassingDurationInMinutes
          ? parseInt(CustomPassingDurationInMinutes)
          : parseInt(StandardPassingDurationInMinutes);

        if (lowestSplitCount == null || duration + passingDuration < lowestSplitCount) {
          lowestSplitCount = duration + passingDuration;
        }
      });

      setMaxSplitCount(lowestSplitCount);
    }
  }, [scheduleData, selectedHeader]);

  useEffect(() => {
    if (newEvaluators) {
      nE = newEvaluators.slice();
    }
  }, [newEvaluators]);

  useEffect(() => {
    const newAvailableEvaluators = { ...evaluators };
    let headerEvaluators = [];

    if (headers) {
      headers.forEach((header) => {
        if (
          header.Evaluators &&
          header.Evaluators.Evaluators &&
          header.pk_ScheduleAssignment != selectedHeader.dataField
        ) {
          headerEvaluators = [...headerEvaluators, ...header.Evaluators.Evaluators];
        }
      });

      headerEvaluators.forEach((h) => {
        if (newAvailableEvaluators[h.pk_User]) {
          delete newAvailableEvaluators[h.pk_User];
        }
      });
    }

    setAvailableEvaluators(newAvailableEvaluators);
  }, [headers]);

  useEffect(() => {
    handleParentPopoverVisibilityTracker('showFocusPopover', showFocusPopover);
  }, [showFocusPopover]);

  useEffect(() => {
    handleParentPopoverVisibilityTracker('showUnavailable', showUnavailable);
  }, [showUnavailable]);

  useEffect(() => {
    handleParentPopoverVisibilityTracker('showRoomNumberPopover', showRoomNumberPopover);
  }, [showRoomNumberPopover]);

  useEffect(() => {
    handleParentPopoverVisibilityTracker('showSplitPopover', showSplitPopover);
  }, [showSplitPopover]);

  useEffect(() => {
    handleParentPopoverVisibilityTracker('showZoomURLPopover', showZoomURLPopover);
  }, [showZoomURLPopover]);

  useEffect(() => {
    handleParentPopoverVisibilityTracker('showEvaluatorsPopover', showEvaluatorsPopover);
  }, [showEvaluatorsPopover]);

  useEffect(() => {
    handleParentPopoverVisibilityTracker('showRoomPropertiesPopover', showRoomPropertiesPopover);
  }, [showRoomPropertiesPopover]);

  useEffect(() => {
    handleParentPopoverVisibilityTracker('showConfirmClearPopover', showConfirmClearPopover);
  }, [showConfirmClearPopover]);

  const meetingInProgress = () => {
    let isMeetingInProgress = false;
    if (scheduleData) {
      const { StartTime, EndTime } = getScheduleStartAndEndTime(scheduleData);
      const now = moment(); //.format('MMM DD, YYYY hh:mm A');
      isMeetingInProgress = now.isSameOrAfter(StartTime) && now.isSameOrBefore(EndTime); //isTimeRangeOverlap([StartTime, now], [now, EndTime]);
    }

    return isMeetingInProgress;
  };

  // To not close header when clicking child popover
  const handleParentPopoverVisibilityTracker = (key, value) => {
    const { dataField } = selectedHeader;
    const newShowHeaderPopOver = clone(showHeaderPopOver);
    const visibility = showHeaderPopOver[dataField];
    visibility[key] = value;
    newShowHeaderPopOver[dataField] = visibility;
    setShowHeaderPopOver(newShowHeaderPopOver);
  };

  const closeOtherPopovers = (popover) => {
    switch (popover) {
      case 'roomNumberPopover':
        setShowUnavailable(false);
        setShowFocusPopover(false);
        setShowSplitPopover(false);
        setShowZoomURLPopover(false);
        break;
      case 'focusPopover':
        setShowUnavailable(false);
        setShowRoomNumberPopover(false);
        setShowSplitPopover(false);
        setShowZoomURLPopover(false);
        break;
      case 'unavailable':
        setShowFocusPopover(false);
        setShowRoomNumberPopover(false);
        setShowSplitPopover(false);
        setShowZoomURLPopover(false);
        break;
      case 'splitColumnPopover':
        setShowFocusPopover(false);
        setShowRoomNumberPopover(false);
        setShowUnavailable(false);
        setShowZoomURLPopover(false);
        break;
      case 'editRoomURLPopover':
        setShowFocusPopover(false);
        setShowRoomNumberPopover(false);
        setShowUnavailable(false);
        setShowSplitPopover(false);
        break;
    }
  };

  const onSave = () => {
    const toAdd = [];
    const toDelete = [];

    if (selectedHeader) {
      setIsUpdatingEvaluators(true);
      const { rawHeader, column, dataField } = selectedHeader;

      if (rawHeader && rawHeader.Evaluators) {
        const newExistingEvaluators = []; // Current evaluators.
        rawHeader.Evaluators.Evaluators.forEach((e) => {
          newExistingEvaluators.push(e.pk_User);
        });

        // nE is checked evaluators. newEvaluators doesn't work, due to saveHook setup.
        nE.forEach((e) => {
          if (newExistingEvaluators.indexOf(e) < 0) {
            toAdd.push(e);
          }
        });
        // check if there are current evaljuators that are no longer checked,
        // meaning, to delete.
        newExistingEvaluators.forEach((e) => {
          if (nE.indexOf(e) < 0) {
            toDelete.push(e);
          }
        });
      } else {
        nE.forEach((evaluator) => {
          if (!toAdd.includes(evaluator)) {
            toAdd.push(evaluator);
          }
        });
      }

      onUpdateEvaluators(
        {
          evaluatorsToAdd: toAdd,
          evaluatorsToDelete: toDelete,
          column: selectedHeader,
          pk_ScheduleAssignment: selectedHeader.dataField,
        },
        () => {
          setIsUpdatingEvaluators(false);
        },
      );
    }
  };

  const onClear = () => {
    if (selectedHeader) {
      const { rawHeader } = selectedHeader;
      if (rawHeader && rawHeader.Evaluators) {
        const newExistingEvaluators = [];
        rawHeader.Evaluators.Evaluators.forEach((e) => {
          newExistingEvaluators.push(e.pk_User);
        });
        setExistingEvaluators(newExistingEvaluators);
        setNewEvaluators(newExistingEvaluators);
      }
    }
    // setNewEvaluators([...existingEvaluators]);
  };

  const isSame = (a, b) => a.length === b.length && a.every((v, i) => b.indexOf(v) >= 0);

  const renderUnavailablePopover = () => {
    const key = `button_${selectedHeader.dataField}_unavailabilityButton`;
    const targetExists = document.getElementById(key);
    if (targetExists) {
      return (
        <Popover
          id="unavailablePopover"
          placementPrefix="unavailablePopover bs-popover"
          isOpen={showUnavailable} // Change with room id later
          target={`button_${selectedHeader.dataField}_unavailabilityButton`} // Change with room id later
          trigger="legacy"
          modifiers={{ flip: { behavior: ['right'] } }}
          placement="right"
          toggle={async (e) => {
            if (dateIsInThePast && !willEditThePast) {
              const continueProcess = await fireConfirmationForEditingPastSchedule();
              if (!continueProcess) {
                return;
              }
            }

            const unavailability_box = document.getElementById('unavailablePopover');
            let hasPickerOpen = false;
            Object.values(showUnavailabilityEvaluatorPicker).forEach((v) => {
              if (v) {
                hasPickerOpen = true;
              }
            });

            if (
              (unavailability_box && unavailability_box.contains(e.target)) ||
              hasPickerOpen ||
              e.target.id === 'unavailable_confirm_delete' ||
              e.target.id === 'unavailable_confirm_delete_button'
            ) {
              return;
            }
            if (unavailabilityPanelHasChanges) {
              Swal.fire({
                title: 'Unsaved Changes',
                text: 'You have unsaved changes. Are you sure you want to discard these? You cannot undo this.',
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: 'red',
                confirmButtonText: `Yes`,
              }).then((response) => {
                if (response.isConfirmed) {
                  if (!showUnavailable) {
                    closeOtherPopovers('unavailable');
                  }

                  setShowUnavailable(!showUnavailable);
                }
              });
            } else {
              if (!showUnavailable) {
                closeOtherPopovers('unavailable');
              }

              setShowUnavailable(!showUnavailable);
            }
          }}
        >
          <PopoverHeader>
            <div style={style.spacedBetweenRow}>
              Unavailability for {selectedHeader.text || ''}
              <div>
                <div style={style.simpleRow}>
                  <FontAwesomeIcon
                    icon={faTimesCircle}
                    style={style.clickableIcon}
                    onClick={(e) => {
                      setShowUnavailable(false);
                    }}
                  />
                </div>
              </div>
            </div>
          </PopoverHeader>
          <PopoverBody>
            <HeaderUnavailablePanel
              setShowUnavailabilityEvaluatorPicker={setShowUnavailabilityEvaluatorPicker}
              showUnavailabilityEvaluatorPicker={showUnavailabilityEvaluatorPicker}
              header={selectedHeader}
              fetchSchedule={fetchSchedule}
              addUnavailableSlot={addUnavailableSlot}
              dContext={dContext}
              token={token}
              updateFlexEvent={updateFlexEvent}
              deleteFlexEvent={deleteFlexEvent}
              scheduleData={scheduleData}
              fireConfirmationForEditingPastSchedule={fireConfirmationForEditingPastSchedule}
              dateIsInThePast={dateIsInThePast}
              willEditThePast={willEditThePast}
              scheduleID={scheduleID}
              addUnavailableSlots={addUnavailableSlots}
              updateFlexEvents={updateFlexEvents}
              setUnavailabilityPanelHasChanges={setUnavailabilityPanelHasChanges}
            />
          </PopoverBody>
        </Popover>
      );
    } else {
      return;
    }
  };

  const renderSplitColumnPopover = () => {
    const defaultTotalSplits =
      selectedHeader && selectedHeader.rawHeader && selectedHeader.rawHeader.ColumnTotalNumberSplits != null
        ? selectedHeader.rawHeader.ColumnTotalNumberSplits
        : 1;
    return (
      <Popover
        placementPrefix="roomNumberPopover bs-popover"
        modifiers={{ flip: { behavior: ['right'] } }}
        placement="right"
        isOpen={showSplitPopover} // Change with room id later
        target={`button_${selectedHeader.dataField}_splitButton`} // Change with room id later
        trigger="legacy"
        toggle={(e) => {
          if (!showSplitPopover) {
            closeOtherPopovers('splitColumnPopover');
          }
          setShowSplitPopover(!showSplitPopover);
        }}
      >
        <PopoverHeader>
          <div style={style.spacedBetweenRow}>
            Split Column
            <FontAwesomeIcon
              icon={faTimesCircle}
              style={style.clickableIcon}
              onClick={(e) => {
                setShowSplitPopover(false);
              }}
            />
          </div>
        </PopoverHeader>
        <PopoverBody>
          <Input
            style={{ textAlign: 'center' }}
            type="number"
            placeholder="Enter number of splits"
            defaultValue={defaultTotalSplits}
            value={splitCount}
            onClick={async () => {
              if (dateIsInThePast && !willEditThePast) {
                const continueProcess = await fireConfirmationForEditingPastSchedule();
                if (!continueProcess) {
                  return;
                }
              }
            }}
            onChange={(e) => {
              let newValue = parseInt(e.target.value);
              if (newValue < 1) {
                newValue = 1;
              }

              if (newValue > maxSplitCount) {
                newValue = maxSplitCount;
              }

              setSplitCount(newValue);
            }}
          />
          <div style={style.roomNumberButtons}>
            <Button
              color="danger"
              style={{ ...style.roomNumberButton }}
              onClick={() => {
                setShowSplitPopover(false);
              }}
            >
              Cancel
            </Button>
            <Button
              color="danger"
              style={style.roomNumberButton}
              onClick={async () => {
                const { dataField } = selectedHeader;
                const { body } = scheduleData;
                const timeSlots = [];
                if (dateIsInThePast && !willEditThePast) {
                  const continueProcess = await fireConfirmationForEditingPastSchedule();
                  if (!continueProcess) {
                    return;
                  }
                }
                const data = {
                  pk_ScheduleAssignment: parseInt(selectedHeader.dataField),
                  TotalNumberOfSplits: 1,
                  column: selectedHeader,
                };

                body.forEach((timeSlot) => {
                  timeSlots.push(timeSlot.pk_Timeslot);
                });

                const willCandidateBeUnscheduled = isCandidateWillBeUnscheduled(splitCountTracker, {
                  timeSlots,
                  headers: [parseInt(dataField)],
                  splitValue: 1,
                });

                if (willCandidateBeUnscheduled) {
                  setShowSplitCellConfirmModal(true);
                  return;
                }
                setColumnSplit(data, (err) => {
                  if (!err) {
                    setShowSplitPopover(false);
                  }
                });
              }}
            >
              Remove
            </Button>
            <Button
              style={{ ...style.roomNumberButton, ...style.genButton }}
              onClick={async () => {
                if (dateIsInThePast && !willEditThePast) {
                  const continueProcess = await fireConfirmationForEditingPastSchedule();
                  if (!continueProcess) {
                    return;
                  }
                }
                const { dataField } = selectedHeader;
                const { body } = scheduleData;
                const timeSlots = [];

                const data = {
                  pk_ScheduleAssignment: parseInt(dataField),
                  TotalNumberOfSplits: splitCount,
                  column: selectedHeader,
                };

                body.forEach((timeSlot) => {
                  timeSlots.push(timeSlot.pk_Timeslot);
                });

                const willCandidateBeUnscheduled = isCandidateWillBeUnscheduled(splitCountTracker, {
                  timeSlots,
                  headers: [parseInt(dataField)],
                  splitValue: splitCount,
                });

                if (willCandidateBeUnscheduled) {
                  setShowSplitCellConfirmModal(true);
                  setSplitCount(defaultTotalSplits);
                  return;
                }

                setColumnSplit(data, (err) => {
                  if (!err) {
                    setShowSplitPopover(false);
                  }
                });
              }}
            >
              Set
            </Button>
          </div>
        </PopoverBody>
      </Popover>
    );
  };

  const renderShowConfirmClearModal = () => {
    return (
      <ConfirmModal
        title="Clear Column"
        message={`Are you sure you want to clear ${`column "${selectedHeader.text}"` || 'the selected column'}?`}
        isOpen={showConfirmClearPopover}
        onAccept={() => {
          onClearColumn([selectedHeader.dataField], (res) => {
            if (res) {
              setShowConfirmClearPopover(false);
              alert.success('Column cleared!');
              // setTimeout(() => {
              //   fetchSchedule();
              // }, 3000);
            } else {
              alert.error('Clear failed!');
            }
          });
        }}
        onCancel={() => {
          setShowConfirmClearPopover(false);
        }}
      />
    );
  };

  const renderEditRoomURLPopover = () => {
    const { department } = dContext;
    const { VirtualMeetingMode } = department;

    if (VirtualMeetingMode !== 'ExternalColumn') {
      return;
    }
    if (selectedHeader && selectedHeader.rawHeader) {
      const { rawHeader = {} } = selectedHeader;
    }

    return (
      <Popover
        placementPrefix="roomNumberPopover bs-popover"
        isOpen={showZoomURLPopover}
        modifiers={{ flip: { behavior: ['right'] } }}
        placement="right"
        target={`button_${selectedHeader.dataField}_editRoomURLBtn`} // Change with room id later
        trigger="legacy"
        toggle={(e) => {
          // `button_${selectedHeader.dataField}_editRoomURLBtn`
          if (!showZoomURLPopover) {
            closeOtherPopovers('editRoomURLPopover');
          }
          setShowZoomURLPopover(!showZoomURLPopover);
        }}
      >
        <PopoverHeader>
          <div style={style.spacedBetweenRow}>
            Virtual Room
            <FontAwesomeIcon
              icon={faTimesCircle}
              style={style.clickableIcon}
              onClick={(e) => {
                setShowZoomURLPopover(false);
              }}
            />
          </div>
        </PopoverHeader>
        <PopoverBody>
          <VirtualRoomPopover
            header={selectedHeader}
            onUpdateEvaluators={onUpdateEvaluators}
            setShowZoomURLPopover={setShowZoomURLPopover}
          />
        </PopoverBody>
      </Popover>
    );
  };

  const renderEvaluatorsPopover = () => {
    return (
      <Popover
        placementPrefix="roomNumberPopover bs-popover"
        isOpen={showEvaluatorsPopover}
        modifiers={{ flip: { behavior: ['right'] } }}
        placement="right"
        target={`button_${selectedHeader.dataField}_evaluatorsBtn`} // Change with room id later
        trigger="legacy"
        toggle={(e) => {
          let hasPickerOpen = false;

          Object.values(showUnavailabilityEvaluatorPicker).forEach((v) => {
            if (v) {
              hasPickerOpen = true;
            }
          });

          if (hasPickerOpen || showUnavailable) {
            return;
          }
          if (!showEvaluatorsPopover) {
            closeOtherPopovers('showEvaluatorsPopover');
          }
          setShowEvaluatorsPopover(!showEvaluatorsPopover);
        }}
      >
        <PopoverHeader>
          <div style={style.spacedBetweenRow}>
            Evaluators for {selectedHeader.text || ''}
            <FontAwesomeIcon
              icon={faTimesCircle}
              style={style.clickableIcon}
              onClick={(e) => {
                setShowEvaluatorsPopover(false);
              }}
            />
          </div>
        </PopoverHeader>
        <PopoverBody>
          <div>
            <div style={style.userList}>
              {Object.values(availableEvaluators)
                .sort((a, b) => {
                  const aString = `${a.UserLast || ''}, ${a.UserFirst || ''}`.toLowerCase();
                  const bString = `${b.UserLast || ''}, ${b.UserFirst || ''}`.toLowerCase();
                  return aString > bString ? 1 : -1;
                })
                // .filter((u) => {
                //   const { Delete, EnableEvaluator } = u;
                //   return !Delete && EnableEvaluator;
                // })
                .map((evaluator, index) => {
                  const { Deleted, EnableEvaluator } = evaluator;
                  const isExisting = existingEvaluators && existingEvaluators.indexOf(evaluator.pk_User) >= 0;

                  const isNewSelection = newEvaluators.indexOf(evaluator.pk_User) >= 0;

                  let backgroundColor = null;

                  if (isExisting && isNewSelection) {
                    backgroundColor = '#c4d5ff';
                  }

                  if (isExisting && !isNewSelection) {
                    backgroundColor = '#f77777';
                  }

                  if (!isExisting && isNewSelection) {
                    backgroundColor = '#88ff82';
                  }

                  if (Deleted || !EnableEvaluator) {
                    return;
                  }

                  return (
                    <div
                      key={evaluator.pk_User}
                      style={{ ...style.userItem, ...style.spacedBetweenRow, backgroundColor: backgroundColor }}
                      onClick={async () => {
                        if (dateIsInThePast && !willEditThePast) {
                          const continueProcess = await fireConfirmationForEditingPastSchedule();
                          if (!continueProcess) {
                            return;
                          }
                        }

                        const newNewEvaluators = [...newEvaluators];
                        if (isNewSelection) {
                          newNewEvaluators.splice(newEvaluators.indexOf(evaluator.pk_User), 1);
                        } else {
                          newNewEvaluators.push(evaluator.pk_User);
                        }

                        setNewEvaluators(newNewEvaluators);
                        setIsChanged(!isSame(existingEvaluators, newNewEvaluators), selectedHeader.dataField);
                      }}
                    >
                      <FontAwesomeIcon icon={isNewSelection ? faCheckSquare : faSquare} />
                      <div
                        style={{
                          ...style.buttonLabel,
                          textDecoration: !isNewSelection && isExisting ? 'line-through' : null,
                        }}
                      >
                        {evaluator ? ` ${evaluator.UserLast || ''}, ${evaluator.UserFirst || ''}` : ''}
                      </div>
                    </div>
                  );
                })}
            </div>
            <div>
              <div style={style.simpleColumn} id={`button_${selectedHeader.dataField}_unavailabilityButton`}>
                <Button color="danger" style={{ ...style.genButton, backgroundColor: null, width: '100%' }}>
                  <div style={style.spacedBetweenRow}>
                    <FontAwesomeIcon icon={faBan} />
                    <div style={style.buttonLabel}> Set Unavailability</div>
                  </div>
                </Button>
              </div>
            </div>
            <div>
              <div style={style.simpleColumn} id={`button_${selectedHeader.dataField}_save`}>
                <Button
                  disabled={isUpdatingEvaluators}
                  color="success"
                  style={{ ...style.genButton, backgroundColor: null, width: '100%' }}
                  onClick={async () => {
                    if (dateIsInThePast && !willEditThePast) {
                      const continueProcess = await fireConfirmationForEditingPastSchedule();
                      if (!continueProcess) {
                        return;
                      }
                    }
                    onSave();
                  }}
                >
                  <div style={style.spacedBetweenRow}>
                    <FontAwesomeIcon icon={faCheck} />
                    <div style={style.buttonLabel}> Save </div>
                  </div>
                </Button>
              </div>
            </div>
          </div>

          {renderUnavailablePopover()}
        </PopoverBody>
      </Popover>
    );
  };

  const renderRoomPropertiesPopover = () => {
    return (
      <Popover
        placementPrefix="roomPropertiesPopover bs-popover"
        isOpen={showRoomPropertiesPopover}
        target={`button_${selectedHeader.dataField}_roomPropertiesBtn`}
        trigger="legacy"
        modifiers={{ flip: { behavior: ['right'] } }}
        placement="right"
        flip={false}
        toggle={(e) => {
          if (showUnavailabilityEvaluatorPicker || showUnavailable) {
            return;
          }
          if (!showRoomPropertiesPopover) {
            closeOtherPopovers('showRoomPropertiesPopover');
          }
          setShowRoomPropertiesPopover(!showRoomPropertiesPopover);
        }}
      >
        <PopoverHeader>
          <div style={style.spacedBetweenRow}>
            Room Properties
            <FontAwesomeIcon
              icon={faTimesCircle}
              style={style.clickableIcon}
              onClick={(e) => {
                setShowRoomPropertiesPopover(false);
              }}
            />
          </div>
        </PopoverHeader>
        <PopoverBody>
          <div>
            <RoomPropertiesPopover
              allowEdit={allowEditFocus}
              hasDelay={false}
              header={selectedHeader}
              maxSplitCount={maxSplitCount}
              onUpdateEvaluators={onUpdateEvaluators}
              reference={focuses}
              returnEntireFocusObject={true}
              setShowRoomPropertiesPopover={setShowRoomPropertiesPopover}
              fireConfirmationForEditingPastSchedule={fireConfirmationForEditingPastSchedule}
              dateIsInThePast={dateIsInThePast}
              willEditThePast={willEditThePast}
              updateSelectedFocuses={(newFocuses) => {
                setFocuses(newFocuses);
              }}
            />
          </div>
        </PopoverBody>
      </Popover>
    );
  };

  return (
    <div
      style={style.dropDownBody}
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <div>
        <div style={style.simpleColumn}>
          <div style={style.simpleRow}>
            <Button
              color="primary"
              style={{ ...style.genButton, width: '100%', backgroundColor: null }}
              id={`button_${selectedHeader.dataField}_evaluatorsBtn`}
            >
              <div style={style.spacedBetweenRow}>
                <FontAwesomeIcon icon={faUser} />
                <div style={style.buttonLabel}>Evaluators</div>
              </div>
            </Button>
          </div>
          <div style={style.simpleRow}>
            <Button
              color="primary"
              style={{ ...style.genButton, width: '100%', backgroundColor: null }}
              id={`button_${selectedHeader.dataField}_roomPropertiesBtn`}
            >
              <div style={style.spacedBetweenRow}>
                <FontAwesomeIcon icon={faDoorOpen} />
                <div style={style.buttonLabel}>Room Properties</div>
              </div>
            </Button>
          </div>
          <div style={style.simpleColumn}>
            <Button style={{ ...style.genButton, width: '100%' }} id={`button_${selectedHeader.dataField}_splitButton`}>
              <div style={style.spacedBetweenRow}>
                <FontAwesomeIcon icon={faBars} />
                <div style={style.buttonLabel}> Split Column</div>
              </div>
            </Button>
          </div>
          <div style={style.simpleRow}>
            <div style={{ ...style.simpleColumn, width: '50%', paddingRight: 5 }}>
              <Button
                color="danger"
                onClick={async (e) => {
                  if (dateIsInThePast && !willEditThePast) {
                    const continueProcess = await fireConfirmationForEditingPastSchedule();
                    if (!continueProcess) {
                      return;
                    }
                  }
                  Swal.fire({
                    title: 'Warning',
                    text: `Are you sure you want to delete column?`,
                    type: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: 'red',
                    confirmButtonText: `Delete`,
                  }).then((result) => {
                    if (result.value) {
                      onDeleteColumn(selectedHeader);
                    }
                  });
                }}
                style={{ ...style.genButton, backgroundColor: null, width: '100%' }}
              >
                <div style={style.spacedBetweenRow}>
                  <FontAwesomeIcon icon={faTrashAlt} />
                  <div style={style.buttonLabel}> Delete Column</div>
                </div>
              </Button>
            </div>

            <div style={{ ...style.simpleColumn, width: '50%', paddingLeft: 5 }}>
              <Button
                color="none"
                style={{ ...style.genButton, backgroundColor: 'orange', width: '100%' }}
                id={`button_${selectedHeader.dataField}_clearColumn`}
                onClick={async () => {
                  if (dateIsInThePast && !willEditThePast) {
                    const continueProcess = await fireConfirmationForEditingPastSchedule();
                    if (!continueProcess) {
                      return;
                    }
                  }
                  setShowConfirmClearPopover(true);
                }}
              >
                <div style={style.spacedBetweenRow}>
                  <FontAwesomeIcon icon={faBan} />
                  <div style={style.buttonLabel}> Clear Column</div>
                </div>
              </Button>
            </div>
          </div>
          <div style={style.simpleRow}>
            <Button
              style={{ ...style.genButton, width: '100%' }}
              onClick={async () => {
                if (dateIsInThePast && !willEditThePast) {
                  const continueProcess = await fireConfirmationForEditingPastSchedule();
                  if (!continueProcess) {
                    return;
                  }
                }
                const candidates = columnTracker[parseInt(selectedHeader.dataField)];

                if (meetingInProgress() && candidates.length > 0) {
                  Swal.fire({
                    title: 'Meeting already in progress!',
                    text: 'The day is in progress, you may be adding interviews that have already passed!',
                    type: 'info',
                    showCancelButton: true,
                    confirmButtonText: `Fill`,
                    confirmButtonColor: 'green',
                    cancelButtonText: 'Cancel',
                    cancelButtonColor: 'grey',
                  }).then((result) => {
                    /* Read more about isConfirmed, isDenied below */
                    if (result.value) {
                      generateScheduleForColumn([parseInt(selectedHeader.dataField)]);
                    }
                  });
                  return;
                }

                if (columnTracker && candidates && candidates.length > 0) {
                  setShowConfirmModal(true);
                } else {
                  generateScheduleForColumn([parseInt(selectedHeader.dataField)]);
                  // generateScheduleForColumn([1782, 1785, 1781]);
                }
              }}
            >
              <div style={style.spacedBetweenRow}>
                <FontAwesomeIcon icon={faMagic} />
                <div style={style.buttonLabel}> Auto Fill Column</div>
              </div>
            </Button>
          </div>
        </div>

        {
          // <div style={style.simpleColumn}>
          //   <Button
          //     style={{ ...style.genButton, width: '100%' }}
          //     onClick={() => {
          //       const candidates = columnTracker[parseInt(selectedHeader.dataField)];
          //       if (meetingInProgress() && candidates.length > 0) {
          //         Swal.fire({
          //           title: 'Meeting already in progress!',
          //           text: 'The day is in progress, you may be adding interviews that have already passed!',
          //           type: 'info',
          //           showCancelButton: true,
          //           confirmButtonText: `Fill`,
          //           confirmButtonColor: 'green',
          //           cancelButtonText: 'Cancel',
          //           cancelButtonColor: 'grey',
          //         }).then((result) => {
          //           /* Read more about isConfirmed, isDenied below */
          //           if (result.value) {
          //             generateScheduleForColumn([parseInt(selectedHeader.dataField)]);
          //           }
          //         });
          //         return;
          //       }
          //       if (columnTracker && candidates && candidates.length > 0) {
          //         setShowConfirmModal(true);
          //       } else {
          //         generateScheduleForColumn([parseInt(selectedHeader.dataField)]);
          //         // generateScheduleForColumn([1782, 1785, 1781]);
          //       }
          //     }}
          //   >
          //     <div style={style.spacedBetweenRow}>
          //       <FontAwesomeIcon icon={faMagic} />
          //       <div style={style.buttonLabel}> Auto Fill Column</div>
          //     </div>
          //   </Button>
          // </div>
          // <div style={style.simpleColumn} id={`button_${selectedHeader.dataField}_unavailabilityButton`}>
          //   <Button color="danger" style={{ ...style.genButton, backgroundColor: null, width: '100%' }}>
          //     <div style={style.spacedBetweenRow}>
          //       <FontAwesomeIcon icon={faBan} />
          //       <div style={style.buttonLabel}> Set Unavailability</div>
          //     </div>
          //   </Button>
          // </div>
          // <div style={style.simpleColumn}>
          //   <Button
          //     style={{ ...style.genButton, width: '100%', backgroundColor: null }}
          //     id={`button_${selectedHeader.dataField}_roomNumberButton`}
          //   >
          //     <div style={style.spacedBetweenRow}>
          //       <FontAwesomeIcon icon={faDoorOpen} />
          //       <div style={style.buttonLabel}>
          //         {' '}
          //         {selectedHeader && selectedHeader.label ? 'Modify' : 'Add'} Room Label
          //       </div>
          //     </div>
          //   </Button>
          // </div>
          // <div style={style.simpleColumn}>
          //   <Button
          //     color="primary"
          //     style={{ ...style.genButton, width: '100%', backgroundColor: null }}
          //     id={`button_${selectedHeader.dataField}_editRoomURLBtn`}
          //   >
          //     <div style={style.spacedBetweenRow}>
          //       <FontAwesomeIcon icon={faLink} />
          //       <div style={style.buttonLabel}>Virtual Room</div>
          //     </div>
          //   </Button>
          // </div>
          // <div style={style.simpleColumn}>
          //   <Button style={{ ...style.genButton, width: '100%' }} id={`button_${selectedHeader.dataField}_focusButton`}>
          //     <div style={style.spacedBetweenRow}>
          //       <FontAwesomeIcon icon={faCrosshairs} />
          //       <div style={style.buttonLabel}> Add/Modify Focus</div>
          //     </div>
          //   </Button>
          // </div>
        }
      </div>

      {renderShowConfirmClearModal()}
      {renderEvaluatorsPopover()}
      {renderRoomPropertiesPopover()}
      {renderSplitColumnPopover()}
      {/* {renderRoomNumberPopover()}
      {renderFocusPopover()}
     
     
      
      {renderEditRoomURLPopover()} */}
      <ConfirmModal
        isOpen={showConfirmModal}
        title="Alert - Existing Candidates!"
        message="Auto-filling this column would overwrite existing Candidates in it. Do you wish to proceed?"
        onAccept={() => {
          generateScheduleForColumn([selectedHeader.dataField]);

          setShowConfirmModal(false);
        }}
        onCancel={() => {
          setShowConfirmModal(false);
        }}
      />
    </div>
  );
};

export default HeaderDropDown;
