import { faCalendarCheck, faClipboard, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Input, Label } from 'reactstrap';
import { useAuth0 } from '../../../../../../auth0/reactAuth0Spa';
import { formatBearerToken } from '../../../../../../Common.functions';
import { DepartmentContext } from '../../../../../../DepartmentWrapper';
import { postDataAgnostic, putData } from '../../../../../../Services/dataApi';
import ConfirmModal from '../../../../Coordinator/Schedule/ConfirmModal/index';
import style from './ ManagePopover.style';

const ManagePopover = ({ interviewDate, candidate, getCandidates, hidePopover }) => {
  const [scheduleNotes, setScheduleNotes] = useState();
  const [showOverBookedAlert, setShowOverBookedAlert] = useState(false);
  const [showAlreadyScheduledAlert, setShowAlreadyScheduledAlert] = useState(false);
  const [isScheduling, setIsScheduling] = useState(false);

  const {
    CountOfCandidates,
    tags,
    EnableTagLimit,
    CandidateRegistrationLimit,
    isScheduled,
    DateOfInterview,
  } = interviewDate;

  const history = useHistory();
  const dContext = useContext(DepartmentContext);
  const { getTokenSilently } = useAuth0();

  let tagLimitCount = 0;
  let color = 'success';
  let isOverBooked = false;

  //   if (EnableTagLimit) {
  //     tags.forEach((tag) => {
  //       tagLimitCount += tag.InterviewDateLimit;
  //     });
  //     if (tagLimitCount > CountOfCandidates) {
  //       isOverBooked = true;
  //       color = 'danger';
  //     }
  //   } else

  if (CountOfCandidates >= CandidateRegistrationLimit) {
    isOverBooked = true;
    color = 'danger';
  }

  if (isScheduled) {
    color = 'danger';
  }

  const scheduleCandidate = () => {
    const { department, season } = dContext;
    setIsScheduling(true);
    getTokenSilently().then((token) => {
      putData(
        'candidate',
        {
          pk_Department: department.pk_Department,
        },
        {
          CandidatePks: [candidate.pk_Candidate],
          fieldsToUpdate: {
            pk_InterviewDate: interviewDate.pk_InterviewDate,
          },
        },
        formatBearerToken(token),
      )
        .then((res) => {
          setIsScheduling(false);
          getCandidates();
          hidePopover();
        })
        .catch((err) => {
          setIsScheduling(false);
        });
    });
  };

  const renderOverBookedAlert = () => {
    const message = `Selected date "${moment(DateOfInterview).format('dddd, MMM. DD, YYYY')}" is ${
      CountOfCandidates > CandidateRegistrationLimit
        ? 'over the limit.'
        : CountOfCandidates == CandidateRegistrationLimit
        ? ' at limit.'
        : ''
    } (${CountOfCandidates || 0}/${CandidateRegistrationLimit || 0}). Proceed anyway?`;

    return (
      <ConfirmModal
        title={'Overbooked!'}
        message={message}
        isOpen={showOverBookedAlert}
        onAccept={() => {
          scheduleCandidate();
          setShowOverBookedAlert(false);
        }}
        onCancel={() => {
          setShowOverBookedAlert(false);
        }}
      />
    );
  };

  const renderAlreadyScheduledAlert = () => {
    const message = candidate
      ? `${candidate.LastName} is already assigned to an interview date - ${moment(candidate.DateOfInterview).format(
          'dddd, MMM. DD, YYYY',
        )}. Do you want to reassign anyway?`
      : '';
    return (
      <ConfirmModal
        title={'Already Scheduled!'}
        message={message}
        isOpen={showAlreadyScheduledAlert}
        onAccept={() => {
          scheduleCandidate();
          setShowAlreadyScheduledAlert(false);
        }}
        onCancel={() => {
          setShowAlreadyScheduledAlert(false);
        }}
      />
    );
  };

  return (
    <div style={style.simpleColumn}>
      <Button
        color="primary"
        style={style.button}
        onClick={() => {
          history.push(`/administrator/candidates?pk_Candidate=${candidate.pk_Candidate}`);
        }}
      >
        <div style={style.spacedBetweenRow}>
          <FontAwesomeIcon icon={faClipboard} />
          <div style={style.centeredText}>View Candidate profile</div>
        </div>
      </Button>
      <Button color="primary" style={style.button}>
        <div style={style.spacedBetweenRow}>
          <FontAwesomeIcon icon={faEnvelope} />
          <div style={style.centeredText}>Email Candidate</div>
        </div>
      </Button>
      <Button
        color={color}
        style={style.button}
        disabled={isScheduling}
        onClick={() => {
          if (isOverBooked) {
            setShowOverBookedAlert(true);
          } else if (isScheduled) {
            setShowAlreadyScheduledAlert(true);
          } else {
            scheduleCandidate();
          }
        }}
      >
        <div style={style.spacedBetweenRow}>
          <FontAwesomeIcon icon={faCalendarCheck} />
          <div style={style.centeredText}>
            Schedule for{' '}
            {interviewDate && interviewDate.DateOfInterview
              ? moment(interviewDate.DateOfInterview).format('ddd, MMM DD, YYYY')
              : ''}
          </div>
        </div>
      </Button>
      <div>
        <Label for={`scheduleNotes`}>Schedule Notes</Label>
        <Input
          id="scheduleNotes"
          type="textarea"
          rows={5}
          value={scheduleNotes}
          placeholder="Scheule Notes. . ."
          onChange={(e) => {
            setScheduleNotes(e.target.value);
          }}
        />
      </div>
      {renderOverBookedAlert()}
      {renderAlreadyScheduledAlert()}
    </div>
  );
};

export default ManagePopover;
