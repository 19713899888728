const style = {
  scroll: {
    marginBottom: '10px',
    height: 'calc(100% - 150px)',
  },
  wrapper: {
    border: '1px solid rgb(51, 51, 51)',
    height: 'calc(100vh - 255px)',
  },
  wrapperHeight: {
    height: 'calc(100vh - 250px)',
  },
  departmentWrapperWidget: {
    border: '1px solid rgb(51, 51, 51)',
    padding: '15px',
    height: 'calc(100vh - 295px)',
    overflowY: 'auto',
  },
  headerWrapper: {
    backgroundColor: 'rgb(51, 51, 51)',
    color: 'white',
    padding: '10px',
    textAlign: 'center',
  },
  blueBackground: '#F1FFFF',
  whiteBackground: '#FFFFFF',
};

export default style;
