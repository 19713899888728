import { connect } from 'react-redux';
import { setActiveDepartment } from '../RezRATE/Body/Department/Department.action';
import { setUser } from '../InterviewDates/InterviewDatesRouter.action';
import Login from './Login';
import { setEmail } from './Login.action';
import { setDate, setDateArray } from 'Body/RezRATE/Header/Header.action';

const mapStateToProps = (state) => ({
  email: state.login.email,
  user: state.userInfo.user,
});

const mapDispatchToProps = (dispatch) => ({
  setEmail: (emailObject) => dispatch(setEmail(emailObject)),
  setActiveDepartment: (department) => dispatch(setActiveDepartment(department)),
  setReduxDate: (date) => dispatch(setDate(date)),
  setReduxDateArray: (dates) => dispatch(setDateArray(dates)),
  setUser: (user) => dispatch(setUser(user)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
