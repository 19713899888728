import React from 'react';
import InterviewDateConfirm from './InterviewDatesSteps/InterviewDateConfirm';

const InterviewConfirmRouter = ({
  changeStep,
  listArray,
  clientID,
  location,
  demo,
  order,
  showButtons,
  currentSlide,
  singleDateChoice,
  setHasNoChoicesToMake,
  headerText,
  dateIsLocked,
  setCurrentSlide,
  setHasInterviewDate,
  setHasPermission,
  setHasPreferenceOrderWithNoInterviewDate,
  setError,
  setDateOfInterview,
  decrementStep,
  hasPreferenceOrderWithNoInterviewDate,
  scheduledLabel,
  setDisplayOverlay,
  dateOfInterview,
  selectedableDates,
  setHasMadeSelection,
  setSelectedableDates,
  setDateList,
  selectedDates,
  setSelectedDates,
  addToSelectedDates,
}) => {
  return (
    <div style={{ display: 'flex', width: '100%', height: '100%' }}>
      <InterviewDateConfirm
        addToSelectedDates={addToSelectedDates}
        changeStep={changeStep}
        clientID={clientID}
        currentSlide={currentSlide}
        dateIsLocked={dateIsLocked}
        dateOfInterview={dateOfInterview}
        decrementStep={decrementStep}
        demo={demo}
        hasPreferenceOrderWithNoInterviewDate={hasPreferenceOrderWithNoInterviewDate}
        headerText={headerText}
        listArray={listArray}
        location={location}
        order={order}
        scheduledLabel={scheduledLabel}
        selectedableDates={selectedableDates}
        selectedDates={selectedDates}
        setCurrentSlide={setCurrentSlide}
        setDateList={setDateList}
        setDateOfInterview={setDateOfInterview}
        setDisplayOverlay={setDisplayOverlay}
        setError={setError}
        setHasInterviewDate={setHasInterviewDate}
        setHasMadeSelection={setHasMadeSelection}
        setHasNoChoicesToMake={setHasNoChoicesToMake}
        setHasPermission={setHasPermission}
        setHasPreferenceOrderWithNoInterviewDate={setHasPreferenceOrderWithNoInterviewDate}
        setSelectedableDates={setSelectedableDates}
        setSelectedDates={setSelectedDates}
        showButtons
        singleDateChoice={singleDateChoice}
      />
    </div>
  );
};

export default InterviewConfirmRouter;
