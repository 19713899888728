const style = {
  simpleRow: {
    display: 'flex',
    flexDirection: 'row',
  },
  simpleColumn: {
    display: 'flex',
    flexDirection: 'column',
  },
  spacedBetweenRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  label: {
    fontSize: 14,
    textAlign: 'center',
    width: '100%',
  },
  largeBoldLabel: {
    fontSize: 16,
    textAlign: 'center',
    width: '100%',
    fontWeight: 'bold',
  },
  userItem: {
    cursor: 'pointer',
    fontWeight: 600,
    paddingBottom: 5,
    paddingLeft: 5,
    paddingTop: 5,
  },
  popOverHeader: {
    textAlign: 'center',
    fontWeight: 600,
  },
};

export default style;
