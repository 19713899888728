const style = {
  simpleRow: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
  },
  simpleColumn: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
  },
  spacedBetweenRow: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'space-between',
  },
  detailRow: {
    display: 'flex',
    flexDirection: 'row',
    padding: 10,
    alignItems: 'center',
  },
};

export default style;
