import React, { useState } from 'react';
import { FormGroup, Col, Input, Label, Form, Button } from 'reactstrap';
import { useAuth0 } from '../../../auth0/reactAuth0Spa';
import Swal from 'sweetalert2';
import UserPhotoTypes from '../../DragAndDropFiles/UserPhoto/UserPhotoTypes';
import UserPhoto from '../../DragAndDropFiles/UserPhoto/UserPhoto';
import { fetchDataAgnostic, putData } from '../../../Services/dataApi';
import { formatBearerToken, swalErrorMessage } from '../../../Common.functions';

const OrganizationCreationAndEditWidget = ({
  OrganizationLogo = '',
  OrganizationName = '',
  OrganizationWebsite = '',
  OrganizationShortName = '',
  pk_Organization = '',
  OrganizationAlias = '',
  setOrganizations,
  updateOrganizationArrayValues,
}) => {
  const [organizationLogo, setOrganizationLogo] = useState(OrganizationLogo);
  const [organizationName, setOrganizationName] = useState(OrganizationName);
  const [organizationWebsite, setOrganizationWebsite] = useState(OrganizationWebsite);
  const [deptConfig, setDeptConfig] = useState({});
  const [organizationShortName, setOrganizationShortName] = useState(OrganizationShortName);
  const [pkOrganization, setPkOrganization] = useState(pk_Organization);

  const { getTokenSilently } = useAuth0();

  const handleSave = (e) => {
    e.preventDefault();
    e.preventDefault();
    getTokenSilently()
      .then((token) => {
        putData(
          'organization',
          {
            pk_Organization: pkOrganization,
          },
          {
            OrganizationName: organizationName,
            OrganizationShortName: organizationShortName,
            OrganizationWebsite: organizationWebsite,
          },
          formatBearerToken(token),
        )
          .then((res) => {
            if (res.status === 200) {
              updateOrganizationArrayValues(res.data);
              Swal.fire({
                title: 'Success!',
                text: 'Department settings have been updated!',
                icon: 'success',
              });
            } else {
              swalErrorMessage();
            }
          })
          .catch((err) => {
            swalErrorMessage();
          });
      })
      .catch((err) => {
        swalErrorMessage();
      });
  };
  return (
    <div>
      <div>
        {pk_Organization && (
          <UserPhoto
            imgStyle={{ width: 100, objectFit: 'scale-down' }}
            photoUrl={organizationLogo}
            setDeptConfig={setDeptConfig}
            pk_Organization={pk_Organization}
            callback={(id, photoURL) => {
              // handleChange('UserPhotoUrl', photoURL);
              if (photoURL) {
                setOrganizationLogo(photoURL);
              }
              setOrganizations((prevState) => {
                // eslint-disable-next-line
                let clone = structuredClone(prevState);
                const index = clone.findIndex((x) => x.pk_Organization === pk_Organization);
                if (clone[index]) {
                  clone[index].OrganizationLogo = photoURL;
                }
                return clone;
              });
            }}
            type={UserPhotoTypes.OrganizationLogo}
          />
        )}
      </div>
      <div>
        <Form>
          <FormGroup row>
            <Label for="organizationName" sm={2}>
              Name:
            </Label>
            <Col sm={10}>
              <Input
                type="text"
                name="organizationName"
                value={organizationName}
                onChange={(e) => setOrganizationName(e.target.value)}
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label for="organizationAlias" sm={2}>
              Alias:
            </Label>
            <Col sm={10}>
              <Input
                type="text"
                name="organizationAlias"
                value={organizationShortName}
                onChange={(e) => setOrganizationShortName(e.target.value)}
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label for="organizationWebsite" sm={2}>
              Website:
            </Label>
            <Col sm={10}>
              <Input
                type="text"
                name="organizationWebsite"
                value={organizationWebsite}
                onChange={(e) => setOrganizationWebsite(e.target.value)}
              />
            </Col>
          </FormGroup>
          <Button onClick={handleSave}>Save</Button>
        </Form>
      </div>
    </div>
  );
};

export default OrganizationCreationAndEditWidget;
