export const setActiveDepartment = (payload) => {
  return {
    type: 'SET_ACTIVE_DEPARTMENT',
    payload,
  };
};

export const setActiveSeason = (payload) => {
  return {
    type: 'SET_ACTIVE_SEASON',
    payload,
  };
};

export const setSeasonChoices = (payload) => {
  return {
    type: 'SET_SEASON_CHOICES',
    payload,
  };
};
