const style = {
  cellStyle: { display: 'flex', justifyContent: 'center', verticalAlign: 'middle', fontSize: 13 },
  headerStyle: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    textAlign: 'center',
    verticalAlign: 'middle',
    cursor: 'default',
    fontSize: 14,
  },
  hideInterviewDatesContainer: {
    marginBottom: 10,
    paddingTop: 5,
    paddingBottom: 5,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'bottom',
  },
  simpleRow: {
    display: 'flex',
    flexDirection: 'row',
  },
  simpleColumn: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  centeredText: {
    textAlign: 'center',
    width: '100%',
    minHeight: 20,
  },
};
export default style;
