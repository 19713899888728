import React, { useEffect, useState } from 'react';
import { Button, Col, Form, FormGroup, Input } from 'reactstrap';
import Swal from 'sweetalert2';
import { postDataAgnostic } from '../../../Services/dataApi';
import { CustomButton, EvenRow } from './InterviewDateSelection.styles';
import { wrap } from 'lodash';

const InterviewDateDecline = ({ changeStep, clientID, location, demo }) => {
  const [comment, setComment] = useState('');
  const [isMobile, setIsMobile] = useState(window.innerWidth < window.innerHeight);

  useEffect(() => {
    setIsMobile(window.innerWidth < window.innerHeight);
  }, [window.innerWidth, window.innerHeight]);
  const simulateDecline = () => {
    Swal.fire(
      'The invitaiton has been declined (not really)',
      'If this were a real entry this would inform the corresponding department you did not want to interview.',
    );
  };

  const postDecline = (e) => {
    e.preventDefault();
    const AccessID = localStorage.getItem('AccessID');
    if (AccessID === null) {
      //do the thing
    }
    postDataAgnostic(
      'candidate/decline',
      { clientID, AccessID },
      { DeclineComment: comment.length > 0 ? comment : null, isDeclining: true },
    )
      .then((results) => {
        window.location.reload();
      })
      .catch((err) => {
        Swal.fire('Error', 'Something went wrong, please try again or contact support', 'error');
      });
  };

  return (
    <Col stlye={{ height: '100%', width: '100%', justifyContent: 'center', alignItems: 'center' }}>
      <div style={{ width: '100%', display: 'flex', justifyContent: 'center', padding: isMobile ? 0 : '15px' }}>
        <Form style={{ width: isMobile ? '100%' : '80%', marginTop: 50 }}>
          <FormGroup>
            <Input
              type="textarea"
              name="text"
              id="exampleText"
              rows={isMobile ? 10 : 5}
              placeholder="Enter optional comments here..."
              value={comment}
              onChange={(e) => setComment(e.target.value)}
            />
          </FormGroup>
        </Form>
      </div>

      {/* <div>
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap' }}>
          <Button color="danger" onClick={demo ? simulateDecline : postDecline} style={{ width: 150 }}>
            Decline Invitation
          </Button>

          <Button color="warning" onClick={(e) => changeStep(e, 1)} style={{ width: 150 }}>
            Go Back
          </Button>
        </div>
      </div> */}
      <EvenRow>
        {!demo && (
          <CustomButton color="danger" onClick={postDecline}>
            Decline Invitation
          </CustomButton>
        )}
        {demo && (
          <CustomButton color="danger" onClick={simulateDecline}>
            Decline Invitation
          </CustomButton>
        )}
        <CustomButton color="warning" onClick={(e) => changeStep(e, 1)}>
          Go Back
        </CustomButton>
      </EvenRow>
    </Col>
  );
};

export default InterviewDateDecline;
