const style = {
  mainContainer: { display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' },
  clickable: { cursor: 'pointer' },
  inputAndAddButtonContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'spaced-between',
    alignItems: 'center',
    paddingBottom: 5,
    paddingTop: 5,
    marginTop: 20,
  },
  manageFieldsNewName: {
    alignItems: 'center',
    textAlign: 'center',
    width: '97%',
    marginRight: 5,
    marginLeft: 5,
  },
  centeredText: {
    width: '100%',
    textAlign: 'center',
  },
};
export default style;
