const style = {
  dropDownBody: {
    display: 'flex',
    flexDirection: 'column',
    maxHeight: 250,
    minWidth: '100%',
    overflowY: 'scroll',
  },
};

export default style;
