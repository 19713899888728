import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import imageToBase64 from 'image-to-base64';

import moment from 'moment';
import tinycolor from 'tinycolor2';
import { clone } from '../../schedule';
import Swal from 'sweetalert2';
import profilePlaceholder from '../CandidateBadges/Portrait_Placeholder.png';
import rezRateLogo from '../rezRateLogo.png';

let rezRateLogoBase64 = null;
let profilePlaceholderBase64 = null;
pdfMake.vfs = pdfFonts.pdfMake.vfs;

// Cheat Sheet
// 1 inch = 72 units.
// LETTER size is 612x792

const tableLayouts = {
  customTable: {
    hLineWidth: (i, node) => {
      return 0;
      // return 0;
      // if (i === 0 || i === node.table.body.length) {
      //   return 0;
      // }
      return i === node.table.headerRows ? 2 : 1;
    },
    vLineWidth: (i) => {
      return 0;
    },
    hLineColor: (i) => {
      return '#aaa';
      return i === 1 ? 'black' : '#aaa';
    },
    paddingLeft: (i) => {
      return 0;
    },
    paddingRight: (i, node) => {
      return 0;
    },
    paddingTop: (i, node) => {
      return 0;
    },
    paddingBottom: (i, node) => {
      return 0;
    },
  },
};

const createPDFProper = (data) => {
  const {
    interviewDate = {},
    candidates,
    department,
    departmentLogo,
    activeFields,
    questions,
    evaluators,
    imgDictionary,
    scaleData,
    rawData,
    ProgramType,
  } = data;
  const { metaData = {}, MomentDateOfInterview = moment() } = interviewDate;
  const { TimeZone, OrganizationName, DepartmentName } = department;

  evaluators.sort((a, b) => {
    const textA = `${a.UserLast}, ${a.UserFirst}`.toUpperCase();
    const textB = `${b.UserLast}, ${b.UserFirst}`.toUpperCase();

    return textA < textB ? -1 : textA > textB ? 1 : 0;
  });
  const rows = [];
  let counter = 0;

  // Renders each candidate section. Each Candidate is rendered a Card, each card containing the candidate header (photo, name, evaluator, etc), the canvas for the line divider, and the Table containing rows of questions. The table is set to NOT break rows, so an entire row consisting, question text, choices and all, get rendered together, always.
  const renderCard = (options) => {
    const {
      candidate,
      questionData,
      mainListItemIndex,
      secondaryListItemIndex,
      ProgramType,
      Type,
      evaluator,
    } = options;

    const {
      DateOfInterview,
      pk_Candidate,
      EvaluationAnswers,
      EnableDoNotRank,
      MedicalSchoolDegreeDateOfGraduation = '',
      CandidateSubHeading,
    } = candidate;

    const userPhotoBase64 = imgDictionary[pk_Candidate];
    const cardRows = [];
    const userDetailRows = [];
    const questionRows = [];
    const candidateQuestionGroups = [{ GroupName: 'Questionnaire', questions: EvaluationAnswers }];

    const { DefaultScale, EvalQuestions } = questionData;
    const { UserFirst = '', UserLast = '', pk_User } = evaluator;
    const referenceQuestionGroups = EvalQuestions; // Assume this is an array of Question Groups
    let dateToDisplay = '';
    let labelToDisplay = '';
    let candidateSubHeading = CandidateSubHeading ? CandidateSubHeading : '';

    dateToDisplay = DateOfInterview ? moment.tz(`${DateOfInterview} 8:00 AM`, null).format('MMM DD, YYYY') : '';
    const userPhoto = userPhotoBase64
      ? {
          columns: [
            {
              image: userPhotoBase64 ? `data:image/png;base64,${userPhotoBase64}` : null,
              fit: [70, 70],
            },
          ],
          margin: [0, 0, 0, 10],
        }
      : {
          columns: [
            {
              image: profilePlaceholderBase64 ? `data:image/png;base64,${profilePlaceholderBase64}` : null,
              fit: [70, 70],
            },
          ],
          margin: [0, 0, 0, 10],
        };

    userDetailRows.push([userPhoto]);

    const cardHeader = [
      {
        columns: [
          { columns: [userDetailRows], width: 210, margin: [0, 0, 10, 0], width: 80 },
          {
            columns: [
              [
                [
                  {
                    style: { alignment: 'left', bold: true },
                    text: `${candidate.LastName}, ${candidate.FirstName}`,
                    fontSize: 12,
                    margin: [0, 0, 0, 2],
                    width: 6.0 * 72,
                  },
                ],
                [
                  {
                    style: { alignment: 'left' },
                    text: `${candidateSubHeading}`,
                    fontSize: 9,
                    margin: [0, 0, 0, 2],
                    width: 6.0 * 72,
                  },
                ],
                [
                  {
                    style: { alignment: 'left' },
                    text: `${
                      ProgramType === 'Residency' && MedicalSchoolDegreeDateOfGraduation
                        ? `Graduated: ${MedicalSchoolDegreeDateOfGraduation || ' '}`
                        : '   '
                    }`,
                    fontSize: 9,
                    margin: [0, 0, 0, 2],
                    width: 6.0 * 72,
                  },
                ],
                [
                  {
                    style: { alignment: 'left', bold: false },
                    text: Type === 'evaluation' ? (dateToDisplay ? `Interviewed on: ${dateToDisplay} ` : ` `) : ` `,
                    fontSize: 9,
                    margin: [0, 0, 0, 2],
                    width: 6.0 * 72,
                  },
                ],
                [
                  {
                    style: { alignment: 'left', bold: false },
                    text: `${Type === 'evaluation' ? 'Evaluator:' : 'Screener:'} ${UserLast}, ${UserFirst}`,
                    fontSize: 9,
                    margin: [0, 0, 0, 2],
                    width: 6.0 * 72,
                  },
                ],
              ],
            ],
            width: 4.5 * 72,
          },
          {
            columns: [
              [
                [
                  {
                    style: { alignment: 'left', bold: false },
                    text: `${OrganizationName}`,
                    fontSize: 9,
                    margin: [0, 0, 0, 2],
                    width: 6.0 * 72,
                  },
                ],
                [
                  {
                    style: { alignment: 'left', bold: true },
                    text: `${DepartmentName}`,
                    fontSize: 9,
                    margin: [0, 0, 0, 2],
                    width: 6.0 * 72,
                  },
                ],
                [
                  {
                    text: `${EnableDoNotRank ? (Type == 'evaluation' ? 'DNR' : Type == 'prescreen' ? 'DNI' : ``) : ''}`,
                    style: { color: 'red', fontSize: 32, fontWeight: 'bold' },
                  },
                ],
              ],
            ],
            width: 100,
            margin: [0, 0, 0, 0],
            width: 2.5 * 72,
          },
        ],
        margin: [10, 0, 0, 0],
      },
    ];

    cardRows.push(cardHeader);

    cardRows.push({
      canvas: [{ type: 'line', x1: 0, y1: 0, x2: 570, y2: 0, lineWidth: 2, color: 'black' }],
      margin: [0, 0, 20, 0],
    });

    referenceQuestionGroups.forEach((qGroup, i) => {
      const referenceGroupName = qGroup.GroupName || `Unnamed Group ${i + 1}}`;
      const referenceQuestions =
        qGroup.questions.sort((a, b) => {
          return a.EvaluationQuestionSortOrder - b.EvaluationQuestionSortOrder;
        }) || [];

      questionRows.push([
        {
          style: { alignment: 'center', bold: true },
          text: `${Type == 'prescreen' ? 'Prescreen' : 'Evaluation'} ${referenceGroupName}`,
          fontSize: 12,
          margin: [0, 10, 0, 20],
          width: '100%',
        },
      ]);

      referenceQuestions.forEach((q, i) => {
        const { EnableEvaluationForm, EnablePrescreenForm, EvaluationQuestionSortOrder } = q;
        if ((Type === 'prescreen' && EnablePrescreenForm) || (Type === 'evaluation' && EnableEvaluationForm)) {
        } else {
          return;
        }
        const candidateQuestionGroup = candidateQuestionGroups.find((cqg) => {
          return referenceGroupName == cqg.GroupName; // Update to use pk_EvaluationQuestionGroup in the future
        });

        const cQuestionData = candidateQuestionGroup
          ? candidateQuestionGroup.questions.find(
              (cQuestion) => cQuestion.pk_EvaluationQuestion == q.pk_EvaluationQuestion,
            )
          : {};

        const { WeightQuestionType, QuestionText, CustomScale } = q; // From reference question
        const { AnswerNumber, AnswerText } = cQuestionData || {}; // candidate's matching question data

        const questionText = [
          {
            style: { alignment: 'left', bold: true },
            text: `${questionRows.length}. ${QuestionText}`,
            fontSize: 10,
            margin: [0, 10, 0, 2],
            width: '100%',
            pageHeaderData: JSON.stringify({ candidate: { ...candidate, pk_User } }),
          },
        ];

        if (WeightQuestionType === 'scale') {
          let counter = 0;
          let choices = [];
          let choicesElement = {};
          const scaleToUse = CustomScale ? CustomScale : DefaultScale;
          let defaultX = scaleToUse.length > 5 ? 24 : 50;

          scaleToUse.forEach((choice, i) => {
            const { DisplayValue, NumericValue, Label, Description } = choice;

            choices.push([
              [
                {
                  canvas: [
                    {
                      type: 'ellipse',
                      x: 30,
                      y: 30,
                      lineColor: 'gray',
                      color: AnswerNumber == NumericValue ? '#1167b1' : '#ffffff',
                      fillOpacity: 1,
                      r1: 20,
                      r2: 20,
                    },
                  ],
                  style: { alignment: 'center' },
                },
                {
                  text: `${DisplayValue || NumericValue}`,
                  fontSize: 15, // 22 - 26
                  // relativePosition: { x: NumericValue > 9 ? defaultX : defaultX + 4, y: 22 },
                  // relativePosition: { x: 30, y: 22 },
                  relativePosition: { x: 5, y: -28 },
                  style: {
                    color: AnswerNumber == NumericValue ? 'white' : 'black',
                    alignment: 'center',
                    // background: AnswerNumber == NumericValue ? '#1167b1' : '#ffffff',
                  },
                },

                {
                  text: `${Label}`,
                  fontSize: 8,
                  bold: true,
                  margin: [10, 10, 0, 0],
                  //   relativePosition: { x: 0, y: 0 },
                  style: { color: 'black', alignment: 'center', noWrap: false },
                },
                {
                  text: `${Description}`,
                  fontSize: 6,
                  margin: [10, 0, 0, 0],
                  //   relativePosition: { x: 0, y: 0 },
                  style: { color: 'black', alignment: 'center', italics: true, noWrap: false },
                },
              ],
            ]);
          });

          choicesElement = {
            columns: choices,
            margin: [0, 0, 0, 10],
          };

          if (i == referenceQuestions.length - 1) {
            // choicesElement.pageBreak = 'after';
          }

          questionRows.push([[[questionText], choicesElement]]);
        } else if (WeightQuestionType === 'text') {
          const answerElement = {
            text: `    ${AnswerText || 'No Comments Entered'}`,
            style: { italics: !AnswerText },
            margin: [40, 0, 0, 10],
            fontSize: 10,
          };

          if (i == referenceQuestions.length - 1) {
            // answerElement.pageBreak = 'after';
          }

          // Update to user Question's AnswerText instead of renderLines
          questionRows.push([[[questionText], [answerElement]]]);
        }
      });
    });

    const candidateEvaluationTable = [
      {
        table: { heights: 'auto', widths: ['100%'], body: questionRows, dontBreakRows: true },
        headerRows: 1,
        margin: [20, 0, 20, 0],
        layout: 'customTable',
        // pageBreak: 'after',
      },
    ];

    if (secondaryListItemIndex < evaluators[mainListItemIndex].Candidates.length - 1) {
      candidateEvaluationTable[0].pageBreak = 'after';
    }
    cardRows.push(candidateEvaluationTable);
    return cardRows;
  };

  evaluators.forEach((evaluator, mainListItemIndex) => {
    const { Candidates = [], Type } = evaluator;

    if (Candidates) {
      Candidates.sort((a, b) => {
        const textA = `${a.LastName}, ${a.FirstName}`.toUpperCase();
        const textB = `${b.LastName}, ${b.FirstName}`.toUpperCase();

        return textA < textB ? -1 : textA > textB ? 1 : 0;
      }).forEach((candidate, secondaryListItemIndex) => {
        rows.push(
          renderCard({
            evaluator,
            candidate,
            ProgramType,
            questionData: scaleData,
            mainListItemIndex,
            secondaryListItemIndex,
            Type,
          }),
        );
      });
    }
  });

  let pdfObject = [rows];
  const docDefinition = {
    info: { title: 'Candidate Evaluations' },
    pageSize: 'LETTER',
    pageOrientation: 'portrait',
    content: pdfObject,
    defaultStyle: {
      fontSize: 10,
    },
    pageMargins: [0.25 * 72, 0.5 * 72, 0.25 * 72, 0.5 * 72],
    footer: (currentPage, allPages) => {
      const footerData = getDataForHeaderFooter(docDefinition, currentPage);

      const { candidatePages, candidateData } = footerData;

      if (candidateData) {
        const { pk_Candidate, pk_User } = candidateData;

        const candidatePagesActual = candidatePages[`${pk_User}_${pk_Candidate}`];

        const candidatePagesCount = candidatePagesActual.length;
        const currentCandidatePage = candidatePagesActual.indexOf(currentPage) + 1;

        const { UserFirst, UserLast, Type, FirstName, LastName } = candidateData;
        return [
          {
            text: [
              {
                text: `${UserLast || ''}, ${UserFirst || ''}`,
                style: { bold: true },
              },
              { text: `${Type === 'evaluation' ? ' Evaluating ' : ' Prescreening '}` },
              { text: `${LastName || ''}, ${FirstName || ''}`, style: { bold: true } },
            ],
            alignment: 'right',
            width: '100%',
            fontSize: 8,
            margin: [0, 0, 25, 0],
          },
          {
            // text: `${currentCandidatePage} of ${candidatePagesCount}`,
            text: `Page ${currentCandidatePage}`,
            alignment: 'right',
            width: '100%',
            fontSize: 8,
            margin: [0, 0, 25, 0],
          },
        ];
      } else {
        return [
          {
            text: `${currentPage}`,
            alignment: 'right',
            width: '100%',
            fontSize: 8,
            margin: [0, 0, 25, 0],
          },
        ];
      }
    },
  };

  pdfMake.createPdf(docDefinition, tableLayouts).open({}, window.open('', '_blank'));
};

/**
 * We plant the header data we want on each element we add to the content array we put in the docDef.
 * Each element when processed, have a pageNumber value embedded deep in it. We use this function is used to get that
 * embedded header data, in this case, the candidate. The location of this data is retrieved based on the
 * @param {object} documentDefinition  contains the elements to render.
 * @param {number} currentPage what page the header is being rendered on.
 * @returns {object} Returns hte header object with all the data for header/footer, in this case,
 */
const getDataForHeaderFooter = (documentDefinition, currentPage) => {
  let headerData = {};
  let candidatePages = {}; // arrays of page numbers involved with a candidate

  documentDefinition.content.forEach((questionGroup, questionGroupIndex) => {
    questionGroup.stack.forEach((candidateEvaluation) => {
      const tableBody = candidateEvaluation.stack[2].stack[0].table.body;
      tableBody.forEach((tableRow, tableRowIndex) => {
        if (tableRowIndex > 0) {
          const pageNumber = tableRow[0].positions[0].pageNumber;
          const rawHeaderData = tableRow[0].stack[0].stack[0].stack[0].pageHeaderData
            ? JSON.parse(tableRow[0].stack[0].stack[0].stack[0].pageHeaderData).candidate
            : null;

          if (rawHeaderData) {
            const { pk_Candidate, pk_User } = rawHeaderData;
            const oldPages = candidatePages[`${pk_User}_${pk_Candidate}`] || [];
            if (!oldPages.includes(pageNumber)) {
              oldPages.push(pageNumber);
              candidatePages[`${pk_User}_${pk_Candidate}`] = oldPages;
            }
          }

          // tableRowIndex >= tableBody.length - 1 means system gave up, assume pdfmake pageNumber issue
          // and assign last tableBody item's rawHeaderData. pageNumber issue first seen here, when a question
          // overflows to the next page and that question's pageNumber is wrong. This is a pdfmake thing.
          if (pageNumber == currentPage || (tableRowIndex >= tableBody.length - 1 && pageNumber + 1 == currentPage)) {
            headerData.candidateData = rawHeaderData ? rawHeaderData : {};
            const { pk_Candidate, pk_User } = rawHeaderData;
            const oldPages = candidatePages[`${pk_User}_${pk_Candidate}`] || [];
            if (!oldPages.includes(currentPage)) {
              oldPages.push(currentPage);
              candidatePages[`${pk_User}_${pk_Candidate}`] = oldPages;
            }
          }
        }
      });
    });
  });

  headerData.candidatePages = candidatePages;
  return headerData;
};

export const createPDFForCandidateEvaluationsWithAnswers = ({
  interviewDate,
  department,
  scaleData,
  ProgramType,
  evaluators = [],
  questions = [], // Array of Question Groups, each with questions property
  callback,
}) => {
  const candidatesCopy = [];
  evaluators.forEach((e) => {
    const { Candidates = [] } = e;
    Candidates.forEach((c) => {
      candidatesCopy.push({ ...c, ...e });
    });
  });
  const imgDictionary = {}; //pk_candidate keys with base64 images as values

  // Process each candidate's photo and convert to base64 before generating pdf proper.
  Promise.all([
    ...candidatesCopy.map((c) => {
      const { pk_Candidate, PhotoUrl } = c;
      if (PhotoUrl && imgDictionary[pk_Candidate] == undefined) {
        imgDictionary[pk_Candidate] = null;
        return new Promise((resolve, reject) => {
          if (PhotoUrl) {
            imageToBase64(c.PhotoUrl)
              .then((base64Img) => {
                c.userPhotoBase64 = base64Img;
                imgDictionary[pk_Candidate] = base64Img;
                resolve(c);
              })
              .catch((err) => {
                delete imgDictionary[pk_Candidate];
                resolve({ error: true, ...c });
              });
          } else {
            resolve(c);
          }
        });
      }
    }),
    new Promise((resolve, reject) => {
      imageToBase64(profilePlaceholder)
        .then((base64) => {
          profilePlaceholderBase64 = base64;
          resolve(base64);
        })
        .catch((err) => {
          reject(err);
        });
    }),
    new Promise((resolve, reject) => {
      imageToBase64(rezRateLogo)
        .then((base64) => {
          rezRateLogoBase64 = base64;
          resolve(base64);
        })
        .catch((err) => {
          reject(err);
        });
    }),
  ])
    .then((res) => {
      createPDFProper({
        evaluators,
        department,
        interviewDate,
        questions,
        scaleData,
        rawData: evaluators,
        imgDictionary,
        ProgramType,
      });
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      createPDFProper({
        evaluators,
        department,
        interviewDate,
        questions,
        scaleData,
        rawData: evaluators,
        imgDictionary,
        ProgramType,
      });
      if (callback) {
        callback(err);
      }
    });
};
