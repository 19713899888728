const style = {
  simpleRow: {
    flexDirection: 'row',
    display: 'flex',
  },
  testButton: {
    backgroundColor: '#0a91ff',
    fontWeight: 600,
  },
  bottomButton: {
    margin: 10,
    fontWeight: 600,
    // backgroundColor: '#0a91ff',
  },
  buttonIcon: {
    marginRight: 10,
  },
  btnContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'center',
    marginTop: 20,
    paddingTop: 5,
    paddingBottom: 0,
    borderTop: '1px solid #c8dae0',
  },
  spacedBetweenRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
};
export default style;
