import React, { useEffect, useState, Fragment } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'reactstrap/lib/Button';
import { calculatePercentage, calculateWeight, calculateMinMaxSpread } from '../../../../../../Common.functions';
import Popup from 'reactjs-popup';

import { faTrash, faInfoCircle, faCheckSquare, faSquare } from '@fortawesome/free-solid-svg-icons';
import style from '../EvaluationQuestions.style';
import {
  FormFeedback,
  FormGroup,
  Input,
  Popover,
  PopoverBody,
  PopoverHeader,
  Table,
  UncontrolledPopover,
} from 'reactstrap';

const type = {
  1: 'String',
  3: 'Yes/No',
};

const AdditionalScores = ({
  additionalFieldOptions,
  editableAdditionalScoringOptions,
  AllowPrescreen,
  handleAdditionalScoreDelete,
  handleAddtionalScorePropertyChange,
  isUpdating,
  numOfQuestionsInWeight,
  questionScale,
  questionWeights,
  scale,
  score,
}) => {
  const [evalPercentage, setEvalPercentage] = useState('-');
  const [prePercentage, setPrePercentage] = useState('-');
  const [spread, setSpread] = useState([]);
  const [popoverOpen, setPopoverOpen] = useState(false);
  const [existingScoreTypes, setExistingScoreTypes] = useState([]);

  const {
    MinOrNoValue,
    MaxOrYesValue,
    ScoreType,
    EnableEvaluationForm,
    fk_EvaluationQuestionWeight,
    EnablePrescreenForm,
    pk_AdditionalScore,
    tempIdentity,
  } = score;

  const {
    Alias,
    DisplayOnRank,
    DisplayToEvaluator,
    Enabled,
    Name,
    ReadOnly,
    Type,
    QuestionType,
    UIType,
    fk_Department,
  } = additionalFieldOptions && additionalFieldOptions[ScoreType] ? additionalFieldOptions[ScoreType] : {};

  const identity = pk_AdditionalScore || tempIdentity;

  useEffect(() => {
    if (editableAdditionalScoringOptions) {
      const newExistingScoreTypes = [];
      editableAdditionalScoringOptions.forEach((item) => {
        if (ScoreType && item.ScoreType != ScoreType) {
          newExistingScoreTypes.push(item.ScoreType);
        }
      });

      setExistingScoreTypes(newExistingScoreTypes);
    }
  }, [editableAdditionalScoringOptions]);

  useEffect(() => {
    const calcSpread = calculateMinMaxSpread(MaxOrYesValue, MinOrNoValue, questionScale.max, 2);
    setSpread(calcSpread);
  }, [MinOrNoValue, MaxOrYesValue, questionScale]);

  useEffect(() => {
    if (score && numOfQuestionsInWeight && questionWeights) {
      const prescreenResult = calculateWeight(
        'prescreen',
        score,
        numOfQuestionsInWeight,
        questionWeights,
        score.fk_EvaluationQuestionWeight,
      );
      const evaluationResult = calculateWeight(
        'evaluation',
        score,
        numOfQuestionsInWeight,
        questionWeights,
        score.fk_EvaluationQuestionWeight,
      );
      setEvalPercentage(evaluationResult);
      setPrePercentage(prescreenResult);
    }
  }, [JSON.stringify(score), numOfQuestionsInWeight, questionWeights]);

  useEffect(() => {
    if (Type == 3) {
      if (MinOrNoValue < 1) {
        handleAddtionalScorePropertyChange('min', identity, 1);
      }
      if (MaxOrYesValue < 1) {
        handleAddtionalScorePropertyChange('max', identity, 1);
      }
    }
  }, [Type]);

  const renderYesNoOptions = () => {
    const options = [];
    let count = 1;
    while (options.length < scale) {
      options.push(<option value={count}>{count}</option>);
      count++;
    }
    return options;
  };

  const selectedWeightCategoryExists = questionWeights.find((item) => {
    return item.pk_EvaluationQuestionWeight == fk_EvaluationQuestionWeight;
  });

  const selectedScoreTypeExists = additionalFieldOptions[ScoreType];

  let backgroundColor = 'inherit';

  if ((!AllowPrescreen && !EnableEvaluationForm) || (AllowPrescreen && !EnableEvaluationForm && !EnablePrescreenForm)) {
    backgroundColor = '#babab8';
  }

  return (
    <tr style={{ backgroundColor }}>
      <td
        style={{ cursor: 'pointer' }}
        onClick={() => {
          handleAdditionalScoreDelete(pk_AdditionalScore, tempIdentity);
        }}
      >
        <Button color="danger" disabled={isUpdating}>
          <FontAwesomeIcon icon={faTrash} />
        </Button>
      </td>
      <td>
        <Input
          type="select"
          name="Score Type"
          invalid={!ScoreType || !selectedScoreTypeExists}
          disabled={isUpdating}
          value={selectedScoreTypeExists ? ScoreType : ''}
          style={{ width: '100%' }}
          onChange={(e) => {
            handleAddtionalScorePropertyChange('field', identity, e.target.value);
          }}
        >
          <option disabled value={''}>
            Select Type
          </option>
          {Object.keys(additionalFieldOptions).map((key, index) => {
            const field = additionalFieldOptions[key];
            const { Name, Alias, Type } = field;

            // ScoreNumberType 1 = "Score"
            // ScoreNumberType 2 = "Text"
            // ScoreNumberType 3 = "Yes/No"
            if (existingScoreTypes.includes(Name)) {
              return null;
            }
            return (
              <option key={Name} value={key}>
                {Alias}
              </option>
            );
          })}
        </Input>
      </td>
      <td>
        <Input
          type="select"
          invalid={!fk_EvaluationQuestionWeight || !selectedWeightCategoryExists}
          name="weightCategorySelect"
          disabled={isUpdating}
          style={{ width: '100%' }}
          value={selectedWeightCategoryExists ? fk_EvaluationQuestionWeight : ''}
          onChange={(e) => {
            handleAddtionalScorePropertyChange('weight', identity, e.target.value);
          }}
        >
          <option disabled value={''}>
            Select Category
          </option>
          {questionWeights.map((item) => {
            return <option value={item.pk_EvaluationQuestionWeight}>{item.CategoryName}</option>;
          })}
        </Input>
      </td>
      <td style={{ width: '30%' }}>
        <div style={style.simpleRow}>
          <div
            style={{
              ...style.simpleColumn,
              width: 50,
              padding: '3px',
              textAlign: 'right',
              justifyContent: 'center',
              // alignItems: 'center',
            }}
          >
            {Type === 3 ? 'No' : `Low`}
          </div>
          <FormGroup
            style={{
              ...style.simpleColumn,
              width: '100%',
              padding: '3px',
              textAlign: 'right',
              justifyContent: 'center',
              alignItems: 'center',
              marginBottom: 0,
            }}
          >
            {Type === 3 ? (
              <Input
                type="select"
                invalid={MinOrNoValue == MaxOrYesValue}
                disabled={isUpdating}
                value={MinOrNoValue}
                onChange={(e) => {
                  e.preventDefault();

                  handleAddtionalScorePropertyChange('min', identity, e.target.value);
                }}
              >
                {renderYesNoOptions()}
              </Input>
            ) : (
              <Input
                type="number"
                invalid={MinOrNoValue >= MaxOrYesValue}
                value={MinOrNoValue}
                disabled={isUpdating}
                onChange={(e) => {
                  e.preventDefault();
                  let newValue = e.target.value;

                  if (newValue > 9999) {
                    newValue = 9999;
                  }

                  if (newValue < 0) {
                    newValue = 0;
                  }
                  handleAddtionalScorePropertyChange('min', identity, newValue);
                }}
              />
            )}
            {/* <FormFeedback>
              {Type === 3 ? 'No values must be lower than Yes values!' : 'Low values must be lower than High values'}
            </FormFeedback> */}
          </FormGroup>

          <div
            style={{
              ...style.simpleColumn,
              width: 50,
              padding: '3px',
              textAlign: 'right',
              justifyContent: 'center',
              // alignItems: 'center',
            }}
          >
            {Type === 3 ? 'Yes' : `High`}
          </div>
          <FormGroup
            style={{
              ...style.simpleColumn,
              width: '100%',
              padding: '3px',
              textAlign: 'right',
              justifyContent: 'center',
              alignItems: 'center',
              marginBottom: 0,
            }}
          >
            {Type === 3 ? (
              <Input
                type="select"
                invalid={MinOrNoValue == MaxOrYesValue}
                disabled={isUpdating}
                value={MaxOrYesValue}
                onChange={(e) => {
                  e.preventDefault();
                  handleAddtionalScorePropertyChange('max', identity, e.target.value);
                }}
              >
                {renderYesNoOptions()}
              </Input>
            ) : (
              <Input
                type="number"
                invalid={MinOrNoValue >= MaxOrYesValue}
                disabled={isUpdating}
                value={MaxOrYesValue}
                onChange={(e) => {
                  e.preventDefault();

                  let newValue = e.target.value;

                  if (newValue > 9999) {
                    newValue = 9999;
                  }

                  if (newValue < 0) {
                    newValue = 0;
                  }

                  handleAddtionalScorePropertyChange('max', identity, newValue);
                }}
              />
            )}
            {/* <FormFeedback>
                {Type === 3
                  ? 'Yes values must be greater than No values!'
                  : 'High values must be greater than Low values'}
              </FormFeedback> */}
          </FormGroup>
        </div>
      </td>
      <td>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
          }}
        >
          <div>
            <Button
              id={`popover_${score.pk_AdditionalScore}`}
              disabled={Type != 1}
              className="btn btn-info btn-sm"
              style={{ width: '36px' }}
              onClick={() => {
                setPopoverOpen(!popoverOpen);
              }}
              // onMouseEnter={() => setPopoverOpen(true)}
              // onMouseLeave={() => setPopoverOpen(false)}
            >
              <FontAwesomeIcon icon={faInfoCircle} />
            </Button>
            <UncontrolledPopover
              className="spread_details"
              placement="left-end"
              // isOpen={popoverOpen}
              target={`popover_${score.pk_AdditionalScore}`}
              trigger={`legacy`}
            >
              <PopoverHeader>Spread Details</PopoverHeader>
              <PopoverBody>
                <div style={{ overflow: 'scroll' }}>
                  <Table>
                    <thead>
                      <tr style={{ textAlign: 'center' }}>
                        {spread.map((item, i) => {
                          return <th key={item}>{i + 1}</th>;
                        })}
                      </tr>
                    </thead>
                    <tbody>
                      <tr style={{ textAlign: 'center' }}>
                        {spread.map((item, i) => {
                          return <td key={item}>{item}</td>;
                        })}
                      </tr>
                    </tbody>
                  </Table>
                </div>
              </PopoverBody>
            </UncontrolledPopover>
          </div>
        </div>
      </td>
      <td>
        {AllowPrescreen ? (
          <div
            className="no_select"
            style={{ ...style.simpleRow, alignItems: 'center', cursor: 'pointer', justifyContent: 'space-between' }}
            onClick={() => {
              handleAddtionalScorePropertyChange('prescreen', identity);
            }}
          >
            <FontAwesomeIcon
              icon={EnablePrescreenForm ? faCheckSquare : faSquare}
              style={{ marginRight: 10 }}
              // color={EnablePrescreenForm ? '#000000' : '#ffffff'}
            />
            Prescreen
          </div>
        ) : null}

        <div
          className="no_select"
          style={{ ...style.simpleRow, alignItems: 'center', cursor: 'pointer', justifyContent: 'space-between' }}
          onClick={() => {
            handleAddtionalScorePropertyChange('eval', identity);
          }}
        >
          <FontAwesomeIcon
            icon={EnableEvaluationForm ? faCheckSquare : faSquare}
            style={{ marginRight: 10 }}
            // color={EnableEvaluationForm ? '#000000' : '#ffffff'}
          />
          Evaluation{' '}
        </div>
      </td>
      <td>
        {AllowPrescreen ? (
          <div style={style.simpleRow}>
            <div style={style.simpleColumn}>{prePercentage}</div>
          </div>
        ) : null}

        <div style={style.simpleRow}>
          <div style={style.simpleColumn}>{evalPercentage}</div>
        </div>
      </td>
    </tr>
  );
};

export default AdditionalScores;
