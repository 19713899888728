import React, { useEffect, useState } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import './Wysiwyg.style.css';
import CustomOption from './CustomButtons';
import { EditorState, Modifier, ContentBlock } from 'draft-js';
import CustomBlockRenderer from './CustomBlockRenderer';

let typeTimer = null;

const Wysiwyg = ({
  editorState,
  setEditorState,
  onEditorStateChange,
  selectedCandidate,
  setForceChildEditorState,
  isUpdating,
  toggleAttachmentPopup,
  forcePlainText,
}) => {
  const [myEditorState, setMyEditorState] = useState(EditorState.createEmpty());

  useEffect(() => {
    if (setForceChildEditorState) {
      setForceChildEditorState((prevState) => {
        return setMyEditorState;
      });
    }
  }, [setForceChildEditorState]);

  // useEffect(() => {
  //   if (editorState) {
  //     setMyEditorState(editorState);
  //   }
  // }, [editorState]);

  useEffect(() => {
    clearTimeout(typeTimer);

    typeTimer = setTimeout(() => {
      onEditorStateChange(myEditorState);
    }, 300);
  }, [myEditorState]);

  const handlePastedText = (text, html, editorState) => {
    // Create a new ContentState from the pasted text
    const contentState = Modifier.replaceText(editorState.getCurrentContent(), editorState.getSelection(), text);

    // Update the editor state with the new ContentState
    setEditorState(EditorState.push(editorState, contentState, 'insert-characters'));

    // Prevent the default paste handling
    return 'handled';
  };

  const isEditorEmpty = (editorState) => {
    const contentState = editorState.getCurrentContent();
    const blockMap = contentState.getBlockMap();

    // Check if there's only one block and it's empty
    return blockMap.size === 1 && blockMap.first().getText().length === 0;
  };

  // Use to make custom blocks for styling and all that jazz, like
  // plugging pictures or whatever.
  const blockRendererFn = (block) => {
    const isShiftEnter = block.getText() === '\n';
    const isEnter = block.getText() === '';

    if (!isEditorEmpty(editorState) && !isShiftEnter && isEnter) {
      return {
        component: CustomBlockRenderer,
        editable: true,
      };
    }
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'row', minHeight: '250px' }}>
      <Editor
        readOnly={isUpdating}
        editorState={myEditorState}
        toolbar={{
          options: [
            'inline',
            'blockType',
            'fontSize',
            // 'fontFamily',
            'list',
            // 'textAlign',
            'colorPicker',
            'link',
            'emoji',
            'image',
            'remove',
            'history',
          ],
        }}
        toolbarClassName="toolbar"
        // wrapperClassName="wrapper"
        editorClassName="editorField"
        toolbarCustomButtons={[
          <CustomOption selectedCandidate={selectedCandidate} toggleAttachmentPopup={toggleAttachmentPopup} />,
        ]}
        onEditorStateChange={(newEditorState) => {
          setMyEditorState(newEditorState);
        }}
        // handlePastedText={forcePlainText ? handlePastedText : null}
        // blockRendererFn={blockRendererFn}
      />
      {/* <textarea disabled value={draftToHtml(convertToRaw(editorState.getCurrentContent()))} /> */}
    </div>
  );
};

export default Wysiwyg;

//Candidate First Name
//Candidate Last Name
//Schedule Interview Button
//View Schedule Button
// Wait List Date
//Interview Date
//Photo Upload Button
