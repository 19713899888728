import React, { useEffect, useState } from 'react';
import Moment from 'react-moment';
import moment from 'moment';

import { DraggableItemWrapper } from '../../../Common.style';
import { Date } from './Selection.style';

const Selection = ({ listKey, data, state, lastPressed, isPressed, mouseY, listArray, handleClickTransfer }) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < window.innerHeight);
  const numberKey = Number(listKey);
  const item = listArray[numberKey];

  const { shadow, scale, y } = state;
  const transY = lastPressed === data && isPressed ? mouseY : y;
  // define conditional css attributes
  const selected = item.selected;
  const backgroundColor = 'rgb(255, 255, 255)';
  const color = 'rgb(64, 64, 64)';
  const action = 'add';

  useEffect(() => {
    setIsMobile(window.innerWidth < window.innerHeight);
  }, [window.innerWidth, window.innerHeight]);

  // handles UI elements for pre-selected items
  return (
    <DraggableItemWrapper
      key={listKey}
      className="draggable_item"
      onClick={(e) => handleClickTransfer('add', data)}
      // need to use style instead of styled-components to keep animation smooth (styled-components creates a new component everytime there's a css change)
      style={{
        boxShadow: `rgba(0, 0, 0, 0.4) 0px ${shadow}px ${2 * shadow}px 0px`,
        transform: `translate3d(0, ${transY}px, 0) scale(${scale})`,
        WebkitTransform: `translate3d(0, ${transY}px, 0) scale(${scale})`,
        zIndex: data === lastPressed ? 99 : data,
        backgroundColor: backgroundColor,
        padding: 10,
        paddingRight: 5,
        paddingLeft: 5,
        alignItems: 'center',
        justifyContent: 'center',
      }}
      backgroundColor={backgroundColor}
    >
      <div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            color: 'black',
            paddingLeft: isMobile ? 0 : 10,
            paddingRight: isMobile ? 0 : 10,
            fontSize: ' 0.7em',
          }}
        >
          <div
            style={{
              fontWeight: '700',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              width: 80,
            }}
          >
            {/* {itemsNumbered}: */}
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
              <div className="mobile_display">
                {moment.tz(item.DateOfInterview || item.InterviewDates4PortalDate, null).format('ddd, MMM DD, YYYY')}
              </div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
              <div className="browser_display">
                {moment.tz(item.DateOfInterview || item.InterviewDates4PortalDate, null).format('ddd, MMMM  DD, YYYY')}
              </div>
            </div>
            <div
              style={{
                color: '#000',
                fontSize: 15,
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              {item.Label}
            </div>
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', width: 80 }}></div>
        </div>
      </div>
    </DraggableItemWrapper>
  );
};

export default Selection;
