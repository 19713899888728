import moment from 'moment';
import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div``;

//Wrapper keeps footer at bottom of page
const Footer = () => {
  const noHeader =
    window.location.pathname.startsWith('/files') ||
    window.location.pathname.startsWith('/viewFile') ||
    window.location.pathname.startsWith('/logo') ||
    window.location.pathname.startsWith('/coordinator/schedule') ||
    window.location.pathname.startsWith('/administrator/rank/chart_print_preview');

  if (noHeader) {
    document.body.style.backgroundColor = '#fff';
    return <div />;
  }

  return (
    <div
      style={{
        width: '100vw',
        maxWidth: '100%',
        height: '50px',
        backgroundColor: '#fff',
        zIndex: 10,
        padding: '15px',
        textAlign: 'center',
      }}
    >
      <p style={{ margin: '0' }}>
        ©2018-{moment().format('YYYY')} <a href="https://druksys.com/">Druksys, LLC</a> •{' '}
        <a href="https://www.rezrate.com/">RezRATE</a>®
      </p>
    </div>
  );
};

export default Footer;

// 80
