import React, { useEffect, useContext } from 'react';
import { useAuth0 } from '../../auth0/reactAuth0Spa';
import { DepartmentContext } from '../../DepartmentWrapper';
import { clone } from 'lodash';
import { useHistory } from 'react-router-dom';
import Loading from 'Body/Statuses/Loading';

const RedirectLogin = ({ match, props }) => {
  const { loading, user, isAuthenticated, loginWithRedirect, getTokenSilently, logout } = useAuth0();
  const { email } = match.params;
  const dContext = useContext(DepartmentContext);
  const history = useHistory();
  useEffect(() => {
    if (user) {
      if (user.email === email) {
        history.push('/');
      } else {
        logout();
      }
    } else {
      // logout();
    }
  }, []);

  return (
    <div style={{ paddingTop: '30vh' }}>
      <Loading
        options={{
          labelText: 'Redirecting . . .',
        }}
      />
    </div>
  );
};

export default RedirectLogin;
