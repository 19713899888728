import { faCircle, faDotCircle, faEllipsisH } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext, useEffect, useState } from 'react';
import { Button } from 'reactstrap';
import AdminEvaluationDreyfusItem from './AdminEvaluationScaleItem/AdminEvaluationDreyfusItem';
import AdminEvaluationLikertItem from './AdminEvaluationScaleItem/AdminEvaluationLikertItem';

import './AdminEvaluationQuestionTemplate.style.css';
import style from './AdminEvaluationQuestionTemplate.style';
import { questionScaleKeys, questionScaleNames } from '../AdminEvaluationSettingsConstants';
import Swal from 'sweetalert2';
import { DepartmentContext } from 'DepartmentWrapper';
const AdminEvaluationQuestionTemplate = ({
  checkScaleMatch,
  hideTitle = false,
  isReadOnly = false,
  isUpdatingOptions,
  isUpdatingScaleType,
  questionOptions,
  questionScale = 'likert10',
  questionsHaveChange,
  saveQuestionChoices,
  scaleData,
  setQuestionScale,
  updateQuestion,
  saveScaleType,
}) => {
  const [fields, setFields] = useState([]);

  const dContext = useContext(DepartmentContext);
  const { department = {} } = dContext;
  const { ProgramType } = department || {};

  const determineRenderType = () => {
    return questionOptions.map((item, i) => {
      switch (questionScale) {
        case questionScaleKeys.dreyfus10:
          return (
            <AdminEvaluationDreyfusItem
              i={i}
              key={`key_${item.pk_QuestionOption}_${item.number}`}
              length={questionOptions.length}
              item={item}
              updateQuestion={updateQuestion}
              isUpdatingOptions={isUpdatingOptions || isReadOnly}
            />
          );

        default:
          return (
            <AdminEvaluationLikertItem
              i={i}
              key={`key_${item.pk_QuestionOption}_${item.number}`}
              length={questionOptions.length}
              item={item}
              updateQuestion={updateQuestion}
              isUpdatingOptions={isUpdatingOptions || isReadOnly}
            />
          );
      }
    });
  };

  if (!questionOptions) {
    return <div />;
  }

  if (hideTitle) {
    return (
      <div className="col">
        <div className="row">{determineRenderType()}</div>
      </div>
    );
  } else {
    const modesToShow = ['likert5', 'likert10'];

    if (ProgramType === 'Mock Oral Board') {
      modesToShow.push('abns4');
      modesToShow.push('passFail3');
    }

    return (
      <div className="col-12">
        <div className="card shadow bg-light" style={{ width: '100%' }}>
          <div className="card-header">
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <div style={{ ...style.simpleColumn, width: '60%', marginRight: 10 }}>
                <h4>Customize default questionnaire response labels and descriptions</h4>
                <p>
                  Labels and optional descriptions appear under each evaluation response to provide additional conext.
                  These can be overwritten on an individual question using the{' '}
                  <Button color="warning" className="descriptionBtn">
                    <FontAwesomeIcon icon={faEllipsisH} />
                  </Button>{' '}
                  button.
                </p>
              </div>
              <div style={{ display: 'flex', flexDirection: 'column', width: '40%', justifyContent: 'center' }}>
                <div style={{ ...style.spaceBetweenRow, flexWrap: 'wrap' }}>
                  {modesToShow.map((mode) => {
                    const modeID = questionScaleKeys[mode];
                    const modeName = questionScaleNames[mode];

                    return (
                      <Button
                        disabled={isUpdatingScaleType}
                        style={{ width: '49%', minWidth: 150, marginBottom: 10 }}
                        color={checkScaleMatch(modeID) ? 'success' : 'secondary'}
                        onClick={() => {
                          if (checkScaleMatch(modeID) || scaleData == modeID) {
                            setQuestionScale(modeID);
                            saveScaleType(modeID);
                          } else {
                            Swal.fire({
                              title: 'Warning!',
                              text: `Changing Questionnaire scale would reset the option values. Are you sure you want to proceed?`,
                              type: 'warning',
                              showCancelButton: true,
                              confirmButtonColor: 'red',
                              confirmButtonText: `Switch and Reset`,
                            }).then((result) => {
                              if (result.value) {
                                setQuestionScale(modeID);
                                saveScaleType(modeID);
                              }
                            });
                          }
                        }}
                      >
                        <div style={style.spaceBetweenRow}>
                          <FontAwesomeIcon icon={checkScaleMatch(modeID) ? faDotCircle : faCircle} />
                          <div style={style.centeredText}>{modeName}</div>
                        </div>
                      </Button>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
          <div className="card-body">
            <div className="row" style={{ height: '20px' }}>
              &nbsp;
            </div>
            <div className="row">
              <div className="col">
                <div className="row">{determineRenderType()}</div>
              </div>
            </div>
            <div className="row">
              <div
                className="col"
                style={{
                  alignItems: 'center',
                  marginTop: 20,
                  width: '100%',
                  justifyContent: 'center',
                  display: 'flex',
                }}
              >
                <Button
                  disabled={!questionsHaveChange}
                  style={{ minWidth: 200 }}
                  color="success"
                  onClick={(e) => {
                    e.preventDefault();
                    saveQuestionChoices();
                  }}
                >
                  Save Labels and Descriptions
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export default AdminEvaluationQuestionTemplate;
