import React, { useState, useContext, useEffect } from 'react';
import DataTypeItem from 'Body/RezRATE/AdminLanding/AdminCandidates/DataTypeItem';
import Loading from 'Body/Statuses/Loading';
import { DepartmentContext } from 'DepartmentWrapper';
import { fetchDataAgnostic, putData } from 'Services/dataApi';
import { formatBearerToken } from 'Common.functions';
import { useAuth0 } from 'auth0/reactAuth0Spa';
import { prepareDataTypesForSortUpdate, separateDataTypesByAvailability } from 'Services/candidates';

import style from './ManageFields.style';
import { Button, Input, InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faTimes } from '@fortawesome/free-solid-svg-icons';

const ManageFields = ({ onSaved }) => {
  const [dataTypes, setDataTypes] = useState({ enabled: [], disabled: [] });
  const [dataTypesRaw, setDataTypesRaw] = useState([]);
  const [isUpdatingDataType, setIsUpdatingDataType] = useState(false);
  const [isInitializedDataTypes, setIsInitializedDataTypes] = useState(false);
  const [availableFieldsSearchString, setAvailableFieldsSearchString] = useState('');

  const dContext = useContext(DepartmentContext);
  const { getTokenSilently } = useAuth0();

  useEffect(() => {
    getDepartmentDataTypes();
  }, []);

  const getDepartmentDataTypes = () => {
    setIsUpdatingDataType(true);
    getTokenSilently()
      .then((token) => {
        fetchDataAgnostic(
          'department/dataTypes',
          { pk_Department: dContext.department.pk_Department },
          formatBearerToken(token),
        )
          .then((res) => {
            setIsInitializedDataTypes(true);
            setIsUpdatingDataType(false);
            setDataTypes(separateDataTypesByAvailability(res.data));
            setDataTypesRaw(res.data);
          })
          .catch((err) => {
            setIsUpdatingDataType(false);
          });
      })
      .catch((err) => {
        setIsUpdatingDataType(false);
      });
  };

  const updateDataType = (dataTypeToUpdate, updateSortOrders) => {
    if (updateSortOrders) {
      dataTypeToUpdate = prepareDataTypesForSortUpdate(dataTypes, dataTypeToUpdate);
    }

    setIsUpdatingDataType(true);
    getTokenSilently()
      .then((token) => {
        putData(
          'department/dataTypes',
          { pk_Department: dContext.department.pk_Department },
          { types: dataTypeToUpdate },
          formatBearerToken(token),
        )
          .then((res) => {
            setIsUpdatingDataType(false);
            getDepartmentDataTypes();
            if (onSaved) {
              onSaved();
            }
          })
          .catch((err) => {
            setIsUpdatingDataType(false);
            getDepartmentDataTypes();
            if (onSaved) {
              onSaved(true);
            }
          });
      })
      .catch((err) => {});
  };

  const renderManageFields = () => {
    const { enabled = [], disabled = [] } = dataTypes;
    const count = enabled ? enabled.length : 0;
    let index = 1;

    const selectOptions = [];

    while (index <= count) {
      selectOptions.push(
        <option key={index} value={index}>
          {index}
        </option>,
      );
      index++;
    }

    const enabledList = enabled.map((dataType) => {
      return (
        <DataTypeItem
          key={dataType.Name}
          dataType={dataType}
          numberOfEnabled={count}
          updateDataType={updateDataType}
          isUpdatingDataType={isUpdatingDataType}
          selectOptions={selectOptions}
        />
      );
    });

    const disabledList = disabled
      .filter((d) => {
        return (
          !availableFieldsSearchString ||
          d.Name.toLowerCase().indexOf(availableFieldsSearchString.toLowerCase()) >= 0 ||
          d.Alias.toLowerCase().indexOf(availableFieldsSearchString.toLowerCase()) >= 0
        );
      })
      .map((dataType, i) => {
        return (
          <DataTypeItem
            key={dataType.Name}
            dataType={dataType}
            numberOfEnabled={count}
            enabled={enabled}
            updateDataType={updateDataType}
            isUpdatingDataType={isUpdatingDataType}
            selectOptions={selectOptions}
          />
        );
      });

    if (!isInitializedDataTypes && isUpdatingDataType) {
      return (
        <div
          style={{
            width: '100%',
            height: '40vh',
            fontWeight: 'bold',
            justifyContent: 'center',
            alignItems: 'center',
            display: 'flex',
          }}
        >
          <div style={{ height: 100, width: 100 }}>
            <Loading
              options={{
                labelText: 'Loading. . .',
              }}
            />
          </div>
        </div>
      );
    }

    return (
      <>
        <div style={{ ...style.simpleColumn, width: '100%', height: '100%' }}>
          <div style={style.simpleRow}>
            <div style={{ ...style.simpleColumn, width: '40%', margin: 5, textAlign: 'center', fontWeight: 'bold' }}>
              Inactive Fields
            </div>
            <div style={{ ...style.simpleColumn, width: '60%', margin: 5, textAlign: 'center', fontWeight: 'bold' }}>
              Active Fields
            </div>
          </div>
          <div style={style.spacedBetweenRow}>
            <div style={{ ...style.manageFieldsContainer, width: '40%', overflowY: 'hidden' }}>
              <div
                style={{
                  ...style.manageFieldsEnabledItemsVisibilityLabels,
                  justifyContent: 'center',
                  width: '100%',
                  marginBottom: 10,
                }}
              >
                <InputGroup>
                  <InputGroupAddon addonType="prepend">
                    <Button
                      onClick={() => {
                        setAvailableFieldsSearchString('');
                      }}
                    >
                      <FontAwesomeIcon icon={faTimes} />
                    </Button>
                  </InputGroupAddon>
                  <Input
                    type={'text'}
                    value={availableFieldsSearchString}
                    onChange={(e) => {
                      setAvailableFieldsSearchString(e.target.value);
                    }}
                  />
                  <InputGroupAddon addonType="append">
                    <Button color="success" style={{ cursor: 'default' }}>
                      <FontAwesomeIcon icon={faSearch} />
                    </Button>
                  </InputGroupAddon>
                </InputGroup>
              </div>
              <div style={{ ...style.simpleRow, overflowY: 'scroll', overflowX: 'clip', height: '100%' }}>
                <div style={style.manageFieldsContainerSubContainer}>
                  {disabledList && disabledList.length > 0 ? (
                    disabledList
                  ) : (
                    <div style={{ display: 'flex', width: '100%', textAlign: 'center', justifyContent: 'center' }}>
                      No{availableFieldsSearchString ? ' Matching ' : ' '}Fields
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div style={{ ...style.manageFieldsContainer, width: '60%', overflowY: 'hidden' }}>
              <div style={style.manageFieldsEnabledItemsVisibilityLabels}>
                <div style={style.visibilityLabel}>Rank</div>
                <div style={style.visibilityLabel}>Eval</div>
              </div>
              <div style={{ ...style.simpleRow, overflowY: 'scroll', overflowX: 'clip' }}>
                <div style={style.manageFieldsContainerSubContainer}>
                  {enabledList && enabledList.length > 0 ? (
                    enabledList
                  ) : (
                    <div style={{ display: 'flex', width: '100%', textAlign: 'center', justifyContent: 'center' }}>
                      No Fields
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  return renderManageFields();
};

export default ManageFields;
