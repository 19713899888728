const style = {
  dropDownBody: {
    display: 'flex',
    flexDirection: 'column',
    maxHeight: 250,
    minWidth: '100%',
    overflowY: 'scroll',
  },
  selectAllRow: {
    alignItems: 'center',
    borderBottom: '1px solid #c8dae0',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    padding: 5,
    fontSize: 16,
    fontWeight: 600,
  },
  clickable: {
    cursor: 'pointer',
  },
};

export default style;
