import { faSquare } from '@fortawesome/free-regular-svg-icons';
import {
  faCheck,
  faCheckSquare,
  faCircle,
  faCog,
  faDotCircle,
  faGear,
  faInfoCircle,
  faLink,
  faPrint,
  faTimes,
  faTrash,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { Button, Input, Label, UncontrolledTooltip } from 'reactstrap';
import Swal from 'sweetalert2';
import { clone } from '../../../../../Services/schedule';
import '../Schedule.style.css';
import style from './style';
import { urlWithHttpProtocol } from 'Common.functions';
import LinkSettingsButton from 'Body/RezRATE/Common/LinkSettingsButton/LinkSettingsButton';

const CustomEventPopover = ({
  setShowAddCustomPopover,
  row,
  addRowCustomEvent,
  hideHeaderPopover,
  scheduleData,
  fireConfirmationForEditingPastSchedule,
  dateIsInThePast,
  willEditThePast,
}) => {
  const [useAllDayLink, setUseAllDayLink] = useState(true);
  const [event, setEvent] = useState({});

  const { metaData } = scheduleData || {};
  const { MainRoomURL } = metaData || {};
  useEffect(() => {
    if (row) {
      const {
        CustomDurationInMinutes,
        CustomMeetingDescription,
        CustomMeetingTitle,
        CustomMeetingUrl,
        CustomPassingDurationInMinutes,
        VirtualRoomType,
        CustomRoomLabel,
      } = row;
      const { metaData = {} } = scheduleData || {};

      const duration = CustomDurationInMinutes != null ? CustomDurationInMinutes : metaData.StandardDurationInMinutes;
      const passingDuration =
        CustomPassingDurationInMinutes != null
          ? CustomPassingDurationInMinutes
          : metaData.StandardPassingDurationInMinutes;

      setEvent({
        CustomDurationInMinutes: duration,
        CustomMeetingDescription,
        CustomMeetingTitle,
        CustomMeetingUrl,
        CustomPassingDurationInMinutes: passingDuration,
        VirtualRoomType: VirtualRoomType || 'AllDay',
        CustomRoomLabel,
      });

      if (VirtualRoomType === 'Custom') {
        setUseAllDayLink(false);
      } else {
        setUseAllDayLink(true);
      }
    }
  }, [JSON.stringify(row)]);

  // useEffect(() => {
  //   if (useAllDayLink) {
  //     updateEvent('VirtualRoomType', 'AllDay');
  //   } else {
  //     updateEvent('VirtualRoomType', 'Custom');
  //   }
  // }, [useAllDayLink]);

  const updateEvent = (key, value) => {
    const newEvent = clone(event);
    newEvent[key] = value;
    setEvent(newEvent);
  };

  const hasSplitsOrCandidates = () => {
    const { headers } = scheduleData;
    let valueToReturn = false;

    const headerIDs = headers.map((header) => {
      return header.pk_ScheduleAssignment;
    });

    headerIDs.forEach((pk_ScheduleAssignment) => {
      const { ScheduleBlockEntries } = row[pk_ScheduleAssignment];
      if (ScheduleBlockEntries && ScheduleBlockEntries.length > 0) {
        if (ScheduleBlockEntries.length > 1) {
          valueToReturn = true;
        }

        if (ScheduleBlockEntries[0].Candidates && ScheduleBlockEntries[0].Candidates.length > 0) {
          valueToReturn = true;
        }
      }
    });

    return valueToReturn;
  };

  const addCustomEvent = () => {
    const newEvent = clone(event);
    newEvent.pk_Timeslot = row.pk_Timeslot;
    newEvent.fk_MeetingRoom = newEvent.RoomNumber;
    newEvent.CustomMeetingUrl = urlWithHttpProtocol(newEvent.CustomMeetingUrl);
    delete newEvent.RoomNumber;

    addRowCustomEvent(newEvent);
    hideHeaderPopover();
    setShowAddCustomPopover(false);
  };

  return (
    <div
      style={style.mainBody}
      onClick={async (e) => {
        if (dateIsInThePast && !willEditThePast) {
          const continueProcess = await fireConfirmationForEditingPastSchedule();
          if (!continueProcess) {
            return;
          }
        }
        e.stopPropagation();
      }}
    >
      <div style={{ ...style.simpleRow }}>
        <div style={{ ...style.simpleColumn, width: '50%', marginRight: 10 }}>
          <div style={style.simpleRow}>
            <div style={{ ...style.simpleColumn, width: '100%' }}>
              <Label style={style.inputLabel} for="eventTitle">
                Event Title
              </Label>
              <Input
                type="text"
                invalid={!event.CustomMeetingTitle}
                style={style.titleField}
                name="eventTitle"
                placeholder="Event Title. . ."
                value={event.CustomMeetingTitle}
                onChange={(e) => {
                  updateEvent('CustomMeetingTitle', e.target.value);
                }}
              />
            </div>
          </div>

          <div style={style.simpleRow}>
            <div style={{ ...style.simpleColumn, width: '100%' }}>
              <Label style={style.inputLabel} for="roomNumber">
                Room
              </Label>
              <Input
                type="text"
                style={style.roomNumberField}
                name="roomNumber"
                id="roomNumber"
                value={event.CustomRoomLabel}
                placeholder="Room Label. . ."
                onChange={(e) => {
                  updateEvent('CustomRoomLabel', e.target.value);
                }}
              />
            </div>
          </div>
        </div>
        <div style={{ ...style.simpleColumn, width: '50%' }}>
          <div style={style.simpleRow}>
            <div style={{ ...style.simpleColumn, width: '100%' }}>
              <Label style={style.inputLabel} for="eventDuration">
                Event Duration
              </Label>
              <Input
                type="number"
                style={style.roomNumberField}
                name="eventDuration"
                id="eventDuration"
                placeholder="Duration minutes. . ."
                value={event.CustomDurationInMinutes}
                onChange={(e) => {
                  let newValue = e.target.value;

                  if (newValue < 1) {
                    newValue = 1;
                  }

                  updateEvent('CustomDurationInMinutes', newValue);
                }}
              />
            </div>
          </div>
          <div style={style.simpleRow}>
            <div style={{ ...style.simpleColumn, width: '100%' }}>
              <Label style={style.inputLabel} for="eventDuration">
                Passing Period
              </Label>
              <Input
                type="number"
                style={style.roomNumberField}
                name="passingPeriod"
                id="passingPeriod"
                placeholder="Passing minutes. . ."
                value={event.CustomPassingDurationInMinutes}
                onChange={(e) => {
                  let newValue = e.target.value;

                  if (newValue < 0) {
                    newValue = 0;
                  }

                  updateEvent('CustomPassingDurationInMinutes', newValue);
                }}
              />
            </div>
          </div>
        </div>
      </div>

      <div style={style.simpleRow}>
        <div style={{ ...style.simpleColumn, width: '100%' }}>
          <div
            style={{ ...style.simpleRow, cursor: 'pointer' }}
            onClick={async (e) => {
              if (dateIsInThePast && !willEditThePast) {
                const continueProcess = await fireConfirmationForEditingPastSchedule();
                if (!continueProcess) {
                  return;
                }
              }
              e.stopPropagation();
              setUseAllDayLink(true);
              updateEvent('VirtualRoomType', 'AllDay');
            }}
          >
            <div style={{ ...style.simpleColumn, width: 50, marginBottom: 0, marginTop: 4 }}>
              <FontAwesomeIcon style={style.checkIcon} icon={useAllDayLink ? faDotCircle : faCircle} />
            </div>
            <div style={{ ...style.simpleColumn, width: 200 }}>
              <div style={style.simpleRow}>
                Use All Day Meeting Link
                <FontAwesomeIcon
                  id={`useAllDayMeetingLabel`}
                  icon={faInfoCircle}
                  style={{ marginTop: 4, marginLeft: 5 }}
                />
                <UncontrolledTooltip target={`useAllDayMeetingLabel`}>
                  {' '}
                  Use the All Day Meeting link for this meeting.
                </UncontrolledTooltip>
              </div>
            </div>
          </div>

          <div
            style={{
              ...style.simpleRow,
              paddingLeft: 45,
              marginBottom: 10,
            }}
          >
            {MainRoomURL ? (
              <Input
                type="textarea"
                invalid={useAllDayLink && !MainRoomURL}
                rows={2}
                disabled
                value={MainRoomURL}
                placeholder={'No Main Room URL. . .'}
              />
            ) : (
              <div
                style={{
                  ...style.simpleColumn,
                  width: '100%',
                  backgroundColor: '#e9ecef',
                  border: `1px solid ${useAllDayLink ? `red` : '#ced4da'}`,
                  color: '#495057',
                  borderRadius: 5,
                  paddingLeft: 10,
                  height: 80,
                }}
              >
                <div>Missing All Day Meeting Link!</div>
                <div style={{ ...style.simpleRow, alignItems: 'stretch', height: 40 }}>
                  <span style={{ marginRight: 0 }}>
                    {`The All Day Meeting Link can be configured by clicking the `}
                    <span style={{ display: 'inline-flex', alignItems: 'center', width: 20 }}>
                      <FontAwesomeIcon icon={faLink} />
                      <span style={{ position: 'relative' }}>
                        <FontAwesomeIcon
                          icon={faCog}
                          style={{ position: 'absolute', fontSize: 10, left: -6, top: 3, color: 'inherit' }}
                        />
                      </span>
                    </span>
                    {` button above schedule. . .`}
                  </span>
                </div>
              </div>
            )}
          </div>
          <div
            style={{ ...style.simpleRow, cursor: 'pointer' }}
            onClick={async (e) => {
              if (dateIsInThePast && !willEditThePast) {
                const continueProcess = await fireConfirmationForEditingPastSchedule();
                if (!continueProcess) {
                  return;
                }
              }
              e.stopPropagation();
              setUseAllDayLink(false);
              updateEvent('VirtualRoomType', 'Custom');
            }}
          >
            <div style={{ ...style.simpleColumn, width: 50, marginBottom: 0, marginTop: 4 }}>
              <FontAwesomeIcon style={style.checkIcon} icon={!useAllDayLink ? faDotCircle : faCircle} />
            </div>
            <div style={{ ...style.simpleColumn, width: 200 }}>
              <div style={style.simpleRow}>
                Use Custom Meeting Link
                <FontAwesomeIcon id="useCustomLinkLabel" icon={faInfoCircle} style={{ marginTop: 4, marginLeft: 5 }} />
                <UncontrolledTooltip target={`useCustomLinkLabel`}>
                  {' '}
                  Use a unique link for this meeting
                </UncontrolledTooltip>
              </div>
            </div>
          </div>

          <div style={{ ...style.simpleRow, paddingLeft: 45 }}>
            <Input
              type="textarea"
              invalid={!useAllDayLink && !event.CustomMeetingUrl}
              disabled={useAllDayLink}
              name="text"
              placeholder="Enter Custom Link. . ."
              value={event.CustomMeetingUrl}
              onChange={(e) => {
                updateEvent('CustomMeetingUrl', e.target.value);
              }}
            />
          </div>
        </div>
      </div>
      <div style={{ ...style.simpleRow, alignItems: 'center', justifyContent: 'center' }}>
        <div style={{ ...style.simpleColumn, width: 100 }}>
          <Button
            color="danger"
            style={{ ...style.genButton, marginBottom: 0, paddingLeft: null, justifyContent: 'center' }}
            onClick={() => {
              setShowAddCustomPopover(false);
              Swal.fire({
                title: 'Warning!',
                text: `Are you sure you want to delete this event? This cannot be undone.`,
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: 'red',
                confirmButtonText: `Delete`,
              }).then((result) => {
                if (result.value) {
                  addRowCustomEvent({
                    pk_Timeslot: row.pk_Timeslot,
                    CustomDurationInMinutes: null,
                    CustomMeetingDescription: null,
                    CustomMeetingTitle: null,
                    CustomMeetingUrl: null,
                    CustomPassingDurationInMinutes: null,
                    VirtualRoomType: null,
                  });
                  hideHeaderPopover();
                }
              });
            }}
          >
            <div style={{ ...style.spaceBetweenRow, margin: 0 }}>
              <FontAwesomeIcon icon={faTrash} />
              {/* <div style={style.buttonLabel}>Delete</div> */}
            </div>
          </Button>
        </div>
        {/* <div style={{ ...style.simpleColumn, width: '33.33%' }}>
          <Button
            color="danger"
            style={{ ...style.genButton, marginBottom: 0, paddingLeft: null, justifyContent: 'center' }}
            onClick={() => {
              setShowAddCustomPopover(false);
            }}
          >
            <div style={{ ...style.spaceBetweenRow, margin: 0 }}>
              <FontAwesomeIcon icon={faTimes} />
              <div style={style.buttonLabel}>Cancel</div>
            </div>
          </Button>
        </div> */}
        <div style={{ ...style.simpleColumn, width: 100 }}>
          <Button
            color="success"
            disabled={!event.CustomMeetingTitle}
            style={{ ...style.genButton, justifyContent: 'center', paddingLeft: null }}
            onClick={() => {
              if (hasSplitsOrCandidates() && !row.CustomMeetingTitle) {
                Swal.fire({
                  title: 'Splits and/or assigned Candidates detected!',
                  text:
                    'Proceeding with creating this Custom Event would clear out any assigned candidates and split cells in this row. Do you want to proceed anyway?',
                  icon: 'warning',
                  showCancelButton: true,
                  cancelButtonColor: 'gray',
                  confirmButtonText: 'Create Custom Event',
                }).then((result) => {
                  if (result.isConfirmed) {
                    addCustomEvent();
                  } else {
                  }
                });
              } else {
                addCustomEvent();
              }
            }}
          >
            <div style={{ ...style.spaceBetweenRow, margin: 0 }}>
              <FontAwesomeIcon icon={faCheck} />
              {/* <div style={style.buttonLabel}>OK</div> */}
            </div>
          </Button>
        </div>
      </div>
    </div>
  );
};

export default CustomEventPopover;
