import { connect } from 'react-redux';
import { setDate, setDateArray, setSeasonType, setZoomBreakRoom } from '../../Header/Header.action';
import { setUser } from '../../Header/UserInformation/UserInformation.action';
import AdminEvaluate from './AdminEvaluate';

const mapDispatchToProps = (dispatch) => {
  return {
    setDate: (date) => dispatch(setDate(date)),
  };
};

const mapStateToProps = (state) => {
  const { date, headerHeight, dateArray } = state.header;
  return { date: date || {}, dateArray };
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminEvaluate);
