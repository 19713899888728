import React, { useState } from 'react';
import Popup from 'reactjs-popup';
import { Row } from 'reactstrap';
import styled from 'styled-components';
import EvaluateBrowser from './Body/Evaluate/EvaluateBrowser';
import EvaluateMobile from './Body/Evaluate/EvaluateMobile/EvaluateMobile';
import MobileForkContainer from './Header/MobileForkEvaluator/MobileFork.container';

const PopupWrapper = styled.div`
  .popup-overlay {
    .popup-content {
      width: auto !important;
    }
  }
`;

// bootstrap wrapper
const Wrapper = ({
  match,
  isAdmin,
  selectedEvaluator,
  isEmbedded,
  date,
  adjacentDates,
  setMyDate,
  handleDateChange,
  dateArray,
}) => {
  const [showLargePhoto, setShowLargePhoto] = useState(false);
  const [largePhoto, setLargePhoto] = useState(null);
  return (
    <div>
      {/* pop needs to be at this level to keep other elements from having a higher z-index */}
      <PopupWrapper>
        <Popup open={showLargePhoto} onClose={() => setShowLargePhoto(false)}>
          <img src={largePhoto} style={{ maxWidth: '70vw', height: 'auto', maxHeight: '70vh', width: 'auto' }} />
        </Popup>
      </PopupWrapper>
      <Row>
        <MobileForkContainer
          date={date}
          isAdmin={isAdmin}
          isEmbedded={isEmbedded}
          selectedEvaluator={selectedEvaluator}
          setShowLargePhoto={setShowLargePhoto}
          setLargePhoto={setLargePhoto}
          match={match}
          MobileComponent={EvaluateMobile}
          BrowserComponent={EvaluateBrowser}
          adjacentDates={adjacentDates}
          setMyDate={setMyDate}
          handleDateChange={handleDateChange}
          dateArray={dateArray}
        />
      </Row>
    </div>
  );
};

export default Wrapper;
