import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell, faExclamationCircle, faTimes } from '@fortawesome/free-solid-svg-icons';
import { Button } from 'reactstrap';
import { useAlert } from 'react-alert';

const NotifAlertTemplate = ({ style, options, message, close }) => {
  const { type } = options;

  return (
    <div
      style={{
        ...style,
        display: 'flex',
        width: 400,
        backgroundColor: type === 'error' ? '#ff4545' : 'black',
        opacity: '0.9',
        padding: 10,
        color: 'white',
        marginTop: 10,
        marginBotom: 10,
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: '20%',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            width: '100%',
            justifyContent: 'center',
          }}
        >
          <FontAwesomeIcon icon={type === 'error' ? faExclamationCircle : faBell} style={{ fontSize: 26 }} />
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: '60%',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {message}
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: '20%',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            width: '100%',
            justifyContent: 'center',
            cursor: 'pointer',
          }}
          onClick={() => {
            close();
          }}
        >
          <Button
            outline
            size="sm"
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              border: type === 'error' ? 'solid white 1px' : null,
            }}
          >
            <FontAwesomeIcon
              icon={faTimes}
              style={{ fontSize: 20, cursor: 'pointer', color: type === 'error' ? 'white' : null }}
            />
          </Button>
        </div>
      </div>
    </div>
  );
};

export default NotifAlertTemplate;
