import React, { useContext, useEffect } from 'react';
import { DepartmentContext } from '../DepartmentWrapper';

const SeasonChanger = ({ children }) => {
  const dContext = useContext(DepartmentContext);
  const { pk_Season } = dContext.season || 1;
  const { EnablePrescreenMode } = dContext.season || {};

  return <div key={`key_${pk_Season}_${EnablePrescreenMode}`}>{children}</div>;
};

export default SeasonChanger;
