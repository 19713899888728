const style = {
  spacedBetweenRow: {
    flexDirection: 'row',
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
  simpleColumn: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    alignItems: 'center',
  },
  simpleRow: {
    flexDirection: 'row',
    display: 'flex',
    marginBottom: 10,
  },
  filterButton: {
    width: '100%',
  },
  clickable: {
    cursor: 'pointer',
  },
};

export default style;
