import React, { useState } from 'react';
import OrganizationList from './Organizations/OrganizationList';
import { Popover, PopoverBody, PopoverHeader, Button } from 'reactstrap';
import DepartmentList from './Organizations/Departments/DepartmentList';
import DepartmentCreationAndEdit from './Organizations/Departments/DepartmentCreationAndEdit';
import OrganizationCreationAndEditWidget from './Organizations/OrganizationCreationAndEditWidget';
import Style from './EntityWrapper.style';

import { Col, Row } from 'reactstrap';

const disableOverlay = {
  backgroundColor: 'rgba(0,0,0,0.2)',
  position: 'absolute',
  top: '0',
  left: '15px',
  width: 'calc(100% - 30px)',
  height: 'calc(100vh - 255px)',
  zIndex: '100',
};

const EntityWrapper = () => {
  const [selectedOrganization, setSelectedOrganization] = useState(null);
  const [departments, setDepartments] = useState([]);
  const [organizations, setOrganizations] = useState([]);
  const [departmentLogo, setDepartmentLogo] = useState('');
  const [departmentName, setDepartmentName] = useState('');
  const [departmentReplyToAddress, setDepartmentReplyToAddress] = useState('');
  const [departmentReplyToName, setDepartmentReplyToName] = useState('');
  const [departmentShortName, setDepartmentShortName] = useState('');
  const [departmentWebsite, setDepartmentWebsite] = useState('');
  const [selectedDepartment, setSelectedDepartment] = useState(null);
  const [orgPopoverOpen, setOrgPopoverOpen] = useState(false);

  const renderDepartmentName = () => {
    if (selectedDepartment) {
      if (departmentName && departmentName.trim().length > 0) {
        return departmentName;
      } else {
        return 'Loading...';
      }
    }

    return 'Create New Department';
  };

  const addNewOrganizationToList = (organization) => {
    setOrganizations((prevState) => {
      // eslint-disable-next-line
      let clone = structuredClone(prevState);
      clone.unshift(organization);
      return clone;
    });
  };

  const updateOrganizationArrayValues = (data) => {
    setOrganizations((prevState) => {
      // eslint-disable-next-line
      let clone = structuredClone(prevState);

      clone.forEach((item) => {
        if (item.pk_Organization === data.pk_Organization) {
          item.OrganizationName = data.OrganizationName;
          item.OrganizationShortName = data.OrganizationShortName;
          item.OrganizationWebsite = data.OrganizationWebsite;
        }
      });
      return clone;
    });
  };

  return (
    <Row>
      <Col md={3}>
        <div style={Style.wrapper}>
          <OrganizationList
            setSelectedOrganization={setSelectedOrganization}
            selectedOrganization={selectedOrganization}
            organizations={organizations}
            addNewOrganizationToList={addNewOrganizationToList}
            setOrganizations={setOrganizations}
            updateOrganizationArrayValues={updateOrganizationArrayValues}
          />
        </div>
      </Col>
      <Col md={3}>
        <div style={Style.wrapper}>
          <DepartmentList
            selectedOrganization={selectedOrganization}
            setSelectedDepartment={setSelectedDepartment}
            organizations={organizations}
            departments={departments}
            setDepartments={setDepartments}
            selectedDepartment={selectedDepartment}
          />
        </div>
      </Col>
      <Col md={6}>
        <div style={Style.headerWrapper}>{renderDepartmentName()}</div>
        {!selectedDepartment && !selectedOrganization && <div style={disableOverlay} />}
        <div style={{ height: 'calc(100vh - 295px)' }}>
          <DepartmentCreationAndEdit
            selectedDepartment={selectedDepartment}
            departments={departments}
            departmentReplyToAddress={departmentReplyToAddress}
            setDepartmentReplyToAddress={setDepartmentReplyToAddress}
            departmentReplyToName={departmentReplyToName}
            setDepartmentReplyToName={setDepartmentReplyToName}
            selectedOrganization={selectedOrganization}
            departmentLogo={departmentLogo}
            setDepartmentLogo={setDepartmentLogo}
            departmentName={departmentName}
            setDepartmentName={setDepartmentName}
            departmentShortName={departmentShortName}
            setDepartmentShortName={setDepartmentShortName}
            organizations={organizations}
            departmentWebsite={departmentWebsite}
            setDepartmentWebsite={setDepartmentWebsite}
            setSelectedDepartment={setSelectedDepartment}
            setDepartments={setDepartments}
          />
        </div>
      </Col>
    </Row>
  );
};

export default EntityWrapper;
