import React, { useContext, useEffect, useState } from 'react';
import ManageFields from './ManageFields/ManageFields';
import Tags from '../Tags/Tags';
import DocumentTypesPopover from '../../AdminCandidates/DocumentTypesPopover';

import style from './CandidateSettings.style';
import { Nav, NavItem } from 'reactstrap';
import { Link, useRouteMatch } from 'react-router-dom';
import Groups from '../Groups/Groups';
import { DepartmentContext } from 'DepartmentWrapper';

const CandidateSettings = () => {
  const [currentTab, setCurrentTab] = useState(1);
  const [tabToGo, setTabToGo] = useState(1);
  const [hasChanges, setHasChanges] = useState();
  const [changes, setChanges] = useState({});
  const tabs = [
    {
      label: 'Manage Fields',
      component: <ManageFields />,
      path: '/candidateSettings/manage_fields',
      tab: 1,
    },
    {
      label: 'Document Types',
      component: <DocumentTypesPopover />,
      path: '/candidateSettings/document_types',
      tab: 2,
    },
    {
      label: 'Manage Tags',
      component: <Tags />,
      path: '/candidateSettings/tags',
      tab: 3,
    },
    {
      label: 'Manage Groups',
      component: <Groups />,
      path: '/candidateSettings/groups',
      tab: 4,
    },
  ];

  const dContext = useContext(DepartmentContext);
  const { department } = dContext || {};
  const { EnableGroups } = department || {};
  const { pathname } = window.location;
  const { path } = useRouteMatch();

  useEffect(() => {
    if (pathname) {
      tabs.forEach((tab) => {
        if (pathname.indexOf(tab.path) >= 0) {
          if (currentTab != tab.tab) {
            setCurrentTab(tab.tab);
          }
        }
      });
    }
  }, [pathname]);

  const renderTabs = () => {
    return tabs.map((tab, index) => {
      if (tab.tab == 4 && !EnableGroups) {
        return;
      }
      return (
        <NavItem className="nav-item" key={index}>
          <Link
            className={'nav-link ' + (currentTab === tab.tab ? 'active' : '')}
            to={`${path}${tab.path}`}
            onClick={() => {
              if (!hasChanges) {
                setCurrentTab(tab.tab);
              }
              setTabToGo(tab.tab);
            }}
          >
            <i className="fa fa-paper-plane"></i> {tab.label}
          </Link>
        </NavItem>
      );
    });
  };

  const renderTabContent = () => {
    return tabs[currentTab - 1].component;
  };

  return (
    <div style={style.simpleRow}>
      <div style={{ ...style.simpleColumn, width: '100%' }}>
        <Nav className="nav-tabs">{renderTabs()}</Nav>
        <div style={{ ...style.simpleRow, paddingTop: 20, height: '50vh' }}>{renderTabContent()}</div>
      </div>
    </div>
  );
};

export default CandidateSettings;
