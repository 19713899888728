import { faInfoCircle, faTriangleExclamation, faUser, faUserTie } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment-timezone';
import React from 'react';
import { Button, UncontrolledTooltip } from 'reactstrap';
import style from './FlexEventItem.style';
import './FlexEventItem.style.css';

const FlexEventItem = ({ flexEvent, setFlexEventToEdit, scheduleData, VirtualMeetingMode }) => {
  const { FlexTimeEnd, FlexDateEnd, FlexTimeStart, FlexDateStart } = flexEvent;
  const flexEventStart = moment.tz(`${FlexDateStart} ${FlexTimeStart}`, null); //moment.tz(flexEvent.FlexStartTime || flexEvent.StartDateTime, null);
  const flexEventEnd = moment.tz(`${FlexDateEnd} ${FlexTimeEnd}`, null); // moment.tz(flexEvent.FlexTimeEnd || flexEvent.EndDateTime, null);

  const { metaData = {} } = scheduleData || {};
  const { MainRoomURL = '' } = metaData || {};
  const { UrlType, CustomUrl, FlexEventName } = flexEvent;

  const isMainRoomAndNoLink = FlexEventName && UrlType === 'AllDay' && !MainRoomURL;
  const isCustomAndNoLink = FlexEventName && UrlType === 'Custom' && !CustomUrl;
  const urlIssue = VirtualMeetingMode !== 'Disabled' && (isMainRoomAndNoLink || isCustomAndNoLink);

  return (
    <div
      style={{
        ...style.simpleRow,
        borderBottom: '1px solid gray',
        paddingTop: 20,
        paddingBottom: 20,
        paddingLeft: 10,
        paddingRight: 10,
        cursor: 'pointer',
      }}
      className="flexEventItem"
      onClick={() => {
        setFlexEventToEdit(flexEvent);
      }}
    >
      <div style={{ ...style.simpleColumn, width: '30%', marginRight: 20 }}>
        <div style={{ color: '#393939' }}>
          {flexEventStart.format('hh:mm A')} - {flexEventEnd.format('hh:mm A')}
        </div>
      </div>
      <div style={{ ...style.simpleColumn, width: '20%', marginRight: 20, color: '#393939' }}>
        {`${flexEventEnd.diff(flexEventStart, 'minutes')} minutes`}
      </div>
      <div style={{ ...style.simpleColumn, width: '40%' }}>
        <div style={style.simpleRow}>
          {' '}
          {flexEvent.FlexEventName || flexEvent.EventName}{' '}
          {urlIssue ? (
            <>
              <FontAwesomeIcon
                icon={faTriangleExclamation}
                style={{ cursor: 'pointer', color: 'red', marginTop: 3, marginLeft: 10 }}
                id={`flexEvent_${flexEvent.pk_FlexEvent}_infoIcon`}
              />
              <UncontrolledTooltip target={`flexEvent_${flexEvent.pk_FlexEvent}_infoIcon`}>
                Warning: {isCustomAndNoLink ? `Custom Event URL is missing!` : `Main Room URL is missing!`}
              </UncontrolledTooltip>
            </>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default FlexEventItem;
