import React from 'react';
import { Button, Input } from 'reactstrap';
import style from './AdminEvaluationItem.style';

const AdminEvaluationLikertItem = ({ item, i, updateQuestion, length, isUpdatingOptions, isReadOnly }) => {
  let typeTimeout = null;

  return (
    <div style={{ ...style.simpleColumn, paddingLeft: 5, paddingRight: 5, width: `${100 / length}%` }}>
      <div style={style.simpleRow}>
        <div style={{ ...style.simpleColumn, width: '100%', alignItems: 'center' }}>
          <Button
            disabled={isUpdatingOptions || isReadOnly}
            outline
            color="primary"
            style={{ borderRadius: '50%', width: '50px', height: '50px' }}
          >
            {item.DisplayValue}
          </Button>
        </div>
      </div>
      <div style={{ marginTop: 10 }} />
      {isReadOnly ? null : (
        <div style={style.simpleRow}>
          <div style={{ ...style.simpleColumn, alignItems: 'center', fontWeight: 'bold', width: '100%' }}>
            Label {item.Number}
          </div>
        </div>
      )}

      <div style={style.simpleRow}>
        <div style={{ ...style.simpleColumn, width: '100%' }}>
          {isReadOnly ? (
            <div style={{ width: '100%', textAlign: 'center', fontWeight: 'bold' }}>{item.Label || <br />}</div>
          ) : (
            <Input
              disabled={isUpdatingOptions || isReadOnly}
              type="textarea"
              defaultValue={item.Label}
              onChange={(e) => {
                const newValue = e.target.value;
                if (typeTimeout) {
                  clearTimeout(typeTimeout);
                }
                typeTimeout = setTimeout(() => {
                  updateQuestion(i, 'Label', newValue);
                }, 300);
              }}
              style={{ textAlign: 'center' }}
            />
          )}
        </div>
      </div>

      {isReadOnly ? null : (
        <div style={style.simpleRow}>
          <div style={{ ...style.simpleColumn, width: '100%' }}>
            <div style={{ textAlign: 'center', fontStyle: 'italic' }}>
              {i == 0 ? (
                'Worst'
              ) : (length == 10 && (i == 4 || i == 5)) || (length == 5 && i == 2) ? (
                'Average'
              ) : i == length - 1 ? (
                'Best'
              ) : (
                <br />
              )}
            </div>
          </div>
        </div>
      )}

      <div style={{ ...style.simpleRow, height: '20px' }}></div>

      {isReadOnly ? null : (
        <div style={style.simpleRow}>
          <div style={{ ...style.simpleColumn, alignItems: 'center', fontWeight: 'bold', width: '100%' }}>
            Description {item.number}
          </div>
        </div>
      )}
      <div style={style.simpleRow}>
        <div style={{ ...style.simpleColumn, width: '100%' }}>
          {isReadOnly ? (
            <div style={{ width: '100%', textAlign: 'center' }}>{item.Description || <br />}</div>
          ) : (
            <Input
              disabled={isUpdatingOptions || isReadOnly}
              type="textarea"
              defaultValue={item.Description}
              onChange={(e) => {
                const newValue = e.target.value;
                if (typeTimeout) {
                  clearTimeout(typeTimeout);
                }
                typeTimeout = setTimeout(() => {
                  updateQuestion(i, 'Description', newValue);
                }, 300);
              }}
              style={{ textAlign: 'center' }}
              rows="4"
              placeholder="Optional"
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default AdminEvaluationLikertItem;
