import React, { useState } from 'react';
import { Button, Col, Row } from 'reactstrap';

const buttonStyle = {
  border: '2px solid rbg(51,51,51)',
  backgroundColor: '#fff',
  color: 'rbg(51,51,51) !important',
};

const BinaryQuestion = ({ question, i, questionaireDisabled }) => {
  const [isYes, setIsYes] = useState(null);

  const toggleNo = () => {
    setIsYes(true);
  };

  const toggleYes = () => {
    setIsYes(false);
  };

  const constructButtonClassNames = (trueOrFalse) => {
    return 'blue-hover ' + (isYes === trueOrFalse ? 'selectedTab' : 'unselectedTab');
  };

  return (
    <Row>
      <Col style={{ padding: 0 }} sm="6">
        <p style={{ fontWeight: 700 }}>
          <span style={{ fontWeight: 700 }}>{i + 1}. </span>
          {question.QuestionsVLQuestionText}
        </p>
      </Col>
      <Col sm="6" style={{ display: 'flex', alignItems: 'center' }}>
        <div style={{ display: 'flex', justifyContent: 'space-evenly', width: '100%' }}>
          <Button
            tabIndex={questionaireDisabled ? -1 : 0}
            onClick={toggleNo}
            className={constructButtonClassNames(true)}
          >
            No
          </Button>
          <Button
            tabIndex={questionaireDisabled ? -1 : 0}
            onClick={toggleYes}
            className={constructButtonClassNames(false)}
          >
            Yes
          </Button>
        </div>
      </Col>
    </Row>
  );
};

export default BinaryQuestion;
