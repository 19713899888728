const style = {
  markFilter: {
    width: 250,
    alignSelf: 'center',
    textAlign: 'center',
  },
  markFilterContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    marginBottom: 20,
  },
  tableHeaderCaret: { marginLeft: 10, cursor: 'pointer' },
  cellStyle: (row) => {
    const styleActual = { alignItems: 'center', justifyContent: 'center', verticalAlign: 'middle' };

    if (row.isSelected) {
      styleActual.backgroundColor = '#caecfa';
    }
    return styleActual;
  },
  clickable: {
    cursor: 'pointer',
  },
  simpleRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    verticalAlign: 'middle',
  },
  spacedBetweenRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    verticalAlign: 'middle',
  },
  buttonLabel: {
    width: '100%',
    fontWeight: 'bold',
    fontSize: 13,
    marginLeft: 10,
  },
  actionButton: {
    marginLeft: 10,
    width: 200,
  },
  listItem: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    textAlign: 'center',
    justifyContent: 'space-between',
    padding: 5,
    cursor: 'pointer',
  },
  evaluatorList: {
    marginBottom: 10,
    marginTop: 10,
    maxHeight: 250,
    overflowY: 'scroll',
  },
  evaluatorListButton: {
    marginBottom: 5,
    width: '100%',
  },
};

export default style;
