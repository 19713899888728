import { faCheckCircle, faCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useRef, useState } from 'react';
import { Button, Input } from 'reactstrap';
import { clone } from '../../../../../../Services/schedule';
import style from './style';
import { removeTrailingNewLines } from 'Services/candidates';
let typeTimeout = null;

const ScoresAndAwards = ({
  selectedCandidate = {},
  dataTypes = [],
  saveResults,
  setChanges,
  changes,
  resetFunctions,
  isSaving,
  isGettingCandidateDetails,
  getCandidateDetails,
}) => {
  const { candidateScoresAndAwards = [] } = selectedCandidate || {};
  const [candidate, setCandidate] = useState({});
  const [dataTypeNames, setDataTypeNames] = useState([]);
  const [fieldsToUpdate, setFieldsToUpdate] = useState({});
  const [hasChanges, setHasChanges] = useState(false);
  const dataReference = useRef(); // We use a dataReference useRef object because passing discardChanges as a function to resetFunctions can't get the curr
  /**
   * 1 = String
   * 2 = Number
   * 3 = Bool, Yes or No
   * 4 = DateTime
   * 5 = Phone
   * 6 = Street Address
   */

  //   First Name
  // Middle Name
  // Last Name
  // Preferred Name
  // Preferred First
  // Preferred Middle
  // Preferred Last
  // Email
  // Phone Number
  // Cell Phone
  // Alternate Phone
  // Permanent Phone
  // AAMCID
  // NRMPID
  // SFMatchID

  const oneLinerFields = [
    'FirstName',
    'MiddleName',
    'LastName',
    'PreferredName',
    'PreferredFirst',
    'PreferredMiddle',
    'PreferredLast',
    'Email',
    'PhoneNumber',
    'CellPhone',
    'AlternatePhone',
    'PermanentPhone',
    'PreferredPhone',
    'AAMCID',
    'NRMPID',
    'SFMatchID',
  ];
  useEffect(() => {
    if (selectedCandidate) {
      dataReference.current = clone(selectedCandidate);
      setCandidate(clone(selectedCandidate));
      setHasChanges(false);
    }
  }, [JSON.stringify(selectedCandidate)]);

  useEffect(() => {
    if (dataTypes) {
      const newDataTypeNames = [];
      dataTypes.forEach((dataType) => {
        newDataTypeNames.push(dataType.Name);
      });
      setDataTypeNames(newDataTypeNames);
    }
  }, [dataTypes]);

  useEffect(() => {
    checkChanges();
  }, [candidate]);

  useEffect(() => {
    if (changes && setChanges) {
      const newChanges = clone(changes);
      newChanges.scoresAndAwards = hasChanges;
      setChanges(newChanges);
    }
  }, [hasChanges]);

  useEffect(() => {
    if (resetFunctions) {
      if (resetFunctions.current == null) {
        resetFunctions.current = {};
      }
      if (resetFunctions.current.scoresAndAwards == null) {
        resetFunctions.current.scoresAndAwards = discardChanges;
      }
    }
  }, [resetFunctions]);

  const checkChanges = () => {
    let newHasChanges = false;
    if (candidate && dataReference.current) {
      Object.keys(candidate).forEach((cKey) => {
        const currentValue = candidate[cKey] || '';
        const referenceValue = dataReference.current[cKey] || '';

        if (dataTypeNames.includes(cKey) && currentValue != referenceValue) {
          newHasChanges = true;
        }
      });

      setHasChanges(newHasChanges);
    }
  };

  const discardChanges = () => {
    setCandidate(clone(dataReference.current));
  };

  const updateCandidate = (key, value) => {
    const newCandidate = clone(candidate);

    newCandidate[key] = value;
    setCandidate(clone(newCandidate));
  };

  const saveCandidate = () => {
    const newCandidate = clone(candidate);
    const fieldsToUpdate = {};
    Object.keys(newCandidate).forEach((cKey) => {
      if (dataTypeNames.includes(cKey)) {
        fieldsToUpdate[cKey] = candidate[cKey]
          ? removeTrailingNewLines(candidate[cKey].replace(/\n{3,}/g, '\n\n'))
          : '';
      }
    });

    saveResults({
      candidatesToUpdate: [candidate.pk_Candidate],
      fieldsToUpdate,
      forcedSelectedCandidateId: candidate.pk_Candidate,
      callback: (success) => {
        if (success) {
          setHasChanges(false);
          getCandidateDetails(candidate, {
            callback: (newCandidate) => {
              dataReference.current = clone(newCandidate);
              setCandidate(newCandidate);
            },
          });
        }
      },
    });
  };

  const renderInput = (dataType, i) => {
    const { UIType, Type } = dataType;
    const typeToUse = Type;
    const value = candidate[dataType.Name];

    // replace triple newLines with double newLines. DO NOT trim trailing newLines when typing.
    // When saving field, limit to two trailing newLines.

    switch (typeToUse) {
      case 1:
        return (
          <>
            <Input
              autoComplete="off"
              autocomplete={`field_${i}`}
              disabled={dataType.ReadOnly || isSaving || isGettingCandidateDetails}
              id={`Input_${dataType.Name}_${candidate.pk_Candidate}_${candidate[dataType.Name]}`}
              value={candidate[dataType.Name] != null ? parseInt(candidate[dataType.Name]) : ''}
              type="number"
              placeholder={dataType.ReadOnly ? 'No Data' : `Enter ${dataType.Alias}. . .`}
              onChange={(e) => {
                updateCandidate(dataType.Name, e.target.value);
              }}
            />
          </>
        );

      case 2:
        return (
          <Input
            autoComplete="off"
            autocomplete={`field_${i}`}
            disabled={dataType.ReadOnly || isSaving || isGettingCandidateDetails}
            id={`Input_${dataType.Name}_${candidate.pk_Candidate}`}
            value={candidate[dataType.Name] ? candidate[dataType.Name].trimStart() : ''}
            type={oneLinerFields.includes(dataType.Name) ? 'text' : 'textarea'}
            // type={candidate[dataType.Name] && candidate[dataType.Name].length < 20 ? 'text' : 'textarea'}
            rows={
              !candidate[dataType.Name] ||
              (candidate[dataType.Name] &&
                candidate[dataType.Name].length < 100 &&
                !candidate[dataType.Name].trimStart().includes('\n') &&
                !oneLinerFields.includes(dataType.Name))
                ? 1
                : 3
            }
            placeholder={dataType.ReadOnly ? 'No Data' : `Enter ${dataType.Alias}. . .`}
            onChange={(e) => {
              const newValue = e.target.value ? e.target.value : '';
              updateCandidate(dataType.Name, newValue.trimStart());
            }}
          />
        );
      case 3:
        return (
          <div style={style.simpleRow}>
            <div style={style.checkBoxContainer}>
              <div style={style.checkBoxSubContainer}>
                <FontAwesomeIcon
                  style={style.checkIcon}
                  icon={value && value.toLowerCase() == 'yes' ? faCheckCircle : faCircle}
                  onClick={() => {
                    if (dataType.ReadOnly || isSaving || isGettingCandidateDetails) return;

                    updateCandidate(dataType.Name, 'Yes');
                  }}
                />
                <div
                  style={style.checkBoxText}
                  onClick={() => {
                    if (dataType.ReadOnly || isSaving || isGettingCandidateDetails) return;
                    updateCandidate(dataType.Name, 'Yes');
                  }}
                >
                  Yes
                </div>
              </div>
            </div>
            <div style={style.checkBoxContainer}>
              <div style={style.checkBoxSubContainer}>
                <FontAwesomeIcon
                  style={style.checkIcon}
                  icon={value && value.toLowerCase() == 'yes' ? faCircle : faCheckCircle}
                  onClick={() => {
                    if (dataType.ReadOnly || isSaving || isGettingCandidateDetails) return;
                    updateCandidate(dataType.Name, 'No');
                  }}
                />
                <div
                  style={style.checkBoxText}
                  onClick={() => {
                    if (dataType.ReadOnly || isSaving || isGettingCandidateDetails) return;
                    updateCandidate(dataType.Name, 'No');
                  }}
                >
                  No
                </div>
              </div>
            </div>
          </div>
        );
      case 4:
        return (
          <Input
            autoComplete="off"
            autocomplete={`field_${i}`}
            disabled={dataType.ReadOnly || isSaving || isGettingCandidateDetails}
            id={`Input_${dataType.Name}_${candidate.pk_Candidate}`}
            value={candidate[dataType.Name]} //{moment(candidate[dataType.Name]).format('MMM DD, YYYY')}
            placeholder={dataType.ReadOnly ? 'No Data' : `Enter ${dataType.Alias}. . .`}
            type="text"
            onChange={(e) => {
              updateCandidate(dataType.Name, e.target.value);
            }}
          />
        );

      case 5:
        return (
          <Input
            autoComplete="off"
            autocomplete={`field_${i}`}
            disabled={dataType.ReadOnly || isSaving || isGettingCandidateDetails}
            id={`Input_${dataType.Name}_${candidate.pk_Candidate}`}
            value={candidate[dataType.Name]}
            placeholder={dataType.ReadOnly ? `No Data` : `Enter ${dataType.Alias}. . .`}
            type="tel"
            onChange={(e) => {
              updateCandidate(dataType.Name, e.target.value);
            }}
          />
        );
      case 6:
        return (
          <Input
            autoComplete="off"
            autocomplete={`field_${i}`}
            disabled={dataType.ReadOnly || isSaving || isGettingCandidateDetails}
            id={`Input_${dataType.Name}_${candidate.pk_Candidate}`}
            value={candidate[dataType.Name]}
            placeholder={dataType.ReadOnly ? 'No Data' : `Enter ${dataType.Alias}. . .`}
            type="text"
            onChange={(e) => {
              updateCandidate(dataType.Name, e.target.value);
            }}
          />
        );

      default:
        return <div>Unknown UI Type: {typeToUse}</div>;
        break;
    }
  };

  return (
    <div style={{ ...style.simpleColumn, width: '100%' }}>
      <div
        style={{
          ...style.simpleRow,
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: 'white',
          position: 'sticky',
          top: 0,
          paddingBottom: 10,
        }}
      >
        <Button
          disabled={!hasChanges}
          color="success"
          style={{ width: 100 }}
          onClick={() => {
            saveCandidate();
          }}
        >
          Save
        </Button>
      </div>
      <div style={{ ...style.simpleRow, height: `100%`, width: 'calc(100% - 20px)' }}>
        <div style={style.fieldsContainer}>
          {dataTypes.map((dataType, i) => {
            if (selectedCandidate) {
              return (
                <div style={{ ...style.simpleRow, backgroundColor: i % 2 ? null : '#cad0db', padding: 10 }}>
                  <div style={{ ...style.simpleColumn, fontWeight: 'bold', width: '35%', alignSelf: 'center' }}>
                    {dataType.Alias}:
                  </div>
                  <div style={{ ...style.simpleColumn, width: '65%', paddingLeft: 20, alignItems: 'center' }}>
                    {renderInput(dataType, i)}
                    {/* <Input
                  defaultValue={selectedCandidate[dataType.Name]}
                  onChange={(e) => {
                    updateCandidate(dataType.Name, e.target.value);
                  }}
                /> */}
                  </div>
                </div>
              );
            }
          })}
        </div>
      </div>
    </div>
  );
};

export default ScoresAndAwards;

// tabs
// scores and awards
// additional details
// waitlist
// communication

// down below candidate information

// just checkboxes for custom fields

// show custom ones
