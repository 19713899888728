const style = {
  simpleColumn: {
    display: 'flex',
    flexDirection: 'column',
  },
  simpleRow: {
    display: 'flex',
    flexDirection: 'row',
  },

  spacedBetweenRow: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'spaced-between',
  },

  button: {
    width: '100%',
    marginBottom: 10,
  },
  centeredText: {
    textAlign: 'center',
    width: '100%',
  },
};

export default style;
