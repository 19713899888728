import React, { useState, useEffect } from 'react';
import { Popover, PopoverHeader, PopoverBody, Button } from 'reactstrap';
import SeasonCreationAndEditWidget from './SeasonCreationAndEditWidget';
import Swal from 'sweetalert2';
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAuth0 } from '../../../../../auth0/reactAuth0Spa';
import { formatBearerToken, swalErrorMessage } from '../../../../../Common.functions';
import { putData } from '../../../../../Services/dataApi';

const SeasonListItem = ({
  CandidatesRegistered,
  CandidatesTotal,
  EnablePrescreenMode,
  updateSeasonArray,
  seasonPopoverOpen,
  SeasonName,
  handleSeasonToggle,
  pk_Season,
  InterviewDateCount,
}) => {
  const [seasonName, setSeasonName] = useState(SeasonName);

  const { getTokenSilently } = useAuth0();

  const saveSeasonName = (e) => {
    e.preventDefault();
    getTokenSilently()
      .then((token) => {
        putData(
          'organization/department/season',
          {
            pk_Season,
          },
          {
            payload: {
              SeasonName: seasonName,
            },
          },
          formatBearerToken(token),
        )
          .then((res) => {
            if (res.status === 200) {
              updateSeasonArray(res.data);
              Swal.fire({
                title: 'Success!',
                text: 'Department settings have been updated!',
                icon: 'success',
              });
            } else {
              swalErrorMessage();
            }
          })
          .catch((err) => {
            swalErrorMessage();
          });
      })
      .catch((err) => {
        swalErrorMessage();
      });
  };

  // useEffect to update the season name
  useEffect(() => {
    setSeasonName(SeasonName);
  }, [SeasonName]);

  return (
    <tr>
      <div className="admin_popover">
        <Popover
          placement="top"
          isOpen={seasonPopoverOpen === pk_Season}
          className="admin_popover"
          modifiers={{ flip: { behavior: ['top'] } }}
          trigger="legacy"
          target={`Popover_${pk_Season}`}
          toggle={(e) => {
            handleSeasonToggle(e, pk_Season);
          }}
        >
          <PopoverHeader>Season</PopoverHeader>
          <PopoverBody>
            <SeasonCreationAndEditWidget seasonName={seasonName} setSeasonName={setSeasonName} />
          </PopoverBody>
          <Button
            color="success"
            onClick={(e) => {
              saveSeasonName(e);
            }}
          >
            Save
          </Button>
        </Popover>
      </div>
      <td>{SeasonName}</td>
      <td>{`${CandidatesRegistered}/${CandidatesTotal}`}</td>
      <td>{InterviewDateCount}</td>
      <td>{EnablePrescreenMode ? 'Prescreen' : 'Evaluation'}</td>
      <td
        id={`Popover_${pk_Season}`}
        onClick={(e) => {
          handleSeasonToggle(e, pk_Season);
        }}
      >
        <FontAwesomeIcon icon={faPencilAlt} />
      </td>
    </tr>
  );
};

export default SeasonListItem;
