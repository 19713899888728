import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useAuth0 } from '../../../../auth0/reactAuth0Spa';
import { formatBearerToken } from '../../../../Common.functions';
import { fetchDataAuth0 } from '../../../../Services/dataApi';
import Schedule from '../../Coordinator/Schedule';

const AdminSchedule = ({ activeDepartment, selectedScheduleItem, history }) => {
  const [schedule, setSchedule] = useState(null);
  const { user, isAuthenticated, loginWithRedirect, getTokenSilently, logout } = useAuth0();

  return (
    <div>
      <Schedule schedule={schedule} history={history} allowScheduleInteraction={true} />
    </div>
  );
};

export default AdminSchedule;
