import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import htmlToPdfmake from 'html-to-pdfmake';
import imageToBase64 from 'image-to-base64';

import moment from 'moment-timezone';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import tinycolor from 'tinycolor2';
import { clone } from '../schedule';
pdfMake.vfs = pdfFonts.pdfMake.vfs;

const tableLayouts = {
  padlessTable: {
    hLineWidth: (i, node) => {
      if (i === 0 || i === node.table.body.length) {
        return 0;
      }
      return i === node.table.headerRows ? 1 : 1;
    },
    vLineWidth: (i) => {
      return 0;
    },
    hLineColor: (i) => {
      return i === 1 ? 'black' : '#aaa';
    },
    paddingLeft: (i) => {
      return 0;
    },
    paddingRight: (i, node) => {
      return 0;
    },
    paddingTop: (i, node) => {
      return 0;
    },
    paddingBottom: (i, node) => {
      return 0;
    },
  },
};

const createPageHeader = (data) => {
  const { interviewDateRow, base64Img, department } = data;
  const { scheduleName, interviewDate } = interviewDateRow;
  const { OrganizationName, DepartmentName } = department;
  const columns = [
    [
      {
        text: `${OrganizationName}`,
        alignment: 'left',
        fontSize: 5,
        bold: true,
        margin: [5, 2, 10, 0],
        width: 'auto',
        maxHeight: 15,
      },
      {
        text: `${DepartmentName}`,
        alignment: 'left',
        fontSize: 5,
        bold: false,
        margin: [5, 0, 10, 0],
        width: 'auto',
        maxHeight: 15,
      },
      {
        text: `${interviewDate ? moment.tz(interviewDate, null).format('dddd, MMMM DD, YYYY') : 'No Date'} ${
          scheduleName ? `(${scheduleName})` : ''
        }`,
        alignment: 'left',
        fontSize: 4,
        bold: false,
        margin: [5, 0, 10, 0],
        width: 'auto',
        maxHeight: 15,
      },
    ],
  ];

  if (base64Img && (base64Img[0] === '/' || base64Img[0] === 'i')) {
    columns.splice(0, 0, {
      image: base64Img ? `data:image/png;base64,${base64Img}` : null,
      width: 20,
      height: 20,
    });
  } else {
    columns.splice(0, 0, {
      text: ' ',
      width: 30,
    });
  }

  return {
    columns,
    margin: [10, 10, 10, 0],
  };
};

const createHeaders = (scheduleData, columnTracker, PDFFontSize) => {
  const { headers } = scheduleData;
  const tableHeaders = [];
  const rawTableHeadersWithRooms = [];
  const rawTableHeadersWithoutRooms = [];

  const headerOrder = [];
  headers.forEach((header) => {
    const { Evaluators = [], RoomLabel = '' } = header;
    let headerLabel = '';
    let headerNames = [];
    const key = header.pk_ScheduleAssignment.toString();
    const candidateCount = columnTracker[key] ? columnTracker[key].length : 0;
    if (header.Evaluators) {
      header.Evaluators.Evaluators.forEach((e, i) => {
        headerNames.push(`${e.UserFirst ? `${e.UserFirst[0]}.` : ''} ${e.UserLast}`);
      });
    }

    headerNames.sort();
    headerNames = headerNames.join(',');
    headerLabel += `${headerNames.replace(/,/g, ', ')}`;

    // headerOrder.push({
    //   pk_ScheduleAssignment: header.pk_ScheduleAssignment,
    //   Label: headerLabel,
    //   RoomLabel: RoomLabel || '',
    // });

    if (RoomLabel) {
      rawTableHeadersWithRooms.push([
        {
          text: headerLabel,
          style: { fontSize: PDFFontSize, bold: true, alignment: 'center' },
          pk_ScheduleAssignment: header.pk_ScheduleAssignment,
        },
        { text: `${RoomLabel || ' '}`, style: { fontSize: PDFFontSize - 1, alignment: 'center' } },
      ]);
    } else {
      rawTableHeadersWithoutRooms.push([
        {
          text: headerLabel,
          style: { fontSize: PDFFontSize, bold: true, alignment: 'center' },
          pk_ScheduleAssignment: header.pk_ScheduleAssignment,
        },
        { text: `${RoomLabel || ' '}`, style: { fontSize: PDFFontSize - 1, alignment: 'center' } },
      ]);
    }

    // tableHeaders.push([
    //   { text: headerLabel, style: { fontSize: PDFFontSize, bold: true, alignment: 'center' } },
    //   { text: `${RoomLabel || ' '}`, style: { fontSize: PDFFontSize - 1, alignment: 'center' } },
    // ]);
  });

  rawTableHeadersWithRooms.sort((a, b) => {
    const textARoomLabel = a[1].text.toUpperCase();
    const textBRoomLabel = b[1].text.toUpperCase();

    return textARoomLabel < textBRoomLabel ? -1 : textARoomLabel > textBRoomLabel ? 1 : 0;
  });

  rawTableHeadersWithoutRooms.sort((a, b) => {
    const textA = a[0].text.toUpperCase();
    const textB = b[0].text.toUpperCase();

    return textA < textB ? -1 : textA > textB ? 1 : 0;
  });

  rawTableHeadersWithRooms.forEach((header) => {
    tableHeaders.push(header);
  });

  rawTableHeadersWithoutRooms.forEach((header) => {
    tableHeaders.push(header);
  });

  tableHeaders.forEach((h) => {
    const { pk_ScheduleAssignment } = h[0];

    headerOrder.push({
      pk_ScheduleAssignment: pk_ScheduleAssignment,
      Label: h[1].text || '',
      RoomLabel: h[0].text || '',
    });
  });
  // tableHeaders.sort((a, b) => {
  //   const textA = a[0].text.toUpperCase();
  //   const textB = b[0].text.toUpperCase();

  //   const textARoomLabel = a[1].text.toUpperCase();
  //   const textBRoomLabel = b[1].text.toUpperCase();

  //   // Sort by room labels first
  //   if (textARoomLabel && textBRoomLabel) {
  //     return textARoomLabel < textBRoomLabel ? -1 : textARoomLabel > textBRoomLabel ? 1 : 0;
  //   } else if (textARoomLabel && !textBRoomLabel) {
  //     return -1;
  //   } else if (!textARoomLabel && textBRoomLabel) {
  //     return 1;
  //   }

  //   // Sort by text labels if both items don't have room labels
  //   if (!textARoomLabel && !textBRoomLabel) {
  //     if (textA && textB) {
  //       return textA < textB ? -1 : textA > textB ? 1 : 0;
  //     } else if (textA && !textB) {
  //       return -1;
  //     } else if (!textA && textB) {
  //       return 1;
  //     }
  //   }

  //   // // Sort by room labels first
  //   // if (textARoomLabel && textBRoomLabel) {
  //   //   return textARoomLabel < textBRoomLabel ? -1 : textARoomLabel > textBRoomLabel ? 1 : 0;
  //   // } else if (textARoomLabel && !textBRoomLabel) {
  //   //   return -1;
  //   // } else if (!textARoomLabel && textBRoomLabel) {
  //   //   return 1;
  //   // }

  //   // // Sort by text labels if both items don't have room labels
  //   // if (textA && textB) {
  //   //   return textA < textB ? -1 : textA > textB ? 1 : 0;
  //   // } else if (textA && !textB) {
  //   //   return -1;
  //   // } else if (!textA && textB) {
  //   //   return 1;
  //   // }

  //   // return 0;

  //   // if (textBRoomLabel && !textARoomLabel) {
  //   //   return -1;
  //   // }

  //   // if (textARoomLabel && !textBRoomLabel) {
  //   //   return -1;
  //   // }

  //   // if (textARoomLabel < textBRoomLabel) {
  //   //   return -1;
  //   // } else if (textARoomLabel > textBRoomLabel) {
  //   //   return 1;
  //   // }

  //   // if (textB && !textA) {
  //   //   return -1;
  //   // }

  //   // if (textA - !textB) {
  //   //   return 1;
  //   // }

  //   // return textA < textB ? -1 : textA > textB ? 1 : 0;
  // });

  // headerOrder.sort((a, b) => {
  //   const textA = a.Label.toUpperCase();
  //   const textB = b.Label.toUpperCase();

  //   const textARoomLabel = a.RoomLabel.toUpperCase();
  //   const textBRoomLabel = b.RoomLabel.toUpperCase();

  //   if (textBRoomLabel && !textARoomLabel) {
  //     return 1;
  //   }

  //   if (textARoomLabel && !textBRoomLabel) {
  //     return -1;
  //   }

  //   if (textARoomLabel < textBRoomLabel) {
  //     return -1;
  //   } else if (textARoomLabel > textBRoomLabel) {
  //     return 1;
  //   }

  //   if (textB && !textA) {
  //     return -1;
  //   }

  //   if (textA - !textB) {
  //     return 1;
  //   }

  //   return textA < textB ? -1 : textA > textB ? 1 : 0;
  // });

  tableHeaders.splice(0, 0, {
    text: 'Time',
    style: { fontWeight: 'bold', fontSize: PDFFontSize + 2, alignment: 'center' },
  });

  return { tableHeaders, headerOrder };
};

const createRows = (scheduleData, columnTracker, candidates, PDFFontSize) => {
  const rows = [];
  const { tableHeaders, headerOrder } = createHeaders(scheduleData, columnTracker, PDFFontSize);

  rows.push(tableHeaders);

  const cellLayout = {
    paddingLeft: (i) => {
      return 0;
    },
    paddingRight: (i, node) => {
      return 0;
    },
    paddingTop: (i, node) => {
      return 0;
    },
    paddingBottom: (i, node) => {
      return 0;
    },
  };

  scheduleData.body.forEach((row, i) => {
    const timeCell = {
      text: `${moment.tz(row.slot, null).format('hh:mm A')}`,
      border: [false, false, false, false],
      style: { alignment: 'center' },
    };
    if (row.CustomMeetingTitle || row.CustomDurationInMinutes || row.CustomPassingDurationInMinutes) {
      timeCell.style.fillColor = '#ffec45';
    }
    const newRow = [
      {
        layout: cellLayout,
        table: {
          widths: ['*'],
          body: [
            [
              {
                layout: cellLayout,
                table: {
                  widths: ['*'],
                  body: [[timeCell]],
                  margin: [0, 0, 0, 0],
                },
                border: [false, false, false, false],
              },
            ],
          ],
          margin: [0, 0, 0, 0],
        },
      },
    ];

    if (row.pk_Timeslot === 'END') {
      newRow.push({
        layout: cellLayout,
        table: {
          widths: ['*'],
          body: [
            [
              {
                layout: cellLayout,
                table: {
                  widths: ['*'],
                  body: [
                    [
                      {
                        text: row.endTimesText,
                        fillColor: '#d4d4d4',
                        border: [false, false, false, false],
                        margin: [0, 0, 0, 0],

                        style: { alignment: 'center', bold: true },
                      },
                    ],
                  ],
                },
                border: [false, false, false, false],
                margin: [0, 0, 0, 0],
              },
            ],
          ],
          margin: [0, 0, 0, 0],
        },
        colSpan: headerOrder.length,
      });
    } else {
      headerOrder.forEach((order) => {
        const newCell = {
          layout: cellLayout,
          table: {
            widths: ['*'],
            body: [],
            margin: [0, 0, 0, 0],
          },
        };
        row[order.pk_ScheduleAssignment].ScheduleBlockEntries.forEach((block) => {
          let newBlock = [];
          if (row[order.pk_ScheduleAssignment].cellState === 'Unavailable') {
            newBlock.push({
              text: `UNAVAILABLE`,
              fillColor: '#ff3d3d',
              color: '#ffffff',
              border: [false, false, false, false],
              margin: [0, 0, 0, 0],
              alignment: 'center',
            });
            newCell.table.body.push([
              {
                layout: cellLayout,
                table: {
                  widths: ['*'],
                  body: [newBlock],
                },
                border: [false, false, false, false],
                margin: [0, 0, 0, 0],
              },
            ]);
          } else if (block.Candidates && block.Candidates.length > 0) {
            block.Candidates.forEach((candidate) => {
              newBlock = [];
              let fillColor = candidates[candidate.pk_Candidate] ? candidates[candidate.pk_Candidate].color : '#ffffff';
              if (row[order.pk_ScheduleAssignment].cellState === 'Warning') {
                fillColor = '#ffc180';
              }
              newBlock.push({
                text: `${candidate.LastName}, ${candidate.FirstName}  ${
                  row[order.pk_ScheduleAssignment].cellState === 'Warning' ? '(!)' : ''
                }`,
                fillColor: fillColor,
                color: tinycolor(fillColor).isLight() ? '#000000' : '#ffffff',
                border: [false, false, false, false],
                margin: [0, 0, 0, 0],
                alignment: 'center',
              });

              newCell.table.body.push([
                {
                  layout: cellLayout,
                  table: {
                    widths: ['*'],
                    body: [newBlock],
                  },
                  border: [false, false, false, false],
                  margin: [0, 0, 0, 0],
                },
              ]);
            });
          } else if (row.CustomMeetingTitle) {
            newBlock.push({
              text: row.CustomMeetingTitle,
              fillColor: '#d4d4d4',
              border: [false, false, false, false],
              margin: [0, 0, 0, 0],
              alignment: 'center',
            });
            newCell.table.body.push([
              {
                layout: cellLayout,
                table: {
                  widths: ['*'],
                  body: [newBlock],
                },
                border: [false, false, false, false],
                margin: [0, 0, 0, 0],
              },
            ]);
          } else {
            // EMPTY CELL
            newCell.table.body.push([
              {
                layout: cellLayout,
                table: {
                  widths: ['*'],
                  body: [
                    [
                      {
                        text: ` xxx  `,
                        fillColor: '#d4d4d4',
                        border: [false, false, false, false],
                        margin: [0, 0, 0, 0],
                        style: { color: '#d4d4d4' },
                      },
                    ],
                  ],
                },
                border: [false, false, false, false],
                margin: [0, 0, 0, 0],
              },
            ]);
          }
        });

        newRow.push(newCell);
      });
    }

    rows.push(newRow);
  });
  return rows;
};

const createPDFProper = (data) => {
  const {
    interviewDateRow,
    scheduleData,
    columnTracker,
    candidates,
    department,
    base64Img,
    PDFOrientation,
    PDFFontSize,
    willGetDataUrl,
    callback,
  } = data;

  const tableWidths = scheduleData.headers.map((h) => {
    return '*';
  });
  tableWidths.splice(0, 0, PDFFontSize * 6.25);

  let pdfObject = [
    {
      layout: 'padlessTable', //'lightHorizontalLines', // optional
      table: {
        headerRows: 1,
        widths: tableWidths,
        body: [...createRows(scheduleData, columnTracker, candidates, PDFFontSize)],
      },
    },
  ];

  const docDefinition = {
    header: createPageHeader({ interviewDateRow, base64Img, department }),
    pageSize: 'A5',
    pageOrientation: PDFOrientation,
    content: pdfObject,
    defaultStyle: {
      fontSize: PDFFontSize || 4,
    },
    pageMargins: [10, 35, 10, 5],
  };

  const pdfDocGenerator = pdfMake.createPdf(docDefinition, tableLayouts);

  if (willGetDataUrl) {
    pdfDocGenerator.getDataUrl((dataURL) => {
      if (callback) {
        callback(dataURL);
      }
    });
  } else {
    // Opera 8.0+
    const isOpera =
      (!!window.opr && !!window.opr.addons) || !!window.opera || navigator.userAgent.indexOf(' OPR/') >= 0;

    // Firefox 1.0+
    const isFirefox = typeof InstallTrigger !== 'undefined';

    // Safari 3.0+ "[object HTMLElementConstructor]"
    const isSafari =
      /constructor/i.test(window.HTMLElement) ||
      (function(p) {
        return p.toString() === '[object SafariRemoteNotification]';
      })(!window['safari'] || (typeof safari !== 'undefined' && window['safari'].pushNotification));

    // Internet Explorer 6-11
    const isIE = /*@cc_on!@*/ false || !!document.documentMode;

    // Edge 20+
    const isEdge = !isIE && !!window.StyleMedia;

    // Chrome 1 - 79
    const isChrome = !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);

    // Edge (based on chromium) detection
    const isEdgeChromium = isChrome && navigator.userAgent.indexOf('Edg') != -1;

    // Blink engine detection
    const isBlink = (isChrome || isOpera) && !!window.CSS;

    const { scheduleName, interviewDate } = interviewDateRow;
    const downloadTitle = `${department.DepartmentName} - ${
      interviewDate ? moment.tz(interviewDate, null).format('MMM DD, YYYY') : 'No Date'
    } ${scheduleName ? `(${scheduleName})` : ''}`;
    pdfDocGenerator.download(downloadTitle);
  }
};

export const createPDFForSchedule = ({
  interviewDateRow,
  scheduleData,
  columnTracker,
  candidates = [],
  department,
  PDFOrientation,
  PDFFontSize,
  willGetDataUrl,
  callback,
}) => {
  if (department && department.DepartmentLogo) {
    imageToBase64(department.DepartmentLogo)
      .then((base64Img) => {
        createPDFProper({
          interviewDateRow,
          scheduleData,
          columnTracker,
          candidates,
          department,
          base64Img,
          PDFOrientation,
          PDFFontSize,
          willGetDataUrl,
          callback,
        });
      })
      .catch((err) => {
        createPDFProper({
          interviewDateRow,
          scheduleData,
          columnTracker,
          candidates,
          department,
          base64Img: null,
          PDFOrientation,
          PDFFontSize,
          willGetDataUrl,
          callback,
        });
      });
  } else {
    createPDFProper({ interviewDateRow, scheduleData, columnTracker, candidates, department });
  }
};
