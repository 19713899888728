import { connect } from 'react-redux';
import header from './header';

const mapStateToProps = (state) => {
  return {
    user: state.userInfo.user,
    breakUrl: state.adminUser.breakUrl,
  };
};

export default connect(mapStateToProps)(header);
