// src/react-auth0-spa.js
import createAuth0Client from '@auth0/auth0-spa-js';
import React, { useContext, useEffect, useState } from 'react';
import { userFirstedLoggedIn } from './authApiCalls';
import { formatBearerToken } from '../Common.functions';
import { putData } from '../Services/dataApi';
import moment from 'moment';

const DEFAULT_REDIRECT_CALLBACK = () => window.history.replaceState({}, document.title, window.location.pathname);

export const Auth0Context = React.createContext({});
export const useAuth0 = () => useContext(Auth0Context);
export const Auth0Provider = ({ children, onRedirectCallback = DEFAULT_REDIRECT_CALLBACK, ...initOptions }) => {
  const { pathname } = window.location;
  const [isAuthenticated, setIsAuthenticated] = useState();
  const [user, setUser] = useState();
  const [auth0Client, setAuth0] = useState();
  const [userChecked, setUserChecked] = useState(false);
  const [loading, setLoading] = useState(true);
  const [userObject, setUserObject] = useState();
  const [popupOpen, setPopupOpen] = useState(false);

  useEffect(() => {
    let loginHint = null;
    if (pathname && pathname.includes('/user/login/')) {
      loginHint = pathname.split('/')[3];
      // if loading is false and it's not the route that changes the loginHint, then return (we don't need to recreate the object)
    } else if (loading === false) {
      return;
    }

    const initAuth0 = async () => {
      const options = { ...initOptions };

      if (loginHint) {
        options.login_hint = loginHint;
      }
      const auth0FromHook = await createAuth0Client(options);
      setAuth0(auth0FromHook);

      if (window.location.search.includes('code=') && window.location.search.includes('state=')) {
        const { appState } = await auth0FromHook.handleRedirectCallback();
        onRedirectCallback(appState);
        const now = moment();
        localStorage.setItem('lastActivity', now.format('MMM DD, YYYY hh:mm A'));
      }
      const isAuthenticated = await auth0FromHook.isAuthenticated();

      setIsAuthenticated(isAuthenticated);

      if (isAuthenticated) {
        const user = await auth0FromHook.getUser();
        setUser(user);
        // checkIfUserExists(user, auth0FromHook);
        logUserLastLogin(auth0FromHook);
      }

      setLoading(false);
    };
    initAuth0();
    // eslint-disable-next-line
  }, []);

  const logUserLastLogin = (auth0FromHook) => {
    auth0FromHook.getTokenSilently().then((token) => {
      putData('user/logLogin', { type: 'login' }, null, formatBearerToken(token)).then((response) => {});
    });
  };

  // const checkIfUserExists = (user, auth0FromHook) => {
  //   // Only need to check once per login

  //   if (userChecked) return;

  //   const { sub } = user;
  //   setUserChecked(true);
  //   const result = (...p) =>
  //     auth0FromHook.getTokenSilently(...p).then((token) => {
  //       dataApi
  //         .postData("/users", { user }, formatBearerToken(token))
  //         .then((res) => {
  //           setUserObject(res.data);
  //         })
  //         .catch((err) => {
  //         });
  //     });
  //   result();
  // };

  const loginWithPopup = async (params = {}) => {
    setPopupOpen(true);
    try {
      await auth0Client.loginWithPopup(params);
    } catch (error) {
      console.error(error);
    } finally {
      setPopupOpen(false);
    }
    const user = await auth0Client.getUser();
    setUser(user);
    logUserLastLogin(auth0Client);
    setIsAuthenticated(true);
  };

  const handleRedirectCallback = async () => {
    setLoading(true);
    await auth0Client.handleRedirectCallback();
    const user = await auth0Client.getUser();
    setLoading(false);
    setIsAuthenticated(true);
    setUser(user);
  };

  return (
    <Auth0Context.Provider
      value={{
        isAuthenticated,
        user,
        userObject,
        loading,
        popupOpen,
        loginWithPopup,
        handleRedirectCallback,
        getIdTokenClaims: (...p) => auth0Client.getIdTokenClaims(...p),
        loginWithRedirect: (...p) => auth0Client.loginWithRedirect(...p),
        getTokenSilently: (...p) => auth0Client.getTokenSilently(...p),
        getTokenWithPopup: (...p) => auth0Client.getTokenWithPopup(...p),
        logout: (...p) => auth0Client.logout(...p),
      }}
    >
      {children}
    </Auth0Context.Provider>
  );
};
