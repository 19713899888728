import React, { useState, useContext, useEffect } from 'react';
import { Row, Col, Nav, NavItem } from 'reactstrap';
import { Link, useHistory, useRouteMatch } from 'react-router-dom';
import { DepartmentContext } from '../../../../DepartmentWrapper';
import SettingsPopover from '../../Header/SettingsPopover/SettingsPopover';
import Focuses from './Focuses/Focuses';
import Tags from './Tags/Tags';
import Advanced from './Advanced/Advanced';
import Notifications from './Notifications/Notifications';
import { RouterPrompt } from '../../Common/RouterPrompt/RouterPrompt';
import { clone } from '../../../../Services/schedule';
import AdminCommunicationSettings from '../AdminCommunications/AdminCommunicationSettings/AdminCommunicationSettings';
import AdminEvluationSettingsLanding from '../AdminEvaluationSettings/AdminEvaluationSettingsLanding';
import CandidateSettings from './CandidateSettings/CandidateSettings';

function AdminSettings({ activeDepartment, candidates, getActiveCandidates }) {
  const [currentTab, setCurrentTab] = useState(1);
  const [tabToGo, setTabToGo] = useState(1);
  const [pageChanges, setPageChanges] = useState({
    sendMessage: false,
    history: false,
    settings: false,
    manageWaitlist: false,
  });

  const dContext = useContext(DepartmentContext);

  const { path } = useRouteMatch();

  const history = useHistory();
  const { location } = history;
  const { pathname } = location;

  let hasChanges = false;

  Object.values(pageChanges).forEach((value) => {
    if (value) {
      hasChanges = true;
    }
  });

  useEffect(() => {
    if (pathname) {
      settingTabs.forEach((tab) => {
        if (pathname.indexOf(tab.path) >= 0) {
          if (currentTab != tab.tab) {
            setCurrentTab(tab.tab);
            setTabToGo(tab.tab);
          }
        }
      });
    }
  }, [pathname]);

  const updateChanges = (key, value) => {
    const newChanges = clone(pageChanges);
    newChanges[key] = value;
    setPageChanges(newChanges);
  };

  const renderTabContent = () => {
    return settingTabs.map((tab, index) => {
      if (currentTab == tab.tab) return tab.component;
    });
  };

  const settingTabs = [
    {
      label: 'Program Settings',
      path: '/programs',
      tab: 1,
      component: <SettingsPopover dContext={dContext} updateChanges={updateChanges} />,
    },
    {
      label: 'Candidate Settings',
      path: '/candidateSettings',
      tab: 2,
      component: <CandidateSettings updateChanges={updateChanges} />,
    },

    {
      label: 'Notifications',
      path: '/notifications',
      tab: 3,
      component: <Notifications dContext={dContext} updateChanges={updateChanges} />,
    },
    {
      label: 'Communication',
      path: '/communication',
      tab: 4,
      component: <AdminCommunicationSettings dContext={dContext} updateChanges={updateChanges} />,
    },
    {
      label: 'Evaluation',
      path: '/evaluation',
      tab: 5,
      component: (
        <AdminEvluationSettingsLanding
          dContext={dContext}
          updateChanges={updateChanges}
          setPageChanges={setPageChanges}
          pageChanges={pageChanges}
        />
      ),
    },
  ];

  return (
    <div>
      <Row className="row">
        <Col lg={12} md={4} className="text-center" style={{ paddingBottom: '10px' }}>
          <Nav className="nav-tabs">
            {settingTabs.map((tab, index) => {
              return (
                <NavItem className="nav-item" key={index}>
                  <Link
                    className={'nav-link ' + (currentTab === tab.tab ? 'active' : '')}
                    to={`${path}${tab.path}`}
                    onClick={() => {
                      if (!hasChanges) {
                        setCurrentTab(tab.tab);
                      }
                      setTabToGo(tab.tab);
                    }}
                  >
                    <i className="fa fa-paper-plane"></i> {tab.label}
                  </Link>
                </NavItem>
              );
            })}
          </Nav>
        </Col>
      </Row>
      {renderTabContent()}

      <RouterPrompt
        hasChanges={hasChanges || currentTab != tabToGo}
        okLabel={'Discard Changes'}
        onCancel={() => {
          setTabToGo(currentTab);
        }}
        onOk={() => {
          setCurrentTab(tabToGo);
          setPageChanges({
            advancedSettings: false,
            programSettings: false,
            notificationSettings: false,
          });

          return true;
        }}
      />
    </div>
  );
}

export default AdminSettings;
