import { faInfoCircle, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap';
import { reduceArrayIntoSubArray } from '../Common.functions';
import { fetchData, postDataAgnostic } from '../Services/dataApi';
import SettingsContainer from './auth/Settings/Settings.container';
import { useAuth0 } from './reactAuth0Spa';
import { useHistory, useParams, useRouteMatch } from 'react-router-dom';
import Loading from 'Body/Statuses/Loading';
import FourOhFour from 'Body/FourOhFour';
import Lottie from 'lottie-web';
import { clone } from 'lodash';
import { matchPath } from 'react-router-dom/cjs/react-router-dom.min';

const rezRateIcon = require('Body/Statuses/RezRATE Logo.json');

const AuthWrapper = ({
  children,
  setUserObject,
  sidebarIsClosed,
  userDepartment,
  setAllUserDepartments,
  setUserDepartment,
  // match,
}) => {
  // const [loading, setLoading] = useState(true);

  const [authObjectLocal, setAuthObjectLocal] = useState(null);
  const [userFromDatabase, setUserFromDatabase] = useState(null);
  const [isRedirectingToLogin, setIsRedirectingToLogin] = useState(false);
  const [showCandidateMessage, setShowCandidateMessage] = useState(null);
  const lottieContainer = useRef(null);

  const { loading, user, isAuthenticated, loginWithRedirect, getTokenSilently } = useAuth0();

  const params = useParams();
  const history = useHistory();
  const match = useRouteMatch(pathname);
  const { location } = history;
  const { pathname } = location;

  const noAuthPaths = [
    '/candidate/photo/',
    '/candidate/schedule/',
    '/candidate/',
    '/notifications/test',
    '/department/attachment',
  ];
  const authPaths = [
    { path: '/', exact: true, strict: false },
    { path: '/user/login/:email', exact: false, strict: false },
    { path: '/organizations', exact: true, strict: false },

    { path: '/viewFile/:pk_Candidate/:pk_File/:pk_DocumentType/:fileName', exact: false, strict: false },
    { path: '/login/:id', exact: false, strict: false },
    { path: '/zoom', exact: false, strict: false },
    { path: '/reset/:id', exact: false, strict: false },
    { path: '/reset', exact: false, strict: false },
    { path: '/unsubscribe/:id', exact: false, strict: false },
    { path: '/agreement', exact: false, strict: false },
    { path: '/evaluate', exact: true, strict: false },
    { path: '/administrator', exact: false, strict: false },
    { path: '/user/evaluate/:pk_InterviewDate', exact: false, strict: false },
    { path: '/e0b6c3c4-c758-4747-8c1f-1248597713f2', exact: false, strict: false },
    { path: '/files/view/:organization/:department/:seasonID/:pk_UUID_FM/:tempToken', exact: false, strict: false },
    { path: '/files/:organization/:department/:seasonID/:pk_UUID_FM/:tempToken', exact: false, strict: false },
    { path: '/logo/:organization/:department/:tempToken', exact: false, strict: false },
    // { path: '/coordinator/schedule/:id', exact: true, strict: false },
  ];

  useLayoutEffect(() => {
    if (!isAuthenticated && !isRedirectingToLogin && !loading && lottieContainer.current) {
      console.log('LOTTIEE');
      Lottie.loadAnimation({
        container: lottieContainer.current,
        renderer: 'svg',
        loop: false,
        autoplay: true,
        animationData: rezRateIcon,
      });
    }
  }, [loading]);

  const getUser = async () => {
    try {
      const token = await getTokenSilently();
      fetchData('/users', null, {
        Authorization: `Bearer ${token}`,
      })
        .then((res) => {
          const FormattedUser = reduceArrayIntoSubArray(res.data, 'pk_User', 'departments', [
            'FirstName',
            'LastName',
            'AbbrevatedName',
            'AltEmail',
            'AltPhoneNumber',
            'AltStreetAddress',
            'DepartmentName',
            'DisplayName',
            'DefaultDepartment',
            'Email',
            'FirstName',
            'LastName',
            'MiddleName',
            'NationalProviderIdentifier',
            'PhoneNumber',
            'PhotoURL',
            'StreetAddress',
            'Suffix',
            'UserColor',
          ]);
          setUserObject(FormattedUser[0]);
          setUserFromDatabase(FormattedUser[0]);
          if (FormattedUser[0] && FormattedUser[0].departments) {
            setAllUserDepartments(FormattedUser[0].departments);
          }
        })
        .catch((err) => {});
    } catch (err) {}
  };

  const renderCandidateMessagModal = () => {
    return (
      <Modal isOpen={showCandidateMessage} centered>
        <ModalHeader
          toggle={() => {
            setShowCandidateMessage(!showCandidateMessage);
          }}
        >
          <div
            style={{
              // marginTop: '20vh',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
            }}
          >
            <FontAwesomeIcon icon={faInfoCircle} style={{ fontSize: 30, marginRight: 10 }} />
            <b style={{ fontSize: 15 }}>Candidates do not need to log into RezRATE. </b>
          </div>
        </ModalHeader>

        <ModalBody style={{ minWidth: '80%' }}>
          <div
            style={{
              // marginTop: '20vh',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              width: '100%',
            }}
          >
            <ul>
              <li>Please check your email for invitations and interview day schedule links.</li>
              <li>These messages are unique to you. Do not share them with others.</li>
              <li>If you’re unable to locate your email, please contact the program you’ve applied to directly.</li>
            </ul>
          </div>
        </ModalBody>
      </Modal>
    );
  };

  if (loading || isRedirectingToLogin) {
    return (
      <div style={{ width: '100%', display: 'flex', height: 'calc(100vh - 450px)' }}>
        <div
          style={{
            height: 'calc(100% - 50px)',
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            // marginTop: 'calc(10vh + 150px)',
            backgroundColor: 'white',
          }}
        >
          <div style={{ height: 100, width: 150, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            {' '}
            <Loading options={{ labelText: 'Authenticating. . .' }} />
          </div>

          {/* <FontAwesomeIcon icon={faSpinner} spin size="4x" /> */}
        </div>
      </div>
    );
  }

  if (!isAuthenticated) {
    const id = params.id;
    const isNoAuthPath = noAuthPaths.find((path) => {
      return pathname.indexOf(path) >= 0;
    });

    // TODO: Add list of all URLs. if URL doesn't match any structure, show 404. Otherwise, try to fix routes in App.js
    if (isNoAuthPath) {
    } else {
      // Check if URL is valid. Of not, redirect.
      let matchPathValue = null;
      let index = 0;
      while (index < authPaths.length && !matchPathValue) {
        matchPathValue = matchPath(pathname, {
          path: authPaths[index].path,
          exact: authPaths[index].exact,
          strict: false,
        });
        // console.log(pathname, ' vs ', authPaths[index], ' matchPathValue: ', matchPathValue != null);
        index++;
      }

      if (!matchPathValue) {
        return <FourOhFour onClickLogin={loginWithRedirect} myKey={'404'} />;
      } else {
        return (
          <div
            style={{
              marginTop: '5vh',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              width: '100%',
            }}
          >
            <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center',
                }}
              >
                <div style={{ maxWidth: '40%', height: 240 }} id="lottie_logo_container" ref={lottieContainer}></div>
                {/* <img style={{ maxWidth: '40%' }} src={rezRateLogo} alt="department logo" /> */}
              </div>
              <div
                style={{
                  fontSize: 50,
                  fontWeight: 'bold',
                  textAlign: 'center',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center',
                  marginBottom: 30,
                }}
              >
                RezRATE
              </div>

              <div
                style={{
                  fontSize: 18,
                  fontWeight: 'bold',
                  textAlign: 'center',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center',
                  marginBottom: 10,
                }}
              >
                Please select a role...
              </div>

              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center',
                  flexWrap: 'wrap',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    width: '60%',
                    minWidth: 300,
                    // borderTop: '1px solid black',
                    paddingTop: 10,
                    flexWrap: 'wrap',
                  }}
                >
                  <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', flexWrap: 'wrap' }}>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '48%',
                        minWidth: 290,
                        justifyContent: 'center',
                        alignItems: 'center',
                        marginBottom: 10,
                      }}
                    >
                      <Button
                        color="primary"
                        style={{ width: 280 }}
                        onClick={() => {
                          setShowCandidateMessage(true);
                        }}
                      >
                        {' '}
                        Candidate
                      </Button>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '48%',
                        minWidth: 290,
                        justifyContent: 'center',
                        alignItems: 'center',
                        marginBottom: 10,
                      }}
                    >
                      <Button
                        color="success"
                        style={{ width: 280 }}
                        onClick={() => {
                          setIsRedirectingToLogin(true);
                          loginWithRedirect();
                        }}
                      >
                        Evaluator or Administrator
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
              {renderCandidateMessagModal()}
            </div>
          </div>
        );

        setIsRedirectingToLogin(true);
        loginWithRedirect();
        return;
      }

      // console.log('matchPathValue', clone(matchPathValue));
      // // loginWithRedirect();
      // // return;
      // return <FourOhFour onClickLogin={loginWithRedirect} myKey={'404'} />;
    }

    // return (
    //   <div
    //     style={{
    //       height: 'calc(100% - 90px)',
    //       width: '100%',
    //       display: 'flex',
    //       justifyContent: 'center',
    //       alignItems: 'center',
    //     }}
    //   >
    //     <Button
    //       onClick={(e) => {
    //         e.preventDefault();
    //         loginWithRedirect();
    //       }}
    //     >
    //       Login
    //     </Button>
    //   </div>
    // );
  }

  const childrenWithProps = React.Children.map(children, (child) => React.cloneElement(child, authObjectLocal));

  return (
    <SettingsContainer user={userFromDatabase} userDepartment={userDepartment}>
      {authObjectLocal && childrenWithProps}
      {!authObjectLocal && children}
    </SettingsContainer>
  );
};

export default AuthWrapper;
