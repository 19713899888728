import React, { useState, useEffect, useContext } from 'react';
import { faPlusCircle, faSearch, faPlus, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row,
  Table,
} from 'reactstrap';
import style from './Groups.style';

// import FocustItems from './FocusItems/FocustItems';
import { clone } from '../../../../../Services/schedule';
import GroupItem from './GroupItem/GroupItem';
import { useAuth0 } from '../../../../../auth0/reactAuth0Spa';
import { deleteData, fetchDataAgnostic, postDataAgnostic, putData } from '../../../../../Services/dataApi';
import { formatBearerToken } from '../../../../../Common.functions';
import { DepartmentContext } from 'DepartmentWrapper';
import Swal from 'sweetalert2';
import { useAlert } from 'react-alert';

const Groups = ({ allowEdit = false }) => {
  const [newGroup, setNewGroup] = useState({ name: '' }); // DocumentTypeName, UIIcon
  const [groupChoices, setGroupChoices] = useState([]);
  const [searchString, setSearchString] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const { getTokenSilently } = useAuth0();

  const dContext = useContext(DepartmentContext);
  const alert = useAlert();
  useEffect(() => {
    getGroups();
  }, []);

  const getGroups = () => {
    setIsLoading(true);
    getTokenSilently()
      .then((token) => {
        fetchDataAgnostic(
          'candidate/groups',
          { pk_Department: dContext.department.pk_Department, pk_Season: dContext.season.pk_Season },
          formatBearerToken(token),
        )
          .then((res) => {
            setGroupChoices(res.data);
            setIsLoading(false);
          })
          .catch((err) => {});
      })
      .catch((err) => {});
  };

  const filterTags = (groupChoices) => {
    const newFilters = { ...groupChoices };
    Object.keys(groupChoices).forEach((key) => {
      const tag = groupChoices[key];
      if (tag.Tag.toLowerCase().indexOf(searchString.toLowerCase()) < 0) {
        delete newFilters[key];
      }
    });
    return newFilters;
  };

  const addNewGroup = (newGroup, promiseDetails) => {
    setIsLoading(true);
    getTokenSilently()
      .then((token) => {
        postDataAgnostic(
          'candidate/group',
          null,
          {
            Name: newGroup,
            Description: '',
            fk_Department: dContext.department.pk_Department,
            fk_Season: dContext.season.pk_Season,
          },
          formatBearerToken(token),
        )
          .then((res) => {
            alert.success('Group added successfully');
            setIsLoading(false);
            if (promiseDetails) {
              const { resolve } = promiseDetails;
              resolve('true');
            } else {
            }
          })
          .catch((err) => {
            alert.error('Error adding group');
            setIsLoading(false);
            if (promiseDetails) {
              const { reject } = promiseDetails;
              reject();
            }
          })
          .finally(() => {
            getGroups();
          });
      })
      .catch((err) => {
        setIsLoading(false);
        alert.error('Error adding group');
      });
  };

  // const updateTags = (tag) => {
  //   getTokenSilently().then((token) => {
  //     putData(
  //       'department/tag',
  //       { pk_Department: dContext.department.pk_Department, pk_Tag: tag.pk_Tag },
  //       { Tag: tag.Tag },
  //       formatBearerToken(token),
  //     )
  //       .then((results) => {})
  //       .catch((err) => {});
  //   });
  // };

  const onSave = (Group) => {
    const newGroupChoices = JSON.parse(JSON.stringify(groupChoices));
    newGroupChoices[Group.index] = Group;
    setGroupChoices(newGroupChoices);
    // updateTags(tag);
  };

  const filteredGroupChoices = groupChoices
    .sort((a, b) => {
      if (!a.Name) {
        return -1;
      }

      if (!b.Name) {
        return 1;
      }
      const result = a.Name > b.Name ? 1 : a.Name < b.Name ? -1 : 0;

      return result;
    })
    .filter((Group) => {
      if (Group.Name) {
        return Group.Name.toLowerCase().indexOf(searchString.toLowerCase()) >= 0;
      }
    });

  const hasSearchValues = filteredGroupChoices.length > 0;

  return (
    <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
      <div style={style.mainContainer}>
        <Card style={{ width: '60%', height: 'auto' }}>
          <CardHeader>
            <div style={style.centeredText}>
              <b>Groups</b>
            </div>
          </CardHeader>
          <CardBody>
            <InputGroup>
              <InputGroupAddon addonType="append">
                <InputGroupText>
                  <FontAwesomeIcon
                    icon={faTimes}
                    style={{ ...style.clickable }}
                    onClick={() => {
                      setSearchString('');
                    }}
                  />
                </InputGroupText>
              </InputGroupAddon>

              <Input
                value={searchString}
                type="text"
                placeholder="Search Groups. . ."
                onChange={(e) => {
                  setSearchString(e.target.value);
                }}
              />
              <InputGroupAddon addonType="append">
                <InputGroupText>
                  <FontAwesomeIcon icon={faSearch} />
                </InputGroupText>
              </InputGroupAddon>
            </InputGroup>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                marginRight: '20px',
              }}
            >
              <div
                style={{
                  alignSelf: 'flex-end',
                  padding: '5px',
                  verticalAlign: 'bottom',
                  width: '100px',
                }}
              >
                <strong> Groups</strong>
              </div>
              <div clasnName="editAndTagWrappper" style={{ display: 'flex', flexDirection: 'row' }}>
                <div
                  style={{
                    alignSelf: 'flex-end',
                    marginLeft: '3px',
                    padding: '5px',
                    textAlign: 'center',
                    verticalAlign: 'bottom',
                    width: '45px',
                  }}
                >
                  <strong>Edit</strong>
                </div>
                <div
                  style={{
                    alignSelf: 'flex-end',
                    marginLeft: '3px',
                    padding: '5px',
                    textAlign: 'center',
                    verticalAlign: 'bottom',
                    width: '60px',
                  }}
                >
                  <strong>Delete </strong>
                </div>
              </div>
            </div>
            <div style={{ overflowY: 'scroll', height: '30vh' }}>
              {filteredGroupChoices && filteredGroupChoices.length > 0 ? (
                filteredGroupChoices.map((Group, i) => {
                  return <GroupItem Group={Group} index={i} onSave={onSave} getGroups={getGroups} />;
                })
              ) : !isLoading ? (
                <div style={style.centeredText}>No Groups</div>
              ) : (
                <div style={style.centeredText}>Loading. . .</div>
              )}
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                marginTop: 10,
              }}
            >
              <Button
                color="success"
                onClick={() => {
                  Swal.fire({
                    title: 'Add New Group',
                    input: 'text',
                    inputAttributes: {
                      autocapitalize: 'off',
                    },
                    showCancelButton: true,
                    confirmButtonText: 'Add',
                    showLoaderOnConfirm: true,
                    preConfirm: (newGroup) => {
                      return new Promise((resolve, reject) => {
                        addNewGroup(newGroup, { resolve, reject });
                      });
                    },
                    allowOutsideClick: () => !Swal.isLoading(),
                  });
                }}
              >
                Add New Group <FontAwesomeIcon icon={faPlus} />
              </Button>
            </div>
          </CardBody>
        </Card>
      </div>
    </div>
  );
};

export default Groups;
