export const questionScaleKeys = {
  likert5: 'likert5',
  likert10: 'likert10',
  dreyfus10: 'dreyfus10',
  abns4: 'abns4',
  passFail3: 'passFail3',
};

export const questionScaleNames = {
  likert5: 'Likert (5pt)',
  likert10: 'Likert (10pt)',
  dreyfus10: 'Dreyfus (10pt)',
  abns4: 'ABNS (4pt)',
  passFail3: 'Pass/Fail (3pt)',
};

export const defaultLikert5QuestionState = [
  { SortOrder: 1, Label: 'Poor', DisplayValue: 1 },
  { SortOrder: 2, Label: 'Below Average', DisplayValue: 2 },
  { SortOrder: 3, Label: 'Average', DisplayValue: 3 },
  { SortOrder: 4, Label: 'Good', DisplayValue: 4 },
  { SortOrder: 5, Label: 'Excellent', DisplayValue: 5 },
];

export const defaultLikert10QuestionScale = [
  { SortOrder: 1, Label: 'Poor', DisplayValue: 1 },
  { SortOrder: 2, Label: '', DisplayValue: 2 },
  { SortOrder: 3, Label: '', DisplayValue: 3 },
  { SortOrder: 4, Label: '', DisplayValue: 4 },
  { SortOrder: 5, Label: '', DisplayValue: 5 },
  { SortOrder: 6, Label: '', DisplayValue: 6 },
  { SortOrder: 7, Label: '', DisplayValue: 7 },
  { SortOrder: 8, Label: '', DisplayValue: 8 },
  { SortOrder: 9, Label: '', DisplayValue: 9 },
  { SortOrder: 10, Label: 'Excellent', DisplayValue: 10 },
];

export const defaultDreyfus10QuestionScale = [
  { firstNumber: 1, secondNumber: 2, Label: 'Poor' },
  { firstNumber: 3, secondNumber: 4, Label: 'Below Average' },
  { firstNumber: 5, secondNumber: 6, Label: 'Average' },
  { firstNumber: 7, secondNumber: 8, Label: 'Good' },
  { firstNumber: 9, secondNumber: 10, Label: 'Excellent' },
];

export const defaultABNS4QuestionScale = [
  { Label: 'Poor', DisplayValue: 1 },
  { Label: 'Below Average', DisplayValue: 2 },
  { Label: 'Good', DisplayValue: 3 },
  { Label: 'Excellent', DisplayValue: 4 },
];

export const defaultPassFail3QuestionScale = [
  { Label: 'Fail', DisplayValue: 1 },
  { Label: 'Borderline', DisplayValue: 2 },
  { Label: 'Pass', DisplayValue: 3 },
];
