const initialState = {
  activeDepartment: null,
  activeSeason: null,
  seasonChoices: [],
};

const DepartmentReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_ACTIVE_DEPARTMENT':
      return {
        ...state,
        activeDepartment: action.payload,
      };
    case 'SET_ACTIVE_SEASON':
      return {
        ...state,
        activeSeason: action.payload,
      };
    case 'SET_SEASON_CHOICES':
      return {
        ...state,
        seasonChoices: action.payload,
      };
    default:
      return state;
  }
};

export default DepartmentReducer;
