import React from 'react';
import DragAndDropFiles from '../../../../../DragAndDropFiles/DragAndDropFiles';
function AddDocuments({
  documentTypes,
  setDocumentTypes,
  activeDepartment,
  embedded,
  match,
  getCandidateDetails,
  selectedCandidate,
  isSingleCandidate,
  isLoading,
}) {
  return (
    <DragAndDropFiles
      attachedDocuments={selectedCandidate && selectedCandidate.Documents ? selectedCandidate.Documents : []}
      documentTypes={documentTypes}
      setDocumentTypes={setDocumentTypes}
      activeDepartment={activeDepartment}
      embedded={embedded}
      match={match}
      getCandidateDetails={getCandidateDetails}
      selectedCandidate={selectedCandidate}
      isSingleCandidate={isSingleCandidate}
      isLoading={isLoading}
    />
  );

  /// FIX TIME !
}

export default AddDocuments;
