const style = {
  genButton: {
    alignItems: 'center',
    backgroundColor: '#0a91ff',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginLeft: 10,
    marginRight: 10,
    paddignRight: 20,
    paddingBottom: 10,
    paddingLeft: 20,
    paddingTop: 10,
  },
  buttonIcon: {
    marginRight: 10,
  },
  simpleRow: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 10,
  },
  simpleColumn: {
    display: 'flex',
    flexDirection: 'column',
  },
  spacedBetweenRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  buttonLabel: { width: '100%', textAlign: 'center', fontWeight: 600 },
};

export default style;
