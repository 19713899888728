import { faTimesCircle } from '@fortawesome/free-regular-svg-icons';
import {
  faCalendarCheck,
  faCalendarTimes,
  faClipboard,
  faClipboardCheck,
  faClipboardList,
  faCommentSlash,
  faEnvelope,
  faEnvelopeCircleCheck,
  faExclamationCircle,
  faThumbsDown,
  faTrashAlt,
  faUserCheck,
  faUserTimes,
} from '@fortawesome/free-solid-svg-icons';

const applicationSystems = ['ERAS', 'SF Match', 'Oral Board', 'Custom'];
const programTypes = ['Residency', 'Fellowship', 'Mock Oral Board', 'Staffing'];

//WARNING: THIS EXISTS ON BOTH FRONTEND AND BACKEND SO CHANGE BOTH
const CandidateImportFields = {
  AAMCID: 'AAMCID',
  AlphaOmegaAlpha: 'AlphaOmegaAlpha',
  AlternatePhone: 'AlternatePhone',
  ApplicantName: 'ApplicantName',
  BirthPlace: 'BirthPlace',
  CellPhone: 'CellPhone',
  Citizenship: 'Citizenship',
  ContactAddress1: 'ContactAddress1',
  ContactAddress2: 'ContactAddress2',
  ContactCity: 'ContactCity',
  ContactCountry: 'ContactCountry',
  ContactState: 'ContactState',
  ContactZip: 'ContactZip',
  CouplesPartnerName: 'CouplesPartnerName',
  CouplesPartnerSpecialty: 'CouplesPartnerSpecialty',
  CurrentAddress: 'CurrentAddress',
  CurrentVisaStatus: 'CurrentVisaStatus',
  DateOfBirth: 'DateOfBirth',
  Email: 'Email',
  ExpectedVisaStatus: 'ExpectedVisaStatus',
  ExplanationOfOtherServiceObligation: 'ExplanationOfOtherServiceObligation',
  Fax: 'Fax',
  FirstName: 'FirstName',
  Gender: 'Gender',
  GoldHumanismHonorSociety: 'GoldHumanismHonorSociety',
  HobbiesAndInterests: 'HobbiesAndInterests',
  LanguageFluency: 'LanguageFluency',
  LastName: 'LastName',
  MiddleName: 'MiddleName',
  MilitaryBranch: 'MilitaryBranch',
  MilitaryServiceObligation: 'MilitaryServiceObligation',
  MilitaryServiceYearsRemaining: 'MilitaryServiceYearsRemaining',
  NBOMEID: 'NBOMEID',
  NRMPID: 'NRMPID',
  OtherServiceObligation: 'OtherServiceObligation',
  PGY2Interest: 'PGY2Interest',
  Pager: 'Pager',
  ParticipatingasaCoupleinNRMP: 'ParticipatingasaCoupleinNRMP',
  PermanentAddress: 'PermanentAddress',
  PermanentAddress1: 'PermanentAddress1',
  PermanentAddress2: 'PermanentAddress2',
  PermanentCity: 'PermanentCity',
  PermanentCountry: 'PermanentCountry',
  PermanentPhone: 'PermanentPhone',
  PermanentState: 'PermanentState',
  PermanentZip: 'PermanentZip',
  PlanstoParticipateinNRMPMatch: 'PlanstoParticipateinNRMPMatch',
  PreferredName: 'PreferredName',
  PreferredPhone: 'PreferredPhone',
  PreviousLastName: 'PreviousLastName',
  SelfIdentify: 'SelfIdentify',
  SigmaSigmaPhi: 'SigmaSigmaPhi',
  TracksAppliedbyApplicant: 'TracksAppliedbyApplicant',
  TracksConsideredbyProgram: 'TracksConsideredbyProgram',
  USOrCanadianApplicant: 'USOrCanadianApplicant',
  USMLEID: 'USMLEID',
  VisaSponsorshipNeeded: 'VisaSponsorshipNeeded',
  AwardsAndRecognitionsMedicalSchool: 'AwardsAndRecognitionsMedicalSchool',
  AwardsAndRecognitionsOthers: 'AwardsAndRecognitionsOthers',
  ExplanationOfWhyMedicalEducationorTrainingInterrupted: 'ExplanationOfWhyMedicalEducationorTrainingInterrupted',
  HigherEducationAttendanceDates: 'HigherEducationAttendanceDates',
  HigherEducationDegree: 'HigherEducationDegree',
  HigherEducationDegreeDateEarned: 'HigherEducationDegreeDateEarned',
  HigherEducationDegreeEarned: 'HigherEducationDegreeEarned',
  HigherEducationInstitution: 'HigherEducationInstitution',
  HigherEducationLocation: 'HigherEducationLocation',
  HigherEducationMajor: 'HigherEducationMajor',
  MedicalDegree: 'MedicalDegree',
  MedicalDegreeExpectedorEarned: 'MedicalDegreeExpectedorEarned',
  MedicalEducationorTrainingInterrupted: 'MedicalEducationorTrainingInterrupted',
  MedicalSchoolAttendanceDates: 'MedicalSchoolAttendanceDates',
  MedicalSchoolClinicalCampus: 'MedicalSchoolClinicalCampus',
  MedicalSchoolCountry: 'MedicalSchoolCountry',
  MedicalSchoolDegreeDateOfGraduation: 'MedicalSchoolDegreeDateOfGraduation',
  MedicalSchoolStateOrProvince: 'MedicalSchoolStateOrProvince',
  MedicalSchoolType: 'MedicalSchoolType',
  MedicalSchoolOfGraduation: 'MedicalSchoolOfGraduation',
  MembershipinHonoraryorProfessionalSocieties: 'MembershipinHonoraryorProfessionalSocieties',
  ACLS: 'ACLS',
  ACLSExpirationDate: 'ACLSExpirationDate',
  BLS: 'BLS',
  BLSExpirationDate: 'BLSExpirationDate',
  BoardCertification: 'BoardCertification',
  BoardCertificationName: 'BoardCertificationName',
  COMLEXUSALevel1Score: 'COMLEXUSALevel1Score',
  COMLEXUSALevel2CEScore: 'COMLEXUSALevel2CEScore',
  COMLEXUSALevel2PEScore: 'COMLEXUSALevel2PEScore',
  COMLEXUSALevel3Score: 'COMLEXUSALevel3Score',
  CSAExamStatus: 'CSAExamStatus',
  CSAPassDate: 'CSAPassDate',
  DEAExpirationDate: 'DEAExpirationDate',
  DEARegistrationNumber: 'DEARegistrationNumber',
  ECFMGCertificationDate: 'ECFMGCertificationDate',
  ECFMGCertified: 'ECFMGCertified',
  MalpracticeCasesPending: 'MalpracticeCasesPending',
  MalpracticeCasesPendingReason: 'MalpracticeCasesPendingReason',
  MedicalLicenseNumber: 'MedicalLicenseNumber',
  MedicalLicenseState: 'MedicalLicenseState',
  MedicalLicenseType: 'MedicalLicenseType',
  MedicalLicensesExpirationDate: 'MedicalLicensesExpirationDate',
  MedicalLicensureProblem: 'MedicalLicensureProblem',
  MedicalLicensureProblemReason: 'MedicalLicensureProblemReason',
  PALS: 'PALS',
  PALSExpirationDate: 'PALSExpirationDate',
  USMLEStep1Score: 'USMLEStep1Score',
  USMLEStep2CKScore: 'USMLEStep2CKScore',
  USMLEStep2CSScore: 'USMLEStep2CSScore',
  USMLEStep3Score: 'USMLEStep3Score',
  CountOfNonPeerReviewedOnlinePublication: 'CountOfNonPeerReviewedOnlinePublication',
  CountOfOralPresentation: 'CountOfOralPresentation',
  CountOfOtherArticles: 'CountOfOtherArticles',
  CountOfPeerReviewedBookChapter: 'CountOfPeerReviewedBookChapter',
  CountOfPeerReviewedJournalArticlesAndOrAbstracts: 'CountOfPeerReviewedJournalArticlesAndOrAbstracts',
  CountOfPeerReviewedJournalArticlesAndOrAbstractsOtherThanPublished:
    'CountOfPeerReviewedJournalArticlesAndOrAbstractsOtherThanPublished',
  CountOfPeerReviewedOnlinePublication: 'CountOfPeerReviewedOnlinePublication',
  CountOfPosterPresentation: 'CountOfPosterPresentation',
  CountOfScientificMonograph: 'CountOfScientificMonograph',
  HigherEducationAttendanceDates_1: 'HigherEducationAttendanceDates_1',
  HigherEducationDegree_1: 'HigherEducationDegree_1',
  HigherEducationDegreeDateEarned_1: 'HigherEducationDegreeDateEarned_1',
  HigherEducationDegreeEarned_1: 'HigherEducationDegreeEarned_1',
  HigherEducationInstitution_1: 'HigherEducationInstitution_1',
  HigherEducationLocation_1: 'HigherEducationLocation_1',
  HigherEducationMajor_1: 'HigherEducationMajor_1',
  MedicalLicenseNumber_1: 'MedicalLicenseNumber_1',
  MedicalLicenseState_1: 'MedicalLicenseState_1',
  MedicalLicenseType_1: 'MedicalLicenseType_1',
  MedicalLicensesExpirationDate_1: 'MedicalLicensesExpirationDate_1',
  HigherEducationAttendanceDates_2: 'HigherEducationAttendanceDates_2',
  HigherEducationDegree_2: 'HigherEducationDegree_2',
  HigherEducationDegreeDateEarned_2: 'HigherEducationDegreeDateEarned_2',
  HigherEducationDegreeEarned_2: 'HigherEducationDegreeEarned_2',
  HigherEducationInstitution_2: 'HigherEducationInstitution_2',
  HigherEducationLocation_2: 'HigherEducationLocation_2',
  HigherEducationMajor_2: 'HigherEducationMajor_2',
  HigherEducationAttendanceDates_3: 'HigherEducationAttendanceDates_3',
  HigherEducationDegree_3: 'HigherEducationDegree_3',
  HigherEducationDegreeDateEarned_3: 'HigherEducationDegreeDateEarned_3',
  HigherEducationDegreeEarned_3: 'HigherEducationDegreeEarned_3',
  HigherEducationInstitution_3: 'HigherEducationInstitution_3',
  HigherEducationLocation_3: 'HigherEducationLocation_3',
  HigherEducationMajor_3: 'HigherEducationMajor_3',
  CustomField1: 'CustomField1',
  CustomField2: 'CustomField2',
  CustomField3: 'CustomField3',
  ProgramSignal: 'ProgramSignal',
  DivisionPreference: 'DivisionPreference',
  MedicalTrainingProgram: 'MedicalTrainingProgram',
  MedicalTrainingStartDate: 'MedicalTrainingStartDate',
  CouplesMatch: 'CouplesMatch',
  MedicalTrainingType: 'MedicalTrainingType',
  MostRecentMedicalTrainingProgram: 'MostRecentMedicalTrainingProgram',
  CustomField4: 'CustomField4',
  CustomField5: 'CustomField5',
  CustomField6: 'CustomField6',
  CustomField7: 'CustomField7',
  CustomField8: 'CustomField8',
  CustomField9: 'CustomField9',
  CustomField10: 'CustomField10',
  PreferredFirst: 'PreferredFirst',
  PreferredMiddle: 'PreferredMiddle',
  PreferredLast: 'PreferredLast',
  pk_UUID_FM: 'pk_UUID_FM',
  SFMatchID: 'SFMatchID',
  CurrentResidency: 'CurrentResidency',
  YearOfResidencyCompletion: 'YearOfResidencyCompletion',
  Category: 'Category',
  FirstDelivery: 'FirstDelivery',
  LastUpdated: 'LastUpdated',
  LastReviewed: 'LastReviewed',
  COMLEXUSALevel1DateScore: 'COMLEXUSALevel1DateScore',
  COMLEXUSALevel2CEDateScore: 'COMLEXUSALevel2CEDateScore',
  COMLEXUSALevel3DateScore: 'COMLEXUSALevel3DateScore',
  USMLEComletionDate: 'USMLEComletionDate',
  USMLEStep2CSDateScore: 'USMLEStep2CSDateScore',
  USMLEStep3DateScore: 'USMLEStep3DateScore',
};

const createDatabaseMatch = () => {
  const payload = {};
  Object.keys(candidateImportFields).forEach((key) => {
    payload[key.toUpperCase()] = { matchingObject: candidateImportFields[key] };
  });
  return payload;
};

const candidateImportFields = {
  AAMCID: { databaseColumn: 'AAMCID', alias: 'AAMC ID', type: 2 },
  AlphaOmegaAlpha: {
    databaseColumn: 'AlphaOmegaAlpha',
    alias: 'Alpha Omega Alpha',
    type: 3,
  },
  AlternatePhone: {
    databaseColumn: 'AlternatePhone',
    alias: 'Alternate Phone',
    type: 2,
  },
  ApplicantName: {
    databaseColumn: 'ApplicantName',
    alias: 'Applicant Name',
    type: 2,
  },
  BirthPlace: { databaseColumn: 'BirthPlace', alias: 'Birthplace', type: 2 },
  CellPhone: { databaseColumn: 'CellPhone', alias: 'Cell Phone', type: 2 },
  Citizenship: { databaseColumn: 'Citizenship', alias: 'Citizenship', type: 2 },
  ContactAddress1: {
    databaseColumn: 'ContactAddress1',
    alias: 'Contact Address 1',
    type: 2,
  },
  ContactAddress2: {
    databaseColumn: 'ContactAddress2',
    alias: 'Contact Address 2',
    type: 2,
  },
  ContactCity: {
    databaseColumn: 'ContactCity',
    alias: 'Contact City',
    type: 2,
  },
  ContactCountry: {
    databaseColumn: 'ContactCountry',
    alias: 'Contact Country',
    type: 2,
  },
  ContactState: {
    databaseColumn: 'ContactState',
    alias: 'Contact State',
    type: 2,
  },
  ContactZip: { databaseColumn: 'ContactZip', alias: 'Contact Zip', type: 2 },
  CouplesPartnerName: {
    databaseColumn: 'CouplesPartnerName',
    alias: 'Couples Partner Name',
    type: 2,
  },
  CouplesPartnerSpecialty: {
    databaseColumn: 'CouplesPartnerSpecialty',
    alias: 'Couples Partner Specialty',
    type: 2,
  },
  CurrentAddress: {
    databaseColumn: 'CurrentAddress',
    alias: 'Current Address',
    type: 2,
  },
  CurrentVisaStatus: {
    databaseColumn: 'CurrentVisaStatus',
    alias: 'Current Visa Status',
    type: 2,
  },
  DateOfBirth: {
    databaseColumn: 'DateOfBirth',
    alias: 'Date of Birth',
    type: 2,
  },
  Email: { databaseColumn: 'Email', alias: 'Email', type: 2 },
  ExpectedVisaStatus: {
    databaseColumn: 'ExpectedVisaStatus',
    alias: 'Expected Visa Status',
    type: 2, //Date
  },
  ExplanationOfOtherServiceObligation: {
    databaseColumn: 'ExplanationOfOtherServiceObligation',
    alias: 'Explanation of Other Service Obligation',
    type: 2,
  },
  Fax: { databaseColumn: 'Fax', alias: 'Fax', type: 2 },
  FirstName: { databaseColumn: 'FirstName', alias: 'First Name', type: 2 },
  Gender: { databaseColumn: 'Gender', alias: 'Gender', type: 2 },
  GoldHumanismHonorSociety: {
    databaseColumn: 'GoldHumanismHonorSociety',
    alias: 'Gold Humanism Honor Society',
    type: 3,
  },
  HobbiesAndInterests: {
    databaseColumn: 'HobbiesAndInterests',
    alias: 'Hobbies and Interests',
    type: 2,
  },
  LanguageFluency: {
    databaseColumn: 'LanguageFluency',
    alias: 'Language Fluency',
    type: 2,
  },
  LastName: { databaseColumn: 'LastName', alias: 'Last Name', type: 2 },
  MiddleName: { databaseColumn: 'MiddleName', alias: 'Middle Name', type: 2 },
  MilitaryBranch: {
    databaseColumn: 'MilitaryBranch',
    alias: 'Military Branch',
    type: 2,
  },
  MilitaryServiceObligation: {
    databaseColumn: 'MilitaryServiceObligation',
    alias: 'Military Service Obligation',
    type: 2,
  },
  MilitaryServiceYearsRemaining: {
    databaseColumn: 'MilitaryServiceYearsRemaining',
    alias: 'Military Service Years Remaining',
    type: 2,
  },
  NBOMEID: { databaseColumn: 'NBOMEID', alias: 'NBOME ID', type: 2 },
  NRMPID: { databaseColumn: 'NRMPID', alias: 'NRMP ID', type: 2 },
  OtherServiceObligation: {
    databaseColumn: 'OtherServiceObligation',
    alias: 'Other Service Obligation',
    type: 2,
  },
  PGY2Interest: {
    databaseColumn: 'PGY2Interest',
    alias: 'PGY 2 Interest',
    type: 2,
  },
  Pager: { databaseColumn: 'Pager', alias: 'Pager', type: 2 },
  ParticipatingasaCoupleinNRMP: {
    databaseColumn: 'ParticipatingasaCoupleinNRMP',
    alias: 'Participating as a Couple in NRMP',
    type: 3,
  },
  PermanentAddress: {
    databaseColumn: 'PermanentAddress',
    alias: 'Permanent Address',
    type: 2,
  },
  PermanentAddress1: {
    databaseColumn: 'PermanentAddress1',
    alias: 'Permanent Address 1',
    type: 2,
  },
  PermanentAddress2: {
    databaseColumn: 'PermanentAddress2',
    alias: 'Permanent Address 2',
    type: 2,
  },
  PermanentCity: {
    databaseColumn: 'PermanentCity',
    alias: 'Permanent City',
    type: 2,
  },
  PermanentCountry: {
    databaseColumn: 'PermanentCountry',
    alias: 'Permanent Country',
    type: 2,
  },
  PermanentPhone: {
    databaseColumn: 'PermanentPhone',
    alias: 'Permanent Phone',
    type: 2,
  },
  PermanentState: {
    databaseColumn: 'PermanentState',
    alias: 'Permanent State',
    type: 2,
  },
  PermanentZip: {
    databaseColumn: 'PermanentZip',
    alias: 'Permanent Zip',
    type: 2,
  },
  PlanstoParticipateinNRMPMatch: {
    databaseColumn: 'PlanstoParticipateinNRMPMatch',
    alias: 'Plans to Participate in NRMP Match',
    type: 3,
  },
  PreferredName: {
    databaseColumn: 'PreferredName',
    alias: 'Preferred Name',
    type: 2,
  },
  PreferredPhone: {
    databaseColumn: 'PreferredPhone',
    alias: 'Preferred Phone',
    type: 2,
  },
  PhoneNumber: {
    databaseColumn: 'PhoneNumber',
    alias: 'Phone Number',
    type: 2,
  },
  PreviousLastName: {
    databaseColumn: 'PreviousLastName',
    alias: 'Previous Last Name',
    type: 2,
  },
  SelfIdentify: {
    databaseColumn: 'SelfIdentify',
    alias: 'Self Identify',
    type: 2,
  },
  SigmaSigmaPhi: {
    databaseColumn: 'SigmaSigmaPhi',
    alias: 'Sigma Sigma Phi',
    type: 3,
  },
  TracksAppliedbyApplicant: {
    databaseColumn: 'TracksAppliedbyApplicant',
    alias: 'Tracks Appliedby Applicant',
    type: 2,
  },
  TracksConsideredbyProgram: {
    databaseColumn: 'TracksConsideredbyProgram',
    alias: 'Tracks Consideredby Program',
    type: 2,
  },
  USOrCanadianApplicant: {
    databaseColumn: 'USOrCanadianApplicant',
    alias: 'US or Canadian Applicant',
    type: 2,
  },
  USMLEID: { databaseColumn: 'USMLEID', alias: 'USMLE ID', type: 2 },
  VisaSponsorshipNeeded: {
    databaseColumn: 'VisaSponsorshipNeeded',
    alias: 'Visa Sponsorship Needed',
    type: 3,
  },
  AwardsAndRecognitionsMedicalSchool: {
    databaseColumn: 'AwardsAndRecognitionsMedicalSchool',
    alias: 'Awards and Recognitions Medical School',
    type: 2,
  },
  AwardsAndRecognitionsOthers: {
    databaseColumn: 'AwardsAndRecognitionsOthers',
    alias: 'Awards and Recognitions Others',
    type: 2,
  },
  ExplanationOfWhyMedicalEducationorTrainingInterrupted: {
    databaseColumn: 'ExplanationOfWhyMedicalEducationorTrainingInterrupted',
    alias: 'Explanation Of Why Medical Educationor Training Interrupted',
    type: 2,
  },
  HigherEducationAttendanceDates: {
    databaseColumn: 'HigherEducationAttendanceDates',
    alias: 'Higher Education Attendance Dates',
    type: 2, //NOT DATES, but a LIST of dates.
  },
  HigherEducationDegree: {
    databaseColumn: 'HigherEducationDegree',
    alias: 'Higher Education Degree',
    type: 2,
  },
  HigherEducationDegreeDateEarned: {
    databaseColumn: 'HigherEducationDegreeDateEarned',
    alias: 'Higher Education Degree Date Earned',
    type: 2, //Date
  },
  HigherEducationDegreeEarned: {
    databaseColumn: 'HigherEducationDegreeEarned',
    alias: 'Higher Education Degree Earned',
    type: 2,
  },
  HigherEducationInstitution: {
    databaseColumn: 'HigherEducationInstitution',
    alias: 'Higher Education Institution',
    type: 2,
  },
  HigherEducationLocation: {
    databaseColumn: 'HigherEducationLocation',
    alias: 'Higher Education Location',
    type: 2,
  },
  HigherEducationMajor: {
    databaseColumn: 'HigherEducationMajor',
    alias: 'Higher Education Major',
    type: 2,
  },

  MedicalTrainingProgram: {
    databaseColumn: 'MedicalTrainingProgram',
    alias: 'Medical Training Program',
    type: 2,
  },
  MedicalTrainingStartDate: {
    databaseColumn: 'MedicalTrainingStartDate',
    alias: 'Medical Training Start Date',
    type: 2,
  },
  CouplesMatch: {
    databaseColumn: 'CouplesMatch',
    alias: 'Couples Match',
    type: 3,
  },
  MedicalTrainingType: {
    databaseColumn: 'MedicalTrainingType',
    alias: 'Medical Training Type',
    type: 2,
  },
  MostRecentMedicalTrainingProgram: {
    databaseColumn: 'MostRecentMedicalTrainingProgram',
    alias: 'Most Recent Medical Training Program',
    type: 2,
  },

  MedicalDegree: {
    databaseColumn: 'MedicalDegree',
    alias: 'Medical Degree',
    type: 2,
  },
  MedicalDegreeExpectedorEarned: {
    databaseColumn: 'MedicalDegreeExpectedorEarned',
    alias: 'Medical Degree Expected or Earned',
    type: 2,
  },
  MedicalEducationorTrainingInterrupted: {
    databaseColumn: 'MedicalEducationorTrainingInterrupted',
    alias: 'Medical Educationor Training Interrupted',
    type: 2,
  },
  MedicalSchoolAttendanceDates: {
    databaseColumn: 'MedicalSchoolAttendanceDates',
    alias: 'Medical School Attendance Dates',
    type: 2, //A list of dates, NOT a date....
  },
  MedicalSchoolClinicalCampus: {
    databaseColumn: 'MedicalSchoolClinicalCampus',
    alias: 'Medical School Clinical Campus',
    type: 2,
  },
  MedicalSchoolCountry: {
    databaseColumn: 'MedicalSchoolCountry',
    alias: 'Medical School Country',
    type: 2,
  },
  MedicalSchoolDegreeDateOfGraduation: {
    databaseColumn: 'MedicalSchoolDegreeDateOfGraduation',
    alias: 'Medical School Degree Date of Graduation',
    type: 2, //Date
  },
  MedicalSchoolStateOrProvince: {
    databaseColumn: 'MedicalSchoolStateOrProvince',
    alias: 'Medical School State or Province',
    type: 2,
  },
  MedicalSchoolType: {
    databaseColumn: 'MedicalSchoolType',
    alias: 'Medical School Type',
    type: 2,
  },
  MedicalSchoolOfGraduation: {
    databaseColumn: 'MedicalSchoolOfGraduation',
    alias: 'Medical School of Graduation',
    type: 2,
  },
  MembershipinHonoraryorProfessionalSocieties: {
    databaseColumn: 'MembershipinHonoraryorProfessionalSocieties',
    alias: 'Membership in Honorary or Professional Societies',
    type: 2,
  },
  ACLS: { databaseColumn: 'ACLS', alias: 'ACLS', type: 2 },
  ACLSExpirationDate: {
    databaseColumn: 'ACLSExpirationDate',
    alias: 'ACLS Expiration Date',
    type: 2, //Date
  },
  BLS: { databaseColumn: 'BLS', alias: 'BLS', type: 2 },
  BLSExpirationDate: {
    databaseColumn: 'BLSExpirationDate',
    alias: 'BLS Expiration Date',
    type: 2, //Date
  },
  BoardCertification: {
    databaseColumn: 'BoardCertification',
    alias: 'Board Certification',
    type: 2,
  },
  BoardCertificationName: {
    databaseColumn: 'BoardCertificationName',
    alias: 'Board Certification Name',
    type: 2,
  },
  COMLEXUSALevel1Score: {
    databaseColumn: 'COMLEXUSALevel1Score',
    alias: 'COMLEX USA Level 1 Score',
    type: 1,
  },
  COMLEXUSALevel2CEScore: {
    databaseColumn: 'COMLEXUSALevel2CEScore',
    alias: 'COMLEX USA Level 2 CE Score',
    type: 1,
  },
  COMLEXUSALevel2PEScore: {
    databaseColumn: 'COMLEXUSALevel2PEScore',
    alias: 'COMLEX USA Level 2 PE Score',
    type: 1,
  },
  COMLEXUSALevel3Score: {
    databaseColumn: 'COMLEXUSALevel3Score',
    alias: 'COMLEX USA Level 3 Score',
    type: 1,
  },
  COMLEXUSALevel1DateScore: {
    databaseColumn: 'COMLEXUSALevel1DateScore',
    alias: 'COMLEX USA Level 1 Date',
    type: 2, //Date
  },
  COMLEXUSALevel2CEDateScore: {
    databaseColumn: 'COMLEXUSALevel2CEDateScore',
    alias: 'COMLEX USA Level 2 CE Date',
    type: 2, //Date
  },
  COMLEXUSALevel3DateScore: {
    databaseColumn: 'COMLEXUSALevel3DateScore',
    alias: 'COMLEX USA Level 3 Date',
    type: 2, //Date
  },
  CSAExamStatus: {
    databaseColumn: 'CSAExamStatus',
    alias: 'CSA Exam Status',
    type: 2,
  },
  CSAPassDate: {
    databaseColumn: 'CSAPassDate',
    alias: 'CSA Pass Date',
    type: 2, //Date
  },
  DEAExpirationDate: {
    databaseColumn: 'DEAExpirationDate',
    alias: 'DEA Expiration Date',
    type: 2, //Date
  },
  DEARegistrationNumber: {
    databaseColumn: 'DEARegistrationNumber',
    alias: 'DEA Registration Number',
    type: 2,
  },
  ECFMGCertificationDate: {
    databaseColumn: 'ECFMGCertificationDate',
    alias: 'ECFMG Certification Date',
    type: 2, //Date
  },
  ECFMGCertified: {
    databaseColumn: 'ECFMGCertified',
    alias: 'ECFMG Certified',
    type: 3,
  },
  MalpracticeCasesPending: {
    databaseColumn: 'MalpracticeCasesPending',
    alias: 'Malpractice Cases Pending',
    type: 2,
  },
  MalpracticeCasesPendingReason: {
    databaseColumn: 'MalpracticeCasesPendingReason',
    alias: 'Malpractice Cases Pending Reason',
    type: 2,
  },
  MedicalLicenseNumber: {
    databaseColumn: 'MedicalLicenseNumber',
    alias: 'Medical License Number',
    type: 2,
  },
  MedicalLicenseState: {
    databaseColumn: 'MedicalLicenseState',
    alias: 'Medical License State',
    type: 2,
  },
  MedicalLicenseType: {
    databaseColumn: 'MedicalLicenseType',
    alias: 'Medical License Type',
    type: 2,
  },
  MedicalLicensesExpirationDate: {
    databaseColumn: 'MedicalLicensesExpirationDate',
    alias: 'Medical Licenses Expiration Date',
    type: 2, //Date
  },
  MedicalLicensureProblem: {
    databaseColumn: 'MedicalLicensureProblem',
    alias: 'Medical Licensure Problem',
    type: 2, //Might be yes/no?
  },
  MedicalLicensureProblemReason: {
    databaseColumn: 'MedicalLicensureProblemReason',
    alias: 'Medical Licensure Problem Reason',
    type: 2,
  },
  PALS: { databaseColumn: 'PALS', alias: 'PALS', type: 2 },
  PALSExpirationDate: {
    databaseColumn: 'PALSExpirationDate',
    alias: 'PALS ExpirationDate',
    type: 2,
  },
  USMLEStep1Score: {
    databaseColumn: 'USMLEStep1Score',
    alias: 'USMLE Step 1 Score',
    type: 1,
  },
  USMLEStep2CKScore: {
    databaseColumn: 'USMLEStep2CKScore',
    alias: 'USMLE Step 2 CK Score',
    type: 1,
  },
  USMLEStep2CSScore: {
    databaseColumn: 'USMLEStep2CSScore',
    alias: 'USMLE Step 2 CS Score',
    type: 1,
  },
  USMLEStep3Score: {
    databaseColumn: 'USMLEStep3Score',
    alias: 'USMLE Step 3 Score',
    type: 1,
  },
  USMLEComletionDate: {
    databaseColumn: 'USMLEComletionDate',
    alias: 'USMLE Completion Date',
    type: 2, //Date
  },
  USMLEStep2CSDateScore: {
    databaseColumn: 'USMLEStep2CSDateScore',
    alias: 'USMLE Step 2 CS Date',
    type: 2, //Date
  },
  USMLEStep3DateScore: {
    databaseColumn: 'USMLEStep3DateScore',
    alias: 'USMLE Step 3 Date',
    type: 2, //Date
  },
  CountOfNonPeerReviewedOnlinePublication: {
    databaseColumn: 'CountOfNonPeerReviewedOnlinePublication',
    alias: 'Count of Non Peer Reviewed Online Publication',
    type: 1,
  },
  CountOfOralPresentation: {
    databaseColumn: 'CountOfOralPresentation',
    alias: 'Count of Oral Presentation',
    type: 1,
  },
  CountOfOtherArticles: {
    databaseColumn: 'CountOfOtherArticles',
    alias: 'Count of Other Articles',
    type: 1,
  },
  CountOfPeerReviewedBookChapter: {
    databaseColumn: 'CountOfPeerReviewedBookChapter',
    alias: 'Count of Peer Reviewed Book Chapter',
    type: 1,
  },
  CountOfPeerReviewedJournalArticlesAndOrAbstracts: {
    databaseColumn: 'CountOfPeerReviewedJournalArticlesAndOrAbstracts',
    alias: 'Count of Peer Reviewed Journal Articles and or Abstracts',
    type: 1,
  },
  CountOfPeerReviewedJournalArticlesAndOrAbstractsOtherThanPublished: {
    databaseColumn: 'CountOfPeerReviewedJournalArticlesAndOrAbstractsOtherThanPublished',
    alias: 'Count of Peer Reviewed Journal Articles and or Abstracts Other Than Published',
    type: 1,
  },
  CountOfPeerReviewedOnlinePublication: {
    databaseColumn: 'CountOfPeerReviewedOnlinePublication',
    alias: 'Count of Peer Reviewed Online Publication',
    type: 1,
  },
  CountOfPosterPresentation: {
    databaseColumn: 'CountOfPosterPresentation',
    alias: 'Count of Poster Presentation',
    type: 1,
  },
  CountOfScientificMonograph: {
    databaseColumn: 'CountOfScientificMonograph',
    alias: 'Count of Scientific Monograph',
    type: 1,
  },
  HigherEducationAttendanceDates_1: {
    databaseColumn: 'HigherEducationAttendanceDates_1',
    alias: 'Higher Education Attendance Dates 1',
    type: 2, //NOT A DATE (LIST OF DATES)
  },
  HigherEducationDegree_1: {
    databaseColumn: 'HigherEducationDegree_1',
    alias: 'Higher Education Degree 1',
    type: 2,
  },
  HigherEducationDegreeDateEarned_1: {
    databaseColumn: 'HigherEducationDegreeDateEarned_1',
    alias: 'Higher Education Degree Date Earned 1',
    type: 2, //Date
  },
  HigherEducationDegreeEarned_1: {
    databaseColumn: 'HigherEducationDegreeEarned_1',
    alias: 'Higher Education Degree Earned 1',
    type: 2,
  },
  HigherEducationInstitution_1: {
    databaseColumn: 'HigherEducationInstitution_1',
    alias: 'Higher Education Institution 1',
    type: 2,
  },
  HigherEducationLocation_1: {
    databaseColumn: 'HigherEducationLocation_1',
    alias: 'Higher Education Location 1',
    type: 2,
  },
  HigherEducationMajor_1: {
    databaseColumn: 'HigherEducationMajor_1',
    alias: 'Higher Education Major 1',
    type: 2,
  },
  MedicalLicenseNumber_1: {
    databaseColumn: 'MedicalLicenseNumber_1',
    alias: 'Medical License Number 1',
    type: 2,
  },
  MedicalLicenseState_1: {
    databaseColumn: 'MedicalLicenseState_1',
    alias: 'Medical License State 1',
    type: 2,
  },
  MedicalLicenseType_1: {
    databaseColumn: 'MedicalLicenseType_1',
    alias: 'Medical License Type 1',
    type: 2,
  },
  MedicalLicensesExpirationDate_1: {
    databaseColumn: 'MedicalLicensesExpirationDate_1',
    alias: 'Medical Licenses Expiration Date 1',
    type: 2, //Date
  },
  HigherEducationAttendanceDates_2: {
    databaseColumn: 'HigherEducationAttendanceDates_2',
    alias: 'Higher Education Attendance Dates 2',
    type: 2, //LIST OF DATES (Not a date)
  },
  HigherEducationDegree_2: {
    databaseColumn: 'HigherEducationDegree_2',
    alias: 'Higher Education Degree 2',
    type: 2,
  },
  HigherEducationDegreeDateEarned_2: {
    databaseColumn: 'HigherEducationDegreeDateEarned_2',
    alias: 'Higher Education Degree Date Earned 2',
    type: 2, //Date
  },
  HigherEducationDegreeEarned_2: {
    databaseColumn: 'HigherEducationDegreeEarned_2',
    alias: 'Higher Education Degree Earned 2',
    type: 2,
  },
  HigherEducationInstitution_2: {
    databaseColumn: 'HigherEducationInstitution_2',
    alias: 'Higher Education Institution 2',
    type: 2,
  },
  HigherEducationLocation_2: {
    databaseColumn: 'HigherEducationLocation_2',
    alias: 'Higher Education Location 2',
    type: 2,
  },
  HigherEducationMajor_2: {
    databaseColumn: 'HigherEducationMajor_2',
    alias: 'Higher Education Major 2',
    type: 2,
  },
  HigherEducationAttendanceDates_3: {
    databaseColumn: 'HigherEducationAttendanceDates_3',
    alias: 'Higher Education Attendance Dates 3',
    type: 2, //NOT A DATE (LIST OF DATES)
  },
  HigherEducationDegree_3: {
    databaseColumn: 'HigherEducationDegree_3',
    alias: 'Higher Education Degree 3',
    type: 2,
  },
  HigherEducationDegreeDateEarned_3: {
    databaseColumn: 'HigherEducationDegreeDateEarned_3',
    alias: 'Higher Education Degree Date Earned 3',
    type: 2, //Date
  },
  HigherEducationDegreeEarned_3: {
    databaseColumn: 'HigherEducationDegreeEarned_3',
    alias: 'Higher Education Degree Earned 3',
    type: 2,
  },
  HigherEducationInstitution_3: {
    databaseColumn: 'HigherEducationInstitution_3',
    alias: 'Higher Education Institution 3',
    type: 2,
  },
  HigherEducationLocation_3: {
    databaseColumn: 'HigherEducationLocation_3',
    alias: 'Higher Education Location 3',
    type: 2,
  },
  HigherEducationMajor_3: {
    databaseColumn: 'HigherEducationMajor_3',
    alias: 'Higher Education Major 3',
    type: 2,
  },
  LastResidency: {
    databaseColumn: 'LastResidency',
    alias: 'Last Residency',
    type: 2,
  },
  Training: {
    databaseColumn: 'Training',
    alias: 'Training',
    type: 2,
  },
  CustomField1: {
    databaseColumn: 'CustomField1',
    alias: 'Custom Field 01',
    type: 2,
  },
  CustomField2: {
    databaseColumn: 'CustomField2',
    alias: 'Custom Field 02',
    type: 2,
    isAltnerativeTable: true,
  },
  CustomField3: {
    databaseColumn: 'CustomField3',
    alias: 'Custom Field 03',
    type: 2,
    isAltnerativeTable: true,
  },
  CustomField4: {
    databaseColumn: 'CustomField4',
    alias: 'Custom Field 04',
    type: 2,
    isAltnerativeTable: true,
  },
  CustomField5: {
    databaseColumn: 'CustomField5',
    alias: 'Custom Field 05',
    type: 2,
    isAltnerativeTable: true,
  },
  CustomField6: {
    databaseColumn: 'CustomField6',
    alias: 'Custom Field 06',
    type: 2,
    isAltnerativeTable: true,
  },
  CustomField7: {
    databaseColumn: 'CustomField7',
    alias: 'Custom Field 07',
    isAltnerativeTable: true,
    type: 2,
  },
  CustomField8: {
    databaseColumn: 'CustomField8',
    alias: 'Custom Field 08',
    isAltnerativeTable: true,
    type: 2,
  },
  CustomField9: {
    databaseColumn: 'CustomField9',
    alias: 'Custom Field 09',
    isAltnerativeTable: true,
    type: 2,
  },
  CustomField10: {
    databaseColumn: 'CustomField10',
    alias: 'Custom Field 10',
    isAltnerativeTable: true,
    type: 2,
  },
  CustomField11: {
    databaseColumn: 'CustomField11',
    alias: 'Custom Field 11',
    isAltnerativeTable: true,
    type: 2,
  },
  CustomField12: {
    databaseColumn: 'CustomField12',
    alias: 'Custom Field 12',
    isAltnerativeTable: true,
    type: 2,
  },
  CustomField13: {
    databaseColumn: 'CustomField13',
    alias: 'Custom Field 13',
    isAltnerativeTable: true,
    type: 2,
  },
  CustomField14: {
    databaseColumn: 'CustomField14',
    alias: 'Custom Field 14',
    isAltnerativeTable: true,
    type: 2,
  },
  CustomField15: {
    databaseColumn: 'CustomField15',
    alias: 'Custom Field 15',
    isAltnerativeTable: true,
    type: 2,
  },
  CustomField16: {
    databaseColumn: 'CustomField16',
    alias: 'Custom Field 16',
    isAltnerativeTable: true,
    type: 2,
  },
  CustomField17: {
    databaseColumn: 'CustomField17',
    alias: 'Custom Field 17',
    isAltnerativeTable: true,
    type: 2,
  },
  CustomField18: {
    databaseColumn: 'CustomField18',
    alias: 'Custom Field 18',
    isAltnerativeTable: true,
    type: 2,
  },
  CustomField19: {
    databaseColumn: 'CustomField19',
    alias: 'Custom Field 19',
    isAltnerativeTable: true,
    type: 2,
  },
  CustomField20: {
    databaseColumn: 'CustomField20',
    alias: 'Custom Field 20',
    isAltnerativeTable: true,
    type: 2,
  },
  CustomField21: {
    databaseColumn: 'CustomField21',
    alias: 'Custom Field 21',
    isAltnerativeTable: true,
    type: 2,
  },
  CustomField22: {
    databaseColumn: 'CustomField22',
    alias: 'Custom Field 22',
    type: 2,
  },
  CustomField23: {
    databaseColumn: 'CustomField23',
    alias: 'Custom Field 23',
    type: 2,
  },
  CustomField24: {
    databaseColumn: 'CustomField24',
    alias: 'Custom Field 24',
    type: 2,
  },
  CustomField25: {
    databaseColumn: 'CustomField25',
    alias: 'Custom Field 25',
    type: 2,
  },
  CustomField26: {
    databaseColumn: 'CustomField26',
    alias: 'Custom Field 26',
    type: 2,
  },
  CustomField27: {
    databaseColumn: 'CustomField27',
    alias: 'Custom Field 27',
    type: 2,
  },
  CustomField28: {
    databaseColumn: 'CustomField28',
    alias: 'Custom Field 28',
    type: 2,
  },
  CustomField29: {
    databaseColumn: 'CustomField29',
    alias: 'Custom Field 29',
    type: 2,
  },
  CustomField30: {
    databaseColumn: 'CustomField30',
    alias: 'Custom Field 30',
    type: 2,
  },
  CustomField31: {
    databaseColumn: 'CustomField31',
    alias: 'Custom Field 31',
    type: 2,
  },
  CustomField32: {
    databaseColumn: 'CustomField32',
    alias: 'Custom Field 32',
    type: 2,
  },
  CustomField33: {
    databaseColumn: 'CustomField33',
    alias: 'Custom Field 33',
    type: 2,
  },
  CustomField34: {
    databaseColumn: 'CustomField34',
    alias: 'Custom Field 34',
    type: 2,
  },
  CustomField35: {
    databaseColumn: 'CustomField35',
    alias: 'Custom Field 35',
    type: 2,
  },
  CustomField36: {
    databaseColumn: 'CustomField36',
    alias: 'Custom Field 36',
    type: 2,
  },
  CustomField37: {
    databaseColumn: 'CustomField37',
    alias: 'Custom Field 37',
    type: 2,
  },
  CustomField38: {
    databaseColumn: 'CustomField38',
    alias: 'Custom Field 38',
    type: 2,
  },
  CustomField39: {
    databaseColumn: 'CustomField39',
    alias: 'Custom Field 39',
    type: 2,
  },
  CustomField40: {
    databaseColumn: 'CustomField40',
    alias: 'Custom Field 40',
    type: 2,
  },
  CustomField41: {
    databaseColumn: 'CustomField41',
    alias: 'Custom Field 41',
    type: 2,
  },
  CustomField42: {
    databaseColumn: 'CustomField42',
    alias: 'Custom Field 42',
    type: 2,
  },
  CustomField43: {
    databaseColumn: 'CustomField43',
    alias: 'Custom Field 43',
    type: 2,
  },
  CustomField44: {
    databaseColumn: 'CustomField44',
    alias: 'Custom Field 44',
    type: 2,
  },
  CustomField45: {
    databaseColumn: 'CustomField45',
    alias: 'Custom Field 45',
    type: 2,
  },
  CustomField46: {
    databaseColumn: 'CustomField46',
    alias: 'Custom Field 46',
    type: 2,
  },
  CustomField47: {
    databaseColumn: 'CustomField47',
    alias: 'Custom Field 47',
    type: 2,
  },
  CustomField48: {
    databaseColumn: 'CustomField48',
    alias: 'Custom Field 48',
    type: 2,
  },
  CustomField49: {
    databaseColumn: 'CustomField49',
    alias: 'Custom Field 49',
    type: 2,
  },
  CustomField50: {
    databaseColumn: 'CustomField50',
    alias: 'Custom Field 50',
    type: 2,
  },
  PreferredFirst: {
    databaseColumn: 'PreferredFirst',
    alias: 'Preferred First',
    type: 2,
  },
  PreferredMiddle: {
    databaseColumn: 'PreferredMiddle',
    alias: 'Preferred Middle',
    type: 2,
  },
  PreferredLast: {
    databaseColumn: 'PreferredLast',
    alias: 'Preferred Last',
    type: 2,
  },
  SFMatchID: { databaseColumn: 'SFMatchID', alias: 'SF Match ID', type: 2 },
  CurrentResidency: {
    databaseColumn: 'CurrentResidency',
    alias: 'Current Residency',
    type: 2,
  },
  YearOfResidencyCompletion: {
    databaseColumn: 'YearOfResidencyCompletion',
    alias: 'Year of Residency Completion',
    type: 2, //Date
  },
  Category: { databaseColumn: 'Category', alias: 'Category', type: 2 },
  FirstDelivery: {
    databaseColumn: 'FirstDelivery',
    alias: 'First Delivery',
    type: 2,
  },
  CurrentWorkAuthorization: {
    databaseColumn: 'CurrentWorkAuthorization',
    alias: 'Current Work Authorization',
    type: 2,
    isAltnerativeTable: true,
  },
  QualifyingExperience: {
    databaseColumn: 'QualifyingExperience',
    alias: 'Qualifying Experience',
    type: 2,
    isAltnerativeTable: true,
  },
  USMLEStep1Status: {
    databaseColumn: 'USMLEStep1Status',
    alias: 'USMLE Step 1 Status',
    type: 2,
    isAltnerativeTable: true,
  },
  VisaSponsorshipSought: {
    databaseColumn: 'VisaSponsorshipSought',
    alias: 'Visa Sponsorship Sought',
    type: 3,
  },
  FelonyConviction: {
    databaseColumn: 'FelonyConviction',
    alias: 'Felony Conviction',
    type: 3,
    isAltnerativeTable: true,
  },
  FelonyConvictionReason: {
    databaseColumn: 'FelonyConvictionReason',
    alias: 'Felony Conviction Reason',
    type: 2,
    isAltnerativeTable: true,
  },
  HigherEducationDegreeEarned_4: {
    databaseColumn: 'HigherEducationDegreeEarned_4',
    alias: 'Higher Education Degree Earned 4',
    type: 2,
    isAltnerativeTable: true,
  },
  HigherEducationInstitution_4: {
    databaseColumn: 'HigherEducationInstitution_4',
    alias: 'Higher Education Institution 4',
    type: 2,
    isAltnerativeTable: true,
  },
  HigherEducationMajor_4: {
    databaseColumn: 'HigherEducationMajor_4',
    alias: 'Higher Education Major 4',
    type: 2,
    isAltnerativeTable: true,
  },
  ImpactfulExperienceEssay: {
    databaseColumn: 'ImpactfulExperienceEssay',
    alias: 'Impactful Experience Essay',
    type: 2,
    isAltnerativeTable: true,
  },
  LimitingFactors: {
    databaseColumn: 'LimitingFactors',
    alias: 'Limiting Factors',
    type: 3,
    isAltnerativeTable: true,
  },
  MedicalDegree_1: {
    databaseColumn: 'MedicalDegree_1',
    alias: 'Medical Degree 1',
    type: 2,
    isAltnerativeTable: true,
  },
  MedicalSchoolAttendanceDates_1: {
    databaseColumn: 'MedicalSchoolAttendanceDates_1',
    alias: 'Medical School Attendance Dates 1',
    type: 2,
    isAltnerativeTable: true,
  },
  MedicalSchoolofGraduation_1: {
    databaseColumn: 'MedicalSchoolofGraduation_1',
    alias: 'Medical School of Graduation 1',
    type: 2,
    isAltnerativeTable: true,
  },
  MisdemeanorConviction: {
    databaseColumn: 'MisdemeanorConviction',
    alias: 'Misdemeanor Conviction',
    type: 3,
    isAltnerativeTable: true,
  },
  MisdemeanorConvictionReason: {
    databaseColumn: 'MisdemeanorConvictionReason',
    alias: 'Misdemeanor Conviction Reason',
    type: 2,
    isAltnerativeTable: true,
  },
  NoteContent: {
    databaseColumn: 'NoteContent',
    alias: 'Note Content',
    type: 2,
  },
  ProfessionalExperienceMeaningfulExperienceEssay: {
    databaseColumn: 'ProfessionalExperienceMeaningfulExperienceEssay',
    alias: 'Professional Experience Meaningful Experience Essay',
    type: 2,
    isAltnerativeTable: true,
  },
  ProfessionalExperienceMeaningfulExperienceEssay_1: {
    databaseColumn: 'ProfessionalExperienceMeaningfulExperienceEssay_1',
    alias: 'Professional Experience Meaningful Experience Essay 1',
    type: 2,
    isAltnerativeTable: true,
  },
  ProfessionalExperienceMeaningfulExperienceEssay_2: {
    databaseColumn: 'ProfessionalExperienceMeaningfulExperienceEssay_2',
    alias: 'Professional Experience Meaningful Experience Essay 2',
    type: 2,
    isAltnerativeTable: true,
  },
  ProfessionalExperienceMeaningfulExperienceEssay_3: {
    databaseColumn: 'ProfessionalExperienceMeaningfulExperienceEssay_3',
    alias: 'Professional Experience Meaningful Experience Essay 3',
    type: 2,
    isAltnerativeTable: true,
  },
  ProfessionalExperienceMeaningfulExperienceEssay_4: {
    databaseColumn: 'ProfessionalExperienceMeaningfulExperienceEssay_4',
    alias: 'Professional Experience Meaningful Experience Essay 4',
    type: 2,
    isAltnerativeTable: true,
  },
  ProfessionalExperienceMeaningfulExperienceEssay_5: {
    databaseColumn: 'ProfessionalExperienceMeaningfulExperienceEssay_5',
    alias: 'Professional Experience Meaningful Experience Essay 5',
    type: 2,
    isAltnerativeTable: true,
  },
  ProfessionalExperienceMeaningfulExperienceEssay_6: {
    databaseColumn: 'ProfessionalExperienceMeaningfulExperienceEssay_6',
    alias: 'Professional Experience Meaningful Experience Essay 6',
    type: 2,
    isAltnerativeTable: true,
  },
  ProfessionalExperienceMeaningfulExperienceEssay_7: {
    databaseColumn: 'ProfessionalExperienceMeaningfulExperienceEssay_7',
    alias: 'Professional Experience Meaningful Experience Essay 7',
    type: 2,
    isAltnerativeTable: true,
  },
  ProfessionalExperienceMeaningfulExperienceEssay_8: {
    databaseColumn: 'ProfessionalExperienceMeaningfulExperienceEssay_8',
    alias: 'Professional Experience Meaningful Experience Essay 8',
    type: 2,
    isAltnerativeTable: true,
  },
  ProfessionalExperienceMeaningfulExperienceEssay_9: {
    databaseColumn: 'ProfessionalExperienceMeaningfulExperienceEssay_9',
    alias: 'Professional Experience Meaningful Experience Essay 9',
    type: 2,
    isAltnerativeTable: true,
  },
  ProfessionalExperienceOrganization: {
    databaseColumn: 'ProfessionalExperienceOrganization',
    alias: 'Professional Experience Organization',
    type: 2,
    isAltnerativeTable: true,
  },
  ProfessionalExperienceOrganization_1: {
    databaseColumn: 'ProfessionalExperienceOrganization_1',
    alias: 'Professional Experience Organization 1',
    type: 2,
    isAltnerativeTable: true,
  },
  ProfessionalExperienceOrganization_2: {
    databaseColumn: 'ProfessionalExperienceOrganization_2',
    alias: 'Professional Experience Organization 2',
    type: 2,
    isAltnerativeTable: true,
  },
  ProfessionalExperienceOrganization_3: {
    databaseColumn: 'ProfessionalExperienceOrganization_3',
    alias: 'Professional Experience Organization 3',
    type: 2,
    isAltnerativeTable: true,
  },
  ProfessionalExperienceOrganization_4: {
    databaseColumn: 'ProfessionalExperienceOrganization_4',
    alias: 'Professional Experience Organization 4',
    type: 2,
    isAltnerativeTable: true,
  },
  ProfessionalExperienceOrganization_5: {
    databaseColumn: 'ProfessionalExperienceOrganization_5',
    alias: 'Professional Experience Organization 5',
    type: 2,
    isAltnerativeTable: true,
  },
  ProfessionalExperienceOrganization_6: {
    databaseColumn: 'ProfessionalExperienceOrganization_6',
    alias: 'Professional Experience Organization 6',
    type: 2,
    isAltnerativeTable: true,
  },
  ProfessionalExperienceOrganization_7: {
    databaseColumn: 'ProfessionalExperienceOrganization_7',
    alias: 'Professional Experience Organization 7',
    type: 2,
    isAltnerativeTable: true,
  },
  ProfessionalExperienceOrganization_8: {
    databaseColumn: 'ProfessionalExperienceOrganization_8',
    alias: 'Professional Experience Organization 8',
    type: 2,
    isAltnerativeTable: true,
  },
  ProfessionalExperienceOrganization_9: {
    databaseColumn: 'ProfessionalExperienceOrganization_9',
    alias: 'Professional Experience Organization 9',
    type: 2,
    isAltnerativeTable: true,
  },
  AuthorizedToWorkInTheUS: {
    databaseColumn: 'AuthorizedToWorkInTheUS',
    alias: 'Authorized to Work in the US',
    type: 3,
    isAltnerativeTable: true,
  },
  LastUpdated: {
    databaseColumn: 'LastUpdated',
    alias: 'Last Updated',
    type: 2, //Date
  },
  LastReviewed: {
    databaseColumn: 'LastReviewed',
    alias: 'Last Reviewed',
    type: 2, //Date
  },
  DivisionPreference: {
    databaseColumn: 'DivisionPreference',
    alias: 'Supplemental Division Preference',
    type: 2,
  },
  ProgramSignal: {
    databaseColumn: 'ProgramSignal',
    alias: 'Supplemental Program Signal',
    type: 1,
  },

  ReviewerComments: {
    databaseColumn: 'ReviewerComments',
    alias: 'Reviewer Comments',
    type: 2,
  },

  DivisionPreferenceEssay: {
    databaseColumn: 'DivisionPreferenceEssay',
    alias: 'Supplemental Division Preference Essay',
    type: 2,
  },
  FrequencyOfParticipation1: {
    databaseColumn: 'FrequencyOfParticipation1',
    alias: 'Supplemental Frequency of Participation 1',
    type: 2,
  },
  FrequencyOfParticipation2: {
    databaseColumn: 'FrequencyOfParticipation2',
    alias: 'Supplemental Frequency of Participation 2',
    type: 2,
  },
  FrequencyOfParticipation3: {
    databaseColumn: 'FrequencyOfParticipation3',
    alias: 'Supplemental Frequency of Participation 3',
    type: 2,
  },
  FrequencyOfParticipation4: {
    databaseColumn: 'FrequencyOfParticipation4',
    alias: 'Supplemental Frequency of Participation 4',
    type: 2,
  },
  FrequencyOfParticipation5: {
    databaseColumn: 'FrequencyOfParticipation5',
    alias: 'Supplemental Frequency of Participation 5',
    type: 2,
  },
  SupplementalEndDate1: {
    databaseColumn: 'SupplementalEndDate1',
    alias: 'Supplemental End Date 1',
    type: 2,
  },
  SupplementalEndDate2: {
    databaseColumn: 'SupplementalEndDate2',
    alias: 'Supplemental End Date 2',
    type: 2,
  },
  SupplementalEndDate3: {
    databaseColumn: 'SupplementalEndDate3',
    alias: 'Supplemental End Date 3',
    type: 2,
  },
  SupplementalEndDate4: {
    databaseColumn: 'SupplementalEndDate4',
    alias: 'Supplemental End Date 4',
    type: 2,
  },
  SupplementalEndDate5: {
    databaseColumn: 'SupplementalEndDate5',
    alias: 'Supplemental End Date 5',
    type: 2,
  },
  SupplementalEssay1: {
    databaseColumn: 'SupplementalEssay1',
    alias: 'Supplemental Essay 1',
    type: 2,
  },
  SupplementalEssay2: {
    databaseColumn: 'SupplementalEssay2',
    alias: 'Supplemental Essay 2',
    type: 2,
  },
  SupplementalEssay3: {
    databaseColumn: 'SupplementalEssay3',
    alias: 'Supplemental Essay 3',
    type: 2,
  },
  SupplementalEssay4: {
    databaseColumn: 'SupplementalEssay4',
    alias: 'Supplemental Essay 4',
    type: 2,
  },
  SupplementalEssay5: {
    databaseColumn: 'SupplementalEssay5',
    alias: 'Supplemental Essay 5',
    type: 2,
  },
  SupplementalExperienceType1: {
    databaseColumn: 'SupplementalExperienceType1',
    alias: 'Supplemental Experience Type 1',
    type: 2,
  },
  SupplementalExperienceType2: {
    databaseColumn: 'SupplementalExperienceType2',
    alias: 'Supplemental Experience Type 2',
    type: 2,
  },
  SupplementalExperienceType3: {
    databaseColumn: 'SupplementalExperienceType3',
    alias: 'Supplemental Experience Type 3',
    type: 2,
  },
  SupplementalExperienceType4: {
    databaseColumn: 'SupplementalExperienceType4',
    alias: 'Supplemental Experience Type 4',
    type: 2,
  },
  SupplementalExperienceType5: {
    databaseColumn: 'SupplementalExperienceType5',
    alias: 'Supplemental Experience Type 5',
    type: 2,
  },
  SupplementalFocusArea1: {
    databaseColumn: 'SupplementalFocusArea1',
    alias: 'Supplemental Focus Area 1',
    type: 2,
  },
  SupplementalFocusArea2: {
    databaseColumn: 'SupplementalFocusArea2',
    alias: 'Supplemental Focus Area 2',
    type: 2,
  },
  SupplementalFocusArea3: {
    databaseColumn: 'SupplementalFocusArea3',
    alias: 'Supplemental Focus Area 3',
    type: 2,
  },
  SupplementalFocusArea4: {
    databaseColumn: 'SupplementalFocusArea4',
    alias: 'Supplemental Focus Area 4',
    type: 2,
  },
  SupplementalFocusArea5: {
    databaseColumn: 'SupplementalFocusArea5',
    alias: 'Supplemental Focus Area 5',
    type: 2,
  },
  SupplementalImpactfulExperiences: {
    databaseColumn: 'SupplementalImpactfulExperiences',
    alias: 'Supplemental Impactful Experiences',
    type: 2,
  },
  SupplementalKeyCharacteristic1: {
    databaseColumn: 'SupplementalKeyCharacteristic1',
    alias: 'Supplemental Key Characteristic1',
    type: 2,
  },
  SupplementalKeyCharacteristic2: {
    databaseColumn: 'SupplementalKeyCharacteristic2',
    alias: 'Supplemental Key Characteristic2',
    type: 2,
  },
  SupplementalKeyCharacteristic3: {
    databaseColumn: 'SupplementalKeyCharacteristic3',
    alias: 'Supplemental Key Characteristic3',
    type: 2,
  },
  SupplementalKeyCharacteristic4: {
    databaseColumn: 'SupplementalKeyCharacteristic4',
    alias: 'Supplemental Key Characteristic4',
    type: 2,
  },
  SupplementalKeyCharacteristic5: {
    databaseColumn: 'SupplementalKeyCharacteristic5',
    alias: 'Supplemental Key Characteristic5',
    type: 2,
  },
  SupplementalOngoing1: {
    databaseColumn: 'SupplementalOngoing1',
    alias: 'Supplemental Ongoing 1',
    type: 2,
  },
  SupplementalOngoing2: {
    databaseColumn: 'SupplementalOngoing2',
    alias: 'Supplemental Ongoing 2',
    type: 2,
  },
  SupplementalOngoing3: {
    databaseColumn: 'SupplementalOngoing3',
    alias: 'Supplemental Ongoing 3',
    type: 2,
  },
  SupplementalOngoing4: {
    databaseColumn: 'SupplementalOngoing4',
    alias: 'Supplemental Ongoing 4',
    type: 2,
  },
  SupplementalOngoing5: {
    databaseColumn: 'SupplementalOngoing5',
    alias: 'Supplemental Ongoing 5',
    type: 2,
  },
  SupplementalOrganizationName1: {
    databaseColumn: 'SupplementalOrganizationName1',
    alias: 'Supplemental Organization Name 1',
    type: 2,
  },
  SupplementalOrganizationName2: {
    databaseColumn: 'SupplementalOrganizationName2',
    alias: 'Supplemental Organization Name 2',
    type: 2,
  },
  SupplementalOrganizationName3: {
    databaseColumn: 'SupplementalOrganizationName3',
    alias: 'Supplemental Organization Name 3',
    type: 2,
  },
  SupplementalOrganizationName4: {
    databaseColumn: 'SupplementalOrganizationName4',
    alias: 'Supplemental Organization Name 4',
    type: 2,
  },
  SupplementalOrganizationName5: {
    databaseColumn: 'SupplementalOrganizationName5',
    alias: 'Supplemental Organization Name 5',
    type: 2,
  },
  SupplementalPositionTitle1: {
    databaseColumn: 'SupplementalPositionTitle1',
    alias: 'Supplemental Position Title 1',
    type: 2,
  },
  SupplementalPositionTitle2: {
    databaseColumn: 'SupplementalPositionTitle2',
    alias: 'Supplemental Position Title 2',
    type: 2,
  },
  SupplementalPositionTitle3: {
    databaseColumn: 'SupplementalPositionTitle3',
    alias: 'Supplemental Position Title 3',
    type: 2,
  },
  SupplementalPositionTitle4: {
    databaseColumn: 'SupplementalPositionTitle4',
    alias: 'Supplemental Position Title 4',
    type: 2,
  },
  SupplementalPositionTitle5: {
    databaseColumn: 'SupplementalPositionTitle5',
    alias: 'Supplemental Position Title 5',
    type: 2,
  },
  SupplementalSetting1: {
    databaseColumn: 'SupplementalSetting1',
    alias: 'Supplemental Setting 1',
    type: 2,
  },
  SupplementalSetting2: {
    databaseColumn: 'SupplementalSetting2',
    alias: 'Supplemental Setting 2',
    type: 2,
  },
  SupplementalSetting3: {
    databaseColumn: 'SupplementalSetting3',
    alias: 'Supplemental Setting 3',
    type: 2,
  },
  SupplementalSetting4: {
    databaseColumn: 'SupplementalSetting4',
    alias: 'Supplemental Setting 4',
    type: 2,
  },
  SupplementalSetting5: {
    databaseColumn: 'SupplementalSetting5',
    alias: 'Supplemental Setting 5',
    type: 2,
  },
  SupplementalSettingPreference: {
    databaseColumn: 'SupplementalSettingPreference',
    alias: 'Supplemental Setting Preference',
    type: 2,
  },
  SupplementalSettingPreferenceEssay: {
    databaseColumn: 'SupplementalSettingPreferenceEssay',
    alias: 'Supplemental Setting Preference Essay',
    type: 2,
  },
  SupplementalStartDate1: {
    databaseColumn: 'SupplementalStartDate1',
    alias: 'Supplemental Start Date 1',
    type: 2,
  },
  SupplementalStartDate2: {
    databaseColumn: 'SupplementalStartDate2',
    alias: 'Supplemental Start Date 2',
    type: 2,
  },
  SupplementalStartDate3: {
    databaseColumn: 'SupplementalStartDate3',
    alias: 'Supplemental Start Date 3',
    type: 2,
  },
  SupplementalStartDate4: {
    databaseColumn: 'SupplementalStartDate4',
    alias: 'Supplemental Start Date 4',
    type: 2,
  },
  SupplementalStartDate5: {
    databaseColumn: 'SupplementalStartDate5',
    alias: 'Supplemental Start Date 5',
    type: 2,
  },
  /*
//Removing RezRATEID for now -- not yet implemented.
  RezRateID: {
    databaseColumn: "RezRateID",
    alias: "RezRateID",
    type: 2,
  },
*/
};

const AAMCIDCSVData = {
  // START AAMCID //
  'AAMC ID': {
    matchingObject: candidateImportFields.AAMCID,
  },
  // AAMCID: {
  //   matchingObject: candidateImportFields.AAMCID,
  // },
  // END AAMCID //

  // -----------------------------

  // START ACLS //
  ACLS: {
    matchingObject: candidateImportFields.ACLS,
  },
  // END ACLS //

  // -----------------------------

  // START ACLSExpirationDate //
  'ACLS EXPIRATION DATE': {
    matchingObject: candidateImportFields.ACLSExpirationDate,
  },
  // END ACLSExpirationDate //

  // -----------------------------

  // START AlphaOmegaAlpha //
  'ALPHA OMEGA ALPHA (YES/NO)': {
    matchingObject: candidateImportFields.AlphaOmegaAlpha,
  },
  AOASTATUS: {
    matchingObject: candidateImportFields.AlphaOmegaAlpha,
  },
  'AOA STATUS': {
    matchingObject: candidateImportFields.AlphaOmegaAlpha,
  },
  // END AlphaOmegaAlpha //

  // -----------------------------

  // START AlternatePhone //
  'ALTERNATE PHONE #': {
    matchingObject: candidateImportFields.AlternatePhone,
  },
  PHONECELL: {
    matchingObject: candidateImportFields.AlternatePhone,
  },
  // END AlternatePhone //

  // -----------------------------

  // START ApplicantName //
  'APPLICANT NAME': {
    matchingObject: candidateImportFields.ApplicantName,
  },
  // END ApplicantName //

  // -----------------------------

  // START AuthorizedToWorkInTheUS //
  'AUTHORIZED TO WORK IN THE US': {
    matchingObject: candidateImportFields.AuthorizedToWorkInTheUS,
  },
  // END AuthorizedToWorkInTheUS //

  // -----------------------------

  // START AwardsAndRecognitionsMedicalSchool //
  'AWARDS & RECOGNITIONS - MEDICAL SCHOOL': {
    matchingObject: candidateImportFields.AwardsAndRecognitionsMedicalSchool,
  },
  // END AwardsAndRecognitionsMedicalSchool //

  // -----------------------------

  // START AwardsAndRecognitionsOthers //
  'AWARDS & RECOGNITIONS - OTHERS': {
    matchingObject: candidateImportFields.AwardsAndRecognitionsOthers,
  },
  // END AwardsAndRecognitionsOthers //

  // -----------------------------

  // START BLS //
  BLS: {
    matchingObject: candidateImportFields.BLS,
  },
  // END BLS //

  // -----------------------------

  // START BLSExpirationDate //
  'BLS EXPIRATION DATE': {
    matchingObject: candidateImportFields.BLSExpirationDate,
  },
  // END BLSExpirationDate //

  // -----------------------------

  // START BirthPlace //
  'BIRTH PLACE': {
    matchingObject: candidateImportFields.BirthPlace,
  },
  // END BirthPlace //

  // -----------------------------

  // START BoardCertification //
  'BOARD CERTIFICATION': {
    matchingObject: candidateImportFields.BoardCertification,
  },
  // END BoardCertification //

  // -----------------------------

  // START BoardCertificationName //
  'BOARD CERTIFICATION NAME': {
    matchingObject: candidateImportFields.BoardCertificationName,
  },
  // END BoardCertificationName //

  // -----------------------------

  // START COMLEXUSALevel1DateScore //
  COMLEXSTEP1DATETAKEN: {
    matchingObject: candidateImportFields.COMLEXUSALevel1DateScore,
  },
  'COMLEX STEP 1 DATE TAKEN': {
    matchingObject: candidateImportFields.COMLEXUSALevel1DateScore,
  },
  'COMLEX LEVEL 1 - DATE TAKEN': {
    matchingObject: candidateImportFields.COMLEXUSALevel1DateScore,
  },
  // END COMLEXUSALevel1DateScore //

  // -----------------------------

  // START COMLEXUSALevel1Score //
  'COMLEX-USA LEVEL 1 SCORE': {
    matchingObject: candidateImportFields.COMLEXUSALevel1Score,
  },
  'COMLEX1 SCORE': {
    matchingObject: candidateImportFields.COMLEXUSALevel1Score,
  },
  'COMLEX STEP 1 Score': {
    matchingObject: candidateImportFields.COMLEXUSALevel1Score,
  },
  'COMLEX LEVEL 1 - RESULT': {
    matchingObject: candidateImportFields.COMLEXUSALevel1Score,
  },
  // END COMLEXUSALevel1Score //

  // -----------------------------

  // START COMLEXUSALevel2CEDateScore //
  COMLEXSTEP2DATETAKEN: {
    matchingObject: candidateImportFields.COMLEXUSALevel2CEDateScore,
  },
  'COMLEX LEVEL 2 - DATE TAKEN': {
    matchingObject: candidateImportFields.COMLEXUSALevel2CEDateScore,
  },
  // END COMLEXUSALevel2CEDateScore //

  // -----------------------------

  // START COMLEXUSALevel2CEScore //
  'COMLEX-USA LEVEL 2 CE SCORE': {
    matchingObject: candidateImportFields.COMLEXUSALevel2CEScore,
  },
  'COMLEX STEP 2 SCORE': {
    matchingObject: candidateImportFields.COMLEXUSALevel2CEScore,
  },
  'COMLEX2 SCORE': {
    matchingObject: candidateImportFields.COMLEXUSALevel2CEScore,
  },
  'COMLEX LEVEL 2 - SCORE': {
    matchingObject: candidateImportFields.COMLEXUSALevel2CEScore,
  },
  // END COMLEXUSALevel2CEScore //

  // -----------------------------

  // START COMLEXUSALevel2PEScore //
  'COMLEX-USA LEVEL 2 PE SCORE': {
    matchingObject: candidateImportFields.COMLEXUSALevel2PEScore,
  },
  // END COMLEXUSALevel2PEScore //

  // -----------------------------

  // START COMLEXUSALevel3DateScore //
  COMLEXSTEP3DATETAKEN: {
    matchingObject: candidateImportFields.COMLEXUSALevel3DateScore,
  },
  'COMLEX STEP 3 DATE TAKEN': {
    matchingObject: candidateImportFields.COMLEXUSALevel3DateScore,
  },
  'COMLEX LEVEL 3 - DATE TAKEN': {
    matchingObject: candidateImportFields.COMLEXUSALevel3DateScore,
  },
  // END COMLEXUSALevel3DateScore //

  // -----------------------------

  // START COMLEXUSALevel3Score //
  'COMLEX-USA LEVEL 3 SCORE': {
    matchingObject: candidateImportFields.COMLEXUSALevel3Score,
  },
  'COMLEX3 SCORE': {
    matchingObject: candidateImportFields.COMLEXUSALevel3Score,
  },
  'COMLEX STEP 3 SCORE': {
    matchingObject: candidateImportFields.COMLEXUSALevel3Score,
  },
  'COMLEX LEVEL 3 - SCORE': {
    matchingObject: candidateImportFields.COMLEXUSALevel3Score,
  },
  // END COMLEXUSALevel3Score //

  // -----------------------------

  // START CSAExamStatus //
  'CSA EXAM STATUS': {
    matchingObject: candidateImportFields.CSAExamStatus,
  },
  // END CSAExamStatus //

  // -----------------------------

  // START CSAPassDate //
  'CSA PASS DATE': {
    matchingObject: candidateImportFields.CSAPassDate,
  },
  // END CSAPassDate //

  // -----------------------------

  // START Category //
  CATEGORY: {
    matchingObject: candidateImportFields.Category,
  },
  // END Category //

  // -----------------------------

  // START CellPhone //
  'CELL PHONE #': {
    matchingObject: candidateImportFields.CellPhone,
  },
  'CELL PHONE': {
    matchingObject: candidateImportFields.CellPhone,
  },
  // END CellPhone //

  // -----------------------------

  // START Citizenship //
  CITIZENSHIP: {
    matchingObject: candidateImportFields.Citizenship,
  },
  // END Citizenship //

  // -----------------------------

  // START ContactAddress1 //
  'CONTACT ADDRESS 1': {
    matchingObject: candidateImportFields.ContactAddress1,
  },
  'ADDRESS 1': {
    matchingObject: candidateImportFields.ContactAddress1,
  },
  ADDRESS1: {
    matchingObject: candidateImportFields.ContactAddress1,
  },
  // END ContactAddress1 //

  // -----------------------------

  // START ContactAddress2 //
  'CONTACT ADDRESS 2': {
    matchingObject: candidateImportFields.ContactAddress2,
  },
  'ADDRESS 2': {
    matchingObject: candidateImportFields.ContactAddress2,
  },
  ADDRESS2: {
    matchingObject: candidateImportFields.ContactAddress2,
  },
  // END ContactAddress2 //

  // -----------------------------

  // START ContactCity //
  'CONTACT CITY': {
    matchingObject: candidateImportFields.ContactCity,
  },
  CITY: {
    matchingObject: candidateImportFields.ContactCity,
  },
  // END ContactCity //

  // -----------------------------

  // START ContactCountry //
  'CONTACT COUNTRY': {
    matchingObject: candidateImportFields.ContactCountry,
  },
  COUNTRY: {
    matchingObject: candidateImportFields.ContactCountry,
  },
  // END ContactCountry //

  // -----------------------------

  // START ContactState //
  'CONTACT STATE': {
    matchingObject: candidateImportFields.ContactState,
  },
  STATE: {
    matchingObject: candidateImportFields.ContactState,
  },
  // END ContactState //

  // -----------------------------

  // START ContactZip //
  'CONTACT ZIP': {
    matchingObject: candidateImportFields.ContactZip,
  },
  ZIP: {
    matchingObject: candidateImportFields.ContactZip,
  },
  // END ContactZip //

  // -----------------------------

  // START CountOfNonPeerReviewedOnlinePublication //
  'COUNT OF NON PEER REVIEWED ONLINE PUBLICATION': {
    matchingObject: candidateImportFields.CountOfNonPeerReviewedOnlinePublication,
  },
  // END CountOfNonPeerReviewedOnlinePublication //

  // -----------------------------

  // START CountOfPeerReviewedOnlinePublication //
  'COUNT OF PEER REVIEWED ONLINE PUBLICATION': {
    matchingObject: candidateImportFields.CountOfPeerReviewedOnlinePublication,
  },
  // END CountOfPeerReviewedOnlinePublication //

  // -----------------------------

  // START CountOfOralPresentation //
  'COUNT OF ORAL PRESENTATION': {
    matchingObject: candidateImportFields.CountOfOralPresentation,
  },
  // END CountOfOralPresentation //

  // -----------------------------

  // START CountOfOtherArticles //
  'COUNT OF OTHER ARTICLES': {
    matchingObject: candidateImportFields.CountOfOtherArticles,
  },
  // END CountOfOtherArticles //

  // -----------------------------

  // START CountOfPeerReviewedBookChapter //
  'COUNT OF PEER REVIEWED BOOK CHAPTER': {
    matchingObject: candidateImportFields.CountOfPeerReviewedBookChapter,
  },
  // END CountOfPeerReviewedBookChapter //

  // -----------------------------

  // START CountOfPeerReviewedJournalArticlesAndOrAbstracts //
  'COUNT OF PEER REVIEWED JOURNAL ARTICLES/ABSTRACTS': {
    matchingObject: candidateImportFields.CountOfPeerReviewedJournalArticlesAndOrAbstracts,
  },
  // END CountOfPeerReviewedJournalArticlesAndOrAbstracts //

  // -----------------------------

  // START CountOfPeerReviewedJournalArticlesAndOrAbstractsOtherThanPublished //
  'COUNT OF PEER REVIEWED JOURNAL ARTICLES/ABSTRACTS(OTHER THAN PUBLISHED)': {
    matchingObject: candidateImportFields.CountOfPeerReviewedJournalArticlesAndOrAbstractsOtherThanPublished,
  },
  // END CountOfPeerReviewedJournalArticlesAndOrAbstractsOtherThanPublished //

  // -----------------------------

  // START CountOfPosterPresentation //
  'COUNT OF POSTER PRESENTATION': {
    matchingObject: candidateImportFields.CountOfPosterPresentation,
  },
  // END CountOfPosterPresentation //

  // -----------------------------

  // START CountOfScientificMonograph //
  'COUNT OF SCIENTIFIC MONOGRAPH': {
    matchingObject: candidateImportFields.CountOfScientificMonograph,
  },
  // END CountOfScientificMonograph //

  // -----------------------------

  // START CouplesMatch //
  'COUPLES MATCH': {
    matchingObject: candidateImportFields.CouplesMatch,
  },
  // END CouplesMatch //

  // -----------------------------

  // START CouplesPartnerName //
  'COUPLES - PARTNER NAME': {
    matchingObject: candidateImportFields.CouplesPartnerName,
  },
  // END CouplesPartnerName //

  // -----------------------------

  // START CouplesPartnerSpecialty //
  'COUPLES - PARTNER SPECIALTY': {
    matchingObject: candidateImportFields.CouplesPartnerSpecialty,
  },
  // END CouplesPartnerSpecialty //

  // -----------------------------

  // START CurrentAddress //
  'CURRENT ADDRESS': {
    matchingObject: candidateImportFields.CurrentAddress,
  },
  // END CurrentAddress //

  // -----------------------------

  // START CurrentResidency //
  CURRENTRESIDENCY: {
    matchingObject: candidateImportFields.CurrentResidency,
  },
  // END CurrentResidency //

  // -----------------------------

  // START CurrentVisaStatus //
  'CURRENT VISA STATUS': {
    matchingObject: candidateImportFields.CurrentVisaStatus,
  },
  // END CurrentVisaStatus //

  // -----------------------------

  // START CurrentWorkAuthorization //
  'CURRENT WORK AUTHORIZATION': {
    matchingObject: candidateImportFields.CurrentWorkAuthorization,
  },
  // END CurrentWorkAuthorization //

  // -----------------------------

  // START CUSTOM FIELDS
  CUSTOMFIELD1: {
    matchingObject: candidateImportFields.CustomField1,
  },
  CUSTOMFIELD01: {
    matchingObject: candidateImportFields.CustomField1,
  },
  CUSTOMFIELD2: {
    matchingObject: candidateImportFields.CustomField2,
  },
  CUSTOMFIELD02: {
    matchingObject: candidateImportFields.CustomField2,
  },
  CUSTOMFIELD3: {
    matchingObject: candidateImportFields.CustomField3,
  },
  CUSTOMFIELD03: {
    matchingObject: candidateImportFields.CustomField3,
  },
  CUSTOMFIELD4: {
    matchingObject: candidateImportFields.CustomField4,
  },
  CUSTOMFIELD04: {
    matchingObject: candidateImportFields.CustomField4,
  },
  CUSTOMFIELD5: {
    matchingObject: candidateImportFields.CustomField5,
  },
  CUSTOMFIELD05: {
    matchingObject: candidateImportFields.CustomField5,
  },
  CUSTOMFIELD6: {
    matchingObject: candidateImportFields.CustomField6,
  },
  CUSTOMFIELD06: {
    matchingObject: candidateImportFields.CustomField6,
  },
  CUSTOMFIELD7: {
    matchingObject: candidateImportFields.CustomField7,
  },
  CUSTOMFIELD07: {
    matchingObject: candidateImportFields.CustomField7,
  },
  CUSTOMFIELD8: {
    matchingObject: candidateImportFields.CustomField8,
  },
  CUSTOMFIELD08: {
    matchingObject: candidateImportFields.CustomField8,
  },
  CUSTOMFIELD9: {
    matchingObject: candidateImportFields.CustomField9,
  },
  CUSTOMFIELD09: {
    matchingObject: candidateImportFields.CustomField9,
  },
  CUSTOMFIELD10: {
    matchingObject: candidateImportFields.CustomField10,
  },
  CUSTOMFIELD11: {
    matchingObject: candidateImportFields.CustomField11,
  },
  CUSTOMFIELD12: {
    matchingObject: candidateImportFields.CustomField12,
  },
  CUSTOMFIELD13: {
    matchingObject: candidateImportFields.CustomField13,
  },
  CUSTOMFIELD14: {
    matchingObject: candidateImportFields.CustomField14,
  },
  CUSTOMFIELD15: {
    matchingObject: candidateImportFields.CustomField15,
  },
  CUSTOMFIELD16: {
    matchingObject: candidateImportFields.CustomField16,
  },
  CUSTOMFIELD17: {
    matchingObject: candidateImportFields.CustomField17,
  },
  CUSTOMFIELD18: {
    matchingObject: candidateImportFields.CustomField18,
  },
  CUSTOMFIELD19: {
    matchingObject: candidateImportFields.CustomField19,
  },
  CUSTOMFIELD20: {
    matchingObject: candidateImportFields.CustomField20,
  },
  CUSTOMFIELD21: {
    matchingObject: candidateImportFields.CustomField21,
  },
  CUSTOMFIELD22: {
    matchingObject: candidateImportFields.CustomField22,
  },
  CUSTOMFIELD23: {
    matchingObject: candidateImportFields.CustomField23,
  },
  CUSTOMFIELD24: {
    matchingObject: candidateImportFields.CustomField24,
  },
  CUSTOMFIELD25: {
    matchingObject: candidateImportFields.CustomField25,
  },
  CUSTOMFIELD26: {
    matchingObject: candidateImportFields.CustomField26,
  },
  CUSTOMFIELD27: {
    matchingObject: candidateImportFields.CustomField27,
  },
  CUSTOMFIELD28: {
    matchingObject: candidateImportFields.CustomField28,
  },
  CUSTOMFIELD29: {
    matchingObject: candidateImportFields.CustomField29,
  },
  CUSTOMFIELD30: {
    matchingObject: candidateImportFields.CustomField30,
  },
  CUSTOMFIELD31: {
    matchingObject: candidateImportFields.CustomField31,
  },
  CUSTOMFIELD32: {
    matchingObject: candidateImportFields.CustomField32,
  },
  CUSTOMFIELD33: {
    matchingObject: candidateImportFields.CustomField33,
  },
  CUSTOMFIELD34: {
    matchingObject: candidateImportFields.CustomField34,
  },
  CUSTOMFIELD35: {
    matchingObject: candidateImportFields.CustomField35,
  },
  CUSTOMFIELD36: {
    matchingObject: candidateImportFields.CustomField36,
  },
  CUSTOMFIELD37: {
    matchingObject: candidateImportFields.CustomField37,
  },
  CUSTOMFIELD38: {
    matchingObject: candidateImportFields.CustomField38,
  },
  CUSTOMFIELD39: {
    matchingObject: candidateImportFields.CustomField39,
  },
  CUSTOMFIELD40: {
    matchingObject: candidateImportFields.CustomField40,
  },
  CUSTOMFIELD41: {
    matchingObject: candidateImportFields.CustomField41,
  },
  CUSTOMFIELD42: {
    matchingObject: candidateImportFields.CustomField42,
  },
  CUSTOMFIELD43: {
    matchingObject: candidateImportFields.CustomField43,
  },
  CUSTOMFIELD44: {
    matchingObject: candidateImportFields.CustomField44,
  },
  CUSTOMFIELD45: {
    matchingObject: candidateImportFields.CustomField45,
  },
  CUSTOMFIELD46: {
    matchingObject: candidateImportFields.CustomField46,
  },
  CUSTOMFIELD47: {
    matchingObject: candidateImportFields.CustomField47,
  },
  CUSTOMFIELD48: {
    matchingObject: candidateImportFields.CustomField48,
  },
  CUSTOMFIELD49: {
    matchingObject: candidateImportFields.CustomField49,
  },
  CUSTOMFIELD50: {
    matchingObject: candidateImportFields.CustomField50,
  },
  // END CUSTOM FIELDS

  // -----------------------------

  // START DEAExpirationDate //
  'DEA EXPIRATION DATE': {
    matchingObject: candidateImportFields.DEAExpirationDate,
  },
  // END DEAExpirationDate //

  // -----------------------------

  // START DEARegistrationNumber //
  'DEA REGISTRATION NUMBER': {
    matchingObject: candidateImportFields.DEARegistrationNumber,
  },
  // END DEARegistrationNumber //

  // -----------------------------

  // START DateOfBirth //
  'DATE OF BIRTH': {
    matchingObject: candidateImportFields.DateOfBirth,
  },
  // END DateOfBirth //

  // -----------------------------

  // START DivisionPreference //
  'DIVISION PREFERENCE': {
    matchingObject: candidateImportFields.DivisionPreference,
  },
  DIVISION_PREFERENCE: {
    matchingObject: candidateImportFields.DivisionPreference,
  },
  // END DivisionPreference //

  // -----------------------------

  // START DivisionPreferenceEssay //
  DIVISION_PREFERENCE_ESSAY: {
    matchingObject: candidateImportFields.DivisionPreferenceEssay,
  },
  // END DivisionPreferenceEssay //

  // -----------------------------

  // START ECFMGCertificationDate //
  'ECFMG CERTIFICATION DATE': {
    matchingObject: candidateImportFields.ECFMGCertificationDate,
  },
  // END ECFMGCertificationDate //

  // -----------------------------

  // START ECFMGCertified //
  'ECFMG CERTIFIED': {
    matchingObject: candidateImportFields.ECFMGCertified,
  },
  // END ECFMGCertified //

  // -----------------------------

  // START Email //
  'E-MAIL': {
    matchingObject: candidateImportFields.Email,
  },
  EMAIL: {
    matchingObject: candidateImportFields.Email,
  },
  // END Email //

  // -----------------------------

  // START ExpectedVisaStatus //
  'EXPECTED VISA STATUS': {
    matchingObject: candidateImportFields.ExpectedVisaStatus,
  },
  // END ExpectedVisaStatus //

  // -----------------------------

  // START ExplanationOfOtherServiceObligation //
  'EXPLANATION OF OTHER SERVICE OBLIGATION': {
    matchingObject: candidateImportFields.ExplanationOfOtherServiceObligation,
  },
  // END ExplanationOfOtherServiceObligation //

  // -----------------------------

  // START ExplanationOfWhyMedicalEducationorTrainingInterrupted //
  'EXPLANATION OF WHY MEDICAL EDUCATION OR TRAINING INTERRUPTED': {
    matchingObject: candidateImportFields.ExplanationOfWhyMedicalEducationorTrainingInterrupted,
  },
  // END ExplanationOfWhyMedicalEducationorTrainingInterrupted //

  // -----------------------------

  // START Fax //
  'FAX #': {
    matchingObject: candidateImportFields.Fax,
  },
  // END Fax //

  // -----------------------------

  // START FelonyConvictionReason //
  'FELONY CONVICTION REASON': {
    matchingObject: candidateImportFields.FelonyConvictionReason,
  },
  // END FelonyConvictionReason //

  // -----------------------------

  // START FirstDelivery //
  'FIRST DELIVERY': {
    matchingObject: candidateImportFields.FirstDelivery,
  },
  // END FirstDelivery //

  // -----------------------------

  // START FirstName //
  'FIRST NAME': {
    matchingObject: candidateImportFields.FirstName,
  },
  FIRSTNAME: {
    matchingObject: candidateImportFields.FirstName,
  },
  // END FirstName //

  // -----------------------------

  // START Gender //
  GENDER: {
    matchingObject: candidateImportFields.Gender,
  },
  // END Gender //

  // -----------------------------

  // START GoldHumanismHonorSociety //
  'GOLD HUMANISM HONOR SOCIETY (YES/NO)': {
    matchingObject: candidateImportFields.GoldHumanismHonorSociety,
  },
  GOLDHUMANISMSTATUS: {
    matchingObject: candidateImportFields.GoldHumanismHonorSociety,
  },
  'GOLD HUMANISM STATUS': {
    matchingObject: candidateImportFields.GoldHumanismHonorSociety,
  },
  // END GoldHumanismHonorSociety //

  // -----------------------------

  // START HigherEducationAttendanceDates //
  'HIGHER EDUCATION ATTENDANCE DATES': {
    matchingObject: candidateImportFields.HigherEducationAttendanceDates,
  },
  // END HigherEducationAttendanceDates //

  // -----------------------------

  // START HigherEducationAttendanceDates_1 //
  'HIGHER EDUCATION ATTENDANCE DATES_1': {
    matchingObject: candidateImportFields.HigherEducationAttendanceDates_1,
  },
  'HIGHER EDUCATION ATTENDANCE DATES_2': {
    matchingObject: candidateImportFields.HigherEducationAttendanceDates_1,
  },
  // END HigherEducationAttendanceDates_1 //

  // -----------------------------

  // START HigherEducationAttendanceDates_3 //
  'HIGHER EDUCATION ATTENDANCE DATES_3': {
    matchingObject: candidateImportFields.HigherEducationAttendanceDates_3,
  },
  // END HigherEducationAttendanceDates_3 //

  // -----------------------------

  // START HigherEducationDegree //
  'HIGHER EDUCATION DEGREE': {
    matchingObject: candidateImportFields.HigherEducationDegree,
  },
  // END HigherEducationDegree //

  // -----------------------------

  // START HigherEducationDegreeDateEarned //
  'HIGHER EDUCATION DEGREE DATE EARNED': {
    matchingObject: candidateImportFields.HigherEducationDegreeDateEarned,
  },
  // END HigherEducationDegreeDateEarned //

  // -----------------------------

  // START HigherEducationDegreeDateEarned_1 //
  'HIGHER EDUCATION DEGREE DATE EARNED_1': {
    matchingObject: candidateImportFields.HigherEducationDegreeDateEarned_1,
  },
  // END HigherEducationDegreeDateEarned_1 //

  // -----------------------------

  // START HigherEducationDegreeDateEarned_2 //
  'HIGHER EDUCATION DEGREE DATE EARNED_2': {
    matchingObject: candidateImportFields.HigherEducationDegreeDateEarned_2,
  },
  // END HigherEducationDegreeDateEarned_2 //

  // -----------------------------

  // START HigherEducationDegreeDateEarned_3 //
  'HIGHER EDUCATION DEGREE DATE EARNED_3': {
    matchingObject: candidateImportFields.HigherEducationDegreeDateEarned_3,
  },
  // END HigherEducationDegreeDateEarned_3 //

  // -----------------------------

  // START HigherEducationDegreeEarned //
  'HIGHER EDUCATION DEGREE EARNED': {
    matchingObject: candidateImportFields.HigherEducationDegreeEarned,
  },
  // END HigherEducationDegreeEarned //

  // -----------------------------

  // START HigherEducationDegreeEarned_1 //
  'HIGHER EDUCATION DEGREE EARNED_1': {
    matchingObject: candidateImportFields.HigherEducationDegreeEarned_1,
  },
  // END HigherEducationDegreeEarned_1 //

  // -----------------------------

  // START HigherEducationDegreeEarned_2 //
  'HIGHER EDUCATION DEGREE EARNED_2': {
    matchingObject: candidateImportFields.HigherEducationDegreeEarned_2,
  },
  // END HigherEducationDegreeEarned_2 //

  // -----------------------------

  // START HigherEducationDegreeEarned_3 //
  'HIGHER EDUCATION DEGREE EARNED_3': {
    matchingObject: candidateImportFields.HigherEducationDegreeEarned_3,
  },
  // END HigherEducationDegreeEarned_3 //

  // -----------------------------

  // START HigherEducationDegreeEarned_4 //
  'HIGHER EDUCATION DEGREE EARNED_4': {
    matchingObject: candidateImportFields.HigherEducationDegreeEarned_4,
  },
  // END HigherEducationDegreeEarned_4 //

  // -----------------------------

  // START HigherEducationDegree_1 //
  'HIGHER EDUCATION DEGREE_1': {
    matchingObject: candidateImportFields.HigherEducationDegree_1,
  },
  // END HigherEducationDegree_1 //

  // -----------------------------

  // START HigherEducationDegree_2 //
  'HIGHER EDUCATION DEGREE_2': {
    matchingObject: candidateImportFields.HigherEducationDegree_2,
  },
  // END HigherEducationDegree_2 //

  // -----------------------------

  // START HigherEducationDegree_3 //
  'HIGHER EDUCATION DEGREE_3': {
    matchingObject: candidateImportFields.HigherEducationDegree_3,
  },
  // END HigherEducationDegree_3 //

  // -----------------------------

  // START HigherEducationInstitution //
  'HIGHER EDUCATION INSTITUTION': {
    matchingObject: candidateImportFields.HigherEducationInstitution,
  },
  // END HigherEducationInstitution //

  // -----------------------------

  // START HigherEducationInstitution_1 //
  'HIGHER EDUCATION INSTITUTION_1': {
    matchingObject: candidateImportFields.HigherEducationInstitution_1,
  },
  // END HigherEducationInstitution_1 //

  // -----------------------------

  // START HigherEducationInstitution_2 //
  'HIGHER EDUCATION INSTITUTION_2': {
    matchingObject: candidateImportFields.HigherEducationInstitution_2,
  },
  // END HigherEducationInstitution_2 //

  // -----------------------------

  // START HigherEducationInstitution_3 //
  'HIGHER EDUCATION INSTITUTION_3': {
    matchingObject: candidateImportFields.HigherEducationInstitution_3,
  },
  // END HigherEducationInstitution_3 //

  // -----------------------------

  // START HigherEducationInstitution_4 //
  'HIGHER EDUCATION INSTITUTION_4': {
    matchingObject: candidateImportFields.HigherEducationInstitution_4,
  },
  // END HigherEducationInstitution_4 //

  // -----------------------------

  // START HigherEducationLocation //
  'HIGHER EDUCATION LOCATION': {
    matchingObject: candidateImportFields.HigherEducationLocation,
  },
  // END HigherEducationLocation //

  // -----------------------------

  // START HigherEducationLocation_1 //
  'HIGHER EDUCATION LOCATION_1': {
    matchingObject: candidateImportFields.HigherEducationLocation_1,
  },
  // END HigherEducationLocation_1 //

  // -----------------------------

  // START HigherEducationLocation_2 //
  'HIGHER EDUCATION LOCATION_2': {
    matchingObject: candidateImportFields.HigherEducationLocation_2,
  },
  // END HigherEducationLocation_2 //

  // -----------------------------

  // START HigherEducationLocation_3 //
  'HIGHER EDUCATION LOCATION_3': {
    matchingObject: candidateImportFields.HigherEducationLocation_3,
  },
  // END HigherEducationLocation_3 //

  // -----------------------------

  // START HigherEducationMajor //
  'HIGHER EDUCATION MAJOR': {
    matchingObject: candidateImportFields.HigherEducationMajor,
  },
  // END HigherEducationMajor //

  // -----------------------------

  // START HigherEducationMajor_1 //
  'HIGHER EDUCATION MAJOR_1': {
    matchingObject: candidateImportFields.HigherEducationMajor_1,
  },
  // END HigherEducationMajor_1 //

  // -----------------------------

  // START HigherEducationMajor_2 //
  'HIGHER EDUCATION MAJOR_2': {
    matchingObject: candidateImportFields.HigherEducationMajor_2,
  },
  // END HigherEducationMajor_2 //

  // -----------------------------

  // START HigherEducationMajor_3 //
  'HIGHER EDUCATION MAJOR_3': {
    matchingObject: candidateImportFields.HigherEducationMajor_3,
  },
  // END HigherEducationMajor_3 //

  // -----------------------------

  // START HigherEducationMajor_4 //
  'HIGHER EDUCATION MAJOR_4': {
    matchingObject: candidateImportFields.HigherEducationMajor_4,
  },
  // END HigherEducationMajor_4 //

  // -----------------------------

  // START HobbiesAndInterests //
  'HOBBIES AND INTERESTS': {
    matchingObject: candidateImportFields.HobbiesAndInterests,
  },
  // END HobbiesAndInterests //

  // -----------------------------

  // START ImpactfulExperienceEssay //
  'IMPACTFUL EXPERIENCE ESSAY': {
    matchingObject: candidateImportFields.ImpactfulExperienceEssay,
  },
  // END ImpactfulExperienceEssay //

  // -----------------------------

  // START LanguageFluency //
  'LANGUAGE FLUENCY': {
    matchingObject: candidateImportFields.LanguageFluency,
  },
  // END LanguageFluency //

  // -----------------------------

  // START LastName //
  'LAST NAME': {
    matchingObject: candidateImportFields.LastName,
  },
  LASTNAME: {
    matchingObject: candidateImportFields.LastName,
  },
  // END LastName //

  // -----------------------------

  // START LastResidency //
  'LAST RESIDENCY': {
    matchingObject: candidateImportFields.LastResidency,
  },
  LASTRESIDENCY: {
    matchingObject: candidateImportFields.LastResidency,
  },
  // END LastResidency //

  // -----------------------------

  // START LastReviewed //
  'LAST REVIEWED': {
    matchingObject: candidateImportFields.LastReviewed,
  },
  // END LastReviewed //

  // -----------------------------

  // START LastUpdated //
  'LAST UPDATED': {
    matchingObject: candidateImportFields.LastUpdated,
  },
  // END LastUpdated //

  // -----------------------------

  // START LimitingFactors //
  'LIMITING FACTORS': {
    matchingObject: candidateImportFields.LimitingFactors,
  },
  // END LimitingFactors //

  // -----------------------------

  // START MalpracticeCasesPending //
  'MALPRACTICE CASES PENDING': {
    matchingObject: candidateImportFields.MalpracticeCasesPending,
  },
  // END MalpracticeCasesPending //

  // -----------------------------

  // START MalpracticeCasesPendingReason //
  'MALPRACTICE CASES PENDING REASON': {
    matchingObject: candidateImportFields.MalpracticeCasesPendingReason,
  },
  // END MalpracticeCasesPendingReason //

  // -----------------------------

  // START MedicalDegree //
  'MEDICAL DEGREE': {
    matchingObject: candidateImportFields.MedicalDegree,
  },
  // END MedicalDegree //

  // -----------------------------

  // START MedicalDegreeExpectedorEarned //
  'MEDICAL DEGREE EXPECTED OR EARNED': {
    matchingObject: candidateImportFields.MedicalDegreeExpectedorEarned,
  },
  // END MedicalDegreeExpectedorEarned //

  // -----------------------------

  // START MedicalDegree_1 //
  'MEDICAL DEGREE_1': {
    matchingObject: candidateImportFields.MedicalDegree_1,
  },
  // END MedicalDegree_1 //

  // -----------------------------

  // START MedicalEducationorTrainingInterrupted //
  'MEDICAL EDUCATION OR TRAINING INTERRUPTED': {
    matchingObject: candidateImportFields.MedicalEducationorTrainingInterrupted,
  },
  // END MedicalEducationorTrainingInterrupted //

  // -----------------------------

  // START MedicalLicenseNumber //
  'MEDICAL LICENSE NUMBER': {
    matchingObject: candidateImportFields.MedicalLicenseNumber,
  },
  // END MedicalLicenseNumber //

  // -----------------------------

  // START MedicalLicenseNumber_1 //
  'MEDICAL LICENSE NUMBER_1': {
    matchingObject: candidateImportFields.MedicalLicenseNumber_1,
  },
  // END MedicalLicenseNumber_1 //

  // -----------------------------

  // START MedicalLicenseState //
  'MEDICAL LICENSE STATE': {
    matchingObject: candidateImportFields.MedicalLicenseState,
  },
  // END MedicalLicenseState //

  // -----------------------------

  // START MedicalLicenseState_1 //
  'MEDICAL LICENSE STATE_1': {
    matchingObject: candidateImportFields.MedicalLicenseState_1,
  },
  // END MedicalLicenseState_1 //

  // -----------------------------

  // START MedicalLicenseType //
  'MEDICAL LICENSE TYPE': {
    matchingObject: candidateImportFields.MedicalLicenseType,
  },
  // END MedicalLicenseType //

  // -----------------------------

  // START MedicalLicenseType_1 //
  'MEDICAL LICENSE TYPE_1': {
    matchingObject: candidateImportFields.MedicalLicenseType_1,
  },
  // END MedicalLicenseType_1 //

  // -----------------------------

  // START MedicalLicensesExpirationDate //
  'MEDICAL LICENSES EXPIRATION DATE': {
    matchingObject: candidateImportFields.MedicalLicensesExpirationDate,
  },
  // END MedicalLicensesExpirationDate //

  // -----------------------------

  // START MedicalLicensesExpirationDate_1 //
  'MEDICAL LICENSES EXPIRATION DATE_1': {
    matchingObject: candidateImportFields.MedicalLicensesExpirationDate_1,
  },
  // END MedicalLicensesExpirationDate_1 //

  // -----------------------------

  // START MedicalLicensureProblem //
  'MEDICAL LICENSURE PROBLEM': {
    matchingObject: candidateImportFields.MedicalLicensureProblem,
  },
  // END MedicalLicensureProblem //

  // -----------------------------

  // START MedicalLicensureProblemReason //
  'MEDICAL LICENSURE PROBLEM REASON': {
    matchingObject: candidateImportFields.MedicalLicensureProblemReason,
  },
  // END MedicalLicensureProblemReason //

  // -----------------------------

  // START MedicalSchoolAttendanceDates //
  'MEDICAL SCHOOL ATTENDANCE DATES': {
    matchingObject: candidateImportFields.MedicalSchoolAttendanceDates,
  },
  // END MedicalSchoolAttendanceDates //

  // -----------------------------

  // START MedicalSchoolAttendanceDates_1 //
  'MEDICAL SCHOOL ATTENDANCE DATES_1': {
    matchingObject: candidateImportFields.MedicalSchoolAttendanceDates_1,
  },
  // END MedicalSchoolAttendanceDates_1 //

  // -----------------------------

  // START MedicalSchoolClinicalCampus //
  'MEDICAL SCHOOL CLINICAL CAMPUS': {
    matchingObject: candidateImportFields.MedicalSchoolClinicalCampus,
  },
  'UNDERGRADUATE EDUCATIONS': {
    matchingObject: candidateImportFields.MedicalSchoolClinicalCampus,
  },
  // END MedicalSchoolClinicalCampus //

  // -----------------------------

  // START MedicalSchoolCountry //
  'MEDICAL SCHOOL COUNTRY': {
    matchingObject: candidateImportFields.MedicalSchoolCountry,
  },
  // END MedicalSchoolCountry //

  // -----------------------------

  // START MedicalSchoolDegreeDateOfGraduation //
  'MEDICAL SCHOOL DEGREE DATE OF GRADUATION': {
    matchingObject: candidateImportFields.MedicalSchoolDegreeDateOfGraduation,
  },
  'YEAR OF GRADUATION': {
    matchingObject: candidateImportFields.MedicalSchoolDegreeDateOfGraduation,
  },
  YEAROFGRADUATION: {
    matchingObject: candidateImportFields.MedicalSchoolDegreeDateOfGraduation,
  },
  // END MedicalSchoolDegreeDateOfGraduation //

  // -----------------------------

  // START MedicalSchoolOfGraduation //
  MEDSCHOOL: {
    matchingObject: candidateImportFields.MedicalSchoolOfGraduation,
  },
  'MEDICAL SCHOOL OF GRADUATION': {
    matchingObject: candidateImportFields.MedicalSchoolOfGraduation,
  },
  'MEDICAL SCHOOL': {
    matchingObject: candidateImportFields.MedicalSchoolOfGraduation,
  },
  // END MedicalSchoolOfGraduation //

  // -----------------------------

  // START MedicalSchoolStateOrProvince //
  'MEDICAL SCHOOL STATE/PROVINCE': {
    matchingObject: candidateImportFields.MedicalSchoolStateOrProvince,
  },
  // END MedicalSchoolStateOrProvince //

  // -----------------------------

  // START MedicalSchoolType //
  'MEDICAL SCHOOL TYPE': {
    matchingObject: candidateImportFields.MedicalSchoolType,
  },
  // END MedicalSchoolType //

  // -----------------------------

  // START MedicalSchoolofGraduation_1 //
  'MEDICAL SCHOOL OF GRADUATION_1': {
    matchingObject: candidateImportFields.MedicalSchoolofGraduation_1,
  },
  // END MedicalSchoolofGraduation_1 //

  // -----------------------------

  // START MedicalTrainingProgram //
  'MEDICAL TRAINING PROGRAM': {
    matchingObject: candidateImportFields.MedicalTrainingProgram,
  },
  // END MedicalTrainingProgram //

  // -----------------------------

  // START MedicalTrainingStartDate //
  'MEDICAL TRAINING START DATE': {
    matchingObject: candidateImportFields.MedicalTrainingStartDate,
  },
  // END MedicalTrainingStartDate //

  // -----------------------------

  // START MedicalTrainingType //
  'MEDICAL TRAINING TYPE': {
    matchingObject: candidateImportFields.MedicalTrainingType,
  },
  // END MedicalTrainingType //

  // -----------------------------

  // START MembershipinHonoraryorProfessionalSocieties //
  'MEMBERSHIP IN HONORARY OR PROFESSIONAL SOCIETIES': {
    matchingObject: candidateImportFields.MembershipinHonoraryorProfessionalSocieties,
  },
  // END MembershipinHonoraryorProfessionalSocieties //

  // -----------------------------

  // START MiddleName //
  'MIDDLE NAME': {
    matchingObject: candidateImportFields.MiddleName,
  },
  // END MiddleName //

  // -----------------------------

  // START MilitaryBranch //
  'MILITARY BRANCH': {
    matchingObject: candidateImportFields.MilitaryBranch,
  },
  // END MilitaryBranch //

  // -----------------------------

  // START MilitaryServiceObligation //
  'MILITARY SERVICE OBLIGATION': {
    matchingObject: candidateImportFields.MilitaryServiceObligation,
  },
  // END MilitaryServiceObligation //

  // -----------------------------

  // START MilitaryServiceYearsRemaining //
  'MILITARY SERVICE YEARS REMAINING': {
    matchingObject: candidateImportFields.MilitaryServiceYearsRemaining,
  },
  // END MilitaryServiceYearsRemaining //

  // -----------------------------

  // START MisdemeanorConviction //
  'MISDEMEANOR CONVICTION': {
    matchingObject: candidateImportFields.MisdemeanorConviction,
  },
  // END MisdemeanorConviction //

  // -----------------------------

  // START MisdemeanorConvictionReason //
  'MISDEMEANOR CONVICTION REASON': {
    matchingObject: candidateImportFields.MisdemeanorConvictionReason,
  },
  // END MisdemeanorConvictionReason //

  // -----------------------------

  // START MostRecentMedicalTrainingProgram //
  'MOST RECENT MEDICAL TRAINING PROGRAM': {
    matchingObject: candidateImportFields.MostRecentMedicalTrainingProgram,
  },
  // END MostRecentMedicalTrainingProgram //

  // -----------------------------

  // START NBOMEID //
  'NBOME ID': {
    matchingObject: candidateImportFields.NBOMEID,
  },
  // END NBOMEID //

  // -----------------------------

  // START NOTECONTENT //
  NOTECONTENT: {
    matchingObject: candidateImportFields.NoteContent,
  },
  NOTE_CONTENT: {
    matchingObject: candidateImportFields.NoteContent,
  },
  'NOTE CONTENT': {
    matchingObject: candidateImportFields.NoteContent,
  },
  NOTE: {
    matchingObject: candidateImportFields.NoteContent,
  },
  NOTES: {
    matchingObject: candidateImportFields.NoteContent,
  },
  // END NOTECONTENT //

  // -----------------------------

  // START NRMPID //
  'NRMP ID': {
    matchingObject: candidateImportFields.NRMPID,
  },
  // END NRMPID //

  // -----------------------------

  // START OtherServiceObligation //
  'OTHER SERVICE OBLIGATION': {
    matchingObject: candidateImportFields.OtherServiceObligation,
  },
  // END OtherServiceObligation //

  // -----------------------------

  // START PALS //
  PALS: {
    matchingObject: candidateImportFields.PALS,
  },
  // END PALS //

  // -----------------------------

  // START PALSExpirationDate //
  'PALS EXPIRATION DATE': {
    matchingObject: candidateImportFields.PALSExpirationDate,
  },
  // END PALSExpirationDate //

  // -----------------------------

  // START PGY2Interest //
  'PGY 2 INTEREST': {
    matchingObject: candidateImportFields.PGY2Interest,
  },
  // END PGY2Interest //

  // -----------------------------

  // START Pager //
  'PAGER #': {
    matchingObject: candidateImportFields.Pager,
  },
  // END Pager //

  // -----------------------------

  // START ParticipatingasaCoupleinNRMP //
  'PARTICIPATING AS A COUPLE IN NRMP': {
    matchingObject: candidateImportFields.ParticipatingasaCoupleinNRMP,
  },
  // END ParticipatingasaCoupleinNRMP //

  // -----------------------------

  // START PermanentAddress //
  'PERMANENT ADDRESS': {
    matchingObject: candidateImportFields.PermanentAddress,
  },
  // END PermanentAddress //

  // -----------------------------

  // START PermanentAddress1 //
  'PERMANENT ADDRESS 1': {
    matchingObject: candidateImportFields.PermanentAddress1,
  },
  // END PermanentAddress1 //

  // -----------------------------

  // START PermanentAddress2 //
  'PERMANENT ADDRESS 2': {
    matchingObject: candidateImportFields.PermanentAddress2,
  },
  // END PermanentAddress2 //

  // -----------------------------

  // START PermanentCity //
  'PERMANENT CITY': {
    matchingObject: candidateImportFields.PermanentCity,
  },
  // END PermanentCity //

  // -----------------------------

  // START PermanentCountry //
  'PERMANENT COUNTRY': {
    matchingObject: candidateImportFields.PermanentCountry,
  },
  // END PermanentCountry //

  // -----------------------------

  // START PermanentPhone //
  'PERMANENT PHONE': {
    matchingObject: candidateImportFields.PermanentPhone,
  },
  'HOME PHONE': {
    matchingObject: candidateImportFields.PermanentPhone,
  },
  // END PermanentPhone //

  // -----------------------------

  // START PermanentState //
  'PERMANENT STATE': {
    matchingObject: candidateImportFields.PermanentState,
  },
  // END PermanentState //

  // -----------------------------

  // START PermanentZip //
  'PERMANENT ZIP': {
    matchingObject: candidateImportFields.PermanentZip,
  },
  // END PermanentZip //

  // -----------------------------

  // START PlanstoParticipateinNRMPMatch //
  'PLANS TO PARTICIPATE IN NRMP MATCH': {
    matchingObject: candidateImportFields.PlanstoParticipateinNRMPMatch,
  },
  // END PlanstoParticipateinNRMPMatch //

  // -----------------------------

  // START PreferredName //
  'PREFERRED NAME': {
    matchingObject: candidateImportFields.PreferredName,
  },
  // END PreferredName //

  // -----------------------------

  // START PreferredPhone //
  'PREFERRED PHONE': {
    matchingObject: candidateImportFields.PreferredPhone,
  },
  PHONEHOME: {
    matchingObject: candidateImportFields.PreferredPhone,
  },
  // END PreferredPhone //

  // -----------------------------

  // START PreviousLastName //
  'PREVIOUS LAST NAME': {
    matchingObject: candidateImportFields.PreviousLastName,
  },
  // END PreviousLastName //

  // -----------------------------

  // START ProfessionalExperienceMeaningfulExperienceEssay //
  'PROFESSIONAL EXPERIENCE MEANINGFUL EXPERIENCE ESSAY': {
    matchingObject: candidateImportFields.ProfessionalExperienceMeaningfulExperienceEssay,
  },
  // END ProfessionalExperienceMeaningfulExperienceEssay //

  // -----------------------------

  // START ProfessionalExperienceMeaningfulExperienceEssay_1 //
  'PROFESSIONAL EXPERIENCE MEANINGFUL EXPERIENCE ESSAY_1': {
    matchingObject: candidateImportFields.ProfessionalExperienceMeaningfulExperienceEssay_1,
  },
  // END ProfessionalExperienceMeaningfulExperienceEssay_1 //

  // -----------------------------

  // START ProfessionalExperienceMeaningfulExperienceEssay_2 //
  'PROFESSIONAL EXPERIENCE MEANINGFUL EXPERIENCE ESSAY_2': {
    matchingObject: candidateImportFields.ProfessionalExperienceMeaningfulExperienceEssay_2,
  },
  // END ProfessionalExperienceMeaningfulExperienceEssay_2 //

  // -----------------------------

  // START ProfessionalExperienceMeaningfulExperienceEssay_3 //
  'PROFESSIONAL EXPERIENCE MEANINGFUL EXPERIENCE ESSAY_3': {
    matchingObject: candidateImportFields.ProfessionalExperienceMeaningfulExperienceEssay_3,
  },
  // END ProfessionalExperienceMeaningfulExperienceEssay_3 //

  // -----------------------------

  // START ProfessionalExperienceMeaningfulExperienceEssay_4 //
  'PROFESSIONAL EXPERIENCE MEANINGFUL EXPERIENCE ESSAY_4': {
    matchingObject: candidateImportFields.ProfessionalExperienceMeaningfulExperienceEssay_4,
  },
  // END ProfessionalExperienceMeaningfulExperienceEssay_4 //

  // -----------------------------

  // START ProfessionalExperienceMeaningfulExperienceEssay_5 //
  'PROFESSIONAL EXPERIENCE MEANINGFUL EXPERIENCE ESSAY_5': {
    matchingObject: candidateImportFields.ProfessionalExperienceMeaningfulExperienceEssay_5,
  },
  // END ProfessionalExperienceMeaningfulExperienceEssay_5 //

  // -----------------------------

  // START ProfessionalExperienceMeaningfulExperienceEssay_6 //
  'PROFESSIONAL EXPERIENCE MEANINGFUL EXPERIENCE ESSAY_6': {
    matchingObject: candidateImportFields.ProfessionalExperienceMeaningfulExperienceEssay_6,
  },
  // END ProfessionalExperienceMeaningfulExperienceEssay_6 //

  // -----------------------------

  // START ProfessionalExperienceMeaningfulExperienceEssay_7 //
  'PROFESSIONAL EXPERIENCE MEANINGFUL EXPERIENCE ESSAY_7': {
    matchingObject: candidateImportFields.ProfessionalExperienceMeaningfulExperienceEssay_7,
  },
  // END ProfessionalExperienceMeaningfulExperienceEssay_7 //

  // -----------------------------

  // START ProfessionalExperienceMeaningfulExperienceEssay_8 //
  'PROFESSIONAL EXPERIENCE MEANINGFUL EXPERIENCE ESSAY_8': {
    matchingObject: candidateImportFields.ProfessionalExperienceMeaningfulExperienceEssay_8,
  },
  // END ProfessionalExperienceMeaningfulExperienceEssay_8 //

  // -----------------------------

  // START ProfessionalExperienceMeaningfulExperienceEssay_9 //
  'PROFESSIONAL EXPERIENCE MEANINGFUL EXPERIENCE ESSAY_9': {
    matchingObject: candidateImportFields.ProfessionalExperienceMeaningfulExperienceEssay_9,
  },
  // END ProfessionalExperienceMeaningfulExperienceEssay_9 //

  // -----------------------------

  // START ProfessionalExperienceOrganization //
  'PROFESSIONAL EXPERIENCE ORGANIZATION': {
    matchingObject: candidateImportFields.ProfessionalExperienceOrganization,
  },
  // END ProfessionalExperienceOrganization //

  // -----------------------------

  // START ProfessionalExperienceOrganization_1 //
  'PROFESSIONAL EXPERIENCE ORGANIZATION_1': {
    matchingObject: candidateImportFields.ProfessionalExperienceOrganization_1,
  },
  // END ProfessionalExperienceOrganization_1 //

  // -----------------------------

  // START ProfessionalExperienceOrganization_2 //
  'PROFESSIONAL EXPERIENCE ORGANIZATION_2': {
    matchingObject: candidateImportFields.ProfessionalExperienceOrganization_2,
  },
  // END ProfessionalExperienceOrganization_2 //

  // -----------------------------

  // START ProfessionalExperienceOrganization_3 //
  'PROFESSIONAL EXPERIENCE ORGANIZATION_3': {
    matchingObject: candidateImportFields.ProfessionalExperienceOrganization_3,
  },
  // END ProfessionalExperienceOrganization_3 //

  // -----------------------------

  // START ProfessionalExperienceOrganization_4 //
  'PROFESSIONAL EXPERIENCE ORGANIZATION_4': {
    matchingObject: candidateImportFields.ProfessionalExperienceOrganization_4,
  },
  // END ProfessionalExperienceOrganization_4 //

  // -----------------------------

  // START ProfessionalExperienceOrganization_5 //
  'PROFESSIONAL EXPERIENCE ORGANIZATION_5': {
    matchingObject: candidateImportFields.ProfessionalExperienceOrganization_5,
  },
  // END ProfessionalExperienceOrganization_5 //

  // -----------------------------

  // START ProfessionalExperienceOrganization_6 //
  'PROFESSIONAL EXPERIENCE ORGANIZATION_6': {
    matchingObject: candidateImportFields.ProfessionalExperienceOrganization_6,
  },
  // END ProfessionalExperienceOrganization_6 //

  // -----------------------------

  // START ProfessionalExperienceOrganization_7 //
  'PROFESSIONAL EXPERIENCE ORGANIZATION_7': {
    matchingObject: candidateImportFields.ProfessionalExperienceOrganization_7,
  },
  // END ProfessionalExperienceOrganization_7 //

  // -----------------------------

  // START ProfessionalExperienceOrganization_8 //
  'PROFESSIONAL EXPERIENCE ORGANIZATION_8': {
    matchingObject: candidateImportFields.ProfessionalExperienceOrganization_8,
  },
  // END ProfessionalExperienceOrganization_8 //

  // -----------------------------

  // START ProfessionalExperienceOrganization_9 //
  'PROFESSIONAL EXPERIENCE ORGANIZATION_9': {
    matchingObject: candidateImportFields.ProfessionalExperienceOrganization_9,
  },
  // END ProfessionalExperienceOrganization_9 //

  // -----------------------------

  // START ProgramSignal //
  'PROGRAM SIGNAL': {
    matchingObject: candidateImportFields.ProgramSignal,
  },
  PROGRAM_SIGNAL: {
    matchingObject: candidateImportFields.ProgramSignal,
  },
  // END ProgramSignal //

  // -----------------------------

  // START QualifyingExperience //
  'QUALIFYING EXPERIENCE': {
    matchingObject: candidateImportFields.QualifyingExperience,
  },
  // END QualifyingExperience //

  // -----------------------------

  // START SFMatchID //
  APPLICANTID: {
    matchingObject: candidateImportFields.SFMatchID,
  },
  'APPLICANT ID': {
    matchingObject: candidateImportFields.SFMatchID,
  },
  SFMATCHID: {
    matchingObject: candidateImportFields.SFMatchID,
  },
  'SFMATCH ID': {
    matchingObject: candidateImportFields.SFMatchID,
  },
  // END SFMatchID //

  // -----------------------------

  // START SelfIdentify //
  'SELF IDENTIFY': {
    matchingObject: candidateImportFields.SelfIdentify,
  },
  // END SelfIdentify //

  // -----------------------------

  // START SigmaSigmaPhi //
  'SIGMA SIGMA PHI (YES/NO)': {
    matchingObject: candidateImportFields.SigmaSigmaPhi,
  },
  'SIGMA SIGMA PHI': {
    matchingObject: candidateImportFields.SigmaSigmaPhi,
  },
  // END SigmaSigmaPhi //

  // -----------------------------

  // START TracksAppliedbyApplicant //
  'TRACKS APPLIED BY APPLICANT': {
    matchingObject: candidateImportFields.TracksAppliedbyApplicant,
  },
  'TRACKS APPLIED BY APPLICANT_1': {
    matchingObject: candidateImportFields.TracksAppliedbyApplicant,
  },
  // END TracksAppliedbyApplicant //

  // -----------------------------

  // START TracksConsideredbyProgram //
  'TRACKS CONSIDERED BY PROGRAM': {
    matchingObject: candidateImportFields.TracksConsideredbyProgram,
  },
  'TRACKS CONSIDERED BY PROGRAM_1': {
    matchingObject: candidateImportFields.TracksConsideredbyProgram,
  },
  // END TracksConsideredbyProgram //

  // -----------------------------

  // START USMLEComletionDate //
  STEPONEDATETAKEN: {
    matchingObject: candidateImportFields.USMLEComletionDate,
  },
  'USMLE STEP ONE DATE TAKEN': {
    matchingObject: candidateImportFields.USMLEComletionDate,
  },
  'USMLE STEP 1 - DATE TAKEN': {
    matchingObject: candidateImportFields.USMLEComletionDate,
  },
  // END USMLEComletionDate //

  // -----------------------------

  // START USMLEID //
  'USMLE ID': {
    matchingObject: candidateImportFields.USMLEID,
  },
  // END USMLEID //

  // -----------------------------

  // START USMLEStep1Score //
  STEPONETHREEDIGITSCORE: {
    matchingObject: candidateImportFields.USMLEStep1Score,
  },
  'USMLE STEP 1 SCORE': {
    matchingObject: candidateImportFields.USMLEStep1Score,
  },
  'USMLE STEP ONE TEST RESULTS': {
    matchingObject: candidateImportFields.USMLEStep1Score,
  },
  // END USMLEStep1Score //

  // -----------------------------

  // START USMLEStep1Status //
  'USMLE STEP 1 STATUS': {
    matchingObject: candidateImportFields.USMLEStep1Status,
  },
  'USMLE STEP 1 - RESULTS': {
    matchingObject: candidateImportFields.USMLEStep1Status,
  },
  // END USMLEStep1Status //

  // -----------------------------

  // START USMLEStep2CKScore //
  'USMLE STEP 2 CK SCORE': {
    matchingObject: candidateImportFields.USMLEStep2CKScore,
  },
  // END USMLEStep2CKScore //

  // -----------------------------

  // START USMLEStep2CSDateScore //
  STEPTWODATETAKEN: {
    matchingObject: candidateImportFields.USMLEStep2CSDateScore,
  },
  'USMLE STEP 2 - DATE TAKEN': {
    matchingObject: candidateImportFields.USMLEStep2CSDateScore,
  },
  'USMLE STEP TWO DATE TAKEN': {
    matchingObject: candidateImportFields.USMLEStep2CSDateScore,
  },
  // END USMLEStep2CSDateScore //

  // -----------------------------

  // START USMLEStep2CSScore //
  STEPTWOTHREEDIGITSCORE: {
    matchingObject: candidateImportFields.USMLEStep2CSScore,
  },
  'USMLE STEP 2 - SCORE': {
    matchingObject: candidateImportFields.USMLEStep2CSScore,
  },
  'USMLE STEP 2 CS SCORE': {
    matchingObject: candidateImportFields.USMLEStep2CSScore,
  },
  // END USMLEStep2CSScore //

  // -----------------------------

  // START USMLEStep3DateScore //
  STEPTHREEDATETAKEN: {
    matchingObject: candidateImportFields.USMLEStep3DateScore,
  },
  'USMLE STEP THREE DATE TAKEN': {
    matchingObject: candidateImportFields.USMLEStep3DateScore,
  },
  'USMLE STEP 3 - DATE TAKEN': {
    matchingObject: candidateImportFields.USMLEStep3DateScore,
  },
  // END USMLEStep3DateScore //

  // -----------------------------

  // START USMLEStep3Score //
  STEPTHREETHREEDIGITSCORE: {
    matchingObject: candidateImportFields.USMLEStep3Score,
  },
  'USMLE STEP THREE THREE DIGIT SCORE': {
    matchingObject: candidateImportFields.USMLEStep3Score,
  },
  'USMLE STEP 3 SCORE': {
    matchingObject: candidateImportFields.USMLEStep3Score,
  },
  'USMLE STEP 3 - SCORE': {
    matchingObject: candidateImportFields.USMLEStep3Score,
  },
  // END USMLEStep3Score //

  // -----------------------------

  // START USOrCanadianApplicant //
  'US OR CANADIAN APPLICANT': {
    matchingObject: candidateImportFields.USOrCanadianApplicant,
  },
  // END USOrCanadianApplicant //

  // -----------------------------

  // START VisaSponsorshipNeeded //
  'VISA SPONSORSHIP NEEDED': {
    matchingObject: candidateImportFields.VisaSponsorshipNeeded,
  },
  // END VisaSponsorshipNeeded //

  // -----------------------------

  // START VisaSponsorshipSought //
  'VISA SPONSORSHIP SOUGHT': {
    matchingObject: candidateImportFields.VisaSponsorshipSought,
  },
  // END VisaSponsorshipSought //

  // -----------------------------

  // START YearOfResidencyCompletion //
  YEAROFRESCOMPLETION: {
    matchingObject: candidateImportFields.YearOfResidencyCompletion,
  },
  'YEAR OF RESIDENCY COMPLETION': {
    matchingObject: candidateImportFields.YearOfResidencyCompletion,
  },
  // END YearOfResidencyCompletion //
};

// const sfMatchData = {
//   ApplicantId: {
//     databaseColumn: CandidateImportFields.SFMatchID,
//   },
//   LastName: {
//     databaseColumn: CandidateImportFields.LastName,
//   },
//   FirstName: {
//     databaseColumn: CandidateImportFields.FirstName,
//   },
//   Email: {
//     databaseColumn: CandidateImportFields.Email,
//   },
//   MedSchool: {
//     databaseColumn: CandidateImportFields.MedicalSchoolClinicalCampus,
//   },
//   YearOfGraduation: {
//     databaseColumn: CandidateImportFields.MedicalSchoolDegreeDateOfGraduation,
//   },
//   'AAMC ID': {
//     databaseColumn: CandidateImportFields.AAMCID,
//   },
//   CurrentResidency: {
//     databaseColumn: CandidateImportFields.CurrentResidency,
//   },
//   YearOfResCompletion: {
//     databaseColumn: CandidateImportFields.YearOfResidencyCompletion,
//   },
//   StepOneDateTaken: {
//     databaseColumn: CandidateImportFields.USMLEComletionDate,
//   },
//   StepOneThreeDigitScore: {
//     databaseColumn: CandidateImportFields.USMLEStep1Score,
//   },
//   StepTwoDateTaken: {
//     databaseColumn: CandidateImportFields.USMLEStep2CSDateScore,
//   },
//   StepTwoThreeDigitScore: {
//     databaseColumn: CandidateImportFields.USMLEStep2CSScore,
//   },
//   StepThreeDateTaken: {
//     databaseColumn: CandidateImportFields.USMLEStep2CSScore,
//   },
//   StepThreeThreeDigitScore: {
//     databaseColumn: CandidateImportFields.USMLEStep3Score,
//   },
//   'NBOME ID': {
//     databaseColumn: CandidateImportFields.NBOMEID,
//   },
//   ComlexStep1DateTaken: {
//     databaseColumn: CandidateImportFields.COMLEXUSALevel1DateScore,
//   },
//   'Comlex1 Score': {
//     databaseColumn: CandidateImportFields.COMLEXUSALevel1Score,
//   },
//   ComlexStep2DateTaken: {
//     databaseColumn: CandidateImportFields.COMLEXUSALevel2CEDateScore,
//   },
//   'Comlex2 Score': {
//     databaseColumn: CandidateImportFields.COMLEXUSALevel2CEScore,
//   },
//   ComlexStep3DateTaken: {
//     databaseColumn: CandidateImportFields.COMLEXUSALevel3DateScore,
//   },
//   'Comlex3 Score': {
//     databaseColumn: CandidateImportFields.COMLEXUSALevel3Score,
//   },
//   AoaStatus: {
//     databaseColumn: CandidateImportFields.AlphaOmegaAlpha,
//   },
//   GoldHumanismStatus: {
//     databaseColumn: CandidateImportFields.GoldHumanismHonorSociety,
//   },
//   Category: {
//     databaseColumn: CandidateImportFields.Catagory,
//   },
//   'First Delivery': {
//     databaseColumn: CandidateImportFields.FirstDelivery,
//   },
//   'Last Updated': {
//     databaseColumn: CandidateImportFields.LastUpdated,
//   },
//   'Last Reviewed': {
//     databaseColumn: CandidateImportFields.LastReviewed,
//   },
//   Address1: {
//     databaseColumn: CandidateImportFields.ContactAddress1,
//   },
//   Address2: {
//     databaseColumn: CandidateImportFields.ContactAddress2,
//   },
//   City: {
//     databaseColumn: CandidateImportFields.ContactCity,
//   },
//   State: {
//     databaseColumn: CandidateImportFields.ContactState,
//   },
//   Zip: {
//     databaseColumn: CandidateImportFields.ContactZip,
//   },
//   Country: {
//     databaseColumn: CandidateImportFields.ContactCountry,
//   },
//   PhoneHome: {
//     databaseColumn: CandidateImportFields.PreferredPhone,
//   },
//   PhoneCell: {
//     databaseColumn: CandidateImportFields.AlternatePhone,
//   },
// };

// const CandidateImportData = { ...AAMCIDCSVData, ...sfMatchData };
const candidateImportData = { ...AAMCIDCSVData, ...createDatabaseMatch() };

const CandidateStatusIcons = {
  markedForInvite: { icon: faEnvelopeCircleCheck, color: '#0067c2' },
  pendingInvite: { icon: faEnvelope, color: 'orange' },
  invited: { icon: faEnvelope, color: '#28a745' },
  waitlisted: { icon: faClipboardList, color: '#d98a30' },
  confirmed: { icon: faUserCheck, color: 'green' },
  unconfirmed: { icon: faUserTimes, color: 'red' },
  waitlistConfirmation: { icon: faClipboardCheck, color: 'green' },
  waitlistConfirmationUnsent: { icon: faClipboardList, color: 'red' },
  rejected: { icon: faThumbsDown, color: 'red' },
  declined: { icon: faTimesCircle, color: 'red' },
  scheduled: { icon: faCalendarCheck, color: '#28a745' },
  unscheduled: { icon: faCalendarTimes, color: 'red' },
  prescreen: { icon: faClipboardList, color: '#28a745' },
  unresponsive: { icon: faCommentSlash, color: '#707070' },
  notSentSchedule: { icon: faExclamationCircle, color: 'red' },
  deleted: { icon: faTrashAlt, color: 'red' },
};

export {
  CandidateImportFields,
  candidateImportFields,
  // CandidateImportData,
  candidateImportData,
  applicationSystems,
  programTypes,
  CandidateStatusIcons,
  AAMCIDCSVData,
};
