const style = {
  mainBody: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: 10,
  },
  simpleRow: {
    display: 'flex',
    flexDirection: 'row',
  },
  simpleColumn: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: 10,
  },
  spaceBetweenRow: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 10,
  },
  titleField: {
    width: '100%',
  },
  roomNumberField: {
    width: '100%',
  },
  inputLabel: {
    fontWeight: 'bold',
  },
  checkIcon: {
    fontSize: 15,
    marginRight: 20,
    cursor: 'pointer',
  },
  genButton: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginLeft: 10,
    marginRight: 10,
    paddignRight: 20,
    paddingBottom: 10,
    paddingLeft: 20,
    paddingTop: 10,
    width: '95%',
  },
  buttonLabel: { width: 130, textAlign: 'center', fontWeight: 600, fontSize: 13 },
};

export default style;
