import { faCheckCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { fetchDataAgnostic } from '../../Services/dataApi';
import { Button, Table } from 'reactstrap';
import styled from 'styled-components';

const HeaderWrapper = styled.div`
  font-weight: 700;
  padding: 5px;
`;

const TableStyle = { margin: 0 };
const TableWrapper = { border: '1px solid grey' };

const ButtonWrapperStyle = { paddingTop: '10px' };

const BulkUploadPreview = ({
  files,
  cancelCurrentSet,
  setItemPreviewPopup,
  setIsPosting,
  isPosting,
  currentSet,
  setFiles,
  allFiles,
  uploadFiles,
}) => {
  const [filesWithoutUser, setFilesWithoutUser] = useState([]);
  const [filesWithUser, setFilesWithUser] = useState([]);
  const [filesWillOverwrite, setFilesWillOverwrite] = useState([]);

  useEffect(() => {
    if (!files) return;

    const noUserAssociation = [];
    const fileAlreadyExists = [];
    const userAssociated = [];

    files.forEach((file) => {
      if (file.userInfo && file.userInfo.existingFile) {
        fileAlreadyExists.push(file);
      } else if (file.userAssociated) {
        userAssociated.push(file);
      } else {
        noUserAssociation.push(file);
      }
    });
    setFilesWillOverwrite(fileAlreadyExists);
    setFilesWithUser(userAssociated);
    setFilesWithoutUser(noUserAssociation);
  }, [files]);

  const handleChange = (i, array) => {
    if (array === 'filesWithoutUser') {
      const filesWithoutUserCopy = _.cloneDeep(filesWithoutUser);
      filesWithoutUserCopy[i].checked = !filesWithoutUserCopy[i].checked;
      setFilesWithoutUser(filesWithoutUserCopy);
      return;
    }

    if (array === 'filesWithUser') {
      const filesWithUserCopy = _.cloneDeep(filesWithUser);
      filesWithUserCopy[i].checked = !filesWithUserCopy[i].checked;
      setFilesWithUser(filesWithUserCopy);
      return;
    }

    if (array === 'filesWillOverwrite') {
      const filesWillOverwriteCopy = _.cloneDeep(filesWillOverwrite);
      filesWillOverwriteCopy[i].checked = !filesWillOverwriteCopy[i].checked;
      setFilesWillOverwrite(filesWillOverwriteCopy);
      return;
    }
  };

  const fileList = (file, icon, iconColor, i, array) => {
    return (
      <tr key={file.name}>
        <td scope="row">
          <FontAwesomeIcon color={iconColor} icon={icon} />
        </td>
        <td>
          <input
            style={{ marginLeft: '10px' }}
            type="checkbox"
            name="myTextEditBox"
            disabled={!file.userAssociated ? true : false}
            defaultValue="checked"
            checked={file.checked}
            onChange={(e) => {
              handleChange(i, array);
            }}
          />
        </td>

        <td>{file.name}</td>
        {file.userAssociated && (
          <td>
            {file.userInfo.Last}, {file.userInfo.First}
          </td>
        )}
      </tr>
    );
  };
  if (!files) {
    return <div />;
  }

  return (
    <div style={{ padding: '10px' }}>
      <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
        <h2>{files.length} files selected</h2>
      </div>
      <div style={{ height: '412px', overflow: 'scroll' }}>
        {filesWithUser.length > 0 && (
          <div>
            <HeaderWrapper>
              <h4>New Matches: {filesWithUser.length}</h4>
            </HeaderWrapper>
            <div style={TableWrapper}>
              <Table style={TableStyle}>
                <thead>
                  <tr>
                    <th></th>
                    <th>Include</th>
                    <th>File Name</th>
                    <th>Candidate</th>
                  </tr>
                </thead>
                <tbody>
                  {filesWithUser.map((file, i) => {
                    return fileList(file, faCheckCircle, '#228B22', i, 'filesWithUser');
                  })}
                </tbody>
              </Table>
            </div>
          </div>
        )}
        {filesWillOverwrite.length > 0 && (
          <div style={{ paddingTop: '10px' }}>
            <HeaderWrapper>
              <h4>Updating: {filesWillOverwrite.length}</h4>
            </HeaderWrapper>
            <div style={TableWrapper}>
              <Table style={TableStyle}>
                <thead>
                  <tr>
                    <th></th>
                    <th>Include</th>
                    <th>File Name</th>
                    <th>Candidate</th>
                  </tr>
                </thead>
                <tbody>
                  {filesWillOverwrite.map((file, i) => {
                    return fileList(file, faCheckCircle, '#228B22', i, 'filesWillOverwrite');
                  })}
                </tbody>
              </Table>
            </div>
          </div>
        )}
        {filesWithoutUser.length > 0 && (
          <div style={{ paddingTop: '10px' }}>
            <HeaderWrapper>
              <h4>Unable To Match: {filesWithoutUser.length}</h4>
            </HeaderWrapper>
            <div style={TableWrapper}>
              <Table style={TableStyle}>
                <thead>
                  <tr>
                    <th>
                      <FontAwesomeIcon color={'red'} icon={faTimesCircle} />
                    </th>
                    <th>Include</th>
                    <th>File Name</th>
                  </tr>
                </thead>
                <tbody>
                  {filesWithoutUser.map((file, i) => {
                    return fileList(file, faTimesCircle, 'red', i, 'filesWithoutUser');
                  })}
                </tbody>
              </Table>
            </div>
          </div>
        )}
      </div>
      <div style={{ width: '100%', display: 'flex', justifyContent: 'space-evenly', paddingTop: '15px' }}>
        <Button
          style={{ backgroundColor: '#d11a2a' }}
          onClick={(e) => {
            e.preventDefault();
            cancelCurrentSet();
            setItemPreviewPopup(false);
          }}
        >
          Cancel
        </Button>
        <Button
          style={{ backgroundColor: '#458B00' }}
          onClick={(e) => {
            e.preventDefault();
            // uploadDocuments();
            const allFilesCopy = _.cloneDeep(allFiles);
            const last = allFilesCopy.length - 1;
            allFilesCopy[last] = [...filesWithUser, ...filesWillOverwrite];
            setFiles(allFilesCopy);
            uploadFiles(allFilesCopy);
            setItemPreviewPopup(false);
          }}
        >
          Continue
        </Button>
      </div>
    </div>
  );
};

export default BulkUploadPreview;
