const style = {
  descriptionText: {
    fontSize: 13,
    marginTop: 10,
  },
  simpleRow: {
    display: 'flex',
    flexDirection: 'row',
  },
  simpleColumn: {
    display: 'flex',
    flexDirection: 'column',
  },
  popOverBody: {
    width: 300,
  },
};

export default style;
