const style = {
  spacedBetweenRow: {
    flexDirection: 'row',
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    alignItems: 'center',
  },
  simpleRow: {
    flexDirection: 'row',
    display: 'flex',
    marginBottom: 5,
  },
  simpleColumn: {
    display: 'flex',
    flexDirection: 'column',
  },
  spacedAroundRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'center',
  },
  genButton: {
    width: '100%',
  },
  clickable: {
    cursor: 'pointer',
  },
  dateListItem: { width: '100%', textAlign: 'center', cursor: 'pointer', padding: 10, minHeight: 60 },
  buttonLabel: {
    width: '100%',
    textAlign: 'center',
    fontWeight: 600,
    fontSize: 13,
    verticalAlign: 'middle',
  },
  topSectionContainer: {
    marginBottom: 0,
  },
  selectedCandidateName: {
    fontSize: 30,
    fontWeight: 600,
  },
  stickyContainer: { position: 'relative', top: 0 },
  tabContainer: {
    height: 'calc(100% - 168px)',

    display: 'flex',
    flexDirection: 'row',
    overflowY: 'scroll',
    overflowX: 'hidden',
    marginTop: 5,
  },
};

export default style;
