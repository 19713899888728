const style = {
  simpleColumn: {
    display: 'flex',
    flexDirection: 'column',
  },
  simpleRow: {
    display: 'flex',
    flexDirection: 'row',
  },
  spacedBetweenRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'spaced-between',
    alignItems: 'center',
  },
  interviewDateItem: {
    display: 'flex',
    flexDirection: 'row',
    borderBottom: 'solid 1px gray',
    padding: 10,
    justifyContent: 'space-between',
    alignItems: 'center',
  },
};
export default style;
