import React, { useContext, useEffect, useState } from 'react';

import { ResponsiveBar } from '@nivo/bar';
import { clone, colors } from '../../../../../Services/schedule';
import style from './AdminRankChart.style';
import { Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckSquare, faSquare } from '@fortawesome/free-solid-svg-icons';
import tinycolor from 'tinycolor2';
import { useAuth0 } from '../../../../../auth0/reactAuth0Spa';
import { fetchDataAgnostic } from '../../../../../Services/dataApi';
import { DepartmentContext } from '../../../../../DepartmentWrapper';
import { formatBearerToken } from '../../../../../Common.functions';
import Loading from 'Body/Statuses/Loading';

const AdminRankChart = ({
  rawData,
  mode,
  setSelectedUser,
  isShowingChartPrinterFriendlyVersion,
  isFetchingCandidates,
}) => {
  const [chartData, setChartData] = useState([]);
  const [weights, setWeights] = useState([]);

  const [keys, setKeys] = useState([]);
  const [barColors, setBarColors] = useState([]);
  const [barFills, setBarFills] = useState([]);

  const [keysToShow, setKeysToShow] = useState([]);
  const [showAllKeys, setShowAllKeys] = useState(false);

  const { getTokenSilently } = useAuth0();
  const dContext = useContext(DepartmentContext);

  useEffect(() => {
    if (isFetchingCandidates) {
      setChartData([]);
    }
  }, [isFetchingCandidates]);
  useEffect(() => {
    if (dContext && dContext.department) {
      getWeights();
    }
  }, [dContext]);

  useEffect(() => {
    if (rawData && weights && weights.length > 0) {
      constructChartData(rawData);
    }
  }, [rawData, weights]);

  const getWeights = () => {
    getTokenSilently()
      .then((token) => {
        fetchDataAgnostic(
          'department/season/weightCategories',
          // 'department/question/weights',
          { pk_Department: dContext.department.pk_Department, pk_Season: dContext.season.pk_Season },
          formatBearerToken(token),
        )
          .then((res) => {
            setWeights(res.data);
          })
          .catch((err) => {});
      })
      .catch((err) => {});
  };

  useEffect(() => {
    if (keysToShow.length != keys.length) {
      setShowAllKeys(false);
    } else {
      setShowAllKeys(true);
    }

    const newChartData = clone(chartData);
    newChartData.sort((a, b) => {
      let totalA = 0;
      let totalB = 0;
      keys.forEach((key) => {
        const keyActual = key.label;
        if (keysToShow.includes(keyActual)) {
          totalA += parseFloat(a[keyActual]) || 0;
          totalB += parseFloat(b[keyActual]) || 0;
        }
      });

      return totalA - totalB;
    });

    setChartData(newChartData);
  }, [keysToShow]);

  const constructChartData = (data) => {
    const newChartData = [];
    const newKeys = [];
    const colorDefs = [];
    const barFillDefs = [];
    const displayNames = [];

    weights.forEach((weight, i) => {
      const { QuestionType, CategoryName, pk_EvaluationQuestionWeight, PrescreenWeight, EvaluationWeight } = weight;
      if (QuestionType === 'scale') {
        newKeys.push({
          label: CategoryName,
          type: QuestionType,
          pk_EvaluationQuestionWeight: pk_EvaluationQuestionWeight,
          percentage: mode == 1 ? EvaluationWeight || 0 : PrescreenWeight || 0,
        });
      }
    });

    if (data && data.length > 0) {
      data.forEach((dataItem, i) => {
        const newD = {};
        const { CandidateGroupAverages = {} } = dataItem;
        let displayName = `${dataItem.LastName ? `${dataItem.LastName},` : ''} ${dataItem.FirstName || ''}`;

        const duplicateCount = displayNames.reduce(
          (currentCount, name) => (name === displayName ? currentCount + 1 : currentCount),
          0,
        );

        displayNames.push(displayName);
        if (duplicateCount > 0) {
          displayName = `${displayName} ${duplicateCount + 1}`;
        }
        newD.displayName = displayName;
        newD.CandidateGroupAverages = clone(CandidateGroupAverages);
        newD.rawData = { ...dataItem };

        // Object.keys(CandidateGroupAverages).forEach((key, index) => {
        //   const weightDetails = weights.find((w) => {
        //     return w.pk_EvaluationQuestionWeight == key; //&& w.QuestionType === 'text';
        //   });

        //   if (weightDetails && key != null && key != 'null') {
        //     const exists = newKeys.find((key) => {
        //       return (
        //         key.pk_EvaluationQuestionWeight == weightDetails.pk_EvaluationQuestionWeight &&
        //         weightDetails.QuestionType === 'scale'
        //       );
        //     });
        //     if (!exists && weightDetails.QuestionType === 'scale') {
        //       newKeys.push({
        //         label: weightDetails.CategoryName,
        //         type: weightDetails.QuestionType,
        //         pk_EvaluationQuestionWeight: weightDetails.pk_EvaluationQuestionWeight,
        //         percentage: mode == 1 ? weightDetails.EvaluationWeight : weightDetails.PrescreenWeight,
        //       });
        //     }
        //   }
        // });
        newChartData.push(newD);
      });
    }

    newChartData.forEach((d) => {
      const { CandidateGroupAverages = {} } = d;
      Object.keys(CandidateGroupAverages).forEach((key, i) => {
        if (key === 'null') {
          return;
        }
        const ave = CandidateGroupAverages[key];
        const weight = newKeys.find((newK) => {
          return newK.pk_EvaluationQuestionWeight == key;
        });
        // Only process value if exists in GET endpoint for Weights. Or if in newKeys, which is valid weight keys (we ignore text types)
        if (weight) {
          d[weight.label] = (ave.GroupAverageAsDecimal * ave.Weight).toFixed(2);
          d[`${weight.label}color`] = colors[i];
        }
      });
      delete d.CandidateGroupAverages;
    });

    newKeys.forEach((key, i) => {
      colorDefs.push(colors[i]);
    });
    setBarFills(barFillDefs);
    setBarColors(colorDefs);
    setChartData(newChartData);
    setKeys(newKeys);
    setKeysToShow(
      newKeys.map((keyObj) => {
        if (keyObj.percentage > 0) {
          return keyObj.label;
        }
      }),
    );
  };

  const getTextWidth = (text, font) => {
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');

    context.font = font || getComputedStyle(document.body).font;
    return context.measureText(text).width;
  };

  const renderChart = () => {
    let height = 30;

    if (rawData.length < 5) {
      height = '30vh';
    } else if (rawData.length < 10) {
      height = '60vh';
    } else {
      height = `${rawData.length * 30}px`;

      // if (height > 90) {
      //   height = '90vh';
      // } else {
      // }
    }

    return (
      <div
        id="chartContainer"
        style={{
          // ...style.simpleRow,
          // alignItems: 'center',
          overflowY: isShowingChartPrinterFriendlyVersion ? 'none' : 'scroll',
          overflowX: 'none',
          height: isShowingChartPrinterFriendlyVersion ? 'auto' : 'calc(100vh - 550px)',
          // minHeight: '50vh',
        }}
      >
        <div style={{ ...style.simpleColumn, height: height }}>
          <ResponsiveBar
            data={chartData}
            keys={keysToShow}
            indexBy="displayName"
            margin={{ top: 10, right: 100, bottom: 50, left: 140 }}
            maxValue={100}
            minValue={0}
            enableGridX={false}
            enableGridY={true}
            padding={0.2}
            layout="horizontal"
            valueScale={{ type: 'linear' }}
            indexScale={{ type: 'band', round: true }}
            valueFormat={{ format: '', enabled: false }}
            colors={barColors}
            borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
            axisTop={null}
            axisRight={{
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legend: '',
              legendPosition: 'middle',
              legendOffset: 40,
              renderTick: (tick) => {
                const candidateData = chartData[tick.tickIndex];
                if (candidateData) {
                  const rawValue = chartData[tick.tickIndex].rawData;
                  let total = 0;
                  const { CandidateGroupAverages = {} } = rawValue;

                  Object.keys(CandidateGroupAverages).forEach((key) => {
                    const weightDetails = weights.find((w) => {
                      return w.pk_EvaluationQuestionWeight == key;
                    });

                    const weightWithPercentage = keys.find((k) => {
                      return (
                        weightDetails && k.pk_EvaluationQuestionWeight == weightDetails.pk_EvaluationQuestionWeight
                      );
                    });

                    if (
                      weightDetails &&
                      weightWithPercentage &&
                      weightWithPercentage.percentage > 0 &&
                      keysToShow.includes(weightDetails.CategoryName)
                    ) {
                      const val = CandidateGroupAverages[key];
                      total += parseFloat(val.GroupAverageAsDecimal * val.Weight);
                    }
                  });

                  return (
                    <g transform={`translate(${tick.x + 30},${tick.y})`} style={{ backgroundColor: 'red' }}>
                      <rect x={-14} y={-8} rx={3} ry={3} width={54} height={24} fill="rgba(0, 0, 0, .05)" />
                      <rect x={-12} y={-12} rx={2} ry={2} width={54} height={24} fill="rgb(232, 193, 160)" />
                      <line stroke="rgb(232, 193, 160)" strokeWidth={1.5} x1={-35} x2={-12} />
                      <text
                        y={5}
                        style={{
                          fill: '#333',
                          fontSize: 12,
                          marginLeft: -20,
                          marginBottom: -10,
                          marginTop: 10,
                          fontWeight: 'bold',
                        }}
                      >{`${total.toFixed(2)}`}</text>
                    </g>
                  );
                } else {
                  return null;
                }
              },
            }}
            axisBottom={{
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legend: '',
              legendPosition: 'middle',
              legendOffset: 40,
              // renderTick: (tick) => {
              //   const { value } = tick;
              //   return `XX${value}`;
              // },
            }}
            axisLeft={{
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legend: '',
              legendPosition: 'end',
              legendOffset: 0,
              renderTick: (tick) => {
                const bar = chartData[tick.tickIndex];
                if (!bar) {
                  return null;
                }
                const rawValue = chartData[tick.tickIndex].rawData;

                const lineStart = getTextWidth(tick.value) - 10;

                return (
                  <g
                    // transform={`translate(${-getTextWidth(tick.value)},${tick.y})`}
                    // transform={`translate(${-120}, ${tick.y})`}
                    style={{
                      display: 'flex',
                      cursor: 'pointer',
                      backgroundColor: 'red',
                      alignItems: 'right',
                      textAlign: 'right',
                    }}
                    onClick={(data) => {
                      setSelectedUser(rawValue);
                    }}
                  >
                    {/* <line
                    transform={`translate(${-lineStart}, ${tick.y})`}
                    stroke="black"
                    strokeWidth={1.5}
                    x1={-10}
                    x2={0}
                  /> */}
                    <text
                      transform={`translate(${-lineStart}, ${tick.y})`}
                      y={4}
                      style={{ fontSize: 11, textAlign: 'right', width: '100%', alignItems: 'self-end' }}
                    >
                      {tick.value}
                    </text>
                  </g>
                );
              },
            }}
            labelSkipWidth={10}
            labelSkipHeight={12}
            labelTextColor={(d) => {
              if (tinycolor(d.color).isLight()) {
                return { from: 'color', modifiers: [['darker', 1.6]] };
              } else {
                return 'white';
              }
            }}
          />
        </div>
      </div>
    );
  };

  if (isFetchingCandidates) {
    return (
      <div style={{ paddingTop: '20vh' }}>
        <Loading options={{ labelText: 'Fetching Rank List. . .' }} />
      </div>
    );
  }
  return (
    <div style={{ ...style.simpleRow }}>
      <div id="chart_container" style={style.simpleColumn}>
        {rawData && rawData.length <= 0 ? (
          <div style={{ width: '100%', textAlign: 'center', fontWeight: 'bold', marginTop: '25vh' }}>No Data</div>
        ) : (
          <>
            <div style={{ ...style.spacedBetweenRow, paddingLeft: 30, paddingRight: 30, marginTop: 20 }}>
              <div style={{ ...style.simpleColumn, width: '20%', minWidth: 180 }}>
                <Button
                  size="sm"
                  outline={showAllKeys ? false : true}
                  color={showAllKeys ? 'success' : null}
                  style={{ marginRight: 5, outline: 'none' }}
                  onClick={() => {
                    setShowAllKeys(!showAllKeys);
                    if (showAllKeys) {
                      setKeysToShow([]);
                    } else {
                      setKeysToShow(
                        keys.map((k) => {
                          if (k.percentage > 0) {
                            return k.label;
                          }
                        }),
                      );
                    }
                  }}
                >
                  <div style={style.spacedBetweenRow}>
                    <div>
                      <FontAwesomeIcon icon={showAllKeys ? faCheckSquare : faSquare} style={{ marginRight: 5 }} />
                    </div>
                    <div> Show all Weights</div>
                  </div>
                </Button>
              </div>
              <div style={{ ...style.simpleRow, flexWrap: 'wrap' }}>
                {keys.map((keyObj, i) => {
                  const key = keyObj.label;
                  const backgroundColor = barColors[i];
                  const isActive = keysToShow.includes(key);
                  return (
                    <Button
                      size="sm"
                      outline={showAllKeys && keyObj.percentage > 0 ? false : true}
                      disabled={keyObj.percentage === 0}
                      style={{
                        marginBottom: 5,
                        marginRight: 5,
                        backgroundColor: isActive ? backgroundColor : null,
                        border: `2px solid ${backgroundColor}`,
                        color: tinycolor(backgroundColor).isLight() || !isActive ? '#3a3a3a' : 'white',
                      }}
                      onClick={() => {
                        // Slice from keys to preserve order of weigths in chart bar
                        let newKeysToShow = keysToShow.slice();
                        if (keysToShow.includes(key)) {
                          const indexOfKey = newKeysToShow.indexOf(key);
                          newKeysToShow.splice(indexOfKey, 1);
                        } else {
                          newKeysToShow = [];
                          keys.forEach((k) => {
                            if (keysToShow.includes(k.label) || k.label == key) {
                              newKeysToShow.push(k.label);
                            }
                          });
                        }
                        setKeysToShow(newKeysToShow);
                      }}
                    >
                      <div style={style.spacedBetweenRow}>
                        <div>
                          <FontAwesomeIcon
                            icon={keysToShow.includes(key) ? faCheckSquare : faSquare}
                            style={{ marginRight: 5 }}
                          />
                        </div>
                        <div>
                          {' '}
                          {keyObj.label} ({keyObj.percentage}%)
                        </div>
                      </div>
                    </Button>
                  );
                })}
              </div>
            </div>

            {renderChart()}
          </>
        )}
      </div>
    </div>
  );
};

export default AdminRankChart;
