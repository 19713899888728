const style = {
  mainBody: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: 10,
  },
  inputFields: {
    textAlign: 'center',
    width: '100%',
  },
  inputLabel: {
    fontWeight: 'bold',
  },
  genButton: {
    alignItems: 'center',
    backgroundColor: '#0a91ff',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginLeft: 10,
    marginRight: 10,
    // paddignRight: 20,
    // paddingBottom: 10,
    // paddingLeft: 20,
    // paddingTop: 10,
    textAlign: 'center',
    fontWeight: 600,
  },
  simpleRow: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 20,
  },
  spacedBetweenRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 20,
  },
  buttonLabel: { width: '100%', textAlign: 'center', fontWeight: 600 },
};

export default style;
