import React, { useEffect, useState } from 'react';
import { faEnvelope, faMobile, faPhone, faPhoneAlt, faUserCircle } from '@fortawesome/free-solid-svg-icons';
import style from './Attendee.style';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'reactstrap';

const Attendee = ({
  attendee,
  index,
  attendees,
  timeStart,
  timeEnd,
  timeZone,
  meetingURL,
  meetingPassword,
  populateDialInNumbers,
  dateIsInThePast,
  willEditThePast,
  fireConfirmationForEditingPastSchedule,
}) => {
  const [nameList, setNameList] = useState('');
  const [emailList, setEmailList] = useState('');

  const backgroundColor = index % 2 == 0 ? '#d9f5ff' : null;
  const { isAttending = {} } = attendee;
  const { IsActive } = isAttending || {};
  // const isAttending = attendee.isAttending && attendee.isActive;
  let columnTitle = '---';
  const numbers = [];

  const processAtendeeListNameAndEmails = (list) => {
    let newEmailList = null;
    let newNameList = '';

    list.forEach((a) => {
      if (a.label) {
        return;
      }

      if (a.Email || a.Auth0Email) {
        if (newEmailList) {
          newEmailList += `,${a.Email || a.Auth0Email}`;
        } else {
          newEmailList = `${a.Email || a.Auth0Email}`;
        }
      }

      if (newNameList) {
        if (a.pk_User) {
          newNameList += `, ${a.UserFirst || ''} ${a.UserLast || ''}`;
        } else {
          newNameList += `, ${a.FirstName || ''} ${a.LastName || ''}`;
        }
      } else {
        if (a.pk_User) {
          newNameList = `${a.UserFirst || ''} ${a.UserLast || ''}`;
        } else {
          newNameList = `${a.FirstName || ''} ${a.LastName || ''}`;
        }
      }
    });

    return { emailList: newEmailList, nameList: newNameList };
  };

  useEffect(() => {
    if (attendee) {
      const myIndex = attendees.findIndex((a) => {
        return (
          (attendee.pk_Candidate != null && a.pk_Candidate == attendee.pk_Candidate) ||
          (attendee.pk_User != null && a.pk_User == attendee.pk_User)
        );
      });
      const newObject = processAtendeeListNameAndEmails(
        attendees.filter((a) => {
          return (
            (a.pk_Candidate != null && attendee.pk_Candidate != a.pk_Candidate) ||
            (a.pk_User != null && attendee.pk_User != a.pk_User)
          );
        }),
      );
      setNameList(newObject.nameList || '');
      setEmailList(newObject.emailList || '');
    }
  }, [attendee]);

  if (attendee) {
    if (attendee.UserFirst || attendee.UserLast) {
      columnTitle = `${attendee.UserFirst || ''} ${attendee.UserLast || ''}`;
    } else if (attendee.FirstName || attendee.LastName) {
      columnTitle = `${attendee.FirstName || ''} ${attendee.LastName || ''}`;
    }

    if (attendee.PhoneNumber) {
      numbers.push({ icon: faPhone, number: attendee.PhoneNumber });
    }

    if (attendee.AltPhoneNumber) {
      numbers.push({ icon: faPhoneAlt, number: attendee.AltPhoneNumber });
    }

    if (attendee.CellPhone) {
      numbers.push({ icon: faMobile, number: attendee.CellPhone });
    }
  }

  return (
    <div style={{ ...style.spacedBetweenRow, ...style.attendee, backgroundColor }}>
      <div style={{ ...style.simpleColumn, width: '10%', marginRight: 10 }}>
        {attendee.UserPhotoUrl || attendee.PhotoUrl ? (
          <img
            style={{ ...style.attendeeProfilePlaceHolder, borderRadius: 25, height: 50, width: 50 }}
            src={attendee.UserPhotoUrl || attendee.PhotoUrl}
          />
        ) : (
          <FontAwesomeIcon icon={faUserCircle} style={style.attendeeProfilePlaceHolder} />
        )}
      </div>
      <div style={{ ...style.simpleColumn, width: '70%' }}>
        <div style={style.attendeeName}>{columnTitle}</div>

        <div
          style={{ marginBottom: 5 }}
          onClick={async (e) => {
            e.stopPropagation();
            if (dateIsInThePast && !willEditThePast) {
              const continueProcess = await fireConfirmationForEditingPastSchedule();
              if (!continueProcess) {
                return;
              }
            }
          }}
        >
          <a
            disabled={!attendee.Email && !attendee.Auth0Email && (!dateIsInThePast || willEditThePast)}
            href={
              attendee.Email || (attendee.Auth0Email && (!dateIsInThePast || willEditThePast))
                ? `mailto:${attendee.Email ||
                    attendee.Auth0Email}?subject=Meeting with ${nameList}.&body=Start Time: ${timeStart} ${timeZone}%0d%0aEnd Time: ${timeEnd} ${timeZone}%0d%0aJoin Meeting: ${meetingURL}%0d%0aPassword:${meetingPassword}%0d%0aDetails:%0d%0a${populateDialInNumbers()}`
                : null
            }
          >
            {/* <a
            style={{ display: 'none' }}
            id="sendEmailProper"
            href={`mailto:${emailData.emailList || ''}?subject=Meeting with ${emailData.nameList ||
              'No one'}!&body=Start Time: ${timeStart} ${timeZone}%0d%0aEnd Time: ${timeEnd} ${timeZone}%0d%0aJoin Meeting: ${meetingURL}%0d%0aPassword:${meetingPassword}%0d%0aDetails:%0d%0a${populateDialInNumbers()}`}
          ></a> */}
            <Button size="sm" color="primary" disabled={!attendee.Email && !attendee.Auth0Email}>
              <div style={style.spacedBetweenRow}>
                <div style={{ marginRight: 10 }}>
                  <FontAwesomeIcon icon={faEnvelope} />
                </div>
                <div>{attendee.Email || attendee.Auth0Email || 'No Email Address'}</div>
              </div>
            </Button>
          </a>
        </div>
        <div style={style.simpleRow}>
          {numbers.map((n) => {
            return (
              <div style={{ marginRight: 10, paddingRight: 10, borderRight: '1px solid grey', fontSize: 12 }}>
                <FontAwesomeIcon icon={n.icon} /> {n.number}
              </div>
            );
          })}
        </div>
      </div>
      <div style={{ ...style.simpleColumn, width: '20%' }}>
        <div style={style.attendeeAttendance(isAttending)}>
          {isAttending == null || (isAttending.fk_Candidate == null && isAttending.fk_User == null)
            ? 'Not yet joined'
            : (isAttending.fk_Candidate || isAttending.fk_User) && IsActive
            ? 'Attending'
            : (isAttending.fk_Candidate || isAttending.fk_User) && !IsActive
            ? 'Vacated'
            : 'Vacated'}
        </div>
      </div>
    </div>
  );
};

export default Attendee;
