import { connect } from 'react-redux';
import RezRATE from './RezRATE';
import { setEvaluatorInterviewDates } from '../../Evaluator.action';

const mapStateToProps = (state) => ({
  evaluator: state.evaluator,
});

const mapDispatchToProps = (dispatch) => ({
  setEvaluatorInterviewDates: (dates) => dispatch(setEvaluatorInterviewDates(dates)),
});

export default connect(mapStateToProps, mapDispatchToProps)(RezRATE);
