import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import imageToBase64 from 'image-to-base64';

import moment from 'moment-timezone';
import { clone } from '../../schedule';
import { roundToDecimalPlace } from '../../../Common.functions';
import profilePlaceholder from '../CandidateBadges/Portrait_Placeholder.png';
import rezRateLogo from '../rezRateLogo.png';

let profilePlaceholderBase64 = null;
let rezRateLogoBase64 = null;
let departmentLogoBase64 = null;
let activeDepartment = null;

pdfMake.vfs = pdfFonts.pdfMake.vfs;

// 1 inch = 72 units.
// LETTER size is 612x792

const tableLayouts = {
  customTable: {
    hLineWidth: (i, node) => {
      return 0;
    },
    vLineWidth: (i) => {
      return 0;
    },
    hLineColor: (i) => {
      return i === 1 ? 'black' : '#aaa';
    },
    paddingLeft: (i) => {
      return 0;
    },
    paddingRight: (i, node) => {
      return 0;
    },
    paddingTop: (i, node) => {
      return 0;
    },
    paddingBottom: (i, node) => {
      return 0;
    },
  },
  headerTable: {
    hLineWidth: (i, node) => {
      return 1;
    },
    vLineWidth: (i) => {
      return 0;
    },
    hLineColor: (i) => {
      return '#aaa';
    },
    paddingLeft: (i) => {
      return 10;
    },
    paddingRight: (i, node) => {
      return 10;
    },
    paddingTop: (i, node) => {
      return 10;
    },
    paddingBottom: (i, node) => {
      return 10;
    },
  },
};

const truncate = (wordToTruncate, limit = 0, forcedCut) => {
  if (wordToTruncate == null) {
    return '';
  }
  if (limit == 0 || wordToTruncate.length < limit + 1) {
    return wordToTruncate;
  } else if (forcedCut) {
    return `${wordToTruncate.slice(0, limit)}`;
  } else if (wordToTruncate.length > limit + 1) {
    return `${wordToTruncate.slice(0, limit - 5)}. . .`;
  }
};

const createPDFProper = (data) => {
  const { interviewDate, department, departmentLogo, candidates } = data;

  const rows = [];

  rows.push([
    { text: 'Sort Order', fontSize: 11, style: { bold: true, alignment: 'center' } },
    { text: 'Candidate', fontSize: 11, style: { alignment: 'center', bold: true, alignment: 'center' } },
    { text: 'Interview Date', fontSize: 11, style: { bold: true, alignment: 'center' } },
    { text: 'Total Score', fontSize: 11, style: { bold: true, alignment: 'center' } },
  ]);

  candidates.forEach((c, i) => {
    const {
      userPhotoBase64,
      FirstName,
      LastName,
      CandidateSubHeading = '',
      DateOfInterview,
      CandidateFinalScoreAsDecimal,
    } = c;
    rows.push([
      {
        text: `${i + 1}`,
        fontSize: 10,
        style: { alignment: 'center', bold: true },
        margin: [0, 10, 0, 0],
        fillColor: i % 2 == 0 ? '#E5E5E5' : 'white',
      },

      {
        table: {
          widths: [120, '*'],
          body: [
            [
              {
                image: userPhotoBase64
                  ? `data:image/png;base64,${userPhotoBase64}`
                  : `data:image/png;base64,${profilePlaceholderBase64}`,
                width: 80,
                fillColor: i % 2 == 0 ? '#E5E5E5' : 'white',
                style: { alignment: 'right' },
              },
              {
                table: {
                  widths: ['*'],
                  body: [
                    [
                      {
                        text: `${FirstName || ''} ${LastName || ''}`,
                        fontSize: 10,
                        style: { alignment: 'center', bold: true },
                      },
                    ],
                    [
                      {
                        text: `${CandidateSubHeading || ' '}`,
                        style: { alignment: 'center', style: 'bold' },
                        fontSize: 9,
                      },
                    ],
                  ],
                },
                margin: [0, 10, 0, 0],
                layout: 'customTable',
              },
            ],
          ],
        },
        layout: 'customTable',
        fillColor: i % 2 == 0 ? '#E5E5E5' : 'white',
      },

      {
        text: ` ${DateOfInterview ? moment(DateOfInterview, null).format('MMM DD, YYYY') : 'No Interview Date'}`,
        fontSize: 9,
        style: { alignment: 'center', bold: false },
        margin: [0, 10, 0, 0],
        fillColor: i % 2 == 0 ? '#E5E5E5' : 'white',
      },

      {
        text: `${
          CandidateFinalScoreAsDecimal != null ? roundToDecimalPlace(100 * CandidateFinalScoreAsDecimal, 2) : 0
        }`,
        fontSize: 10,
        style: { alignment: 'center', bold: false },
        margin: [0, 10, 0, 0],
        fillColor: i % 2 == 0 ? '#E5E5E5' : 'white',
      },
    ]);
  });

  let pdfObject = {
    table: {
      headerRows: 1,
      widths: ['auto', '*', 'auto', 'auto'],
      body: rows,
      dontBreakRows: true,
    },

    layout: 'headerTable',
    margin: [0, 0, 0, 20],
  };

  const departmentLogoToUse = departmentLogo || rezRateLogoBase64;
  // imageToBase64(bgGuide)
  //   .then((bgBase64) => {
  const docDefinition = {
    // background: { image: `data:image/png;base64,${bgBase64}`, width: 612 },
    info: { title: `Rank List for ${department.DepartmentName}` },
    pageSize: 'LETTER',
    pageOrientation: 'portrait',
    content: [pdfObject],
    images: { deptLogo: `data:image/png;base64,${departmentLogoToUse}` },
    defaultStyle: {
      fontSize: 15,
    },
    pageMargins: [0.25 * 72, 1.5 * 72, 0.25 * 72, 72],
    footer: function(currentPage, pageCount) {
      return { text: `${currentPage.toString() + ' of ' + pageCount}`, alignment: 'center', fontSize: 10 };
    },
    header: function(currentPage, pageCount, pageSize) {
      return [
        {
          columns: [
            {
              width: 80,
              columns: [
                departmentLogoToUse[0] === '/' || departmentLogoToUse[0] === 'i'
                  ? {
                      image: `data:image/png;base64,${departmentLogoToUse}`,
                      fit: [80, 80],
                    }
                  : { text: '', width: 80, height: 80 },
              ],
            },
            [
              {
                text: `${department.DepartmentName || 'No Dept Name'}`,
                style: { alignment: 'left', bold: true },
                margin: [0, 10, 0, 0],
                fillColor: '#E5E5E5',
              },
            ],
          ],

          margin: [20, 20, 20, 0],
        },
      ];
    },
  };

  pdfMake.createPdf(docDefinition, tableLayouts).open({}, window.open('', '_blank'));
  // })
  // .catch((err) => {
  // });
};

export const createPDFForRankList = ({ department, season, candidates = [], callback }) => {
  const candidatesCopy = clone(candidates);
  activeDepartment = department;

  Promise.all([
    ...candidatesCopy.map((c) => {
      return new Promise((resolve, reject) => {
        if (c.PhotoUrl) {
          imageToBase64(c.PhotoUrl)
            .then((base64Img) => {
              c.userPhotoBase64 = base64Img;
              resolve(c);
            })
            .catch((err) => {
              resolve({ error: true, ...c });
            });
        } else {
          resolve(c);
        }
      });
    }),
    new Promise((resolve, reject) => {
      imageToBase64(profilePlaceholder)
        .then((base64) => {
          profilePlaceholderBase64 = base64;
          resolve(base64);
        })
        .catch((err) => {
          reject(err);
        });
    }),

    new Promise((resolve, reject) => {
      imageToBase64(rezRateLogo)
        .then((res) => {
          rezRateLogoBase64 = res;
          resolve();
        })
        .catch(() => {
          resolve();
        });
    }),
  ])
    .then((res) => {
      if (department.DepartmentLogo) {
        imageToBase64(department.DepartmentLogo)
          .then((res) => {
            departmentLogoBase64 = res;
            if (callback) {
              callback();
            }
            createPDFProper({ candidates: candidatesCopy, department, departmentLogo: res });
          })
          .catch((err) => {
            console.log("Couldn't get department logo");
            if (callback) {
              callback();
            }
            createPDFProper({ candidates: candidatesCopy, department });
          });
      } else {
        if (callback) {
          callback();
        }
        createPDFProper({ candidates: candidatesCopy, department });
      }
    })
    .catch((err) => {
      if (callback) {
        callback();
      }
    });
};
