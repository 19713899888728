const style = {
  tagList: {
    marginTop: 10,
    marginBottom: 10,
    height: 150,
    overflowY: 'scroll',
  },
  actionButtons: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    marginTop: 5,
  },
  centeredText: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    textAlign: 'center',
  },
};

export default style;
