const style = {
  itemRow: {
    minHeight: 80,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: 15,
    cursor: 'pointer',
  },
  imgPlaceHolder: {
    fontSize: 50,
    // marginRight: 10,
    width: 80,
    height: 80,
    objectFit: 'contain',
  },
  candidateInfo: {
    textAlign: 'left',
    fontWeight: 600,
    // fontSize: 12,
  },
  buttonIcon: {
    cursor: 'pointer',
    margin: 5,
  },
  simpleRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    verticalAlign: 'middle',
  },
  simpleColumn: {
    display: 'flex',
    flexDirection: 'column',
  },
};

export default style;
