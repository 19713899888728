const style = {
  seasonButton: {
    width: 250,
  },
  spacedBetweenRow: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  simpleRow: {
    display: 'flex',
    flexDirection: 'row',
  },
  simpleColumn: {
    display: 'flex',
    flexDirection: 'column',
  },
  headerStyle: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    textAlign: 'center',
    verticalAlign: 'middle',
  },
  seasonLabel: {
    textAlign: 'center',
    cursor: 'pointer',
  },
  tableContainer: { height: 400, overflowY: 'scroll', marginBottom: 10 },
};
export default style;
