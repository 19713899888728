const style = {
  simpleRow: {
    display: 'flex',
    flexDirection: 'row',
  },
  simpleColumn: {
    display: 'flex',
    flexDirection: 'column',
  },
  spacedBetweenRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  disabledCheckbox: {
    cursor: 'not-allowed',
    fontSize: 25,
    marginRight: 10,
    color: 'darkgray',
  },
  enabledCheckbox: {
    fontSize: 25,
    marginRight: 10,
    cursor: 'pointer',
  },
};

export default style;
