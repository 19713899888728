const style = {
  simpleRow: {
    display: 'flex',
    flexDirection: 'row',
  },
  spacedBetweenRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'spaced-between',
  },
  simpleColumn: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  centeredText: {
    width: '100%',
    textAlign: 'center',
  },
};
export default style;
