import React from 'react';

import { Row, Col, Input, InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import EvaluatorDropDownItem from '../EvaluatorDropDownItem';

import style from './style.js';
import { clone } from '../../../../../../Services/schedule';

const EvaluatorDropDown = ({ evaluators = [], scheduleData, columnTracker, candidates, ignoreValidity = false }) => {
  const { headers = [] } = scheduleData || {};
  const validEvaluators = [];
  const evaluatorHeaderTracker = {};
  const evaluatorsToDisplay = ignoreValidity ? Object.values(evaluators) : [];

  headers.forEach((h) => {
    if (h.Evaluators && h.Evaluators.Evaluators) {
      const evs = h.Evaluators.Evaluators || [];
      evs.forEach((ev) => {
        if (!validEvaluators.includes(ev.pk_User)) {
          validEvaluators.push(ev.pk_User);
          evaluatorHeaderTracker[ev.pk_User] = h.pk_ScheduleAssignment;
        }
      });
    }
  });

  Object.values(evaluators).map((evaluator, i) => {
    const { Delete, EnableEvaluator } = evaluator;
    if (Delete || !EnableEvaluator) {
      return;
    }

    if (validEvaluators.includes(evaluator.pk_User)) {
      evaluatorsToDisplay.push(evaluator);
    }
    return null;
  });

  return (
    <div style={style.simpleRow}>
      <div style={{ ...style.simpleColumn, minWidth: '100%' }}>
        <div style={style.simpleRow}>
          <div style={{ ...style.simpleColumn, flex: 1 }}>
            <InputGroup>
              <Input type="text" placeholder="Search for Evaluator" />
              <InputGroupAddon addonType="append">
                <InputGroupText>
                  <FontAwesomeIcon icon={faSearch} />
                </InputGroupText>
              </InputGroupAddon>
            </InputGroup>
          </div>
        </div>
        <div style={style.simpleRow}>
          <div style={{ ...style.dropDownBody, marginTop: 5 }}>
            {evaluatorsToDisplay
              .sort((a, b) => {
                const aName = `${a.UserLast ? `${a.UserLast}, ` : ''}${a.UserFirst || ''}`;
                const bName = `${b.UserLast ? `${b.UserLast}, ` : ''}${b.UserFirst || ''}`;

                if (aName < bName) {
                  return -1;
                }
                if (aName > bName) {
                  return 1;
                }
                return 0;
              })
              .map((evaluator, i) => {
                return (
                  <EvaluatorDropDownItem
                    key={evaluator.pk_User}
                    evaluator={evaluator}
                    index={i}
                    ignoreValidity={ignoreValidity}
                    candidateInteviewCount={
                      columnTracker[evaluatorHeaderTracker[evaluator.pk_User]]
                        ? columnTracker[evaluatorHeaderTracker[evaluator.pk_User]].length
                        : 0
                    }
                    candidates={candidates}
                  />
                );
              })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default EvaluatorDropDown;
