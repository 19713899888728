import React, { useEffect, useImperativeHandle, useRef, useState } from 'react';
import { Col, Row } from 'reactstrap';
import { useAuth0 } from '../../../../../auth0/reactAuth0Spa';
import { formatBearerToken } from '../../../../../Common.functions';
import { fetchDataAgnostic, fetchDataAuth0 } from '../../../../../Services/dataApi';
import CandidateList from '../../CandidateList';
import AdminMessageComposer from './AdminMessageComposer/AdminMessageComposer';
import { clone } from 'lodash';

const AdminSendMessage = ({
  candidates,
  activeDepartment,
  interviewDates,
  setPageChanges,
  pageChanges,
  setCandidates,
  getCandidates,
}) => {
  const [availableTemplates, setAvailableTemplates] = useState(null);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [selectedTemplateId, setSelectedTemplateId] = useState('placeholder');
  const [selectedCandidate, setSelectedCandidate] = useState();
  const [selectedCandidateIds, setSelectedCandidateIds] = useState([]);

  const [resetList, setResetList] = useState();

  const [isUpdating, setIsUpdating] = useState();

  const { getTokenSilently } = useAuth0();

  useEffect(() => {
    if (candidates && selectedCandidate == null) {
      setSelectedCandidate(candidates[0]);
    }
  }, [candidates]);

  useEffect(() => {
    getTemplates();
  }, []);

  const getTemplates = (id = null, cb) => {
    getTokenSilently().then((token) => {
      fetchDataAuth0(
        'department/readCommunicationTemplates',
        { pk_Department: activeDepartment.pk_Department },
        formatBearerToken(token),
      )
        .then((result) => {
          const newData = result.data || [];

          if (newData) {
            newData.sort(function(a, b) {
              var textA = a.Title.toUpperCase();
              var textB = b.Title.toUpperCase();
              return textA < textB ? -1 : textA > textB ? 1 : 0;
            });
          }

          setAvailableTemplates(newData);

          if (id) {
            // if a template was just added select that template from the options
            setSelectedTemplateId(id);
            getTemplateById(id);
          } else if (selectedTemplate) {
            getTemplateById(selectedTemplate.pk_CommunicationTemplate);
          } else {
            console.log('no selected template');
          }

          if (cb) {
            cb();
          }
        })
        .catch((err) => {
          if (cb) {
            cb(err);
          }
        });
    });
  };

  const getTemplateById = (value) => {
    setSelectedTemplateId(value);
    // if they choose the placeholder option just set the selected template to null
    if (value === 'placeholder') {
      setSelectedTemplate(null);
      return;
    }

    setIsUpdating(true);
    getTokenSilently().then((token) => {
      fetchDataAgnostic(
        'department/readCommunicationTemplate',
        { pk_Department: activeDepartment.pk_Department, pk_CommunicationTemplate: value },
        formatBearerToken(token),
      )
        .then((result) => {
          setIsUpdating(false);
          setSelectedTemplate(result.data);
        })
        .catch((err) => {
          setIsUpdating(false);
        });
    });
  };

  if (!activeDepartment) {
    return <div />;
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'row', overflowX: 'auto' }}>
      <div
        style={{
          padding: 10,
          paddingTop: 5,
          margin: 5,
          display: 'flex',
          flexDirection: 'column',
          width: '28%',
          minWidth: 220,
          height: `calc(100vh - 300px)`,
        }}
        className="card shadow bg-light"
      >
        <CandidateList
          height={`calc(100vh - 240px)`}
          multiselect={true}
          interviewDates={interviewDates}
          candidates={candidates}
          pk_Department={activeDepartment.pk_Department}
          setSelectedCandidate={setSelectedCandidate}
          selectedCandidate={selectedCandidate}
          showTagFilter={true}
          setResetList={(newResetList) => {
            setResetList(() => {
              return newResetList;
            });
          }}
          updateParentValues={(newSelectedCandidateIDs) => {
            const selectedActual = [];
            Object.keys(newSelectedCandidateIDs).forEach((key) => {
              if (newSelectedCandidateIDs[key]) {
                selectedActual.push(parseInt(key));
              }
            });
            setSelectedCandidateIds(selectedActual);
          }}
        />
      </div>
      <div
        style={{
          padding: 0,
          margin: 5,
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          overflowY: 'clip',
          minWidth: 620,
          height: `calc(100vh - 300px)`,
        }}
        className="card shadow bg-light"
      >
        <AdminMessageComposer
          activeDepartment={activeDepartment}
          availableTemplates={availableTemplates}
          candidates={candidates}
          getCandidates={getCandidates}
          getTemplateById={getTemplateById}
          getTemplates={getTemplates}
          isUpdating={isUpdating}
          pageChanges={pageChanges}
          selectedCandidate={selectedCandidate}
          selectedCandidateIds={selectedCandidateIds}
          selectedTemplate={selectedTemplate}
          selectedTemplateId={selectedTemplateId}
          setCandidates={setCandidates}
          setPageChanges={setPageChanges}
          setSelectedCandidateIds={setSelectedCandidateIds}
          setSelectedTemplate={setSelectedTemplate}
          setSelectedTemplateId={setSelectedTemplateId}
          resetList={() => {
            if (resetList) {
              resetList();
            } else {
              console.log('no reset list func');
            }
          }}
        />
      </div>
    </div>
  );
};

export default AdminSendMessage;
