import React from 'react';
import moment from 'moment-timezone';

import { Input, Label, Modal, ModalBody, ModalHeader, Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckSquare, faSquare } from '@fortawesome/free-regular-svg-icons';

import style from './CloneModal.style';
import Swal from 'sweetalert2';

const CloneModal = ({
  scheduleID,
  setWillEditThePast,
  resetCopyValues,
  showCloneModal,
  setShowCloneModal,
  setInterviewDateToClone,
  willCopyHeaders,
  setWillCopyHeaders,
  willCopyStartTime,
  setWillCopyStartTime,
  willCopyInterviewDuration,
  setWillCopyInterviewDuration,
  willCopyPassingDuration,
  setWillCopyPassingDuration,
  willCopyCandidateAssignments,
  setWillCopyCandidateAssignments,
  willCopyCustomEvents,
  setWillCopyCustomEvents,
  willCopyTimeSlots,
  setWillCopyTimeSlots,
  willCopyFlexEventEvaluators,
  setWillCopyFlexEventEvaluators,
  willCopyCustomDurations,
  setWillCopyCustomDurations,
  willCopyFocuses,
  setWillCopyFocuses,
  willCopyFlexEvents,
  setWillCopyFlexEvents,
  willCopyEvaluators,
  setWillCopyEvaluators,
  interviewDateToClone,
  cloneFromExistingSchedule,
  isCloning,
  isSubmitting,
  interviewDates,
}) => {
  return (
    <Modal
      isOpen={showCloneModal}
      zIndex={3}
      centered={true}
      onOpened={() => {
        setWillEditThePast(true);
        resetCopyValues();
      }}
    >
      <ModalHeader
        closeButton
        toggle={() => {
          setShowCloneModal(false);
        }}
      >
        Clone Schedule Configuration
      </ModalHeader>
      <ModalBody>
        <div>
          <div>
            <Label htmlFor="interviewDateDropdown">Interview Date to Clone</Label>
            <Input
              disabled={isSubmitting}
              type="select"
              onChange={(e) => {
                setInterviewDateToClone(JSON.parse(e.target.value));
              }}
            >
              <option value={null} disabled selected>
                Select Interview Date
              </option>
              {interviewDates.map((interviewDate) => {
                if (interviewDate.pk_InterviewDate != scheduleID) {
                  return (
                    <option value={JSON.stringify(interviewDate)}>{`(${interviewDate.pk_InterviewDate}) ${moment
                      .tz(interviewDate.DateOfInterview, null)
                      .format('MMM DD, YYYY')} - ${interviewDate.Label || '(No Label)'}`}</option>
                  );
                }
              })}
            </Input>
          </div>
          <br />
          <br />
          <div style={{ ...style.spacedBetweenRow, alignItems: null }}>
            <div style={{ ...style.simpleColumn, width: '45%', padding: 10, alignItems: 'top' }}>
              <div style={style.simpleRow}>
                <FontAwesomeIcon
                  icon={willCopyStartTime ? faCheckSquare : faSquare}
                  style={style.enabledCheckbox}
                  onClick={() => {
                    setWillCopyStartTime(!willCopyStartTime);
                  }}
                />
                <div>Start</div>
              </div>
              <div style={style.simpleRow}>
                <FontAwesomeIcon
                  icon={willCopyInterviewDuration ? faCheckSquare : faSquare}
                  style={style.enabledCheckbox}
                  onClick={() => {
                    setWillCopyInterviewDuration(!willCopyInterviewDuration);
                  }}
                />
                <div>Duration</div>
              </div>
              <div style={style.simpleRow}>
                <FontAwesomeIcon
                  icon={willCopyPassingDuration ? faCheckSquare : faSquare}
                  style={style.enabledCheckbox}
                  onClick={() => {
                    setWillCopyPassingDuration(!willCopyPassingDuration);
                  }}
                />
                <div>Passing</div>
              </div>
              <br />
              <div style={style.simpleRow}>
                <FontAwesomeIcon
                  icon={willCopyHeaders ? faCheckSquare : faSquare}
                  onClick={() => {
                    setWillCopyFlexEventEvaluators(!willCopyFlexEventEvaluators);
                    setWillCopyTimeSlots(!willCopyHeaders);
                    setWillCopyHeaders(!willCopyHeaders);
                    setWillCopyFocuses(!willCopyHeaders);
                  }}
                  style={style.enabledCheckbox}
                />
                <div>Headers/Evaluators</div>
              </div>
              <div style={{ ...style.simpleColumn, marginLeft: 15 }}>
                <div style={style.simpleRow}>
                  <FontAwesomeIcon
                    onClick={() => {
                      if (willCopyHeaders) {
                        setWillCopyFocuses(!willCopyFocuses);
                      }
                    }}
                    icon={willCopyFocuses ? faCheckSquare : faSquare}
                    style={willCopyHeaders ? style.enabledCheckbox : style.disabledCheckbox}
                  />
                  <div>Focuses</div>
                </div>
              </div>
              <div style={style.simpleRow}>
                <FontAwesomeIcon
                  icon={willCopyTimeSlots ? faCheckSquare : faSquare}
                  style={style.enabledCheckbox}
                  onClick={() => {
                    setWillCopyEvaluators(!willCopyFlexEvents);
                    setWillCopyFlexEventEvaluators(!willCopyTimeSlots);
                    setWillCopyTimeSlots(!willCopyTimeSlots);
                  }}
                />
                <div>Time Slots</div>
              </div>

              <div style={style.simpleRow}>
                <div style={{ ...style.simpleColumn, marginLeft: 15 }}>
                  <div style={style.simpleRow}>
                    <FontAwesomeIcon
                      icon={willCopyTimeSlots ? faCheckSquare : faSquare}
                      style={style.disabledCheckbox}
                    />
                    <div>Custom Durations</div>
                  </div>

                  <div style={style.simpleRow}>
                    <FontAwesomeIcon
                      icon={willCopyTimeSlots ? faCheckSquare : faSquare}
                      style={style.disabledCheckbox}
                    />
                    <div>Custom Events</div>
                  </div>
                </div>
              </div>
            </div>
            <div style={{ ...style.simpleColumn, width: '45%', padding: 10, alignItems: 'top' }}>
              <div style={style.simpleRow}>
                <FontAwesomeIcon
                  icon={willCopyCandidateAssignments ? faCheckSquare : faSquare}
                  style={style.enabledCheckbox}
                  onClick={() => {
                    setWillCopyCandidateAssignments(!willCopyCandidateAssignments);
                  }}
                />
                <div>Candidate Assignments</div>
              </div>

              <div style={style.simpleRow}>
                <FontAwesomeIcon
                  icon={willCopyFlexEvents ? faCheckSquare : faSquare}
                  style={style.enabledCheckbox}
                  onClick={() => {
                    setWillCopyEvaluators(!willCopyFlexEvents);
                    setWillCopyFlexEvents(!willCopyFlexEvents);
                  }}
                />
                <div>Flex Events</div>
              </div>

              <div style={style.simpleRow}>
                <div style={{ ...style.simpleColumn, marginLeft: 15 }}>
                  <div style={style.simpleRow}>
                    <FontAwesomeIcon
                      icon={willCopyEvaluators ? faCheckSquare : faSquare}
                      style={willCopyFlexEvents ? style.enabledCheckbox : style.disabledCheckbox}
                      onClick={() => {
                        if (willCopyFlexEvents) {
                          setWillCopyEvaluators(!willCopyEvaluators);
                        }
                      }}
                    />
                    <div>Evaluators</div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div style={{ ...style.simpleRow, alignItems: 'center', justifyContent: 'center', marginTop: 20 }}>
            <Button
              disabled={interviewDateToClone == null || isCloning}
              style={{ width: 150 }}
              color="success"
              onClick={() => {
                Swal.fire({
                  title: 'Warning!',
                  text: `Cloning will overwrite the current schedule's configuration and values (depending on the checked items). Are you sure you want to continue?`,
                  type: 'warning',
                  showCancelButton: true,
                  confirmButtonColor: 'red',
                  confirmButtonText: `Yes`,
                }).then((result) => {
                  if (result.value) {
                    cloneFromExistingSchedule();
                  } else {
                    setShowCloneModal(false);
                  }
                });
              }}
            >
              Clone
            </Button>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};
export default CloneModal;
