import React, { useContext, useEffect, useState } from 'react';
import style from './style.js';
import { DepartmentContext } from '../../../../../../DepartmentWrapper';
import { fetchDataAgnostic } from '../../../../../../Services/dataApi.js';
import { formatBearerToken } from '../../../../../../Common.functions.js';
import { useAuth0 } from '../../../../../../auth0/reactAuth0Spa.js';
import AdminSentItems from '../../../AdminCommunications/AdminCommunicationHistory/AdminSentItems/AdminSentItems';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import AdminEmailPreview from '../../../AdminCommunications/AdminCommunicationHistory/AdminEmailPreview/AdminEmailPreview';

const Communication = ({ selectedCandidate, columnSentItems }) => {
  const [sentMessages, setSentMessages] = useState([]);
  const [selectedSentItem, setSelectedSentItem] = useState();
  const [showPreview, setShowPreview] = useState(false);
  const [abortController, setAbortController] = useState();
  const dContext = useContext(DepartmentContext);
  const { getTokenSilently } = useAuth0();

  useEffect(() => {
    if (selectedCandidate) {
      if (abortController) {
        abortController.abort();
      }
      const newAbortController = new AbortController();
      setAbortController(newAbortController);

      // setTimeout(() => {
      getSentMessages(newAbortController);
      // }, 500);
    }
  }, [selectedCandidate]);

  const getSentMessages = (abortController) => {
    getTokenSilently()
      .then((token) => {
        fetchDataAgnostic(
          'department/communication/history',
          {
            pk_Department: dContext.department.pk_Department,
            pk_Season: dContext.season.pk_Season,
            pk_Candidate: selectedCandidate.pk_Candidate,
          },
          formatBearerToken(token),
          { abortController: abortController },
        )
          .then((res) => {
            setAbortController(null);
            setSentMessages(res.data);
          })
          .catch((err) => {
            setAbortController(null);
            return false;
          });
      })
      .catch((err) => {
        setAbortController(null);
      });
  };

  const renderPreviewModal = () => {
    return (
      <Modal isOpen={showPreview} centered={true} style={{ maxWidth: '60%' }}>
        <ModalHeader
          closeButton
          toggle={() => {
            setShowPreview(!showPreview);
          }}
        >
          Preview
        </ModalHeader>
        <ModalBody>
          <AdminEmailPreview itemToPreview={selectedSentItem} />
        </ModalBody>
      </Modal>
    );
  };

  return (
    <div
      style={{
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        verticalAlign: 'middle',
      }}
    >
      <AdminSentItems
        columnSentItems={columnSentItems}
        activeDepartment={dContext.department}
        selectedSentItem={selectedSentItem}
        setSelectedSentItem={setSelectedSentItem}
        sentMessages={sentMessages}
        forcedFilterCandidate={selectedCandidate}
        showFilter={false}
        onClick={() => {
          setShowPreview(true);
        }}
      />
      {renderPreviewModal()}
    </div>
  );
};

export default Communication;
