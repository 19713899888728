import React, { Component } from 'react';
import { NodeGroup } from 'react-move';
import { insertNumber } from '../../SortableList';
import { List, Wrapper } from './ListAnimation.style';
import moment from 'moment-timezone';
import { clone } from 'lodash';

function getTime(time) {
  const result = 1 - Math.pow(2, -10 * time);
  return result;
}

function createNumberedArray(num) {
  let numberedArray = [];
  for (let i = 0; i < num; i++) {
    numberedArray.push(i);
  }
  return numberedArray;
}

function updateOrder(arr, beg, end) {
  const copy = arr.slice(0);
  const val = copy[beg];
  copy.splice(beg, 1);
  copy.splice(end, 0, val);
  return copy;
}

function clamp(n, min, max) {
  return Math.max(Math.min(n, max), min);
}

const itemHeight = 75; // set list-item height and line-height in css as well

// Much of this was impoted from a pre-existing example and tweaked for current use
class ListAnimations extends Component {
  constructor(props) {
    super(props);
    const active = props.listArray.filter((item) => item.selected);

    this.state = {
      topDeltaY: 0,
      mouseY: 0,
      isPressed: false,
      lastPressed: 0,
      selected: props.hasPreferenceOrderWithNoInterviewDate ? 0 : active.length,
      stopScrolling: false,
      order: createNumberedArray(props.listArray.length),
      listArray: props.listArray,
    };
  }

  addItemsIfUserHasPreference() {
    const { hasPreferenceOrderWithNoInterviewDate, preferenceItems } = this.props;
    let indexesToAdd = [];

    // need to find the user's selections so we can add them to the selected list
    //record the index number and the preference number so we can sort index by preference order
    this.props.listArray.forEach((item, i) => {
      let { preferenceOrder, isSelected } = item;
      if (isSelected) {
        indexesToAdd.push({ index: i, preferenceOrder, preferenceOrder });
      }
    });

    indexesToAdd.sort((a, b) => {
      if (a.preferenceOrder > b.preferenceOrder) {
        return 1;
      } else {
        return -1;
      }
    });

    if (indexesToAdd.length === 0) {
      return;
    }

    // add selected items
    indexesToAdd.forEach((item) => {
      const { index, preferenceOrder } = item;
      setTimeout(() => {
        const response = this.add(index);
        this.setState({ listArray: response });
      }, 20);
    });
  }

  // add touchmove event for iphones
  componentDidMount() {
    this.addItemsIfUserHasPreference();

    window.addEventListener('touchmove', this.handleTouchMove, {
      passive: false,
    });
  }

  componentWillUnmount() {
    window.removeEventListener('touchmove', this.handleTouchMove, {
      passive: true,
    });
  }

  handleTouchStart = (pos, pressY, { touches: [{ pageY }] }) => {
    this.setState({
      topDeltaY: pageY - pressY,
      mouseY: pressY,
      stopScrolling: true,
      isPressed: true,
      lastPressed: pos,
    });

    window.addEventListener('touchend', this.handleTouchEnd);
  };

  handleTouchMove = (e) => {
    if (!this.state.stopScrolling) {
      return;
    }
    e.preventDefault();
    window.scroll = false;
    this.handleMouseMove(e.touches[0]);
  };

  handleMouseDown = (pos, pressY, { pageY }) => {
    this.setState({
      topDeltaY: pageY - pressY,
      mouseY: pressY,
      isPressed: true,
      lastPressed: pos,
    });

    window.addEventListener('mousemove', this.handleMouseMove);
    window.addEventListener('mouseup', this.handleMouseUp);
  };

  handleMouseMove = ({ pageY }) => {
    const { isPressed, topDeltaY, lastPressed, selected } = this.state;
    const { setOrder, order } = this.props;
    if (isPressed) {
      const mouseY = pageY - topDeltaY;
      // change value at end to restrict list
      const currentRow = clamp(Math.round(mouseY / itemHeight), 0, selected - 1);
      let newOrder = order;

      if (currentRow !== order.indexOf(lastPressed)) {
        newOrder = updateOrder(order, order.indexOf(lastPressed), currentRow);
      }

      this.setState({ mouseY });
      setOrder(newOrder);
    }
  };

  handleMouseUp = () => {
    this.setState({ isPressed: false, topDeltaY: 0 });
    window.removeEventListener('mouseup', this.handleMouseUp);
    window.removeEventListener('mousemove', this.handleMouseMove);
    this.updateSelectionOrder();
  };

  handleTouchEnd = () => {
    this.setState({ isPressed: false, topDeltaY: 0, stopScrolling: false });
    window.removeEventListener('touchend', this.handleTouchEnd);
    this.updateSelectionOrder();
    return true;
  };

  // removes from selected (sets item.selected = false)
  remove = (item) => {
    const { selected } = this.state;
    const { setListArray, setOrder, order, listArray } = this.props;
    const itemSelected = listArray.slice(0);
    itemSelected[item].selected = false;
    // delete itemSelected[item].recordId;
    const newOrder = order.filter((num) => item !== num);
    const newList = insertNumber(newOrder, item, selected - 1);

    // selected currently decides insert position and number of elements which can be drag-and-dropped
    this.updateSelectionOrder(newOrder, itemSelected);
    setOrder(newList);
    setListArray(itemSelected);
    this.setState({ order: newList, selected: selected - 1 });
  };

  // updates order of selection.  If variables aren't passed in just uses state variables
  updateSelectionOrder = (newOrder, itemsSelected) => {
    const { setListArray, listArray, order } = this.props;
    // grab state variables if nothing was passedin
    const orderToBeCompared = newOrder ? newOrder : order;
    const itemToBeAltered = itemsSelected ? itemsSelected : listArray.slice();
    orderToBeCompared.forEach((num) => {
      itemToBeAltered[num].preference = orderToBeCompared.indexOf(num);
    });

    setListArray(itemToBeAltered);
  };

  // adds to selected (sets item.selected = true)
  add = (item) => {
    const { selected } = this.state;
    const { setListArray, order, listArray, setOrder } = this.props;
    const itemSelected = listArray.slice(0);
    itemSelected[item].selected = true;
    const newOrder = order.filter((num) => item !== num);
    newOrder.splice(selected, 0, item);
    this.updateSelectionOrder(newOrder, itemSelected);
    setOrder(newOrder);
    setListArray(itemSelected);
    // selected currently decides insert position and number of elements which can be drag-and-dropped
    this.setState({ selected: selected + 1 });
    return itemSelected;
  };

  // handles click when items are selectable.  This should be different from handle click which will handle selection events
  handleClickTransfer = (direction, item) => {
    if (direction === 'add') {
      this.add(item);
    } else if (direction === 'remove') {
      this.remove(item);
    }
  };

  // intended to handle click events where the item is being selected
  handleClick = (item) => {};
  render() {
    const { mouseY, isPressed, lastPressed } = this.state;
    const { clickable, HTMLComponent, isOrdinal, listArray, order, singleDateChoice } = this.props;

    if (!listArray || listArray.length === 0) {
      return <div />;
    }

    listArray.forEach((item) => {
      item.MomentDateOfInterview = moment(
        `${moment(item.DateOfInterview).format('MMM DD, YYYY')} ${item.StartTime}`,
        null,
      );
      item.formattedDate = item.MomentDateOfInterview.format('MMM DD, YYYY hh:mm A');
    });

    const sortedListArray = listArray.sort((a, b) => {
      if (a.MomentDateOfInterview.isBefore(b.MomentDateOfInterview)) {
        return -1;
      } else if (a.MomentDateOfInterview.isAfter(b.MomentDateOfInterview)) {
        return 1;
      } else {
        return 0;
      }
    });

    return (
      <Wrapper>
        <NodeGroup
          data={createNumberedArray(listArray.length)}
          keyAccessor={(d) => `${d}`}
          start={(d) => {
            return {
              scale: 1,
              shadow: 1,
              y: order.indexOf(d) * itemHeight,
            };
          }}
          update={(d) => {
            const dragging = lastPressed === d && isPressed;
            return {
              scale: [dragging ? 1.1 : 1],
              shadow: [dragging ? 5 : 1],
              y: [order.indexOf(d) * itemHeight],
              timing: { duration: 350, ease: getTime },
            };
          }}
        >
          {(nodes) => (
            <List height={`${listArray.length * itemHeight - 5}`}>
              {nodes.map(({ key, data, state, keyAccessor }, i) => {
                return (
                  <HTMLComponent
                    listKey={key}
                    singleDateChoice={singleDateChoice}
                    key={key}
                    data={data}
                    state={state}
                    keyAccessor={keyAccessor}
                    lastPressed={lastPressed}
                    isPressed={isPressed}
                    mouseY={mouseY}
                    clickable={clickable}
                    listArray={sortedListArray}
                    handleClick={this.handleClick}
                    handleClickTransfer={this.handleClickTransfer}
                    handleMouseDown={this.handleMouseDown}
                    handleTouchStart={this.handleTouchStart}
                    handleTouchEnd={this.handleTouchEnd}
                    handleMouseUp={this.handleMouseUp}
                    isOrdinal={isOrdinal}
                  />
                );
              })}
            </List>
          )}
        </NodeGroup>
      </Wrapper>
    );
  }
}

export default ListAnimations;
