import React, { useEffect, useState, useContext } from 'react';
import { Redirect } from 'react-router-dom';
import { fetchDataAgnostic } from '../../Services/dataApi';
import { setCorrectEvalToken, evaluatorTypeEnum } from '../../Common.functions';
import EvaluatorRedirect from '../EvaluatorRedirect';
import { DepartmentContext } from '../../DepartmentWrapper';

const EvalTokenSetter = ({ match, setIsAdmin }) => {
  const [redirect, setRedirect] = useState(false);
  const dContext = useContext(DepartmentContext);

  const getQueryString = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const isAdmin = urlParams.get('admin');
    if (isAdmin && isAdmin === 'true') {
      setIsAdmin(true);
      setCorrectEvalToken(isAdmin === 'true', match.params.id);
    } else {
      setCorrectEvalToken(isAdmin === 'true', match.params.id);
    }
  };
  useEffect(() => {
    getQueryString();
    setRedirect(true);
  }, []);
  if (redirect) {
    return <EvaluatorRedirect type="evaluator" />;
  }
  return <div></div>;
};

export default EvalTokenSetter;
