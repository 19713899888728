import { faChevronCircleLeft, faChevronCircleRight, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { Button, CustomInput, Popover, PopoverBody, PopoverHeader, UncontrolledTooltip } from 'reactstrap';
import { useAuth0 } from '../../../../auth0/reactAuth0Spa';
import Swal from 'sweetalert2';
import { formatBearerToken } from '../../../../Common.functions';
import { DepartmentContext } from '../../../../DepartmentWrapper';
import { fetchDataAgnostic, postDataAgnostic } from '../../../../Services/dataApi';
import AdminRegisteredCandidates from './AdminRegeresteredCandidates/AdminRegeresteredCandidates';
import style from './AdminWaitlist.style';
import './AdminWaitlist.style.css';
import AdminWaitlistedCandidates from './AdminWaitlistedCandidates/AdminWaitlistedCandidates';
import ManageWaitlistDatePicker from './DatePicker/ManageWaitlistDatePicker';

let getDateWaitTimer = null;

const AdminManageWaitlist = ({ interviewDates, candidates, activeDepartment, token }) => {
  const [myInterviewDates, setMyInterviewDates] = useState([]);
  const [selectedDate, setSelectedDate] = useState();
  const [waitlistedCandidates, setWaitlistedCandidates] = useState([]);
  const [registeredCandidates, setRegisteredCandidates] = useState([]);

  const [showDatePicker, setShowDatePicker] = useState(false);
  const [showRegistered, setShowRegistered] = useState(false);
  const [isFetchingCandidates, setIsFetchingCandidates] = useState(false);

  const dContext = useContext(DepartmentContext);
  const { getTokenSilently } = useAuth0();

  useEffect(() => {
    if (interviewDates && interviewDates.length > 0) {
      setMyInterviewDates(interviewDates);
      if (selectedDate == null) {
        setSelectedDate(interviewDates[0]);
      }
    }
  }, [interviewDates]);

  useEffect(() => {
    if (selectedDate) {
      clearTimeout(getDateWaitTimer);

      getDateWaitTimer = setTimeout(() => {
        setWaitlistedCandidates([]);
        setRegisteredCandidates([]);
        getCandidates();
      }, 1000);
    }
  }, [selectedDate]);

  useEffect(() => {}, [candidates]);

  const getCandidates = () => {
    setIsFetchingCandidates(true);
    getTokenSilently()
      .then((token) => {
        fetchDataAgnostic(
          'candidate/interviewDate/status',
          { pk_Department: dContext.department.pk_Department, pk_InterviewDate: selectedDate.pk_InterviewDate },
          formatBearerToken(token),
        )
          .then((res) => {
            setIsFetchingCandidates(false);
            if (res.data) {
              setWaitlistedCandidates(res.data.waitlistedCandidates);
              setRegisteredCandidates(res.data.scheduledCandidates);
            }
            // setIsLoading(false);
          })
          .catch((err) => {
            console.log('getCandidates err: ', err);
            setIsFetchingCandidates(false);
          });
      })
      .catch((err) => {});
  };

  const goToNextInterviewDate = (direction) => {
    const guiltyIndex = myInterviewDates.findIndex((interviewDate) => {
      return interviewDate.pk_InterviewDate == selectedDate.pk_InterviewDate;
    });
    let newIndex = guiltyIndex + direction;
    if (guiltyIndex >= 0) {
      if (newIndex >= myInterviewDates.length) {
        newIndex = 0;
      } else if (newIndex < 0) {
        newIndex = myInterviewDates.length - 1;
      }
      setSelectedDate(myInterviewDates[newIndex]);
    }
  };

  const renderDatePicker = () => {
    return (
      <Popover
        flip={false} // popperClassName="datePicker"
        // placement="bottom"
        placementPrefix="datePicker bs-popover"
        isOpen={showDatePicker}
        trigger="legacy"
        target={`date_picker_btn`}
        toggle={() => {
          setShowDatePicker(!showDatePicker);
        }}
      >
        <PopoverHeader>
          <div style={style.spacedBetweenRow}>
            <div style={{ width: '100%' }}>Interview Date Picker</div>
            <div>
              <FontAwesomeIcon
                icon={faTimesCircle}
                style={style.clickable}
                onClick={(e) => {
                  e.stopPropagation();
                  setShowDatePicker(false);
                }}
              />
            </div>
          </div>
        </PopoverHeader>
        <PopoverBody>
          <ManageWaitlistDatePicker
            interviewDates={myInterviewDates}
            setSelectedDate={setSelectedDate}
            selectedDate={selectedDate}
            setShowDatePicker={setShowDatePicker}
          />
        </PopoverBody>
      </Popover>
    );
  };

  const slotForDates = (pk_InterviewDate = null) => {
    getTokenSilently()
      .then((token) => {
        postDataAgnostic(
          'candidate/slot',
          {
            pk_Department: dContext.department.pk_Department,
            pk_Season: dContext.season.pk_Season,
            pk_InterviewDate: pk_InterviewDate,
          },
          null,
          formatBearerToken(token),
        )
          .then((res) => {
            if (res.data && res.data.dateLocked) {
              return Swal.fire('Oops...', 'The selected date is currently locked and cannot be slotted', 'error');
            }
            return Swal.fire(
              'Success',
              `${pk_InterviewDate ? 'Selected date has' : 'All unlocked dates have'} been slotted`,
              'success',
            );
          })
          .catch((err) => {
            return Swal.fire('Something went wrong...', 'Please try again or contact support', 'error');
          });
      })
      .catch((err) => {});
  };

  let btnColor = 'primary';

  if (selectedDate && moment(selectedDate.DateOfInterview).isBefore(moment())) {
    btnColor = 'danger';
  }

  return (
    <div style={style.simpleCol}>
      <div style={style.simpleRow}>
        <div style={{ ...style.simpleColumn, width: '15%' }}></div>
        <div style={{ ...style.simpleColumn, width: '70%' }}>
          <div style={{ ...style.simpleRow, justifyContent: 'center' }}>
            <Button
              disabled={isFetchingCandidates}
              color="primary"
              onClick={() => {
                goToNextInterviewDate(-1);
              }}
            >
              <FontAwesomeIcon icon={faChevronCircleLeft} />
            </Button>

            <Button
              disabled={isFetchingCandidates}
              id="date_picker_btn"
              color={btnColor}
              className="light-blue-border-hover"
              style={{ marginRight: 10, marginLeft: 10, minWidth: 550, border: '6px solid rgba(0, 0, 0, 0)' }}
            >
              {`${selectedDate ? moment(selectedDate.DateOfInterview).format('dddd, MMMM DD, YYYY') : 'No Dates'} ${
                selectedDate && selectedDate.Label ? selectedDate.Label : ''
              }`}
            </Button>
            <Button
              disabled={isFetchingCandidates}
              color="primary"
              onClick={() => {
                goToNextInterviewDate(1);
              }}
            >
              <FontAwesomeIcon icon={faChevronCircleRight} />
            </Button>
          </div>
        </div>
        <div style={{ ...style.simpleColumn, width: '15%' }}></div>
      </div>

      <div style={{ ...style.simpleColumn, height: 'calc(100vh - 340px)', overflowY: 'scroll' }}>
        <div style={style.simpleRow}>
          <div style={{ ...style.simpleColumn, width: '50%', marginRight: 10, alignItems: 'flex-end' }}>
            <AdminWaitlistedCandidates
              candidates={waitlistedCandidates}
              interviewDate={selectedDate}
              getCandidates={getCandidates}
              showRegistered={showRegistered}
              setShowRegistered={setShowRegistered}
              isFetchingCandidates={isFetchingCandidates}
            />
          </div>
          <div style={{ ...style.simpleColumn, width: '50%', alignItems: 'flex-start' }}>
            <AdminRegisteredCandidates
              candidates={registeredCandidates}
              interviewDate={selectedDate}
              showRegistered={showRegistered}
              isFetchingCandidates={isFetchingCandidates}
            />
          </div>
        </div>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <div style={{ display: 'flex', padding: '10px' }}>
            <Button
              id="slotSelectedDate"
              disabled={waitlistedCandidates.length === 0}
              onClick={(e) => {
                e.preventDefault();
                slotForDates(selectedDate.pk_InterviewDate);
              }}
              color="primary"
            >
              Slot Selected Date
              <UncontrolledTooltip placement="top" target="slotSelectedDate">
                Triggers automated slotting for the selected date
              </UncontrolledTooltip>
            </Button>
            <div style={{ width: '15px' }}></div>
            <Button
              id="slotAllDate"
              disabled={waitlistedCandidates.length === 0}
              onClick={(e) => {
                e.preventDefault();
                slotForDates(null);
              }}
              color="primary"
            >
              Slot All Dates
              <UncontrolledTooltip placement="top" target="slotAllDate">
                Triggers automated slotting for all dates
              </UncontrolledTooltip>
            </Button>
          </div>
        </div>
      </div>
      {renderDatePicker()}
    </div>
  );
};

export default AdminManageWaitlist;
