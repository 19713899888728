/* eslint-disable no-console */
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import Popup from 'reactjs-popup';
import styled from 'styled-components';

const Cover = styled.div`
  position: relative;
  left: -12px;
  height: 2px;
  top: -1px;
`;

const TimeTrackerCircle = styled.div`
  position: relative;
  top: calc(50% - 5px);
  left: -6px;
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  border-radius: 12px;
  border: 1px solid rgb(51, 51, 51);
  width: 10px;
  height: 10px;
`;

const createTimeTracker = (heights, i, difference, color, coverBorder, needsPadding, isPassingPeriod) => {
  const padding = needsPadding ? '12px 0 0 20px' : 0;

  const backgroundColor = difference && difference.height && difference.height !== 0 ? 'rgb(12, 116, 137)' : null;

  return (
    <td style={{ padding, position: 'relative' }}>
      <div
        style={{
          display: 'flex',
          position: 'absolute',
          left: '50%',
          justifyContent: 'center',
          height: heights ? heights[i] + 'px' : 'auto',
          padding: 0,
        }}
      >
        <div className="difference" style={difference} />
        {heights && !isPassingPeriod && heights[i] > 0 && <TimeTrackerCircle style={{ backgroundColor }} />}
        <Cover style={{ border: coverBorder }} />
      </div>
    </td>
  );
};

const PopupWithCloseButton = ({ closeOnDocumentClick, open, closeFunction, children }) => {
  return (
    <Popup closeOnDocumentClick={closeOnDocumentClick} open={open} onClose={() => closeFunction(false)}>
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <div onClick={() => closeFunction(false)} style={{ padding: '5px 10px 5px 10px', cursor: 'pointer' }}>
          <FontAwesomeIcon icon={faTimes} />
        </div>
      </div>
      {children}
    </Popup>
  );
};

const Circle = styled.div`
  position: absolute;
  bottom: 10%;
  right: 10px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  border: 1px solid rgb(51, 51, 51);
  background-color: #cc0000;
  width: 30px;
  height: 30px;
`;

/* eslint-enable */

export { createTimeTracker, PopupWithCloseButton, Circle };
