const style = {
  simpleRow: {
    display: 'flex',
    flexDirection: 'row',
  },
  simpleColumn: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  spacedBetweenRow: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  centeredText: {
    textAlign: 'center',
    width: '100%',
  },
  actionButtonsContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    marginTop: 5,
    padding: '0 10px',
    zIndex: 5,
  },
  actionButton: {
    marginRight: 10,
  },

  inputContainer: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 10,
  },
  templateLabelContainer: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    minWidth: 100,
    verticalAlign: 'middle',
    width: 100,
  },
  templateLabel: {
    alignItems: 'center',
    fontWeight: 'bold',
    paddingRight: 10,
    textAlign: 'right',
    verticalAlign: 'middle',
    width: '100%',
    fontSize: 13,
  },
  templateInputContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    minWidth: 200,
  },
  templateInput: {
    width: 'calc(100%-80px)',
  },
};
export default style;
