import { faCloudUploadAlt, faPortrait, faSpinner, faTrash, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import Swal from 'sweetalert2';
import { useAuth0 } from '../../../auth0/reactAuth0Spa';
import { formatBearerToken, timer } from '../../../Common.functions';
import { DepartmentContext } from '../../../DepartmentWrapper';
import { postDataAgnostic } from '../../../Services/dataApi';
import { clone } from '../../../Services/schedule';
import UserPhotoTypes from './UserPhotoTypes';
import axios from 'axios';
import ConfirmModal from 'Body/RezRATE/Coordinator/Schedule/ConfirmModal';

const { AdminCandidate, AdminEvaluator, Candidate, DepartmentLogo, OrganizationLogo } = UserPhotoTypes;

const endpointData = {};
endpointData[AdminCandidate] = {
  url: 'files/admin/candidate/photo',
};
endpointData[AdminEvaluator] = {
  url: 'files/admin/user/photo',
};
endpointData[Candidate] = {
  url: 'files/candidate/photo',
};
endpointData[DepartmentLogo] = {
  url: 'department/season/department/logo',
};
endpointData[OrganizationLogo] = {
  url: 'organization/logo',
};

async function checkImage(url, callback) {
  if (url) {
    callback(true);
    return true;
  }
  callback(false);
  return false;
  // Check the response status

  // var request = new XMLHttpRequest();
  // return new Promise((resolve, reject) => {
  //   request.open('GET', url, true);
  //   request.send();
  //   request.onload = function() {
  //     if (request.status == 200) {
  //       //if(statusText == OK)
  //       callback(true);
  //       return true;
  //     } else {
  //       callback(false);

  //       return false;
  //     }
  //   };
  // });
}

// when using this component use the type in the adjacent file UserPhotoTypes.js to pass in the type
const UserPhoto = ({
  match,
  id,
  photoUrl,
  callback,
  type,
  imgStyle,
  setDeptConfig,
  externalAPI = false,
  pk_Organization,
  pk_Department,
  handleDelete,
  showDeleteButton = true,
}) => {
  const [file, setFile] = useState([]);
  const [userPhotoUrl, setUserPhotoUrl] = useState(null);
  const [userId, setUserId] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const [hasDroppedFile, setHasDroppedFile] = useState(false);

  const dContext = useContext(DepartmentContext);

  const { getTokenSilently } = useAuth0();

  const needsAuth = type !== Candidate;

  // setting the variable locally becuase I don't want to have to reply on the callback updating the photoUrl variable.
  // this way we can set it locally when it's posted and make sure it's always changed to the current
  useEffect(() => {
    setUserPhotoUrl(photoUrl);
  }, [photoUrl]);

  useEffect(() => {
    if (id) {
      setUserId(id);
    }
  }, [id]);

  const formatAuth = (token) => {
    if (token) {
      return formatBearerToken(token);
    } else {
      return {};
    }
  };

  const handleUriPropagation = async (uri) => {
    await checkImage(uri, (photoIsReady) => {
      if (!photoIsReady) {
        return false;
      }

      if (setDeptConfig) {
        setDeptConfig((prevState) => {
          // eslint-disable-next-line
          let clone = structuredClone(prevState);
          clone.DepartmentLogo = uri;
          return clone;
        });
      }
      // setTimeout(() => {
      setUserPhotoUrl(uri);
      setIsUploading(false);

      // provide parent componets with id and photo url if they need it
      if (callback) {
        callback(id, uri);
      }
      return true;
    });
  };

  const postPhoto = async (fileData, mimeType) => {
    setIsUploading(true);
    let token = null;

    if (needsAuth) {
      token = await getTokenSilently();
    }

    let seasonPk = null;
    let departmentPk = null;

    if (dContext) {
      if (dContext.season && dContext.season.pk_Season) {
        seasonPk = dContext.season.pk_Season;
      }
      if (dContext.department && dContext.department.pk_Department) {
        departmentPk = dContext.department.pk_Department;
      }
    }

    if (pk_Department) {
      departmentPk = pk_Department;
    }

    postDataAgnostic(
      endpointData[type].url,
      { pk_Organization, pk_Department: departmentPk, pk_Season: seasonPk, userId: id, mimeType },
      fileData,
      {
        'content-type': 'multipart/form-data',
        ...formatAuth(token),
      },
    )
      .then(async (result) => {
        const { uri } = result.data;
        let count = 0;
        let imageHasLoaded = false;

        while (!imageHasLoaded || count > 10) {
          try {
            imageHasLoaded = handleUriPropagation(uri);
            if (!imageHasLoaded) {
              await timer(200);
              count += 1;
            } else {
              return;
            }
          } catch (err) {}
          count += 1;
        }

        // NOTE ON THIS TIMEOUT: for some reason, when uploading fo azure blob storage and mounting the returned image url,
        // ocassionally it appears that the image is not ready to be mounted. As a quick-fix for this, we're implementing
        // a timeout to allow the image time to become an available resource. This is not ideal
        // setTimeout(() => {

        // }, 1000);

        // }, 2000);
      })
      .catch((err) => {
        setIsUploading(false);
      });
  };

  const singleCandidateObject = (files) => {
    const formData = new FormData();
    for (var i in files) {
      formData.append(`File`, files[i]);
      formData.append('w', 1);
      formData.append('h', 1);
      formData.append('x', 1);
      formData.append('y', 1);
    }
    return formData;
  };

  const initiateReplacement = (acceptedFiles) => {
    const incorrectLength = acceptedFiles.length !== 1;
    const incorrectBytes = acceptedFiles[0].size > 5000000;
    if (incorrectBytes) {
      Swal.fire('Oops...', `It looks like the file might be a little too big, make sure it's under 5mb`, 'error');
      return;
    }

    if (incorrectLength) {
      Swal.fire('Oops,', 'Please make sure you only upload a single image file', 'error');
      return;
    }

    const fileData = singleCandidateObject(acceptedFiles);
    const { type } = acceptedFiles[0];

    postPhoto(fileData, type);
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: ['image/png', 'image/jpg', 'image/jpeg'],
    onDrop: (acceptedFiles) => {
      setHasDroppedFile(true);
      if (!acceptedFiles || acceptedFiles.length <= 0) {
        Swal.fire({
          title: 'Invalid File Type',
          icon: 'error',
          type: 'error',
          text: 'Please Use PNG or JPG',
        });
      } else if (photoUrl) {
        Swal.fire({
          title: 'Warning: Uploading a new photo will replace the current one',
          showDenyButton: true,
          showCancelButton: true,
          confirmButtonText: `Replace`,
          denyButtonText: `Don't Replace`,
        }).then((result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.value) {
            initiateReplacement(acceptedFiles);
          }
        });
      } else {
        initiateReplacement(acceptedFiles);
      }
    },
  });

  const imageStyle = imgStyle ? imgStyle : { width: 100, height: 100, objectFit: 'contain' };

  if (isUploading) {
    return (
      <div
        style={{
          width: 'clamp(102px, 100%, 100%)',
          border: '1px dashed black',
          height: '200px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <FontAwesomeIcon icon={faSpinner} size="2x" spin />
      </div>
    );
  }

  return (
    <div
      className="hover_blue"
      {...getRootProps()}
      style={{
        width: 'clamp(102px, 100%, 100%)',
        border: '1px dashed black',
        position: 'relative', // Add this line
      }}
    >
      <input {...getInputProps()} />
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          cursor: 'pointer',
          justifyContent: 'center',
          flexDirection: 'column',
          // minHeight: '50vh',
          minHeight: 100,
          textAlign: 'center',
        }}
      >
        {userPhotoUrl && (
          <div
            style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              backgroundImage: 'url()',
              justifyContent: 'center',
              backgroundColor: 'gray',
              position: 'relative', // Add this line
            }}
          >
            <img src={userPhotoUrl} alt="" style={imageStyle} />
            {handleDelete ? (
              <div
                className="hover_blue"
                style={{
                  position: 'absolute', // Change this line
                  bottom: 2, // Add this line
                  right: 2, // Add this line
                  backgroundColor: 'white',
                  padding: 2,
                  borderRadius: 20,
                  width: 25,
                  height: 25,
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  Swal.fire({
                    title: 'Are you sure you want to delete this image?',
                    cancelButtonText: 'Delete',
                    showCancelButton: true,
                    cancelButtonColor: 'red',
                    confirmButtonText: `Cancel`,
                    confirmButtonColor: 'gray',
                  }).then((result) => {
                    if (result.isDismissed) {
                      if (handleDelete) {
                        handleDelete();
                      }
                      // handleDeletePhoto();
                    }
                  });
                }}
              >
                {' '}
                <FontAwesomeIcon
                  icon={faTrashAlt}
                  style={{
                    color: 'red',
                    cursor: 'pointer',
                    fontSize: 13,
                  }}
                  id={'deletePhoto'}
                />
              </div>
            ) : null}
          </div>
        )}
        {!userPhotoUrl && (
          <div style={{ padding: '50px' }}>
            <FontAwesomeIcon icon={faCloudUploadAlt} size="6x" color="#009ACD" />
            <p>Click or Drag</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default UserPhoto;

UserPhoto.propTypes = {
  id: PropTypes.number,
  photoUrl: PropTypes.string,
};
