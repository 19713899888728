const style = {
  filterButton: {
    width: '100%',
    marginTop: 10,
  },
  spacedBetweenRow: {
    flexDirection: 'row',
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },

  filterChoiceButton: {
    width: '100%',
    marginTop: 10,
  },
  userListContainer: {
    marginTop: 10,
    height: 'calc(100vh - 470px)',
    overflowY: 'scroll',
    width: '100%',
  },
  itemRow: {
    minHeight: 80,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: 15,
    cursor: 'pointer',
  },
  imgPlaceHolder: {
    fontSize: 50,
    marginRight: 10,
    width: 40,
  },
  candidateInfo: {
    textAlign: 'left',
    fontWeight: 600,
    width: '100%',
  },
  centeredText: {
    width: '100%',
    textAlign: 'center',
  },
};
export default style;
