const style = {
  actionButton: (label) => {
    const sActual = {
      width: 'fit-content',
      minWidth: label ? label.length * 5 : null,
      display: 'flex',
      flexDirection: 'column',
      marginRight: 5,
      marginTop: 5,
    };
    return sActual;
  },
  actionButtonContainer: {
    display: 'flex',
    paddingTop: '4px',
    width: '100%',
    justifyContent: 'left',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  spacedBetweenRow: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
};

export default style;
