import { connect } from 'react-redux';
import {
  setAllUserDepartments,
  setUserDepartment,
  setUserObject,
} from '../Body/RezRATE/Header/UserInformation/UserInformation.action';
import { authObject } from './auth/auth.action';
import AuthWrapper from './AuthWrapper';

const mapStateToProps = (state) => {
  return {
    loginHint: state.auth.loginHint,
    userDepartment: state.userInfo.userDepartment,
    isAuthenticated: state.auth.isAuthenticated,
    authObject: state.auth.authObject,
  };
};

const mapDispatchToProps = (dispatch) => ({
  setAuthObject: (showCalendar) => dispatch(authObject(showCalendar)),
  setUserObject: (user) => dispatch(setUserObject(user)),
  setUserDepartment: (department) => dispatch(setUserDepartment(department)),
  setAllUserDepartments: (departments) => dispatch(setAllUserDepartments(departments)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AuthWrapper);
