export const isAuthenticated = (payload) => {
  return {
    type: 'IS_AUTHENTICATED',
    payload,
  };
};

export const authObject = (payload) => {
  return {
    type: 'AUTH_OBJECT',
    payload,
  };
};
