const style = {
  markFilter: {
    width: 120,
    alignSelf: 'center',
    textAlign: 'center',
  },
  dateListItem: { width: '100%', textAlign: 'center', cursor: 'pointer', padding: 10, height: 60 },
  tableHeaderCaret: { marginLeft: 10, cursor: 'pointer' },
  cellStyle: (row) => {
    const { rowIndex } = row;
    const styleActual = {
      alignItems: 'center',
      justifyContent: 'center',
      verticalAlign: 'middle',
      paddingTop: 2,
      paddingBottom: 2,
      height: 60,
      overflowY: 'auto',
    };

    if (row.isSelected) {
      styleActual.backgroundColor = '#caecfa';
    } else {
      styleActual.backgroundColor = rowIndex % 2 == 0 ? '#ffffff' : '#f2f2f2';
    }
    return styleActual;
  },
  clickable: {
    cursor: 'pointer',
  },
  simpleRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    verticalAlign: 'middle',
  },
  simpleColumn: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  spacedBetweenRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    verticalAlign: 'middle',
  },
  buttonLabel: {
    width: '100%',
    fontWeight: 'bold',
    fontSize: 13,
    marginLeft: 10,
  },
  actionButton: {
    marginLeft: 10,
    minWidth: 200,
    marginTop: 5,
    width: 250,
  },
  listItem: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    textAlign: 'center',
    justifyContent: 'space-between',
    padding: 5,
    cursor: 'pointer',
  },
  evaluatorList: {
    marginBottom: 10,
    marginTop: 10,
    maxHeight: 250,
    overflowY: 'scroll',
  },
  evaluatorListButton: {
    marginBottom: 5,
    width: '100%',
  },
  centeredText: {
    display: 'flex',
    flexDirection: 'row',
    textAlign: 'center',
    justifyContent: 'center',
  },
  genButton: {
    alignItems: 'center',
    backgroundColor: '#0a91ff',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginLeft: 10,
    marginRight: 10,
    paddignRight: 20,
    paddingBottom: 10,
    paddingTop: 10,
    minWidth: 100,
  },
};

export default style;
