import arrayMove from 'array-move';
import React from 'react';
import { Col, Row } from 'reactstrap';
import SelectionFork from '../../Animation/HTMLComponents/SelectableElements/SelectionFork';
// import SweetAlert from 'sweetalert2-react';
import ListAnimationContainer from '../../Animation/ListAnimation.container';
import { CustomButton, EvenRow } from './InterviewDateSelection.styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import Loading from 'Body/Statuses/Loading';

const InterviewDateSelection = (props) => {
  const { listArray, setListArray, preferenceItems, hasPreferenceOrderWithNoInterviewDate } = props;

  // TODO: vet fucntionality once final decision is made in UI
  const onSortEnd = ({ oldIndex, newIndex }) => {
    const newArray = arrayMove(this.props.selectedDates, oldIndex, newIndex);
    this.props.setSelectedDates(newArray);
    // find the new position of the selected item (this is necessary if a non-selected item is being moved)
    for (let i in newArray) {
      if (newArray[i].id === this.props.selectedItem) {
        this.props.setSelectedItemIndex(Number(i));
        return;
      }
    }
  };

  // TODO: Vet these once final decision on UI is made
  const {
    changeStep,
    selectedableDates,
    selectedDates,
    dateOfInterview,
    singleDateChoice,
    handleClick,
    currentSlide,
    setCurrentSlide,
    validateDateSelection,
  } = props;

  const combined = selectedDates.concat(selectedableDates);

  if (!combined || combined.length === 0) {
    return (
      <div style={{ color: '#000000' }}>
        {/* <FontAwesomeIcon icon={faSpinner} spin size="4x" /> */}

        <div
          style={{
            height: '100%',
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <div style={{ width: 100, height: 100, display: 'flex' }}>
            {' '}
            <Loading />
          </div>
        </div>
      </div>
    );
  }

  // TODO: Vet these once final decision on UI is made
  const checkIfCurrentSlide = (num) => {
    if (currentSlide !== num) {
      setCurrentSlide(num);
    }
  };

  return (
    <Col stlye={{ height: '100%', width: '100%', justifyContent: 'center', alignItems: 'center' }}>
      <Row>
        <ListAnimationContainer
          handleClick={handleClick}
          clickable={true}
          dateOfInterview={dateOfInterview}
          singleDateChoice={singleDateChoice}
          preferenceItems={preferenceItems}
          hasPreferenceOrderWithNoInterviewDate={hasPreferenceOrderWithNoInterviewDate}
          listArray={combined}
          HTMLComponent={SelectionFork}
          isOrdinal={true}
        />
      </Row>
      <EvenRow>
        <CustomButton color="danger" onClick={(e) => changeStep(e, 0)}>
          Decline
        </CustomButton>
        <CustomButton color="success" onClick={(e) => validateDateSelection(e, 2)}>
          Next
        </CustomButton>
      </EvenRow>
    </Col>
  );
};

export default InterviewDateSelection;
