import React, { useContext, useState } from 'react';
import { Input, InputGroup, InputGroupAddon, Button, Label } from 'reactstrap';
import { DepartmentContext } from '../../../../../DepartmentWrapper';
import { postDataAgnostic } from '../../../../../Services/dataApi';
import { useHistory } from 'react-router-dom';
import { NotificationManager } from 'react-notifications';

import style from './style';
import { formatBearerToken } from '../../../../../Common.functions';
import { useAuth0 } from '../../../../../auth0/reactAuth0Spa';
import Swal from 'sweetalert2';
import { clone } from 'lodash';

const AddTimeSlotPopover = ({
  pk_InterviewDate,
  forcedContext,
  row,
  fetchSchedule,
  hideHeaderPopover,
  scheduleData,
  setShowAddTimeSlotPopover,
}) => {
  const [slotsToAdd, setSlotsToAdd] = useState(1);
  const deptContext = useContext(DepartmentContext);
  const dContext = forcedContext ? forcedContext : deptContext;
  const history = useHistory();
  const { getTokenSilently } = useAuth0();

  const insertTimeslot = (mode) => {
    if (slotsToAdd === '' || slotsToAdd < 1) {
      NotificationManager.error('Please choose a number between 1 and 50');
      return;
    }
    return getTokenSilently()
      .then((token) => {
        const rowSortOrders = [];
        const pathExploded = history.location.pathname.split('/');
        const scheduleID = pk_InterviewDate ? pk_InterviewDate : pathExploded[pathExploded.length - 1];

        let toAdd = slotsToAdd;

        if (row) {
          const { SortOrder } = row;
          // Inserting in the middle of schedule.
          let currentSlot = SortOrder;
          if (mode === 'after') {
            currentSlot = SortOrder + 1;
          }

          while (toAdd > 0) {
            rowSortOrders.push(currentSlot);
            toAdd--;
            currentSlot++;
          }
        } else {
          // Inserting at beginning or end of schedule.
          let highestSortOrder = null;
          let lowestSortOrder = null;
          scheduleData.body.forEach((item) => {
            if (item.pk_Timeslot === 'END') {
              return;
            }
            if (highestSortOrder == null || item.SortOrder > highestSortOrder) {
              highestSortOrder = item.SortOrder;
            }
            if (lowestSortOrder == null || item.SortOrder < lowestSortOrder) {
              lowestSortOrder = item.SortOrder;
            }
          });

          let currentSlot = 0;
          if (mode === 'after') {
            currentSlot = highestSortOrder + 1;
          }

          while (toAdd > 0) {
            rowSortOrders.push(currentSlot);
            toAdd--;
            currentSlot++;
          }
        }

        postDataAgnostic(
          'department/season/schedule/row',
          {
            pk_Department: dContext.department.pk_Department,
            pk_InterviewDate: Number(scheduleID),
          },
          {
            rowSortOrders: rowSortOrders,
          },
          formatBearerToken(token),
        )
          .then((res) => {
            fetchSchedule(token);
            setShowAddTimeSlotPopover(false);
            hideHeaderPopover();
          })
          .catch((err) => {});
      })
      .catch((err) => {});
  };

  return (
    <div style={style.mainBody}>
      <Label style={style.inputLabel} for="slotsToAdd">
        Number of slots to add:
      </Label>
      <div style={style.simpleRow}>
        <Input
          type="number"
          style={style.inputFields}
          name="slotsToAdd"
          id="slotsToAdd"
          placeholder=""
          value={slotsToAdd}
          onChange={(e) => {
            let newValue = Number(e.target.value);

            // should let the user put in a blank value so they can type in a new number
            if (e.target.value === '') {
              setSlotsToAdd(null);
            } else if (newValue < 1) {
              setSlotsToAdd(1);
              NotificationManager.error('Timeslot insert cannot be less than 1');
            } else if (newValue > 50) {
              setSlotsToAdd(50);
              NotificationManager.error('Timeslot insert cannot exceed 50');
            } else {
              setSlotsToAdd(newValue);
            }
          }}
        />
      </div>

      <div style={style.simpleRow}>
        <Button
          color="danger"
          style={{ ...style.genButton, backgroundColor: null }}
          onClick={() => {
            setShowAddTimeSlotPopover(false);
          }}
        >
          Cancel
        </Button>
        <Button
          style={style.genButton}
          onClick={(e) => {
            e.preventDefault();
            insertTimeslot('before');
          }}
        >
          {row ? 'Before' : 'Beginning'}
        </Button>
        <Button
          style={style.genButton}
          onClick={(e) => {
            e.preventDefault();
            insertTimeslot('after');
          }}
        >
          {row ? 'After' : 'End'}
        </Button>
      </div>
      <div style={style.simpleRow}></div>
      <div style={style.simpleRow}></div>
    </div>
  );
};

export default AddTimeSlotPopover;
