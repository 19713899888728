import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment-timezone';
import React, { useState, useContext } from 'react';
import { useAlert } from 'react-alert';
import { Col } from 'reactstrap';
import Swal from 'sweetalert2';
import { useAuth0 } from '../../auth0/reactAuth0Spa';
import { formatBearerToken } from '../../Common.functions';
import { DepartmentContext } from '../../DepartmentWrapper';
import { postDataAgnostic, deleteData } from '../../Services/dataApi';
import SingleCandidateDragAndDropItem from './SingleCandidateDragAndDropItem';
import Loading from 'Body/Statuses/Loading';

const iconStyle = {
  height: '40px',
  width: '40px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '4px 4px',
};

const SingleCandidateDragAndDrop = ({
  candidateFiles,
  currentLogo,
  documentTypes,
  fileUpload,
  getCandidateDetails,
  getCandidateInformation,
  initiateFileUpload,
  isLoading,
  selectedCandidate,
  setCandidateFiles,
  setDocumentTypes,
  setFiles,
  setSelectedDocumentType,
  singleCandidateObject,
  uploadLogo,
  urlObject,
}) => {
  const dContext = useContext(DepartmentContext);
  const [confirmDelete, setConfirmDelete] = useState({});
  const { getTokenSilently } = useAuth0();
  const alert = useAlert();
  const deleteFile = (file) => {
    if (file && file.pk_File) {
      return getTokenSilently().then((token) => {
        return deleteData(
          'files/delete',
          { pk_Department: dContext.department.pk_Department, pk_Season: dContext.season.pk_Season },
          { pk_File: file.pk_File },
          formatBearerToken(token),
        )
          .then((res) => {
            const candidateFilesCopy = [...candidateFiles];
            candidateFilesCopy.forEach((item, i) => {
              if (file.pk_File === file.pk_File) {
                candidateFilesCopy.splice(i, 1);
              }
            });
            getCandidateDetails(selectedCandidate);
            setConfirmDelete(false);
            alert.success('Deleted File!');
            return true;
          })
          .catch((err) => {
            setConfirmDelete(false);
            alert.error('Failed to Delete file!');
            return false;
          });
      });
    } else {
      alert.error('Failed to Delete file!');
      setConfirmDelete(false);
      return true;
    }
  };

  const determineRender = () => {
    if (uploadLogo) {
      return (
        <SingleCandidateDragAndDropItem
          setFiles={setFiles}
          item={{}}
          setDocumentTypes={setDocumentTypes}
          documentTypes={documentTypes}
          getCandidateDetails={getCandidateDetails}
          selectedCandidate={selectedCandidate}
          uploadLogo={uploadLogo}
          initiateFileUpload={initiateFileUpload}
          urlObject={urlObject}
          currentLogo={currentLogo}
          getCandidateInformation={getCandidateInformation}
          candidateFiles={candidateFiles}
          setSelectedDocumentType={setSelectedDocumentType}
        />
      );
    }

    const updateDelete = (attachedDoc, shouldBeConfirmed) => {
      Swal.fire({
        title: 'Are you sure you want to delete?',
        showCancelButton: true,
        confirmButtonText: `Delete`,
        confirmButtonColor: 'red',
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.value) {
          deleteFile(attachedDoc);
        }
      });
    };

    if (isLoading) {
      return (
        <div style={{ marginTop: 20 }}>
          <Loading options={{ labelText: 'Fetching Documents. . .' }} />
        </div>
      );
    } else if (documentTypes && documentTypes.length > 0) {
      return (
        <table style={{ width: '100%', marginLeft: '20px' }}>
          <thead style={{ position: 'sticky', top: 0, backgroundColor: 'white' }}>
            <th>Delete</th>
            <th style={{ textAlign: 'center' }}>Type</th>
            <th style={{ textAlign: 'center' }}>File</th>
            <th style={{ textAlign: 'center' }}>Filename</th>
            <th style={{ textAlign: 'center' }}>Last Updated</th>
          </thead>
          <tbody>
            {documentTypes.map((item) => {
              const { Documents = [] } = selectedCandidate || {};
              // console.log('Documents: ', Documents);
              const attachedDoc = Documents.find((doc) => {
                // console.log('comparing doc: ', doc.fk_DocumentType, ' vs ', item.pk_DocumentType);
                return doc.fk_DocumentType === item.pk_DocumentType;
              });
              const { createdAt, updatedAt, Name } = attachedDoc || {};
              const { Hide } = item;
              return (
                <tr key={item.pk_DocumentType}>
                  <td>
                    {attachedDoc && (
                      <div
                        className="hover_blue"
                        style={{ ...iconStyle, cursor: 'pointer' }}
                        onClick={() => {
                          updateDelete(attachedDoc, true);
                        }}
                      >
                        <FontAwesomeIcon icon={faTrash} style={{ color: '#d11a2a' }} size="lg" />
                      </div>
                    )}
                  </td>
                  <td style={{ textAlign: 'center' }}>
                    <b style={{ fontSize: 15 }}>{item.DocumentTypeName}</b>
                    <br />
                    {Hide ? '(Hidden)' : ''}
                  </td>
                  <td style={{ width: '150px', height: '100%' }}>
                    <SingleCandidateDragAndDropItem
                      attachedDoc={attachedDoc || {}}
                      candidateFiles={candidateFiles}
                      confirmDelete={confirmDelete[item.pk_DocumentType]}
                      deleteFile={deleteFile}
                      documentTypes={documentTypes}
                      getCandidateDetails={getCandidateDetails}
                      getCandidateInformation={getCandidateInformation}
                      iconStyle={iconStyle}
                      initiateFileUpload={initiateFileUpload}
                      item={item}
                      key={item.pk_DocumentType}
                      postDataAgnostic={postDataAgnostic}
                      selectedCandidate={selectedCandidate}
                      setConfirmDelete={setConfirmDelete}
                      setDocumentTypes={setDocumentTypes}
                      setFiles={setFiles}
                      setSelectedDocumentType={setSelectedDocumentType}
                      urlObject={urlObject}
                    />
                  </td>
                  <td>{Name && Name.split('-UUID-')[1]}</td>
                  <td>
                    {updatedAt
                      ? moment.tz(updatedAt, null).format('MM/DD/yyyy hh:mm:ss A')
                      : createdAt
                      ? moment.tz(createdAt, null).format('MM/DD/yyyy hh:mm:ss A')
                      : ' '}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      );
    } else {
      return <div>No Document Types</div>;
    }
  };
  return (
    <Col
      style={{
        padding: 0,
        maxWidth: '100%',
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: 300,
        oveflowY: 'scroll',
        paddingLeft: 20,
        paddingRight: 20,
        marginTop: 20,
      }}
    >
      <div style={{ width: '100%', height: '100%' }}>
        <div style={{ width: '100%' }}>{determineRender()}</div>
      </div>
    </Col>
  );
};

export default SingleCandidateDragAndDrop;
