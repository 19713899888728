const style = {
  mainBody: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
  },
  simpleRow: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 20,
  },
  simpleColumn: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  spacedBetweenRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
    marginBottom: 20,
  },
  inputFields: {
    textAlign: 'center',
  },
  inputLabel: {
    fontWeight: 'bold',
  },
  genButton: {
    alignItems: 'center',
    backgroundColor: '#0a91ff',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginLeft: 10,
    marginRight: 10,
    paddignRight: 20,
    paddingBottom: 10,
    paddingLeft: 20,
    paddingTop: 10,
    width: '100%',
  },
  buttonLabel: { width: 80, textAlign: 'center', fontWeight: 600, fontSize: 13 },
};

export default style;
