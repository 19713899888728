const style = {
  itemRow: {
    display: 'flex',
    borderBottom: '1px solid #c8dae0',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 10,
    paddingBottom: 10,
  },
  imgPlaceHolder: {
    fontSize: 50,
    marginRight: 10,
    width: 40,
  },
  candidateInfo: {
    textAlign: 'left',
    fontWeight: 600,
    minHeight: 30,
  },
  buttonIcon: {
    cursor: 'pointer',
    margin: 5,
  },
  simpleRow: {
    display: 'flex',
    flexDirection: 'row',
  },
  simpleColumn: {
    display: 'flex',
    flexDirection: 'column',
  },
};

export default style;
