import React, { useEffect, useState } from 'react';
import Moment from 'react-moment';
import moveIcon from '../../../../assets/images/icons/moveIcon.png';
import { ordinal_suffix_of } from '../../../../SortableList';
import { DraggableItemWrapper } from '../../../Common.style';
import moment from 'moment-timezone';

const Selection = ({
  listKey,
  data,
  state,
  lastPressed,
  isPressed,
  mouseY,
  listArray,
  isOrdinal,
  singleDateChoice,
  handleMouseDown,
  handleTouchEnd,
  handleClickTransfer,
  handleTouchStart,
  i,
}) => {
  const numberKey = Number(listKey);
  const item = listArray[numberKey];
  const [isMobile, setIsMobile] = useState(window.innerWidth < window.innerHeight);
  const itemsNumbered = isOrdinal ? ordinal_suffix_of(item.preference + 1) : item.preferences + 1;
  const { shadow, scale, y } = state;
  const transY = lastPressed === data && isPressed ? mouseY : y;

  const backgroundColor = 'rgb(64, 64, 64)';

  useEffect(() => {
    setIsMobile(window.innerWidth < window.innerHeight);
  }, [window.innerWidth, window.innerHeight]);

  // handles UI elements for selected items
  return (
    <DraggableItemWrapper
      key={listKey}
      // need to use style instead of styled-components to keep animation smooth
      style={{
        boxShadow: `rgba(0, 0, 0, 0.4) 0px ${shadow}px ${2 * shadow}px 0px`,
        transform: `translate3d(0, ${transY}px, 0) scale(${scale})`,
        WebkitTransform: `translate3d(0, ${transY}px, 0) scale(${scale})`,
        zIndex: data === lastPressed ? 99 : data,
        backgroundColor: backgroundColor,

        padding: 10,
        paddingRight: 5,
        paddingLeft: 5,
        alignItems: 'center',
        justifyContent: 'center',
      }}
      className="draggable_item"
      backgroundColor={backgroundColor}
    >
      {/* <ClickTransfer>
        <Date className="date" style={{ color: color }}>
          {!singleDateChoice && <span style={{ fontWeight: '700' }}>{itemsNumbered}:</span>}&nbsp;&nbsp;
          <Moment className="mobile_display" format="ddd, MMM DD, YYYY" utc>
            {item.DateOfInterview || item.InterviewDates4PortalDate}
          </Moment>
          <Moment className="browser_display" format="dddd, MMMM DD, YYYY" utc>
            {item.DateOfInterview || item.InterviewDates4PortalDate}
          </Moment>{' '}
        </Date>
        {item.Label && <div style={{ color: '#fff' }}>{item.Label}</div>}
      </ClickTransfer> */}
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          color: '#fff',
          paddingLeft: isMobile ? 0 : 10,
          paddingRight: isMobile ? 0 : 10,
          fontSize: ' 0.7em',
        }}
      >
        {!singleDateChoice && (
          <div
            style={{
              fontWeight: '700',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              width: 50,
            }}
          >
            {itemsNumbered}:
          </div>
        )}
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: isMobile ? 'calc(100% - 100px)' : '100%',
          }}
          onClick={(e) => {
            if (!singleDateChoice) handleClickTransfer('remove', data);
          }}
        >
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
            <div className="mobile_display">
              {moment.tz(item.DateOfInterview || item.InterviewDates4PortalDate, null).format('ddd, MMM DD, YYYY')}
            </div>
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
            <div className="browser_display">
              {moment.tz(item.DateOfInterview || item.InterviewDates4PortalDate, null).format('ddd, MMMM DD, YYYY')}
            </div>
          </div>
          <div
            style={{
              color: '#fff',
              fontSize: 15,
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            {item.Label}
          </div>
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', width: 50 }}>
          {' '}
          {!singleDateChoice && (
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                // position: 'absolute',
                width: '50px',
                fontSize: '0.7em',
                right: 0,
                cursor: 'move',
                cursor: `${(props) => (props.active ? 'grabbing' : 'grab')}`,
                cursor: `-moz-grab`,
                cursor: `-webkit-grab`,
                zIndex: 10,
              }}
              className="movable"
              onMouseDown={(e) => handleMouseDown(data, y, e)}
              onTouchStart={(e) => handleTouchStart(data, y, e)}
              onTouchEnd={(e) => handleTouchEnd(e)}
            >
              <img className="move-icon" src={moveIcon} />
              {/* <FontAwesomeIcon icon={faArrowsUpDownLeftRight} style={{ fontSize: 35 }} /> */}
            </div>
          )}
        </div>
      </div>

      {/* {!singleDateChoice && (
        <DraggableDiv
          className="movable"
          onMouseDown={(e) => handleMouseDown(data, y, e)}
          onTouchStart={(e) => handleTouchStart(data, y, e)}
          onTouchEnd={(e) => handleTouchEnd(e)}
        >
          <img className="move-icon" src={moveIcon} />
        </DraggableDiv>
      )} */}
    </DraggableItemWrapper>
  );
};

export default Selection;
