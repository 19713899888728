const style = {
  simpleRow: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 10,
  },
  mainContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  simpleColumn: {
    display: 'flex',
    flexDirection: 'column',
    padding: 10,
    width: '50%',
  },
  spacedBetweenRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  spacedAroundRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'center',
  },
};
export default style;
