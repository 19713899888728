import axios from 'axios';
import { clone } from 'lodash';

const constructUrl = (url) => {
  const { REACT_APP_BUILD_TYPE } = process.env;

  if (REACT_APP_BUILD_TYPE && REACT_APP_BUILD_TYPE === 'liveproduction') {
    return 'https://prodapi.rezrate.com/' + url;
  }

  if (REACT_APP_BUILD_TYPE && REACT_APP_BUILD_TYPE === 'livedevelopment') {
    return 'https://devapi.rezrate.com/' + url;
  }

  return `https://devapi.rezrate.com/` + url;

  // return `http://localhost:8080/` + url;
};

// StartDateAndTime: '2022-03-31T09:20:00.000Z';

// generic fetch function
async function fetchData(url, params) {
  const result = await axios({
    url,
    method: 'GET',
    // withCredentials: determineCredentials(),
    headers: {
      'Content-Type': 'application/json',
    },
    params,
  });
  return result;
}

// generic fetch function
async function fetchDataWithoutCredentials(url, params) {
  const result = await axios({
    url,
    method: 'GET',
    // withCredentials: determineCredentials(),
    headers: {
      'Content-Type': 'application/json',
    },
    params,
  });
  return result;
}

async function fetchDataAgnostic(url, params, headers, options = {}) {
  const completeHeaders = { 'Content-Type': 'application/json', ...headers };
  const { abortController, callback } = options;
  const { signal } = abortController || {};
  const axiosOptions = {
    url: constructUrl(url),
    method: 'GET',
    // signal: signal,
    // withCredentials: determineCredentials(),
    params,
    headers: completeHeaders,
  };

  if (options && abortController) {
    axiosOptions.signal = signal;
  }

  if (url === 'department/candidate') {
    // console.log('fetchDataAgnostic firing url: ', url);
  }

  if (callback) {
    axios
      .get(constructUrl(url), axiosOptions)
      .then((result) => {
        callback(result);
      })
      .catch((err) => {
        console.log('axios err: ', err);
        callback(err);
      });
    return;
  } else {
    const result = await axios(axiosOptions);
    // .then((res) => {
    //   console.log('axios res: ', res);
    // })
    // .catch((err) => {
    //   console.log('axios err: ', err);
    // });
    if (url === 'department/candidate') {
      // console.log('fetchDataAgnostic done url: ', url);
      // console.log('axiosOptions: ', clone(axiosOptions));
    }
    return result;
  }
}

async function fetchDataAgnosticWithoutCredentials(url, params, headers) {
  const completeHeaders = { 'Content-Type': 'application/json', ...headers };

  const result = await axios({
    url: constructUrl(url),
    method: 'GET',
    params,
    headers: completeHeaders,
  });
  return result;
}

// generic post function
async function postDataFM(url, params, dates) {
  const result = await axios({
    url,
    method: 'POST',
    // withCredentials: determineCredentials(),
    params,
    header: { 'Access-Control-Allow-Origin': '*' },
  });
  return result;
}

// generic post function
async function postData(url, params, dates) {
  const result = await axios({
    url,
    method: 'POST',
    // withCredentials: determineCredentials(),
    params,
    header: { 'Access-Control-Allow-Origin': '*' },
    data: { dates },
  });
  return result;
}

async function postDataWithoutCredentials(url, params, data, headers) {
  const result = await axios({
    url: url,
    method: 'POST',
    headers: { ...headers },
    params,
    data,
  });
  return result;
}

async function postDataAgnostic(url, params, data, headers) {
  const result = await axios({
    url: constructUrl(url),
    method: 'POST',
    headers: { ...headers },
    // withCredentials: determineCredentials(),
    params,
    data,
  });
  return result;
}

async function postDataAgnosticWithoutCredentials(url, params, data, headers) {
  const result = await axios({
    url: constructUrl(url),
    method: 'POST',
    headers: { ...headers },
    params,
    data,
  });
  return result;
}

async function fetchDataAuth0(url, params, headers) {
  const completeHeaders = { 'Content-Type': 'application/json', ...headers };

  const result = await axios({
    url: constructUrl(url),
    method: 'GET',
    params,
    headers: completeHeaders,
  });
  return result;
}
// const constructApiBaseUrl = (subDirectory) => {
//   const
//   return `string`
// }

async function deleteData(url, params, data, headers) {
  const completeHeaders = { 'Content-Type': 'application/json', ...headers };

  const result = await axios({
    url: constructUrl(url),
    method: 'DELETE',
    params,
    data,
    headers: completeHeaders,
  });
  return result;
}

async function putData(url, params, data, headers) {
  const completeHeaders = { 'Content-Type': 'application/json', ...headers };
  const result = await axios({
    url: constructUrl(url),
    method: 'PUT',
    params,
    data,
    headers: completeHeaders,
  });

  return result;
}

export {
  fetchData,
  putData,
  deleteData,
  postDataAgnosticWithoutCredentials,
  postData,
  fetchDataAuth0,
  fetchDataAgnostic,
  postDataFM,
  fetchDataWithoutCredentials,
  postDataWithoutCredentials,
  constructUrl,
  postDataAgnostic,
  fetchDataAgnosticWithoutCredentials,
};
