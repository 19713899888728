import { connect } from 'react-redux';
import { setUser } from '../../Body/InterviewDates/InterviewDatesRouter.action';
import CandidatePhoto from './CandidatePhoto';

const mapStateToProps = (state) => {
  return {
    user: state.userInfo.user,
  };
};
const mapDispatchToProps = (dispatch) => ({
  setUser: (user) => dispatch(setUser(user)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CandidatePhoto);
