import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import imageToBase64 from 'image-to-base64';

import moment from 'moment';
import tinycolor from 'tinycolor2';
import { clone } from '../../schedule';
import Swal from 'sweetalert2';
import profilePlaceholder from './Portrait_Placeholder.png';

const bgGuide = require('./AVERY_5390.png');
let profilePlaceholderBase64 = null;

pdfMake.vfs = pdfFonts.pdfMake.vfs;

// 1 inch = 72 units.
// LETTER size is 612x792

const tableLayouts = {
  customTable: {
    hLineWidth: (i, node) => {
      return 1;
      // return 0;
      // if (i === 0 || i === node.table.body.length) {
      //   return 0;
      // }
      return i === node.table.headerRows ? 2 : 1;
    },
    vLineWidth: (i) => {
      return 0;
    },
    hLineColor: (i) => {
      return '#aaa';
      return i === 1 ? 'black' : '#aaa';
    },
    paddingLeft: (i) => {
      return 0;
    },
    paddingRight: (i, node) => {
      return 0;
    },
    paddingTop: (i, node) => {
      return 0;
    },
    paddingBottom: (i, node) => {
      return 0;
    },
  },
  linelessTable: {
    hLineWidth: (i, node) => {
      return 0;
      // if (i === 0 || i === node.table.body.length) {
      //   return 0;
      // }
      return i === node.table.headerRows ? 2 : 1;
    },
    vLineWidth: (i) => {
      return 0;
    },
    hLineColor: (i) => {
      return '#aaa';
      return i === 1 ? 'black' : '#aaa';
    },
    paddingLeft: (i) => {
      return 0;
    },
    paddingRight: (i, node) => {
      return 0;
    },
    paddingTop: (i, node) => {
      return 0;
    },
    paddingBottom: (i, node) => {
      return 0;
    },
  },

  headerLineOnly: {
    hLineWidth: (i, node) => {
      if (i > 0) {
        return 0.2;
      } else {
        return 0;
      }

      // if (i > 0 && i < node.table.body.length) {
      //   return 1;
      // } else {
      //   return 0;
      // }
      // return i === node.table.headerRows ? 2 : 1;
    },
    vLineWidth: (i) => {
      return 0;
    },
    hLineColor: (i) => {
      return '#aaa';
      return i === 1 ? 'black' : '#aaa';
    },
    paddingLeft: (i) => {
      return 0;
    },
    paddingRight: (i, node) => {
      return 0;
    },
    paddingTop: (i, node) => {
      return 0;
    },
    paddingBottom: (i, node) => {
      return 0;
    },
  },
};

const forbiddenFields = ['FirstName', 'LastName', 'Email', 'MiddleName'];

const truncate = (wordToTruncate, limit = 0, forcedCut) => {
  if (wordToTruncate == null) {
    return '';
  }
  if (limit == 0 || wordToTruncate.length < limit + 1) {
    return wordToTruncate;
  } else if (forcedCut) {
    return `${wordToTruncate.slice(0, limit)}`;
  } else if (wordToTruncate.length > limit + 1) {
    return `${wordToTruncate.slice(0, limit - 5)}. . .`;
  }
};

const renderLines = (lineCount, minimumCount = 0) => {
  let counter = 0;
  const lines = [];
  const lineCountActual = lineCount < minimumCount ? minimumCount : lineCount;

  while (counter < lineCountActual) {
    // lines.push([
    //   {
    //     canvas: [{ type: 'line', x1: 0, y1: 0, x2: 540.2, y2: 0, lineWidth: 1, color: 'black' }],
    //     margin: [10, 25, 0, 0],
    //     pageBreak: counter >= lineCountActual - 1 ? 'after' : null,
    //   },
    // ]);
    lines.push([
      {
        table: {
          headerRows: 0,
          widths: [560],
          heights: [25],
          body: [[{ text: '' }]],
        },
        pageBreak: counter >= lineCountActual - 1 ? 'after' : null,
        margin: [10, 0, 0, 0],
        layout: 'headerLineOnly',
      },
    ]);
    counter++;
  }

  return lines;
  // return [
  //   {
  //     table: {
  //       headerRows: 0,
  //       widths: ['100%'],
  //       body: [['']],
  //     },
  //     layout: 'headerLineOnly',
  //   },
  // ];
};
const createPDFProper = (data) => {
  const { interviewDate, candidates, department, departmentLogo, activeFields } = data;
  const { metaData, MomentDateOfInterview } = interviewDate;
  const { TimeZone } = department;
  let dateToDisplay = '';
  let labelToDisplay = '';

  if (metaData) {
    const { DateOfInterview, StartTime, Label } = metaData;
    dateToDisplay = moment(`${DateOfInterview} ${StartTime}`).format('MMM DD, YYYY');
    labelToDisplay = Label;
  } else if (MomentDateOfInterview) {
    labelToDisplay = 'Ayy yooo';
    dateToDisplay = MomentDateOfInterview.format('MMM DD, YYYY');
  }

  const rows = [];

  let counter = 0;

  const renderCard = (candidate, counter) => {
    const { userPhotoBase64 } = candidate;
    const cardRows = [];
    const userDetailRows = [];
    const fieldRows = [];

    const userPhoto = userPhotoBase64
      ? {
          columns: [
            {
              image: userPhotoBase64 ? `data:image/png;base64,${userPhotoBase64}` : null,
              fit: [200, 200],
            },
          ],
          margin: [0, 0, 0, 10],
        }
      : {
          columns: [
            {
              image: profilePlaceholderBase64 ? `data:image/png;base64,${profilePlaceholderBase64}` : null,
              fit: [200, 200],
            },
          ],
          margin: [0, 0, 0, 10],
        };

    userDetailRows.push([userPhoto]);

    activeFields.forEach((df, i) => {
      if (
        !df.DisplayToEvaluator ||
        fieldRows.length >= 20 ||
        forbiddenFields.includes(df.Alias) ||
        forbiddenFields.includes(df.Name)
      ) {
        return;
      }
      fieldRows.push([
        {
          width: 360,
          columns: [
            {
              text: `${df.Alias || df.Name}`,
              fontSize: 10,
              style: { bold: true },
              width: 150,
              margin: [0, 0, 10, 0],
            },
            {
              text: `${candidate[df.Name] || '-'}`,
              fontSize: 10,
              width: 200,
            },
          ],
          fillColor: fieldRows.length % 2 == 0 ? '#e8e8e8' : null,
        },
      ]);
    });

    // if (fieldRows.length <= 0) {
    //   fieldRows.push([
    //     {
    //       width: 360,
    //       columns: [
    //         {
    //           text: `No Data`,
    //           fontSize: 10,
    //           style: { bold: true },
    //           width: 350,
    //           margin: [0, 0, 10, 0],
    //           alignment: 'center',
    //         },
    //       ],
    //       fillColor: fieldRows.length % 2 == 0 ? '#e8e8e8' : null,
    //     },
    //   ]);
    // }

    const fieldsTable = {
      table: { heights: 12, widths: [5.0 * 72], body: fieldRows, dontBreakRows: true },
      margin: [0, 5, 0, 0],
      layout: 'customTable',
    };

    cardRows.push([
      {
        columns: [
          { columns: [userDetailRows], width: 210, margin: [0, 0, 10, 0] },
          [
            [
              {
                style: { alignment: 'left', bold: true },
                text: `${candidate.LastName}, ${candidate.FirstName} ${
                  candidate.MiddleName ? candidate.MiddleName : ''
                }`,
                fontSize: 14,
                margin: [0, 0, 0, 5],
                width: 5.0 * 72,
              },
            ],
            [
              {
                style: { alignment: 'left' },
                text: `${candidate.CandidateSubHeading ? candidate.CandidateSubHeading : ' '}`,
                width: 5.0 * 72,
                margin: [0, 0, 0, 0],
              },
            ],
            [
              {
                style: { alignment: 'left' },
                text: `${candidate.Email ? candidate.Email : ' '}`,
                width: 5.0 * 72,
                margin: [0, 0, 0, 0],
              },
            ],
            [
              {
                style: { alignment: 'left' },
                text: `${candidate.PhoneNumber ? candidate.PhoneNumber : ' '}`,
                // fontSize: 12,
                margin: [0, 0, 0, 0],
                width: 5.0 * 72,
              },
            ],
            [fieldRows.length > 0 ? fieldsTable : { text: '' }],
          ],
        ],
        margin: [10, 0, 0, 5],
      },
    ]);

    cardRows.push([
      [
        {
          style: { alignment: 'left', bold: true },
          text: `Notes ${fieldRows.length > 35 ? `(${candidate.LastName}, ${candidate.FirstName})` : ''}`,
          // fontSize: 12,
          margin: [10, 10, 0, 0],
          pageBreak: fieldRows.length > 35 ? 'before' : null,
        },
      ],
      ...renderLines(7 + (12 - fieldRows.length / 2), 4),
      // renderLines(8 + (12 - fieldRows.length / 2), 4),
    ]);

    // return cardRows;
    // return [cardRows];
    return [
      {
        table: { body: [[cardRows]], dontBreakRows: activeFields.length <= 35 },
        layout: 'linelessTable',
      },
    ];
  };

  while (counter < candidates.length) {
    const columns = [];
    const tableRow = [];
    rows.push(renderCard(candidates[counter], counter));

    counter++;
  }

  if (rows.length <= 0) {
    Swal.fire('Error', 'There are no Candidates for this Interview Date. There is nothing to print!', 'error');
    return;
  }

  // let pdfObject = [rows];
  let pdfObject = [
    {
      table: { body: rows, dontBreakRows: false },
      layout: 'linelessTable',
    },
  ];
  // imageToBase64(bgGuide)
  //   .then((bgBase64) => {
  const docDefinition = {
    // background: { image: `data:image/png;base64,${bgBase64}`, width: 612 },
    info: { title: 'Candidate Face Sheet w/ Notes' },
    pageSize: 'LETTER',
    pageOrientation: 'portrait',
    content: pdfObject,
    defaultStyle: {
      fontSize: 10,
    },
    pageMargins: [0.25 * 72, 1 * 72, 0.25 * 72, 0.5 * 72],
    header: function(currentPage, pageCount, pageSize) {
      return [
        {
          text: `${dateToDisplay} ${labelToDisplay ? `(${labelToDisplay})` : ''}`,
          alignment: 'bottom',
          style: { bold: true },
          fontSize: 15,
          margin: [0.4 * 72, 30, 0, 0],
        },
      ];
    },
  };

  pdfMake.createPdf(docDefinition, tableLayouts).open({}, window.open('', '_blank'));
  // })
  // .catch((err) => {
  // });
};

// Alias: "Alternate Phone"
// Enabled:
// Name: "AlternatePhone"
// Type: 2

/**
 * 1 = String
 * 2 = Number
 * 3 = Bool, Yes or No
 * 4 = DateTime
 * 5 = Phone
 * 6 = Street Address
 */
const dummyFields = [];

export const createPDFForCandidateFacesWithNotes = ({
  interviewDate,
  department,
  candidates = [],
  activeFields = [],
  callback,
}) => {
  const candidatesCopy = clone(
    candidates.sort((a, b) => {
      const textA = `${a.LastName}, ${a.FirstName}`.toUpperCase();
      const textB = `${b.LastName}, ${b.FirstName}`.toUpperCase();

      return textA < textB ? -1 : textA > textB ? 1 : 0;
    }),
  );
  Promise.all([
    ...candidatesCopy.map((c) => {
      return new Promise((resolve, reject) => {
        if (c.PhotoUrl) {
          imageToBase64(c.PhotoUrl)
            .then((base64Img) => {
              c.userPhotoBase64 = base64Img;
              resolve(c);
            })
            .catch((err) => {
              resolve({ error: true, ...c });
            });
        } else {
          resolve(c);
        }
      });
    }),
    new Promise((resolve, reject) => {
      imageToBase64(profilePlaceholder)
        .then((base64) => {
          profilePlaceholderBase64 = base64;
          resolve(base64);
        })
        .catch((err) => {
          reject(err);
        });
    }),
  ])
    .then((res) => {
      createPDFProper({ candidates: candidatesCopy, department, interviewDate, activeFields });
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      if (callback) {
        callback(err);
      }
    });
};
