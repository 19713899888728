import React, { useEffect, useState, useContext, useRef } from 'react';
import { useAlert } from 'react-alert';

import { faInfoCircle, faTags } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './SettingsPopover.css';
import { Button, Card, CardBody, CardHeader, CustomInput, Input, Label, UncontrolledTooltip } from 'reactstrap';

import style from './SettingsPopover.style';
import UserPhoto from '../../../DragAndDropFiles/UserPhoto/UserPhoto';
import UserPhotoTypes from '../../../DragAndDropFiles/UserPhoto/UserPhotoTypes';
import { clone } from '../../../../Services/schedule';
import { useAuth0 } from '../../../../auth0/reactAuth0Spa';
import { fetchDataAgnostic, putData } from '../../../../Services/dataApi';
import { formatBearerToken } from '../../../../Common.functions';
import { DepartmentContext } from '../../../../DepartmentWrapper';
import Advanced from 'Body/RezRATE/AdminLanding/AdminSettings/Advanced/Advanced';
import moment from 'moment-timezone';
import { applicationSystems, programTypes } from 'enums';

const SettingsPopover = ({ updateChanges }) => {
  const [isProcessing, setIsProcessing] = useState(false);
  const [customName, setCustomName] = useState('');
  const [deptConfig, setDeptConfig] = useState({});

  const [DepartmentName, setDepartmentName] = useState();
  const [DepartmentAddressLine1, setDepartmentAddressLine1] = useState();
  const [DepartmentAddressLine2, setDepartmentAddressLine2] = useState();
  const [ReplyAddressDisplayName, setReplyAddressDisplayName] = useState();
  const [DepartmentEmail, setDepartmentEmail] = useState();
  const [DepartmentPhone, setDepartmentPhone] = useState();
  const [DepartmentWebsite, setDepartmentWebsite] = useState();
  const [DepartmentZipCode, setDepartmentZipCode] = useState();
  const [DepartmentCountry, setDepartmentCountry] = useState();
  const [EnableTagFiltering, setEnableTagFiltering] = useState();
  const [EnableGroups, setEnableGroups] = useState();
  const [CandidateSubheadingField, setCandidateSubheadingField] = useState('');

  const [isSaving, setIsSaving] = useState(false);
  const [isGettingSettings, setIsGettingSettings] = useState(false);

  // =================== ADVANCED ===================//
  const [TerminateVirtualMeeting, setTerminateVirtualMeeting] = useState('End of Passing');
  const [VirtualMeetingMode, setVirtualMeetingMode] = useState('Disabled');
  const [FirstBlockEarlyJoinInMinutes, setFirstBlockEarlyJoinInMinutes] = useState(5);

  const [ApplicationSystem, setApplicationSystem] = useState('ERAS');
  const [ProgramType, setProgramType] = useState('Residency');
  const [Time, setTime] = useState(null);
  const [GMT, setGMT] = useState(null);
  const GMTRef = useRef(null);

  const joinMeetingMinutes = [5, 10, 15];
  // =================================================//

  const dContext = useContext(DepartmentContext);
  const { department = {}, season } = dContext;
  const { getTokenSilently } = useAuth0();
  const { EnableSuperUser } = department;
  const alert = useAlert();

  const { DepartmentLogo } = deptConfig || {};

  const virtualMeetingModes = [
    {
      value: 'Disabled',
      label: 'Disabled',
    },
    {
      value: 'Internal',
      label: 'RezRATE Managed Meetings', // Auto create w/ zoom
    },
    {
      value: 'External',
      label: 'Coordinator Managed Breakout Rooms (Single URL)', // Go to Visit Break Room
    },
    {
      value: 'ExternalColumn',
      label: 'Coordinator Managed Breakout Rooms (Multiple URL)', // Go to per-column URL, not yet implemented.
    },
  ];

  useEffect(() => {
    getSettings();

    setInterval(() => {
      setTime(moment.tz(moment.now(), GMTRef.current).format('HH:mm'));
    }, 1000);
  }, []);

  useEffect(() => {
    if (GMTRef.current) {
      setTime(moment.tz(moment.now(), GMTRef.current).format('HH:mm'));
    }
  }, [GMTRef.current]);

  useEffect(() => {
    GMTRef.current = GMT;
  }, [GMT]);

  useEffect(() => {}, [dContext]);

  useEffect(() => {
    if (updateChanges && Object.keys(deptConfig).length > 0) {
      if (!noChanges()) {
        updateChanges('programSettings', true);
      } else {
        updateChanges('programSettings', false);
      }
    }
  }, [
    DepartmentName,
    DepartmentAddressLine1,
    DepartmentAddressLine2,
    ReplyAddressDisplayName,
    DepartmentEmail,
    DepartmentPhone,
    DepartmentWebsite,
    DepartmentZipCode,
    DepartmentCountry,
    EnableTagFiltering,
    EnableGroups,
    VirtualMeetingMode,
    TerminateVirtualMeeting,
    FirstBlockEarlyJoinInMinutes,
    ApplicationSystem,
    ProgramType,
    Time,
    GMT,
  ]);

  const getSettings = () => {
    setIsGettingSettings(true);
    getTokenSilently()
      .then((token) => {
        fetchDataAgnostic(
          'department/settings',
          {
            pk_Department: department.pk_Department,
            pk_Season: season.pk_Season,
          },
          formatBearerToken(token),
        )
          .then((res) => {
            const {
              DepartmentLogo,
              DepartmentName,
              DepartmentAddressLine1,
              DepartmentAddressLine2,
              ReplyAddressDisplayName,
              DepartmentEmail,
              DepartmentPhone,
              DepartmentCountry,
              DepartmentWebsite,
              DepartmentZipCode,

              CandidateSubheadingField,
              EnableTagFiltering,
              EnableGroups,
              ApplicationSystem,
              TimeZone,
              FirstBlockEarlyJoinInMinutes,
              ProgramType,
              VirtualMeetingMode,
              // TerminateVirtualMeeting,
            } = res.data[0] || {};

            const newDepartment = clone(dContext.department);

            newDepartment.DepartmentLogo = DepartmentLogo;
            newDepartment.DepartmentName = DepartmentName || '';
            newDepartment.DepartmentAddressLine1 = DepartmentAddressLine1 || '';
            newDepartment.DepartmentAddressLine2 = DepartmentAddressLine2 || '';
            newDepartment.ReplyAddressDisplayName = ReplyAddressDisplayName || '';
            newDepartment.DepartmentEmail = DepartmentEmail || '';
            newDepartment.DepartmentPhone = DepartmentPhone || '';
            newDepartment.DepartmentCountry = DepartmentCountry || '';
            newDepartment.DepartmentWebsite = DepartmentWebsite || '';
            newDepartment.DepartmentZipCode = DepartmentZipCode || '';

            newDepartment.EnableTagFiltering = EnableTagFiltering || '';
            newDepartment.EnableGroups = EnableGroups || '';
            newDepartment.ApplicationSystem = ApplicationSystem || '';
            newDepartment.TimeZone = TimeZone || '';
            newDepartment.FirstBlockEarlyJoinInMinutes = FirstBlockEarlyJoinInMinutes || '';
            newDepartment.ProgramType = ProgramType || '';
            newDepartment.VirtualMeetingMode = VirtualMeetingMode || '';
            newDepartment.CandidateSubheadingField = CandidateSubheadingField || '';
            // newDepartment.TerminateVirtualMeeting = TerminateVirtualMeeting || '';

            if (ApplicationSystem) {
              setApplicationSystem(ApplicationSystem);
            }

            if (ProgramType) {
              setProgramType(ProgramType);
            }

            if (TimeZone) {
              setGMT(TimeZone);
            }

            if (FirstBlockEarlyJoinInMinutes) {
              setFirstBlockEarlyJoinInMinutes(FirstBlockEarlyJoinInMinutes);
            }

            if (VirtualMeetingMode) {
              setVirtualMeetingMode(VirtualMeetingMode);
            }

            if (CandidateSubheadingField) {
              setCandidateSubheadingField(CandidateSubheadingField);
            }

            // if (TerminateVirtualMeeting) {
            //   setTerminateVirtualMeeting(TerminateVirtualMeeting);
            // }

            dContext.setDepartment(clone(newDepartment));
            setDeptConfig(clone(newDepartment));
            setDepartmentName(DepartmentName);
            setDepartmentAddressLine1(DepartmentAddressLine1);
            setDepartmentAddressLine2(DepartmentAddressLine2);
            setReplyAddressDisplayName(ReplyAddressDisplayName);
            setDepartmentEmail(DepartmentEmail);
            setDepartmentPhone(DepartmentPhone);
            setDepartmentWebsite(DepartmentWebsite);
            setDepartmentZipCode(DepartmentZipCode);
            setDepartmentCountry(DepartmentCountry);
            setEnableTagFiltering(EnableTagFiltering);
            setEnableGroups(EnableGroups);
            setIsGettingSettings(false);
          })
          .catch((err) => {
            setIsGettingSettings(false);
          });
      })
      .catch((err) => {});
  };

  const saveSettings = () => {
    const payload = {
      CandidateSubheadingField,
      DepartmentName,
      DepartmentAddressLine1,
      DepartmentAddressLine2,
      ReplyAddressDisplayName,
      DepartmentEmail,
      DepartmentPhone,
      DepartmentCountry,
      DepartmentWebsite,
      DepartmentZipCode,
      EnableTagFiltering,
      EnableGroups,
      VirtualMeetingMode,
      FirstBlockEarlyJoinInMinutes,
      ApplicationSystem,
      ProgramType,
      Time,
      TimeZone: GMT,
    };

    setIsSaving(true);
    getTokenSilently()
      .then((token) => {
        putData(
          'department/settings',
          {
            pk_Department: department.pk_Department,
            pk_Season: season.pk_Season,
          },
          {
            payload,
          },
          formatBearerToken(token),
        )
          .then((res) => {
            setIsSaving(false);
            getSettings();
            if (updateChanges) {
              updateChanges('programSettings', false);
            }
            alert.success('Settings Saved!');
          })
          .catch((err) => {
            setIsSaving(false);
            alert.error('Unable to save settings!');
          });
      })
      .catch((err) => {
        setIsSaving(false);
      });
  };

  const noChanges = () => {
    const deptCopy = deptConfig ? clone(deptConfig) : {};
    const deptNew = {
      DepartmentName: DepartmentName || '',
      DepartmentAddressLine1: DepartmentAddressLine1 || '',
      DepartmentAddressLine2: DepartmentAddressLine2 || '',
      ReplyAddressDisplayName: ReplyAddressDisplayName || '',
      DepartmentEmail: DepartmentEmail || '',
      DepartmentPhone: DepartmentPhone || '',
      DepartmentCountry: DepartmentCountry || '',
      DepartmentWebsite: DepartmentWebsite || '',
      DepartmentZipCode: DepartmentZipCode || '',

      EnableTagFiltering: EnableTagFiltering || '',
      EnableGroups: EnableGroups || '',
      CandidateSubheadingField: CandidateSubheadingField || '',
      VirtualMeetingMode: VirtualMeetingMode || '',
      // TerminateVirtualMeeting: TerminateVirtualMeeting || '',
      FirstBlockEarlyJoinInMinutes: FirstBlockEarlyJoinInMinutes || '',
      ApplicationSystem: ApplicationSystem || '',
      ProgramType: ProgramType || '',
      TimeZone: GMT || '',
    };

    let noChanges = true;

    Object.keys(deptNew).forEach((key) => {
      const oldValue = deptCopy[key] || '';
      const newValue = deptNew[key] || '';

      if (oldValue != newValue) {
        noChanges = false;
      }
    });
    return noChanges;
  };

  return (
    <div style={style.simpleRow}>
      <div style={{ ...style.simpleColumn, width: '100%' }}>
        <div style={style.simpleRow}>
          <Card className="shadow" style={{ marginBottom: 30, width: '100%' }}>
            <CardBody>
              <div style={{ ...style.simpleRow, alignItems: 'center', justifyContent: 'center' }}>
                <div style={{ ...style.simpleColumn, width: 360 }}>
                  {' '}
                  <UserPhoto
                    imgStyle={{ width: 350, objectFit: 'scale-down' }}
                    id={department ? department.pk_Department : null}
                    photoUrl={DepartmentLogo}
                    setDeptConfig={setDeptConfig}
                    callback={(id, photoURL) => {
                      // handleChange('UserPhotoUrl', photoURL);
                      if (photoURL) {
                        const newDepartment = clone(dContext.department);
                        newDepartment.DepartmentLogo = photoURL;
                        dContext.setDepartment(newDepartment);
                      }
                    }}
                    type={UserPhotoTypes.DepartmentLogo}
                  />
                </div>
                <div style={{ ...style.simpleColumn, width: '50%', padding: 20 }}>
                  <div style={style.inputContainers}>
                    <Label style={style.inputLabel} for="departmentName">
                      Program Name
                    </Label>
                    <Input
                      disabled={isGettingSettings}
                      id="departmentName"
                      type="text"
                      style={style.inputs}
                      placeholder="Program Name"
                      value={DepartmentName || ''}
                      onChange={(e) => {
                        setDepartmentName(e.target.value);
                      }}
                    />
                  </div>
                  <div style={style.inputContainers}>
                    <Label style={style.inputLabel} for="email">
                      Reply Email
                    </Label>
                    <Input
                      disabled={isGettingSettings}
                      style={style.inputs}
                      id="support_email"
                      type="email"
                      value={DepartmentEmail || ''}
                      placeholder="Email"
                      onChange={(e) => {
                        setDepartmentEmail(e.target.value);
                        // handleChange('DepartmentEmail', e.target.value);
                      }}
                    />
                  </div>

                  <div style={style.inputContainers}>
                    <Label style={style.inputLabel} for="customDisplayName">
                      Replay Email Display Name
                    </Label>
                    <Input
                      disabled={isGettingSettings}
                      style={style.inputs}
                      id="customDisplayName"
                      type="text"
                      value={ReplyAddressDisplayName || ''}
                      placeholder={DepartmentName}
                      onChange={(e) => {
                        setReplyAddressDisplayName(e.target.value);
                      }}
                    />
                  </div>

                  <div style={style.inputContainers}>
                    <Label style={style.inputLabel} for="phone_number">
                      Phone Number
                    </Label>
                    <Input
                      disabled={isGettingSettings}
                      style={style.inputs}
                      id="phone_number"
                      type="phone"
                      value={DepartmentPhone || ''}
                      placeholder="Phone number"
                      onChange={(e) => {
                        setDepartmentPhone(e.target.value);
                        //handleChange('DepartmentPhone', e.target.value);
                      }}
                    />
                  </div>
                  <div style={style.inputContainers}>
                    <Label style={style.inputLabel} for="website">
                      Website
                    </Label>
                    <Input
                      disabled={isGettingSettings}
                      style={style.inputs}
                      id="website"
                      type="text"
                      value={DepartmentWebsite || ''}
                      placeholder="Website"
                      onChange={(e) => {
                        setDepartmentWebsite(e.target.value);
                        // handleChange('DepartmentWebsite', e.target.value);
                      }}
                    />
                  </div>
                </div>
                <div style={{ ...style.simpleColumn, width: '50%', padding: 20 }}>
                  <div style={style.inputContainers}>
                    <Label style={style.inputLabel} for="add_1">
                      Address 1
                    </Label>
                    <Input
                      disabled={isGettingSettings}
                      style={style.inputs}
                      autocomplete={`field_add1`}
                      id="add_1"
                      type="text"
                      value={DepartmentAddressLine1 || ''}
                      placeholder="Address Line 1"
                      onChange={(e) => {
                        setDepartmentAddressLine1(e.target.value);
                        // handleChange('DepartmentAddressLine1', e.target.value);
                      }}
                    />
                  </div>
                  <div style={style.inputContainers}>
                    <Label style={style.inputLabel} for="add_2">
                      Address 2
                    </Label>
                    <Input
                      disabled={isGettingSettings}
                      style={style.inputs}
                      autocomplete={`field_add2`}
                      id="add_2"
                      type="text"
                      value={DepartmentAddressLine2 || ''}
                      placeholder="Address Line 2"
                      onChange={(e) => {
                        setDepartmentAddressLine2(e.target.value);
                        // handleChange('DepartmentAddressLine2', e.target.value);
                      }}
                    />
                  </div>
                  <div style={style.inputContainers}>
                    <Label style={style.inputLabel} for="cntry">
                      Country
                    </Label>
                    <Input
                      disabled={isGettingSettings}
                      style={style.inputs}
                      autocomplete={`field_cntry`}
                      id="cntry"
                      type="text"
                      defaultValue={'USA'}
                      value={DepartmentCountry}
                      placeholder="Country?"
                      onChange={(e) => {
                        setDepartmentCountry(e.target.value);
                        // handleChange('country', e.target.value);
                      }}
                    />
                  </div>
                  <div style={style.inputContainers}>
                    <Label style={style.inputLabel} for="zip">
                      Zip Code
                    </Label>
                    <Input
                      disabled={isGettingSettings}
                      style={style.inputs}
                      id="zip"
                      type="text"
                      value={DepartmentZipCode || ''}
                      placeholder="ZIP Code"
                      onChange={(e) => {
                        setDepartmentZipCode(e.target.value);
                        // handleChange('DepartmentZipCode', e.target.value);
                      }}
                    />
                  </div>
                </div>
              </div>
              <div style={style.simpleRow}>
                <Card style={{ width: 'calc(100% - 360px)', marginLeft: 360 }}>
                  <CardBody style={{ minHeight: '300px' }}>
                    <div>
                      <div style={{ ...style.simpleRow, justifyContent: 'end' }}>
                        <div style={{ ...style.simpleColumn, width: '50%', marginRight: 20 }}>
                          <Card>
                            <CardBody>
                              <div style={{ ...style.simpleColumn, width: '100%', marginRight: 20 }}>
                                <div style={style.simpleRow}>
                                  <div style={{ ...style.simpleColumn, width: '100%', marginRight: 20 }}>
                                    <div style={style.inputContainers}>
                                      <Label for="VirtualMeetingMode"> Virtual Meeting Mode:</Label>
                                      <Input
                                        disabled={isGettingSettings}
                                        type="select"
                                        name="select"
                                        id="VirtualMeetingMode"
                                        value={VirtualMeetingMode}
                                        onChange={(e) => {
                                          setVirtualMeetingMode(e.target.value);
                                        }}
                                      >
                                        {virtualMeetingModes.map((mode) => {
                                          return <option value={mode.value}>{mode.label}</option>;
                                        })}
                                      </Input>
                                    </div>
                                  </div>
                                </div>
                                {VirtualMeetingMode === 'Internal' ? (
                                  <>
                                    <div style={style.simpleRow}>
                                      <div style={{ ...style.simpleColumn, width: '100%', marginRight: 20 }}>
                                        <div style={style.inputContainers}>
                                          <Label for="TerminateVirtualMeeting"> Terminate Virtual Meetings at: </Label>
                                          <Input
                                            disabled={VirtualMeetingMode !== 'RezRATE Managed Meetings'}
                                            type="select"
                                            name="select"
                                            id="TerminateVirtualMeeting"
                                            value={'End of Passing'} // value={TerminateVirtualMeeting}
                                            onChange={(e) => {
                                              setTerminateVirtualMeeting(e.target.value);
                                            }}
                                          >
                                            <option value="End of Meeting">End of Meeting</option>
                                            <option value="End of Passing">End of Passing period</option>
                                          </Input>
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                ) : (
                                  <div style={{ height: 76 }} />
                                )}
                                <div style={style.simpleRow}>
                                  <div style={{ ...style.simpleColumn, width: '100%', marginRight: 20 }}>
                                    <div style={style.inputContainers}>
                                      <Label for="firstJoin"> Display First Join Meeting Link </Label>
                                      <Input
                                        disabled={VirtualMeetingMode === 'Disabled' || isGettingSettings}
                                        type="select"
                                        name="select"
                                        id="firstJoin"
                                        value={FirstBlockEarlyJoinInMinutes}
                                        onChange={(e) => {
                                          setFirstBlockEarlyJoinInMinutes(e.target.value);
                                        }}
                                      >
                                        {joinMeetingMinutes.map((choice) => {
                                          return <option value={choice}>{`${choice} minutes early.`}</option>;
                                        })}
                                      </Input>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </CardBody>
                          </Card>
                        </div>

                        <div style={{ ...style.simpleColumn, width: '50%', marginLeft: 30 }}>
                          <Card>
                            <CardBody>
                              <div style={style.simpleRow}>
                                <div style={{ ...style.simpleColumn, width: '50%', marginRight: 20 }}>
                                  <div style={style.inputContainers}>
                                    <Label for="matchType">Program Type</Label>
                                    {ProgramType === 'Mock Oral Board' || ProgramType === 'Staffing' ? (
                                      <div style={{ fontWeight: 'bold' }}>{ProgramType}</div>
                                    ) : (
                                      <Input
                                        disabled={isGettingSettings}
                                        id="ProgramType"
                                        type="select"
                                        value={ProgramType}
                                        placeholder="Program Type"
                                        onChange={(e) => {
                                          const newValue = e.target.value;
                                          setProgramType(e.target.value);
                                        }}
                                      >
                                        {programTypes.map((ProgramType) => {
                                          if (ProgramType === 'Mock Oral Board' || ProgramType === 'Staffing')
                                            return null;

                                          return (
                                            <option key={ProgramType} value={ProgramType}>
                                              {ProgramType}
                                            </option>
                                          );
                                        })}
                                      </Input>
                                    )}
                                  </div>
                                </div>
                                <div style={{ ...style.simpleColumn, width: '45%' }}>
                                  {ProgramType === 'Mock Oral Board' || ProgramType === 'Staffing' ? null : (
                                    <div style={style.inputContainers}>
                                      <Label for="applicationsystems">Application System</Label>
                                      <Input
                                        disabled={isGettingSettings}
                                        id="applicationsystems"
                                        type="select"
                                        value={ApplicationSystem}
                                        placeholder="Application System"
                                        onChange={(e) => {
                                          const newValue = e.target.value;
                                          setApplicationSystem(e.target.value);
                                        }}
                                      >
                                        {applicationSystems.map((ApplicationSystem) => {
                                          return (
                                            <option
                                              key={ApplicationSystem}
                                              value={ApplicationSystem.toLocaleLowerCase().replace(/ /g, '_')}
                                            >
                                              {ApplicationSystem}
                                            </option>
                                          );
                                        })}
                                      </Input>
                                    </div>
                                  )}
                                </div>
                              </div>

                              <div style={style.simpleRow}>
                                <div style={{ ...style.simpleColumn, width: '50%', marginRight: 20 }}>
                                  <div style={style.inputContainers}>
                                    <Label for="timezone">Timezone</Label>
                                    <Input
                                      disabled={isGettingSettings}
                                      id="timezone"
                                      type="select"
                                      placeholder="GMT"
                                      value={GMT}
                                      onChange={(e) => {
                                        const newTime = moment.tz(moment.now(), e.target.value).format('HH:mm');
                                        const newGMT = e.target.value;
                                        setTime(newTime);
                                        setGMT(e.target.value);
                                      }}
                                    >
                                      {moment.tz.names().map((name) => {
                                        if (name.indexOf('US/') >= 0) {
                                          const z =
                                            moment()
                                              .tz(name)
                                              .utcOffset() / 60;
                                          return (
                                            <option value={name}>
                                              {name} (GMT {z > 0 ? `+ ${z}` : z})
                                            </option>
                                          );
                                        }
                                      })}
                                    </Input>
                                  </div>
                                </div>
                                <div style={{ ...style.simpleColumn, width: '45%' }}>
                                  <div style={style.inputContainers}>
                                    <Label for="Time">Time</Label>
                                    <Input
                                      disabled
                                      id="Time"
                                      type="time"
                                      // defaultValue={'1:02'}
                                      placeholder="Time"
                                      value={Time}
                                      onChange={(e) => {
                                        //   handleChange('time', e.target.value);
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>

                              <div style={style.simpleRow}>
                                <div style={{ ...style.simpleColumn, width: '100%', marginRight: 20 }}>
                                  <div style={style.inputContainers}>
                                    <Label for="candidateSubHeading">
                                      Candidate Subheading{' '}
                                      <FontAwesomeIcon
                                        icon={faInfoCircle}
                                        id={'candidateSubHeadingInfo'}
                                        style={{ cursor: 'pointer' }}
                                      />{' '}
                                      <UncontrolledTooltip placement="right" target="candidateSubHeadingInfo">
                                        Candidate Subheading indicates which field you would like to display under the
                                        candidate's name on the evaluator schedule, rank list, and on reports.
                                      </UncontrolledTooltip>
                                    </Label>
                                    <Input
                                      type="select"
                                      value={CandidateSubheadingField}
                                      onChange={(e) => {
                                        setCandidateSubheadingField(e.target.value);
                                      }}
                                    >
                                      <option value={''} disabled>
                                        {' '}
                                        Select Field
                                      </option>
                                      <option value="MedicalSchoolOfGraduation"> Medical School Of Graduation</option>
                                      <option value="LastResidency">Last Residency</option>
                                      <option value="CurrentResidency">Current Residency</option>
                                    </Input>
                                  </div>
                                </div>
                              </div>
                              {EnableSuperUser ? (
                                <div style={style.simpleRow}>
                                  <div style={{ ...style.simpleColumn, width: '50%', marginRight: 20 }}>
                                    <div style={style.inputContainers}>
                                      <Label for="timezone">Tag Filtering</Label>
                                      <CustomInput
                                        disabled={isGettingSettings}
                                        id="allowTagFiltering"
                                        type="switch"
                                        size="sm"
                                        label={
                                          <div
                                            style={{ color: EnableTagFiltering ? 'green' : 'gray', fontWeight: 'bold' }}
                                          >
                                            {EnableTagFiltering ? 'Enabled' : 'Disabled'}{' '}
                                          </div>
                                        }
                                        checked={EnableTagFiltering}
                                        onChange={(e) => {
                                          setEnableTagFiltering(e.target.checked);
                                        }}
                                      />
                                    </div>
                                  </div>
                                  {ProgramType === 'Mock Oral Board' ? (
                                    <div style={{ ...style.simpleColumn, width: '50%', marginRight: 20 }}>
                                      <div style={style.inputContainers}>
                                        <Label for="candidateGroups">Candidate Groups</Label>
                                        <CustomInput
                                          disabled={isGettingSettings}
                                          id="candidateGroups"
                                          type="switch"
                                          size="sm"
                                          label={
                                            <div
                                              style={{
                                                color: EnableGroups ? 'green' : 'gray',
                                                fontWeight: 'bold',
                                              }}
                                            >
                                              {EnableGroups ? 'Enabled' : 'Disabled'}
                                            </div>
                                          }
                                          checked={EnableGroups}
                                          onChange={(e) => {
                                            setEnableGroups(e.target.checked);
                                          }}
                                        />
                                      </div>
                                    </div>
                                  ) : null}
                                </div>
                              ) : null}
                            </CardBody>
                          </Card>
                        </div>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </div>
              <div style={{ ...style.simpleRow, justifyContent: 'center', marginTop: 30 }}>
                <Button
                  disabled={noChanges() || isSaving || isGettingSettings}
                  style={{ width: 200 }}
                  color="success"
                  onClick={() => {
                    saveSettings();
                  }}
                >
                  Save
                </Button>
              </div>
            </CardBody>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default SettingsPopover;
