const style = {
  topNavButton: {
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 20,
    paddignRight: 20,
    marginRight: 20,
  },
  tableContainer: (scheduleData, windowWidth, hasFlexColumn) => {
    const { headers = [] } = scheduleData || {};
    const styleActual = {
      marginTop: 10,
      display: 'flex',
      flexDirection: 'row',
      width: '100%',
      height: 'calc(100vh - 420px)',
      overflowY: 'scroll',
    };
    let columnCount = headers.length;
    if (hasFlexColumn) {
      columnCount += 1;
    }

    const minimumTableWidth = 180 * columnCount + 90;
    const windowWidthActual = windowWidth ? windowWidth : window.innerWidth - 40; // 40 is estimated borders

    if (minimumTableWidth > windowWidthActual) {
      styleActual.overflowX = 'scroll';
    }

    return styleActual;
  },
  headerStyle: {
    cursor: 'pointer',
    fontWeight: 'bold',
    // width: 'inherit',
    // overflow: 'hidden',
    // textOverflow: 'ellipsis',
  },
  simpleRow: {
    display: 'flex',
    flexDirection: 'row',
  },
  simpleColumn: {
    display: 'flex',
    flexDirection: 'column',
  },
  spacedBetweenRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  buttonIcon: {
    marginRight: 10,
  },
  addColumnIcon: {
    fontSize: 25,
    cursor: 'pointer',
  },
  flexEndRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  clickableIcon: {
    cursor: 'pointer',
  },
  loadingContainer: {
    marginTop: '10%',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    flexDirecton: 'column',
  },
  loadingScheduleContainer: {
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    flexDirecton: 'column',
  },
  loadingContent: {
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
  },
  candidateLabel: {
    borderRadius: '8px',
    fontWeight: 500,
    padding: 0,
    display: 'flex',
    flexDirection: 'row',
  },
  splitCell: (isCurrentTime) => {
    const styleActual = {
      borderTop: '2px dashed #eb4034',
      marginTop: 5,
      paddingTop: 5,
    };

    if (isCurrentTime) {
      styleActual.border = '2px solid green';
      styleActual.paddingBottom = 5;
    } else {
      styleActual.borderTop = '2px dashed #eb4034';
    }
    return styleActual;
  },
  centeredLabel: {
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    width: '100%',
  },
  loadingIndicator: {
    fontWeight: 600,
    fontSize: 20,
  },
  genButton: {
    alignItems: 'center',
    backgroundColor: '#0a91ff',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginLeft: 10,
    marginRight: 10,
    paddingLeft: 20,
    paddignRight: 20,
    paddingBottom: 10,
    paddingTop: 10,
    minWidth: 130,
  },
  previewButtonPanel: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    padding: '20px, 10px',
    width: '100%',
    borderRadius: 20,
    marginBottom: 10,
  },
  splitCellButtonRow: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 20,
  },
  buttonLabel: { width: '100%', textAlign: 'center', fontWeight: 600 },
  ellipsis: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    textAlign: 'center',
    width: 'calc(100% - 45px)',
  },
};

export default style;
