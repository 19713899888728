import { faCheckSquare, faCircleDot, faInfoCircle, faSquare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import { Button, CustomInput, Input, Label, Popover, PopoverBody, PopoverHeader } from 'reactstrap';
import { useAuth0 } from '../../../../../auth0/reactAuth0Spa.js';
import { formatBearerToken, swalErrorMessage } from '../../../../../Common.functions.js';
import { putData } from '../../../../../Services/dataApi.js';
import style from './style.js';
import Swal from 'sweetalert2';
import { clone } from 'lodash';
import { useAlert } from 'react-alert';
import { faCircle } from '@fortawesome/free-regular-svg-icons';

let updateTimeout = null;
let oldData = {};

const RegistrationLimitPopover = ({
  setShowSettings,
  isUpdating,
  updateEntry,
  data,
  dContext,
  token,
  tags = [],
  setTagFilterHasChanges,
  getActiveInterviewDates,
}) => {
  const [limitByTags, setLimitByTags] = useState();
  const [limit, setLimit] = useState();
  const [tagToLimit, setTagToLimit] = useState();

  const [tagLimits, setTagLimits] = useState({});
  const [showInfo, setShowInfo] = useState(false);

  const { getTokenSilently } = useAuth0();
  const { TagToLimit } = data;

  const alert = useAlert();

  useEffect(() => {
    if (data) {
      setTagToLimit(data.pk_Tag);
    }
  }, []);
  useEffect(() => {
    if (tags && data && data.tags) {
      const newTags = [];
      const dataTags = data.tags;

      tags.forEach((tag) => {
        if (tag.Tag) {
          const tagWithLimit = dataTags.find((dTag) => {
            return dTag.pk_Tag == tag.pk_Tag;
          });

          newTags.push({
            ...tag,
            InterviewDateLimit: tagWithLimit && tagWithLimit.InterviewDateLimit ? tagWithLimit.InterviewDateLimit : 0,
          });
        }
      });
      setTagLimits(newTags);
    }
  }, [tags, data]);

  useEffect(() => {
    if (data) {
      setLimitByTags(data.EnableTagLimit);
      setLimit(data.CandidateRegistrationLimit);
      let hasDataChange = false;

      Object.keys(data).forEach((key) => {
        if (data[key] != oldData[key]) {
          hasDataChange = true;
        }
      });

      if (hasDataChange) {
        oldData = clone(data);
        setTagToLimit(data.pk_Tag);
      }
    }
  }, [data]);

  useEffect(() => {
    setTagFilterHasChanges(tagToLimit != data.pk_Tag);
  }, [tagToLimit]);

  const updateTagLimit = (pk_Tag, limit) => {
    // New Update Tag should be on the PUT for the interview date
    if (updateTimeout) {
      clearTimeout(updateTimeout);
    }
    updateTimeout = setTimeout(() => {
      getTokenSilently()
        .then((token) => {
          putData(
            'department/tags/limit',
            { pk_Department: dContext.department.pk_Department, pk_InterviewDate: data.pk_InterviewDate, limit },
            { tagsToUpdate: [pk_Tag] },
            formatBearerToken(token),
          )
            .then((res) => {
              getActiveInterviewDates();
            })
            .catch((err) => {
              swalErrorMessage();
            });
        })
        .catch((err) => {});
    }, 700);
  };

  const renderInfoPopover = () => {
    return (
      <Popover
        flip={false}
        isOpen={showInfo}
        // popperClassName="importPopover"
        trigger="click"
        target="infoButton"
      >
        <PopoverHeader>
          <p style={{ textAlign: 'center' }} id="infoHeader">
            <b>About Interview Date Tag Limits</b>
          </p>
        </PopoverHeader>
        <PopoverBody>
          <div
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <p id="infoBody1">
              Tags will be considered when candidates register for interview sessions. Enabling Limit by tags restricts
              visibility of interview dates to enabled tags and only allow the set number of candidates with a given tag
              to register for this session.
            </p>
            <p id="infoBody2">
              Note: candidates with multiple tags will count toward each corresponding category. We recommend you use
              mutually exclusive tags if you enable this feature.
            </p>
          </div>
        </PopoverBody>
      </Popover>
    );
  };

  const cellFormatter = (cell, row, rowIndex, formatExtraData) => {
    if (formatExtraData.label === 'Limit') {
      return (
        <div>
          <Input
            disabled={isUpdating}
            type="number"
            value={cell || 0}
            style={{
              textAlign: 'center',
            }}
            onClick={() => {}}
            onChange={(e) => {
              let value = parseInt(e.target.value);
              if (value < 0) {
                return;
              }
              const newValue = value >= 0 ? value : 0;
              const newTagLimits = tagLimits.slice();
              const index = newTagLimits.findIndex((tag) => {
                return tag.pk_Tag == row.pk_Tag;
              });
              if (index != null) {
                newTagLimits[index].InterviewDateLimit = newValue;
              }
              setTagLimits(newTagLimits);
              updateTagLimit(row.pk_Tag, newValue);
              // API
              // setLimit(e.target.limit);
            }}
          />
        </div>
      );
    } else {
      return <div style={{ textAlign: 'center' }}>{cell}</div>;
    }
  };

  const makeColumns = () => {
    const headers = [
      { text: 'Tag', dataField: 'Tag' },
      { text: 'Limit', dataField: 'InterviewDateLimit' },
    ];
    const newColumns = [];

    headers.forEach((header) => {
      newColumns.push({
        dataField: header.dataField,
        text: header.text,
        label: header.text,
        formatter: cellFormatter,
        // headerFormatter: headerFormatter,
        style: { verticalAlign: 'middle' },
        headerStyle: (column, colIndex) => {
          const style = {
            verticalAlign: 'middle',
            textAlign: 'center',
            position: 'sticky',
            top: -1,
            backgroundColor: 'white',
          };
          if (column.text === 'Limit') {
            style.width = 100;
          }
          return style;
        },
        formatExtraData: { label: header.text },
        events: {
          onClick: (e, column, columnIndex, row, rowIndex) => {},
        },
        headerEvents: {
          onClick: (e, column, columnIndex) => {},
        },
        headerAttrs: {
          // id: 'timeSlotHeader',
        },
        attrs: (cell, row, rowIndex, colIndex) => {
          // return { id: `timeSlot_${row.pk_Timeslot}` };
        },
      });
    });

    return newColumns;
  };

  const renderLimitByTags = () => {
    const { pk_Tag, Tag } = data;

    return (
      <div>
        <div
          style={{
            ...style.simpleColumn,
            alignItems: 'center',
            width: '100%',
            overflowY: 'scroll',
            height: 230,
            marginBottom: 5,
          }}
        >
          <div
            style={{
              ...style.simpleRow,
              justifyContent: 'space-between',
              width: '100%',
              backgroundColor: tagToLimit == null ? 'lightblue' : 'lightgray',
              cursor: 'pointer',
            }}
            onClick={() => {
              setTagToLimit(null);
            }}
          >
            <div style={{ ...style.simpleColumn, width: 50, justifyContent: 'center', alignItems: 'center' }}>
              <FontAwesomeIcon icon={tagToLimit == null ? faCircleDot : faCircle} />
            </div>
            <div style={{ ...style.simpleColumn, width: '100%', justifyContent: 'center', alignItems: 'left' }}>
              Visible To All
            </div>
          </div>
          {tagLimits && tagLimits.length > 0
            ? tagLimits.map((tag, i) => {
                const { Tag } = tag;
                const backgroundColor = i % 2 == 0 ? 'white' : 'lightgray';

                return (
                  <div
                    style={{
                      ...style.simpleRow,
                      justifyContent: 'space-between',
                      width: '100%',
                      backgroundColor: tagToLimit == tag.pk_Tag ? 'lightblue' : backgroundColor,
                    }}
                    onClick={() => {
                      setTagToLimit(tag.pk_Tag);
                    }}
                  >
                    <div
                      style={{
                        ...style.simpleColumn,
                        width: 50,
                        justifyContent: 'center',
                        alignItems: 'center',
                        cursor: 'pointer',
                      }}
                    >
                      <FontAwesomeIcon icon={tagToLimit == tag.pk_Tag ? faCircleDot : faCircle} />
                    </div>
                    <div style={{ ...style.simpleColumn, width: '100%', justifyContent: 'center', alignItems: 'left' }}>
                      {Tag}
                    </div>
                  </div>
                );
              })
            : null}
        </div>
        <p style={{ textAlign: 'center' }}>
          <b>
            {pk_Tag
              ? `Session is only visible to candidates with ${Tag} Tag.`
              : 'Session is visible to all candidates.'}
          </b>
        </p>
        <div style={{ ...style.simpleRow, alignItems: 'center', justifyContent: 'center' }}>
          <Button
            disabled={data.pk_Tag == tagToLimit}
            style={{ width: 120 }}
            color="success"
            onClick={() => {
              updateEntry({ pk_Tag: tagToLimit }, data.pk_InterviewDate, (res) => {
                if (res) {
                  alert.success('Updated Session Tag Limit!');
                } else {
                  alert.error('Failed to Update Session Tag Limit!');
                }
                setShowSettings(false);
              });
            }}
          >
            Save
          </Button>
        </div>
      </div>
    );
  };

  return (
    <div
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      {renderLimitByTags()}
    </div>
  );
};

export default RegistrationLimitPopover;
