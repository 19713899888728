import { faCheckSquare, faSquare } from '@fortawesome/free-regular-svg-icons';
import {
  faCheck,
  faClipboardList,
  faEnvelope,
  faHandPaper,
  faThumbsDown,
  faTimes,
  faUserCheck,
  faUserTimes,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DepartmentContext } from 'DepartmentWrapper';
import { getElementById } from 'domutils';
import { CandidateStatusIcons } from 'enums';
import { clone } from 'lodash';
import React, { useEffect, useRef, useState, useContext } from 'react';
import { Button, CustomInput, Popover, PopoverBody, PopoverHeader, UncontrolledTooltip } from 'reactstrap';

const MarkPopover = ({
  showStatusPopover,
  setShowStatusPopover,
  selectedCandidate,
  saveResults,
  isUpdatingStatus,
  setIsUpdatingStatus,
}) => {
  const [fieldsToUpdate, setFieldsToUpdate] = useState({});

  const dContext = useContext(DepartmentContext);
  const { season } = dContext || {};
  const { AllowPrescreen } = season || {};

  const {
    pk_Candidate,
    EnableMarkedForInvitation,
    EnableMarkedForPrescreen,
    HasBeenSentInvitation,
    IsPreScreen,
    HasDeclinedInterview,
    EnableRespondedToInvitation,
    IsWaitlisted,
    IsScheduled,
    HasBeenSentConfirmation,
    HasBeenRejectedForInterview,
    WaitListConfirmationHasBeenSent,
  } = selectedCandidate;

  const {
    pendingInvite,
    invited,
    markedForInvite,
    waitlisted,
    confirmed,
    unconfirmed,
    waitlistConfirmation,
    waitlistConfirmationUnsent,
    rejected,
    scheduled,
    prescreen,
    declined,
  } = CandidateStatusIcons;

  const statusToSetup = [
    {
      booleanString: 'EnableMarkedForPrescreen',
      label: 'Mark as Prescreen',
      icon: prescreen.icon,
      editable: AllowPrescreen,
      switchIsVisible: true,
      color: prescreen.color,
    },
    {
      booleanString: 'EnableMarkedForInvitation',
      label: 'Mark for Invitation',
      icon: markedForInvite.icon,
      editable: AllowPrescreen,
      switchIsVisible: true,
      color: markedForInvite.color,
    },
    {
      booleanString: 'EnableMarkedForInvitation',
      label: 'Pending Invite',
      icon: pendingInvite.icon,
      editable: false,
      switchIsVisible: false,
      color: pendingInvite.color,
    },
    {
      booleanString: 'HasBeenSentInvitation',
      label: 'Invited',
      icon: invited.icon,
      editable: false,
      switchIsVisible: false,
      color: invited.color,
    },
    {
      booleanString: 'IsScheduled',
      label: 'Registered',
      icon: scheduled.icon,
      editable: false,
      switchIsVisible: false,
      color: scheduled.color,
    },

    {
      booleanString: 'HasBeenSentConfirmation',
      label: 'Registration Confirmation Sent',
      icon: HasBeenSentConfirmation ? confirmed.icon : unconfirmed.icon,
      editable: false,
      switchIsVisible: false,
      color: HasBeenSentConfirmation ? confirmed.color : unconfirmed.color,
    },
    {
      booleanString: 'WaitListConfirmationHasBeenSent',
      label: 'Waitlist Confirmation Sent',
      icon: WaitListConfirmationHasBeenSent ? waitlistConfirmation.icon : waitlistConfirmationUnsent.icon,
      editable: false,
      switchIsVisible: false,
      color: WaitListConfirmationHasBeenSent ? waitlistConfirmation.color : waitlistConfirmationUnsent.color,
    },
    {
      booleanString: 'IsWaitlisted',
      label: 'Waitlisted',
      icon: waitlisted.icon,
      editable: false,
      switchIsVisible: false,
      color: waitlisted.color,
    },
    {
      booleanString: 'HasBeenRejectedForInterview',
      label: 'Rejected',
      icon: rejected.icon,
      editable: true,
      switchIsVisible: true,
      color: rejected.color,
    },
    {
      booleanString: 'HasDeclinedInterview',
      label: 'Declined',
      icon: declined.icon,
      editable: false,
      switchIsVisible: false,
      color: declined.color,
    },
  ];

  useEffect(() => {
    if (selectedCandidate) {
      setFieldsToUpdate({});
    }
  }, [selectedCandidate]);

  if (document.getElementById(`edit_${pk_Candidate}`) == null) {
    return <div></div>;
  }

  let hasChange = false;
  Object.keys(fieldsToUpdate).forEach((key) => {
    if (fieldsToUpdate[key] != selectedCandidate[key]) {
      hasChange = true;
    }
  });

  return (
    <Popover
      target={`edit_${pk_Candidate}`}
      isOpen={selectedCandidate && showStatusPopover}
      placementPrefix="widgetMarkPopover bs-popover"
      trigger="legacy"
      placement="right"
      flip={false}
      modifiers={{ preventOverflow: { boundariesElement: 'viewport' } }}
      toggle={() => {
        setShowStatusPopover(false);
      }}
    >
      <PopoverHeader>Current Status:</PopoverHeader>
      <PopoverBody>
        <div style={{ display: 'flex', flexDirection: 'row', marginBottom: 10 }}></div>
        {statusToSetup.map((status, i) => {
          let currentValue =
            fieldsToUpdate[status.booleanString] != null
              ? fieldsToUpdate[status.booleanString]
              : selectedCandidate[status.booleanString];

          const isPreScreenSwitch = status.label === 'Mark as Prescreen';
          if (!AllowPrescreen) {
            if (isPreScreenSwitch) {
              currentValue = false;
            }

            if (status.label === 'Mark for Invitation') {
              currentValue = true;
            }
          }

          if (status.label === 'Pending Invite' && (HasBeenSentInvitation || IsScheduled)) {
            currentValue = false;
          }

          return (
            <>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  fontSize: 16,
                  fontWeight: 'bold',
                  backgroundColor: i % 2 == 0 ? 'white' : '#dee2e6',
                  cursor: status.editable ? 'pointer' : 'not-allowed',
                }}
                onClick={() => {
                  if (!status.editable) {
                    return;
                  }
                  const newFieldsToUpdate = clone(fieldsToUpdate);
                  newFieldsToUpdate[status.booleanString] = !currentValue;
                  setFieldsToUpdate(newFieldsToUpdate);
                }}
              >
                <div
                  id={`${status.booleanString}_${status.label.replaceAll(' ', '_')}`}
                  style={{ display: 'flex', flexDirection: 'column', width: '100%' }}
                >
                  <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: 50,
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <FontAwesomeIcon icon={status.icon} style={{ color: status.color }} />
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>{status.label}</div>

                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: 50,
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <FontAwesomeIcon
                        icon={currentValue ? faCheck : faTimes}
                        style={{
                          fontSize: 15,
                          justifyContent: 'center',
                          alignItems: 'center',
                          color: currentValue ? 'green' : 'red',
                        }}
                      />
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', width: 50 }}>
                      {status.editable || status.switchIsVisible ? (
                        <>
                          <CustomInput
                            type={'switch'}
                            checked={currentValue}
                            disabled={!status.editable}
                            id={`${status.label}`}
                            onClick={(e) => {
                              e.stopPropagation();
                            }}
                          />
                        </>
                      ) : null}
                    </div>
                  </div>
                </div>
                {!status.editable && !status.switchIsVisible ? (
                  <UncontrolledTooltip target={`${status.booleanString}_${status.label.replaceAll(' ', '_')}`}>
                    {'This cannot be modified. '}
                    {status.booleanString === 'EnableMarkedForInvitation' && HasBeenSentInvitation
                      ? 'Candidate has already been sent an invitation. '
                      : ''}
                    {isPreScreenSwitch && !AllowPrescreen ? 'Prescreen with RezRATE is Disabled.' : ''}
                  </UncontrolledTooltip>
                ) : null}

                {!AllowPrescreen ? (
                  <UncontrolledTooltip target={`${status.booleanString}_${status.label.replaceAll(' ', '_')}`}>
                    Prescreen is disabled. All candidates are marked for invite.
                  </UncontrolledTooltip>
                ) : null}
              </div>
            </>
          );
        })}
        <div style={{ display: 'flex', flexDirection: 'row', marginTop: 20 }}>
          <Button
            disabled={isUpdatingStatus || !hasChange}
            color="success"
            style={{ width: '50%', marginRight: 10 }}
            onClick={() => {
              setIsUpdatingStatus(true);
              saveResults({
                candidatesToUpdate: [selectedCandidate.pk_Candidate],
                forcedSelectedCandidateId: selectedCandidate.pk_Candidate,
                fieldsToUpdate,
                callback: () => {
                  setIsUpdatingStatus(false);
                  setShowStatusPopover(false);
                },
              });
            }}
          >
            {' '}
            Save{' '}
          </Button>{' '}
          <Button
            disabled={isUpdatingStatus}
            style={{ width: '50%' }}
            onClick={() => {
              setShowStatusPopover(false);
            }}
            color="secondary"
          >
            {' '}
            Cancel{' '}
          </Button>
        </div>
      </PopoverBody>
    </Popover>
  );
};

export default MarkPopover;
