import React, { useEffect, useState } from 'react';
import { Col, Form, FormFeedback, FormGroup, Input, Label, Row } from 'reactstrap';

const TextQuestion = ({
  question,
  updateEval,
  answerText,
  i,
  setIsTyping,
  checkIfStatusNeedsChangingToModify,
  questionaireDisabled,
}) => {
  const [text, setText] = useState('');
  const [typingTimeout, setTypingTimeout] = useState(0);
  const [oldValue, setOldValue] = useState('');

  const textLimit = 2500; // should be 2500

  useEffect(() => {
    console.log('question: ', question);
    setText(question.AnswerText || '');
    setOldValue(question.AnswerText || '');
    const questionField = document.getElementById(`question_${question.pk_EvaluationQuestion}`);
    if (questionField) {
      questionField.value = question.AnswerText;
    }
  }, [question]);

  const postAnswer = (id, value) => {
    setOldValue(question.AnswerText || '');
    updateEval(value, id, 'text', (res) => {
      if (res.error) {
        revert();
      } else {
        setOldValue(text || '');
      }
    });
  };

  const revert = () => {
    console.log('reverting to: ', oldValue);
    setText(oldValue);
  };

  const changeName = async (event) => {
    let placeholder = event.target.value || '';

    const continueWorkflow = await checkIfStatusNeedsChangingToModify();

    if (continueWorkflow && placeholder.length >= textLimit) {
      if (placeholder.length > textLimit) {
        placeholder = text;
      }
      placeholder = placeholder.substring(0, textLimit);

      // const questionField = document.getElementById(`question_${question.pk_EvaluationQuestion}`);

      // if (questionField) {
      //   const oldSelectionStart = questionField.selectionStart - (oldCount - placeholder.length);
      //   const oldSelectionEnd = questionField.selectionEnd - (oldCount - placeholder.length);

      //   // questionField.value = placeholder;
      //   // questionField.setSelectionRange(oldSelectionStart, oldSelectionEnd);
      // }

      if (text !== placeholder) {
        setIsTyping(true);
        if (typingTimeout) {
          clearTimeout(typingTimeout);
        }
        setTypingTimeout(
          setTimeout(function() {
            const { pk_EvaluationQuestion } = question;
            postAnswer(pk_EvaluationQuestion, placeholder);
          }, 2 * 1000),
        );
      }

      setText(placeholder || '');
      return;
    } else if (!continueWorkflow) {
      const questionField = document.getElementById(`question_${question.pk_EvaluationQuestion}`);

      questionField.value = text;
    }

    setText((prevState) => {
      if (continueWorkflow) {
        return placeholder || '';
      }

      return prevState;
    });

    if (continueWorkflow === false) {
      return;
    }

    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }

    setIsTyping(true);

    setTypingTimeout(
      setTimeout(function() {
        const { pk_EvaluationQuestion } = question;
        postAnswer(pk_EvaluationQuestion, placeholder);
      }, 2 * 1000),
    );
  };

  return (
    <Row>
      <Col style={{ padding: 0 }} sm="12">
        <p style={{ fontWeight: 700 }}>
          {i + 1}. {question.EnableRequired && <span style={{ color: '#ff0000' }}>*</span>}
          {question.QuestionText}
        </p>
      </Col>
      <Col sm="12" style={{ display: 'flex', alignItems: 'center' }}>
        <div style={{ display: 'flex', justifyContent: 'space-evenly', width: '100%' }}>
          <Form style={{ width: '100%' }}>
            <FormGroup>
              <Label
                for={`question_${question.pk_EvaluationQuestion}`}
                style={{
                  fontSize: 10,
                  fontWeight: 'bold',
                  color: text.length >= textLimit ? 'red' : text.length >= 2000 ? 'orange' : 'black',
                  margin: 0,
                  visibility: text.length >= 2000 ? 'visible' : 'hidden',
                }}
              >
                {textLimit - text.length || 0} character{text.length == 1 ? '' : 's'} remaining. . .
              </Label>
              <Input
                type="textarea"
                name="text"
                id={`question_${question.pk_EvaluationQuestion}`}
                // invalid={text.length >= textLimit}
                // value={text}
                defaultValue={text}
                tabIndex={!questionaireDisabled ? 0 : -1}
                onChange={changeName}
                rows={5}
                maxLength={textLimit}
                style={{ border: text.length >= textLimit ? '1px solid red' : null }}
              />
              {/* <FormFeedback invalid={text.length > textLimit}>Max {textLimit} characters</FormFeedback> */}
            </FormGroup>
          </Form>
        </div>
      </Col>
    </Row>
  );
};

export default TextQuestion;
