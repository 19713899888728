import { faCheckSquare, faSquare, faTimesCircle } from '@fortawesome/free-regular-svg-icons';
import { faBan, faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { clone } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Popover, PopoverBody, PopoverHeader, UncontrolledPopover } from 'reactstrap';

import style from './EvaluatorPicker.style';

const EvaluatorPicker = ({
  updateSlot,
  header,
  flexEvent = {},
  setShowUnavailabilityEvaluatorPicker,
  showUnavailabilityEvaluatorPicker,
  rowIndex,
  setSelectedRow,
  selectedRowRef,
}) => {
  const [existingEvaluators, setExistingEvaluators] = useState([]);
  const [newEvaluators, setNewEvaluators] = useState([]);
  const [evaluatorsToAdd, setEvaluatorsToAdd] = useState([]);
  const [evaluatorsToDelete, setEvaluatorsToDelete] = useState([]);

  const { rawHeader } = header || {};
  const evaluators = rawHeader && rawHeader.Evaluators ? rawHeader.Evaluators.Evaluators : [];

  useEffect(() => {
    const newExistingEvaluators = [];
    if (flexEvent) {
      flexEvent.evaluators.forEach((e) => {
        newExistingEvaluators.push(e.pk_User);
      });
    }

    setExistingEvaluators(newExistingEvaluators);

    setNewEvaluators(newExistingEvaluators);
  }, []);

  const isSame = (a, b) => a.length === b.length && a.every((v, i) => b.indexOf(v) >= 0);

  const isChanged = !isSame(existingEvaluators, newEvaluators);
  const targetKey = `button_${
    flexEvent && flexEvent.pk_FlexEvent != null ? flexEvent.pk_FlexEvent : `temp_${rowIndex}`
  }_addEvaluator`;

  return (
    <Popover
      placementPrefix="unavailableEvaluatorPicker bs-popover"
      isOpen={showUnavailabilityEvaluatorPicker[`picker_${rowIndex}`]}
      target={targetKey}
      trigger="legacy"
      style={{ zIndex: 999999999 }}
      onClick={(e) => {
        e.stopPropagation();
      }}
      toggle={(e) => {
        const newShowUnavailabilityEvaluatorPicker = clone(showUnavailabilityEvaluatorPicker);
        newShowUnavailabilityEvaluatorPicker[`picker_${rowIndex}`] = !newShowUnavailabilityEvaluatorPicker[
          `picker_${rowIndex}`
        ];
        setShowUnavailabilityEvaluatorPicker(newShowUnavailabilityEvaluatorPicker);
      }}
    >
      <PopoverHeader closeButton>
        <div
          style={style.spacedBetweenRow}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <div>
            <div style={{ ...style.flexEndRow, width: '100%' }}>
              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', width: '100%' }}>
                Evaluators
              </div>
              {isChanged ? (
                <FontAwesomeIcon
                  icon={faCheck}
                  id="unavailability_popover_save"
                  style={{ ...style.clickableIcon, marginRight: 10 }}
                  onClick={(e) => {
                    const newFlexEvent = clone(flexEvent);

                    const newEvaluatorsActual = [];
                    newEvaluators.forEach((pk_User) => {
                      const newEvaluatorActual = evaluators.find((e) => {
                        return e.pk_User === pk_User;
                      });
                      if (newEvaluatorActual) {
                        newEvaluatorsActual.push(newEvaluatorActual);
                      }
                    });
                    newFlexEvent.evaluators = newEvaluatorsActual;
                    newFlexEvent.evaluatorsToDelete = evaluatorsToDelete;
                    newFlexEvent.evaluatorsToAdd = evaluatorsToAdd;

                    setSelectedRow(newFlexEvent);
                    updateSlot(newFlexEvent, rowIndex, true);
                  }}
                />
              ) : null}
              {isChanged ? (
                <div
                  style={{ ...style.clickableIcon, marginRight: 10 }}
                  onClick={(e) => {
                    e.stopPropagation();
                    const newNewEvaluators = existingEvaluators.slice();
                    setNewEvaluators(newNewEvaluators);
                  }}
                >
                  <FontAwesomeIcon id="unavailability_popover_reset" icon={faBan} />
                </div>
              ) : null}
              <div
                style={style.clickableIcon}
                onClick={(e) => {
                  e.stopPropagation();
                  document.body.click();
                  //   setShowUnavailabilityEvaluatorPicker(false);
                }}
              >
                <FontAwesomeIcon disabled={true} id="unavailability_popover_close" icon={faTimesCircle} />
              </div>
            </div>
          </div>
        </div>
      </PopoverHeader>
      <PopoverBody>
        <div style={style.mainBody}>
          <div style={style.userList}>
            {evaluators.map((evaluator) => {
              const isExisting = existingEvaluators && existingEvaluators.indexOf(evaluator.pk_User) >= 0;

              const isNewSelection = newEvaluators.indexOf(evaluator.pk_User) >= 0;

              let backgroundColor = null;

              if (isExisting && isNewSelection) {
                backgroundColor = '#c4d5ff';
              }

              if (isExisting && !isNewSelection) {
                backgroundColor = '#f77777';
              }

              if (!isExisting && isNewSelection) {
                backgroundColor = '#88ff82';
              }

              return (
                <div
                  id="unavailability_popover_checkbox_container"
                  key={evaluator.pk_User}
                  style={{
                    ...style.userItem,
                    ...style.spaceBetweenRow,
                    backgroundColor: backgroundColor,
                    alignItems: 'center',
                  }}
                  onClick={(e) => {
                    e.stopPropagation();

                    const newNewEvaluators = newEvaluators.slice();
                    const newEvaluatorsToDelete = evaluatorsToDelete.slice();
                    const newEvaluatorsToAdd = evaluatorsToAdd.slice();

                    const itemIndex = newNewEvaluators.indexOf(evaluator.pk_User);
                    if (itemIndex >= 0) {
                      newNewEvaluators.splice(itemIndex, 1);
                      if (newEvaluatorsToDelete.indexOf(evaluator.pk_User) < 0) {
                        newEvaluatorsToDelete.push(evaluator.pk_User);
                      }
                      if (newEvaluatorsToAdd.indexOf(evaluator.pk_User) >= 0) {
                        newEvaluatorsToAdd.splice(newEvaluatorsToAdd.indexOf(evaluator.pk_User), 1);
                      }
                    } else {
                      newNewEvaluators.push(evaluator.pk_User);
                      if (newEvaluatorsToDelete.indexOf(evaluator.pk_User) >= 0) {
                        newEvaluatorsToDelete.splice(newEvaluatorsToAdd.indexOf(evaluator.pk_User), 1);
                      }
                      if (newEvaluatorsToAdd.indexOf(evaluator.pk_User) < 0) {
                        newEvaluatorsToAdd.push(evaluator.pk_User);
                      }
                    }
                    setNewEvaluators(newNewEvaluators);
                    setEvaluatorsToAdd(newEvaluatorsToAdd);
                    setEvaluatorsToDelete(newEvaluatorsToDelete);
                  }}
                >
                  <FontAwesomeIcon
                    disabled={true}
                    id="unavailability_popover_checkbox"
                    icon={isNewSelection ? faCheckSquare : faSquare}
                    style={{ marginRight: 10 }}
                  />
                  <div style={style.centeredRow}>
                    {evaluator.UserLast}, {evaluator.UserFirst}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </PopoverBody>
    </Popover>
  );
};

export default EvaluatorPicker;
