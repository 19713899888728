export const setUser = (payload) => {
  return {
    type: 'SET_USER',
    payload,
  };
};

export const setBreakUrl = (payload) => {
  return {
    type: 'SET_BREAK_URL',
    payload,
  };
};

export const setUserObject = (payload) => {
  return {
    type: 'SET_USER_OBJECT',
    payload,
  };
};

export const setExternalToken = (payload) => {
  return {
    type: 'SET_EXTERNAL_CODE',
    payload,
  };
};

export const setUserDepartment = (payload) => {
  return {
    type: 'SET_USER_DEPARTMENT',
    payload,
  };
};

export const setAllUserDepartments = (payload) => {
  return {
    type: 'SET_ALL_USER_DEPARTMENTS',
    payload,
  };
};

export const setAllUsers = (payload) => {
  return {
    type: 'SET_ALL_USERS',
    payload,
  };
};
