const style = {
  topNavButton: {
    backgroundColor: '#0a91ff',
    marginRight: 10,
    marginLeft: 10,
    paddignRight: 20,
    paddingBottom: 10,
    paddingLeft: 20,
    paddingTop: 10,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'row',
  },
  printButton: {
    backgroundColor: '#0a91ff',
    marginRight: 10,
  },
  buttonIcon: {
    marginRight: 10,
  },
  popOverHeader: {
    textAlign: 'center',
    fontWeight: 600,
  },
  flexStartRow: {
    alignItems: 'centers',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },
  flexEndRow: {
    alignItems: 'centers',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  spacedBetweenRow: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  simpleRow: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    margin: 10,
  },
  simpleColumn: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: 10,
  },
  scheduleTitleDate: {
    alignItems: 'center',
    fontSize: 24,
    fontWeight: 600,
    alignItems: 'flex-start',
    flexDirection: 'column',
    display: 'flex',
  },
  printButtonContainer: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
  },
  savedIcon: {
    fontSize: 15,
    color: 'green',
  },
  loadingIcon: {
    fontSize: 15,
    color: 'blue',
  },
  buttonLabel: {
    width: '100%',
    textAlign: 'center',
    fontWeight: 600,
    // fontSize: '14px'
  },
};

export default style;
