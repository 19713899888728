import { DepartmentContext } from 'DepartmentWrapper';
import React, { useContext } from 'react';
import AdminRanksSort from '../AdminRanks/AdminRanksSort';
import Loading from 'Body/Statuses/Loading';

const AdminRankList = ({
  sliderValue,
  candidateRanks = [],
  postChange,
  setCandidateRanks,
  isFetchingCandidates,
  setSelectedUser,
  selectedUser,
  selectedTagFilter,
  mode,
  defaultModes,
  setLastJumpTo,
  detailNotesHaveChange,
  viewMode,
}) => {
  const dContext = useContext(DepartmentContext);
  const { season } = dContext || {};
  const { EnablePrescreenMode } = season || {};

  if (isFetchingCandidates && (!candidateRanks || candidateRanks.length <= 0)) {
    return (
      <div style={{ paddingTop: '20vh' }}>
        <Loading options={{ labelText: 'Fetching Rank List. . .' }} />
      </div>
    );
  }

  if (viewMode === 'Grid') {
    return (
      <div style={{ width: '100%', width: '100%' }}>
        {candidateRanks && candidateRanks.length > 0 ? (
          <AdminRanksSort
            viewMode={viewMode}
            selectedUser={selectedUser}
            sliderValue={sliderValue}
            candidates={candidateRanks}
            isFetchingCandidates={isFetchingCandidates}
            postChange={postChange}
            setCandidateRanks={setCandidateRanks}
            setSelectedUser={setSelectedUser}
            selectedTagFilter={selectedTagFilter}
            isCollapsed={true}
            setLastJumpTo={setLastJumpTo}
            unique_key={2}
          />
        ) : isFetchingCandidates ? (
          <div style={{ marginTop: '20vh' }}>
            <Loading options={{ labelText: 'Fetching Rank List. . .' }} />
          </div>
        ) : (
          <div style={{ textAlign: 'center', fontWeight: 'bold' }}>No Items</div>
        )}
      </div>
    );
  } else {
    return (
      <>
        {/* <table className="table table-striped" style={{ margin: '0px', marginRight: 10 }}>
          <thead>
            <tr>
              <th style={{ padding: '5px 10px 5px 0px' }}>Rank Order</th>
              <th style={{ padding: '5px 10px 5px 0px' }}>Flag</th>
              <th style={{ padding: '5px 10px 5px 0px', textAlign: 'center' }}>Candidate</th>
              <th style={{ padding: '5px 10px 5px 0px' }}>Interview Date</th>
              <th style={{ padding: '5px 10px 5px 0px' }}>
                {mode == defaultModes.prescreen ? 'Do Not Interview' : 'Do Not Rank'}
              </th>
              <th style={{ padding: '5px 10px 5px 0px' }}>
                {mode == defaultModes.prescreen ? 'Prescreen Score' : 'Final Score'}
              </th>
            </tr>
          </thead>
          {candidateRanks && candidateRanks.length > 0 ? (
            <AdminRanksSort
              selectedUser={selectedUser}
              sliderValue={sliderValue}
              candidates={candidateRanks}
              isFetchingCandidates={isFetchingCandidates}
              postChange={postChange}
              setCandidateRanks={setCandidateRanks}
              setSelectedUser={setSelectedUser}
              selectedTagFilter={selectedTagFilter}
              isCollapsed={true}
              setLastJumpTo={setLastJumpTo}
              viewMode={viewMode}
            />
          ) : (
            <tbody></tbody>
          )}
        </table> */}
        <div
          id={`rankListContainer_List`}
          style={{ display: 'flex', overflowY: 'scroll', minHeight: 455, height: 'calc(100vh - 420px)' }}
        >
          <table className="table table-striped table-hover" style={{ margin: '0px', height: '100%' }}>
            <thead
              style={{
                top: -2,
                position: 'sticky',
                verticalAlign: 'middle',
                backgroundColor: 'white',
                zIndex: 3,
                padding: 20,
              }}
            >
              <tr>
                <th style={{ padding: '5px 10px 5px 0px', textAlign: 'center' }}>Rank Order</th>
                <th style={{ padding: '5px 10px 5px 0px' }}>Flag</th>
                <th style={{ padding: '5px 10px 5px 0px', textAlign: 'center' }}>Candidate</th>
                <th style={{ padding: '5px 10px 5px 0px' }}>Interview Date</th>
                <th style={{ padding: '5px 10px 5px 0px' }}>
                  {mode == defaultModes.prescreen ? 'Do Not Interview' : 'Do Not Rank'}
                </th>
                <th style={{ padding: '5px 10px 5px 0px' }}>
                  {mode == defaultModes.prescreen ? 'Prescreen Score' : 'Final Score'}
                </th>
              </tr>
            </thead>
            {candidateRanks && candidateRanks.length > 0 ? (
              <AdminRanksSort
                viewMode={viewMode}
                detailNotesHaveChange={detailNotesHaveChange}
                unique_key={2}
                selectedUser={selectedUser}
                sliderValue={sliderValue}
                candidates={candidateRanks}
                isFetchingCandidates={isFetchingCandidates}
                postChange={postChange}
                setCandidateRanks={setCandidateRanks}
                setSelectedUser={setSelectedUser}
                setLastJumpTo={setLastJumpTo}
                selectedTagFilter={selectedTagFilter}
              />
            ) : (
              <tbody>
                <tr>
                  <td colSpan={6} style={{ fontWeight: 'bold', textAlign: 'center' }}>
                    No Items
                  </td>
                </tr>
              </tbody>
            )}
          </table>
        </div>
      </>
    );
  }
};

export default AdminRankList;
