const style = {
  simpleRow: {
    display: 'flex',
    flexDirection: 'row',
  },
  simpleColumn: {
    display: 'flex',
    flexDirection: 'column',
  },
  fieldsContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    // height: 300,
    oveflowY: 'scroll',
    paddingLeft: 20,
    paddingRight: 20,
    marginTop: 20,
  },

  checkBoxText: {
    display: 'flex',
    justifyContent: 'center',
    textAlign: 'center',
    alignItems: 'center',
    cursor: 'pointer',
  },
  checkIcon: {
    cursor: 'pointer',
    marginRight: 10,
  },
  checkBoxContainer: {
    display: 'flex',
    flexDirection: 'column',
    minWidth: 200,
  },
  checkBoxSubContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    textAlign: 'center',
    alignItems: 'center',
  },
};
export default style;
