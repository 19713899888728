import React, { useContext, useEffect, useRef, useState } from 'react';
import SingleCandidateDragAndDropItem from '../../../../../../../DragAndDropFiles/SingleCandidateDragAndDropItem';
import { Button, Input, Label, Popover, PopoverBody, PopoverHeader, UncontrolledPopover } from 'reactstrap';

import style from './NewAttachmentPopover.style';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { constructUrl } from 'Services/dataApi';
import { formatBearerToken } from 'Common.functions';
import { DepartmentContext } from 'DepartmentWrapper';
import { post } from 'axios';
import { useAuth0 } from 'auth0/reactAuth0Spa';
import Swal from 'sweetalert2';
import DragAndDropGrid from '../DragAndDropGrid/DragAndDropGrid';
import { useAlert } from 'react-alert';
const NewAttachmentPopover = ({
  target,
  onSave,
  onDelete,
  onCancel,
  getAttachments,
  setShowNewAttachmentPopover,
  showNewAttachmentPopover,
  uniqueId = '',
}) => {
  const [newAttachment, setNewAttachment] = useState({});
  const [Name, setName] = useState('');

  const [isUploading, setIsUploading] = useState(false);
  const [currentProgress, setCurrentProgress] = useState(0);

  const NameCountLimit = 250;
  const dContext = useContext(DepartmentContext);
  const { department } = dContext;
  const { getTokenSilently } = useAuth0();
  const nameRef = useRef();
  const alert = useAlert();

  useEffect(() => {
    if (!showNewAttachmentPopover) {
      setName('');
      setNewAttachment({});
      nameRef.current = null;
    }
  }, [showNewAttachmentPopover]);

  const initiateFileUpload = () => {
    console.log('here');
  };

  const setFile = (newFile) => {
    const newNewAttachment = { ...newAttachment };
    newNewAttachment.file = newFile;
    console.log('nameRef.current: ', nameRef.current);
    if (!nameRef.current) {
      const newFileName = newFile.name.replace(/\.[^.]*$/, '');
      newNewAttachment.Name = newFileName;
      setName(newFileName);
      nameRef.current = newFileName;
    }
    setNewAttachment(newNewAttachment);
  };

  const prepareFileForUpload = (file) => {
    const formData = new FormData();

    const fileJson = {
      name: file.name,
      displayName: nameRef.current,
      pk_Department: department.pk_Department,
    };
    const jsonToSend = JSON.stringify(fileJson);
    console.log('fileJson: ', fileJson);
    formData.append(`File`, file, jsonToSend);

    return formData;
  };

  const fileUpload = (formData) => {
    const url = constructUrl('files/communications/attachment');

    setIsUploading(true);
    getTokenSilently().then((token) => {
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
          ...formatBearerToken(token),
        },
        onUploadProgress: function(progressEvent) {
          var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          setCurrentProgress(percentCompleted);
        },
      };
      post(url, formData, config)
        .then((res) => {
          getAttachments();
          setShowNewAttachmentPopover(false);
          alert.success('File uploaded successfully');
          // handleSingleCandidateUpload(res.data);
        })
        .catch((err) => {
          console.log('post err: ', err);
          Swal.fire('Oops', 'Something went wrong uploading file. Please try again or contact support', 'error').then(
            () => {
              // clearQueue();
            },
          );
        })
        .finally(() => {
          setCurrentProgress(0);
          setIsUploading(false);
        });
    });
  };

  return (
    <Popover
      target={target}
      trigger="legacy"
      modifiers={{ flip: { behavior: ['left'] }, preventOverflow: { boundariesElement: 'viewport' } }}
      placementPrefix="attachmentsPopover bs-popover"
      id={`newAttachmentPopover_${uniqueId}`}
      isOpen={showNewAttachmentPopover}
      placement="top"
      onClick={(e) => {
        e.stopPropagation();
      }}
      toggle={(e) => {
        const self = document.getElementById(`newAttachmentPopover_${uniqueId}`);
        const eventTarget = e.target;
        // console.log('toggle newAttachmentPopover: ', self);
        // console.log('toggle eventTarget: ', eventTarget);

        // if (self && eventTarget) {
        //   console.log('self.contains(eventTarget): ', self.contains(eventTarget));
        // }
        if (self && eventTarget && self.contains(eventTarget)) {
          return;
        }
      }}
    >
      <PopoverHeader>
        <div style={style.spacedBetweenRow}>
          <div style={style.buttonLabel}>New Attachment</div>
          <div>
            <FontAwesomeIcon
              icon={faTimesCircle}
              style={{ cursor: 'pointer' }}
              onClick={() => {
                setShowNewAttachmentPopover(false);
              }}
            />
          </div>
        </div>
      </PopoverHeader>
      <PopoverBody>
        <div style={style.simpleRow}>
          <div style={{ ...style.simpleColumn, width: '100%' }}>
            <div style={style.simpleRow}>
              <div style={{ ...style.simpleColumn, width: '100%' }}>
                <Label for="fileName">Attachment Name</Label>
                <Input
                  id="fileName"
                  value={Name}
                  type="text"
                  onChange={(e) => {
                    if (e.target.value.length > NameCountLimit) {
                      return;
                    }
                    nameRef.current = e.target.value;
                    setNewAttachment({ ...newAttachment, Name: e.target.value });
                    setName(e.target.value);
                  }}
                />
              </div>
            </div>

            <div style={{ borderBottom: '3px #75b1fa solid', width: `${currentProgress}%`, marginTop: 5 }.simpleRow}>
              <DragAndDropGrid
                setParentFile={setFile}
                typeRestrictions={'.jpg, .jpeg, .pdf, .png, .docx, .xlsx, .doc, .xls, .pptx, .ppt'}
              />
            </div>
            <div style={style.spacedBetweenRow}>
              {/* <Button size="sm" color="danger" style={{ width: '32.9%' }}>
                Delete
              </Button> */}
              <Button
                disabled={isUploading}
                size="sm"
                color="secondary"
                style={{ width: '32.9%' }}
                onClick={() => {
                  setShowNewAttachmentPopover(false);
                }}
              >
                Cancel
              </Button>
              <Button
                disabled={isUploading}
                size="sm"
                color="success"
                style={{ width: '32.9%' }}
                onClick={() => {
                  fileUpload(prepareFileForUpload(newAttachment.file));
                }}
              >
                Save
              </Button>
            </div>
          </div>
        </div>
      </PopoverBody>
    </Popover>
  );
};

export default NewAttachmentPopover;
