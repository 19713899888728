import { DepartmentContext } from 'DepartmentWrapper';
import { useAuth0 } from 'auth0/reactAuth0Spa';
import React, { useState, useEffect, useContext, useRef } from 'react';
import { Button, Card, CardBody, CardHeader, Input, InputGroup, InputGroupAddon, Modal, ModalBody } from 'reactstrap';
import style from './UserSearch.style';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle, faDotCircle, faSearch, faTimes, faUser } from '@fortawesome/free-solid-svg-icons';
import { fetchDataAgnostic } from 'Services/dataApi';
import { formatBearerToken } from 'Common.functions';
import Loading from 'Body/Statuses/Loading';
import Swal from 'sweetalert2';
const UserSearch = ({ onSearch, selectDepartment, setShowSuperUserPanel, selectRole }) => {
  const [searchString, setSearchString] = useState('');
  const [selectedOption, setSelectedOption] = useState('Name');
  const [isLoading, setIsLoading] = useState(false);
  const [matchingCandidates, setMatchingCandidates] = useState([]);
  const [matchingUsers, setMatchingUsers] = useState([]);
  const [rawSearchResult, setRawSearchResult] = useState();

  const [currentPage, setCurrentPage] = useState(0);
  const [currentMaxPages, setCurrentMaxPages] = useState(0);
  const [currentCandidatesMaxCount, setCurrentCandidatesMaxCount] = useState(0);
  const [currentUsersMaxCount, setCurrentUsersMaxCount] = useState(0);

  const [isRedirecting, setIsRedirecting] = useState(false);

  const currentPageRef = useRef(0);
  const searchstringRef = useRef();
  const oldSearchStringRef = useRef();

  const selectedOptionRef = useRef();
  const oldSelectedOptionRef = useRef();

  const matchingCandidatesRef = useRef([]);
  const matchingUsersRef = useRef([]);
  const currentCandidatesMaxCountRef = useRef(0);
  const currentUsersMaxCountRef = useRef(0);

  const disableSearchRef = useRef();

  const options = ['Name', 'Email', 'Phone'];
  const itemLimitPerPage = 10;

  const { getTokenSilently } = useAuth0();
  const dContext = useContext(DepartmentContext);

  const matchingUsersCount = matchingUsers.filter((u) => {
    return !u.isDummy;
  }).length;

  const matchingCandidatesCount = matchingCandidates.filter((c) => {
    return !c.isDummy;
  }).length;

  const disableSearch =
    (matchingCandidatesCount >= currentCandidatesMaxCount &&
      matchingUsersCount >= currentUsersMaxCount &&
      oldSearchStringRef.current == searchstringRef.current &&
      oldSelectedOptionRef.current == selectedOptionRef.current) ||
    isLoading ||
    !searchString;

  useEffect(() => {
    window.addEventListener('keydown', (e) => {
      if (e.key === 'Enter') {
        getSearchData({ searchString: searchstringRef.current, selectedOption: selectedOptionRef.current });
      }
    });

    return () => {
      window.removeEventListener('keydown', (e) => {
        if (e.key === 'Enter') {
          getSearchData({ searchString: searchstringRef.current, selectedOption: selectedOptionRef.current });
        }
      });
    };
  }, []);

  useEffect(() => {
    searchstringRef.current = searchString;
  }, [searchString]);

  useEffect(() => {
    selectedOptionRef.current = selectedOption;
  }, [selectedOption]);

  useEffect(() => {
    const mostRecentDivider = document.getElementById('candidates_divider_' + currentPage);
    if (mostRecentDivider) {
      mostRecentDivider.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }

    matchingCandidatesRef.current = matchingCandidates.slice();
  }, [matchingCandidates]);

  useEffect(() => {
    const mostRecentDivider = document.getElementById('users_divider_' + currentPage);
    if (mostRecentDivider) {
      setTimeout(() => {
        mostRecentDivider.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }, 1000);
    }

    matchingUsersRef.current = matchingUsers.slice();
  }, [matchingUsers]);

  useEffect(() => {
    disableSearchRef.current = disableSearch;
  }, [disableSearch]);

  useEffect(() => {
    currentPageRef.current = currentPage;
  }, [currentPage]);

  useEffect(() => {
    currentCandidatesMaxCountRef.current = currentCandidatesMaxCount;
  }, [currentCandidatesMaxCount]);

  useEffect(() => {
    currentUsersMaxCountRef.current = currentUsersMaxCount;
  }, [currentUsersMaxCount]);

  const getSearchData = (options = {}) => {
    let pageToUse = currentPageRef.current + 1;
    let searchStringToUse = options.searchString || searchString;
    let selectedOptionToUse = options.selectedOption
      ? options.selectedOption.toLowerCase()
      : selectedOption.toLowerCase();

    if (disableSearchRef.current) {
      return;
    }

    if (
      oldSearchStringRef.current !== searchstringRef.current ||
      oldSelectedOptionRef.current !== selectedOptionRef.current
    ) {
      setCurrentPage(1);
      pageToUse = 1;
      setMatchingCandidates([]);
      setMatchingUsers([]);
    } else {
      setCurrentPage(pageToUse);
    }

    if (searchStringToUse && searchStringToUse.indexOf('@') > -1) {
      selectedOptionToUse = 'email';
    }

    if (selectedOptionToUse === 'Phone') {
      searchStringToUse = searchStringToUse.replace(/\D/g, '');
      if (!searchStringToUse) {
        Swal.fire({
          icon: 'error',
          title: 'Invalid Phone Number',
          text: 'Please enter a valid phone number',
        });
        return;
      }
    }

    oldSearchStringRef.current = searchstringRef.current;
    oldSelectedOptionRef.current = selectedOptionRef.current;
    setIsLoading(true);

    getTokenSilently()
      .then((token) => {
        fetchDataAgnostic(
          'organization/user/search',
          {
            searchText: searchStringToUse,
            limit: itemLimitPerPage,
            searchType: selectedOptionToUse,
            page: pageToUse,
          },
          formatBearerToken(token),
        )
          .then((res) => {
            // let newMatchingCandidates = [];
            // let newMatchingUsers = [];
            let newMatchingCandidates = res.data.Candidates.results || [];
            let newMatchingUsers = res.data.Users.results || [];
            if (pageToUse > 1) {
              if (res.data.Users.results && res.data.Users.results.length > 0) {
                newMatchingUsers.splice(0, 0, { isDummy: true, page: pageToUse });
              }

              if (res.data.Candidates.results && res.data.Candidates.results.length > 0) {
                newMatchingCandidates.splice(0, 0, { isDummy: true, page: pageToUse });
              }
            }
            newMatchingCandidates = [...matchingCandidatesRef.current, ...newMatchingCandidates];
            newMatchingUsers = [...matchingUsersRef.current, ...newMatchingUsers];

            setIsLoading(false);
            setMatchingCandidates(newMatchingCandidates);
            setMatchingUsers(newMatchingUsers);
            setCurrentCandidatesMaxCount(res.data.Candidates.total);
            setCurrentUsersMaxCount(res.data.Users.total);
            setRawSearchResult(res.data);
          })
          .catch((err) => {
            Swal.fire({
              icon: 'error',
              title: 'Error',
              text: 'Error searching for users',
            });
            setIsLoading(false);
          });
      })
      .catch((err) => {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Error searching for users',
        });
        setIsLoading(false);
      });
  };

  const resetAll = () => {
    setSearchString('');
    setMatchingCandidates([]);
    setMatchingUsers([]);
    setCurrentCandidatesMaxCount(0);
    setCurrentUsersMaxCount(0);
    setCurrentPage(0);
    oldSearchStringRef.current = null;
    searchstringRef.current = null;
    oldSelectedOptionRef.current = null;
    selectedOptionRef.current = null;
  };

  const renderDividerLine = (page, id) => {
    return (
      <div
        id={id}
        style={{
          ...style.simpleRow,
          width: '100%',
          marginTop: 10,
          marginBottom: 10,
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        {' '}
        <div style={{ ...style.simpleColumn, height: 2, backgroundColor: 'lightgray' }}></div>
        <div style={{ ...style.simpleColumn, fontWeight: 'bold', width: 'auto', paddingLeft: 5, paddingRight: 5 }}>
          {page}
        </div>
        <div style={{ ...style.simpleColumn, height: 2, backgroundColor: 'lightgray' }}></div>
      </div>
    );
  };

  const renderCandidateItem = (candidate, index) => {
    const {
      isDummy,
      page,
      PhotoURL,
      FirstName = '',
      LastName = '',
      Email = '',
      PhoneNumber = '',
      SeasonName = 'No Season',
      OrganizationName = 'No Organization',
      DepartmentName = 'No Department',
      isRemoved,
    } = candidate;

    if (isDummy && currentPage > 1) {
      return renderDividerLine(page, `candidates_divider_${page}`);
    } else if (isDummy) {
      return;
    }

    return (
      <div
        style={{
          ...style.simpleRow,
          paddingTop: 5,
          paddingBottom: 5,
          width: '100%',
          borderBottom: '1px solid lightgray',
          // borderTop: isRemoved ? '3px solid red' : 'none',
          // borderLeft: isRemoved ? '3px solid red' : 'none',
          // borderRight: isRemoved ? '3px solid red' : 'none',
          // backgroundColor: index % 2 === 0 ? 'white' : 'lightgray',
          backgroundColor: isRemoved ? '#ff7878' : index % 2 === 0 ? 'white' : 'lightgray',
          color: 'red',
        }}
      >
        <div style={{ ...style.simpleColumn, width: 110, marginRight: 10 }}>
          {PhotoURL ? (
            <img src={PhotoURL} style={{ width: 110, height: 110, borderRadius: 110, objectFit: 'contain' }} />
          ) : (
            <div style={{ ...style.simpleRow, width: 110, justifyContent: 'center' }}>
              {' '}
              <FontAwesomeIcon icon={faUser} style={{ fontSize: 98 }} />
            </div>
          )}
        </div>
        <div style={{ ...style.simpleColumn }}>
          <div style={{ ...style.simpleRow, fontSize: 15, fontWeight: 'bold' }}>
            <span>
              {' '}
              {LastName}
              {LastName && FirstName ? ', ' : ''}
              {FirstName}
            </span>
          </div>
          <div style={{ ...style.simpleRow, fontSize: 12 }}>
            <div
              style={{
                ...style.simpleColumn,
                width: 'auto',
                fontWeight: 'bold',
                marginRight: 5,
              }}
            >
              Email:
            </div>
            <div style={{ ...style.simpleColumn }}> {Email}</div>
          </div>
          <div style={{ ...style.simpleRow, fontSize: 12 }}>
            <div
              style={{
                ...style.simpleColumn,
                width: 'auto',
                fontWeight: 'bold',
                marginRight: 5,
              }}
            >
              Phone:
            </div>
            <div style={{ ...style.simpleColumn, width: 'auto' }}>{PhoneNumber}</div>
          </div>
          <div style={{ ...style.simpleRow, fontSize: 12 }}>
            <div
              style={{
                ...style.simpleColumn,
                width: 'auto',
                fontWeight: 'bold',
                marginRight: 5,
              }}
            >
              Organization:
            </div>
            <div style={{ ...style.simpleColumn, width: 'auto' }}>{OrganizationName}</div>
          </div>
          <div style={{ ...style.simpleRow, fontSize: 12 }}>
            <div
              style={{
                ...style.simpleColumn,
                width: 'auto',
                fontWeight: 'bold',
                marginRight: 5,
              }}
            >
              Department:
            </div>
            <div style={{ ...style.simpleColumn, width: 'auto' }}>{DepartmentName}</div>
          </div>
          <div style={{ ...style.simpleRow, fontSize: 12 }}>
            <div
              style={{
                ...style.simpleColumn,
                width: 'auto',
                fontWeight: 'bold',
                marginRight: 5,
              }}
            >
              Season Name:
            </div>
            <div style={{ ...style.simpleColumn, width: 'auto' }}>{SeasonName}</div>
          </div>
        </div>
      </div>
    );
  };

  const renderUserItem = (user, index) => {
    const {
      isDummy,
      PhotoURL,
      page,
      FirstName = '',
      LastName = '',
      Email = '',
      PhoneNumber = '',
      Season = 'No Season',
      Organizations = [],
      Department = 'No Department',
    } = user;

    if (isDummy && currentPage > 1) {
      return renderDividerLine(page, `users_divider_${page}`);
    } else if (isDummy) {
      return;
    }

    return (
      <div
        style={{
          ...style.simpleRow,
          paddingTop: 5,
          paddingBottom: 5,
          width: '100%',
          borderBottom: '1px solid lightgray',
          backgroundColor: index % 2 === 0 ? 'white' : 'lightgray',
        }}
      >
        <div style={{ ...style.simpleColumn, width: 110, marginRight: 10 }}>
          {PhotoURL ? (
            <img src={PhotoURL} style={{ width: 110, height: 110, borderRadius: 110, objectFit: 'contain' }} />
          ) : (
            <div style={{ ...style.simpleRow, width: 110, justifyContent: 'center' }}>
              {' '}
              <FontAwesomeIcon icon={faUser} style={{ fontSize: 98 }} />
            </div>
          )}
        </div>
        <div style={{ ...style.simpleColumn }}>
          <div style={{ ...style.simpleRow, fontSize: 15, fontWeight: 'bold' }}>
            <span>
              {' '}
              {LastName}
              {LastName && FirstName ? ', ' : ''}
              {FirstName}
            </span>
          </div>
          <div style={{ ...style.simpleRow, fontSize: 12 }}>
            <div
              style={{
                ...style.simpleColumn,
                width: 'auto',
                fontWeight: 'bold',
                marginRight: 5,
              }}
            >
              Email:
            </div>
            <div style={{ ...style.simpleColumn }}> {Email}</div>
          </div>
          <div style={{ ...style.simpleRow, fontSize: 12 }}>
            <div
              style={{
                ...style.simpleColumn,
                width: 'auto',
                fontWeight: 'bold',
                marginRight: 5,
              }}
            >
              Phone:
            </div>
            <div style={{ ...style.simpleColumn, width: 'auto' }}>{PhoneNumber}</div>
          </div>
          <div style={{ ...style.simpleRow, fontSize: 12, fontWeight: 'bold' }}>Organizations:</div>
          <ul>
            {Organizations && Organizations.length > 0 ? (
              Organizations.map((org, index) => {
                const { OrganizationName, Departments = [] } = org;
                return Departments.map((dept, index) => {
                  const { DepartmentName, Roles = [] } = dept;
                  const { EnableAdmin, EnableEvaluator } = Roles[0] || {};

                  return (
                    <li>
                      <div
                        style={{ ...style.simpleRow, fontSize: 12, cursor: 'pointer' }}
                        onClick={() => {
                          if (selectDepartment) {
                            setIsRedirecting(true);
                            selectDepartment({ pk_Department: dept.fk_Department }, (err) => {
                              setIsRedirecting(false);
                              if (!err) {
                                if (selectRole) {
                                  selectRole('admin');
                                }
                              } else {
                                Swal.fire({
                                  icon: 'error',
                                  title: 'Error',
                                  text: 'Error redirecting to department',
                                });
                              }
                            });
                          }
                        }}
                      >
                        <div style={{ ...style.simpleColumn, width: 'auto', fontWeight: 'bold' }}>
                          {OrganizationName}
                        </div>
                        <div
                          style={{
                            ...style.simpleColumn,
                            width: 'auto',
                            paddingLeft: 2,
                            paddingRight: 2,
                          }}
                        >
                          {OrganizationName && DepartmentName ? ' - ' : ''}
                        </div>
                        <div style={{ ...style.simpleColumn, width: 'auto' }}>{DepartmentName}</div>
                        <div style={{ ...style.simpleColumn, width: 'auto', paddingLeft: 5 }}>
                          ({EnableAdmin ? 'Admin' : ''}
                          {EnableAdmin && EnableEvaluator ? ', ' : ''}
                          {EnableEvaluator ? 'Evaluator' : ''})
                        </div>
                      </div>
                    </li>
                  );
                });
              })
            ) : (
              <div style={{ ...style.simpleRow, fontSize: 12, marginLeft: 10 }}>No Organizations</div>
            )}
          </ul>
        </div>
      </div>
    );
  };

  const renderRedirectionModal = () => {
    return (
      <Modal isOpen={isRedirecting} toggle={() => {}} size="lg" centered>
        {/* <ModalHeader>Redirecting</ModalHeader> */}
        <ModalBody>
          <div style={{ ...style.simpleColumn, alignItems: 'center', paddingBottom: 20 }}>
            <Loading options={{ labelText: 'Redirecting. . .' }} />
          </div>
        </ModalBody>
      </Modal>
    );
  };

  return (
    <div>
      <Card>
        <CardHeader>User Search</CardHeader>
        <CardBody>
          <div style={{ ...style.simpleRow, justifyContent: 'center' }}>
            <div style={{ minWidth: 300, width: '30%' }}>
              <InputGroup>
                <InputGroupAddon addonType="prepend">
                  <Button
                    size="sm"
                    onClick={() => {
                      resetAll();
                    }}
                  >
                    <FontAwesomeIcon icon={faTimes} />
                  </Button>
                </InputGroupAddon>
                <Input
                  autoFocus
                  size="sm"
                  type="text"
                  value={searchString}
                  placeholder="Search. . ."
                  onChange={(e) => {
                    setSearchString(e.target.value);
                  }}
                />
                <InputGroupAddon addonType="append">
                  <Button
                    color="success"
                    size="sm"
                    disabled={disableSearch}
                    onClick={() => {
                      getSearchData();
                    }}
                  >
                    <FontAwesomeIcon icon={faSearch} />
                  </Button>
                </InputGroupAddon>
                <InputGroupAddon addonType="append">
                  <Button
                    style={{ width: 100 }}
                    color="primary"
                    size="sm"
                    onClick={() => {
                      getSearchData();
                    }}
                    disabled={
                      (matchingCandidatesCount >= currentCandidatesMaxCount &&
                        matchingUsersCount >= currentUsersMaxCount) ||
                      isLoading
                    }
                  >
                    Load More
                  </Button>
                </InputGroupAddon>
              </InputGroup>
            </div>
          </div>
          <div style={{ ...style.simpleRow, justifyContent: 'center' }}>
            <div style={{ ...style.simpleColumn, minWidth: 300, width: '30%' }}>
              <div style={{ ...style.simpleRow, justifyContent: 'center' }}>
                {options.map((option, index) => {
                  return (
                    <div
                      style={{ ...style.simpleColumn, width: '33%', cursor: 'pointer', padding: 5 }}
                      onClick={() => {
                        setSelectedOption(option);
                      }}
                    >
                      <div style={{ ...style.simpleRow, alignItems: 'center', justifyContent: 'center' }}>
                        <FontAwesomeIcon
                          icon={selectedOption === option ? faDotCircle : faCircle}
                          style={{ marginRight: 5 }}
                        />
                        {option}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          <div style={{ ...style.simpleRow, height: 'calc(100vh - 450px)', justifyContent: 'space-between' }}>
            <div style={{ ...style.simpleColumn, width: 'calc(50% - 10px)' }}>
              <div
                style={{
                  ...style.simpleRow,
                  alignItems: 'start',
                  width: '100%',
                  height: '100%',
                  justifyContent: 'space-between',
                }}
              >
                <Card style={{ width: '100%' }}>
                  <CardHeader
                    style={{
                      ...style.simpleRow,
                      alignItems: 'start',
                      width: '100%',
                      height: '100%',
                      justifyContent: 'center',
                      fontWeight: 'bold',
                    }}
                  >
                    <div style={{ ...style.simpleRow, width: '100%' }}>
                      <div style={{ ...style.simpleColumn, width: 100 }}></div>
                      <div
                        style={{
                          ...style.simpleColumn,
                          width: 'calc(100% - 200px)',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        Candidates (
                        {
                          matchingCandidates.filter((c) => {
                            return !c.isDummy;
                          }).length
                        }
                        /{currentCandidatesMaxCount})
                      </div>
                      <div
                        style={{
                          ...style.simpleColumn,
                          width: 100,
                          color: 'gray',
                          fontStyle: 'italic',
                          fontSize: 10,
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        {isLoading ? 'Loading . . .' : ``}
                        {/* <Button style={{ width: 100 }} color="primary" size="sm">
                          Load More
                        </Button> */}
                      </div>
                    </div>
                  </CardHeader>
                  <CardBody style={{ height: 'calc(100vh - 500px)', paddingRight: 5, paddingLeft: 5, paddingTop: 5 }}>
                    <div
                      id="candidates_list"
                      style={{ ...style.simpleRow, height: 'calc(100vh - 520px)', overflowY: 'auto' }}
                    >
                      <div style={{ ...style.simpleColumn, alignItems: 'center' }}>
                        {isLoading && currentPage <= 0 ? (
                          <Loading options={{ labelText: 'Loading. . .' }} />
                        ) : matchingCandidates.length > 0 ? (
                          matchingCandidates.map((candidate, index) => {
                            return renderCandidateItem(candidate, index);
                          })
                        ) : (
                          <div> NO ITEMS</div>
                        )}
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </div>
            </div>
            <div style={{ ...style.simpleColumn, width: 'calc(50% - 10px)' }}>
              <div style={{ ...style.simpleRow, alignItems: 'start', width: '100%', height: '100%' }}>
                <Card style={{ width: '100%' }}>
                  <CardHeader
                    style={{
                      ...style.simpleRow,
                      alignItems: 'start',
                      width: '100%',
                      height: '100%',
                      justifyContent: 'center',
                      fontWeight: 'bold',
                    }}
                  >
                    <div style={{ ...style.simpleRow, width: '100%' }}>
                      <div style={{ ...style.simpleColumn, width: 100 }}></div>
                      <div
                        style={{
                          ...style.simpleColumn,
                          width: 'calc(100% - 200px)',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        Users (
                        {
                          matchingUsers.filter((u) => {
                            return !u.isDummy;
                          }).length
                        }
                        /{currentUsersMaxCount})
                      </div>
                      <div
                        style={{
                          ...style.simpleColumn,
                          width: 100,
                          color: 'gray',
                          fontStyle: 'italic',
                          fontSize: 10,
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        {isLoading ? 'Loading . . .' : ``}
                        {/* <Button style={{ width: 100 }} color="primary" size="sm">
                          Load More
                        </Button> */}
                      </div>
                    </div>
                  </CardHeader>
                  <CardBody
                    id="users_list"
                    style={{ height: 'calc(100vh - 500px)', paddingRight: 5, paddingLeft: 5, paddingTop: 5 }}
                  >
                    <div style={{ ...style.simpleRow, height: 'calc(100vh - 520px)', overflowY: 'auto' }}>
                      <div style={{ ...style.simpleColumn, alignItems: 'center' }}>
                        {isLoading && currentPage <= 0 ? (
                          <Loading options={{ labelText: 'Loading. . .' }} />
                        ) : matchingUsers.length > 0 ? (
                          matchingUsers.map((user, index) => {
                            return renderUserItem(user, index);
                          })
                        ) : (
                          <div> NO ITEMS</div>
                        )}
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </div>
            </div>
          </div>
        </CardBody>
      </Card>
      {renderRedirectionModal()}
    </div>
  );
};

export default UserSearch;
