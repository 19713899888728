import {
  faExclamationTriangle,
  faFilter,
  faKey,
  faPortrait,
  faSearch,
  faTimes,
  faTimesCircle,
  faUpload,
  faUserPlus,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { postDataAgnostic } from '../../../../../Services/dataApi';
import { formatBearerToken } from '../../../../../Common.functions';
import { DepartmentContext } from '../../../../../DepartmentWrapper';
import newUserTemplate from '../../../../../assets/files/New_User_Template.xlsx';
import React, { useState, useEffect, useContext } from 'react';
import { useAuth0 } from '../../../../../auth0/reactAuth0Spa';
import SingleUser from './SingleUser';
import {
  Col,
  Row,
  Button,
  Input,
  InputGroup,
  InputGroupAddon,
  Modal,
  ModalBody,
  ModalHeader,
  Popover,
  Tooltip,
  PopoverBody,
  PopoverHeader,
} from 'reactstrap';
import AdminBulkImportUsers from '../../AdminBulkImportUsers/AdminBulkImportUsers';

import style from './UserList.style';
const UserList = ({
  users,
  getUsers,
  selectedUser,
  setSelectedUser,
  setShowAddUser,
  discardChanges,
  hasUserDetailChanges,
}) => {
  const [bulkImportPopoverOpen, setBulkImportPopoverOpen] = useState(false);
  const [searchString, setSearchString] = useState();
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [filterBy, setFilterBy] = useState('All');
  const [showFilterPopover, setShowFilterPopover] = useState(false);
  const [userStatusToolTipOpen, setUserStatusToolTipOpen] = useState(false);

  const { user, isAuthenticated, loginWithRedirect, getTokenSilently, logout } = useAuth0();

  const dContext = useContext(DepartmentContext);

  const filterNames = {
    'Department Admin': 'Showing Department Admins',
    Evaluator: 'Showing Evaluators',
    'No Permissions': 'Showing Users with No Permissions',
    'Point of Contact': 'Showing Users with Point of Contact',
    Deactivated: 'Showing Deactivated Users',
    All: 'Showing All',
  };

  const filterButtonNames = {
    'Department Admin': 'Administrator',
    Evaluator: 'Evaluator',
    'Point of Contact': 'Point of Contact',
    'No Permissions': 'No Permissions',
    Deactivated: 'Deactivated',
  };

  useEffect(() => {
    filterBySearch(searchString, true);
  }, [searchString]);

  useEffect(() => {
    if (searchString) {
      filterBySearch(searchString);
    } else {
      filterByButton(filterBy);
    }
  }, [users]);

  useEffect(() => {
    setSearchString('');
    filterByButton(filterBy);
  }, [filterBy]);

  useEffect(() => {
    const displayUsers = getUsersToShow();

    if (displayUsers && displayUsers.length <= 0) {
      setSelectedUser(null);
    } else {
      if (selectedUser) {
        const selectedUserExistsInFilteredList = displayUsers.find((u) => {
          return u.pk_User == selectedUser.pk_User;
        });
        if (selectedUserExistsInFilteredList) {
          setSelectedUser(selectedUserExistsInFilteredList);
        } else {
          setSelectedUser(displayUsers[0]);
        }
      } else {
        setSelectedUser(displayUsers[0]);
      }
    }
  }, [filteredUsers]);

  const getUsersToShow = () => {
    const usersToShow = [];

    filteredUsers.forEach((u) => {
      const { Deleted } = u;
      if (Deleted && filterBy !== 'Deactivated') {
      } else {
        usersToShow.push(u);
      }
    });
    return usersToShow;
  };

  const filterBySearch = (str, resetFilterBy) => {
    const newFiltered = users.filter((user) => {
      const name = `${user.UserFirst} ${user.UserLast}`;
      const accentlessName = name
        .toLowerCase()
        .normalize('NFD')
        .replace(/\p{Diacritic}/gu, '');

      return (
        str == '' ||
        !str ||
        name.toLowerCase().indexOf(str.toLowerCase()) >= 0 ||
        accentlessName.indexOf(str.toLowerCase()) >= 0
      );
    });
    setFilteredUsers(
      newFiltered.sort((a, b) => {
        const aString = `${a.UserLast || ''}, ${a.UserFirst || ''}`;
        const bString = `${b.UserLast || ''}, ${b.UserFirst || ''}`;
        return aString > bString ? 1 : -1;
      }),
    );

    // if (resetFilterBy) {
    // setFilterBy(filterBy);
    // }
  };

  const filterByButton = (type) => {
    const newFiltered = users.filter((user) => {
      const { EnableAdmin, EnableEvaluator, EnablePointOfContact, Deleted } = user;
      switch (type) {
        case 'Department Admin':
          return EnableAdmin;
        case 'Evaluator':
          return EnableEvaluator;
        case 'Point of Contact':
          return EnablePointOfContact;
        case 'Deactivated':
          return Deleted;
        case 'All':
          return true;
        case 'No Permissions':
          return !EnableAdmin && !EnableEvaluator && !EnablePointOfContact;
      }
    });

    setFilteredUsers(
      newFiltered.sort((a, b) => {
        const aString = `${a.UserLast || ''}, ${a.UserFirst || ''}`;
        const bString = `${b.UserLast || ''}, ${b.UserFirst || ''}`;
        return aString > bString ? 1 : -1;
      }),
    );
  };

  const postUserInAuth = (pk_User, type) => {
    return getTokenSilently().then((token) => {
      return postDataAgnostic(
        'department/user',
        { pk_User, pk_Department: dContext.department.pk_Department, type },
        null,
        formatBearerToken(token),
      )
        .then((result) => {
          getUsers();

          return result.data;
        })
        .catch((err) => {
          return false;
        });
    });
  };

  const renderSearchInput = () => {
    return (
      <InputGroup>
        <InputGroupAddon addonType="prepend">
          <Button
            onClick={() => {
              setSearchString('');
              setFilterBy('All');
            }}
          >
            <FontAwesomeIcon icon={faTimes} />
          </Button>
        </InputGroupAddon>
        <Input
          value={searchString}
          type="text"
          placeholder="Search User. . ."
          onChange={(e) => {
            setSearchString(e.target.value);
          }}
        />
        <InputGroupAddon addonType="append">
          <Button color="success">
            <FontAwesomeIcon icon={faSearch} />
          </Button>
        </InputGroupAddon>
      </InputGroup>
    );
  };

  const renderBulkImporter = () => {
    return (
      <Modal centered isOpen={bulkImportPopoverOpen} onClosed={() => {}}>
        <ModalHeader
          closeButton
          toggle={() => {
            setBulkImportPopoverOpen((prevState) => !prevState);
          }}
        >
          <div>Bulk Import Users</div>
          <div>
            <a href={newUserTemplate} target="_blank" download="New_User_Template.xlsx">
              Download Bulk User Template
            </a>
          </div>
        </ModalHeader>
        <ModalBody>
          <AdminBulkImportUsers callback={getUsers} />
        </ModalBody>
      </Modal>
    );
  };

  const renderFilterButton = () => {
    return (
      <>
        <Button id="filter_button" style={style.filterButton}>
          <div style={style.spacedBetweenRow}>
            <div>{filterNames[filterBy]}</div>
            <div>
              <FontAwesomeIcon icon={faFilter} />
            </div>
          </div>
        </Button>
        <Popover
          flip={false}
          popperClassName="teamFilterPopover"
          isOpen={showFilterPopover}
          trigger="legacy"
          target="filter_button"
          toggle={() => {
            setShowFilterPopover(!showFilterPopover);
          }}
        >
          <PopoverHeader>
            <div style={style.spacedBetweenRow}>
              <div>Filter/Sort</div>
              <div>
                <FontAwesomeIcon
                  icon={faTimesCircle}
                  style={style.clickable}
                  onClick={(e) => {
                    e.stopPropagation();
                    setShowFilterPopover(false);
                  }}
                />
              </div>
            </div>
          </PopoverHeader>
          <PopoverBody>
            {Object.keys(filterButtonNames).map((name) => {
              return (
                <Button
                  color={filterBy === name ? null : 'primary'}
                  style={{ ...style.filterChoiceButton, backgroundColor: filterBy === name ? '#d9f5ff' : null }}
                  onClick={() => {
                    setFilterBy(name);
                    setShowFilterPopover(false);
                  }}
                >
                  {filterButtonNames[name]}
                </Button>
              );
            })}

            <Button
              color="danger"
              style={style.filterChoiceButton}
              onClick={() => {
                setFilterBy('All');
                setSearchString('');
                setShowFilterPopover(false);
              }}
            >
              Clear Filters
            </Button>
          </PopoverBody>
        </Popover>
      </>
    );
  };

  const renderUserList = () => {
    const usersToShow = [];

    filteredUsers.forEach((u) => {
      const { Deleted } = u;
      if (Deleted && filterBy !== 'Deactivated') {
      } else {
        usersToShow.push(u);
      }
    });
    return (
      <div style={style.userListContainer}>
        {usersToShow.map((user, index) => {
          return (
            <SingleUser
              discardChanges={discardChanges}
              hasUserDetailChanges={hasUserDetailChanges}
              key={user.pk_User}
              user={user}
              getUsers={getUsers}
              selectedUser={selectedUser}
              postUserInAuth={postUserInAuth}
              index={index}
              setSelectedUser={setSelectedUser}
              filterBy={filterBy}
            />
          );
        })}
      </div>
    );
  };

  return (
    <Row style={{ padding: 10 }} className="card shadow bg-light">
      <Col md={12}>
        <Row>{renderSearchInput()}</Row>
        <Row>{renderFilterButton()}</Row>
        <Row> {renderUserList()}</Row>
        <Row>{renderBulkImporter()}</Row>

        <Row>
          <Button
            style={style.filterChoiceButton}
            color="primary"
            id="Popover1"
            type="button"
            onClick={() => {
              setBulkImportPopoverOpen(true);
            }}
          >
            <div style={style.spacedBetweenRow}>
              <FontAwesomeIcon icon={faUpload} />
              <div style={style.centeredText}> Bulk Import Users</div>
            </div>
          </Button>
        </Row>
        <Row>
          <Button
            style={style.filterChoiceButton}
            color="success"
            onClick={() => {
              setShowAddUser(true);
            }}
          >
            <div style={style.spacedBetweenRow}>
              <FontAwesomeIcon icon={faUserPlus} />
              <div style={style.centeredText}> New User</div>
            </div>
          </Button>
        </Row>
      </Col>
    </Row>
  );
};

export default UserList;
