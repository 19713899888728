import React, { useContext, useEffect, useState } from 'react';
import { faTrashAlt, faPencilAlt, faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Input } from 'reactstrap';
import style from './GrpupItem.style';
import { DepartmentContext } from 'DepartmentWrapper';
import { useAuth0 } from 'auth0/reactAuth0Spa';
import { deleteData, putData } from 'Services/dataApi';
import { formatBearerToken } from 'Common.functions';
import Swal from 'sweetalert2';
import { useAlert } from 'react-alert';

function GroupItem({ Group, index, onClickGroup, onSave, getGroups }) {
  const [myGroup, setMyGroup] = useState({});
  const [edit, setEdit] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const backgroundColor = index % 2 == 0 ? '#c7cbd1' : 'white';

  const dContext = useContext(DepartmentContext);
  const alert = useAlert();

  const { getTokenSilently } = useAuth0();

  useEffect(() => {
    if (Group) {
      setMyGroup(Group);
    }
  }, [Group]);

  const updateGroup = (Group) => {
    setIsLoading(true);
    getTokenSilently()
      .then((token) => {
        putData(
          'candidate/group',
          {
            pk_Department: dContext.department.pk_Department,
            pk_Group: Group.pk_Group,
            pk_Season: dContext.season.pk_Season,
          },
          { Name: Group.Name, Description: Group.Description },
          formatBearerToken(token),
        )
          .then((results) => {
            setIsLoading(false);
            getGroups();
            alert.success('Group updated successfully');
          })
          .catch((err) => {
            setIsLoading(false);
            getGroups();
            alert.error('Error updating group');
          });
      })
      .catch((err) => {
        setIsLoading(false);
        getGroups();
        alert.error('Error updating group');
      });
  };

  const deleteGroup = (groupToDelete, promiseDetails) => {
    // console.log('deleteGroupFromCandidates firing');
    setIsLoading(true);
    getTokenSilently()
      .then((token) => {
        deleteData(
          'candidate/group',
          {
            pk_Department: dContext.department.pk_Department,
            pk_Season: dContext.season.pk_Season,
            pk_Group: groupToDelete.pk_Group,
          },

          {},
          formatBearerToken(token),
        )
          .then((res) => {
            setIsLoading(false);
            alert.success('Group deleted successfully');
            if (promiseDetails) {
              const { resolve } = promiseDetails;
              resolve('true');
            } else {
            }
            getGroups();
          })
          .catch((err) => {
            alert.error(`Error deleting "${groupToDelete.Name}"`);
            setIsLoading(false);
            if (promiseDetails) {
              const { reject } = promiseDetails;
              reject();
            }
            getGroups();
          });
      })
      .catch((err) => {
        alert.error(`Error deleting "${groupToDelete.Name}"`);
        setIsLoading(false);
        getGroups();
      });
  };

  const addGroup = (Group) => {};

  return (
    <div style={{ ...style.itemRow, backgroundColor }}>
      <div style={style.simpleColumn}>
        <div style={style.simpleRow}>
          {edit ? (
            <Input
              value={myGroup.Name}
              type="text"
              onChange={(e) => {
                setMyGroup({ ...myGroup, Name: e.target.value });
                // onSave({ ...myGroup, Name: e.target.value, index: index });
              }}
            />
          ) : (
            <div style={style.itemLabel}>{myGroup.Name}</div>
          )}
        </div>
      </div>
      <div style={style.simpleColumn}>
        <div style={style.simpleRow}>
          <div style={{ marginRight: '30px' }}>
            <FontAwesomeIcon
              icon={faPencilAlt}
              style={style.clickable}
              onClick={() => {
                // Add SWAL with input for editing group name
                Swal.fire({
                  title: 'Edit Group Name',
                  input: 'text',
                  inputValue: myGroup.Name,
                  showCancelButton: true,
                  confirmButtonText: 'Save',
                  cancelButtonText: 'Cancel',
                  inputValidator: (value) => {
                    if (!value) {
                      return 'You need to write something!';
                    }
                  },
                }).then((result) => {
                  if (result.isConfirmed) {
                    setMyGroup({ ...myGroup, Name: result.value });
                    updateGroup({ ...myGroup, Name: result.value });
                  }
                });

                // if (edit) {
                //   // updateGroup(myGroup);
                // } else {
                //   setEdit(!edit);
                // }
              }}
            />
          </div>
          <div style={{ marginRight: '35px' }}>
            <FontAwesomeIcon
              icon={faTrashAlt}
              style={style.clickable}
              onClick={() => {
                // onDeleteTag(Group.pk_Group);
                Swal.fire({
                  title: 'Are you sure?',
                  text: 'You will not be able to recover this group!',
                  icon: 'warning',
                  showCancelButton: true,
                  confirmButtonColor: 'red',
                  confirmButtonText: 'Yes, delete it!',
                  cancelButtonText: 'Cancel',
                }).then((result) => {
                  if (result.isConfirmed) {
                    deleteGroup(myGroup);
                  }
                });
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default GroupItem;
