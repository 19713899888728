import arrayMove from 'array-move';
import moment from 'moment';
import React, { useContext, useEffect, useState, Fragment } from 'react';
import ReactCSSTransitionReplace from 'react-css-transition-replace';
import Loading from '../Statuses/Loading';
import Error from '../Statuses/Error';
import Success from '../Statuses/Success';
import Info from '../Statuses/Info';
import { Button } from 'reactstrap';
import Swal from 'sweetalert2';
import { DepartmentContext } from '../../DepartmentWrapper';
import { fetchDataAgnostic, postDataAgnostic, putData } from '../../Services/dataApi';
import { insertDate } from '../../SortableList';
import CandidateValidatePopup from './CandidateValidatePopup/CandidateValidatePopup';
import { renderCandidateNotFound } from '../../Common.functions';
import { demoInterviewDates4Portal, demoUserObject } from './demo';
import { HeaderWrapper } from './InterviewDatesWrapper.styles';
import InterviewConfirmRouter from './InterviewConfirmRouter';
import InterviewDateConfirm from './InterviewDatesSteps/InterviewDateConfirm';
import InterviewDateDecline from './InterviewDatesSteps/InterviewDateDecline';
import CandidateHeader from '../../Header/header.container';
import InterviewDateSelection from './InterviewDatesSteps/InterviewDateSelection';
import { CustomCard, CustomCardHeader, CustomCarousel, CustomCol } from './InterviewDatesWrapper.styles';

const headers = [
  'Please confirm your choice to decline',
  'Select one or multiple available interview dates:',
  'Please confirm your selection:',
  `Congratulations, you've been scheduled for the following date`,
  'You have been waitlisted for the dates below',
  'You have been waitlisted for the date below',
  '-',
];
const InterviewDatesRouter = (props) => {
  const [candidateHasDeclined, setCandidateHasDeclined] = useState(false);
  const [candidateMessage, setCandidateMessage] = useState('');
  const [currentSlide, setCurrentSlide] = useState(1);
  const [data, setData] = useState(null);
  const [dateIsLocked, setDateIsLocked] = useState(false);
  const [dateOfInterview, setDateOfInterview] = useState(false);
  const [demo, setDemo] = useState((clientID && clientID.toUpperCase() === 'DEMO') || clientID == null ? true : false);
  const [digits, setDigits] = useState([]);
  const [displayOverlay, setDisplayOverlay] = useState(false);
  const [emailsForToken, setEmailsForToken] = useState({});
  const [emailToSendToken, setEmailToSendToken] = useState(null);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [getError, setGetError] = useState(false);
  const [hasMadeSelection, setHasMadeSelection] = useState(false);
  const [hasNoChoicesToMake, setHasNoChoicesToMake] = useState(false);
  const [hasPermission, setHasPermission] = useState(true);
  const [hasPreferenceOrderWithNoInterviewDate, setHasPreferenceOrderWithNoInterviewDate] = useState(false);
  const [interviewDate, setInterviewDate] = useState(null);
  const [needUserInfo, setNeedUserInfo] = useState(true);
  const [pin, setPin] = useState(null);
  const [preferenceItems, setPreferenceItems] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedItemIndex, setSelectedItemIndex] = useState(null);
  const [singleDateChoice, setSingleDateChoice] = useState(false);
  const [validCandidate, setValidCandidate] = useState(true);
  const [step, setStep] = useState(1);
  const clientID = props.match.params.id;
  const [automationIsEnabled, setAutomationIsEnabled] = useState(false);
  const [iseWaitlisted, setIsWaitlisted] = useState(false);

  const [hasInterviewDate, setHasInterviewDate] = useState(false);

  const [headerText, setHeaderText] = useState(['Select one or multiple available interview dates:']);
  const [ignoreDecline, setIgnoreDecline] = useState(false);
  const [incorrectUUID, setIncorrectUUID] = useState(false);
  const [isInvalidPin, setIsInvalidPin] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [modificationConfirmed, setModificationConfirmed] = useState(false);
  const [needsAccessToken, setNeedsAccessToken] = useState(false);
  const [scheduledLabel, setScheduledLabel] = useState(null);
  const [selectedableDates, setSelectedableDates] = useState([]);
  const [selectedDates, setSelectedDates] = useState([]);
  const [showAlert, setShowAlert] = useState(false);

  const { listArray, order, user } = props;
  const { setUser, setDateList, setOrder, location, history } = props;

  const dContext = useContext(DepartmentContext);

  useEffect(() => {
    if (clientID && clientID.toUpperCase() !== 'DEMO') {
      getCandidate();
      getDepartmentInfo();
      setDemo(false);
    } else {
      Swal.fire('This is a demo!', 'Changes will not be recorded. <br> Feel free to click around.');
      setUser(demoUserObject);
      setOrder(demoInterviewDates4Portal);
      setDateList(demoInterviewDates4Portal);
      setSelectedableDates(demoInterviewDates4Portal);
      setIsLoading(false);
      // setTitle({
      //   pageTitle: 'Registration',
      //   departmentName: data.Departments4PortalName,
      //   organizationName: data.Departments4PortalOrganization,
      // });
    }
  }, [clientID, isLoading]);

  const getDepartmentInfo = () => {
    if (props && props.match && props.match.params && props.match.params.id) {
      fetchDataAgnostic('department/season/candidate/candidateId', { candidateUUID: props.match.params.id })
        .then((result) => {
          setAutomationIsEnabled(result.data.EnableAutoSlotState);
          setIsWaitlisted(result.data.IsWaitlisted);
          props.setUser(result.data);
        })
        .catch((err) => {});
    }
  };

  const checkForToken = () => {
    const AccessID = localStorage.getItem('AccessID');
    if (AccessID === null) {
      setNeedsAccessToken(true);
      fetchDataAgnostic('candidate/email', { clientID: clientID }, null)
        .then((result) => {
          setEmailsForToken(result.data);
        })
        .catch((err) => {})
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  const tokenCheck = () => {
    checkForToken();
    setHasPermission(false);
  };

  const getCandidate = (modifySelection) => {
    // NOTE: we're currently leaving this out of the MVP
    const AccessID = '123455';
    // const AccessID = localStorage.getItem('AccessID');

    // if (AccessID === null && modifySelection) {
    //   // tokenCheck();
    //   return;
    // }

    setSelectedableDates([]);

    fetchDataAgnostic('candidate/interviewDates', {
      CandidateUUID: clientID,
      AccessID,
      modifySelection,
      pk_Season: dContext.season.pk_Season,
    })
      .then((result) => {
        if (result.data)
          if (result.data && result.data.permission != null && result.data.permission === false) {
            // if permission is false then the AccessID is invalid
            localStorage.removeItem('AccessID');
            return;
          }

        const {
          hasInterviewDate,
          madeChoices,
          dates,
          interviewDate,
          permission,
          declined,
          candidateMessage,
        } = result.data;

        if (declined === true) {
          setCandidateHasDeclined(true);
        }
        // if (permission === false) {
        //   tokenCheck();
        //   return;
        // }
        if (dates && dates.length === 1 && !madeChoices) {
          setSingleDateChoice(true);
          dates[0].selected = true;
          setHeaderText('The following date is available for interview');
        }

        if (hasInterviewDate) {
          setHasInterviewDate(true);
          setInterviewDate(interviewDate);
          return;
        }
        dates.forEach((item, i) => {
          const { DateOfInterview } = item;
          dates[i].DateOfInterview = new Date(DateOfInterview);
        });

        if (dates.length === 0) {
          setHasNoChoicesToMake(true);
        }
        setCandidateMessage(candidateMessage);
        setHasMadeSelection(madeChoices);
        setOrder(dates);

        // if there are no dates we shouldn't ask them to pick one
        if (dates.length === 0) {
          setHeaderText('-');
        }
        setDateList(dates);
        setSelectedableDates(dates);
      })
      .catch((err) => {
        console.log('err', err.response);

        if (err && err.response && err.response.status === 404) {
          setValidCandidate(false);
        } else {
          setError(true);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const sendEmail = (e) => {
    e.preventDefault();
    postDataAgnostic('candidate/sendToken', { clientID, emailType: emailToSendToken }, null)
      .then((result) => {})
      .catch((err) => {
        // Swal.fire('Error', 'Something went wrong, please try again later or contact support', 'error');
        // setHasPermission(true);
      });
  };

  const submitPin = (pin, callback) => {
    setIsInvalidPin(false);
    return putData(
      'candidate/authenticate',
      {
        CandidateUUID: clientID,
        AccessPin: pin,
        // pk_Season: dContext.season.pk_Season,
      },
      null,
    )
      .then((result) => {
        if (result.data === 'Invalid pin') {
          localStorage.removeItem('AccessID');
          setDigits(['', '', '', '', '', '']);
          setIsInvalidPin(true);
          return 'invalid pin';
        }

        if (result.data === 'Expired pin') {
          localStorage.removeItem('AccessID');
          setDigits(['', '', '', '', '', '']);
          return 'expired pin';
        }

        if (result.data.AccessID) {
          try {
            localStorage.setItem('AccessID', result.data.AccessID);
            Swal.fire('Validated', '', 'success');
            setHasInterviewDate(false);
            setHasMadeSelection(false);
            setDateOfInterview(false);
            setOrder([]);
            setDateList([]);
            setSelectedableDates([]);
            setHasPermission(true);
            getCandidate(true);
          } catch (err) {}
        }
      })
      .catch((err) => {});
  };

  const checkForRemovalOfCurrentSelections = () => {
    // if isSelected is true and selected is false than it means they are removing this date from their previously selected dates
    return listArray.filter((item) => item.isSelected && !item.selected);
  };

  // Does not let the user move to the next screen until they've picked at least one date
  const validateDateSelection = (e, num) => {
    e.preventDefault();
    if (listArray.filter((item) => item.selected).length === 0) {
      Swal.fire('Oops...', 'Please select at least one interview date.', 'error');
    } else {
      const hasDeselected = checkForRemovalOfCurrentSelections();
      if (hasDeselected.length > 0) {
        const message = `Are you sure you want to remove yourself from ${hasDeselected.length} date${
          hasDeselected.length === 1 ? '' : 's'
        }?`;
        Swal.fire({
          title: 'Caution!!',
          text: message,
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: 'red',
          confirmButtonText: `Continue`,
        }).then((result) => {
          if (result.value) {
            changeStep(e, num);
          }
        });
      } else {
        changeStep(e, num);
      }
    }
  };

  // changes step.  Currently this moves it back and forth based on the num that's passed in
  const changeStep = (e, num) => {
    // if (e) e.preventDefault();

    const { listArray } = props;

    setHeaderText(headers[step]);
    setCurrentSlide(num);
    setHeaderText(headers[num]);
    // this scrollTo helps with very long lists
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const handleValidationCancel = () => {
    setHasPermission(true);
  };

  const renderContent = () => {
    if (isLoading) {
      return <Loading />;
    }

    if (hasNoChoicesToMake && !hasInterviewDate) {
      const { DepartmentName } = user;
      return (
        <Info
          header={`${DepartmentName} does not currently have any dates available.`}
          subHeader={'Please contact the coordinator for additional details'}
        />
      );
    }

    // NOTE: we're leaving this out for the MVP
    return (
      // <div key="7">
      //   <CandidateValidatePopup
      //     isOpen={!hasPermission}
      //     sendEmail={sendEmail}
      //     setEmailToSendToken={setEmailToSendToken}
      //     digits={digits}
      //     setDigits={setDigits}
      //     cancelCallback={handleValidationCancel}
      //     pin={pin}
      //     emailsForToken={emailsForToken}
      //     setPin={setPin}
      //     isInvalidPin={isInvalidPin}
      //     submitPin={submitPin}
      //   />
      <Fragment>{loadContent()}</Fragment>
      // </div>
    );
  };

  // toggles item selection if selected item is already clicked
  // otherwise, set selected item as item clicked
  const toggleOrSetSelectedItem = (id, index) => {
    if (id === selectedItem) {
      setSelectedItem(null);
      setSelectedItemIndex(null);
    } else {
      setSelectedItem(id);
      setSelectedItemIndex(index);
    }
  };

  // not needed as increment step handles both
  //TODO deprecate this and rename increment step
  const decrementStep = () => {
    setHeaderText(headers[step - 2]);
    setStep(step - 1);
  };

  // uses the index of selectableDates
  const addToSelectedDates = (index) => {
    const newItem = selectedableDates[index];
    newItem.selected = true;
    setSelectedDates([...selectedDates, newItem]);
    setSelectedableDates(selectedableDates.filter((date, i) => i !== index));
  };

  // TODO: Deprecate if browser uses drag and drop functionality
  const resetSelection = () => {
    setSelectedItem(null);
    setSelectedItemIndex(null);
  };

  // TODO: Deprecate if browser uses drag and drop functionality
  const moveSelectedItemUp = (buttonIsDisabled) => {
    if (!buttonIsDisabled) {
      const newArray = arrayMove(selectedDates, selectedItemIndex, selectedItemIndex - 1);
      setSelectedDates(newArray);
      setSelectedItemIndex(selectedItemIndex - 1);
    }
  };

  // TODO: Deprecate if browser uses drag and drop functionality
  const moveSelectedItemDown = (buttonIsDisabled) => {
    if (!buttonIsDisabled) {
      const newArray = arrayMove(selectedDates, selectedItemIndex, selectedItemIndex + 1);
      setSelectedDates(newArray);
      setSelectedItemIndex(selectedItemIndex + 1);
    }
  };

  // TODO: Deprecate if browser uses drag and drop functionality
  const retractFromSelectedDates = (buttonIsDisabled) => {
    if (!buttonIsDisabled) {
      setSelectedableDates(insertDate(selectedableDates, selectedDates[selectedItemIndex]));
      setSelectedDates(selectedDates.filter((date, i) => i !== selectedItemIndex));
      resetSelection();
    }
  };

  const ignoreDeclineFlag = (e) => {
    e.preventDefault();
    setCurrentSlide(1);
    setHasPreferenceOrderWithNoInterviewDate(false);
    setDateOfInterview(false);
    setIgnoreDecline(true);
  };

  const loadContent = () => {
    const { listArray = [], order, user } = props;

    if (!validCandidate) {
      return renderCandidateNotFound();
    }

    if (error) {
      const header = errorMessage.header ? errorMessage.header : 'service is temporarily unavailable';
      const subHeader = errorMessage.subHeader ? errorMessage.subHeader : 'please try again later';
      return <Error header={header} subHeader={subHeader} />;
    }
    if (incorrectUUID) {
      return (
        <Error
          key={1}
          header="The ID provided is either invalid or expired"
          subHeader="Please verify the address and try again"
        />
      );
    }
    // if (!user || !listArray || listArray.length === 0) {
    //   return <Loading key={1} header="Loading invitation..." />;
    // }
    if (candidateHasDeclined && !ignoreDecline) {
      // if (false) {
      return (
        <CustomCol key={2} sm={12}>
          <CustomCard>
            <div
              className="alert alert-error"
              style={{
                textAlign: 'center',
                flexDirection: 'column',
                height: '40vh',
                margin: '0',
                display: 'flex',
                justifyContent: 'space-evenly',
                alignItems: 'center',
              }}
            >
              <h3 style={{ paddingBottom: '20px' }}>You have declined your invitation</h3>
              <Button onClick={(e) => ignoreDeclineFlag(e)} color="primary">
                I changed my mind...
              </Button>
            </div>
          </CustomCard>
        </CustomCol>
      );
      // will display schedule date (not needed for now)
    } else if (hasInterviewDate && interviewDate) {
      const interviewDateFormatted = moment(interviewDate.DateOfInterview)
        .utc()
        .format('dddd, MMMM DD, YYYY');
      const message = `Congratulations, you're scheduled to interview on the following date`;
      return (
        <CustomCol key={2} sm={12}>
          <CustomCard>
            <CustomCardHeader>
              <h6>{message}</h6>
              {interviewDate.DateLocked && (
                <h6>Your interview date is locked in - if you need to modify it, please contact your coordinator</h6>
              )}
            </CustomCardHeader>
            <div
              style={{
                height: 'calc(100vh - 350px)',
                width: '100%',
                display: 'flex',
                justifyContent: 'start',
                alignItems: 'center',
                flexDirection: 'column',
              }}
            >
              <InterviewDateConfirm
                changeStep={changeStep}
                dateOfInterview={interviewDate}
                getCandidate={getCandidate}
                hasMadeSelection={true}
                hasPreferenceOrderWithNoInterviewDate={hasPreferenceOrderWithNoInterviewDate}
                interviewDate={interviewDate}
                isScheduled={true}
                listArray={listArray}
                modificationConfirmed={modificationConfirmed}
                scheduledInterviewDate={interviewDateFormatted}
                setDateList={setDateList}
                setDateOfInterview={setDateOfInterview}
                setDigits={setDigits}
                setHasInterviewDate={setHasInterviewDate}
                setHasMadeSelection={setHasMadeSelection}
                setHasNoChoicesToMake={setHasNoChoicesToMake}
                setIsLoading={setIsLoading}
                setModificationConfirmed={setModificationConfirmed}
                setOrder={setOrder}
                setSelectedableDates={setSelectedableDates}
                showButton={false}
                submitPin={submitPin}
              />
            </div>
          </CustomCard>
        </CustomCol>
      );
      // } else if (needUserInfo) {
      //   return <div />;
      //   // return <GetUserInfo />;
    } else if (hasMadeSelection) {
      return (
        <CustomCol key={2} sm={12}>
          <CustomCard>
            <CustomCardHeader>
              <h4>Thank you for your submission.</h4>
              <h6>{candidateMessage}</h6>
            </CustomCardHeader>
            <div
              style={{
                height: 'calc(100vh - 350px)',
                width: '100%',
                display: 'flex',
                justifyContent: 'start',
                alignItems: 'center',
                flexDirection: 'column',
              }}
            >
              <InterviewDateConfirm
                changeStep={changeStep}
                getCandidate={getCandidate}
                hasMadeSelection={hasMadeSelection}
                hasPreferenceOrderWithNoInterviewDate={hasPreferenceOrderWithNoInterviewDate}
                listArray={listArray}
                modificationConfirmed={modificationConfirmed}
                setDateList={setDateList}
                setDateOfInterview={setDateOfInterview}
                setDigits={setDigits}
                setHasInterviewDate={setHasInterviewDate}
                setHasMadeSelection={setHasMadeSelection}
                setHasNoChoicesToMake={setHasNoChoicesToMake}
                setIsLoading={setIsLoading}
                setModificationConfirmed={setModificationConfirmed}
                setOrder={setOrder}
                setSelectedableDates={setSelectedableDates}
                showButton={false}
                submitPin={submitPin}
              />
            </div>
          </CustomCard>
        </CustomCol>
      );
      return (
        <CustomCol key={2} sm={12}>
          <CustomCard>
            <CustomCardHeader>
              <h4>{headerText}</h4>
            </CustomCardHeader>
            {renderSwitch()}
          </CustomCard>
        </CustomCol>
      );
    } else {
      return (
        <CustomCol key={2} sm={12}>
          <CustomCard>
            <CustomCardHeader>
              <h4>{headerText}</h4>
            </CustomCardHeader>
            {renderSwitch()}
          </CustomCard>
        </CustomCol>
      );
    }

    // <div>There's nothing here</div>
  };

  const renderSwitch = () => {
    return (
      <CustomCarousel
        className="carousel"
        showArrows={false}
        showStatus={false}
        showIndicators={false}
        showThumbs={false}
        swipeable={false}
        selectedItem={currentSlide}
      >
        <div
          id="slide1"
          style={{
            minHeight: 300,
            height: 'auto',
            // height: 'calc(100vh - 300px)',
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <InterviewDateDecline changeStep={changeStep} clientID={clientID} location={location} demo={demo} />
        </div>
        <div
          id="slide2"
          style={{
            minHeight: 300,
            height: 'auto',
            // height: 'calc(100vh - 300px)',
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {/* vet these once final decisions are made on UI */}
          <InterviewDateSelection
            addToSelectedDates={addToSelectedDates}
            changeStep={changeStep}
            currentSlide={currentSlide}
            dateOfInterview={dateOfInterview}
            decrementStep={decrementStep}
            hasPreferenceOrderWithNoInterviewDate={hasPreferenceOrderWithNoInterviewDate}
            moveSelectedItemDown={moveSelectedItemDown}
            moveSelectedItemUp={moveSelectedItemUp}
            preferenceItems={preferenceItems}
            retractFromSelectedDates={retractFromSelectedDates}
            selectedableDates={selectedableDates}
            selectedDates={selectedDates}
            selectedItem={selectedItem}
            selectedItemIndex={selectedItemIndex}
            setCurrentSlide={setCurrentSlide}
            setSelectedableDates={setSelectedableDates}
            setSelectedDates={setSelectedDates}
            setSelectedItemIndex={setSelectedItemIndex}
            setShowAlert={setShowAlert}
            showAlert={showAlert}
            singleDateChoice={singleDateChoice}
            toggleOrSetSelectedItem={toggleOrSetSelectedItem}
            validateDateSelection={validateDateSelection}
          />
        </div>

        <div
          id="slide3"
          style={{
            minHeight: 300,
            height: 'auto',
            // height: 'calc(100vh - 300px)',
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {/* {displayOverlay ||
            (true && <div style={{ height: '100%', backgroundColor: '#000', position: 'absolute' }}></div>)} */}
          <InterviewConfirmRouter
            changeStep={changeStep}
            listArray={listArray}
            clientID={clientID}
            location={location}
            headerText={headerText}
            dateIsLocked={dateIsLocked}
            demo={demo}
            singleDateChoice={singleDateChoice}
            setHasPermission={setHasPermission}
            setDateList={setDateList}
            setHasNoChoicesToMake={setHasNoChoicesToMake}
            setHasInterviewDate={setHasInterviewDate}
            setIsLoading={setIsLoading}
            scheduledLabel={scheduledLabel}
            setDisplayOverlay={setDisplayOverlay}
            order={order}
            // hasPreferenceOrderWithNoInterviewDate={hasPreferenceOrderWithNoInterviewDate}
            setDateOfInterview={setDateOfInterview}
            setError={setError}
            dateOfInterview={dateOfInterview}
            hasPreferenceOrderWithNoInterviewDate={hasPreferenceOrderWithNoInterviewDate}
            setHasPreferenceOrderWithNoInterviewDate={setHasPreferenceOrderWithNoInterviewDate}
            showButtons
            currentSlide={currentSlide}
            setCurrentSlide={setCurrentSlide}
            decrementStep={decrementStep}
            selectedableDates={selectedableDates}
            setSelectedableDates={setSelectedableDates}
            selectedDates={selectedDates}
            setSelectedDates={setSelectedDates}
            addToSelectedDates={addToSelectedDates}
            hasPermission={hasPermission}
            sendEmail={sendEmail}
            setEmailToSendToken={setEmailToSendToken}
            pin={pin}
            emailsForToken={emailsForToken}
            setPin={setPin}
            isInvalidPin={isInvalidPin}
            submitPin={submitPin}
          />
        </div>
      </CustomCarousel>
    );
  };

  return (
    <HeaderWrapper>
      <CandidateHeader showNotifTester={false} />
      <br />
      <br />
      {renderContent()}
    </HeaderWrapper>
  );
};

export default InterviewDatesRouter;
