const initialState = {
  userInfo: null,
  breakUrl: { url: '', buttonText: '' },
  userObject: null,
  externalToken: null,
  userDepartment: null,
  allUsers: [],
};

const UserInformationReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_USER':
      return {
        ...state,
        userInfo: action.payload,
      };
    case 'SET_BREAK_URL':
      return {
        ...state,
        breakUrl: action.payload,
      };
    case 'SET_USER_OBJECT':
      return {
        ...state,
        userObject: action.payload,
      };
    case 'SET_EXTERNAL_CODE':
      return {
        ...state,
        externalToken: action.payload,
      };
    case 'SET_USER_DEPARTMENT':
      return {
        ...state,
        userDepartment: action.payload,
      };
    case 'SET_ALL_USER_DEPARTMENTS':
      return {
        ...state,
        allUserDepartments: action.payload,
      };
    case 'SET_ALL_USERS':
      return {
        ...state,
        allUsers: action.payload,
      };
    default:
      return state;
  }
};

export default UserInformationReducer;
