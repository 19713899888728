const style = {
  imageSizing: {
    maxWidth: '100%',
    maxHeight: '100%',
    width: 'auto',
    height: 'auto',
  },
  background: {
    background: '',
  },
  simpleRow: {
    display: 'flex',
    flexDirection: 'row',
  },
  centeredText: {
    width: '100%',
    textAlign: 'center',
    alignItems: 'center',
    verticalAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
  },
  simpleColumn: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
};

export default style;
