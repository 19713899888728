import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import imageToBase64 from 'image-to-base64';

import tinycolor from 'tinycolor2';
import { clone } from '../../schedule';
import Swal from 'sweetalert2';
import { calculateFontSize, pdf_concatenate } from '../../candidates';
import rezRateLogo from '../rezRateLogo.png';
import bgGuide from './AVERY_5384.png';

pdfMake.vfs = pdfFonts.pdfMake.vfs;
let rezRateLogoBase64 = null;
// 1 inch = 72 units.
// LETTER size is 612x792

const tableLayouts = {
  customTable: {
    hLineWidth: (i, node) => {
      return 0;
      // if (i === 0 || i === node.table.body.length) {
      //   return 0;
      // }
      // return i === node.table.headerRows ? 2 : 1;
    },
    vLineWidth: (i) => {
      return 0;
    },
    hLineColor: (i) => {
      return i === 1 ? 'black' : '#aaa';
    },
    paddingLeft: (i) => {
      return 0;
    },
    paddingRight: (i, node) => {
      return 0;
    },
    paddingTop: (i, node) => {
      return 0;
    },
    paddingBottom: (i, node) => {
      return 0;
    },
  },
};

const truncate = (wordToTruncate, limit = 0, forcedCut) => {
  if (wordToTruncate == null) {
    return '';
  }
  if (limit == 0 || wordToTruncate.length < limit + 1) {
    return wordToTruncate;
  } else if (forcedCut) {
    return `${wordToTruncate.slice(0, limit)}`;
  } else if (wordToTruncate.length > limit + 1) {
    return `${wordToTruncate.slice(0, limit - 5)}. . .`;
  }
};

const createPDFProper = (data) => {
  const { interviewDate, candidates, department, departmentLogo } = data;
  const { ProgramType } = department;

  const rows = [];
  let counter = 0;
  const renderCard = (candidate, counter) => {
    const cardRows = [];

    const subTextKey = 'CandidateSubHeading';

    const deptLogo = {
      width: 55,
      columns: [
        {
          image: `data:image/png;base64,${departmentLogo || rezRateLogoBase64}`,
          // width: 90,
          fit: [55, 50],
        },
      ],
      margin: [10, 0, 10, 0],
    };

    cardRows.push({
      columns: [
        deptLogo,
        {
          width: 225,
          // text: pdf_concatenate({ string: department.DepartmentName, maxLimit: 30 }),
          text: department.DepartmentName,
          fontSize: calculateFontSize({
            text: department.DepartmentName,
            maxWidth: 650,
            maxHeight: 18,
          }),
          style: { alignment: 'left' },
          margin: [10, 5, 0, 0],
        },
      ],
      margin: [0, 20, 0, 5],
    });

    cardRows.push({ canvas: [{ type: 'line', x1: 20, y1: 0, x2: 260.2, y2: 0, lineWidth: 1 }] });
    cardRows.push([
      {
        style: { alignment: 'center', bold: true },
        // text: pdf_concatenate({
        //   string: `${candidate.FirstName} ${candidate.LastName}`,
        //   maxLimit: 22,
        //   forcedCut: true,
        // }),
        text: `${candidate.FirstName} ${candidate.LastName}`,
        fontSize: calculateFontSize({
          text: `${candidate.FirstName} ${candidate.LastName}`,
          maxWidth: 450,
          maxHeight: 22,
          startingFontSize: 22,
        }),
        margin: [15, 20, 15, 0],
      },
    ]);

    cardRows.push([
      {
        style: { alignment: 'center' },
        text: `${candidate[subTextKey] ? `${candidate[subTextKey]}` : '---'}`,
        fontSize: calculateFontSize({
          text: `${candidate[subTextKey] ? `${candidate[subTextKey]}` : '---'}`,
          maxWidth: 490,
          maxHeight: 14,
          startingFontSize: 14,
        }),
        margin: [15, 10, 15, 10],
      },
    ]);

    return cardRows;
  };

  while (counter < candidates.length) {
    const columns = [];
    const tableRow = [];

    columns.push({ width: 3.95 * 72, columns: [renderCard(candidates[counter], counter)] });
    tableRow.push(renderCard(candidates[counter], counter));
    counter++;
    if (counter < candidates.length) {
      columns.push({ width: 3.95 * 72, columns: [renderCard(candidates[counter], counter)] });
      tableRow.push(renderCard(candidates[counter], counter));
    } else {
      tableRow.push({ text: '' });
    }

    rows.push(tableRow);
    counter++;
  }

  let pdfObject = [
    { table: { heights: 212, widths: [3.95 * 72, 3.95 * 72], body: rows, dontBreakRows: true }, layout: 'customTable' },
  ];

  if (rows.length <= 0) {
    Swal.fire('Error', 'There are no Candidates for this Interview Date. There is nothing to print!', 'error');
    return;
  }

  // imageToBase64(bgGuide)
  //   .then((bgBase64) => {
  const docDefinition = {
    // background: { image: `data:image/png;base64,${bgBase64}`, width: 612 },
    info: { title: 'Candidate Badges 5384' },
    pageSize: 'LETTER',
    pageOrientation: 'portrait',
    content: pdfObject,
    defaultStyle: {
      fontSize: 15,
    },

    pageMargins: [0.25 * 72, 72, 0.25 * 72, 72],
  };

  pdfMake.createPdf(docDefinition, tableLayouts).open({}, window.open('', '_blank'));
  // })
  // .catch((err) => {});
};

export const createPDFForCandidateBadges2 = ({ interviewDate, department, candidates = [], callback }) => {
  const startProper = () => {
    const candidatesCopy = clone(candidates);
    if (department.DepartmentLogo) {
      imageToBase64(department.DepartmentLogo)
        .then((res) => {
          if (callback) {
            callback();
          }
          createPDFProper({ candidates: candidatesCopy, department, departmentLogo: res });
        })
        .catch((err) => {
          if (callback) {
            callback();
          }

          createPDFProper({ candidates: candidatesCopy, department });
        });
    } else {
      if (callback) {
        callback();
      }
      createPDFProper({ candidates: candidatesCopy, department });
    }
  };

  imageToBase64(rezRateLogo)
    .then((res) => {
      rezRateLogoBase64 = res;
      startProper();
    })
    .catch(() => {
      startProper();
    });
};
