const style = {
  simpleColumn: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  simpleRow: {
    display: 'flex',
    flexDirection: 'row',
  },
  spacedBetweenRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  titleHeader: {
    fontSize: 20,
    fontWeight: 'bold',
  },
  subTitleHeader: {
    fontSize: 17,
    fontWeight: 'bold',
    width: '100%',
    textAlign: 'center',
  },
  inputContainer: {
    minWidth: 150,
    width: '100%',
    marginTop: 20,
  },
  previewHeader: {
    verticalAlign: 'middle',
    fontSize: 25,
  },
  templatesSection: {
    display: 'flex',
    flexDirection: 'row',
  },
  templatesSectionSubcontainers: {
    display: 'flex',
    flexDirection: 'column',
    width: '50%',
    // margin: 10,
    paddingTop: 20,
    paddingBottom: 20,
    paddingLeft: 20,
    paddingRight: 20,
  },
  previewContainer: {
    marginTop: 10,
    height: 300,
    overflowY: 'scroll',
    backgroundColor: 'aliceblue',
    padding: 10,
  },
};
export default style;
