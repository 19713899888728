import {
  faCheckSquare,
  faClipboardCheck,
  faClipboardList,
  faEnvelope,
  faPortrait,
  faSquare,
  faThumbsDown,
  faTimes,
  faUserCheck,
  faUserTimes,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CandidateStatusIcons } from 'enums.js';
import moment from 'moment';
import React, { useContext } from 'react';
import { UncontrolledTooltip } from 'reactstrap';
import Swal from 'sweetalert2';
import style from './style.js';
import { clone } from 'lodash';
import { DepartmentContext } from 'DepartmentWrapper.js';

const CandidateListItem = ({
  candidate = {},
  discardChanges,
  filterBy,
  hasChanges,
  index,
  isGettingCandidateDetails,
  multiselect,
  selectedCandidate,
  selectedCandidateIds,
  setSelectedCandidate,
  toggleCandidateSelection,
  uniqueId,
  useUnsavedChangesAlert,
}) => {
  const dContext = useContext(DepartmentContext);
  const {
    PhotoUrl,
    Email,
    DateOfInterview,
    pk_Candidate,
    DeclinedComments,
    EnableMarkedForInvitation,
    EnableMarkedForPrescreen,
    EnableRespondedToInvitation,
    HasBeenRejectedForInterview,
    HasBeenSentConfirmation,
    HasBeenSentInvitation,
    HasDeclinedInterview,
    IsScheduled,
    IsWaitlisted,
    WaitlistDates,
  } = candidate;

  let candidateName = `---`;
  if (candidate && candidate.LastName && candidate.FirstName) {
    candidateName = `${candidate.LastName}, ${candidate.FirstName}`;
  } else if (candidate && candidate.LastName) {
    candidateName = `${candidate.LastName}`;
  } else if (candidate && candidate.FirstName) {
    candidateName = ` ${candidate.FirstName}`;
  }

  const isSelected = multiselect && selectedCandidateIds[candidate.pk_Candidate];

  let backgroundColor = null;
  if ((!multiselect && selectedCandidate && selectedCandidate.pk_Candidate == candidate.pk_Candidate) || isSelected) {
    backgroundColor = '#d9f5ff'; //'#fffd7d';
  } else if (index % 2 == 0) {
    backgroundColor = '#c7cbd1';
  }

  let subText = '';
  if (filterBy == 'All by Import Date') {
    subText =
      candidate && candidate.createdAt //createdAt
        ? `Imported: ${moment(candidate.createdAt).format('dddd, MMM. DD, YYYY')}`
        : 'No Import Date';
  } else if (candidate && DateOfInterview) {
    subText = `${moment(DateOfInterview).format('dddd, MMM. DD, YYYY')} ${
      candidate.Label ? `(${candidate.Label})` : ''
    }`;
  } else if (candidate && WaitlistDates) {
    subText = 'Pref: ';
    WaitlistDates.sort((a, b) => {
      return a.PreferenceOrder - b.PreferenceOrder;
    }).forEach((date, i) => {
      if (date.PreferenceOrder != null && date.PreferenceOrder != undefined) {
        subText += `${subText !== 'Pref: ' ? ', ' : ''}${
          date.DateOfInterview ? moment(date.DateOfInterview).format('MM/DD') : 'N/A'
        }`;
      }
    });
  }

  const renderStatusBar = () => {
    const statuses = [];
    const statusStyle = { marginRight: 5, cursor: 'pointer' };
    const { season } = dContext || {};
    const { AllowPrescreen } = season || {};
    const {
      pendingInvite,
      invited,
      waitlisted,
      confirmed,
      unconfirmed,
      rejected,
      scheduled,
      markedForInvite,
      prescreen,
    } = CandidateStatusIcons;

    if (EnableMarkedForInvitation) {
      statuses.push(
        <>
          <FontAwesomeIcon
            id={`marked_for_invite_${pk_Candidate}`}
            icon={markedForInvite.icon}
            style={{ color: markedForInvite.color, ...statusStyle }}
          />
          <UncontrolledTooltip
            target={`marked_for_invite_${pk_Candidate}`}
            flip={false}
            modifiers={{ flip: { behavior: ['top'] }, preventOverflow: { boundariesElement: 'viewport' } }}
          >
            Marked for Invite - This candidate was marked for Invitation.
          </UncontrolledTooltip>
        </>,
      );
    }

    if (HasBeenSentInvitation) {
      // Invited, waiting response
      statuses.push(
        <>
          <FontAwesomeIcon
            id={`invited_${pk_Candidate}`}
            icon={invited.icon}
            style={{ color: invited.color, ...statusStyle }}
          />
          <UncontrolledTooltip
            target={`invited_${pk_Candidate}`}
            flip={false}
            modifiers={{ flip: { behavior: ['top'] }, preventOverflow: { boundariesElement: 'viewport' } }}
          >
            Invited - This candidate has been sent an invitation.
          </UncontrolledTooltip>
        </>,
      );
    } else if (EnableMarkedForInvitation && !HasBeenSentInvitation && !IsWaitlisted && !IsScheduled) {
      // Pending Invi
      statuses.push(
        <>
          <FontAwesomeIcon
            id={`pending_invite_${pk_Candidate}`}
            icon={pendingInvite.icon}
            style={{ color: pendingInvite.color, ...statusStyle }}
          />
          <UncontrolledTooltip
            target={`pending_invite_${pk_Candidate}`}
            flip={false}
            modifiers={{ flip: { behavior: ['top'] }, preventOverflow: { boundariesElement: 'viewport' } }}
          >
            Pending Invite - This candidate was marked for Invitation but has not been sent an invite yet.
          </UncontrolledTooltip>
        </>,
      );
    }

    if (IsWaitlisted) {
      statuses.push(
        <>
          <FontAwesomeIcon
            id={`waitlisted_${pk_Candidate}`}
            icon={waitlisted.icon}
            style={{ color: waitlisted.color, ...statusStyle }}
          />
          <UncontrolledTooltip
            target={`waitlisted_${pk_Candidate}`}
            flip={false}
            modifiers={{ flip: { behavior: ['top'] }, preventOverflow: { boundariesElement: 'viewport' } }}
          >
            Waitlisted - This candidate is currently waitlisted.
          </UncontrolledTooltip>
        </>,
      );
    }

    if (AllowPrescreen && EnableMarkedForPrescreen) {
      statuses.push(
        <>
          <FontAwesomeIcon
            id={`marked_for_prescreen_${pk_Candidate}`}
            icon={prescreen.icon}
            style={{ color: prescreen.color, ...statusStyle }}
          />
          <UncontrolledTooltip
            target={`marked_for_prescreen_${pk_Candidate}`}
            flip={false}
            modifiers={{ flip: { behavior: ['top'] }, preventOverflow: { boundariesElement: 'viewport' } }}
          >
            Marked for Prescreen - This candidate is marked for Prescreen.
          </UncontrolledTooltip>
        </>,
      );
    }
    if (IsScheduled) {
      statuses.push(
        <>
          <FontAwesomeIcon
            id={`confirmation_${pk_Candidate}`}
            icon={HasBeenSentConfirmation ? confirmed.icon : unconfirmed.icon}
            style={{ color: HasBeenSentConfirmation ? confirmed.color : unconfirmed.color, ...statusStyle }}
          />
          <UncontrolledTooltip
            target={`confirmation_${pk_Candidate}`}
            flip={false}
            modifiers={{ flip: { behavior: ['top'] }, preventOverflow: { boundariesElement: 'viewport' } }}
          >
            {HasBeenSentConfirmation
              ? 'Confirmation Sent - Candidate has been sent a registration confirmation email.'
              : 'Unconfirmed - Candidate has not been sent a registration confirmation email.'}
          </UncontrolledTooltip>
        </>,
      );

      statuses.push(
        <>
          <FontAwesomeIcon
            id={`scheduled_${pk_Candidate}`}
            icon={scheduled.icon}
            style={{ color: scheduled.color, ...statusStyle }}
          />
          <UncontrolledTooltip
            target={`scheduled_${pk_Candidate}`}
            flip={false}
            modifiers={{ flip: { behavior: ['top'] }, preventOverflow: { boundariesElement: 'viewport' } }}
          >
            Scheduled - This candidate is registered for an interview date! (
            {moment(DateOfInterview).format('dddd, MMM. DD, YYYY')})
          </UncontrolledTooltip>
        </>,
      );
    }

    if (HasBeenRejectedForInterview) {
      statuses.push(
        <>
          <FontAwesomeIcon
            id={`rejected_${pk_Candidate}`}
            icon={rejected.icon}
            style={{ color: rejected.color, ...statusStyle }}
          />
          <UncontrolledTooltip
            target={`rejected_${pk_Candidate}`}
            flip={false}
            modifiers={{ flip: { behavior: ['top'] }, preventOverflow: { boundariesElement: 'viewport' } }}
          >
            Rejected - This candidate was marked as Rejected by Program.
          </UncontrolledTooltip>
        </>,
      );
    }

    return statuses;
  };

  const handleSelectCandidate = () => {
    if (!multiselect) {
      if (
        (setSelectedCandidate && selectedCandidate == null) ||
        (selectedCandidate && candidate.pk_Candidate != selectedCandidate.pk_Candidate)
      ) {
        setSelectedCandidate(candidate);
      }
    } else {
      toggleCandidateSelection(candidate.pk_Candidate);
    }
  };

  return (
    <div
      tabIndex={0}
      disabled={true}
      id={`candidateList_${pk_Candidate}_${uniqueId || ''}`}
      style={{
        ...style.itemRow,
        backgroundColor,
      }}
      onClick={(e) => {
        e.preventDefault();
        if (
          !isGettingCandidateDetails &&
          hasChanges &&
          useUnsavedChangesAlert &&
          candidate.pk_Candidate != selectedCandidate.pk_Candidate
        ) {
          Swal.fire({
            title: 'Warning!',
            text: `There are unsaved changes. Are you sure want to load a different candidate?`,
            // showDenyButton: true,
            showCancelButton: true,
            confirmButtonText: `Discard`,
            // denyButtonText: `Cancel`,
          }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.value) {
              if (discardChanges) {
                discardChanges();
                handleSelectCandidate();
              }
            }
          });
          return;
        } else {
          handleSelectCandidate();
        }
      }}
    >
      <div style={style.simpleColumn}>
        <div style={style.simpleRow}>
          {multiselect ? (
            <FontAwesomeIcon style={{ fontSize: 20, marginRight: 15 }} icon={isSelected ? faCheckSquare : faSquare} />
          ) : null}
          <div style={{ width: 90, alignContent: 'center', marignRight: 10 }}>
            {PhotoUrl ? (
              <div
                style={{
                  width: 80,
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <img src={PhotoUrl} width={80} style={style.imgPlaceHolder} />
              </div>
            ) : (
              <FontAwesomeIcon style={style.imgPlaceHolder} icon={faPortrait} />
            )}
          </div>
          <div style={style.candidateInfo}>
            <div style={style.simpleRow}>
              <div
                style={{
                  fontWeight: 'bold',
                  display: 'flex',
                  flexDirection: 'column',
                  width: '100%',
                }}
              >
                <div
                  style={{
                    ...style.simpleRow,
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    width: '100%',
                  }}
                >
                  {candidateName}
                </div>
              </div>
            </div>

            <div style={{ fontSize: 11 }}>{Email}</div>
            {HasDeclinedInterview ? null : <div style={{ fontSize: 11 }}>{subText}</div>}

            <div style={{ ...style.simpleRow, fontSize: 12, alignItems: 'center' }}>{renderStatusBar()}</div>

            {HasDeclinedInterview ? (
              <>
                <div id={`declined_${pk_Candidate}`} style={{ fontSize: 11, color: 'red', fontWeight: 'bold' }}>
                  CANDIDATE DECLINED
                </div>
                <UncontrolledTooltip
                  target={`declined_${pk_Candidate}`}
                  flip={false}
                  modifiers={{ flip: { behavior: ['right'] }, preventOverflow: { boundariesElement: 'viewport' } }}
                >
                  Candidate Declined - This candidate has declined the invitation. Comments:{' '}
                  {DeclinedComments || `No Comments`}
                </UncontrolledTooltip>
              </>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CandidateListItem;
