const style = {
  spacedBetweenRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  simpleColumn: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  simpleRow: {
    display: 'flex',
    flexDirection: 'row',
  },
  ellipsis: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    textAlign: 'center',
    width: 'calc(100% - 45px)',
  },
  rowColumn: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    padding: 10,
  },
};

export default style;
