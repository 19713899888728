import React from 'react';
import EvaluationQuestion from './EvaluationQuestion';
import AdminEvaluationDreyfusItem from '../AdminEvaluationQuestionTemplate/AdminEvaluationScaleItem/AdminEvaluationDreyfusItem';
import AdminEvaluationLikertItem from '../AdminEvaluationQuestionTemplate/AdminEvaluationScaleItem/AdminEvaluationLikertItem';

const EvaluationQuestionOptions = ({ options, questionScale, updateQuestion, isReadOnly, isUpdatingOptions }) => {
  const handleChange = () => {};

  const renderOptions = () => {
    if (questionScale === 'dreyfus10') {
      return options.map((item, i) => {
        return (
          <AdminEvaluationDreyfusItem
            i={i}
            key={item.number}
            length={options.length}
            item={item}
            updateQuestion={updateQuestion}
            isUpdatingOptions={isUpdatingOptions || isReadOnly}
          />
        );
      });
    } else {
      return options.map((item, i) => {
        return (
          <AdminEvaluationLikertItem
            i={i}
            key={item.number}
            length={options.length}
            item={item}
            updateQuestion={updateQuestion}
            isUpdatingOptions={isUpdatingOptions || isReadOnly}
          />
        );
      });
    }
  };

  return <div className="row">{renderOptions()}</div>;
};

export default EvaluationQuestionOptions;
