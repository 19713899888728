import { faCalendarAlt, faLock, faPencilAlt, faUser, faUsers } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { Col, Row, TabContent, TabPane } from 'reactstrap';
import Swal from 'sweetalert2';
import { informUserEvalLocked, smoothScrollTop } from '../../../../../Common.functions';
import CandidatesAlphabetical from '../CandidatesAlphabetical';
import Evaluation from '../Evaluation';
import Portfolio from '../Portfolio';
import Schedule from '../Schedule';
import Style from './EvaluateMobile.style';

const disabledColor = '#a8a8a8';
const iconSize = '2x';

const EvaluateMobile = ({
  userInformation,
  selectedUser,
  selectedCandidateId,
  checkIfEvalsAreLocked,
  lockedStatuses,
  checkIfStatusNeedsChangingToModify,
  candidateInfo,
  allDayRoom,
  setCandidateEvaluationStatus,
  candidateEvaluationStatus,
  evaluationsLocked,
  setCandidateEvaluation,
  differences,
  alarms,
  pk_User,
  informUserMeetingIsStartingLocally,
  setAlarms,
  isAdmin,
  filterCandidateInfo,
  getCandidateStatus,
  incompleteCandidates,
  interviewHasPassed,
  requestZoomMeeting,
  handleZoomClick,
  disableJoinMeetingButton,
  setDisableJoinMeetingButton,
  currentlyGettingEval,
  Schedules4Eval__pkUUID_Schedule,
  firstBlockEarlyJoin,
  Schedules4EvalJoinManualURL,
  interviewIsToday,
  setSchedules4EvalJoinManualURL,
  reduxDate,
  setSchedules4Eval__pkUUID_Schedule,
  setShowLargePhoto,
  setUserIdOnCurrentInterview,
  setShowRed,
  requestVirtualMeetingEvaluator,
  currentCandidateSortType,
  sortCandAscending,
  showRed,
  userIdOnCurrentInterview,
  DNRStatus,
  setDNRStatus,
  setEvalStatus,
  evalStatus,
  setLargePhoto,
  date,
  getCandidateDetails,
  getCandidateEvaluation,
  getInfo,
  schedule,
  timeoutDurationInMinutes,
  postRequest,
  getCandidate,
  candidateEvaluation,
  setSelectedUser,
  setSelectedTimeSlot,
  selectedTimeSlot,
}) => {
  const [carouselIndex, setCarouselIndex] = useState(0);
  const [activeTab, setActiveTab] = useState('1');
  const [hasSchedule, setHasSchedule] = useState(null);
  const [selectedCandidateIdLocal, setSelectedCandidateIdLocal] = useState(null);
  const [disableEvaluation, setDisableEvaluation] = useState(true);
  const [error, setError] = useState(false);
  const [disableScreen, setDisableScreen] = useState(false);
  const [showSchedule, setShowSchedule] = useState(false);
  const [renderEval, setRenderEval] = useState(false);
  const [chosenCandidate, setChosenCandidate] = useState({ uniqueId: null, candidate: null });
  const [firstUserRecieved, setFirstUserRecieved] = useState(false);
  const stylething = { width: '100vw', display: 'none' };

  useEffect(() => {
    if (evalStatus === 'Complete') {
      getInfo();
      setActiveTab('3');
    }
  }, [evalStatus]);

  const getDefaultTab = () => {
    if (date === 'Prescreen') {
      toggle('2');
      return;
    }
    if (hasSchedule) {
      toggle('1');
    } else {
      toggle('2');
    }
  };

  useEffect(() => {
    if (schedule && schedule.length > 0) {
      setHasSchedule(true);
    } else {
      setHasSchedule(false);
    }
  }, [schedule]);

  useEffect(() => {
    if (hasSchedule !== null) getDefaultTab();
  }, [date, hasSchedule]);

  useEffect(() => {
    if (selectedUser && selectedUser !== 'fetching') {
      if (selectedUser.CandidateSelected__pkUUID_Candidate !== selectedCandidateIdLocal) {
        setFirstUserRecieved(true);
        setActiveTab('3');
        smoothScrollTop();
        setSelectedCandidateIdLocal(selectedUser.CandidateSelected__pkUUID_Candidate);
      }
    }

    if ((!selectedUser && disableEvaluation === false) || selectedUser === 'fetching') {
      setDisableEvaluation(true);
    } else if (selectedUser && selectedUser !== 'fetching' && disableEvaluation === true) {
      setDisableEvaluation(false);
    }
  }, [selectedUser]);

  const changeEvalTab = () => {
    setRenderEval(false);
    return getCandidateEvaluation(selectedUser.pk_Candidate, selectedUser.pk_InterviewDate).then((res) => {
      setRenderEval(true);
      smoothScrollTop();
      if (!res.error) {
        setActiveTab('4');
        getInfo(true, true, false, currentCandidateSortType, sortCandAscending);
        return true;
      } else {
        setError(true);
        return false;
      }
    });
  };

  const changeToCandidateTab = () => {
    getInfo(true, true, false, currentCandidateSortType, sortCandAscending);
    setActiveTab('3');
  };

  const getEvalAndChangeTab = () => {
    if (checkIfEvalsAreLocked()) {
      informUserEvalLocked();
      return;
    }
    if (
      candidateEvaluationStatus &&
      candidateEvaluationStatus.Status &&
      candidateEvaluationStatus.Status.toUpperCase() === 'COMPLETE'
    ) {
      return Swal.fire({
        title: 'Do you want to modify this evaluation?',
        text: `Status will be set to "In progress."`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Modify',
      }).then((result) => {
        if (result.value === true) {
          getInfo(true, true, false, currentCandidateSortType, sortCandAscending);
          return changeEvalTab();
        }
      });
    } else {
      return changeEvalTab();
    }
  };

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  return (
    <div style={{ top: 0 }} className="mobile_wrapper">
      <div style={{ minHeight: 'calc(100vh - 186px)' }}>
        <TabContent activeTab={activeTab} style={{ width: '100%' }}>
          {schedule && (
            <TabPane tabId="1">
              <Schedule
                alarms={alarms}
                allDayRoom={allDayRoom}
                changeToCandidateTab={changeToCandidateTab}
                date={date}
                differences={differences}
                disableJoinMeetingButton={disableJoinMeetingButton}
                firstBlockEarlyJoin={firstBlockEarlyJoin}
                getCandidate={getCandidate}
                handleZoomClick={handleZoomClick}
                informUserMeetingIsStartingLocally={informUserMeetingIsStartingLocally}
                interviewIsToday={interviewIsToday}
                isMobile={true}
                postRequest={postRequest}
                requestVirtualMeetingEvaluator={requestVirtualMeetingEvaluator}
                requestZoomMeeting={requestZoomMeeting}
                schedule={schedule}
                Schedules4Eval__pkUUID_Schedule={Schedules4Eval__pkUUID_Schedule}
                Schedules4EvalJoinManualURL={Schedules4EvalJoinManualURL}
                selectedCandidateIdLocal={selectedCandidateIdLocal}
                setAlarms={setAlarms}
                setDisableJoinMeetingButton={setDisableJoinMeetingButton}
                setSchedules4Eval__pkUUID_Schedule={setSchedules4Eval__pkUUID_Schedule}
                setSchedules4EvalJoinManualURL={setSchedules4EvalJoinManualURL}
                setShowRed={setShowRed}
                setUserIdOnCurrentInterview={setUserIdOnCurrentInterview}
                showRed={showRed}
                userIdOnCurrentInterview={userIdOnCurrentInterview}
                selectedTimeSlot={selectedTimeSlot}
                setSelectedTimeSlot={setSelectedTimeSlot}
                setSelectedUser={setSelectedUser}
              />
            </TabPane>
          )}
          <TabPane tabId="2">
            <Row>
              <Col md="12">
                <CandidatesAlphabetical
                  selectedUser={selectedUser}
                  getCandidate={getCandidate}
                  Schedules4Eval__pkUUID_Schedule={Schedules4Eval__pkUUID_Schedule}
                  setSchedules4Eval__pkUUID_Schedule={setSchedules4Eval__pkUUID_Schedule}
                  setChosenCandidate={setChosenCandidate}
                  chosenCandidate={chosenCandidate}
                  isMobile={true}
                  disableJoinMeetingButton={disableJoinMeetingButton}
                  setDisableJoinMeetingButton={setDisableJoinMeetingButton}
                  changeToCandidateTab={changeToCandidateTab}
                  selectedCandidateIdLocal={selectedCandidateIdLocal}
                  Schedules4EvalJoinManualURL={Schedules4EvalJoinManualURL}
                  setSchedules4EvalJoinManualURL={setSchedules4EvalJoinManualURL}
                  candidateInfo={candidateInfo}
                  setUserIdOnCurrentInterview={setUserIdOnCurrentInterview}
                  userIdOnCurrentInterview={userIdOnCurrentInterview}
                  requestZoomMeeting={requestZoomMeeting}
                  interviewIsToday={interviewIsToday}
                  handleZoomClick={handleZoomClick}
                  postRequest={postRequest}
                />
              </Col>
            </Row>
          </TabPane>
          <TabPane tabId="3">
            <Portfolio
              selectedUser={selectedUser}
              setCarouselIndex={setCarouselIndex}
              mobile="true"
              getEvalAndChangeTab={() => setActiveTab('3')}
              DNRStatus={DNRStatus}
              allDayRoom={allDayRoom}
              lockedStatuses={lockedStatuses}
              candidateEvaluationStatus={candidateEvaluationStatus}
              disableJoinMeetingButton={disableJoinMeetingButton}
              setDisableJoinMeetingButton={setDisableJoinMeetingButton}
              date={date}
              evaluationsLocked={evaluationsLocked}
              checkIfEvalsAreLocked={checkIfEvalsAreLocked}
              activeTab={activeTab}
              setDNRStatus={setDNRStatus}
              setChosenCandidate={setChosenCandidate}
              Status4SelectedDNR={
                candidateEvaluation && candidateEvaluation.Status ? candidateEvaluation.Status.EnableDoNotRank : false
              }
              chosenCandidate={chosenCandidate}
              setShowSchedule={setShowSchedule}
              setShowLargePhoto={setShowLargePhoto}
              setLargePhoto={setLargePhoto}
            />
          </TabPane>
          <TabPane tabId="4">
            {selectedUser && (
              <div style={{ width: '100%', display: 'flex', justifyContent: 'space-evenly', alignItems: 'center' }}>
                <img style={{ height: '50px', width: 'auto' }} src={selectedUser.CandidateSelectedPhotoSM} />
                <p style={{ padding: 0, margin: 0 }}>{selectedUser.CandidateSelectedNameFull}</p>
              </div>
            )}
            {!disableEvaluation && renderEval && !evaluationsLocked && (
              <Evaluation
                candidateEvaluation={candidateEvaluation}
                candidateInfo={candidateInfo}
                getCandidateStatus={getCandidateStatus}
                isAdmin={isAdmin}
                candidateEvaluationStatus={candidateEvaluationStatus}
                checkIfStatusNeedsChangingToModify={checkIfStatusNeedsChangingToModify}
                activeTab={activeTab}
                evalTab="4"
                reduxDate={reduxDate}
                pk_User={pk_User}
                date={date}
                setCandidateEvaluationStatus={setCandidateEvaluationStatus}
                setDisableScreen={setDisableScreen}
                schedule={schedule}
                getInfo={getInfo}
                DNRStatus={DNRStatus}
                setCandidateEvaluation={setCandidateEvaluation}
                toggleToSchedule={() => {
                  if (schedule && schedule.length) {
                    setActiveTab('1');
                  } else {
                    setActiveTab('2');
                  }
                }}
                setDNRStatus={setDNRStatus}
                setEvalStatus={setEvalStatus}
                selectedUser={selectedUser}
                evalStatus={evalStatus}
                timeoutDurationInMinute={timeoutDurationInMinutes}
                typeOfEvent="touchstart"
                selectedCandidateId={selectedCandidateId}
                currentlyGettingEval={currentlyGettingEval}
                getCandidateEvaluation={getCandidateEvaluation}
                Status4SelectedDNR={
                  candidateEvaluation && candidateEvaluation.Status ? candidateEvaluation.Status.EnableDoNotRank : false
                }
              />
            )}
          </TabPane>
        </TabContent>
      </div>
      <Style.FixedNavigation>
        <Style.NavigationSub>
          <div>
            <div
              onClick={() => {
                if (hasSchedule && activeTab !== '1') {
                  setCarouselIndex(0);
                  smoothScrollTop();
                  getInfo(true, true, false, currentCandidateSortType, sortCandAscending);
                  setActiveTab('1');
                }
              }}
              style={{ backgroundColor: hasSchedule ? '#084887' : disabledColor }}
            >
              <FontAwesomeIcon style={{ left: '16px' }} size={iconSize} icon={faCalendarAlt} />
            </div>
            <p>Schedule</p>
          </div>
          <div>
            <div
              onClick={() => {
                if (activeTab !== '2') {
                  setCarouselIndex(1);
                  smoothScrollTop();
                  getInfo(true, true, false, currentCandidateSortType, sortCandAscending);
                  setActiveTab('2');
                }
              }}
              style={{ backgroundColor: '#D64933' }}
            >
              <FontAwesomeIcon style={{ left: '11px' }} size={iconSize} icon={faUsers} />
            </div>
            <p>Candidates</p>
          </div>
          <div>
            <div
              onClick={() => {
                getCandidateEvaluation(selectedUser.pk_Candidate, selectedUser.pk_InterviewDate).then((res) => {
                  if (!res.error) {
                    setActiveTab('3');
                  } else {
                    setError(true);
                  }
                });
              }}
              style={{ backgroundColor: firstUserRecieved && selectedUser !== 'fetching' ? '#ADC698' : disabledColor }}
            >
              <FontAwesomeIcon style={{ left: '15px' }} size={iconSize} icon={faUser} />
            </div>
            <p>Details</p>
          </div>
          <div>
            <div
              onClick={() => {
                if (evaluationsLocked) {
                  informUserEvalLocked();
                  return;
                }

                if (disableEvaluation) return;
                getEvalAndChangeTab();
              }}
              style={{
                backgroundColor:
                  firstUserRecieved && selectedUser !== 'fetching' && !evaluationsLocked ? '#F7B538' : disabledColor,
              }}
            >
              <FontAwesomeIcon
                style={{ left: informUserEvalLocked ? '16px' : '14px' }}
                size={iconSize}
                icon={informUserEvalLocked ? faLock : faPencilAlt}
              />
            </div>
            <p>Evaluation</p>
          </div>
        </Style.NavigationSub>
      </Style.FixedNavigation>
    </div>
  );
};

export default EvaluateMobile;
