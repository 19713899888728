import styled from 'styled-components';

const UserInformationStyle = {
  Wrapper: styled.div`
    color: #fff;
    display: flex;
    padding-top: 10px;
    padding-bottom: 10px;
    justify-content: space-between;
    & img {
      height: 50px;
      width: auto;
    },
  `,
  TitleWrapper: styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    padding: 5px 5px 5px 0px;
  `,
  CogWrapper: styled.div`
    position: absolute;
    bottom: 0;
    right: 0;
  `,
  AvatarIconWrapper: styled.div`
    border: 3px solid #fff;
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
  `,
};

export default UserInformationStyle;
