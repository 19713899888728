import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import imageToBase64 from 'image-to-base64';

import { clone } from '../../schedule';
import Swal from 'sweetalert2';
import { calculateFontSize, pdf_concatenate } from '../../candidates';
import rezRateLogo from '../rezRateLogo.png';
import moment from 'moment-timezone';
import { roundToDecimalPlace } from 'Common.functions';

pdfMake.vfs = pdfFonts.pdfMake.vfs;
let rezRateLogoBase64 = null;
const candidateImages = {};
const candidateSessions = {};

// 1 inch = 72 units.
// LETTER size is 612x792

const tableLayouts = {
  customTable: {
    hLineWidth: (i, node) => {
      return 0;
    },
    vLineWidth: (i) => {
      return 0;
    },
    hLineColor: (i, node) => {
      return '#aaa';
    },
    paddingLeft: (i) => {
      return 0;
    },
    paddingRight: (i, node) => {
      return 0;
    },
    paddingTop: (i, node) => {
      return 10;
    },
    paddingBottom: (i, node) => {
      return 10;
    },
  },
};

/**
 * We plant the header data we want on each element we add to the content array we put in the docDef.
 * Each element when processed, have a pageNumber value embedded deep in it. We use this function is used to get that
 * embedded header data, in this case, the candidate. The location of this data is retrieved based on the
 * @param {object} documentDefinition  contains the elements to render.
 * @param {number} currentPage what page the header is being rendered on.
 * @returns {object} Returns hte header object with all the data for header/footer, in this case,
 */
const getDataForHeaderFooter = (documentDefinition, currentPage) => {
  let headerData = {};
  let candidatePages = {}; // arrays of page numbers involved with a candidate
  let lastCandidate = null;
  documentDefinition.content[0].table.body.forEach((candidateCard, candidateIndex) => {
    const candidateQuestionsCard = candidateCard[0].stack[2].stack[0].table.body[0];
    lastCandidate = candidateCard[0].stack[0].stack[0].pageHeaderData.candidate;

    candidateQuestionsCard.forEach((questionCard, questionCardIndex) => {
      const pageNumber = questionCard.positions[0].pageNumber;
      if (
        !headerData.candidateData &&
        (pageNumber == currentPage ||
          (pageNumber == currentPage - 1 && questionCardIndex == candidateQuestionsCard.length - 1))
      ) {
        const { pageHeaderData = {} } = questionCard.stack ? questionCard.stack[0] : {};
        const { candidate = {} } = pageHeaderData;
        const { pk_Candidate = null } = candidate;

        const oldPages = candidatePages[pk_Candidate] || [];

        if (!oldPages.includes(pageNumber)) {
          oldPages.push(pageNumber);
          candidatePages[pk_Candidate] = oldPages;
        }

        headerData.candidateData = candidate;
      }
    });
  });

  if (!headerData.candidateData) {
    // headerData.candidateData = lastCandidate;
  }
  headerData.candidatePages = candidatePages;

  return headerData;
};

const getGroupAverages = (data) => {
  const { GroupAverages = {} } = data;
  const groupData = {};
  const keys = [
    'Average',
    'ExamineeCount',
    'PassingScore',
    'PassRate',
    'FailRate',
    'RankTotal',
    'Mean',
    'StandardDeviation',
  ];
  keys.forEach((key) => {
    groupData[key] = GroupAverages[key] || 0;
  });

  groupData.GroupName = GroupAverages.GroupName || '';
  return groupData;
};

const renderCandidatedetailsCard = (options) => {
  const { departmentLogo, department, candidate, rawData } = options;
  const { DepartmentName = '', OrganizationName = '' } = department || {};
  const { LastName = '', FirstName = '', DateOfInterview, InterviewStartTime, Label, userPhotoBase64 } = candidate;
  const candidateName = `${LastName ? LastName : ''}, ${FirstName ? FirstName : ''}`;
  const detailsTextAreaMaxWidth = 7 * 72 - 120;
  const sessionDate = candidateSessions[candidate.pk_Candidate] || '--- -- ----';
  const deptLogo = {
    // image: `data:image/png;base64,${departmentLogo || rezRateLogoBase64}`,
    image: `data:image/png;base64,${rezRateLogoBase64}`,
    width: 100,
    fit: [100, 100],
  };
  const candidateImage = {
    image: `data:image/png;base64,${candidateImages[candidate.pk_Candidate] || rezRateLogoBase64}`,
    width: 100,
    fit: [100, 100],
  };

  const columns = {
    widths: [100, '*'],
    columns: [
      candidateImages[candidate.pk_Candidate] ? candidateImage : deptLogo,
      {
        text: [
          {
            text: `${DepartmentName || ''} `,
            style: {
              alignment: 'left',
              bold: true,
              fontSize: calculateFontSize({
                text: DepartmentName || '',
                maxWidth: detailsTextAreaMaxWidth,
                maxHeight: 18,
              }),
            },
            width: detailsTextAreaMaxWidth,
            fillColor: '#f5f5f5',
            margin: [10, 5, 0, 0],
          },
          {
            text: `\n${OrganizationName || ''}`,
            style: {
              alignment: 'left',
              bold: true,
              fontSize: calculateFontSize({
                text: OrganizationName || '',
                maxWidth: detailsTextAreaMaxWidth,
                maxHeight: 18,
              }),
            },
            width: detailsTextAreaMaxWidth,
            fillColor: '#f5f5f5',
            margin: [10, 5, 0, 0],
          },
          {
            text: `\n${sessionDate}`,
            style: {
              alignment: 'left',
              bold: true,
              fontSize: calculateFontSize({
                text: sessionDate,
                maxWidth: detailsTextAreaMaxWidth,
                maxHeight: 18,
              }),
            },
            width: detailsTextAreaMaxWidth,
            fillColor: '#f5f5f5',
            margin: [10, 0, 0, 10],
          },
          {
            text: `\n\n${candidateName}`,
            style: {
              alignment: 'left',
              bold: true,
              fontSize: calculateFontSize({
                text: candidateName,
                maxWidth: detailsTextAreaMaxWidth,
                maxHeight: 18,
                startingFontSize: 20,
              }),
            },
            width: detailsTextAreaMaxWidth,
            fillColor: '#f5f5f5',
            margin: [10, 5, 0, 0],
          },
        ],
        width: detailsTextAreaMaxWidth,
      },
    ],
    margin: [10, 10, 10, 0],
  };

  const pageHeaderData = { candidate };

  return {
    pageHeaderData,
    widths: [7 * 72],
    table: {
      body: [[columns]],
    },
    layout: {
      hLineWidth: (i, node) => {
        return 0;
        if (i == node.table.body.length) {
          return 1;
        } else {
          return 0;
        }
      },
      vLineWidth: (i, node) => {
        return 0;
      },
      hLineColor: (i, node) => {
        return '#aaa';
      },
      paddingBottom: (i, node) => {
        return 10;
      },
    },
  };
};

const renderScoreSummaryCard = (options) => {
  const { candidate, candidateCount, department, rawData } = options;
  const { EnableGroups, ProgramType } = department;
  const { fk_Group } = candidate;

  const {
    StandardDeviation = 0,
    Mean = 0,
    PassingScore = 0,
    PassRate = '',
    FailRate = '',
    Average = 0,
    GroupData = {},
    ExamineeCount = 0,
    RankTotal = 0,
  } = rawData || {};

  const { GroupMedianScores = {}, GroupAverages = {} } = GroupData[fk_Group] || {};

  const { Rank = 0, TotalPoints = 0, ExamScore = 0, Result = '----', GroupRank = '' } = candidate;

  const defaultStyle = { fontSize: 10 };

  const compareWithGroups =
    EnableGroups && ProgramType === 'Mock Oral Board' && fk_Group != null && GroupAverages.pk_Group != null;
  // &&
  // GroupMedianScores.pk_Group != null;

  const widths = compareWithGroups ? [1.5 * 72, 1.5 * 72, 2 * 72 - 5, 2 * 72 - 5] : [2 * 72, 2 * 72, 3 * 72 - 10];

  const groupValues = getGroupAverages(GroupData[fk_Group] || {});
  const groupColumnRows = [
    { text: `${groupValues.GroupName || ''} Examinees`, style: { bold: true, fontSize: 12 } },
    { text: groupValues.ExamineeCount, style: defaultStyle, fillColor: '#e8e8e8' },
    // {
    //   text: `--`,
    //   style: defaultStyle,
    // },
    // {
    //   text: `${roundToDecimalPlace(groupValues.Mean, 3, false)}`,
    //   style: defaultStyle,
    //   fillColor: '#e8e8e8',
    // },
    // {
    //   text: `${roundToDecimalPlace(groupValues.StandardDeviation, 3, false)}`,
    //   style: defaultStyle,
    // },
    { text: roundToDecimalPlace(groupValues.Average, 3, false), style: { ...defaultStyle, bold: true, fontSize: 13 } },
    { text: groupValues.PassingScore, style: defaultStyle, fillColor: '#e8e8e8' },
    {
      text: [
        { text: 'Pass: ', bold: true },
        `${roundToDecimalPlace(parseFloat(groupValues.PassRate) * 100, 3, false)}%\n`,
        { text: 'Fail: ', bold: true },
        `${roundToDecimalPlace(parseFloat(groupValues.FailRate) * 100, 3, false)}%`,
      ],
      style: defaultStyle,
    },
    { text: `${GroupRank} of ${groupValues.RankTotal}`, style: defaultStyle, fillColor: '#e8e8e8' },
  ];

  const tableBody = [
    [
      { text: '' },
      { text: 'Examinee', style: { bold: true, fontSize: 12 } },
      { text: 'All Examinees', style: { bold: true, fontSize: 12 } },
    ],
    [
      { text: 'Examinee Count', style: { ...defaultStyle, bold: true }, fillColor: '#e8e8e8' },
      { text: '1', style: defaultStyle, fillColor: '#e8e8e8' },
      { text: ExamineeCount || 0, style: defaultStyle, fillColor: '#e8e8e8' },
    ],
    // [
    //   { text: 'Total Points', style: { ...defaultStyle, bold: true } },
    //   { text: roundToDecimalPlace(TotalPoints, 3, false), style: defaultStyle },
    //   {
    //     text: '--',
    //     style: defaultStyle,
    //   },
    // ],
    // [
    //   { text: 'Mean', style: { ...defaultStyle, bold: true }, fillColor: '#e8e8e8' },
    //   { text: '--', style: defaultStyle, fillColor: '#e8e8e8' },
    //   {
    //     text: `${roundToDecimalPlace(Mean, 3, false)}`,
    //     style: defaultStyle,
    //     fillColor: '#e8e8e8',
    //   },
    // ],
    // [
    //   { text: 'StDev', style: { ...defaultStyle, bold: true } },
    //   { text: '--', style: defaultStyle },
    //   {
    //     text: `${roundToDecimalPlace(StandardDeviation, 3, false)}`,
    //     style: defaultStyle,
    //   },
    // ],
    [
      { text: 'Exam Score', style: { ...defaultStyle, bold: true, fontSize: 13 } },
      {
        text: roundToDecimalPlace(parseFloat(ExamScore), 3, false),
        style: { ...defaultStyle, bold: true, fontSize: 13 },
      },
      { text: roundToDecimalPlace(Average, 3, false), style: { ...defaultStyle, fontSize: 13, bold: true } },
    ],
    [
      { text: 'Passing Score', style: { ...defaultStyle, bold: true }, fillColor: '#e8e8e8' },
      { text: PassingScore, style: defaultStyle, fillColor: '#e8e8e8' },
      { text: PassingScore, style: defaultStyle, fillColor: '#e8e8e8' },
    ],
    [
      { text: 'Result', style: { ...defaultStyle, bold: true, fontSize: 13 } },
      { text: Result, style: { ...defaultStyle, fontSize: 13 } },
      {
        text: [
          { text: 'Pass: ', bold: true },
          `${roundToDecimalPlace(parseFloat(PassRate) * 100, 3, false)}%\n`,
          { text: 'Fail: ', bold: true },
          `${roundToDecimalPlace(parseFloat(FailRate) * 100, 3, false)}%`,
        ],
        style: defaultStyle,
      },
    ],
    [
      { text: 'Rank', style: { ...defaultStyle, bold: true }, fillColor: '#e8e8e8' },
      { text: '', style: defaultStyle, fillColor: '#e8e8e8' },
      { text: `${Rank} of ${ExamineeCount || 0}`, style: defaultStyle, fillColor: '#e8e8e8' },
    ],
  ];

  if (compareWithGroups) {
    tableBody.forEach((row, i) => {
      row.push(groupColumnRows[i]);
    });
  }

  return {
    table: {
      widths: widths,
      headerRows: 1,
      body: tableBody,
      dontBreakRows: true,
    },
    layout: {
      hLineWidth: (i, node) => {
        return 1;
        if (i == node.table.body.length) {
          return 1;
        } else {
          return 0;
        }
      },
      vLineWidth: (i, node) => {
        return 0;
      },
      hLineColor: (i, node) => {
        return '#aaa';
      },
      paddingTop: (i, node) => {
        return 4;
      },
      paddingBottom: (i, node) => {
        return 4;
      },
    },
    margin: [0, 0, 0, 20],
  };
};

const renderScaleDataCard = (options) => {
  const { scaleData } = options;
  const { DefaultScale } = scaleData || {};
  const tableRows = [];
  const tableHeaders = [];
  const tableBody = [];

  DefaultScale.forEach((scale, i) => {
    const { DisplayValue = '', Label = '' } = scale;
    tableHeaders.push({
      text: DisplayValue,
      style: { bold: true, fontSize: 11 },
      alignment: 'center',
      fillColor: '#aaa',
    });
    tableBody.push({ text: Label, style: { fontSize: 9 }, alignment: 'center' });
  });

  tableRows.push(tableHeaders);
  tableRows.push(tableBody);

  const widths = [];
  let count = DefaultScale.length;
  const widthForEachColumn = count <= 5 ? (6.7 / count) * 72 : (6.05 / count) * 72;

  while (count > 0) {
    widths.push(widthForEachColumn);
    count--;
  }

  return {
    table: {
      widths,
      headerRows: 1,
      body: tableRows,
      dontBreakRows: true,
    },
    layout: {
      hLineWidth: (i, node) => {
        return 1;
      },
      vLineWidth: (i, node) => {
        return 1;
      },
      hLineColor: (i, node) => {
        return '#7a7878';
      },
      vLineColor: (i, node) => {
        if (i == 0) {
          return null;
        } else {
          return '#7a7878';
        }
      },

      paddingTop: (i, node) => {
        return 4;
      },
      paddingBottom: (i, node) => {
        return 4;
      },
    },
    margin: [0, 0, 0, 20],
    width: 6.7 * 72,
  };
};

const renderQuestionScoreCard = (options) => {
  const { candidate, candidateIndex, candidateCount, rawData, department } = options;
  const { EnableGroups, ProgramType } = department;
  const { EvaluationAnswers = [], fk_Group } = candidate;

  const { MedianScores = {}, GroupData = {} } = rawData || {};
  const { GroupMedianScores = {}, GroupAverages = {} } = GroupData[fk_Group] || {};

  const pageHeaderData = { candidate };
  const tableRows = [];
  const GroupName = GroupAverages.GroupName || '';

  EvaluationAnswers.forEach((answer, i) => {
    const { QuestionText = '', ExamineeScore = 0, pk_EvaluationQuestion, AnswerType = 1, Comment } = answer;
    const MedianExamineeScore = roundToDecimalPlace(MedianScores[pk_EvaluationQuestion], 3, false);
    let answerText = null;

    const compareWithGroups =
      EnableGroups &&
      ProgramType === 'Mock Oral Board' &&
      fk_Group != null &&
      GroupMedianScores[pk_EvaluationQuestion] != null;

    const widths = compareWithGroups ? [100, 150, 200] : [100, 150];
    const columns = [
      { text: `Examinee Score\n${ExamineeScore}`, style: { fontSize: 10 }, width: 100, pageHeaderData },
      {
        text: `Median Score for All Examinees\n${MedianExamineeScore}`,
        style: { fontSize: 10 },
        width: 150,
        pageHeaderData,
      },
    ];

    const medianGroupScore = GroupMedianScores[pk_EvaluationQuestion] || '--';

    if (compareWithGroups) {
      columns.push({
        text: `Median Score for ${GroupName}\n${medianGroupScore || '--'}`,
        style: { fontSize: 10 },
        width: 200,
        pageHeaderData,
      });
    }

    if (AnswerType == 1) {
      answerText = {
        widths: widths,
        columns: columns,
        // width: 7 * 72,
        margin: [10, 0, 0, 20],
      };
    } else {
      answerText = {
        text: `${Comment || '----'}`,
        style: { fontSize: 10 },
        pageHeaderData,
      };
    }

    tableRows.push([
      [
        {
          text: QuestionText,
          style: { fontSize: 12, bold: true },
          margin: [0, 0, 0, 5],
          pageHeaderData,
        },
        answerText,
      ],
    ]);
  });

  if (tableRows.length <= 0) {
    tableRows.push([{ text: 'No Evaluations found.', style: { fontSize: 10 } }]);
  }

  const toReturn = {
    table: {
      widths: [7 * 72],
      body: tableRows,
      dontBreakRows: true,
    },
    layout: {
      hLineWidth: (i, node) => {
        if (i == node.table.body.length) {
          return 1;
        } else {
          return 0;
        }
      },
      vLineWidth: (i, node) => {
        return 0;
      },
      hLineColor: (i, node) => {
        return '#aaa';
      },
      paddingTop: (i, node) => {
        return 4;
      },
      paddingBottom: (i, node) => {
        return 4;
      },
      marginBottom: (i, node) => {
        return 10;
      },
    },
    width: 7 * 72,
  };

  if (candidateIndex < candidateCount - 1) {
    toReturn.pageBreak = 'after';
  }

  return toReturn;
};

const createPDFProper = (data) => {
  const { rawData, candidates = [], department, departmentLogo, scaleData } = data;
  const { Candidates = [] } = rawData;

  const rows = [];
  const renderCard = (options) => {
    const { candidate } = options;
    const cardRows = [];

    cardRows.push([renderCandidatedetailsCard({ ...options, department, departmentLogo })]);

    cardRows.push([renderScoreSummaryCard({ ...options, department, candidateCount: Candidates.length })]);

    cardRows.push([renderScaleDataCard({ ...options })]);

    cardRows.push([renderQuestionScoreCard({ ...options, department, candidateCount: Candidates.length })]);

    return cardRows;
  };

  Candidates.forEach((candidate, candidateIndex) => {
    rows.push([renderCard({ rawData, scaleData, candidate, department, departmentLogo, candidateIndex })]);
  });

  let pdfObject = [
    {
      table: { heights: 'auto', widths: [7 * 72], body: rows },
      layout: 'customTable',
      width: 7 * 72,
    },
  ];

  if (rows.length <= 0) {
    Swal.fire('Error', 'There are no Candidates for this Interview Date. There is nothing to print!', 'error');
    return;
  }
  // imageToBase64(bgGuide)
  //   .then((bgBase64) => {
  // console.log('bgBase64: ', `data:image/png;base64,${bgBase64}`);
  const docDefinition = {
    // background: { image: `data:image/png;base64,${bgBase64}`, width: 612 },
    info: {
      title: `Mock Oral Report${
        Candidates && Candidates.length == 1
          ? ` - ${Candidates[0].LastName || ''}${
              Candidates[0].LastName && Candidates[0].FirstName ? ', ' : ''
            }${Candidates[0].FirstName || ''}`
          : ''
      }`,
    },
    pageSize: 'LETTER',
    pageOrientation: 'portrait',
    content: pdfObject,
    defaultStyle: {
      fontSize: 15,
    },
    footer: (currentPage) => {
      const footerData = getDataForHeaderFooter(docDefinition, currentPage);
      const { candidatePages, candidateData } = footerData;
      const { LastName, FirstName } = candidateData || {};

      return [
        {
          text: `${LastName || ''}${LastName ? ', ' : ''} ${FirstName || ''}`,
          style: { fontSize: 8, italics: true },
          alignment: 'center',
          width: '100%',
          margin: [0, 0, 0, 0],
        },
        {
          text: `www.RezRATE.com\n${currentPage}`,
          style: { fontSize: 8 },
          alignment: 'center',
          width: '100%',
          margin: [0, 0, 0, 0],
        },
      ];

      // const { candidatePages, candidateData } = footerData;
      // if (candidateData) {
      //   const candidatePagesActual = candidatePages[candidateData.pk_Candidate];
      //   const candidatePagesCount = candidatePagesActual.length;
      //   const currentCandidatePage = candidatePagesActual.indexOf(currentPage) + 1;
      //   return [
      //     {
      //       text: `${candidateData.LastName}, ${candidateData.FirstName}`,
      //       alignment: 'center',
      //       width: '100%',
      //       fontSize: 8,
      //     },
      //     {
      //       text: `${currentCandidatePage} of ${candidatePagesCount}`,
      //       alignment: 'center',
      //       width: '100%',
      //       fontSize: 8,
      //     },
      //   ];
      // }
    },
    // header: (currentPage) => {
    //   const headerData = getDataForHeaderFooter(docDefinition, currentPage);

    //   const { candidatePages, candidateData } = headerData;
    //   const { LastName, FirstName } = candidateData || {};
    //   return {
    //     text: `${LastName || ''}${LastName ? ', ' : ''} ${FirstName || ''}`,
    //     style: { fontSize: 8, italics: true },
    //     alignment: 'left',
    //     width: '100%',
    //     margin: [0, 0, 0, 0],
    //   };
    // },
    pageMargins: [0.75 * 72, 0.75 * 72, 0.75 * 72, 30],
  };

  pdfMake.createPdf(docDefinition, tableLayouts).open({}, window.open('', '_blank'));
  // })
  // .catch((err) => {});
};

export const createPDFForMockOrals = ({ rawData, scaleData, department, candidates = [], callback }) => {
  const { Candidates = [] } = rawData;

  const candidatesCopy = clone(Candidates).filter((candidate) => {
    return candidate.IsScheduled;
  });

  if (Candidates.length <= 0) {
    Swal.fire('Error', 'There are no selected scheduled candidates. There is nothing to print!', 'error');
    if (callback) {
      callback();
    }
  }

  candidates.forEach((candidate) => {
    const { DateOfInterview, InterviewStartTime, Label } = candidate;
    const sessionDate = `${moment.tz(`${DateOfInterview} ${InterviewStartTime}`, null).format('MMM DD, YYYY')} ${
      Label ? `(${Label})` : ''
    }`;

    candidateSessions[candidate.pk_Candidate] = sessionDate;
  });
  const startProper = () => {
    if (department.DepartmentLogo) {
      imageToBase64(department.DepartmentLogo)
        .then((res) => {
          if (callback) {
            callback();
          }
          createPDFProper({ rawData, scaleData, candidates: candidatesCopy, department, departmentLogo: res });
        })
        .catch((err) => {
          if (callback) {
            callback();
          }

          createPDFProper({ rawData, scaleData, candidates: candidatesCopy, department });
        });
    } else {
      if (callback) {
        callback();
      }
      createPDFProper({ rawData, scaleData, candidates: candidatesCopy, department });
    }
  };

  Promise.all([
    ...candidates.map((c) => {
      return new Promise((resolve, reject) => {
        if (c.PhotoUrl) {
          imageToBase64(c.PhotoUrl)
            .then((base64Img) => {
              candidateImages[c.pk_Candidate] = base64Img;
              // c.userPhotoBase64 = base64Img;
              resolve(c);
            })
            .catch((err) => {
              // console.log('err: ', err);
              resolve({ error: true, ...c });
            });
        } else {
          resolve(c);
        }
      });
    }),
  ])
    .then(() => {
      imageToBase64(rezRateLogo)
        .then((res) => {
          rezRateLogoBase64 = res;
          startProper();
        })
        .catch(() => {
          startProper();
        });
    })
    .catch((err) => {
      console.log('err: ', err);
    });
};
