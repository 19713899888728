import { faEnvelope, faEnvelopeOpen } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import React from 'react';

import style from './SentItem.style';

const SentItem = ({ sentItem, selectedSentItem, setSelectedSentItem, onClick, columnSentItems }) => {
  const { isOpened, text, Subject, DateSent, FirstName, LastName, index } = sentItem;
  let backgroundColor = index % 2 == 0 ? '#c7cbd1' : null;
  if (selectedSentItem && selectedSentItem.pk_CommunicationHistory == sentItem.pk_CommunicationHistory) {
    backgroundColor = '#d9f5ff';
  }
  return (
    <div
      style={{ ...style.mainContainer, backgroundColor }}
      onClick={() => {
        setSelectedSentItem(sentItem);
        if (onClick) {
          onClick();
        }
      }}
    >
      <div style={{ ...style.simpleColumn, width: 100, minWidth: 80 }}>
        <FontAwesomeIcon icon={isOpened ? faEnvelopeOpen : faEnvelope} style={{ fontSize: 60 }} />
      </div>
      <div style={{ ...style.simpleColumn, width: '100%' }}>
        {columnSentItems ? (
          <div style={{ ...style.simpleRow, height: '100%', marginLeft: 10 }}>
            <div
              style={{
                ...style.simpleColumn,
                width: '30%',
                height: '100%',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <div style={style.dateLabel}>{moment(DateSent).format('MM/DD/YYYY hh:mm A')}</div>
            </div>
            <div
              style={{
                ...style.simpleColumn,
                width: '30%',
                height: '100%',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <div style={style.minorText}>{Subject || '(No Subject)'}</div>
            </div>
            <div
              style={{
                ...style.simpleColumn,
                width: '30%',
                height: '100%',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <div style={style.minorText}>{`${LastName}, ${FirstName}`}</div>
            </div>
          </div>
        ) : (
          <>
            <div style={style.dateLabel}>{moment(DateSent).format('MM/DD/YYYY hh:mm A')}</div>
            <div style={style.minorText}>{Subject || '(No Subject)'}</div>
            <div style={style.minorText}>{`${LastName}, ${FirstName}`}</div>
          </>
        )}
      </div>
    </div>
  );
};

export default SentItem;
