import { faBellSlash, faHandPaper, faTimesCircle } from '@fortawesome/free-regular-svg-icons';
import { faCalendarAlt, faDownload, faExclamationCircle, faSortAlphaDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { Button, ButtonGroup } from 'reactstrap';
import moment from 'moment';

import style from './style.js';
import { CandidateStatusIcons } from 'enums.js';

const FilterPopover = ({ setShowFilterPopover, setFilterBy, filterBy, interviewDates }) => {
  const {
    pendingInvite,
    invited,
    waitlisted,
    confirmed,
    unconfirmed,
    rejected,
    declined,
    scheduled,
    unscheduled,
    markedForInvite,
    unresponsive,
    notSentSchedule,
  } = CandidateStatusIcons;

  const buttons = [
    {
      iconData: {
        icon: faCalendarAlt,
        color: null,
      },
      label: 'Show All (Interview Date)',
      filterBy: 'All by Interview Date',
    },
    {
      iconData: {
        icon: faSortAlphaDown,
        color: null,
      },
      label: 'Show All (Alphabetical)',
      filterBy: 'All by Name',
    },
    {
      iconData: invited,
      label: 'Show All (Invitation Sent)',
      filterBy: 'All Invited',
    },
    {
      iconData: markedForInvite,
      label: 'Show All (Marked for Invite)',
      filterBy: 'All Marked for Invite',
    },
    {
      iconData: pendingInvite,
      label: 'Show All (Pending Invitation)',
      filterBy: 'All Pending Invite',
    },
    {
      iconData: {
        icon: faDownload,
        color: null,
      },
      label: 'Show All (Import Date)',
      filterBy: 'All by Import Date',
    },
    {
      iconData: scheduled,
      label: 'Show Registered Candidates',
      filterBy: 'Scheduled',
    },
    {
      iconData: unscheduled,
      label: 'Show Unregistered Candidates',
      filterBy: 'Unscheduled',
    },
    {
      iconData: waitlisted,
      label: 'Show Waitlisted',
      filterBy: 'Waitlisted',
    },
    {
      iconData: unresponsive,
      label: 'Show Unresponsive Candidates',
      filterBy: 'Unresponsive',
    },
    {
      iconData: notSentSchedule,
      label: 'Show All (Not Sent Schedule)',
      filterBy: 'All Not Sent Schedule',
    },
    {
      iconData: declined,
      label: 'Show Declined Candidates',
      filterBy: 'Declined',
    },
  ];

  return (
    <div>
      <div style={style.scheduleDateContainer}>
        {interviewDates && interviewDates.length > 0 ? (
          interviewDates.map((interviewDate, i) => {
            const dateToDisplay = moment(interviewDate.DateOfInterview).format('dddd, MMM. DD, YYYY');

            const backgroundColor =
              filterBy.pk_InterviewDate == interviewDate.pk_InterviewDate ? '#d9f5ff' : i % 2 == 0 ? null : '#c7cbd1';
            return (
              <div
                style={{ ...style.dateListItem, backgroundColor }}
                onClick={() => {
                  setFilterBy(interviewDate);
                }}
              >
                {`${dateToDisplay}   ${interviewDate.Label ? `(${interviewDate.Label})` : ''}`}
              </div>
            );
          })
        ) : (
          <div style={{ textAlign: 'center' }}>'No Schedule Dates'</div>
        )}
      </div>

      {buttons.map((button, i) => {
        return (
          <div style={style.simpleRow}>
            <Button
              color="primary"
              outline
              style={{
                ...style.filterButton,
                backgroundColor: filterBy == button.filterBy ? '#d9f5ff' : null,
                color: null,
              }}
              onClick={(e) => {
                e.stopPropagation();
                setFilterBy(button.filterBy);
                setShowFilterPopover(false);
              }}
            >
              <div style={style.spacedBetweenRow}>
                <div>
                  <FontAwesomeIcon icon={button.iconData.icon} color={button.iconData.color} />
                </div>
                <div style={style.buttonLabel}>{button.label}</div>
              </div>
            </Button>
          </div>
        );
      })}

      {
        // Keep below for reference for a bit. DELETE later though, cause goddamn that's a lotta lines my doods. -April 6, 2023
      }
      {/* <div style={style.simpleRow}>
        <Button
          color="primary"
          outline
          style={{
            ...style.filterButton,
            backgroundColor: filterBy == 'All by Interview Date' ? '#d9f5ff' : null,
            color: null,
          }}
          onClick={(e) => {
            e.stopPropagation();
            setFilterBy('All by Interview Date');
            setShowFilterPopover(false);
          }}
        >
          <div style={style.spacedBetweenRow}>
            <div>
              <FontAwesomeIcon icon={faCalendarAlt} />
            </div>
            <div style={style.buttonLabel}>Show All (Interview Date)</div>
          </div>
        </Button>
      </div>
      <div style={style.simpleRow}>
        <Button
          color="primary"
          outline
          style={{
            ...style.filterButton,
            backgroundColor: filterBy == 'All by Name' ? '#d9f5ff' : null,
            color: null,
          }}
          onClick={(e) => {
            e.stopPropagation();
            setFilterBy('All by Name');
            setShowFilterPopover(false);
          }}
        >
          <div style={style.spacedBetweenRow}>
            <div>
              <FontAwesomeIcon icon={faSortAlphaDown} />
            </div>
            <div style={style.buttonLabel}>Show All (Alphabetical)</div>
          </div>
        </Button>
      </div>
      <div style={style.simpleRow}>
        <Button
          color="primary"
          outline
          style={{
            ...style.filterButton,
            backgroundColor: filterBy == 'All Invited' ? '#d9f5ff' : null,
            color: null,
          }}
          onClick={(e) => {
            e.stopPropagation();
            setFilterBy('All Invited');
            setShowFilterPopover(false);
          }}
        >
          <div style={style.spacedBetweenRow}>
            <div>
              <FontAwesomeIcon icon={invited.icon} style={{ color: invited.color }} />
            </div>
            <div style={style.buttonLabel}>Show All (Invitation Sent)</div>
          </div>
        </Button>
      </div>
      <div style={style.simpleRow}>
        <Button
          color="primary"
          outline
          style={{
            ...style.filterButton,
            backgroundColor: filterBy == 'All Marked for Invite' ? '#d9f5ff' : null,
            color: null,
          }}
          onClick={(e) => {
            e.stopPropagation();
            setFilterBy('All Marked for Invite');
            setShowFilterPopover(false);
          }}
        >
          <div style={style.spacedBetweenRow}>
            <div>
              <FontAwesomeIcon icon={markedForInvite.icon} style={{ color: markedForInvite.color }} />
            </div>
            <div style={style.buttonLabel}>Show All (Marked for Invite)</div>
          </div>
        </Button>
      </div>
      <div style={style.simpleRow}>
        <Button
          color="primary"
          outline
          style={{
            ...style.filterButton,
            backgroundColor: filterBy == 'All Pending Invite' ? '#d9f5ff' : null,
            color: null,
          }}
          onClick={(e) => {
            e.stopPropagation();
            setFilterBy('All Pending Invite');
            setShowFilterPopover(false);
          }}
        >
          <div style={style.spacedBetweenRow}>
            <div>
              <FontAwesomeIcon icon={pendingInvite.icon} style={{ color: pendingInvite.color }} />
            </div>
            <div style={style.buttonLabel}>Show All (Pending Invitation)</div>
          </div>
        </Button>
      </div>
      <div style={style.simpleRow}>
        <Button
          color="primary"
          outline
          style={{
            ...style.filterButton,
            backgroundColor: filterBy == 'All by Import Date' ? '#d9f5ff' : null,
            color: null,
          }}
          onClick={(e) => {
            e.stopPropagation();
            setFilterBy('All by Import Date');
            setShowFilterPopover(false);
          }}
        >
          <div style={style.spacedBetweenRow}>
            <div>
              <FontAwesomeIcon icon={faDownload} />
            </div>
            <div style={style.buttonLabel}> Show All (Import Date)</div>
          </div>
        </Button>
      </div>
      <div style={style.simpleRow}>
        <Button
          color="primary"
          outline
          style={{
            ...style.filterButton,
            backgroundColor: filterBy == 'Scheduled' ? '#d9f5ff' : null,
            color: null, //filterBy == 'Scheduled' ? 'white' : null,
          }}
          onClick={(e) => {
            e.stopPropagation();
            setFilterBy('Scheduled');
            setShowFilterPopover(false);
          }}
        >
          <div style={style.spacedBetweenRow}>
            <div>
              <FontAwesomeIcon icon={scheduled.icon} style={{ color: scheduled.color }} />
            </div>
            <div style={style.buttonLabel}>Show Registered Candidates</div>
          </div>
        </Button>
      </div>
      <div style={style.simpleRow}>
        <Button
          color="primary"
          outline
          style={{
            ...style.filterButton,
            backgroundColor: filterBy == 'Unscheduled' ? '#d9f5ff' : null,
            color: null,
          }}
          onClick={(e) => {
            e.stopPropagation();
            setFilterBy('Unscheduled');
            setShowFilterPopover(false);
          }}
        >
          <div style={style.spacedBetweenRow}>
            <div>
              <FontAwesomeIcon icon={unscheduled.icon} style={{ color: unscheduled.color }} />
            </div>
            <div style={style.buttonLabel}> Show Unregistered Candidates</div>
          </div>
        </Button>
      </div>
      <div style={style.simpleRow}>
        <Button
          color="primary"
          outline
          style={{
            ...style.filterButton,
            backgroundColor: filterBy == 'Waitlisted' ? '#d9f5ff' : null,
            color: null,
          }}
          onClick={(e) => {
            e.stopPropagation();
            setFilterBy('Waitlisted');
            setShowFilterPopover(false);
          }}
        >
          <div style={style.spacedBetweenRow}>
            <div>
              <FontAwesomeIcon icon={waitlisted.icon} style={{ color: waitlisted.color }} />
            </div>
            <div style={style.buttonLabel}>Show Waitlisted Candidates</div>
          </div>
        </Button>
      </div>
      <div style={style.simpleRow}>
        <Button
          color="primary"
          outline
          style={{
            ...style.filterButton,
            backgroundColor: filterBy == 'Unresponsive' ? '#d9f5ff' : null,
            color: null,
          }}
          onClick={(e) => {
            e.stopPropagation();
            setFilterBy('Unresponsive');
            setShowFilterPopover(false);
          }}
        >
          <div style={style.spacedBetweenRow}>
            <div>
              <FontAwesomeIcon icon={unresponsive.icon} style={{ color: unresponsive.color }} />
            </div>
            <div style={style.buttonLabel}>Show Unresponsive Candidates</div>
          </div>
        </Button>
      </div>
      <div style={style.simpleRow}>
        <Button
          color="primary"
          outline
          style={{
            ...style.filterButton,
            backgroundColor: filterBy == 'Declined' ? '#d9f5ff' : null,
            color: null,
          }}
          onClick={(e) => {
            e.stopPropagation();
            setFilterBy('Declined');
            setShowFilterPopover(false);
          }}
        >
          <div style={style.spacedBetweenRow}>
            <div>
              <FontAwesomeIcon icon={declined.icon} style={{ color: declined.color }} />
            </div>
            <div style={style.buttonLabel}>Show Declined Candidates</div>
          </div>
        </Button>
      </div> */}
    </div>
  );
};

export default FilterPopover;
