const style = {
  cellStyle: { display: 'flex', justifyContent: 'center', verticalAlign: 'middle' },
  simpleRow: {
    display: 'flex',
    flexDirection: 'row',
  },
  simpleColumn: {
    display: 'flex',
    flexDirection: 'column',
  },
  headerStyle: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    textAlign: 'center',
    verticalAlign: 'middle',
  },
  centeredText: {
    display: 'flex',
    flexDirection: 'column',
    fontWeight: 'bold',
    textAlign: 'center',
    width: '100%',
  },
};
export default style;
