import React, { useState, useEffect } from 'react';
import { Input, Label, Button } from 'reactstrap';
import { clone } from '../../../../../Services/schedule';

import style from './style';

const CustomDurationPopover = ({
  row,
  scheduleData,
  addRowCustomEvent,
  hideHeaderPopover,
  setShowCustomDurationPopover,
  fireConfirmationForEditingPastSchedule,
  dateIsInThePast,
  willEditThePast,
  showPastScheduleWarning,
}) => {
  const [event, setEvent] = useState({});
  const { metaData = {} } = scheduleData || {};

  useEffect(() => {
    reset(row);
  }, [JSON.stringify(row)]);

  const reset = (row) => {
    if (row) {
      const {
        CustomDurationInMinutes,
        CustomMeetingDescription,
        CustomMeetingTitle,
        CustomMeetingUrl,
        CustomPassingDurationInMinutes,
        VirtualRoomType,
      } = row;

      const duration = CustomDurationInMinutes != null ? CustomDurationInMinutes : metaData.StandardDurationInMinutes;
      const passingDuration =
        CustomPassingDurationInMinutes != null
          ? CustomPassingDurationInMinutes
          : metaData.StandardPassingDurationInMinutes;

      setEvent({
        CustomDurationInMinutes: duration,
        CustomMeetingDescription,
        CustomMeetingTitle,
        CustomMeetingUrl,
        CustomPassingDurationInMinutes: passingDuration,
        VirtualRoomType,
        fk_MeetingRoom: undefined,
        pk_Timeslot: row.pk_Timeslot,
      });
    }
  };

  return (
    <div
      style={style.mainBody}
      onClick={async (e) => {
        if (dateIsInThePast && !willEditThePast) {
          const continueProcess = await fireConfirmationForEditingPastSchedule();
          if (!continueProcess) {
            return;
          }
        }
        e.stopPropagation();
      }}
    >
      <div>Default duration is {metaData.StandardDurationInMinutes || 8} minutes.</div>
      <div style={style.simpleRow}>
        <div style={style.simpleColumn}>
          <Label style={style.inputLabel} for="durationMinutes">
            Duration in Minutes:
          </Label>
          <Input
            type="number"
            style={style.inputFields}
            name="durationMinutes"
            id="durationMinutes"
            value={event.CustomDurationInMinutes}
            placeholder=""
            defaultValue={metaData.StandardDurationInMinutes}
            onChange={(e) => {
              const newEvent = clone(event);
              let newValue = e.target.value;

              if (newValue < 1) {
                newValue = 1;
              }

              newEvent.CustomDurationInMinutes = newValue;
              setEvent(newEvent);
            }}
          />
        </div>
      </div>
      <div style={style.simpleRow}>
        <div style={style.simpleColumn}>
          <Label style={style.inputLabel} for="passingMinutes">
            Passing Period in Minutes:
          </Label>
          <Input
            type="number"
            style={style.inputFields}
            name="passingMinutes"
            id="passingMinutes"
            value={event.CustomPassingDurationInMinutes}
            placeholder=""
            defaultValue={metaData.StandardPassingDurationInMinutes}
            onChange={(e) => {
              const newEvent = clone(event);
              let newValue = e.target.value;

              if (newValue < 0) {
                newValue = 0;
              }

              newEvent.CustomPassingDurationInMinutes = newValue;
              setEvent(newEvent);
            }}
          />
        </div>
      </div>
      <div style={style.spacedBetweenRow}>
        <Button
          color="danger"
          style={{ ...style.genButton, backgroundColor: null }}
          onClick={async () => {
            setShowCustomDurationPopover(false);
          }}
        >
          <div style={style.buttonLabel}>Cancel</div>
        </Button>
        <Button
          style={style.genButton}
          onClick={async () => {
            if (dateIsInThePast && !willEditThePast) {
              const continueProcess = await fireConfirmationForEditingPastSchedule();
              if (!continueProcess) {
                return;
              }
            }
            const newEvent = clone(event);
            // newEvent.CustomDurationInMinutes = metaData.StandardDurationInMinutes;
            // newEvent.CustomPassingDurationInMinutes = metaData.StandardPassingDurationInMinutes;

            newEvent.CustomDurationInMinutes = null;
            newEvent.CustomPassingDurationInMinutes = null;

            addRowCustomEvent(newEvent);
            setEvent(newEvent);
          }}
        >
          <div style={style.buttonLabel}>Reset</div>
        </Button>
        <Button
          style={style.genButton}
          onClick={async () => {
            if (dateIsInThePast && !willEditThePast) {
              const continueProcess = await fireConfirmationForEditingPastSchedule();
              if (!continueProcess) {
                return;
              }
            }
            const newEvent = clone(event);
            if (newEvent.CustomDurationInMinutes == metaData.StandardDurationInMinutes) {
              newEvent.CustomDurationInMinutes = null;
            }

            if (newEvent.CustomPassingDurationInMinutes == metaData.StandardPassingDurationInMinutes) {
              newEvent.CustomPassingDurationInMinutes = null;
            }
            addRowCustomEvent(newEvent);
            hideHeaderPopover();
          }}
        >
          <div style={style.buttonLabel}>Set</div>
        </Button>
      </div>
    </div>
  );
};

export default CustomDurationPopover;
