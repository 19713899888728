import { faExternalLinkAlt, faEye, faEyeSlash, faLock, faUnlock } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import React, { useState, useEffect, useContext } from 'react';
import {
  Button,
  FormFeedback,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  Label,
  UncontrolledTooltip,
} from 'reactstrap';
import { useAuth0 } from '../../../../../../auth0/reactAuth0Spa';
import {
  checkIfZoomURLAndIfHasPassword,
  formatBearerToken,
  isValidURL,
  swalErrorMessage,
  urlWithHttpProtocol,
} from '../../../../../../Common.functions';
import { putData } from '../../../../../../Services/dataApi';
import { DepartmentContext } from '../../../../../../DepartmentWrapper';
import { useAlert } from 'react-alert';
import style from './style';

const SettingsDropDown = ({ dateData, onUpdate, setParentChanges }) => {
  const [isUpdating, setIsUpdating] = useState(false);
  const [MainRoomURL, setMainRoomURL] = useState('');
  const [MainRoomURLDetails, setMainRoomURLDetails] = useState('');
  const [hasChange, setHasChange] = useState(false);

  const alert = useAlert();

  const { getTokenSilently } = useAuth0();

  const dContext = useContext(DepartmentContext);

  const zoomDetails = checkIfZoomURLAndIfHasPassword(MainRoomURL);
  const { isZoomURL, hasPassword } = zoomDetails;

  useEffect(() => {
    if (dateData) {
      setMainRoomURL(dateData.MainRoomURL || '');
      setMainRoomURLDetails(dateData.MainRoomURLDetails || '');
    }
  }, [JSON.stringify(dateData)]);

  useEffect(() => {
    checkChanges();
  }, [MainRoomURL, MainRoomURLDetails]);

  useEffect(() => {
    setParentChanges(hasChange);
  }, [hasChange]);

  const updateEntry = (entry) => {
    setIsUpdating(true);
    getTokenSilently()
      .then((token) => {
        putData(
          'department/interviewDate/putEntry',
          {
            pk_Department: dContext.department.pk_Department,
            pk_Season: dContext.season.pk_Season,
            pk_InterviewDate: dateData.pk_InterviewDate,
          },
          { entry },
          formatBearerToken(token),
        )
          .then((result) => {
            setIsUpdating(false);
            if (result.data.error) {
              swalErrorMessage();
              return;
            }
            alert.success('Link settings Saved!');
            if (onUpdate) {
              onUpdate();
            }
          })
          .catch((err) => {
            setIsUpdating(false);
            swalErrorMessage();
          });
      })
      .catch((err) => {
        swalErrorMessage();
        return false;
      });
  };

  const checkChanges = () => {
    const oldURL = dateData.MainRoomURL || '';
    const oldDetails = dateData.MainRoomURLDetails || '';
    let newHasChange = false;

    if (MainRoomURL !== oldURL) {
      newHasChange = true;
    }

    if (MainRoomURLDetails !== oldDetails) {
      newHasChange = true;
    }

    setHasChange(newHasChange);
  };

  const saveFields = () => {
    let mainRoomUrl = '';
    let mainRoomUrlDetails = '';
    if (MainRoomURL.trim() !== '') {
      mainRoomUrl = urlWithHttpProtocol(MainRoomURL);
    }

    if (MainRoomURLDetails.trim() !== '') {
      mainRoomUrlDetails = MainRoomURLDetails;
    }
    const entry = {
      MainRoomURL: mainRoomUrl,
      MainRoomURLDetails: mainRoomUrlDetails,
    };
    setHasChange(false);
    updateEntry(entry);
  };

  return (
    <div>
      <div>
        <FormGroup>
          <Label for="meetingLink">All Day Meeting Link (Used as break room or conference room)</Label>
          <InputGroup>
            <Input
              style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}
              invalid={(MainRoomURL && !isValidURL(MainRoomURL)) || (isZoomURL && !hasPassword)}
              id="meetingLink"
              type="text"
              placeholder="Enter URL here. . ."
              value={MainRoomURL}
              onChange={(e) => {
                // setHasChange(true);
                setMainRoomURL(e.target.value);
              }}
            />
            <FormFeedback invalid={!MainRoomURL || !isValidURL(MainRoomURL)}>
              {!isValidURL(MainRoomURL) ? 'Unusual URL found. Unusual URL detected. Please double check.' : ''}
            </FormFeedback>
            <FormFeedback invalid={(MainRoomURL && !isValidURL(MainRoomURL)) || (isZoomURL && !hasPassword)}>
              {isZoomURL && !hasPassword
                ? 'Zoom URL detected, but without a password. We recommend utilizing embedded passwords for Zoom meetings.'
                : ''}{' '}
              {isZoomURL && !hasPassword ? (
                <a href="https://www.rezrate.com/support/zoom-password/" target="_blank">
                  {' '}
                  Get Zoom Support
                </a>
              ) : (
                ''
              )}
            </FormFeedback>
          </InputGroup>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: 10,
            }}
          >
            <Button
              id={`testButton_${dateData.pk_InterviewDate}`}
              disabled={!MainRoomURL}
              style={style.testButton}
              onClick={() => {
                const urlToOpen = urlWithHttpProtocol(MainRoomURL);
                window.open(urlToOpen);
              }}
            >
              <FontAwesomeIcon icon={faExternalLinkAlt} style={{ marginRight: 10 }} /> Preview
            </Button>
          </div>
        </FormGroup>
      </div>

      <div>
        <Label for="helpText">All Day Meeting Help Text (This will appear on the join meeting card)</Label>
        <Input
          id="helpText"
          type="textarea"
          rows={5}
          placeholder="Optional description could include information such as password or dial in number"
          value={MainRoomURLDetails}
          onChange={(e) => {
            // setHasChange(true);
            setMainRoomURLDetails(e.target.value);
          }}
        />
      </div>

      <div
        style={{ ...style.simpleRow, justifyContent: 'center', alignItems: 'center', marginTop: 20 }}
        onClick={() => {
          saveFields();
        }}
      >
        <Button disabled={!hasChange} style={{ width: 200 }} color="success">
          Save
        </Button>
      </div>
      {/* <div style={style.btnContainer}>
        <Button
          disabled={isUpdating || isInPast || CannotBeUnlocked}
          color={isInPast || CannotBeUnlocked ? 'secondary' : EnableHiddenStatus ? 'danger' : 'success'}
          style={style.bottomButton}
          onClick={() => {
            updateEntry({ EnableHiddenStatus: !EnableHiddenStatus }, pk_InterviewDate);
          }}
        >
          <div style={{ ...style.spacedBetweenRow, width: '100%' }}>
            <FontAwesomeIcon icon={EnableHiddenStatus || isInPast ? faEyeSlash : faEye} style={style.buttonIcon} />
            <div style={style.buttonLabel}>Date is {`${EnableHiddenStatus || isInPast ? 'Hidden' : 'Visible'}`}</div>
          </div>
        </Button>
        <Button
          disabled={isUpdating || CannotBeUnlocked}
          color={
            isInPast || CannotBeUnlocked || EnableLockedStatus ? 'secondary' : EnableLockedStatus ? 'danger' : 'success'
          }
          style={style.bottomButton}
          onClick={() => {
            updateEntry({ EnableLockedStatus: !EnableLockedStatus }, pk_InterviewDate);
          }}
        >
          <div style={{ ...style.spacedBetweenRow, width: '100%' }}>
            <FontAwesomeIcon icon={EnableLockedStatus || isInPast ? faLock : faUnlock} style={style.buttonIcon} />
            <div style={style.buttonLabel}>Date is {`${EnableLockedStatus || isInPast ? 'Locked' : 'Unlocked'} `}</div>
          </div>
        </Button>
      </div> */}
    </div>
  );
};

export default SettingsDropDown;
