import { faPortrait } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import style from './AdminRankResponse.style';
import UserPhoto from '../../../../../../DragAndDropFiles/UserPhoto/UserPhoto';
import { Card, CardBody, CardHeader } from 'reactstrap';
import { questionTypes } from '../constants';
/**
 * @param {string} filterType comment, questionRating
 * @param {string} displayType mini, expanded
 */
const AdminRankResponse = ({ item = {}, filterType, displayType = 'mini' }) => {
  const { index } = item;
  const renderRatingBars = (evaluator) => {
    const { AnswerNumber = 0, MaxAnswerValue = 5 } = evaluator;
    const bars = [];
    let count = 0;
    while (count < MaxAnswerValue) {
      const barColor = count <= AnswerNumber - 1 ? '#118ded' : '#606b63';
      const width = `${(1 / MaxAnswerValue) * 100}%`;
      bars.push(<div style={{ ...style.ratingBar, backgroundColor: barColor, width }} />);
      count++;
    }
    return bars;
  };

  if (displayType === 'mini') {
    const backgroundColor = index % 2 == 0 ? '#c7cbd1' : 'white';
    const {
      UserFirst,
      UserLast,
      UserPhotoUrl,
      AnswerNumber,
      AnswerText,
      MaxAnswerValue = 5,
      questionType = questionTypes.Rating,
      AnswerTypeNumber1OrText2,
    } = item;

    if (AnswerTypeNumber1OrText2 == 2) {
      return (
        <div
          style={{ ...style.simpleRow, backgroundColor, paddingTop: 10, paddingBottom: 10, verticalAlign: 'middle' }}
        >
          <div style={{ ...style.simpleColumn, width: '20%' }}>
            <div
              style={{
                ...style.simpleRow,
                alignItems: 'center',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100%',
                paddingLeft: 10,
              }}
            >
              {UserPhotoUrl ? (
                <img
                  src={UserPhotoUrl}
                  style={{ objectFit: 'contain', borderRadius: 20, height: 60, width: 60 }}
                  alt="candidate headshot"
                />
              ) : (
                <FontAwesomeIcon icon={faPortrait} fontSize={30} />
              )}
            </div>
          </div>
          <div style={style.commentContainer}>
            <div style={style.evaluatorName}>
              {UserLast || ''}, {UserFirst || ''}
            </div>
            <div style={{ ...style.simpleRow, verticalAlign: 'middle' }}>{AnswerText}</div>
          </div>
        </div>
      );
    }
    if (AnswerTypeNumber1OrText2 == 1) {
      return (
        <div
          style={{ ...style.simpleRow, backgroundColor, paddingTop: 10, paddingBottom: 10, verticalAlign: 'middle' }}
        >
          <div style={{ ...style.simpleColumn, width: '20%' }}>
            <div
              style={{
                ...style.simpleRow,
                alignItems: 'center',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100%',
                paddingTop: 5,
                paddingBottom: 5,
                paddingLeft: 10,
              }}
            >
              {UserPhotoUrl ? (
                <img src={UserPhotoUrl} style={{ objectFit: 'contain', borderRadius: 20, height: 60, width: 60 }} />
              ) : (
                <FontAwesomeIcon icon={faPortrait} style={{ fontSize: 60 }} />
              )}
            </div>
          </div>
          <div style={style.commentContainer}>
            <div style={style.evaluatorName}>
              {UserLast || ''}, {UserFirst || ''} : {AnswerNumber || 0} / {MaxAnswerValue || 0}
            </div>
            <div style={{ ...style.simpleRow, verticalAlign: 'middle' }}>{renderRatingBars(item)}</div>
          </div>
        </div>
      );
    }
    return (
      <div style={{ ...style.simpleRow, backgroundColor, paddingTop: 10, paddingBottom: 10, verticalAlign: 'middle' }}>
        <div style={{ ...style.simpleColumn, width: '20%' }}>
          <div
            style={{
              ...style.simpleRow,
              alignItems: 'center',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
              paddingTop: 5,
              paddingBottom: 5,
              paddingLeft: 10,
            }}
          >
            {UserPhotoUrl ? (
              <img src={UserPhotoUrl} style={{ objectFit: 'contain', borderRadius: 20, height: 60, width: 60 }} />
            ) : (
              <FontAwesomeIcon icon={faPortrait} style={{ fontSize: 60 }} />
            )}
          </div>
        </div>
        <div style={style.commentContainer}>
          <div style={style.evaluatorName}>
            {UserLast || ''}, {UserFirst || ''}
          </div>
          <div style={{ ...style.simpleRow, verticalAlign: 'middle' }}>Unknown Response type!</div>
        </div>
      </div>
    );
  } else {
    const { Answers, index, QuestionText, qID, questionType } = item;

    return (
      <Card style={{ marginTop: 20 }}>
        <CardHeader>
          <b>{`#${index + 1}.`}</b>
          {` ${QuestionText}`}
        </CardHeader>
        <CardBody>
          {Answers.length > 0 ? (
            Answers.map((evaluatorRating, i) => {
              const {
                UserFirst,
                UserLast,
                UserPhotoUrl,
                AnswerText,
                AnswerNumber,
                MaxAnswerValue = 5,
                AnswerTypeNumber1OrText2,
              } = evaluatorRating;

              return (
                <div
                  style={{
                    ...style.simpleRow,
                    //   backgroundColor,
                    paddingTop: 10,
                    paddingBottom: 10,
                    verticalAlign: 'middle',
                    borderBottom: i < Answers.length - 1 ? '1px solid #dbdbd9' : null,
                  }}
                >
                  <div style={{ ...style.simpleColumn, width: '20%' }}>
                    <div
                      style={{
                        ...style.simpleRow,
                        alignItems: 'center',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '100%',
                      }}
                    >
                      {UserPhotoUrl ? (
                        <img
                          src={UserPhotoUrl}
                          style={{ objectFit: 'contain', borderRadius: 20, height: 60, width: 60 }}
                        />
                      ) : (
                        <FontAwesomeIcon icon={faPortrait} style={{ fontSize: 60 }} />
                      )}
                    </div>
                  </div>
                  <div style={style.commentContainer}>
                    <div style={style.evaluatorName}>
                      {UserLast || ''}, {UserFirst || ''}{' '}
                      {`${AnswerTypeNumber1OrText2 == 2 ? '' : `: ${AnswerNumber || 0} / ${MaxAnswerValue || 0}`}`}
                    </div>

                    {AnswerTypeNumber1OrText2 != 2 ? (
                      <div style={{ ...style.simpleRow, verticalAlign: 'middle' }}>
                        {renderRatingBars(evaluatorRating)}
                      </div>
                    ) : null}
                    {AnswerTypeNumber1OrText2 == 2 ? (
                      <div style={{ ...style.simpleRow, verticalAlign: 'middle' }}>
                        <div style={style.simpleColumn}>
                          <div style={{ textIndent: 40 }}>{AnswerText || 'No Comments.'}</div>
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
              );
            })
          ) : (
            <div
              style={{
                ...style.simpleRow,
                //   backgroundColor,
                paddingTop: 10,
                paddingBottom: 10,
                verticalAlign: 'middle',
              }}
            >
              No responses recorded for this question for this candidate.
            </div>
          )}
        </CardBody>
      </Card>
    );
  }
};
export default AdminRankResponse;
