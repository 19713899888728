import { connect } from 'react-redux';
import { setDate, setDateArray, setSeasonType, setZoomBreakRoom } from '../Header.action';
import { setUser } from '../UserInformation/UserInformation.action';
import MobileFork from './MobileFork';

const mapDispatchToProps = (dispatch) => {
  return {
    setReduxDateArray: (dateArray) => dispatch(setDateArray(dateArray)),
    setReduxDate: (date) => dispatch(setDate(date)),
    setUser: (userObject) => dispatch(setUser(userObject)),
    setSeasonType: (seasonType) => dispatch(setSeasonType(seasonType)),
    setZoomBreakRoom: (zoomLink) => dispatch(setZoomBreakRoom(zoomLink)),
  };
};

const mapStateToProps = (state) => {
  const { headerHeight, dateArray, date } = state.header;
  const { interviewDates } = state.evaluator;
  return {
    headerHeight,
    reduxDateArray: dateArray,
    reduxDate: date,
    interviewDates,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MobileFork);
