const style = {
  itemRow: {
    alignItems: 'center',
    borderBottom: '1px solid #c8dae0',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    minHeight: 80,
    padding: 15,
  },
  imgPlaceHolder: {
    fontSize: 50,
    marginRight: 10,
    width: 40,
  },
  info: {
    textAlign: 'left',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    verticalAlign: 'middle',
  },
  buttonIcon: {
    cursor: 'pointer',
    margin: 5,
  },
  simpleRow: {
    display: 'flex',
    flexDirection: 'row',
  },
  simpleColumn: {
    display: 'flex',
    flexDirection: 'column',
  },
  spacedBetweenRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'spaced-between',
  },
};

export default style;
