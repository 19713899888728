import { faCog, faLink } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SettingsDropDown from 'Body/RezRATE/Coordinator/Schedule/TopBar/SettingsDropDown';
import { DepartmentContext } from 'DepartmentWrapper';
import React, { useEffect, useRef, useState } from 'react';
import {
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  Popover,
  PopoverBody,
  PopoverHeader,
  UncontrolledTooltip,
} from 'reactstrap';

import style from './LinkSettingsButton.style';
import './LinkSettingsButton.style.css';
import { checkIfZoomURLAndIfHasPassword, isValidURL } from 'Common.functions';
import Swal from 'sweetalert2';
const LinkSettingsButton = ({
  id,
  disabled,
  btnSize, // sm or lg
  MainRoomURL,
  MainRoomURLDetails,
  scheduleDate,
  scheduleData,
  scheduleID,
  dContext,
  isInPreviewMode,
  setShowUnsavedChangeModal,
  onUpdate,
  btnWidth,
  marginRight,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [hasChanges, setHasChanges] = useState(false);

  const zoomDetails = checkIfZoomURLAndIfHasPassword(MainRoomURL);
  const { isZoomURL, hasPassword } = zoomDetails;

  const renderSettingsPopOver = () => {
    if (disabled) return null;
    return (
      <Modal
        centered
        style={{ maxWidth: '80vw' }}
        isOpen={isOpen}
        target={`settings_button_${id}`}
        trigger="legacy"
        modifiers={{ preventOverflow: { boundariesElement: 'viewport' } }}
      >
        <ModalHeader
          toggle={() => {
            if (isInPreviewMode && isInPreviewMode() && !isOpen) {
              if (setShowUnsavedChangeModal) {
                setShowUnsavedChangeModal(true);
              }

              return;
            }
            if (hasChanges) {
              Swal.fire({
                title: 'Are you sure?',
                text: 'You have unsaved changes. Are you sure you want discard them? This cannot be undone.',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#d33',
                cancelButtonColor: '#3085d6',
                confirmButtonText: 'Discard',
              }).then((result) => {
                if (result.value) {
                  setIsOpen(!isOpen);
                }
              });
            } else {
              setIsOpen(!isOpen);
            }
          }}
        >
          <div style={style.popOverHeader}>
            {scheduleDate ? scheduleDate.format('MMM DD, YYYY') : 'No Date'}{' '}
            {scheduleData && scheduleData.Label
              ? `(${scheduleData.Label}) `
              : scheduleData && scheduleData.metaData && scheduleData.metaData.Label
              ? `(${scheduleData.metaData.Label}) `
              : ' '}
            - Interview Date Settings
          </div>
        </ModalHeader>
        <ModalBody style={{ ...style.popOverBody, width: '80vw' }}>
          <SettingsDropDown
            setParentChanges={setHasChanges}
            dateData={{ MainRoomURL, MainRoomURLDetails, pk_InterviewDate: scheduleID }}
            dContext={dContext}
            onUpdate={() => {
              onUpdate();
              setIsOpen(false);
            }}
            scheduleID={scheduleID}
          />
        </ModalBody>
      </Modal>
    );
  };

  return (
    <div
      id={`settings_button_container_${id}`}
      style={{ width: btnWidth || 61, marginRight: marginRight != null ? marginRight : 10 }}
    >
      <Button
        size={btnSize}
        disabled={disabled}
        id={`settings_button_${id}`}
        color={
          MainRoomURL && (isValidURL(MainRoomURL) || (isZoomURL && hasPassword))
            ? 'success'
            : (MainRoomURL && !isValidURL(MainRoomURL)) || (isZoomURL && !hasPassword)
            ? 'warning'
            : 'danger'
        }
        style={{ ...style.printButton, backgroundColor: null }}
        onClick={() => {
          setIsOpen(!isOpen);
        }}
        // disabled={dateIsInThePast && !willEditThePast}
      >
        <div style={style.simpleRow}>
          <FontAwesomeIcon icon={faLink} />
          <div style={{ position: 'relative' }}>
            <FontAwesomeIcon
              icon={faCog}
              style={{ position: 'absolute', fontSize: 10, left: -6, top: 2, color: 'inherit' }}
            />
          </div>
        </div>
      </Button>
      {!MainRoomURL && !disabled ? (
        <UncontrolledTooltip target={`settings_button_container_${id}`}>Missing All Day URL!</UncontrolledTooltip>
      ) : null}
      {renderSettingsPopOver()}
    </div>
  );
};

export default LinkSettingsButton;
