import { faEnvelopeOpen, faFilter, faSearch, faTimes, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext, useEffect, useState } from 'react';
import { Button, Input, InputGroup, InputGroupAddon, Popover, PopoverBody, PopoverHeader } from 'reactstrap';
import { useAuth0 } from '../../../../../../auth0/reactAuth0Spa';
import { formatBearerToken } from '../../../../../../Common.functions';
import { fetchDataAuth0 } from '../../../../../../Services/dataApi';
import { clone } from '../../../../../../Services/schedule';
import style from './AdminSentItems.style';
import SentItem from './SentItem/SentItem';
import { DepartmentContext } from '../../../../../../DepartmentWrapper';

const AdminSentItems = ({
  candidates = [],
  interviewDates,
  activeDepartment,
  selectedSentItem,
  setSelectedSentItem,
  sentMessages,
  isFetchingItems,
  forcedFilterCandidate,
  showFilter = true,
  columnSentItems,
  onClick,
}) => {
  const [filterBy, setFilterBy] = useState();
  const [searchString, setSearchString] = useState('');
  const [filteredSentItems, setFilteredSentItems] = useState([]);
  const [availableTemplates, setAvailableTemplates] = useState([]);
  const [selectedTemplateId, setSelectedTemplateId] = useState();

  const [showFilterPopover, setShowFilterPopover] = useState(false);
  const [token, setToken] = useState();

  const dContext = useContext(DepartmentContext);
  const { getTokenSilently } = useAuth0();

  useEffect(() => {
    getTemplates();
  }, [activeDepartment]);

  useEffect(() => {
    if (sentMessages) {
      setFilteredSentItems(sentMessages);
      if (sentMessages.length > 0) {
        setSelectedSentItem(sentMessages[0]);
      }
    }
  }, [sentMessages]);

  useEffect(() => {
    if (forcedFilterCandidate) {
      filterByString();
    }
  }, [forcedFilterCandidate]);

  // useEffect(() => {
  //   if (dContext && dContext.department) {
  //     getTemplates();
  //   }
  // }, [dContext]);

  useEffect(() => {
    filterByButton();
  }, [JSON.stringify(filterBy)]);

  useEffect(() => {
    filterByString();
  }, [searchString]);

  useEffect(() => {
    if (forcedFilterCandidate) {
      const newSentMessages = clone(sentMessages);
      const newFilteredSentMessages = [];
      const name = `${forcedFilterCandidate.FirstName || ''} ${forcedFilterCandidate.MiddleName || ''} ${
        forcedFilterCandidate.LastName
      }`;
      newSentMessages.forEach((sm) => {
        const { FirstName, MiddleName, LastName, EmailSentTo } = sm;
        const msgName = `${FirstName || ''} ${forcedFilterCandidate.MiddleName || ''} ${LastName || ''}`;
        if (name === msgName) {
          newFilteredSentMessages.push(sm);
        }
      });
      setFilteredSentItems(newFilteredSentMessages);
    }
  }, [forcedFilterCandidate]);

  const filterByString = () => {
    // Email,
    // Candidate Name,
    // Sent Date,
    // Template Name,
    // Email Body

    // NOTE: Currently missing Email Body, Mike says disregard Sent Date

    const newFilteredSentMessages = [];
    const name = `${(forcedFilterCandidate && forcedFilterCandidate.FirstName) || ''} ${(forcedFilterCandidate &&
      forcedFilterCandidate.MiddleName) ||
      ''} ${(forcedFilterCandidate && forcedFilterCandidate.LastName) || ''}`.toLowerCase();

    sentMessages.forEach((sm) => {
      const { FirstName, MiddleName, LastName, EmailSentTo, Subject } = sm;
      const sentName = `${FirstName || ''} ${MiddleName || ''} ${LastName || ''}`.toLowerCase();
      const matchingTemplate = sm.fk_Template
        ? availableTemplates.find((t) => {
            return t.pk_CommunicationTemplate === sm.fk_Template;
          })
        : null;
      const matchingWithTemplate = matchingTemplate
        ? matchingTemplate.Title.toLowerCase().indexOf(searchString) >= 0
        : null;

      if (
        (sentName.indexOf(searchString) >= 0 ||
          EmailSentTo.toLowerCase().indexOf(searchString) >= 0 ||
          Subject.toLowerCase().indexOf(searchString) >= 0 ||
          matchingWithTemplate ||
          !searchString) &&
        ((forcedFilterCandidate && sentName.indexOf(name) >= 0) || !forcedFilterCandidate)
      ) {
        newFilteredSentMessages.push(sm);
      }
    });

    setFilteredSentItems(newFilteredSentMessages);
    setFilterBy(null);
  };

  const getTemplates = (id = null) => {
    if (!activeDepartment) {
      return;
    }
    getTokenSilently().then((token) => {
      fetchDataAuth0(
        'department/readCommunicationTemplates',
        { pk_Department: activeDepartment.pk_Department },
        formatBearerToken(token),
      )
        .then((result) => {
          setAvailableTemplates(result.data);
          if (id) {
            // if a template was just added select that template from the options
            setSelectedTemplateId(id);
          }
        })
        .catch((err) => {});
    });
  };

  const filterByButton = () => {
    const newSentItems = sentMessages.slice().filter((sentItem) => {
      return filterBy == null || (sentItem && filterBy && sentItem.fk_Template == filterBy.pk_CommunicationTemplate);
    });

    setFilteredSentItems(newSentItems);
  };

  const renderFilterButton = () => {
    return (
      <>
        <Button id="filter_button" style={style.filterButton}>
          <div style={style.spacedBetweenRow}>
            <div>{filterBy ? `Messages of type "${filterBy.Title}"` : 'Showing all messages'}</div>
            <div>
              <FontAwesomeIcon icon={faFilter} />
            </div>
          </div>
        </Button>
        <Popover
          flip={false}
          placement="bottom"
          placementPrefix="templatesFilter bs-popover"
          isOpen={showFilterPopover}
          trigger="legacy"
          target="filter_button"
          toggle={() => {
            setShowFilterPopover(!showFilterPopover);
          }}
        >
          <PopoverHeader>
            <div style={style.spacedBetweenRow}>
              <div>Templates</div>
              <div>
                <FontAwesomeIcon
                  icon={faTimesCircle}
                  style={style.clickable}
                  onClick={(e) => {
                    e.stopPropagation();
                    setShowFilterPopover(false);
                  }}
                />
              </div>
            </div>
          </PopoverHeader>
          <PopoverBody>
            <div style={{ overflowY: 'scroll', height: 350 }}>
              {availableTemplates ? (
                availableTemplates.map((template, i) => {
                  let backgroundColor = i % 2 == 0 ? '#c7cbd1' : null;
                  if (filterBy && template.pk_CommunicationTemplate === filterBy.pk_CommunicationTemplate) {
                    backgroundColor = '#d9f5ff';
                  }
                  return (
                    <div
                      style={{ padding: 10, backgroundColor, textAlign: 'center', cursor: 'pointer' }}
                      onClick={() => {
                        setFilterBy(template);
                        setShowFilterPopover(false);
                      }}
                    >
                      {template.Title}
                    </div>
                  );
                })
              ) : (
                <div>No Templates</div>
              )}
            </div>
          </PopoverBody>
        </Popover>
      </>
    );
  };

  const renderSearchInput = () => {
    return (
      <InputGroup>
        <InputGroupAddon addonType="prepend">
          <Button
            onClick={() => {
              setSearchString('');
              setFilterBy(null);
            }}
          >
            <FontAwesomeIcon icon={faTimes} />
          </Button>
        </InputGroupAddon>
        <Input
          value={searchString}
          type="text"
          placeholder="Search Sent Items. . ."
          onChange={(e) => {
            setFilterBy(null);
            setSearchString(e.target.value.toLowerCase());
          }}
        />
        <InputGroupAddon addonType="append">
          <Button color="success">
            <FontAwesomeIcon icon={faSearch} />
          </Button>
        </InputGroupAddon>
      </InputGroup>
    );
  };

  return (
    <div>
      <div style={{ ...style.simpleRow, position: 'sticky', top: 0, backgroundColor: 'white' }}>
        {renderSearchInput()}
      </div>
      <div style={style.simpleRow}>{showFilter ? renderFilterButton() : null}</div>
      <div style={{ ...style.simpleRow, overflowY: 'scroll', height: 'calc(100vh - 430px)' }}>
        <div style={style.simpleColumn}>
          {isFetchingItems ? (
            <div
              style={{
                marginTop: '15vh',
              }}
            >
              Loading Messages. . .
            </div>
          ) : filteredSentItems && filteredSentItems.length > 0 ? (
            filteredSentItems.map((sentItem, i) => {
              return (
                <SentItem
                  columnSentItems={columnSentItems}
                  sentItem={{ ...sentItem, index: i }}
                  setSelectedSentItem={setSelectedSentItem}
                  selectedSentItem={selectedSentItem}
                  onClick={onClick}
                />
              );
            })
          ) : (
            <div style={{ textAlign: 'center' }}>No Messages</div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AdminSentItems;
