import tinycolor from 'tinycolor2';

const style = {
  scheduleDetailModal: {
    borderRadius: '20px',
    minWidth: '85vw',
    marginTop: '5%',
  },
  scheduleDetailModalBody: {
    height: 460,
  },
  tabsStyle: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
  },
  tabStyle: {
    cursor: 'pointer',
    fontWeight: 700,
    minWidth: '33%',
    width: '33%',
  },
  tabTextStyle: {
    textAlign: 'center',
    width: '100%',
  },
  availableCandidatesTab: {
    display: 'flex',
    flexDirection: 'row',
    paddingTop: 30,
    height: '100%',
  },
  availablCandidatesTabLeft: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: 30,
    width: '30%',
  },
  availableCandidatesRight: {
    display: 'flex',
    flexDirection: 'column',
    width: '70%',
    height: '100%',
    paddingLeft: 30,
  },
  spacedBetweenRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  simpleRow: {
    display: 'flex',
    flexDirection: 'row',
  },
  simpleColumn: {
    display: 'flex',
    flexDirection: 'column',
  },
  buttonRow: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 20,
  },
  buttonIcon: {
    marginRight: 10,
    cursor: 'pointer',
  },
  buttonLabel: { width: '100%', textAlign: 'center', fontWeight: 600 },
  genButton: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginLeft: 10,
    marginRight: 10,
    paddignRight: 20,
    paddingBottom: 10,
    paddingLeft: 20,
    paddingTop: 10,
    width: '100%',
  },
  availableCandidatesList: {
    marginTop: 5,
    maxHeight: 350,
    overflowY: 'scroll',
  },
  availableCandidate: (data) => {
    const { c, selectedCandidates = [], candidateHoveredOn } = data;
    let isSelected = false;
    let border = null;
    let cursor = 'pointer';

    if (c.type && c.type === 'current') {
      border = '5px solid #fffd7d';
    }

    if (c.type === 'invalid') {
      cursor = 'not-allowed';
    }

    return {
      backgroundColor:
        c.color != c.originalColor
          ? candidateHoveredOn && candidateHoveredOn.pk_Candidate == c.pk_Candidate && c.isDuplicatePosition
            ? c.originalColor
            : c.color
          : c.color,
      borderRadius: '5px',
      border: border,
      color: tinycolor(c.color).isLight() ? 'black' : 'white',
      cursor,
      fontWeight: 600,
      padding: 10,
      paddingLeft: 15,
      marginBottom: 5,
    };
  },
  noCandidateBlock: {
    alignItems: 'center',
    backgroundColor: '#c8dae0',
    display: 'flex',
    flexDirection: 'row',
    height: '100%',
    justifyContent: 'center',
    textAlign: 'center',
    fontSize: 25,
    fontWeight: 600,
    padding: 40,
  },
  meetingAttendanceTab: {
    display: 'flex',
    flexDirection: 'row',
    padding: 20,
    marginTop: 10,
    maxHeight: 280,
  },
  attendee: {
    borderBottom: '1px solid #c8dae0',
    padding: 10,
  },
  attendeeProfilePlaceHolder: {
    fontSize: 50,
  },
  attendeeAttendance: (isAttending) => {
    const toReturn = {
      fontWeight: 600,
    };
    if (isAttending) {
      toReturn.color = '#5bad40';
    } else if (isAttending == null) {
      toReturn.color = '#6c757d';
    } else {
      toReturn.color = '#ed3d1a';
    }
    return toReturn;
  },
  attendeeName: {
    fontSize: 12,
    fontWeight: 600,
  },
  attendeeList: {
    display: 'flex',
    flexDirection: 'column',
    maxHeight: 290,
    overflowY: 'scroll',
  },
  getMeetingURLButton: {
    width: '100%',
  },
  bottomButtonPanel: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    marginTop: 20,
  },
  evaluatorsMainContainer: {
    height: 340,
    overflowY: 'scroll',
  },
  evaluatorListContainer: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100%',
    padding: 20,
    paddingTop: 40,
    width: '100%',
  },
  evaluatorItem: {
    // borderBottom: '2px solid #c8dae0',
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 5,
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 5,
    paddingBottom: 5,
  },
  evaluatorName: {
    alignItems: 'flex-start',
    display: 'flex',
    flexDirection: 'column',
    fontWeight: 600,
    justifyContent: 'center',
    paddingLeft: 10,
    width: '57%',
  },
  evaluatorImage: {
    objectFit: 'cover',
    borderRadius: 90,
  },
  flexEndRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
};
export default style;
