import styled from 'styled-components';
import { Col, Card } from 'reactstrap';
import { Carousel } from 'react-responsive-carousel';

const CustomCardHeader = styled.div`
  padding: 10px 15px;
  border-bottom: 1px solid transparent;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  color: #fff;
  background-color: rgb(51, 122, 183);
  border-color: rgb(51, 122, 183);
  text-align: center;
  h4 {
    @media (max-width: 600px) {
      font-size: 1.2rem;
    }
  }
`;

const HeaderWrapper = styled.div`
  width: 100%;
  background-color: rgb(255, 255, 255);
  vertical-align: middle;
  padding: 20px;
  margin-bottom: 10px;
  border: 1px solid transparent;
  border-radius: 4px;
  -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
`;

const CustomCol = styled(Col)`
  width: 100%;
  padding: 0;
`;

const CustomCard = styled(Card)`
  border-color: rgb(51, 122, 183);
`;

const CustomCarousel = styled(Carousel)`
  background-color: #fff !important;
`;

export { CustomCardHeader, CustomCol, CustomCard, CustomCarousel, HeaderWrapper };
