import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import arrayMove from 'array-move';
import React, { Fragment, useContext } from 'react';
import { sortableHandle } from 'react-sortable-hoc';
import { Button } from 'reactstrap';
import Swal from 'sweetalert2';
import { clone } from '../../../../../Services/schedule';
import { v4 as uuidv4 } from 'uuid';
import EvaluationQuestion from './EvaluationQuestion';

const DragHandle = sortableHandle(() => <div>::</div>);

const Wrapper = ({ children }) => <>{children}</>;

const SortableItem = (props) => {
  const {
    value,
    changeValue,
    labelClick,
    groupIndex,
    questionIndex,
    getQuestions,
    pk_QuestionGroup,
    numOfQuestionsInWeight,
    setQuestions,
    questions,
    questionWeights,
    setSelectedQuestionId,
    selectedQuestionId,
    sortOrderChoices,
    season,
    isUpdating,
    changeSortOrder,
    deleteNewQuestion,
  } = props;

  const { uuid } = value;
  return (
    <EvaluationQuestion
      changeSortOrder={changeSortOrder}
      changeValue={changeValue}
      DragHandle={DragHandle}
      getQuestions={getQuestions}
      groupIndex={groupIndex}
      isUpdating={isUpdating}
      labelClick={labelClick}
      numOfQuestionsInWeight={numOfQuestionsInWeight}
      pk_QuestionGroup={pk_QuestionGroup}
      question={value || {}}
      questionIndex={questionIndex}
      questions={questions}
      questionWeights={questionWeights}
      season={season}
      selectedQuestionId={selectedQuestionId}
      setQuestions={setQuestions}
      setSelectedQuestionId={setSelectedQuestionId}
      sortOrderChoices={sortOrderChoices}
      deleteNewQuestion={deleteNewQuestion}
    />
  );
  return (
    <div style={{ width: '100%', minWidth: '1800px !important' }} className="table-row-test">
      <EvaluationQuestion
        question={value || {}}
        labelClick={labelClick}
        DragHandle={DragHandle}
        numOfQuestionsInWeight={numOfQuestionsInWeight}
        questionWeights={questionWeights}
        changeValue={changeValue}
        setSelectedQuestionId={setSelectedQuestionId}
        pk_QuestionGroup={pk_QuestionGroup}
        // selectedQuestion={selectedQuestionId === (pk_QuestionOption || uuid)}
        selectedQuestionId={selectedQuestionId}
        sortOrderChoices={sortOrderChoices}
        groupIndex={groupIndex}
        questionIndex={questionIndex}
        setQuestions={setQuestions}
        questions={questions}
      />
    </div>
  );
  // <li>
  //   <DragHandle />
  //   {value.sortOrder}
  // </li>
};

// const SortableContainer = sortableContainer(({ children }) => {
//   return <div>{children}</div>;
// });

const EvaluationQuestionsSort = ({
  changeSortOrder,
  changeValue,
  defaultQuestionWeight,
  getQuestions,
  isUpdating,
  labelClick,
  numOfQuestionsInWeight,
  questions,
  questionWeights,
  season,
  selectedQuestionId,
  setEditableQuestions,
  setQuestions,
  setSelectedQuestionId,
  sortOrderChoices,
}) => {
  const tdWidth = 9; //season && season.data && season.data.AllowPrescreen ? 9 : 8;

  const onSortEnd = ({ oldIndex, newIndex }) => {
    setQuestions((prevState) => {
      return arrayMove(prevState, oldIndex, newIndex);
    });
  };

  const onSortStart = () => {
    setSelectedQuestionId('');
  };

  if (!questions || questions.length == 0) {
    return (
      <tbody>
        <Wrapper>
          <tr>
            <td colSpan={tdWidth}>
              {' '}
              <div style={{ justifyContent: 'center', display: 'flex', textAlign: 'center', fontWeight: 'bold' }}>
                No Data
              </div>
            </td>
          </tr>
        </Wrapper>
      </tbody>
    );
  }
  const addNewQuestion = (fk_QuestionGroup) => {
    if (!defaultQuestionWeight) {
      return Swal.fire(
        'Error',
        'Please make sure you have created at least one weight category before creating a question',
        'error',
      );
    }
    setEditableQuestions((prevState) => {
      const prevStateCopy = clone(prevState);
      const itemToPush = {
        uuid: uuidv4(),
        QuestionText: '',
        EnableRequired: false,
        QuestionType: null,
        pk_EvaluationQuestionWeight: defaultQuestionWeight.pk_EvaluationQuestionWeight,
        EnablePrescreenForm: true,
        fk_QuestionGroup,
        EnableEvaluationForm: true,
      };

      prevStateCopy.forEach((item, i) => {
        if (item.pk_QuestionGroup === fk_QuestionGroup) {
          itemToPush.EvaluationQuestionSortOrder = prevStateCopy[i].questions.length + 1;
          prevStateCopy[i].questions.push(itemToPush);
        }
      });
      return prevStateCopy;
    });
  };

  const deleteNewQuestion = (groupIndex, questionIndex) => {
    setEditableQuestions((prevState) => {
      const newQuestions = clone(prevState);
      const newQuestionGroup = newQuestions[groupIndex].questions;
      newQuestionGroup.splice(questionIndex, 1);
      newQuestionGroup.forEach((q, i) => {
        q.EvaluationQuestionSortOrder = i + 1;
      });
      newQuestions[groupIndex].questions = newQuestionGroup;
      return newQuestions;
    });
  };

  return (
    <tbody>
      {questions.map((value, groupIndex) => {
        if (!value.questions || value.questions.length === 0) {
          return (
            <Wrapper>
              <tr key={value.pk_QuestionGroup}>
                <td colSpan={tdWidth}>{value.GroupName || 'Unnamed Question Group'}</td>
              </tr>
              <tr>
                <td colSpan={tdWidth}>No Questions</td>
              </tr>
              <tr>
                <td colSpan={tdWidth}>
                  <button
                    style={{ marginBottom: 20 }}
                    type="button"
                    onClick={(e) => {
                      e.preventDefault();
                      addNewQuestion(value.pk_QuestionGroup);
                    }}
                    className="btn btn-success btn-sm"
                  >
                    <FontAwesomeIcon icon={faPlus} /> Add Question
                  </button>
                </td>
              </tr>
            </Wrapper>
          );
        }

        const groupQuestions = value.questions.sort((a, b) => {
          return a.EvaluationQuestionSortOrder - b.EvaluationQuestionSortOrder;
        });

        const AllowPrescreen = season && season.data ? season.data.AllowPrescreen : false;

        let addBtnSpan = 9;

        value.GroupName = null;
        return (
          <>
            <tr>
              <td colSpan={tdWidth}>
                {value.GroupName || ''}{' '}
                {value.GroupName ? `(${value.questions.length} Questions)` : value.questions.length}{' '}
                {value.GroupName ? '' : 'Questions'}
              </td>
            </tr>
            {groupQuestions.map((item, questionIndex) => {
              return (
                <SortableItem
                  // onClick={onSortStart}
                  changeSortOrder={changeSortOrder}
                  changeValue={changeValue}
                  deleteNewQuestion={deleteNewQuestion}
                  getQuestions={getQuestions}
                  groupIndex={groupIndex}
                  i={groupIndex}
                  isUpdating={isUpdating}
                  key={`item_${value.pk_QuestionGroup}_${item.pk_EvaluationQuestion}_${questionIndex}`}
                  labelClick={labelClick}
                  numOfQuestionsInWeight={numOfQuestionsInWeight}
                  pk_QuestionGroup={value.pk_QuestionGroup}
                  questionIndex={questionIndex}
                  questions={questions}
                  questionWeights={questionWeights}
                  season={season}
                  selectedQuestionId={selectedQuestionId}
                  setQuestions={setQuestions}
                  setSelectedQuestionId={setSelectedQuestionId}
                  sortOrderChoices={sortOrderChoices}
                  value={item}
                />
              );
            })}
            <tr>
              <td colSpan={addBtnSpan}>
                <Button
                  onClick={(e) => {
                    e.preventDefault();
                    addNewQuestion(value.pk_QuestionGroup);
                  }}
                  className="btn btn-success btn-sm"
                >
                  <FontAwesomeIcon icon={faPlus} /> Add Question
                </Button>
              </td>
            </tr>
          </>
        );
      })}
    </tbody>
  );
};

export default EvaluationQuestionsSort;
