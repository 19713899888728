const style = {
  simpleRow: {
    display: 'flex',
    flexDirection: 'row',
  },
  simpleColumn: {
    display: 'flex',
    flexDirection: 'column',
  },
  spacedBetweenRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  manageFieldsEnabledItemsVisibilityLabels: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    width: 'calc(100% - 27px)',
  },

  manageFieldsContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '50%',
    padding: 10,
    height: 'calc(100vh - 400px)',
    margin: 5,
    border: '1px solid black',
    overflowY: 'scroll',
  },
  manageFieldsContainerSubContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: 'calc(100% - 10px)',
    padding: 10,
    margin: 5,
  },
  visibilityLabel: {
    alignSelf: 'flex-end',
    fontSize: 10,
    fontWeight: 'bold',
    marginLeft: 3,
    padding: 5,
    textAlign: 'center',
    verticalAlign: 'bottom',
    width: 45,
  },
};

export default style;
