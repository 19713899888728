import { faCloudUploadAlt, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Axios from 'axios';
import React, { useEffect, useState, Fragment } from 'react';
import { useDropzone } from 'react-dropzone';
import { useAuth0 } from '../../auth0/reactAuth0Spa';
import { Col, Row, Button, ListGroup, ListGroupItem } from 'reactstrap';
import { fetchDataAgnostic, constructUrl, postDataAgnostic } from '../../Services/dataApi';
import Swal from 'sweetalert2';
import Error from '../../Body/Statuses/Error';
import Loading from '../../Body/Statuses/Loading';
import CandidateHeader from '../../Header/header.container';
import UserPhotoTypes from '../../Body/DragAndDropFiles/UserPhoto/UserPhotoTypes';
import UserPhoto from '../../Body/DragAndDropFiles/UserPhoto/UserPhoto';

const thumb = {};

const thumbInner = {};

const CandidateSubscription = ({ match, user, setUser }) => {
  const [hasPhoto, setHasPhoto] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [isSubscribed, setIsSubscribed] = useState(null);
  const [programName, setProgramName] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [postingSubscription, setPostingSubscription] = useState(false);
  const [isDemo, setIsDemo] = useState(false);
  const [error, setError] = useState(false);
  const [noUser, setNoUser] = useState(false);
  const [isPosting, setIsPosting] = useState(false);
  const [currentProgress, setCurrentProgress] = useState(0);
  const [files, setFiles] = useState([]);

  const { PhotoUrl } = user || {};
  const { candidateId = 'DEMO' } = match.params;

  const { getTokenSilently } = useAuth0();

  useEffect(() => {
    if (candidateId.toUpperCase() === 'DEMO') {
      setIsDemo(true);
      setUser({});
      setIsLoading(false);
      return;
    }

    fetchDataAgnostic('candidate/email/subscription', { CandidateUUID: candidateId })
      .then((result) => {
        const { Email, FirstName, LastName, DepartmentName, userSubscribed } = result.data;
        setIsSubscribed(userSubscribed);
        setUser({ Email, FirstName, LastName });
        setProgramName(DepartmentName);
        setIsLoading(false);
      })
      .catch((err) => {
        setError(true);
      });
  }, []);

  const handleSubscribeButton = (e) => {
    e.preventDefault();
    setPostingSubscription(true);
    postDataAgnostic('candidate/email/subscribe', { CandidateUUID: candidateId })
      .then((result) => {
        return window.location.reload(false);
      })
      .catch((err) => {
        setPostingSubscription(false);
        return Swal.fire('Error', 'Something went wrong, please contact support', 'error');
      });
  };

  const handleUnsubscribeClick = (e) => {
    e.preventDefault();
    setPostingSubscription(true);
    postDataAgnostic('candidate/email/unsubscribe', { CandidateUUID: candidateId })
      .then((result) => {
        return window.location.reload(false);
      })
      .catch((err) => {
        setPostingSubscription(false);

        return Swal.fire('Error', 'Something went wrong, please contact support', 'error');
      });
  };

  const determineDisplay = () => {
    if (!isSubscribed) {
      return (
        <Fragment>
          <Col />
          <Col>
            {' '}
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                padding: '15px',
                height: '100%',
                alignItems: 'center',
              }}
            >
              <div>
                <Button disabled={postingSubscription} onClick={handleSubscribeButton} color="danger">
                  Oops, please re-subscribe me!
                </Button>
              </div>
            </div>
          </Col>
          <Col />
        </Fragment>
      );
    }
    return (
      <Fragment>
        <Col />
        <Col>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
              color: 'black',
              border: '1px solid black',
              padding: '60px',
              backgroundColor: '#e8e8e8',
            }}
          >
            <h4 className="bold" style={{ paddingBottom: '20px' }}>
              Are you sure you want to completely unsubscribe from RezRATE Notifications?
            </h4>
            <h6 className="bold">
              You will not just be unsubscribed from {programName}, but all programs that utilize RezRATE services.
            </h6>
            <div>
              <div>
                <h6 className="bold">Doing so will prevent you from receiving any of the following:</h6>
                <ul className="bold">
                  <li>Residency and Fellowship interview invitations</li>
                  <li>Registration Confirmations</li>
                  <li>Interview Day Schedules</li>
                  <li>General communications from program coordinators about your interview with their programs.</li>
                </ul>
              </div>
              <div style={{ display: 'flex', justifyContent: 'center', padding: '15px' }}>
                <Button disabled={postingSubscription} onClick={handleUnsubscribeClick} color="danger">
                  Unsubscribe
                </Button>
              </div>
            </div>
            <p>
              <span className="bold">Note:</span> RezRATE only sends these communications at the behest of verified
              Residency and Fellowship programs for the purposes of facilitating recruitment interviews. If you feel you
              have received communications from this system in error, please{' '}
              <a href={'https://www.rezrate.com/contact'} target="_blank" rel="noopener noreferrer">
                contact us.
              </a>
            </p>
          </div>
        </Col>
        <Col />
      </Fragment>
    );
  };

  if (isLoading) {
    return <Loading />;
  }

  if ((user === null || !user) && !isDemo) {
    return <Error header="Error" subHeader="We couldn't find a user with that ID" />;
  }

  const { id = 'DEMO' } = match.params;

  return (
    <Row style={{ padding: '25px', width: '100%' }}>
      <Col>
        <Row>
          <CandidateHeader />
        </Row>

        <br />
        <Row style={{ marginTop: '5vh', height: '50vh' }}>{determineDisplay()}</Row>
      </Col>
    </Row>
  );
};

export default CandidateSubscription;

// documents dissapearing
// inversion
// ship bell
// schedule showing
// status not updating
